import {Header} from "../../landing_page/home/Header";


export default function PrivacyEnglish() {
  return (
    <>
      <Header/>
      <div className="bg-white lg:px-8 pb-16 pt-56 lg:pt-56">
        <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Website Privacy Policy</h1>
          <p className="mt-10 leading-8">
            This Privacy Policy applies to: (i) use of the DataSenses owned and operated Websites (datasenses.io,
            dts.guru and their subdomains); (ii) our marketing activities including, registrations as part of
            webinars, conference events, emails etc.; and (iii) any other product or activity that links to, or
            otherwise references, this privacy policy (collectively, the “Marketing Platforms“). We respect your privacy
            rights and value your trust. This Privacy Policy describes how we collect, receive, use, store, share,
            transfer, and process your personal information, as well as your rights in determining what we do with the
            information that we collect or hold about you.
          </p>
          <div className="mt-10 max-w-2xl space-y-4 leading-8">
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">1. Google Limited Use Policy
            </h2>
            <p>
              DataSenses use information received from Google Apis will adhere to
              <a className={"text-blue-600"}
                 href={"https://developers.google.com/terms/api-services-user-data-policy"}> Google API Services User
                Data Policy</a>,
              including the Limited Use requirements. DataSenses doesn't transfer the data to any other app
            </p>
          </div>
          <div className="mt-10 max-w-2xl space-y-4 leading-8">
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">2. Information We Collect
            </h2>
            <p>
              When using or interacting with our Marketing Platforms, we may collect or receive the following types of
              information (collectively, “Information”).
            </p>
            <ul className="list-decimal ml-5 space-y-4">
              <li className="pl-5">"Personal Information” such as name, email, contact details or any other personal
                content that you
                provide to us whether through a form or field on our website or any other communication (e.g. email,
                phone, post etc.).
              </li>
              <li className="pl-5">
                “Technical Information” such as browser type, operating system, device type, IP address and other
                similar
                technical information typically received from a browser or device automatically when visiting or
                interacting with our Marketing Platforms. This may include the referring URL that led you to our
                website.
              </li>
              <li className="pl-5">
                “Usage Information” such as the pages you visited on our website, where you clicked, searches performed
                on
                our website and other similar information related to how you have used our website. It may also include
                information related to whether you receive, opened or clicked on any links in an email sent to you.
              </li>
            </ul>
            <p>
              We understand that certain Technical information or Usage Information may, either alone or when combined
              with other data, be deemed personal data under various laws and jurisdictions and we are committed to
              treating such data in compliance with applicable laws.
            </p>
          </div>
          <div className="mt-10 max-w-2xl space-y-4 leading-8">
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">3. The Methods We Use to Collect and
              Receive Information
            </h2>
            <p>
              Depending on the type of Information, we may collect or receive Information in the following ways:
            </p>
            <ul className="list-decimal ml-5 space-y-4">
              <li className="pl-5">When you voluntarily share Information with us. For example, when you register to one
                of our events,
                request that we contact you through our online forms, submit your CV, or when you request to receive
                certain content that we may make available (e.g. research and white papers).
              </li>
              <li className="pl-5">Through use of cookies and other similar technologies (e.g. HTML5 Local Storage, LSO,
                web beacons,
                JavaScript, etc.). These technologies help us to analyze how our Marketing Platforms are used and to
                tailor content that is relevant to you. It also helps us to deliver more relevant advertisements to
                you on
                our own or third party sites. For more information on such technologies, including how to manage your
                cookie preferences please see our Cookie Policy.
              </li>
              <li className="pl-5">Information obtained from third party sources. This includes Information received
                through the various
                business support tools and services that we use (e.g. marketing platforms, analytics services etc.)
                and
                public sources such as social media sites. We may combine the Information received through these
                sources
                with other Information we have in order to ensure our records are up to date and so that we are able
                to
                provide you with relevant content.
              </li>
            </ul>
          </div>
          <div className="mt-10 max-w-2xl space-y-4 leading-8">
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">4. The Purposes
            </h2>
            <p>We use Information for the following purposes:
            </p>
            <ul className="list-decimal ml-5 space-y-4">
              <li className="pl-5">
                To process your inquiries and to respond to your requests. For example, when you contact us to learn
                more
                about our products or services or when you request to receive certain materials from us (e.g. white
                papers, research papers, product and services information). Additionally, if you submit your CV to us,
                we
                use it to evaluate your candidacy (including by assessing your qualifications and skills).
              </li>
              <li className="pl-5">
                To send you information related to our services and products that we believe may interest you, such as
                newsletters or product and service updates. Such communications will be sent to you when you have chosen
                to receive such communication or when we have a legitimate interest to market our products and services.
              </li>
              <li className="pl-5">
                To understand how you have used our Marketing Platforms and to customize them in accordance with your
                interests and past actions and choices. We do this based on our legitimate interest to operate our
                Marketing Platforms, diagnose any problems with the Marketing Platforms and to improve the Marketing
                Platforms and your experience when navigating through them.
              </li>
              <li className="pl-5">
                In our legitimate interest to prevent fraud or harm to us or to any third party, and ensure the
                security
                of our network and services.
              </li>
              <li className="pl-5">
                Where it is necessary for DataSenses to comply with its legal obligations and to exercise and enforce
                its
                legal rights.
              </li>
              <li className="pl-5">Where it is necessary for DataSenses to comply with its legal obligations and to
                exercise and enforce
                its
                legal rights.
              </li>
              <li className="pl-5">We may use certain third-party marketing and advertising networks to help us market
                our products on our own website and on third party websites with our own data only. (e.g. remarketing ads across the Internet by Google).
                We <span className={"font-bold"}>DO NOT</span> use any of our client's data for any marketing and advertising activities.
              </li>
            </ul>
          </div>
          <div className="mt-10 max-w-2xl space-y-4 leading-8">
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">5. Who We Share Information With
            </h2>
            <ul className="list-decimal ml-5 space-y-4">
              <li className="pl-5">Third parties that help us operate our Marketing Platforms (e.g. hosting company,
                recruitment platforms
                and agencies, payment processors, business management and email distribution service providers and other
                similar service providers). These companies are authorized to use your personal information only as
                necessary to provide these services to us.
              </li>
              <li className="pl-5">When required by law, such as to comply with court orders, search warrants,
                regulatory orders,
                subpoenas,
                and other lawful requests by public authorities, including to meet national security or law enforcement
                requirements;
              </li>
              <li className="pl-5">
                Legal authorities, consultants, advisors or service providers when required to investigate, respond to,
                or
                prevent, fraud; or when required to ensure the security of our network and services and protect the
                safety
                of DataSenses or the public.
              </li>
              <li className="pl-5">DataSenses resellers, agents, partners or service providers acting on our behalf in
                connection with
                the
                offering of DataSenses’s products or services.
              </li>
              <li className="pl-5">Third-party marketing and advertising networks to help us market our products on our
                own website (not data of our clients) and on third party websites (e.g. remarketing ads across the Internet by Google).
                We <span className={"font-bold"}>DO NOT</span> share any of our client's data with third parties for any purpose.
              </li>
            </ul>
            <p>
              Without derogating from the foregoing, DataSenses may publish general aggregate and unidentifiable
              information (e.g. statistical information) relating to use of its Marketing Platforms.
            </p>
          </div>
          <div className="mt-10 max-w-2xl space-y-4 leading-8">
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">6. Protecting Your Information
            </h2>
            <p>
              We strive to meet the highest standards of security measures across our business units. We have
              implemented appropriate technical and organizational measures based on industry-leading standards. Such
              measures are designed to protect against unauthorized access or unlawful processing of Personal
              Information and against accidental loss, destruction or damage of Personal Information. As part of such
              measures, we have established various policies and procedures that guide us, including in respect of asset
              management, access management, physical security, people security, product security, cloud and network
              infrastructure security, third-party security, vulnerability management, security monitoring, and incident
              response.
            </p>
          </div>
          <div className="mt-10 max-w-2xl space-y-4 leading-8">
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">7. Information Storage and Retention
            </h2>
            <p>
              We may store Information on our own servers as well as those operated by third party data hosting
              providers. We will only retain your Personal Information for as long as necessary to pursue the purpose of
              the collection. We may also retain your Personal Information for such time needed to pursue our legitimate
              business interests, in connection with any legal claims and compliance with legal obligations. When we use
              your information for direct marketing purposes, we will retain your data until you opt-out from receiving
              the marketing materials; provided that we will need to retain certain information to maintain a record of
              your request.
            </p>
          </div>
          <div className="mt-10 max-w-2xl space-y-4 leading-8">
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              8. Modifications to This Policy
            </h2>
            <p>
              DataSenses may amend this Privacy Policy at any time, provided that in the event of a material change we
              shall provide notice on this page and/or a note near the link to this page. The updates shall apply
              immediately for new Information collected or provided as of the day of the update and following thirty
              (30) days for any Information collected or provided to DataSenses prior to the update date. We encourage
              you to periodically review this page for any updates.
            </p>
          </div>
          <div className="mt-10 max-w-2xl space-y-4 leading-8">
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              9. User consent for AI
            </h2>
            <ul className="list-decimal ml-5 space-y-4">
              <li className="pl-5">We don't share the received data from our clients to any third party.
                tools.
              </li>
              <li className="pl-5">We just receive the information that our clients send to us. Example: If our clients send data of their users to our app using our API when their users do some actions on their platform such as Install App, Add To Cart, and allowed user information such as email, phone.
              </li>
            </ul>
          </div>
          <div className="mt-10 max-w-2xl space-y-4 leading-8">
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              10. Contacting Us
            </h2>
            <p>
              if you have any questions or concerns about this Website Privacy Policy, the information we collect, the
              practices of DataSenses, or your dealings with the Marketing Platforms, please contact us by sending an
              email to: contact@datasenses.com
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
