import {useTranslation} from 'react-i18next';
import {useParams} from "react-router-dom";
import {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {DISPLAY_DATE_CALENDAR_FORMAT} from "consts";
import moment from "moment";
import {showError} from "components/FlashMessage/flashMessageSlice";
import LoadingSpinner from "components/LoadingSpinner";
import './index.scss';
import SegmentAudience from "views/segments/segment_detail/segment_audience";
import Segment from "services/segment";
import {DsIconReload} from "components/DsIcon";


export default function SegmentDetail() {
  const {t} = useTranslation();
  const {appId, segmentId} = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [segmentResult, setSegmentResult] = useState({});
  const [segment, setSegment] = useState({});
  // const [enabled, setEnabled] = useState(false)
  const [listSyncAudienceSegment, setListSyncAudienceSegment] = useState({})


  const onReload = async (payload) => {
    setIsLoading(true);

    try {
      const requestResponse = await Segment.getSegmentData({appId, payload});
      setSegmentResult(requestResponse.result);
    } catch (error) {
      const {message} = error;

      dispatch(showError({message}));
    }

    setIsLoading(false);
  }

  const getSegment = async () => {
    try {
      const response = await Segment.getSegment({appId, segmentId});
      setSegment(response);
      const result = JSON.parse(response.result);
      setSegmentResult(result);
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
  }

  const navigateFindPeople = () => {
    window.location.href = `/app/${appId}/find-people/${segmentId}`;
  }

  useEffect(() => {
    getSegment();
  }, []);


  return (
    <div id="segment-detail">
      <div className="flex justify-between items-center mt-5">
        <p className="ds-text-page-title">{t('DETAILS')}</p>
        <div className="flex items-center">
          <button
            disabled={isLoading}
            onClick={() => onReload(JSON.parse(segment.payload))}
            className="mx-2 pl-5 h-7 w-7 ds-button-transparent focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-lg focus:outline-none relative"
          >
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
              {isLoading ? <LoadingSpinner/> : <DsIconReload/>}
            </div>
          </button>
          <button
            onClick={() => navigateFindPeople()}
            className="text-white ds-button"
          >
            {t('EDIT')}
          </button>
        </div>
      </div>
      <div>
        <dl
          className="items-center mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white ds-block md:grid-cols-3 md:divide-x md:divide-y-0">
          <div className="p-3">
            <dt className=" text-gray-700">Segment</dt>
            <h3 className="font-extrabold leading-6 text-gray-900 text-2xl">{segment.name}</h3>
          </div>
          <dt className={"p-3 text-base font-normal text-gray-500"}>
            <div>
              <div className={"flex items-center"}>
                <svg viewBox="0 0 2 2" className="h-1 w-1 fill-current">
                  <circle cx={1} cy={1} r={1}/>
                </svg>
                <p className="truncate ml-2">Created by {segment.createdBy}</p>
              </div>
              <div className={"flex items-center"}>
                <svg viewBox="0 0 2 2" className="h-1 w-1 fill-current">
                  <circle cx={1} cy={1} r={1}/>
                </svg>
                <p className="truncate ml-2">On {moment(segment.createdAt).format(DISPLAY_DATE_CALENDAR_FORMAT)}</p>
              </div>

            </div>
          </dt>
        </dl>
      </div>
      <div>
        <dl
          className="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white ds-block md:grid-cols-3 md:divide-x md:divide-y-0">
          <div className="px-4 py-5 sm:p-6">
            <dt className="text-base font-normal text-gray-700">Total Users</dt>
            <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold ds-text-color">
                {segmentResult.totalUsers}
                {/*<span className="ml-2 text-sm font-medium text-gray-500">from {segmentResult.totalUsers}</span>*/}
              </div>
            </dd>
          </div>
          <div className="px-4 py-5 sm:p-6">
            <dt className="text-base font-normal text-gray-700">Total Phone numbers</dt>
            <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold ds-text-color">
                {segmentResult.totalPhoneNumbers}
              </div>
            </dd>
          </div>
          <div className="px-4 py-5 sm:p-6">
            <dt className="text-base font-normal text-gray-700">Total Emails</dt>
            <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold ds-text-color">
                {segmentResult.totalEmails}
                {/*<span className="ml-2 text-sm font-medium text-gray-500">from {segmentResult.totalUsers}</span>*/}
              </div>
            </dd>
          </div>

        </dl>
      </div>

      <div className={"flex items-center mt-5 space-x-2"}>
        <h3 className="ds-text-page-title ">
          {t('SYNC')}
        </h3>
        <span className={"ds-text-lighter"}>(Under Development)</span>
      </div>


      {/*<SegmentAudience*/}
      {/*  listSyncAudienceSegment={listSyncAudienceSegment}*/}
      {/*  segment={segment}*/}
      {/*/>*/}
    </div>
  );
};
