import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import DeleteAppModal from "views/homepage/delete_app";
import React, {useState, useEffect} from "react";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle, faUpload, faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import {PATHS, STATUS} from 'consts';
import {showError, showInfo} from "components/FlashMessage/flashMessageSlice";
import ToggleCheckbox from "components/ToggleCheckbox";
import {FormProvider, useForm} from "react-hook-form";
import TextInput from "components/TextInput";
import {PLATFORM} from "consts";

import AppService from "services/app";


export default function AppInfo({appInfo}) {
  const { t } = useTranslation();
  const methods = useForm();
  const dispatch = useDispatch();

  const [isDeleteAppOpen, setIsDeleteAppOpen] = useState(false);
  const [fileName, setFileName] = useState('Choose a file');
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const active = methods.watch("active");

  const popUpDeleteApp = () => {
    setIsDeleteAppOpen(true);
  }

  const goToHome = () => {
    window.location = PATHS.APP
  }

  useEffect(() => {
    methods.setValue("active", appInfo.isUninstallRecorded);
    setFileName(appInfo.apnsAuthKey ? appInfo.apnsAuthKey : 'Choose a file')
  }, [appInfo]);

  const handleFileChange = (event) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const file = event.target.files[0];
      setFile(file);
      setFileName(file ? file.name : 'Choose a file');
      setFileError("");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data) => {
    if (!file && data.active && appInfo.platform === PLATFORM.IOS && !appInfo.apnsAuthKey) {
      setFileError("APNs Authentication Key is required.");
    }

    const formData = new FormData();

    formData.append('is_uninstall_recorded', data.active);
    formData.append('firebase_notification_project_id', data.projectId && data.active ? data.projectId : '');
    formData.append('apns_team_id', data.apnsTeamId && data.active ? data.apnsTeamId : '');
    formData.append('apns_key_id', data.apnsKeyId && data.active ? data.apnsKeyId : '');
    formData.append('apns_auth_key', file && data.active ? file : '');
    formData.append('app_id', appInfo.appId)

    try {
      const result = await AppService.updateUninstallMeasurement(appInfo.appId, formData);
    
      dispatch(showInfo({ message: "Update successfully." }));
    } catch (e) {
      const {message} = e;
      dispatch(showError({message}));
    }
  }


  return (
    <div>
      <div className="ds-block">
        <div className="rounded-md p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <FontAwesomeIcon className="h-4 w-4 text-blue-400" icon={faInfoCircle}/>
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm">
                <span className="text-base font-semibold ds-text-color">Next steps for your app</span><br/><br/>
                <span className="text-sm font-semibold">1. Integrate the Adjust SDK into your app</span><br/>
                <span className="text-sm pl-5">Go to SDK integration tab</span><br/>
                <span className="text-sm font-semibold">2. Send test events</span><br/>
                <span className="text-sm pl-5">With some events your reports will contain more than generic KPIs.</span><br/>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="ds-block mt-5">
        <div className="px-4 py-6 sm:px-6">
          <h3 className="text-base font-semibold leading-7 text-gray-900">App details</h3>
        </div>
        <div className="border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">App name</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{appInfo.name}</dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">Currency</dt>
              <dd
                className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{appInfo.currency ? appInfo.currency : 'USD'} </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">Created By</dt>
              <dd
                className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{appInfo.createdBy} </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">Platform</dt>
              <dd
                className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{appInfo.platform} </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">Status</dt>
              <dd
                className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{appInfo.status === STATUS.APP_STATUS_ACTIVE ?
                <span className="text-teal-700 font-bold">Active</span> :
                <span className="text-rose-600 font-bold">Inactive</span>} </dd>
            </div>
            { appInfo.platform === PLATFORM.WEB ?
              <div></div>
              : 
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900">Activate Uninstall Measurement</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)} enctype="multipart/form-data">
                      <div className="flex justify-start items-center w-full">
                        <ToggleCheckbox name="active"/>                
                      </div> 
                      { active && appInfo.platform === PLATFORM.ANDROID ? 
                        <div className="mt-6">
                          <TextInput
                            label="Project ID"
                            name="projectId"
                            type="text"
                            className="ds-input ds-input-text w-1/2"
                            defaultValue={appInfo.firebaseNotificationProjectId}
                            rules={
                              {
                                required: t('Project ID is required.'),
                              }
                            }
                          />
                        </div> 
                      : active && appInfo.platform === PLATFORM.IOS ? 
                        <div>
                          <div className="mt-6">
                            <label className="ds-input-text">Upload your APNs Authentication Key (.p8)</label>
                          </div>
                          <div>
                            <input
                              type="file"
                              id="file"
                              accept=".p8"
                              onChange={handleFileChange}
                              className="hidden"
                            />
                            <label for="file" className="ds-label-inputfile"><FontAwesomeIcon icon={faUpload} className="fa-solid mr-2"/>{fileName}</label>
                            {fileError ? <div className='text-xs text-red-700 mt-1'>APNs Authentication Key is required</div> : ''}
                          </div>

                          <div className = "mt-6 flex flex-row items-center">
                            <TextInput
                              label="Team ID"
                              name="apnsTeamId"
                              type={showPassword ? "text" : "password"}
                              className="ds-input ds-input-text w-full"
                              defaultValue={appInfo.apnsTeamId ? appInfo.apnsTeamId : ""}
                              rules={
                                {
                                  required: t('Team ID is required.'),
                                }
                              }
                            />
                            <button
                              type="button"
                              onClick={togglePasswordVisibility}
                              className="mt-6 ml-2"
                            >
                              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                            </button>
                          </div> 

                          <div className = "mt-6 flex flex-row items-center">
                            <TextInput
                              label="Key ID"
                              name="apnsKeyId"
                              type={showPassword ? "text" : "password"}
                              className="ds-input ds-input-text w-full"
                              defaultValue={appInfo.apnsKeyId ? appInfo.apnsKeyId : ""}
                              rules={
                                {
                                  required: t('Key ID is required.'),
                                }
                              }
                            />
                            <button
                              type="button"
                              onClick={togglePasswordVisibility}
                              className="mt-6 ml-2"
                            >
                              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                            </button>
                          </div> 
                        </div>
                        : <div></div>
                      }
                      <div className="flex items-center justify-end mt-6">
                        <button
                          type="submit"
                          className="ds-button"
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </FormProvider>
                </dd>
              </div>
            }
          </dl>
        </div>
      </div>
      {appInfo.status ?
        <div className="ds-block mt-5">
          <div className="px-4 py-6 sm:px-6">
            <h3 className="text-base font-semibold leading-7 text-gray-900">Delete this app</h3>
          </div>
          <div className="border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
              <div className="px-4 py-6 sm:grid sm:gap-4 sm:px-6">
                <span className="mt-1 text-sm text-gray-700">Deleted apps are immediately hidden from the dashboard and will be permanently deleted from our system after 30 days.</span>
              </div>
              <div className="px-4 py-6 sm:grid sm:gap-4 sm:px-6 grid flex justify-center">
                <button onClick={() => popUpDeleteApp()}
                        className="w-48 bg-rose-600 text-white rounded border font-semibold py-2 px-4">Delete
                </button>
              </div>
            </dl>
          </div>
        </div>
        : ''
      }

      <DeleteAppModal
        isOpen={isDeleteAppOpen}
        id={appInfo.appId}
        name={appInfo.name}
        reset={goToHome}
        closeModal={() => setIsDeleteAppOpen(false)}
      />

    </div>
  )
}
