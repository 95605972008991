import HttpMethod from 'consts/httpMethod';
import {processRequest, processSuccessResponse, processErrorResponse, isUndefinedOrEmpty} from 'utils';


export default {
  async getFieldList({appId, isAds}) {

    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: '/chart/field/list',
      method: HttpMethod.POST
    };

    try {
      const res = await processRequest(ep, {data: {appId, isAds}});
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },


  async getFieldValue({appId, fieldKey}) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: '/chart/field/value/get',
      method: HttpMethod.POST
    };

    try {
      const res = await processRequest(ep, {data: {appId, fieldKey}});
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  async getEventProperty() {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: '/list-event-fields',
      method: HttpMethod.POST
    };

    try {
      const res = await processRequest(ep);
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  async getChart(appId, payload) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/chart?app_id=${appId}`,
      method: HttpMethod.POST
    };

    try {
      const res = await processRequest(ep, {data: payload});

      switch (payload.chart_type) {
        case "kpi":
        case "line_chart":
        case "stacked_chart":
        case "bar_chart":
          const result = processSuccessResponse({
            code: 0,
            data: res,
          });
          if (isUndefinedOrEmpty(result.data.data)) {
            return null
          }
          return result
        case "table":
          // Create a hardcoded table response
          const tableResponse = this.createTableResponse();
          return processSuccessResponse({
            code: 0,
            data: tableResponse,
          });

        default:
          return processSuccessResponse({
            code: 0,
            data: res,
          });
      }
    } catch (e) {
      // Handle the error, returning null as specified
      return null;
    }
  },

  createTableResponse() {
    return {
      chart_name: "table_1",
      chart_type: "table",
      metric: [
        {name: "session"},
        {name: "user"},
      ],
      attributes: [
        {name: "session", type: "string"},
        {name: "user", type: "string"},
        {name: "SPLIT", type: "DATASET"}
      ],
      data: [
        {
          "SPLIT": {
            "attributes": [
              {
                "name": "session",
                "type": "string"
              },
              {
                "name": "user",
                "type": "string"
              },
              {
                "name": "date_key",
                "type": "string"
              },
              {
                "name": "SPLIT",
                "type": "DATASET"
              }
            ],
            "data": [
              {
                "SPLIT": {
                  "attributes": [
                    {
                      "name": "session",
                      "type": "string"
                    },
                    {
                      "name": "user",
                      "type": "string"
                    },
                    {
                      "name": "platform",
                      "type": "string"
                    }
                  ],
                  "data": [
                    {
                      "platform": "web",
                      "session": "66",
                      "user": "66"
                    },
                    {
                      "platform": "ios",
                      "session": "1",
                      "user": "1"
                    },
                    {
                      "platform": "Android",
                      "session": "67",
                      "user": "67"
                    },
                    {
                      "platform": "iPhone",
                      "session": "1",
                      "user": "1"
                    },
                    {
                      "platform": "mweb",
                      "session": "16",
                      "user": "13"
                    }
                  ],
                  "key": [
                    "platform"
                  ]
                },
                "date_key": "2023-11-02",
                "session": "151",
                "user": "148"
              },
              {
                "SPLIT": {
                  "attributes": [
                    {
                      "name": "session",
                      "type": "string"
                    },
                    {
                      "name": "user",
                      "type": "string"
                    },
                    {
                      "name": "platform",
                      "type": "string"
                    }
                  ],
                  "data": [
                    {
                      "platform": "mweb",
                      "session": "17",
                      "user": "12"
                    },
                    {
                      "platform": "android",
                      "session": "3",
                      "user": "3"
                    },
                    {
                      "platform": "ios",
                      "session": "1",
                      "user": "1"
                    },
                    {
                      "platform": "iPhone",
                      "session": "3",
                      "user": "1"
                    },
                    {
                      "platform": "Android",
                      "session": "84",
                      "user": "84"
                    },
                    {
                      "platform": "web",
                      "session": "65",
                      "user": "62"
                    }
                  ],
                  "key": [
                    "platform"
                  ]
                },
                "date_key": "2023-11-03",
                "session": "173",
                "user": "163"
              }
            ],
            "key": [
              "date_key"
            ]
          },
          "session": "324.000000",
          "user": "311.000000"
        }
      ],
      key: null,
    };
  }
}
