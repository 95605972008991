import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import tabs, { tabLink } from "../account_settings/tabs";
// import AccountOverview from "./overview";
import MyProfile from "./my_profile";
import CompanyProfile from "./company_profile";
import CompanyUsers from "./users";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function AccountSettings() {
  const { t } = useTranslation();
  const [hashValue, setHashValue] = useState(window.location.hash || tabLink.MY_PROFILE);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleHashChange = () => {
      setHashValue(window.location.hash);
    };

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  return (
    <>
      <div className="ml-2 mr-2" id="project">
        <div className="ds-text-page-title">Account Setting</div>

        <div id="account-setings" className="relative sm:pb-0">
          <div className="mt-4">
            <div className="hidden sm:block">
              <nav className="-mb-px flex space-x-8">
                {tabs.map((tab) => (
                  <a
                    id={tab.name}
                    key={tab.name}
                    href={tab.href}
                    className={classNames(
                      hashValue === tab.href
                        ? 'ds-tab-color'
                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                      'whitespace-nowrap select-none cursor-pointer border-b-2 px-1 pb-2 text-sm font-medium'
                    )}
                    aria-current={tab.current ? 'page' : undefined}
                  >
                    {t(tab.name)}
                  </a>
                ))}
              </nav>

              <div className="py-5 flex-auto">
                <div className="tab-content tab-space">
                  {/* {hashValue === tabLink.OVERVIEW && <AccountOverview/>} */}
                  {hashValue === tabLink.MY_PROFILE && <MyProfile/>}
                  {hashValue === tabLink.COMPANY_PROFILE && <CompanyProfile/>}
                  {hashValue === tabLink.USERS && <CompanyUsers/>}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}
