import {useDispatch} from "react-redux";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";

import {showError} from "components/FlashMessage/flashMessageSlice";
import EmptyBox from "components/EmptyBox";
import AppService from 'services/app';
import {isAppActive} from "utils/app";
import {PermissionDenied} from "errors";
import {PATHS} from "consts";

import tabs, {tabLink} from './tabs';
import AppInfo from "./app_info";
// import AppConversion from "./app_attribution";
import AppSDK from "./app_sdk";
import AppAttribution from "./app_attribution";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function AppProject() {
  const {t} = useTranslation();
  const {appId} = useParams();
  const [hashValue, setHashValue] = useState(window.location.hash || '#info');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [appInfo, setAppInfo] = useState({});

  const getApps = async () => {
    try {
      const result = await AppService.getApp({appId});
      setAppInfo(result);
    } catch (err) {
      if (err instanceof PermissionDenied) {
        navigate(`${PATHS.APP}${PATHS.NOT_FOUND}`);
      } else {
        const {message} = err;
        dispatch(showError({message}));
      }
    }
  };

  useEffect(() => {
    const handleHashChange = () => {
      setHashValue(window.location.hash);
    };

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  useEffect(() => {
    getApps();
  }, [appId]);

  return (
    <>
      {
        !isAppActive(appInfo) ?
          <div className="ml-2 mr-2">
            <EmptyBox title={"App Is Inactive"} subTitle={"Contact to us reactive your app (contact@datasenses.io)"}
                      logoUrl={"https://static.datasenses.io/phone.png"}/>
          </div>

          : <div className="ml-2 mr-2" id="project">
            <div id="project-info" className="relative border-b border-gray-200 pb-5 sm:pb-0">
              <div className="mt-4">
                <div className="hidden sm:block">
                  <nav className="-mb-px flex space-x-8">
                    {tabs.map((tab) => (
                      <a
                        id={tab.name}
                        key={tab.name}
                        href={tab.href}
                        className={classNames(
                          hashValue === tab.href
                            ? 'ds-tab-color'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                          'whitespace-nowrap select-none cursor-pointer border-b-2 px-1 pb-2 text-sm font-medium'
                        )}
                        aria-current={tab.current ? 'page' : undefined}
                      >
                        {t(tab.name)}
                      </a>
                    ))}
                  </nav>

                  <div className="py-5 flex-auto">
                    <div className="tab-content tab-space">
                      {hashValue === tabLink.INFO && <AppInfo appInfo={appInfo}/>}
                      {hashValue === tabLink.CONVERSION && <AppAttribution appInfo={appInfo}/>}
                      {hashValue === tabLink.SDK && <AppSDK appInfo={appInfo}/>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      }
    </>
  );
}
