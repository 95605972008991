import tabs, { tabLink } from "./tabs";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ListLinks from "./list_link";
import UpdateDeeplinkTemplate from "./update_template";


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Deeplink() {
  const { t } = useTranslation();

  const [hashValue, setHashValue] = useState(window.location.hash || tabLink.LINK);

  useEffect(() => {
    const handleHashChange = () => {
      setHashValue(window.location.hash);
    };

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  return (
    <div id="deeplink">
      <div className="ds-text-page-title mt-5">Deeplink</div>

      <div className="mt-5">
        <div className="hidden sm:block">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <a
                id={tab.name}
                key={tab.name}
                href={tab.href}
                className={classNames(
                  hashValue === tab.href
                    ? 'ds-tab-color'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap select-none cursor-pointer border-b-2 px-1 pb-2 text-sm font-medium'
                )}
                aria-current={tab.current ? 'page' : undefined}
              >
                {t(tab.name)}
              </a>
            ))}
          </nav>

          <div className="flex-auto">
            <div className="tab-content tab-space">
              {hashValue === tabLink.LINK && <ListLinks/>}
              {hashValue === tabLink.TEMPLATE && <UpdateDeeplinkTemplate/>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
