import React, { useEffect, useState } from 'react'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Combobox } from '@headlessui/react'
import { useFormContext } from "react-hook-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faAngleDown, faCheck} from '@fortawesome/free-solid-svg-icons';
import {isUndefinedOrEmpty} from "utils";
import {json} from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function TailwindCombobox({
                                           items = [],
                                           name = '',
                                           title = '',
                                           hint = '',
                                           rules = {},
                                           defaultValue = {},
                                           className = ''
                                         }) {
  const [query, setQuery] = useState('')
  const [selectedItem, setSelectedItem] = useState(defaultValue)
  const { register, formState: { errors }, setValue, watch } = useFormContext();
  const value = watch(name);

  useEffect(() => {
    if (!isUndefinedOrEmpty(defaultValue)) {
      setSelectedItem(defaultValue);
      setValue(name, defaultValue.fieldKey);
    } else if (!isUndefinedOrEmpty(value) && !isUndefinedOrEmpty(items) && items.length > 0 && items.find(item => item.fieldKey === value)) {
      setSelectedItem(items.find(item => item.fieldKey === value));
    } else if (!isUndefinedOrEmpty(items) && items.length > 0) {
      setSelectedItem(items[0]);
      setValue(name, items[0].fieldKey);
    }
  }, [JSON.stringify(defaultValue), items, value]);

  useEffect(() => {
    register(name, { required: rules.required });
  }, []);

  const onChangeSelectedItem = (item) => {
    setSelectedItem(item);
    setValue(name, item.fieldKey);
  };

  const displayName = (item) => {
    if (item?.isNoneOption) {
      return "None"
    }
    return item?.name || ""
  }

  const filteredItems =
    query === ''
      ? items
      : items.filter((item) => {
        return item.name.toLowerCase().includes(query.toLowerCase())
      })

  return (
    <Combobox as="div" value={selectedItem} onChange={onChangeSelectedItem} >
      {title && <Combobox.Label className="ds-input-text">{title}</Combobox.Label>}

      <div className={`relative duration-200 ease-in-out ${className}`}>
        <Combobox.Input
          className={classNames(
            `w-full ds-input ${className}`,
            selectedItem?.isNoneOption ? 'text-transparent' : 'ds-input-text',
            `ds-focus`
          )}
          displayValue={displayName}
          readOnly
          onChange={(e) => setQuery(e.target.value)}
        />

        <Combobox.Button className="absolute inset-y-0 right-0 items-center rounded-r-md ml-2 ds-focus">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {!isUndefinedOrEmpty(filteredItems) && (
          <Combobox.Options className={`ds-option ring-1 ring-black max-h-72 ${className}`}>
            {filteredItems.map((item) => (
              <Combobox.Option
                key={item.fieldKey}
                value={item}
                className={({ active }) =>
                classNames(
                    'relative cursor-pointer select-none py-2 pl-3 pr-9 max-h-30',
                    active ? 'ds-hover-color' : '',
                    item.isNoneOption ? 'text-sm font-normal text-gray-300' : 'ds-input-text'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span className={classNames('block truncate', selected)}>
                      {displayName(item)}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active
                        )}
                      >
                        <FontAwesomeIcon className="h-4 w-4" aria-hidden="true" icon={faCheck} />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
      <div className="relative">
        {errors[name] ? <div className='text-sm text-red-700'>{errors[name].message}</div> : <div className='text-sm text-gray-500'>{hint}</div>}
      </div>
    </Combobox>
  )
}
