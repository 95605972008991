import { faSquarePlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EMAIL_REGEX } from "consts";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export default function Input({
  value = [],
  onChange = () => { },
}) {
  const { t } = useTranslation();

  const [isAdding, setIsAdding] = useState(false);
  const [email, setEmail] = useState('');

  const isValidEmail = useMemo(() => {
    return EMAIL_REGEX.test(email)
  }, [email]);

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const remove = () => {
    setIsAdding(false);
    setEmail("");
  };

  const add = () => {
    onChange([...value, email]);
    remove();
  };

  const removeEmail = (email) => {
    const newValue = value.filter((item) => item !== email);
    onChange(newValue);
  };

  return (
    <>
     <div className="table w-80 mt-3">
      {
        value.map((email, index) => (
          <div key={index} className="table-row">
            <div className="table-cell py-2">{email}</div>
            <div className="table-cell py-2">
              <FontAwesomeIcon onClick={() => removeEmail(email)} icon={faTrashAlt} className="text-sm text-red-500 cursor-pointer" />
            </div>
          </div>
        ))
      }
     </div>

      {
        isAdding && (
          <div className="flex justify-start items-center w-full mt-5">
            <input
              className="rounded py-1 px-2 border border-black focus:border-blue-500 focus:outline-none w-80"
              placeholder="Ex. john@doe.com"
              onChange={handleInputChange}
            />

            <div
              onClick={add}
              className={`border w-fit py-1 px-3 rounded ms-3 select-none ${isValidEmail ? 'bg-white cursor-pointer text-blue-700 border-blue-500 hover:bg-blue-100' : 'bg-gray-300 opacity-50 cursor-not-allowed'}`}
            >
              {t("ADD")}
            </div>

            <FontAwesomeIcon onClick={remove} icon={faTrashAlt} className="ms-10 text-red-500 cursor-pointer" />
          </div>
        )
      }
      <div
        onClick={() => setIsAdding(true)}
        className={`py-1 px-3 border w-fit rounded mt-5 select-none ${isAdding ? 'bg-gray-300 opacity-50 cursor-not-allowed' : 'bg-white text-blue-700 border-blue-500 hover:bg-blue-100 cursor-pointer'}`}
      >
        <FontAwesomeIcon icon={faSquarePlus} className="me-2" />
        Add team member
      </div>
    </>
  )
}
