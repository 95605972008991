import {useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useController, useFormContext} from 'react-hook-form';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashCan} from '@fortawesome/free-solid-svg-icons';

import ToggleBox from 'components/ToggleBox';
import FieldFilter from 'components/FieldFilter';

import {areArraysEqual} from 'utils';

import ChartSerivce from 'services/chart';
import {titleCase} from "utils/util";

/**
 * FieldFilterToogleBox is a component used to manage field filters with a toggle box UI.
 * @param {Object} props - The props object.
 * @param {string} props.appId - The ID of the App.
 * @param {string} props.fieldKey - The key of the field.
 * @param {string} props.displayedName - The displayed name of the field.
 * @param {string} props.name - The name of the field.
 * @param {Array} [props.defaultValue=[]] - The default value for the field, defaults to an empty array.
 * @returns {JSX.Element} The JSX representation of the FieldFilterToogleBox component.
 */
export default function FieldFilterToogleBox(
  {
    appId,
    fieldKey,
    displayedName,
    name,
    defaultValue = [],
    className = 'ds-gray-background',
  }) {
  const {t} = useTranslation();

  const {control} = useFormContext();
  const {field} = useController({name, control, defaultValue});

  const [values, setValues] = useState([]);
  const [isEdited, setIsEdited] = useState(false);
  const [options, setOptions] = useState([]);

  const wrapper = useRef(null);

  const handleOnClose = () => {
    if (isEdited) {
      field.onChange(values);
      setIsEdited(false);
    }
  };

  const onFieldChange = (newValues) => {
    if (!areArraysEqual(values, newValues)) {
      setValues(newValues);
      setIsEdited(true);
    }
  };

  const deleteSelected = (e) => {
    e.stopPropagation();
    field.onChange([]);
  }

  const isAll = useMemo(() => {
    return field.value.length === 0
  }, [field.value]);

  const firstSelectedText = useMemo(() => {
    if (field.value.length > 0) {
      return field.value[0];
    }
    return ""
  }, [field.value]);

  const getFieldValue = async () => {
    const result = await ChartSerivce.getFieldValue({appId, fieldKey});
    setOptions(result);
  };

  useEffect(() => {
    getFieldValue();
  }, [appId, fieldKey]);

  const checkData = () => {
    if (options.length === 0) {
      setTimeout(() => wrapper.current.close());
    }
  };

  return (
    <ToggleBox ref={wrapper} onClose={handleOnClose}>
      <ToggleBox.Button>
        <button
          type="button"
          className={`items-center flex flex-row ds-input ds-input-text ${className}`}
          onClick={checkData}
        >
          {titleCase(displayedName)}: <span className='ms-1'>{isAll ? t('ALL') : firstSelectedText} {field.value.length > 1 ? <span
          className='text-sm text-violet-500 rounded-full bg-violet-200 px-1.5 py-0.5'>{field.value.length}</span> : ''}</span>
          {!isAll ? <FontAwesomeIcon icon={faTrashCan} onClick={deleteSelected}
                                     className='ms-2 h-3 w-3 font-extrabold text-slate-500 hover:text-red-500'/> : ''}
        </button>
      </ToggleBox.Button>

      <ToggleBox.Content>
        <div className="max-h-40 overflow-y-auto">
          <FieldFilter
            onChange={onFieldChange}
            value={field.value}
            options={options}
          />
        </div>
      </ToggleBox.Content>
    </ToggleBox>
  );
};
