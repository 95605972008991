import {Header} from "views/landing_page/home/Header";
import {Hero} from "views/landing_page/home/Hero";
import {CallToAction} from "views/landing_page/home/CallToAction";
import {Container} from "views/landing_page/home/Container";
import {DsIconLogo} from "components/DsIcon";

export default function LandingPageAboutUs() {
  return (
    <>
      <Header />
      <main className="pb-16 pt-56 lg:pt-56">
        <Hero/>
      </main>
      <section
        id="get-started-today"
        className="w-full bg-blue-600 py-24"
      >
        <Container className="relative">
          <div className="mx-auto text-center">
            <div>
              <DsIconLogo fill={"white"}/>
            </div>
            <h2 className="lp-text-title-bg-dark">
              Let's talk!
            </h2>
            <p className="mt-4 lp-text-desc-bg-dark">
              We are happy to serve & support your business growth
            </p>
            <div className="mt-10 flex justify-center">
              <a
                className="group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-white text-black hover:text-slate-100 hover:bg-blue-500 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600"
                color="blue" variant="solid" href="/register"><span>Request a demo</span></a>
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}
