import {PARTNERS} from "consts/partner";
import {fieldOptions, integrationStatus, timeType} from "./types";

const getIncludingValue = (value) => {
  switch (value) {
    case 'value_and_revenue':
      return [true, true]
    case 'no_value_and_no_revenue':
      return [false, false]
    case 'value_and_no_revenue':
      return [true, false]
    case 'no_value_and_revenue':
      return [false, true]
  }
}

export const processSaveIntegrationData = (data) => {
  const postbackEvents = []

  if (!!data.inAppEventPostbacks) {
    data.inAppEventPostbacks.map((event) => {
      postbackEvents.push({
        datasensesEvent: event.datasensesEvent,
        partnerEvent: event.partnerEvent,
        usersOrigin: event.usersOrigin,
        including: event.including,
        valueIncluded: getIncludingValue(event.including)[0],
        revenueIncluded: getIncludingValue(event.including)[1],
      })
    })
  }

  if (data.clickThroughType === timeType.Days) {
    data.installClickThroughLookbackDay = data.clickThrough
  }
  if (data.clickThroughType === timeType.Hours) {
    data.installClickThroughLookbackHour = data.clickThrough
  }

  data.status = integrationStatus[data.active]
  data.inAppEventPostbacks = postbackEvents

  return data;
};

export const processDataSourceData = (data) => {
  const parts = data.intervalTime.split('-');
  data.intervalTimeType = parts[1];
  data.intervalTime = parts[0];

  if (data.fields.includes("all")) {
    const fields = []

    fieldOptions[data.partner].map((kv) => {
      if (kv.fieldKey !== "all") {
        fields.push(kv.fieldKey);
      }
    });
    data.fields = fields
  }

  return data
}

export const processSocialMediaDataSourceData = (data) => {
  const parts = data.source.split('-');

  if (data.partner === PARTNERS.APPLE_SEARCH) {
    data.partner = parts[0] + "_" + parts[1]
    data.social_media_options = parts[2]
  } else {
    data.partner = parts[0];
    data.social_media_options = parts[1];
  }

  return data
}


