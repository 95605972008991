import {useDispatch} from 'react-redux';
import {Dialog, Transition} from '@headlessui/react'
import {Fragment, useState} from 'react'
import {useTranslation} from 'react-i18next';
import {FormProvider, useForm} from 'react-hook-form';
import LoadingSpinner from 'components/LoadingSpinner';
import {showError, showInfo} from "components/FlashMessage/flashMessageSlice";

import CompanyService from 'services/company';
import TailwindCombobox from "../../../../components/TailwindCombobox";
import {roleOptions} from "consts/chartConfig";

export default function UpdateUserModal({
                                          email, isOpen = false, closeModal = () => {
  }, reset = () => {
  }
                                        }) {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm();

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);

      await CompanyService.updateRole({email: email, role: data.role});

      dispatch(showInfo({message: t("UPDATE_VALUE_SUCCESSFULLY", {Value: "Role"})}));

      closeModal();

      reset();
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }

    setIsLoading(false);
    reset();
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25"/>
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className="w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Update Role
                </Dialog.Title>

                <FormProvider {...methods}>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Select new role for <strong>{email}</strong>
                    </p>
                  </div>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="flex flex-wrap mt-3">
                      <div className="w-full pr-2 ds-input-text">
                        <label>{t('Role')}</label>

                        <TailwindCombobox
                          name="role"
                          items={roleOptions}
                        />
                      </div>
                    </div>

                    <div className="mt-10 sm:mt-4 sm:flex sm:flex-row-reverse mt-3">
                      <button
                        type="button"
                        className="ds-button-cancel ml-2"
                        onClick={closeModal}
                      >
                        {t('Cancel')}
                      </button>

                      <button
                        type="submit"
                        disabled={isLoading}
                        className="inline-flex items-center w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto"
                      >
                        {isLoading ?
                          <>

                            <LoadingSpinner className="me-3" textColor="text-blue-500"/>
                            {t('LOADING')}
                          </>
                          :
                          t('Update')
                        }
                      </button>
                    </div>
                  </form>
                </FormProvider>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
