import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from 'react-redux';
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faSquarePlus, faTrashCan,} from "@fortawesome/free-solid-svg-icons";
import {Link, useParams} from "react-router-dom";
import {PATHS} from "consts";

import Tooltip from "components/Tooltip";
import ToggleCheckbox from "components/ToggleCheckbox";
import TextInput from "components/TextInput";
import TailwindCombobox from "components/TailwindCombobox";

import './index.scss';

import IntegrationService from "services/integration";
import {processGetIntegrationData} from "./processor";
import {processSaveIntegrationData} from "../../processor";

import {includingOptions, timeType, userFromOptions,} from "../../types.js";
import {showError, showInfo} from "components/FlashMessage/flashMessageSlice";
import MetadataService from "../../../../services/metadata";
import AlertInfo from "components/AlertMessage/AlertInfo";
import {InformationCircleIcon} from "@heroicons/react/20/solid";
import {isUndefinedOrEmpty} from "utils";
import DsListBoxNoNavigate from "components/DsListBoxNoNavigate";
import BackButton from "views/integration/meta_ads/back_button";
import {DsIconTooltip} from "components/DsIcon";
import LoadingSpinner from "components/LoadingSpinner";
import {PARTNER_INTEGRATION_DOCS_URL, PARTNERS} from "consts/partner";

export default function Integration() {
  const appList = useSelector((state) => state.app.appList);
  const [appId, setAppId] = useState(appList.length > 0 ? appList[0].app : null);

  const methods = useForm();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [initValue, setInitValue] = useState(null);
  const [datasensesEvents, setDatasensesEvents] = useState(null);
  const [partnerEvents, setPartnerEvents] = useState(null);

  const active = methods.watch('active');
  const inAppEventPostbacksEnabled = methods.watch('inAppEventPostbacksEnabled');

  const {fields, append, remove} = useFieldArray({
    control: methods.control,
    name: 'inAppEventPostbacks',
  });

  useEffect(() => {
    methods.setValue('clickThroughType', timeType.Days);
  }, []);

  const getEventsList = async () => {
    try {
      const events = await MetadataService.getFieldValues({appId, fieldKey: 'event_name'});
      const eventNames = [];

      events.map((item) => {
        eventNames.push({fieldKey: item, name: item})
      });

      setDatasensesEvents(eventNames)
      setPartnerEvents(eventNames)
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
  }

  const getAppIntegrationTiktok = async () => {
    try {
      const result = await IntegrationService.getAppIntegration({appId, partner: 'tiktok'});

      if (!isUndefinedOrEmpty(appId)) {
        const processedResult = processGetIntegrationData(result)

        const tempValue = [
          {key: 'active', value: processedResult.appIntegrationTiktok.active},
          {key: 'tiktokAppId', value: processedResult.appIntegrationTiktok.tiktokAppId},
          {
            key: 'installViewThroughAttributionEnabled',
            value: processedResult.appIntegrationTiktok.installViewThroughAttributionEnabled
          },
          {
            key: 'reEngagementAttributionEnabled',
            value: processedResult.appIntegrationTiktok.reEngagementAttributionEnabled
          },
          {key: 'inAppEventPostbacksEnabled', value: processedResult.appIntegrationTiktok.inAppEventPostbacksEnabled},
          {key: 'inAppEventPostbacks', value: processedResult.appIntegrationTiktok.inAppEventPostbacks},
        ]

        tempValue.map((kv) => {
          methods.setValue(kv.key, kv.value);
        });

        setInitValue(tempValue);
      }
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
  }

  useEffect(() => {
    if (appId) {
      getAppIntegrationTiktok();
      getEventsList();
    }
  }, [appId]);

  const discardChanges = () => {
    if (initValue === null) {
      return;
    }
    initValue.map((kv) => {
      methods.setValue(kv.key, kv.value);
    });
  }

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const payload = processSaveIntegrationData(data);
      await IntegrationService.saveIntegration(
        {
          appId: appId,
          partner: 'tiktok',
          ...payload
        }
      );
      dispatch(showInfo({message: t("SAVE_VALUE_SUCCESSFULLY", {Value: "Integration with Tiktok"})}));

      const tempValue = [
        {key: 'active', value: data.active},
        {key: 'tiktokAppId', value: data.tiktokAppId},
        {key: 'installViewThroughAttributionEnabled', value: data.installViewThroughAttributionEnabled},
        {key: 'reEngagementAttributionEnabled', value: data.reEngagementAttributionEnabled},
        {key: 'inAppEventPostbacksEnabled', value: data.inAppEventPostbacksEnabled},
        {key: 'inAppEventPostbacks', value: data.inAppEventPostbacks},
      ]
      setInitValue(tempValue);
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
    setIsLoading(false);
  }

  return (
    <div id="intergration-tab" className={"sm:p-3"}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="ds-input-text">
            <div className="ds-text-form-title">Tiktok ads integration</div>

            <div className="ds-text-lighter">
              Set up your integration details for this partner.
              <a href={PARTNER_INTEGRATION_DOCS_URL[PARTNERS.TIKTOK]} target="_blank" rel="noopener noreferrer"
                 className={`ml-1 ds-text-hyperlink`}>
                Learn more
              </a>
            </div>
            <div className={"w-1/3 mt-3"}>
              <DsListBoxNoNavigate listData={appList} onSelectionChange={(a) => {
                setAppId(a.appId)
              }}/>
            </div>
            <div className="flex justify-start items-center w-full mt-3">
              <div className="me-1">Activate partner</div>

              <Tooltip minWidth="380">
                <Tooltip.Label>
                  <div><DsIconTooltip className="h-full w-full text-blue-400"
                                      aria-hidden="true"/>
                  </div>
                </Tooltip.Label>
                <Tooltip.Content>
                  <p>Activate partner to enable attribution and send postbacks.</p>
                </Tooltip.Content>
              </Tooltip>

              <ToggleCheckbox name="active" className="ms-5"/>
            </div>
            <hr className="h-px mt-3 bg-gray-200 border-0"/>

            <div className={active ? 'visible' : 'invisible h-0'}>
              <div className="ds-text-form-title mt-3">General settings</div>

              <div className="flex justify-start items-center w-full mt-1">
                <div className="mt-1.5">Tiktok App Id</div>
              </div>
              <AlertInfo
                className={"mt-2"}
                message={
                  "If you don't have a Tiktok App ID, you can create one by following the instructions in the Tiktok Setup App docs."
                }
                link={"https://business-api.tiktok.com/portal/docs?rid=5ipocbxyw8v&id=1771101111730178"}
              />
              <TextInput
                name="tiktokAppId"
                className="mt-2 w-4/12"
              />

              <div className="flex justify-start items-center w-full mt-10">
                <div className="me-2">Install view-through attribution</div>

                <Tooltip minWidth="380">
                  <Tooltip.Label>
                    <div><DsIconTooltip className="h-full w-full text-blue-400"
                                        aria-hidden="true"/>
                    </div>
                  </Tooltip.Label>
                  <Tooltip.Content>
                    <p>Turn on to start attributing installs by users who view your ad, but don't click it</p>
                  </Tooltip.Content>
                </Tooltip>

                <ToggleCheckbox name="installViewThroughAttributionEnabled" className="ms-10"/>
              </div>

              <div className="flex justify-start items-center w-full mt-5">
                <div className="me-2">Re-engagement attribution</div>

                <Tooltip minWidth="380">
                  <Tooltip.Label>
                    <div><DsIconTooltip className="h-full w-full text-blue-400"
                                        aria-hidden="true"/>
                    </div>
                  </Tooltip.Label>
                  <Tooltip.Content>
                    <p>Turn on to start attributing re-engagements of your existing users</p>
                  </Tooltip.Content>
                </Tooltip>

                <ToggleCheckbox name="reEngagementAttributionEnabled" className="ms-10"/>
              </div>

              <hr className="h-px my-6 bg-gray-200 border-0"/>

              <div className="flex justify-start items-center w-full mt-5">
                <div className="me-2">In-app event postbacks</div>

                <Tooltip minWidth="380">
                  <Tooltip.Label>
                    <div><DsIconTooltip className="h-full w-full text-blue-400"
                                        aria-hidden="true"/>
                    </div>
                  </Tooltip.Label>
                  <Tooltip.Content>
                    <p>To use Tiktok ads optimization capabilities such as app event optimization, value-based
                      optimization, and dynamic ads, it's crucial to map SDK events to the corresponding Tiktok ads
                      event
                      ID. Custom events are not available for optimization.</p>
                  </Tooltip.Content>
                </Tooltip>

                <ToggleCheckbox name="inAppEventPostbacksEnabled" className="ms-10"/>
              </div>

              <div className={(inAppEventPostbacksEnabled && active) ? 'visible' : 'invisible h-0'}>
                <>
                  <div className="text-xs mt-2">Set up the events and data included in postbacks sent to this partner
                  </div>

                  <div className="grid grid-cols-5 gap-4 mt-5">
                    <div>DataSenses event</div>
                    <div>mapped to partner event</div>
                    <div>for users from</div>
                    <div>including</div>
                    <div></div>
                  </div>
                  {
                    fields.map((item, index) => (
                      <div key={item.id} className="grid grid-cols-5 gap-4 mt-2">
                        <div>
                          <TailwindCombobox
                            name={`inAppEventPostbacks.${index}.datasensesEvent`}
                            items={datasensesEvents}
                          />
                        </div>
                        <div>
                          <TailwindCombobox
                            name={`inAppEventPostbacks.${index}.partnerEvent`}
                            items={partnerEvents}/>
                        </div>
                        <div>
                          <TailwindCombobox name={`inAppEventPostbacks.${index}.usersOrigin`}
                                            items={userFromOptions}/>
                        </div>
                        <div>
                          <TailwindCombobox name={`inAppEventPostbacks.${index}.including`}
                                            items={includingOptions}/>
                        </div>
                        <div>
                          <FontAwesomeIcon className="text-red-500 cursor-pointer mt-2" onClick={() => remove(index)}
                                           icon={faTrashCan}/>
                        </div>
                      </div>
                    ))
                  }
                  <div
                    onClick={append}
                    className=" cursor-pointer ds-button w-fit mt-4"
                  >
                    <FontAwesomeIcon icon={faSquarePlus} className="me-2"/>
                    Add Events
                  </div>
                </>
              </div>
              <hr className="h-px mt-4 mb-3 bg-gray-200 border-0"/>
            </div>
          </div>

          <footer>
            <div className="flex justify-between items-center px-4 w-full">
              <BackButton />

              <div className="flex justify-end items-center mb-1">
                <button
                  type="button"
                  className="ds-button-cancel mr-2"
                  onClick={discardChanges}
                >
                  {t('DISCARD_CHANGES')}
                </button>

                <button
                  type="submit"
                  className={`${isLoading && "cursor-not-allowed"} ds-button inline-flex focus:outline-none`}
                >
                  {isLoading ?
                    <>
                      <LoadingSpinner className="mt-0.5 me-2"/>
                      {t('LOADING')}
                    </>
                    :
                    t('SAVE_INTEGRATION')
                  }
                </button>
              </div>
            </div>
          </footer>
        </form>
      </FormProvider>
    </div>
  );
}
