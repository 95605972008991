export const darkIndigo = '#220D4E';
export const white = '#FFFFFF';
export const softPeach = '#ECEDF2';
export const amethystSmoke = '#A397B4';
export const azul = '#2563EB';
export const porcelain = '#EFF2F3';
export const mulledWine = '#4E4562';
export const bluish = '#2976BB';
export const congressBlue = '#02478E';
export const brandeisBlue = '#0070FF';
export const ultramarineBlue = '#516cf5';
export const antiFlashWhite = '#f3f3f7';
export const independence = '#43425D';
export const blackCoral = '#565a71';
export const shadow = '#0000000A';
export const babyPowder = '#FFFFFC';
export const geranium = '#DE1E25';
export const linkWater = '#D9E4F5';
export const lightGray = '#d2d5d8';
export const brightGray = '#EDEDE9';
export const chineseSilver = '#ccc';
export const iron = '#d4d9dd';
export const riverBed = '#434C59';
export const alabaster = '#FAFAFA';
export const whiteSmoke = '#F5F5F5';
export const greenWhite = '#e9e9ed';
export const gravel = '#43474f';
export const romance = '#f3f6fC';
export const snowDrift = '#F7FAF7';
export const dogwoodRose = '#E0216A';
export const blueViolet = '#8A2BE2';
export const fuelYellow = '#ECA927';
export const greenTeal = '#0CB577';
export const dsMainBackground = '#FFFFFF';
export const dsBorderBlueColor = '#91CAFF';
export const dsBorderGrayColor = 'rgb(240, 240, 240)';
export const dsBorderDashedGrayColor = 'rgb(177, 175, 175)';
export const dsButtonBlueColor = '#3E79F7';
export const dsHoverColor = '#F5F3FF';
export const dsTextColor = '#455560';
export const dsVioletColor = '#8b5cf6';
export const dsVioletHoverColor = '#c4b5fd';
export const dsVioletFocusColor = '#6d28d9';
export const dsVioletBackgroundColor = '#f5f3ff';
export const dsGrayColor = '#FAFAFA';
export const dsSidePanelTextColor = '#5A6872';
export const dsTableBorderColor = '#e0e0e0';
