import {useEffect, useState} from "react";
import deeplink from "services/deeplink";
import {useTranslation} from "react-i18next";
import TailwindComboboxWithoutForm from "components/TailwindCombobox/TailwindComboboxWithoutForm";
import {DsIconDelete, DsIconEdit} from "components/DsIcon";
import TailwindMenuDialog from "components/TailwindMenuDialog";
import {PATHS} from "consts";
import {Link} from "react-router-dom";
import {faSquarePlus} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteLinkModal from "../delete_link";


export default function ListLinks() {
  const { t } = useTranslation();

  const [listTemplates, setListTemplates] = useState([]);
  const [deepLinkList, setDeepLinkList] = useState([]);
  const [templateId, setTemplateId] = useState('');
  const [deletedLinkId, setDeletedLinkId] = useState('');
  const [deletedLinkName, setDeletedLinkName] = useState('');
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const getTemplates = async () => {
    try {
        const result = await deeplink.getListTemplates();
        setListTemplates(result.map((t) => ({
          "value": t.id,
          "text": t.name,
        })))
        setTemplateId(result[0].id)
    } catch (e) {
    }
  }

  const getLinksByTemplateId = async () => {
    try {
      const result = await deeplink.getDeepLinkList(templateId);
      setDeepLinkList(result)
    } catch (e) {

    }
  }

  const handleTemplateChanged = (fieldValue) => {
    setTemplateId(fieldValue);
    getLinksByTemplateId();
  }

  useEffect(() => {
    if (listTemplates.length > 0) {
      getLinksByTemplateId(listTemplates[0].value);
    }
  }, [templateId]);

  useEffect( () => {
    getTemplates()
  }, []);

  const handleEdit = (templateId, linkId) => {
    window.location.href = `${PATHS.APP}${PATHS.DEEPLINK}/${templateId}/${linkId}`
  }

  const handleDelete = (link) => {
    setDeletedLinkId(link.url_short);
    setDeletedLinkName(link.name);
    setIsDeleteOpen(true);
  }


  return (
    <div id="list-deeplink">
      <div className="gap-4 bg-white mt-7 rounded-lg shadown-md sm:p-3 border border-gray-100">
        <div className="p-4">
          <div className="flex justify-between items-center">
            <div className="items-center w-2/5">
              <span className="text-sm font-semibold text-gray-900">Template</span>

              <TailwindComboboxWithoutForm
                items={listTemplates}
                onChange={handleTemplateChanged}
              />
            </div>
            <div className="flex items-center justify-end">
              <Link
                type="submit"
                className="ds-button"
                to={`${PATHS.APP}${PATHS.DEEPLINK}${PATHS.DEEPLINK_CREATE}`}
              >
                <FontAwesomeIcon className="me-2" icon={faSquarePlus}/>
                Create link
              </Link>
            </div>
          </div>

          <div className="relative ds-input-text mt-4">
            <table className="min-w-full divide-y divide-gray-300 table-auto">
              <thead>
                <tr>
                  <th scope="col" className="py-3 pl-4 pr-3 text-left sm:pl-0">
                    {t('Link Name')}
                  </th>
                  <th scope="col" className="px-3 py-3.5 pl-3.5 pr-3 text-left sm:pl-0">
                    {t('Short URL')}
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left">
                    {t('Campaign')}
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left">
                    {t('Created At')}
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left">
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
              {deepLinkList && deepLinkList.map((link, index) => (
                <tr key={index}>
                  <td className="whitespace-normal py-4 cursor-pointer font-bold ds-text-color"
                  onClick={() => handleEdit(`${link.template_id}`, `${link.url_short}`)}
                  >{link.name}</td>
                  <td className="whitespace-normal px-3 py-4 pl-4 pr-3 sm:pl-0">
                    {link.full_url_short}
                  </td>
                  <td
                    className="whitespace-normal px-3 py-4">{`utm_source=${link.utm_source}&utm_campaign=${link.utm_campaign}&utm_medium=${link.utm_medium}`}</td>
                  <td className="whitespace-normal px-3 py-4">{link.created_at}</td>
                  <td>
                    <TailwindMenuDialog
                      items={[
                        {
                          Name: "Edit",
                          Icon: <DsIconEdit />,

                          // Action: () => handleEdit(`${link.template_id}`, `${link.url_short}`),
                          Link: `${PATHS.APP}${PATHS.DEEPLINK}/${link.template_id}/${link.url_short}`,
                          ActionType: "link"
                        },
                        {
                          Name: "Delete",
                          Icon: <DsIconDelete/>,
                          Action: () => handleDelete(link),
                          ActionType: "button"
                        },
                      ]}
                    />
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <DeleteLinkModal
        templateId={templateId}
        linkId={deletedLinkId}
        name={deletedLinkName}
        isOpen={isDeleteOpen}
        closeModal={() => setIsDeleteOpen(false)}
        reset={getLinksByTemplateId}
      />
    </div>
  )
}
