import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AppService from "services/app";


export const appSlice = createSlice({
  name: 'app',
  initialState: {
    appList: [],
  },
  reducers: {
    setApp: (state, action) => {
      state.appList = action.payload;
    },
  },
});

export const { setApp } = appSlice.actions;

export const getApps = createAsyncThunk(
  'app/getApp',
  async (_, thunkAPI) => {
    const result = await AppService.listApps();

    thunkAPI.dispatch(setApp(result));
  }
);



export default appSlice.reducer;
