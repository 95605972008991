import TailwindCombobox from "components/TailwindCombobox";
import TextInput from "components/TextInput";

export default function PropertyFilter({
                                         index = "",
                                         name,
                                         propertyOptions = [],
                                         operatorOptions = [],
                                       }) {
  return (
    <div key={index} className={`flex items-center space-x-2`}>

      <TailwindCombobox
        name={`${name}.name`}
        items={propertyOptions}
      />

      <TailwindCombobox
        name={`${name}.operator`}
        items={operatorOptions}
      />

      <TextInput
        name={`${name}.value`}
      />
    </div>
  )
}
