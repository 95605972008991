import {useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

import {showError, showInfo} from "components/FlashMessage/flashMessageSlice";

import AppService from "services/app";


export default function AppAttribution({appInfo}) {
  const [openNewUser, setOpenNewUser] = useState(false)
  const dispatch = useDispatch();
  const [attributionClickLookbackDay, setAttributionClickLookbackDay] = useState(appInfo.attributionClickLookbackDay);
  const navigate = useNavigate();
  const onEditNewUser = () => {
    setOpenNewUser(true)
  }

  const onDiscardChangeNewUser = () => {
    setOpenNewUser(false)
  }

  const onSaveNewUser = async () => {
    try {
      const result = await AppService.updateApp(appInfo.appId, {
        attributionClickLookbackDay: attributionClickLookbackDay,
      });
      dispatch(showInfo({"message": "Save successfully"}));
      setOpenNewUser(false);
    } catch (error) {
      dispatch(showError({"message": "Something went wrong"}))
    }
  }

  return (
    <div>
      <div className="ds-block mt-5">
        <div className="px-4 py-6 sm:px-6">
          <h3 className="text-base font-semibold leading-7 text-gray-900">New users: Attribution</h3>
          <p className="text-sm text-gray-900">Choose how to attribute different engagements from new users for this
            app. These settings will be applied by default to all links for this app.
          </p>
        </div>
        <div className="border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">Clicks</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex justify-end">
                <button onClick={onEditNewUser}
                        className="font-semibold custom-button-blue text-white rounded-md px-3 py-1">Edit
                </button>
              </dd>
              <dt className="text-sm font-medium text-gray-900 pl-5">Matching type</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">Device Matching</dd>
              <dt className="text-sm font-medium text-gray-900 pl-5">Attribution window (days)</dt>
              {openNewUser ?
                <dd
                  className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <input
                    type="number"
                    name="attributionClickLookbackDay"
                    id="attributionClickLookbackDay"
                    className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="fill a number between 1 and 30"
                    min="1"
                    max="30"
                    onChange={(e) => setAttributionClickLookbackDay((e.target.value))}
                  />
                </dd>
                : <dd
                  className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{attributionClickLookbackDay}</dd>
              }
              {openNewUser ?
                <>
                  <dt className="text-sm font-medium text-gray-900"></dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex justify-end">
                    <button onClick={onDiscardChangeNewUser}
                            className="font-semibold custom-button-gray text-black rounded-md px-3 py-1">Discard
                      change
                    </button>
                    <button onClick={onSaveNewUser}
                            className="font-semibold custom-button-blue text-white rounded-md px-3 py-1 ml-5">Save
                    </button>
                  </dd>
                </>
                : ""
              }
            </div>
          </dl>
        </div>
      </div>

    </div>
  )
}
