import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {PLATFORM} from "consts";
import {useDispatch} from "react-redux";

import {showError} from "components/FlashMessage/flashMessageSlice";

import AppService from 'services/app';
import {SDKS} from "consts/app";

export default function AppSDK({appInfo}) {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const downloadCredential = async () => {
    try {
      const result = await AppService.downloadCredential(appInfo.appId);
    } catch (err) {
      const {message} = err;
      dispatch(showError({message}));
    }
  }

  return (
    <>
      <div>
        <div className="ds-block mt-5">
          <div className="px-4 py-6 sm:px-6">
            <h3 className="text-base font-semibold leading-7 text-gray-900">SDK Integration Info</h3>
          </div>
          <div className="border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900">Platform</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{appInfo.platform}</dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900">SDK dev key</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{appInfo.sdkDevKey}</dd>
              </div>
              {
                appInfo.platform === PLATFORM.ANDROID ?
                  <>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-900">Package name</dt>
                      <dd
                        className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{appInfo.sdkFirebasePackageName}</dd>
                    </div>
                  </>
                  : appInfo.platform === PLATFORM.IOS ?
                    <>
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900">Bundle id</dt>
                        <dd
                          className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{appInfo.sdkFirebaseBundleId}</dd>
                      </div>
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900">AppStore id</dt>
                        <dd
                          className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{appInfo.sdkFirebaseAppstoreId}</dd>
                      </div>
                    </>
                    : ""
              }
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900">Credential file</dt>
                <dd
                  className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {appInfo.platform === PLATFORM.ANDROID ?
                    <button type="button" onClick={downloadCredential}
                            className="inline-block border-b border-transparent text-blue-500 hover:border-blue-500 hover:text-blue-700 transition duration-300">
                      <span className="mr-2">google-services.json</span>
                      <FontAwesomeIcon icon={faDownload}/>
                    </button>
                    : appInfo.platform === PLATFORM.IOS ?
                      <button onClick={downloadCredential} type="button"
                              className="inline-block border-b border-transparent text-blue-500 hover:border-blue-500 hover:text-blue-700 transition duration-300">
                        <span className="mr-2">GoogleService-Info.plist</span>
                        <FontAwesomeIcon icon={faDownload}/>
                      </button>
                      : "Contact us to get credential file"
                  }
                </dd>
              </div>
            </dl>
          </div>
        </div>

      </div>
      <div className="mt-6 px-4 sm:px-0">
        <div className="mt-4 border-t border-gray-100">
          <ul
            role="list"
            className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-3"
          >
            {SDKS.map((sdk, index) => (
              <li
                key={index}
                className="col-span-1 divide-y divide-gray-200  ds-block"
              >
                <div className="flex w-full items-center justify-between space-x-6 p-6">
                  <div className="flex-1 truncate">
                    <div className="flex items-center space-x-3">
                      <h3 className="truncate text-sm font-medium text-gray-900">
                        {sdk.name}
                      </h3>
                    </div>
                    <p className="mt-1 truncate text-sm text-gray-500">
                      {sdk.title}
                    </p>
                  </div>
                  {/*<img*/}
                  {/*  className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300"*/}
                  {/*  src={sdk.imageUrl}*/}
                  {/*  alt=""*/}
                  {/*/>*/}
                  <div className="h-8 w-8">
                    <sdk.logo/>
                  </div>
                </div>
                <div>
                  <div className="-mt-px flex divide-x divide-gray-200">
                    <div className="flex w-0 flex-1">
                      <a
                        href={sdk.href}
                        className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                      >
                        <FontAwesomeIcon
                          icon={faUpRightFromSquare}
                          className="h-4 w-4 text-gray-400"
                          aria-hidden="true"
                        />
                        {t("READ")}
                      </a>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  )
}
