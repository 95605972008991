import {Route, Routes} from 'react-router-dom';
import {PATHS} from "consts";

import NotFound from "components/NotFound";
import Homepage from "views/homepage";
import Overview from 'views/overview';
import Login from 'views/login';
import Invite from 'views/invite';
import EmailVerify from 'views/email_verify';
import RequestDemo from 'views/request_demo';
import CreateAccount from "views/create_account";
import AppProject from "views/app_project";
import TableAnalysis from "views/table_analysis";
import Segments from "views/segments/segments";
import FindPeople from "views/segments/find_people";
import SegmentDetail from "views/segments/segment_detail";
import Funnel from "views/analytics/funnel";
import Cohort from "views/analytics/cohort";
import MarketingSuccess from 'views/marketing_success';
import GoogleAdsIntegration from 'views/integration/google_ads';
import MetaAdsIntegration from 'views/integration/meta_ads';
import PartnerMarketplace from "views/partner_marketplace";
import LandingHome from "views/landing_page/home";
import UpdateDeeplink from 'views/deeplink/update_link';
import AppEmpty from 'views/app_empty';
import DashboardList from 'views/dashboard_list';
import Mock from "views/mock";
import ChartList from 'views/chart_list';
import AccountSettings from "views/account_settings";
import UserAdd from "views/account_settings/users/user_add";
import ReportList from 'views/report_list';
import PrivacyEnglish from "views/legal/privacy/english";
import PrivacyVietnamese from "views/legal/privacy/vietnamese";
import MyExpenseDiaryEn from "views/legal/privacy/myExpenseDiaryEn";
import MyExpenseDiaryVi from "views/legal/privacy/myExpenseDiaryVi";
import PaymentReturn from 'views/payment_return';
import LandingPageMeasurement from "views/landing_page/measurement";
import LandingPagePricing from "views/landing_page/pricing";
import LandingPageAnalytics from "views/landing_page/analytics";
import LandingPageFraud from "views/landing_page/fraud";
import LandingPageRecommendation from "views/landing_page/recommendation";
import LandingPageAboutUs from "views/landing_page/aboutus";
import LandingPageContact from "views/landing_page/contact";
import Deeplink from "views/deeplink";

import './App.scss';
import SkadnetworkOverview from "views/skadnetwork/overview";
import TikTokAdsIntegration from "views/integration/tiktok_ads";
import CreateDeeplinkTemplate from "views/deeplink/create_template";
import CreateDeeplink from "views/deeplink/create_link";
import SkadnetworkStudio from "views/skadnetwork/skan_studio";

import Recommendation from "views/recommendation";
import AppleSearchAdsIntegration from "views/integration/apple_search_ads";
import MockProduct from "views/mock/mock_product";
import ShopMetric from "views/landing_page/shopmetric";
import HaravanIntegration from "views/integration/haravan";
import TiktokShopIntegration from "views/integration/tiktok_shop";
import ShopifyIntegration from "views/integration/shopify";
import LazadaIntegration from "views/integration/lazada";
import ShopeeIntegration from "views/integration/shopee";
import EventsList from "views/events_list";
import SkanEmpty from "views/skadnetwork/empty";
import FraudContact from "views/fraud/contact";
import Dashboard from "views/dashboard_list/dashboard_detail";
import EventsAnalysis from "views/analytics/events";
import GrowthAnalytics from 'views/landing_page/growth analytic';
import AnalyticDashboard from "views/roi";
import TermOfService from "views/legal/privacy/TermOfService";

function App() {

  return (
    <Routes>
      <Route path={PATHS.APP} element={<Homepage/>}>
        <Route path={`${PATHS.APP_EMPTY}`} element={<AppEmpty/>}/>
        <Route path={`${PATHS.APP}${PATHS.NOT_FOUND}`} element={<NotFound/>}/>
        <Route path={`${PATHS.APP}/:appId`} element={<AppProject/>}/>
        <Route path={`${PATHS.APP}/:appId${PATHS.OVERVIEW}`} element={<Overview/>}/>
        {/*<Route path={`${PATHS.APP}/:appId${PATHS.FRAUD}`} element={<Fraud/>}/>*/}
        <Route path={`${PATHS.APP}/:appId${PATHS.FRAUD}`} element={<FraudContact/>}/>
        <Route path={`${PATHS.APP}/:appId${PATHS.OVERVIEW}/:dashboardId`} element={<Overview/>}/>
        <Route path={`${PATHS.APP}/:appId${PATHS.OVERVIEW}/:dashboardId`} element={<Overview/>}/>

        <Route path={`${PATHS.APP}${PATHS.PARTNER_MARKETPLACE}`} element={<PartnerMarketplace/>}/>
        <Route path={`${PATHS.APP}${PATHS.INTEGRATION}/google`} element={<GoogleAdsIntegration/>}/>
        <Route path={`${PATHS.APP}${PATHS.INTEGRATION}/meta`} element={<MetaAdsIntegration/>}/>
        <Route path={`${PATHS.APP}${PATHS.INTEGRATION}/tiktok`} element={<TikTokAdsIntegration/>}/>
        <Route path={`${PATHS.APP}${PATHS.INTEGRATION}/apple_search`} element={<AppleSearchAdsIntegration/>}/>
        <Route path={`${PATHS.APP}${PATHS.INTEGRATION}/haravan`} element={<HaravanIntegration/>}/>
        <Route path={`${PATHS.APP}${PATHS.INTEGRATION}/tiktok-shop`} element={<TiktokShopIntegration/>}/>
        <Route path={`${PATHS.APP}${PATHS.INTEGRATION}/shopify`} element={<ShopifyIntegration/>}/>
        <Route path={`${PATHS.APP}${PATHS.INTEGRATION}/lazada`} element={<LazadaIntegration/>}/>
        <Route path={`${PATHS.APP}${PATHS.INTEGRATION}/shopee`} element={<ShopeeIntegration/>}/>

        <Route path={`${PATHS.APP}/:appId${PATHS.ROI}`} element={<AnalyticDashboard/>}/>
        <Route path={`${PATHS.APP}/:appId${PATHS.ANALYTIC_DASHBOARD}`} element={<AnalyticDashboard/>}/>


        <Route path={`${PATHS.APP}${PATHS.DASHBOARD}`} element={<DashboardList/>}/>
        {/*<Route path={`${PATHS.APP}/:appId${PATHS.DASHBOARD}/:dashboardId`} element={<Dashboard/>}/>*/}
        <Route path={`${PATHS.APP}${PATHS.DASHBOARD}/:dashboardId`} element={<Dashboard/>}/>
        <Route path={`${PATHS.APP}/:appId${PATHS.CHART}`} element={<ChartList/>}/>
        <Route path={`${PATHS.APP}/:appId${PATHS.EVENTS}`} element={<EventsAnalysis/>}/>
        <Route path={`${PATHS.APP}/:appId${PATHS.FUNNEL}`} element={<Funnel/>}/>
        <Route path={`${PATHS.APP}/:appId${PATHS.FUNNEL}/:dashboardId`} element={<Funnel/>}/>
        <Route path={`${PATHS.APP}/:appId${PATHS.COHORT}`} element={<Cohort/>}/>
        <Route path={`${PATHS.APP}/:appId${PATHS.COHORT}/:dashboardId`} element={<Cohort/>}/>

        <Route path={`${PATHS.APP}/:appId${PATHS.ALL_REPORTS}`} element={<ReportList/>}/>
        <Route path={`${PATHS.APP}/:appId${PATHS.ALL_REPORTS}/:reportId`} element={<TableAnalysis/>}/>
        <Route path={`${PATHS.APP}/${PATHS.TABLE_ANALYTIC}`} element={<TableAnalysis/>}/>

        <Route path={`${PATHS.ACCOUNT_SETTINGS}`} element={<AccountSettings/>}/>
        <Route path={`${PATHS.ACCOUNT_SETTINGS}${PATHS.USER}`} element={<UserAdd/>}/>
        <Route path={`${PATHS.ACCOUNT_SETTINGS}${PATHS.USER}/:appId`} element={<UserAdd/>}/>

        <Route path={`${PATHS.APP}/:appId${PATHS.SEGMENTS}`} element={<Segments/>}/>
        <Route path={`${PATHS.APP}/:appId${PATHS.FIND_PEOPLE}`} element={<FindPeople/>}/>
        <Route path={`${PATHS.APP}/:appId${PATHS.FIND_PEOPLE}/:segmentId`} element={<FindPeople/>}/>
        <Route path={`${PATHS.APP}/:appId${PATHS.SEGMENT_DETAIL}/:segmentId`} element={<SegmentDetail/>}/>
        <Route path={`${PATHS.APP}${PATHS.DEEPLINK}${PATHS.DEEPLINK_CREATE}`} element={<CreateDeeplink/>}/>
        <Route path={`${PATHS.APP}${PATHS.DEEPLINK}/:templateId/:linkId`} element={<UpdateDeeplink/>}/>
        <Route path={`${PATHS.APP}${PATHS.DEEPLINK}`} element={<Deeplink/>}/>

        <Route path={`${PATHS.APP}${PATHS.DEEPLINK_TEMPLATE}`} element={<CreateDeeplinkTemplate/>}/>

        <Route path={`${PATHS.APP}/:appId${PATHS.SKADNETWORK_OVERVIEW}`} element={<SkadnetworkOverview/>}/>
        <Route path={`${PATHS.APP}/:appId${PATHS.SKADNETWORK_STUDIO}`} element={<SkadnetworkStudio/>}/>
        <Route path={`${PATHS.APP}/empty${PATHS.SKADNETWORK}`} element={<SkanEmpty/>}/>
        <Route path={`${PATHS.APP}/:appId${PATHS.EVENTS_LIST}`} element={<EventsList/>}/>
        <Route path={`${PATHS.APP}${PATHS.MOCK}`} element={<Mock/>}/>
        <Route path={`${PATHS.APP}${PATHS.RECOMMENDATION}`} element={<Recommendation/>}/>
      </Route>
      <Route path={PATHS.MARKETING_SUCCESS} element={<MarketingSuccess/>}/>
      <Route path={PATHS.PAYMENT_RETURN} element={<PaymentReturn/>}/>
      <Route path={PATHS.EMAIL_VERIFY} element={<EmailVerify/>}/>
      <Route path={PATHS.LOGIN} element={<Login/>}/>
      <Route path={PATHS.REQUEST_DEMO} element={<RequestDemo/>}/>
      <Route path={PATHS.CREATE_ACCOUNT} element={<CreateAccount/>}/>
      <Route path={PATHS.HOMEPAGE} element={<LandingHome/>}/>
      <Route path={PATHS.MEASUREMENT} element={<LandingPageMeasurement/>}/>
      <Route path={PATHS.PRICING} element={<LandingPagePricing/>}/>
      <Route path={PATHS.ANALYTICS} element={<LandingPageAnalytics/>}/>
      <Route path={PATHS.LP_FRAUD} element={<LandingPageFraud/>}/>
      <Route path={PATHS.LANDING_PAGE_RECOMMENDATION} element={<LandingPageRecommendation/>}/>
      <Route path={PATHS.ABOUT_US} element={<LandingPageAboutUs/>}/>
      <Route path={PATHS.CONTACT} element={<LandingPageContact/>}/>
      <Route path={PATHS.PRIVACY_ENGLISH} element={<PrivacyEnglish/>}/>
      <Route path={PATHS.PRIVACY_VIETNAMESE} element={<PrivacyVietnamese/>}/>
      <Route path={PATHS.MY_EXPENSE_DIARY_PRIVACY_ENGLISH} element={<MyExpenseDiaryEn/>}/>
      <Route path={PATHS.MY_EXPENSE_DIARY_VIETNAMESE} element={<MyExpenseDiaryVi/>}/>
      <Route path={`${PATHS.JOIN_INVITE}/:token`} element={<Invite/>}/>

      <Route path={`${PATHS.MOCK}`} element={<Mock/>}/>
      <Route path={`${PATHS.MOCK}/product/:productId`} element={<MockProduct/>}/>
      <Route path={`${PATHS.SHOP_METRIC}`} element={<ShopMetric/>}/>
      <Route path={`${PATHS.GROWTH}`} element={<GrowthAnalytics/>}/>
      <Route path={`${PATHS.TERM_OF_SERVICE}`} element={<TermOfService/>}/>

    </Routes>
  );
}

export default App;
