import moment from 'moment';
import { useFormContext, useController } from 'react-hook-form';
import { DATE_FORMAT } from 'consts';

import Input from './WithoutForm';

/**
 * MultiFilterBar component for managing multiple filters.
 * @param {Object} props - The props object.
 * @param {string} props.name - The name of the multi-filter bar.
 * @param {Object} [props.defaultValue] - The default value for the multi-filter bar.
 * @param {Object[]} props.defaultValue.filter - The default filter configuration.
 * @param {string} props.defaultValue.filter[].name - The name of the filter.
 * @param {string[]} props.defaultValue.filter[].value - The value of the filter.
 * @param {Object[]} [props.defaultValue.show=[]] - The default show configuration.
 * @returns {JSX.Element} A React component representing the multi-filter bar.
 */
export default function MultiFilterBar({
  name,
  defaultValue={
    filter: [
      {
        name: 'Date',
        fieldKey: 'date_key',
        fieldType:	'Date',
        value: [
          moment().subtract(2, 'days').format(DATE_FORMAT),
          moment().add(2, 'days').format(DATE_FORMAT)
        ]
      },
    ],
    show: [

    ]
  }
}) {
  const { control } = useFormContext();
  const { field } = useController({name, control, defaultValue});

  const onChange = (value) => {
    field.onChange(value);
  };

  return (
    <Input onChange={onChange} value={field.value} />
  );
}
