import UserBox from "./user_box";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";


export default function UserAdd() {
  const [userInfo, setUserInfo] = useState({})
  const { appId } = useParams()

  useEffect(() => {

  }, []);

  return (
    <>
      <div className="space-y-10 divide-y divide-gray-900/10">
        <div className="grid grid-cols-1 gap-x-8 gap-y-8">
          <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
            <div className="px-4 py-6 sm:p-8">
              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 mb-8">
                <h1 className="text-lg font-bold leading-6 text-gray-900">User details</h1>
              </div>
              <UserBox userInfo={userInfo} />
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
