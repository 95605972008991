import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useTranslation} from 'react-i18next';
import {PATHS} from "consts";
import {showError, showInfo} from "components/FlashMessage/flashMessageSlice";
import TailwindMenuDialog from "components/TailwindMenuDialog";
import {DsIconDelete, DsIconEdit} from "components/DsIcon";
import DataSourceService from "services/data_source";
import {useForm} from "react-hook-form";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faSquarePlus} from "@fortawesome/free-solid-svg-icons";
import CreateDataSource from "./create_data_source";
import UpdateDataSource from "./update_data_source";
import {dataSourceDestination, dataSourceIntervalTime, dataSourceStatus} from "../../types";
import './index.scss';
import BackButton from "views/integration/meta_ads/back_button";


export default function ListDataSources() {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const methods = useForm();

  const [isCreateDataSourceOpen, setIsCreateDataSourceOpen] = useState(false);
  const [isUpdateDataSourceOpen, setIsUpdateDataSourceOpen] = useState(false);
  const [dataSourceList, setDataSourceList] = useState([]);
  const [updatedId, setUpdatedId] = useState('');
  const partner = 'shopify';


  const listDataSources = async () => {
    try {
      const dataSources = await DataSourceService.listDataSources({partner});

      setDataSourceList(dataSources);
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
  };

  useEffect(() => {
    listDataSources();
  }, [partner]);

  const handleUpdate = async (dataSourceId) => {
    setIsUpdateDataSourceOpen(true);
    setUpdatedId(dataSourceId);
  }

  const handleDelete = async (partner, dataSourceId) => {
    try {
      await DataSourceService.deleteDataSource({partner, dataSourceId});
      dispatch(showInfo({ message: t("DELETE_VALUE_SUCCESSFULLY", { Value: "data source" }) }));
      listDataSources();
    } catch (error) {
      const { message } = error;
      dispatch(showError({ message }));
    }
  }


  return (
    <div id="configurations">
      <div className={"pl-3"}>
        <div className="flex items-center mt-3 mb-2">
          <button
            className="ds-button"
            onClick={() => setIsCreateDataSourceOpen(true)}
          >
            <FontAwesomeIcon icon={faSquarePlus} className="me-2"/>
            Create
          </button>
        </div>

        <table className="min-w-full divide-y divide-gray-300">
          <thead>
          <tr>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              {t('NAME')}
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              {t('Shop Id')}
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              {t('STATUS')}
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              {t('Interval time')}
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              {t('Destination')}
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              {t('Destination info')}
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            </th>
          </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
          {dataSourceList && dataSourceList.map((dataSource, index) => (
            <tr key={index}>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{dataSource.name}</td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{dataSource.shopId}</td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {dataSourceStatus[dataSource.status]}
              </td>
              <td
                className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {dataSourceIntervalTime[`${dataSource.intervalTime}-${dataSource.intervalTimeType}`]}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {dataSourceDestination[dataSource.destination]}
              </td>
              <td className="whitespace-normal px-3 py-4 text-sm text-gray-500">
                {dataSource.destination === 'DATA_SOURCE_DESTINATION_GOOGLE_SHEETS' ?
                  <div>
                    Google Sheets URL: <a class="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline" target="_blank" href={dataSource.googleSheetsUrl}>{dataSource.googleSheetsUrl}</a>
                    <br></br><br></br>
                    Sheet name: {dataSource.sheetName}
                  </div>
                  : dataSource.destination === 'DATA_SOURCE_DESTINATION_GOOGLE_CLOUD_STORAGE' ?
                    <div>
                      Bucket name: {dataSource.bucketName}<br></br>
                      Object key: {dataSource.objectKey}
                    </div>
                    : <div>
                      Table Id: `{dataSource.gCloudProjectId}.{dataSource.dataset}.{dataSource.tableName}`
                    </div>
                }
              </td>
              <td className="relative whitespace-nowrap">
                <TailwindMenuDialog
                  items={[
                    {
                      Name: "Update",
                      Icon: <DsIconEdit/>,
                      Action: () => handleUpdate(dataSource.id),
                      ActionType: "button"
                    },
                    {
                      Name: "Delete",
                      Icon: <DsIconDelete/>,
                      Action: () => handleDelete(partner, dataSource.id),
                      ActionType: "button"
                    },
                  ]}
                />
              </td>
            </tr>
          ))}
          </tbody>
        </table>

        <hr className="h-px mt-1 bg-gray-200 border-0"/>

        <div className="flex justify-between items-center px-4 w-full mt-5 mb-4">
          <BackButton />
        </div>
      </div>

      <CreateDataSource
        isOpen={isCreateDataSourceOpen}
        closeModal={() => setIsCreateDataSourceOpen(false)}
        reset={() => listDataSources()}
        partner={partner}
      />
      <UpdateDataSource
        isOpen={isUpdateDataSourceOpen}
        closeModal={() => setIsUpdateDataSourceOpen(false)}
        reset={() => listDataSources()}
        id={updatedId}
        partner={partner}
      />
    </div>
  );
};
