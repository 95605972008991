import { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';

import TextInput from 'components/TextInput';
import { showInfo, showError } from "components/FlashMessage/flashMessageSlice";
import LoadingSpinner from 'components/LoadingSpinner';

import Chart from "services/chart_record";

import './index.scss';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

export default function AddChart({ reset = () => { } }) {
  const { t } = useTranslation();
  const { appId } = useParams();

  const methods = useForm();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    setIsLoading(true);

    try {
      const requestData = {
        appId,
        name: data.name,
        definition: data.definition
      }

      await Chart.createChart(requestData);

      dispatch(showInfo({ message: t("CREATE_VALUE_SUCCESSFULLY", { Value: "Chart" }) }));

      methods.reset();

      reset();

      setMobileMenuOpen(false);
    } catch (error) {
      const { message } = error;
      dispatch(showError({ message }));
    }

    setIsLoading(false);
  };

  return (
    <div id="add-chart">
      <button
        onClick={() => setMobileMenuOpen(true)}
        type="button"
        className="text-white bg-blue-600 hover:bg-blue-700 font-medium rounded-lg text-sm px-4 py-2.5 focus:outline-none"
      >
        + {t("Charts")}
      </button>

      <Dialog id="add-user-dialog" as="div" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0" />
        <Dialog.Panel className="panel fixed inset-y-0 right-0 w-full overflow-y-auto bg-white max-w-2xl sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between bg-gray-200 px-6 py-2">
            <span href="#" className="capitalize text-lg -m-1.5 p-1.5">
              {t('Create Chart')}
            </span>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <FontAwesomeIcon className="h-5 w-5" icon={faXmark} />
            </button>
          </div>

          <div className="px-6 py-2">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className="flex flex-wrap">
                  <div className="w-full sm:w-1/1 md:w-1/2 lg:w-1/2 pr-2">
                    <label className="text-sm required">{t('Chart Name')}</label>
                    <TextInput
                      name="name"
                      className="w-full mt-1"
                      placeholder={t('ENTER_VALUE', { Value: 'chart name' })}
                      rules={
                        { required: t('VALUE_IS_REQUIRED', { Value: t('NAME') }) }
                      }
                    />
                  </div>
                  <div className="w-full sm:w-1/1 md:w-1/2 lg:w-1/2 pr-2">
                    <label className="text-sm required">{t('Definition')}</label>
                    <TextInput
                      name="definition"
                      className="w-full mt-1"
                      placeholder={t('ENTER_VALUE', { Value: 'definition' })}
                      rules={
                        { required: t('VALUE_IS_REQUIRED', { Value: t('DEFINITION') }) }
                      }
                    />
                  </div>
                </div>

                <button
                  className="bg-transparent inline-flex items-center justify-center hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-1.5 px-2 mt-2 border border-blue-500 hover:border-transparent rounded">

                  {isLoading ?
                    <>
                      <LoadingSpinner textColor="text-blue-500" className="me-3" />
                      {t('LOADING')}
                    </>
                    :
                    <>+ {t("CHARTS")}</>
                  }
                </button>
              </form>
            </FormProvider>
          </div>
        </Dialog.Panel>
      </Dialog>
    </div>
  );
}
