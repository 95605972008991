import {useTranslation} from "react-i18next";

import TailwindCombobox from "components/TailwindCombobox";

import ChartList from "./chart_list";
import {isUndefinedOrEmpty} from "utils";
import FieldFilterToggleBox2 from "components/FieldFilterToggleBox2";
import AddToDashboard from "views/dashboard_list/add_to_dashboard";
import {CHART_CONFIG} from "consts";


export default function VisualisationHeader({
                                              chartId,
                                              dimensions,
                                              dimensionValue,
                                              metrics,
                                              defaultChartType,
                                              showDelete = false,
                                              onDelete = () => {
                                              },
                                              className = "",

                                              limitOptions = [],
                                              orderOptions = [],
                                              dataOptions = [],
                                              frequencyOptions = [],
                                              multiMetrics = [],
                                              multiDimensions = [],
                                              chartType,

                                              cardType,
                                              cardSubType,
                                              payload,
                                            }) {
  const {t} = useTranslation();

  // const [metricsByDimensionKey, setMetricsByDimensionKey] = useState([]);

  // useEffect(() => {
  //   setMetricsByDimensionKey(getMetricsByDimensionKey(dimensionValue, metrics));
  // }, [dimensionValue, metrics])
  //
  return (
    <>
      <div className={`flex items-center justify-between ds-input-text ${className}`}>
        <div className="grow items-center flex gap-2">
          {
            !isUndefinedOrEmpty(dataOptions)
            &&
            <div className={"flex items-center justify-between gap-2"}>
              <div className="ds-text-color-violet">{t('SHOW')}</div>
              <TailwindCombobox
                name={`${chartId}.showNonZero`}
                items={dataOptions}
              />
            </div>
          }
          <div className={"flex items-center justify-between gap-2"}>
            {
              !isUndefinedOrEmpty(dimensions)
              &&
              <div className={"flex items-center justify-between gap-2"}>
                <div className="ds-text-color-violet">{t('TOP')}</div>
                <TailwindCombobox
                  name={`${chartId}.dimension`}
                  items={dimensions}
                />
              </div>
            }

            {
              !isUndefinedOrEmpty(multiDimensions)
              &&
              <div className={"flex items-center justify-between gap-2"}>
                <div className="ds-text-color-violet">{t('TOP')}</div>
                <FieldFilterToggleBox2
                  name={`${chartId}.dimension`}
                  options={multiDimensions}
                  defaultValue={!isUndefinedOrEmpty(multiDimensions) ? multiDimensions[0].value : []}
                />
              </div>

            }
          </div>
          {
            !isUndefinedOrEmpty(metrics) && defaultChartType !== CHART_CONFIG.CHART_TYPE_KPI
            &&
            <div className={"flex items-center justify-between gap-2"}>
              <div className="ds-text-color-violet">{t('BY')}</div>
              <TailwindCombobox
                name={`${chartId}.metric`}
                items={metrics}
              />
            </div>
          }

          {
            !isUndefinedOrEmpty(multiMetrics)
            &&
            <div className={"flex items-center justify-between gap-2"}>
              <div className="ds-text-color-violet">{t('BY')}</div>
              <FieldFilterToggleBox2
                name={`${chartId}.metric`}
                options={multiMetrics}
                defaultValue={!isUndefinedOrEmpty(multiMetrics) ? multiMetrics[0].value : []}
              />
            </div>
          }

          {/*{*/}
          {/*  !isUndefinedOrEmpty(multiDimensions)*/}
          {/*  &&*/}
          {/*  <div className={"flex items-center justify-between"}>*/}
          {/*    <div className="ds-text-color-violet">{t('BY')}</div>*/}
          {/*    <FieldFilterToggleBox2*/}
          {/*      name={`${chartId}.dimension`}*/}
          {/*      options={multiDimensions}*/}
          {/*      // defaultValue={[multiMetrics[1].value]}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*}*/}


          {
            !isUndefinedOrEmpty(limitOptions)
            && chartType === CHART_CONFIG.CHART_TYPE_BARCHART
            &&
            <div className={"flex items-center justify-between gap-2"}>
              <div className="ds-text-color-violet">{t('LIMIT')}</div>
              <TailwindCombobox
                name={`${chartId}.limit`}
                items={limitOptions}
              />
            </div>
          }

          {
            !isUndefinedOrEmpty(orderOptions)
            && chartType === CHART_CONFIG.CHART_TYPE_BARCHART
            &&
            <div className={"flex items-center justify-between gap-2"}>
              <div className="ds-text-color-violet">{t('Order')}</div>
              <TailwindCombobox
                name={`${chartId}.order`}
                items={orderOptions}
              />
            </div>
          }


          {
            !isUndefinedOrEmpty(frequencyOptions)
            && (chartType === CHART_CONFIG.CHART_TYPE_LINECHART || chartType === CHART_CONFIG.CHART_TYPE_STACKEDCHART)
            &&
            <div className={"flex items-center justify-between gap-2"}>
              <div className="ds-text-color-violet">{t('FREQUENCY')}</div>
              <TailwindCombobox
                name={`${chartId}.frequency`}
                items={frequencyOptions}
              />
            </div>
          }
        </div>

        <AddToDashboard
          className="pt-2 ml-3"
          cardType={cardType}
          cardSubType={cardSubType}
          payload={payload}
        />

        {
          defaultChartType !== CHART_CONFIG.CHART_TYPE_KPI
          &&
          <div className="flex-none items-center justify-end pt-2">
            <ChartList
              name={`${chartId}.type`}
              defaultChartType={defaultChartType}
              showDelete={showDelete}
              onDelete={onDelete}
            />
          </div>
        }
      </div>
    </>
  )
}
