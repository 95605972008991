export {default as ERROR_CODES} from './errorCodes';
export {default as PATHS} from './paths';
export {default as TIME_ZONES} from './timezones';
export {default as BUSINESS_CATEGORIES} from './businessCategories';
export {default as CHART_CONFIG} from './chartConfig';

export const FLASH_TYPES = {
  INFO: 'info',
  WARN: 'warn',
  ERROR: 'error'
};

export const FILTER_TYPES = {
  INCLUDE: 'include',
  EXCLUDE: 'exclude',
  CONTAIN: 'contain',
  REGEX: 'regex',
  GREATER_THAN: 'greater_than',
  GREATER_THAN_OR_EQUAL_TO: 'greater_than_or_equal_to',
  LESS_THAN: 'less_than',
  LESS_THAN_OR_EQUAL_TO: 'less_than_or_equal_to',
  IS_EQUAL_TO: 'is_equal_to',
};

export const entries = [
  {
    text: '10 rows',
    value: 10,
  },
  {
    text: '25 rows',
    value: 25,
  },
  {
    text: '50 rows',
    value: 50,
  },
  {
    text: '100 rows',
    value: 100,
  },
];

export const PLATFORM = {
  ANDROID: 'Android',
  IOS: 'iOS',
  WEB: 'Web',
  MWEB: 'MWeb',
  APPSTORE: 'appstore',
  GGPLAY: 'googleplay',
}


export const STATUS = {
  APP_STATUS_ACTIVE: 'APP_STATUS_ACTIVE',
  APP_STATUS_INACTIVE: 'APP_STATUS_INACTIVE',
}

export const MIN_DATE = '2015-01-01';

export const FLASH_TIMEOUT = 5000;

export const DISPLAY_DATE_FORMAT = 'DD/MM/YYYY';
export const DISPLAY_DATE_CALENDAR_FORMAT = 'MMMM DD, YYYY'
export const DURATION_FORMAT = 'HH:mm:ss.SSS';
export const HOUR_FORMAT = "HH:mm";
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';
export const DISPLAY_DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';
export const TIMESTAMP_PROTOBUF_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ'

export const EMAIL_REGEX = /\S+@\S+\.\S+/;
export const URL_REGEX = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
export const GOOGLE_ADS_PARTNER_REGEX = /^\d{10}$/;
export const FACEBOOK_ADS_PARTNER_REGEX = /^\d{16}$/;
export const APPLE_SEARCH_ADS_PARTNER_REGEX = /^\d{7}$/;

export const MESSAGE_INFO = "info";
export const MESSAGE_ERROR = "error";
export const MESSAGE_SUCCESS = "success";

export const DATASENSES_DOCS_URL = "https://developer.datasenses.io/docs/sdk"

export const REPORT_TYPE = {
  REPORT_OVERVIEW: "REPORT_OVERVIEW",
  REPORT_FUNNEL: "REPORT_FUNNEL",
  REPORT_COHORT: "REPORT_COHORT",
}

export const CARD_TYPE = {
  CARD_TYPE_UNSPECIFIED: "CARD_TYPE_UNSPECIFIED",
  CARD_TYPE_EVENT: "CARD_TYPE_EVENT",
  CARD_TYPE_USER: "CARD_TYPE_USER",
  CART_TYPE_FUNNEL: "CART_TYPE_FUNNEL",
  CARD_TYPE_COHORT: "CARD_TYPE_COHORT",
  CARD_TYPE_SEGMENT: "CARD_TYPE_SEGMENT",
  CARD_TYPE_TREND: "CARD_TYPE_TREND",
  CARD_TYPE_PIVOT: "CARD_TYPE_PIVOT",
}

export const CARD_TYPE_DESCRIPTION = {
  CARD_TYPE_UNSPECIFIED: "",
  CARD_TYPE_EVENT: "Event",
  CARD_TYPE_USER: "User",
  CART_TYPE_FUNNEL: "Funnel",
  CARD_TYPE_COHORT: "Cohort",
  CARD_TYPE_SEGMENT: "Segment",
  CARD_TYPE_TREND: "Trend",
  CARD_TYPE_PIVOT: "Pivot",
}

export const CARD_SUBTYPE = {
  CARD_SUBTYPE_UNSPECIFIED: "CARD_SUBTYPE_UNSPECIFIED",
  CARD_SUBTYPE_EVENT_TREND: "CARD_SUBTYPE_EVENT_TREND",
  CARD_SUBTYPE_EVENT_PROPERTY: "CARD_SUBTYPE_EVENT_PROPERTY",
  CARD_SUBTYPE_EVENT_SESSION: "CARD_SUBTYPE_EVENT_SESSION",
  CARD_SUBTYPE_EVENT_GEOGRAPHY: "CARD_SUBTYPE_EVENT_GEOGRAPHY",
}

export const CARD_SUBTYPE_DESCRIPTION = {
  CARD_SUBTYPE_UNSPECIFIED: "",
  CARD_SUBTYPE_EVENT_TREND: "Event Trend",
  CARD_SUBTYPE_EVENT_PROPERTY: "Event Property",
  CARD_SUBTYPE_EVENT_SESSION: "Event Session",
  CARD_SUBTYPE_EVENT_GEOGRAPHY: "Event Geography",
}

export const DESTINATIONS = {
  GG_SHEET: "DATA_SOURCE_DESTINATION_GOOGLE_SHEETS",
  EX_BIGQUERY: "DATA_SOURCE_DESTINATION_EXTERNAL_BIGQUERY",
  GCS: "DATA_SOURCE_DESTINATION_GOOGLE_CLOUD_STORAGE",
}


