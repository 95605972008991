import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {PARTNERS} from "consts/partner";
import Tooltip from "components/Tooltip";
import AppService from 'services/app';
import {FormProvider, useForm} from "react-hook-form";
import TextInput from "components/TextInput";
import {showError, showInfo} from "components/FlashMessage/flashMessageSlice";
import {useDispatch} from "react-redux";
import BackButton from "views/integration/meta_ads/back_button";
import PartnerConnectionList from "views/integration/connection_list";
import CopyButton from "components/CopyButton";
import {DsIconTooltip} from "components/DsIcon";

export default function Cost() {
  const {t} = useTranslation();
  const methods = useForm();
  const dispatch = useDispatch();
  const [connections, setConnections] = useState([]);

  const publicKey = "-----BEGIN PUBLIC KEY-----\nMFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAEY4roya45bX49y5tw1dvTM/fIPEk8\nEcOImxgpfXntoKXq190FYUibTjY0AMuRM9L7JVgodssKxf6YI/KTBKml6A==\n-----END PUBLIC KEY-----"

  const onSubmit = async (data) => {
    try {
      await AppService.integrateAppleSearchAds(data);
      dispatch(showInfo({message: 'Connected successfully'}));
    } catch (error) {
      dispatch(showError({message: "Connect Unsuccessfully"}))
    }
  }

  const getConnection = async () => {
    const result = await AppService.getConnectionByPartner(PARTNERS.APPLE_SEARCH)
    setConnections(result)
  }

  useEffect(() => {
    getConnection();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>

      <div id="cost" className={"sm:p-3"}>
        <div className="flex justify-start items-center w-full">
          <h3 className="ds-text-form-title mr-2">Connect to your Apple Search Ads
          </h3>

          <Tooltip minWidth="250">
            <Tooltip.Label>
              <div><DsIconTooltip className="h-full w-full text-blue-400"
                                  aria-hidden="true"/>
              </div>
            </Tooltip.Label>
            <Tooltip.Content>
              <p>Campaign data will be synced daily. Connect again if something went wrong</p>
            </Tooltip.Content>
          </Tooltip>

        </div>
        <div className="ds-text-lighter">Enhance your integration to receive data.
          <a target={"_blank"} className={" text-blue-500 font-medium"}
             href={"https://developer.datasenses.io/docs/partner-apple-search-ads"}> Read the document for more
            detail</a>
        </div>

        <FormProvider {...methods}>
          <form className="flex items-center " onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="mb-3 mt-3 mr-3 w-2/3 ">
              <div className={"relative"}>
                  <textarea
                    name="comment"
                    id="comment"
                    disabled={true}
                    className={"w-full h-24"}
                    placeholder={publicKey}
                  />
                <div className={"absolute top-0 right-0"}><CopyButton id={"appleKey"} message={publicKey}/></div>
              </div>
              <div className={"grid grid-rows-5 gap-4"}>
                <div>
                  <TextInput
                    label="Client ID"
                    name="clientId"
                    type="text"
                    className="ds-input ds-input-text w-full"
                    rules={
                      {
                        required: t('VALUE_IS_REQUIRED', {Value: t('Client ID')}),
                      }
                    }
                  />
                </div>
                <div>
                  <TextInput
                    label="Team ID"
                    name="teamId"
                    type="text"
                    className="ds-input ds-input-text w-full"
                    rules={
                      {
                        required: t('VALUE_IS_REQUIRED', {Value: t('Team ID')}),
                      }
                    }
                  />
                </div>
                <div>
                  <TextInput
                    label="Key ID"
                    name="keyId"
                    type="text"
                    className="ds-input ds-input-text w-full"
                    rules={
                      {
                        required: t('VALUE_IS_REQUIRED', {Value: t('Key ID')}),
                      }
                    }
                  />
                </div>
                <div>
                  <TextInput
                    label="Organization ID"
                    name="orgId"
                    type="text"
                    className="ds-input ds-input-text w-full"
                    rules={
                      {
                        required: t('VALUE_IS_REQUIRED', {Value: t('Organization ID')}),
                      }
                    }
                  />
                </div>
                <div className={"flex items-center justify-end"}>
                  <button
                    type="Connect"
                    className="ds-button mr-3"
                  >
                    Connect
                  </button>
                </div>
              </div>
            </div>
          </form>
        </FormProvider>

        <div className={"mt-16"}>
          {connections.length > 0 &&
            <PartnerConnectionList connections={connections} action={onSubmit}/>
          }
        </div>

        <hr className="h-px my-6 bg-gray-200 border-0"/>
        <div className="flex justify-between items-center px-4 w-full">
          <BackButton/>
        </div>
      </div>
    </>
  )
}
