export const DATE_KEY_TYPE = {
  DATE_RELATIVE: 'date_relative',
  DATE_EXACT: 'date_exact',
}

export const DATE_KEY_TYPE_OPTIONS = [
  {
    name: 'Date Relative',
    fieldKey: DATE_KEY_TYPE.DATE_RELATIVE,
  },
  {
    name: 'Date Exact',
    fieldKey: DATE_KEY_TYPE.DATE_EXACT,
  },
];

export const DATE_KEY_TYPE_FIELD = "date_key_type"
