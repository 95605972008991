import React, {useState} from "react";
import AppService from "services/app";
import {showError, showInfo} from "components/FlashMessage/flashMessageSlice";
import {useDispatch} from "react-redux";
import {PARTNERS} from "consts/partner";

function CheckboxGroup({partner, connectionId}) {
  const [items, setItems] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useDispatch();

  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
    if (!isDropdownOpen) {
      fetchItems()
    }
  };

  const fetchItems = async () => {
    try {
      const result = await AppService.getGetCustomerByPartnerId({
        partner,
        partnerId: connectionId,
      });
      setItems(result);
      const initialCheckedItems = result.reduce((acc, item) => {
        acc[item.id] = item.status === 1;
        return acc;
      }, {});
      setCheckedItems(initialCheckedItems);
    } catch (e) {
      showError(e.message);
    }
  }

  const handleCheckboxChange = (id, checked) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [id]: checked,
    }));
  };

  const handleClick = async () => {
    let customers = []
    for (const [key, value] of Object.entries(checkedItems)) {
      customers.push({
        customerId: key,
        status: value ? 1 : 0,
      });
    }

    try {
      await AppService.updateCustomerByPartnerId({
        partner: partner,
        partnerId: connectionId,
        data: customers,
      });
      dispatch(showInfo({message: 'Updated successfully'}));
      await fetchItems()
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
  }

  return (
    <>
      <div>
        <div>
          <button
            id="dropdownCheckboxButton"
            onClick={handleDropdownToggle}
            className="ds-button inline-flex items-center"
            type="button"
          >
            {isDropdownOpen ? "Hide" : "Show"}
          </button>
          {isDropdownOpen && <button className="ds-button ml-1" onClick={handleClick}>
            Apply
          </button>}
        </div>

        {isDropdownOpen && (
          <div id="dropdownDefaultCheckbox"
               className="absolute z-10 w-48 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600 mt-2">
            <ul className="p-3 space-y-3 text-sm text-gray-700 dark:text-gray-200 max-h-40 overflow-y-auto"
                aria-labelledby="dropdownCheckboxButton">
              {items.map((item, index) => (
                <li key={item.id}>
                  <div className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                    <input
                      id={item.id}
                      name={item.id}
                      type="checkbox"
                      checked={checkedItems[item.id] || false}
                      onChange={(e) => handleCheckboxChange(item.id, e.target.checked)}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label htmlFor={item.id}
                           className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 ds-input-text">
                      {partner === PARTNERS.TIKTOK ? item.adsCustomerName
                        : item.adsCustomerId}
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  );
}

export default CheckboxGroup;
