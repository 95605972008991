import {
  timeType,
} from "../../types.js";

/**
 * Represents integration details for a Google app.
 * @typedef {Object} AppIntegrationGoogle
 * @property {string} id - The unique identifier of the integration.
 * @property {string} appId - The ID of the associated app.
 * @property {string} linkId - The link identifier.
 * @property {boolean} deepLinkEnabled - Indicates whether deep linking is enabled.
 * @property {boolean} nonEuUsers - Indicates whether non-EU users are allowed.
 * @property {number} installClickThroughLookbackDay - The number of days to look back for install click-through.
 * @property {number} installClickThroughLookbackHour - The number of hours to look back for install click-through.
 * @property {boolean} installViewThroughAttributionEnabled - Indicates whether install view-through attribution is enabled.
 * @property {number} installViewThroughLookbackDay - The number of days to look back for install view-through.
 * @property {number} installViewThroughLookbackHour - The number of hours to look back for install view-through.
 * @property {boolean} reinstallAttributionEnabled - Indicates whether reinstall attribution is enabled.
 * @property {boolean} reEngagementAttributionEnabled - Indicates whether re-engagement attribution is enabled.
 * @property {boolean} ignoreActiveUsersRetargetingEnabled - Indicates whether retargeting of active users is ignored.
 * @property {number} inactivityWindowDay - The number of days for the inactivity window.
 * @property {Array<Object>} inAppEventPostbacks - An array of objects representing in-app event postbacks.
 * @property {string} inAppEventPostbacks.datasensesEvent - The event on the Datasenses platform.
 * @property {string} inAppEventPostbacks.partnerEvent - The corresponding event on the partner's platform.
 * @property {string} inAppEventPostbacks.usersOrigin - Origin of users.
 * @property {boolean} inAppEventPostbacks.valueIncluded - Indicates whether value is included.
 * @property {boolean} inAppEventPostbacks.revenueIncluded - Indicates whether revenue is included.
 * @property {string} status - The status of the integration partner.
 * @property {string} createdBy - The email of the user who created the integration.
 * @property {string} updatedBy - The email of the user who last updated the integration.
 * @property {string} createdAt - The timestamp when the integration was created.
 * @property {string} updatedAt - The timestamp when the integration was last updated.
 */

/**
 * Represents integration details for a Google app.
 * @typedef {Object} Project
 * @property {AppIntegrationGoogle} appIntegrationGoogle - Integration details for Google app.
 */


/**
 * Retrieves the integration details for a specific app and partner.
 * @async
 * @param {Project} data - The options object.
 */

export const processGetIntegrationData = (data) => {
  data.appIntegrationGoogle.active = data.appIntegrationGoogle.status === 'INTEGRATION_PARTNER_ACTIVE';

  if (data.appIntegrationGoogle.installClickThroughLookbackDay > 0) {
    data.appIntegrationGoogle.clickThrough = data.appIntegrationGoogle.installClickThroughLookbackDay
    data.appIntegrationGoogle.clickThroughType = timeType.Days
  } else {
    data.appIntegrationGoogle.clickThrough = data.appIntegrationGoogle.installClickThroughLookbackHour
    data.appIntegrationGoogle.clickThroughType = timeType.Hours
  }

  if (!!data.appIntegrationGoogle.inAppEventPostbacks) {
    if (data.appIntegrationGoogle.inAppEventPostbacks.length > 0) {
      data.appIntegrationGoogle.inAppEventPostbacksEnabled = true
    } else {
      data.appIntegrationGoogle.inAppEventPostbacksEnabled = false
    }

    for (let key = 0; key < data.appIntegrationGoogle.inAppEventPostbacks.length; key++) {
      if (data.appIntegrationGoogle.inAppEventPostbacks[key].valueIncluded &&
        data.appIntegrationGoogle.inAppEventPostbacks[key].revenueIncluded) {
        data.appIntegrationGoogle.inAppEventPostbacks[key].including = 'value_and_revenue';
      } else if (data.appIntegrationGoogle.inAppEventPostbacks[key].valueIncluded &&
        !data.appIntegrationGoogle.inAppEventPostbacks[key].revenueIncluded) {
        data.appIntegrationGoogle.inAppEventPostbacks[key].including = 'value_and_no_revenue';
      } else if (!data.appIntegrationGoogle.inAppEventPostbacks[key].valueIncluded &&
        !data.appIntegrationGoogle.inAppEventPostbacks[key].revenueIncluded) {
        data.appIntegrationGoogle.inAppEventPostbacks[key].including = 'no_value_and_no_revenue';
      }
    }
  }

  return data;
}
