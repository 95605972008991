import TextInput from "components/TextInput";
import {FormProvider, useForm} from "react-hook-form";
import DataSourceService from "services/data_source";
import {showError, showInfo} from "components/FlashMessage/flashMessageSlice";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquarePlus} from "@fortawesome/free-solid-svg-icons";
import {processSocialMediaDataSourceData} from "views/integration/processor";
import TailwindCombobox from "components/TailwindCombobox";
import {destinationSocialMediaOptions, sourceOptions} from "views/integration/types";
import LoadingSpinner from "components/LoadingSpinner";


export default function DataSourceSocialMediaFormCreate({closeModal, reset, partner, connections}) {
  const methods = useForm();
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);


  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      console.log(data)
      processSocialMediaDataSourceData(data)
      if (data.social_media_options === "media") {
        await DataSourceService.createSocialMediaDataSource(data);
      } else {
        await DataSourceService.createAdsDataSource(data);
      }
      dispatch(showInfo({message: t("CREATE_VALUE_SUCCESSFULLY", {Value: "data source"})}));
      closeModal();
      reset();
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
    setIsLoading(false);
  }

  useEffect(() => {
    methods.setValue('partner', partner);
  }, [partner]);


  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="gap-4 bg-white mt-5 ds-border-gray shadown-md">
          <div className="grid grid-cols-4 gap-4 bg-white rounded-lg shadown-md">
            <div className="col-span-3">
              <div className="p-4">
                <div className="grid gap-4">
                  <div>
                    <TextInput
                      label="Name"
                      name="name"
                      className="ds-input-text w-full"
                      rules={
                        {
                          required: t('Name is required'),
                        }
                      }
                    />
                  </div>


                  <div>
                    <div className="ds-input-text me-2">Connection</div>
                    <div>
                      <TailwindCombobox
                        label="Connection"
                        name="connectionId"
                        className="w-full"
                        items={connections}
                        rules={
                          {
                            required: t('Connection is required'),
                          }
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <span className="ds-input-text">Source</span>
                    <TailwindCombobox
                      name="source"
                      className="w-full"
                      items={sourceOptions.find(item => item.partner === partner).options}
                      rules={
                        {
                          required: t('Source is required'),
                        }
                      }
                    />
                  </div>
                  <div>
                    <span className="ds-input-text">Export data to</span>
                    <TailwindCombobox
                      name="destination"
                      className="w-full"
                      items={destinationSocialMediaOptions}
                      rules={
                        {
                          required: t('Export destination is required'),
                        }
                      }
                    />
                  </div>


                </div>
              </div>
            </div>

          </div>

          <div className="flex justify-end items-center mb-5 mt-2 mr-5">
            <button
              type="submit"
              className="ds-button"
            >
              {isLoading ?
                <>
                  <LoadingSpinner className="me-3" textColor="text-blue-500"/>
                  {t('LOADING')}
                </>
                :
                <div>
                  <FontAwesomeIcon icon={faSquarePlus} className="me-2"/>
                  Create
                </div>
              }
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  )
}
