import SegmentFilterType from "components/SegmentFilterInput/types";

const OPERATOR_TYPE = {
  GREATER_OR_EQUALS: ">=",
  LESS_THAN_OR_EQUALS: "<=",
  EQUALS: "=",
  LESS_THAN: "<",
  IN: "IN",
};

const FIELD_NAME = {
  DATE_KEY: "date_key",
  EVENT_NAME: "event_name",
  HOUR: "hour",
  DAY: "day",
  DAY_OF_WEEK: "day_of_week",
  FIRST_NAME: "first_name",
  LAST_NAME: "last_name",
}

/**
 * Processes the provided data for further analytic.
 * @param {Object} data - The data object containing parameters for analytic.
 * @param {string} data.type - The type of analytic or event.
 * @param {string} data.segment - The segment of users to analyze.
 * @param {Array<string>} data.date - The date range for analytic.
 * @param {Array<Array<Object>>} data.funnelSteps - The steps in the conversion funnel, each step represented by an array of objects.
 * @param {string} data.conversionTime - The time window for conversion in seconds.
 * @param {boolean} data.enforceStrictOrder - A flag indicating whether to enforce strict order in funnel steps.
 */
export const proceedParam = (data) => {
  const requestPayload = {};

  requestPayload.steps = data.funnelSteps.map((funnelStep, index) => {
    const stepSubs = funnelStep.map((item, index) => {
      const filters = [];

      filters.push({
        field: FIELD_NAME.EVENT_NAME,
        operator: OPERATOR_TYPE.EQUALS,
        value: item.paramEventName,
      });

      for (const filter of item.paramFilter) {
        if (filter.type === SegmentFilterType.EVENT_PROPERTY) {
          filters.push({
            field: `$.${filter.value.property}`,
            operator: filter.value.operator,
            value: filter.value.value
          });
        }

        if (filter.type === SegmentFilterType.FIRST_NAME) {
          filters.push({
            field: FIELD_NAME.FIRST_NAME,
            operator: OPERATOR_TYPE.IN,
            value: filter.value
          });
        }

        if (filter.type === SegmentFilterType.LAST_NAME) {
          filters.push({
            field: FIELD_NAME.LAST_NAME,
            operator: OPERATOR_TYPE.IN,
            value: filter.value
          });
        }

        if (filter.type === SegmentFilterType.TIME_OF_THE_DAY) {
          filters.push({field: FIELD_NAME.HOUR, operator: OPERATOR_TYPE.GREATER_OR_EQUALS, value: filter.value[0]})
          filters.push({field: FIELD_NAME.HOUR, operator: OPERATOR_TYPE.LESS_THAN_OR_EQUALS, value: filter.value[1]})
        }

        if (filter.type === SegmentFilterType.DAY_OF_THE_MONTH) {
          if (filter.value.length > 0) {
            filters.push({
              field: FIELD_NAME.DAY,
              operator: OPERATOR_TYPE.IN,
              value: filter.value
            });
          }
        }
      }

      return {
        step_sub: index + 1,
        filters,
      };
    });

    return {
      step: index + 1,
      step_subs: stepSubs,
    };
  });

  return requestPayload;
};
