import React, {useState} from 'react';

export const JsonToggle = ({jsonData, maxLength = 50, className}) => {
  const jsonString = JSON.stringify(jsonData, null);
  const isInitiallyExpanded = jsonString.length <= maxLength;
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  // Check if jsonData is null
  const isNull = jsonData === null;

  // Count the number of top-level properties in the JSON object
  const propertyCount = isNull ? 0 : Object.keys(jsonData).length;

  return (
    <a
      className={`whitespace-nowrap px-3 py-4 text-sm text-gray-500 overflow-auto ${className}`}
      onClick={handleToggle}
      style={{
        cursor: 'pointer',
        whiteSpace: isExpanded ? 'pre-wrap' : 'nowrap',
      }}
    >
      {isExpanded ? (isNull ? 'None' : jsonString) :
          <span style={{ fontWeight: 'bold' }}>{`{...}`}</span>
      }
    </a>
  );
};
