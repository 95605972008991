import HttpMethod from 'consts/httpMethod';
import {processErrorResponse, processRequest, processSuccessResponse} from 'utils';


export default {
  async inviteUser({ token }) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/join-invite/${token}`,
      method: HttpMethod.POST
    };

    try {
      const res = await processRequest(ep);
      return processSuccessResponse(res);
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  }
}
