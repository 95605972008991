import HttpMethod from "consts/httpMethod";
import { processErrorResponse, processRequest, processSuccessResponse } from "utils";

export default {
  async listCompanyUsers() {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/user-management/list`,
      method: HttpMethod.GET
    };

    try {
      const res = await processRequest(ep);
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async removeUser(email) {
    console.log(email)
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/user-management/users/remove`,
      method: HttpMethod.POST
    };

    try {
      const res = await processRequest(ep, { data: { email } });
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async updateRole(data) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/user-management/role/update`,
      method: HttpMethod.PUT
    };

    try {
      const res = await processRequest(ep, { data });
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async getCompanyInfo() {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/company`,
      method: HttpMethod.GET
    };

    try {
      const res = await processRequest(ep);
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async updateCompanyInfo(data) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/company`,
      method: HttpMethod.PUT
    };

    try {
      const res = await processRequest(ep, { data });
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async getInvoice() {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `company/invoice`,
      method: HttpMethod.GET
    };

    try {
      const res = await processRequest(ep);
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return false;
    }
  }
}
