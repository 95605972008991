import i18n from '../i18n';

// eslint-disable-next-line
export default [
  {
    text: i18n.t("DELIVERY_SERVICES"),
    value: "Delivery Services",
  },
  {
    text: i18n.t("ECOMMERCE"),
    value: "eCommerce",
  },
  {
    text: i18n.t("EDUCATION"),
    value: "Education",
  },
  {
    text: i18n.t("MEDIA_AND_ENTERTAINMENT"),
    value: "Media & Entertainment",
  },
  {
    text: i18n.t("FINANCE"),
    value: "Finance",
  },
  {
    text: i18n.t("GAMES"),
    value: "Games",
  },
  {
    text: i18n.t("HEALTH_AND_FITNESS"),
    value: "Health & Fitness",
  },
  {
    text: i18n.t("HEALTHCARE"),
    value: "Healthcare",
  },
  {
    text: i18n.t("LIFESTYLE"),
    value: "Lifestyle",
  },
  {
    text: i18n.t("LOCAL"),
    value: "Local",
  },
  {
    text: i18n.t("MUSIC"),
    value: "Music",
  },
  {
    text: i18n.t("OTHER"),
    value: "Other",
  },
  {
    text: i18n.t("RETAIL"),
    value: "Retail",
  },
  {
    text: i18n.t("SOCIAL_NETWORKING"),
    value: "Social Networking",
  },
  {
    text: i18n.t("SPORTS"),
    value: "Sports",
  },
  {
    text: i18n.t("TRAVEL"),
    value: "Travel",
  },
  {
    text: i18n.t("TRANSPORTATION"),
    value: "Transportation",
  },
];
