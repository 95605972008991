import HttpMethod from 'consts/httpMethod';
import { processRequest, processSuccessResponse, processErrorResponse } from 'utils';


export default {
  async verify({ token, password }) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: '/email/verify',
      method: HttpMethod.POST
    };

    try {
      const res = await processRequest(ep, { data: { token, password } });
      const data = processSuccessResponse(res);
      return data;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  }
}
