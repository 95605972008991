import HttpMethod from "consts/httpMethod";
import {processErrorResponse, processForm, processRequest, processSuccessResponse} from "utils";

export default {
  async createApp({name, logo, link, platform, timezone}) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: '/app',
      method: HttpMethod.POST
    };
    try {
      const res = await processRequest(ep, {data: {name, logo, link, platform, timezone}});
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  integrateHaravan() {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/marketing/link?source=haravan`,
      method: HttpMethod.GET
    };
    try {
      const res = processForm(ep).then(data => {
        if (data.status === 200) {
          window.location.href = data.data.url;
        }
      })
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  integrateTiktokShop() {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/marketing/link?source=tiktok_shop`,
      method: HttpMethod.GET
    };
    try {
      const res = processForm(ep).then(data => {
        if (data.status === 200) {
          window.location.href = data.data.url;
        }
      })
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  integrateLazada() {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/marketing/link?source=lazada`,
      method: HttpMethod.GET
    };
    try {
      const res = processForm(ep).then(data => {
        if (data.status === 200) {
          window.location.href = data.data.url;
        }
      })
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  integrateShopify() {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/marketing/link?source=shopify`,
      method: HttpMethod.GET
    };
    try {
      const res = processForm(ep).then(data => {
        if (data.status === 200) {
          window.location.href = data.data.url;
        }
      })
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  integrateShopee() {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/marketing/link?source=shopee`,
      method: HttpMethod.GET
    };
    try {
      const res = processForm(ep).then(data => {
        if (data.status === 200) {
          window.location.href = data.data.url;
        }
      })
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  async updateApp(appId, data) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/app/${appId}/update`,
      method: HttpMethod.PUT
    };
    try {
      const res = await processRequest(ep, {data: data});
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async updateUninstallMeasurement(appId, data) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/app/${appId}/uninstall-measurement/update`,
      method: HttpMethod.PUT,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    try {
      const res = await processRequest(ep, {data: data});
      return;
    } catch (e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  /**
   * Represents a project object.
   * @typedef {Object} ProjectDetail
   * @property {string} projectId - The unique identifier of the project.
   * @property {string} name - The name of the project.
   * @property {string} devKey - The dev key of the project.
   * @property {string} timeZone - The time zone of the project.
   * @property {string} createBy - The user created the project.
   */

  /**
   * Asynchronously fetches project details based on the provided projectId.
   *
   * @param {Object} params - Parameters for the project retrieval.
   * @param {string} params.projectId - The unique identifier of the project.
   * @returns {Promise<ProjectDetail>} A Promise that resolves to a Project object representing the fetched project details.
   * @throws {Error} If an error occurs during the request, it handles the error and returns an empty array.
   */
  async getApp({appId}) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/app/${appId}`,
      method: HttpMethod.GET
    };
    try {
      const res = await processRequest(ep);
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  async getAppIntegrationInfo() {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/app/marketing-integration`,
      method: HttpMethod.GET
    };
    try {
      const res = await processRequest(ep);
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  async getListMarketingPartner({appId}) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/app/${appId}/marketing-integration`,
      method: HttpMethod.GET
    };
    try {
      const res = await processRequest(ep);
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  /**
   * Represents a project object.
   * @typedef {Object} Project
   * @property {string} projectId - The unique identifier of the project.
   * @property {string} name - The name of the project.
   * @property {string} role - The role of the project.
   * @property {string} topic - The topic of the project.
   * @property {string} createBy - The user created the project.
   */

  /**
   * Asynchronously retrieves a list of projects.
   *
   * @async
   * @function listProjects
   * @returns {Promise<Array<Project>>} A Promise that resolves with an array of project objects.
   * @throws {Error} Throws an error if the request fails.
   */
  async listApps() {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/app/list`,
      method: HttpMethod.GET
    };
    try {
      const res = await processRequest(ep);
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  async deleteApp(id) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/app/${id}/delete`,
      method: HttpMethod.DELETE
    };
    try {
      const res = await processRequest(ep);
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  integrateGoogleCost(adsCustomerId) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/marketing/link?source=google&adsCustomerId=${adsCustomerId}`,
      method: HttpMethod.GET
    };
    try {
      const res = processForm(ep).then(data => {
        if (data.status === 200) {
          window.location.href = data.data.url;
        }
      })
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },


  integrateFacebookCost2(adsCustomerId) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/marketing/v2/link?source=facebook&adsCustomerId=${adsCustomerId}`,
      method: HttpMethod.GET
    };
    try {

      const res = processForm(ep).then(data => {
        if (data.status === 200) {
          window.location.href = data.data.url;
        }
      })
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  integrateFacebookCost(adsCustomerId) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/marketing/link?source=facebook&adsCustomerId=${adsCustomerId}`,
      method: HttpMethod.GET
    };
    try {

      const res = processForm(ep).then(data => {
        if (data.status === 200) {
          window.location.href = data.data.url;
        }
      })
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },



  integrateTiktokCost(adsCustomerId) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/marketing/link?source=tiktok&adsCustomerId=${adsCustomerId}`,
      method: HttpMethod.GET
    };
    try {

      const res = processForm(ep).then(data => {
        if (data.status === 200) {
          window.location.href = data.data.url;
        }
      })
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  async integrateAppleSearchAds({clientId, teamId, keyId, orgId}) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/marketing/link/apple-search-ads`,
      method: HttpMethod.POST
    };
    try {
      const res = await processRequest(ep, {data: {clientId, teamId, keyId, orgId}});
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  async downloadCredential(appId) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/app/${appId}/google-services-info`,
      method: HttpMethod.GET
    };
    try {
      const res = processForm(ep).then(data => {
        if (data.status === 200) {
          const fileContents = atob(data.data.androidIosAppConfig.fileContents);
          const fileName = data.data.androidIosAppConfig.fileName;
          let blob = null;
          if (fileName.includes(".json")) {
            const jsonData = JSON.parse(fileContents);
            blob = new Blob([JSON.stringify(jsonData)], {type: 'application/json'});
          } else if (fileName.includes(".plist")) {
            blob = new Blob([fileContents], {type: 'application/xml'});
          }
          if (blob) {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      })
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }

  },

  async getConnectionByPartner(partner) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/data-connection/${partner}/list`,
      method: HttpMethod.GET
    };
    try {
      const res = await processRequest(ep);
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },


  async getGetCustomerByPartnerId({partner , partnerId: partnerId}) {
    console.log(partner, partnerId);
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/data-connection/${partner}/${partnerId}/customer`,
      method: HttpMethod.GET
    };
    try {
      const res = await processRequest(ep);
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  async updateCustomerByPartnerId({partner, partnerId, data}) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/data-connection/${partner}/${partnerId}/customer`,
      method: HttpMethod.PUT
    };
    const requestData = {
      customers: data
    }
    try {
      const res = await processRequest(ep, { data: requestData });
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async getEventLogs({appId}) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `app/${appId}/event-logs`,
      method: HttpMethod.GET
    };
    try {
      const res = await processRequest(ep);
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  }

}
