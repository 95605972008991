import React, {useEffect, useMemo, useState} from "react";
import {isUndefinedOrEmpty} from "utils";
import ChartSerivce from "services/chart";
import {showError} from "components/FlashMessage/flashMessageSlice";
import {useParams} from "react-router-dom";
import FieldFilterToogleBox from "components/FieldFilterToogleBox";
import {CHART_CONFIG} from "consts";
import {useFormContext} from "react-hook-form";
import DateFilterToggleBox from "components/DateFilterToggleBox";
import {DATE_KEY_TYPE_FIELD, DATE_KEY_TYPE_OPTIONS} from "consts/dateFilter";
import TailwindCombobox from "components/TailwindCombobox";

export default function UserFrom({
                                   name,
                                   dateDiff,
                                 }) {
  const {appId} = useParams();
  const [fieldList, setFieldList] = useState([]);
  const {getValues} = useFormContext();

  const getChartFieldList = async () => {
    try {
      if (!isUndefinedOrEmpty(appId)) {
        const result = await ChartSerivce.getFieldList({appId: appId});
        setFieldList(result);
      }
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
  };

  const filters = useMemo(() => {
    return fieldList.filter(
      (element) =>
        element.filterable === true &&
        element.fieldKey !== CHART_CONFIG.CHART_DATE_FIELD &&
        element.fieldKey.startsWith('utm_')
    );
  }, [fieldList]);

  useEffect(() => {
    getChartFieldList()
  }, [appId]);

  return (
    <div>
      <div className={`ds-block`}>
        <div className="ds-text-form-title mb-4">
          User from
        </div>

        <div className="flex space-x-2">

          {filters.map((filter, index) => (
            <div key={index}>
              <FieldFilterToogleBox
                appId={appId}
                fieldKey={filter.fieldKey}
                displayedName={filter.name}
                className={`bg-white`}
                name={`${name}.${CHART_CONFIG.CHART_FILTER}.${filter.fieldKey}`}
              />
            </div>
          ))}

          <DateFilterToggleBox
            name={`${name}.${CHART_CONFIG.CHART_DATE_FIELD}`}
            type={getValues(`${name}.${DATE_KEY_TYPE_FIELD}`)}
            dateDiff={dateDiff}
            className={`!bg-white`}
          />

          <TailwindCombobox
            name={`${name}.${DATE_KEY_TYPE_FIELD}`}
            items={DATE_KEY_TYPE_OPTIONS}
          />
        </div>

      </div>
    </div>
  );
}
