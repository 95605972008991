export default function DsIconColoredMetaAds() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="w-full" height="h-full" fill="none" viewBox="0 0 24 24" id="meta">
      <path fill="#5E94FF" fill-rule="evenodd"
            d="M7.70394 6.75C6.36944 6.75 5.16583 7.55241 4.65256 8.78425C4.20875 9.8494 3.61974 12.4519 3.35759 13.6725C3.28162 14.0261 3.24636 14.3458 3.27291 14.6429C3.35558 15.5678 3.64775 16.0575 3.9664 16.3276C4.29458 16.6059 4.76682 16.75 5.39545 16.75C6.13513 16.75 6.82263 16.369 7.21466 15.7417L8.86055 13.1083L11.1255 9.3333L10.5385 8.35486C9.94107 7.35921 8.86507 6.75 7.70394 6.75ZM12.0002 7.87556L11.8247 7.58311C10.9562 6.13566 9.39195 5.25 7.70394 5.25C5.76389 5.25 4.01412 6.41651 3.26794 8.20733C2.75866 9.42961 2.14128 12.1924 1.89103 13.3575C1.79815 13.7899 1.73384 14.2726 1.77887 14.7764C1.88564 15.9711 2.29447 16.8766 2.99635 17.4717C3.68869 18.0588 4.55652 18.25 5.39545 18.25C6.65232 18.25 7.82052 17.6025 8.48666 16.5367L10.1362 13.8975L12.0002 10.791L13.857 13.8859L15.5137 16.5367C16.1798 17.6025 17.348 18.25 18.6049 18.25C19.5419 18.25 20.489 17.9835 21.1952 17.2419C21.8968 16.5053 22.2502 15.4155 22.2502 14C22.2502 13.1196 21.9122 11.8102 21.5866 10.7307C21.2506 9.61708 20.8879 8.62354 20.7765 8.32387C20.7513 8.25603 20.7221 8.18098 20.6872 8.10234C19.9172 6.37101 18.1983 5.25 16.2964 5.25C14.6084 5.25 13.0441 6.13567 12.1756 7.58315L12.0002 7.87556ZM12.8748 9.33331L15.1398 13.1083L16.7857 15.7417C17.1777 16.369 17.8652 16.75 18.6049 16.75C19.2669 16.75 19.7675 16.5661 20.1091 16.2074C20.4554 15.8437 20.7502 15.1835 20.7502 14C20.7502 13.3735 20.4837 12.2685 20.1505 11.1639C19.8275 10.0934 19.4769 9.13291 19.3704 8.84626C19.3509 8.79359 19.3339 8.75069 19.3166 8.71187C18.7871 7.52112 17.6046 6.75 16.2964 6.75C15.1353 6.75 14.0593 7.35922 13.4619 8.35489L12.8748 9.33331Z"
            clip-rule="evenodd"></path>
      <path fill="#5485E5" fill-rule="evenodd"
            d="M12 7.87525V10.7913L10.1433 13.8859L10.1363 13.8976L10.1362 13.8975L8.48666 16.5367C7.82052 17.6025 6.65232 18.25 5.39545 18.25C4.55652 18.25 3.68869 18.0588 2.99635 17.4717C2.29447 16.8766 1.88564 15.9711 1.77887 14.7764C1.73384 14.2726 1.79815 13.7899 1.89103 13.3575C2.14128 12.1924 2.75866 9.42961 3.26794 8.20733C4.01412 6.41651 5.76389 5.25 7.70394 5.25C9.39195 5.25 10.9562 6.13565 11.8247 7.5831L11.8247 7.58311L12 7.87525ZM4.65256 8.78425C5.16583 7.55241 6.36944 6.75 7.70394 6.75C8.86507 6.75 9.94107 7.35921 10.5385 8.35486L11.1255 9.3333L8.86055 13.1083L7.21466 15.7417C6.82263 16.369 6.13513 16.75 5.39545 16.75C4.76682 16.75 4.29458 16.6059 3.9664 16.3276C3.64775 16.0575 3.35558 15.5678 3.27291 14.6429C3.24636 14.3458 3.28162 14.0261 3.35759 13.6725C3.61974 12.4519 4.20875 9.8494 4.65256 8.78425Z"
            clip-rule="evenodd"></path>
    </svg>
  )
}
