import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import {
  BookmarkSquareIcon,
  CalendarDaysIcon,
  LifebuoyIcon,
} from "@heroicons/react/24/outline";

const resources = [
  {
    name: "ShopMetric",
    description: "One Click to connect all data sources",
    href: "/shop-metric",
    icon: LifebuoyIcon,
  },
  {
    name: "Growth Analytics Provider",
    description: "Key for susstainable business",
    href: "/growth-analytics",
    icon: BookmarkSquareIcon,
  },
];


export default function Example() {
  return (
    <Popover className="relative">
      <PopoverButton className="inline-block rounded-lg px-2 py-1 semi-bold text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900">
        <span>Products</span>
        {/* <ChevronDownIcon aria-hidden="true" className="h-5 w-5" /> */}
      </PopoverButton>

      <PopoverPanel
        transition
        className="absolute left-0 z-10 mt-5 flex w-screen max-w-max -translate-x-4 px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="w-screen max-w-md glass-effect flex-auto overflow-hidden rounded-3xl text-sm bg-white">
          <div className="p-4">
            {resources.map((item) => (
              <div
                key={item.name}
                className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-white"
              >
                <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-violet-50 group-hover:text-gray-600">
                  <item.icon
                    aria-hidden="true"
                    className="h-7 w-7 text-gray-600 group-hover:text-gray-900"
                  />
                </div>
                <div>
                  <a
                    href={item.href}
                    className="font-semibold text-gray-600 group-hover:text-gray-600"
                  >
                    {item.name}
                    <span className="absolute inset-0" />
                  </a>
                  <p className="mt-1 text-gray-600 group-hover:text-gray-600">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </PopoverPanel>
    </Popover>
  );
}
