export const FineValueOptions = Array.from({length: 63}, (_, i) => i + 1).map((i) => ({
  name: i,
  fieldKey: i,
}));
export const CoarseValueOptions = [
  {name: 'High', fieldKey: 'COARSE_VALUE_HIGH'},
  {name: 'Medium', fieldKey: 'COARSE_VALUE_MEDIUM'},
  {name: 'Low', fieldKey: 'COARSE_VALUE_LOW'},
];

export const defaultEventsList = [
  {fieldKey: 'app_installed', name: 'app_installed'},
  {fieldKey: 'charged', name: 'charged'},
  {fieldKey: 'first_open', name: 'first_open'},
];

export const getCoarseValue = (fieldKey) => {
  const option = CoarseValueOptions.find(option => option.fieldKey === fieldKey);
  return option ? option.name : ''; // Return the name if option is found, otherwise return an empty string
};
