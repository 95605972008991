import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {DsIconHaravan} from "components/DsIcon";

import AppService from 'services/app';
import {FormProvider, useForm} from "react-hook-form";
import {showInfo, showWarn} from "components/FlashMessage/flashMessageSlice";
import {useDispatch} from "react-redux";
import BackButton from "views/integration/meta_ads/back_button";
import HaravanPartnerConnectionList from "views/integration/haravan/connection/connection_list";
import {PARTNERS} from "consts/partner";


export default function Connection() {
  const methods = useForm();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [connections, setConnections] = useState([]);

  const onSubmit = async (data) => {
    try {
      AppService.integrateHaravan()
    } catch (error) {
      dispatch(showWarn({message: error.message}))
    }
  }

  const getConnection = async () => {
    const result = await AppService.getConnectionByPartner(PARTNERS.HARAVAN)
    setConnections(result)
  }

  const [searchParams] = useSearchParams();
  const status = searchParams.get('status');
  useEffect(() => {
    if (status === "success") {
      dispatch(showInfo({message: 'Connect successfully'}))
    }
    getConnection();
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div id="connection" className={"sm:p-3"}>
        <div className="flex justify-start items-center w-full">
          <h3 className="ds-text-form-title">Connect to your Haravan</h3>
        </div>
        <div className="ds-text-lighter">Enhance your integration to receive data</div>

        <FormProvider {...methods}>
          <form className="flex items-center mt-3" onSubmit={methods.handleSubmit(onSubmit)}>
            <button
              type="submit"

              className="ds-button-with-border mr-3 flex items-center border-2"
            >
              <div className={"mr-2"}>
                <div className={"h-10 w-10"}><DsIconHaravan/></div>
              </div>
              <FontAwesomeIcon icon={faAngleRight}/>
            </button>
          </form>
        </FormProvider>

        <div className={"mt-16"}>
          {connections.length > 0 &&
            <HaravanPartnerConnectionList connections={connections} action={onSubmit}/>
          }
        </div>

        <hr className="h-px mt-6 bg-gray-200 border-0"/>

        <div className="flex justify-between items-center px-4 w-full mt-5 mb-1">
          <BackButton/>
        </div>
      </div>
    </>
  )
}
