import HttpMethod from 'consts/httpMethod';
import { processRequest, processSuccessResponse, processErrorResponse } from 'utils';


export default {
  async getFunnel(payload) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: '/funnel',
      method: HttpMethod.POST
    };

    try {
      const res = await processRequest(ep, { data: payload });
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  /**
   * Represents a cohort data object.
   * @typedef {Object} CohortData
   * @property {number} cohort_month - The month of the cohort.
   * @property {number} month_number - The number of months since the cohort started.
   * @property {number} total_users - The total number of users in the cohort.
   * @property {number} percentage - The percentage of users compared to the total users in the cohort.
   */

  /**
   * Fetches cohort data from the API.
   * @param {Object} payload The payload to be sent with the request.
   * @returns {Promise<Array<CohortData>>} A promise that resolves with an array of cohort data objects.
   */
  async getCohort(payload) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: '/cohort',
      method: HttpMethod.POST
    };

    try {
      const res = await processRequest(ep, { data: payload });
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },
};
