import {Fragment, useEffect, useState} from 'react'
import {Listbox, Transition} from '@headlessui/react'
import {ChevronUpDownIcon} from '@heroicons/react/20/solid'
import {Link, useNavigate, useParams} from "react-router-dom";
import {isUndefinedOrEmpty} from 'utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import {DsIconAndroid, DsIconIos, DsIconWebPlatform} from "components/DsIcon";
import {PLATFORM} from "consts";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function DsListBox({listData = []}) {
  const {appId} = useParams();

  const [selected, setSelected] = useState(listData.length > 0 ? listData[0] : {});
  const navigate = useNavigate()

  const handleOnClick = (url) => {
    navigate(url)
  }

  useEffect(() => {
    if (listData.length > 0) {
      if (isUndefinedOrEmpty(appId)) {
        setSelected(listData[0]);
      } else {
        const item = listData.find((item) => item.appId === appId);

        if (item) {
          setSelected(item);
        } else {
          setSelected({});
        }
      }
    } else {
      setSelected({});
    }
  }, [listData, appId]);

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({open}) => (
        <>
          <div className="relative mt-2">
            <Listbox.Button className="relative w-full ds-input ds-input-text ds-gray-background ds-focus">
              <span className="flex items-center">
                  {selected.logo ? <img src={selected.logo} alt=""
                                        className="h-5 w-5 flex-shrink-0 rounded-full"/>
                    :
                    <div className="h-5 w-5">
                      <DsIconWebPlatform className="h-5 w-5 flex-shrink-0 rounded-full"/>
                    </div>
                  }

                <div className={"ml-3 h-4 w-4"}>
                    {selected.platform === PLATFORM.ANDROID ?
                      <DsIconAndroid className="h-5 w-5 flex-shrink-0 rounded-full"/>
                      :
                      selected.platform === PLATFORM.IOS ?
                        <DsIconIos className="h-5 w-5 flex-shrink-0 rounded-full"/>
                        : ""
                    }
                  </div>
                <span className="ml-3">
                  {selected.name}
                </span>

              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="ds-option shadow-lg ring-1 ring-black sm:text-sm max-h-72">
                {listData.map((element) => (
                  <Listbox.Option
                    key={element.appId}
                    onClick={() => handleOnClick(element.href)}
                    className={({active}) =>
                      classNames(
                        active ? 'ds-hover-color' : '',
                        'relative cursor-pointer select-none py-2 pl-3 pr-9 ds-input-text'
                      )
                    }
                    value={element}
                  >
                    {({selected, active}) => (
                      <>
                        <div className="flex items-center">

                          <div className={"mr-3 h-5 w-5"}>
                            {element.platform === PLATFORM.ANDROID ?
                              <DsIconAndroid className="h-5 w-5 flex-shrink-0 rounded-full"/>
                              :
                              element.platform === PLATFORM.IOS ?
                                <DsIconIos className="h-5 w-5 flex-shrink-0 rounded-full"/>
                                :
                                <DsIconWebPlatform className="h-5 w-5 flex-shrink-0 rounded-full"/>
                            }
                          </div>

                          {element.logo ?
                            <img
                              src={element.logo} alt=""
                              className="h-5 w-5 flex-shrink-0 rounded-full"
                            />
                            :
                            <div className="h-5 w-5"/>
                            // <div className="h-5 w-5">
                            //   <DsIconWebPlatform className="h-5 w-5 flex-shrink-0 rounded-full"/>
                            // </div>
                          }
                          <span
                            className={classNames('ml-3 block truncate')}
                          >
                            <Link to={element.href}>{element.name}</Link>
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-red' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <FontAwesomeIcon className="h-5 w-5" aria-hidden="true" icon={faCheck}/>
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
