import React, {useEffect, useState} from "react";
import {PATHS, PLATFORM} from "consts";
import {useDispatch} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import TextInput from "components/TextInput";
import deeplink from "services/deeplink";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ToggleCheckbox from "components/ToggleCheckbox";
import TailwindComboboxWithoutForm from "components/TailwindCombobox/TailwindComboboxWithoutForm";
import {showError, showInfo} from "components/FlashMessage/flashMessageSlice";
import TextAreaInput from "components/TextAreaInput";
import {faChevronRight, faSquarePlus} from "@fortawesome/free-solid-svg-icons";
import AppSvc from "services/app";
import TailwindCombobox from "components/TailwindCombobox";
import {androidRedirectOptions, iOSRedirectOptions, webRedirectOptions} from "../types";


export default function CreateDeeplink() {
  const methods = useForm();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [listTemplates, setListTemplates] = useState([]);
  const [template, setTemplate] = useState({});
  const [deeplinkResult, setDeeplinkResult] = useState({});
  const [defaultLinkInfo, setDefaultLinkInfo] = useState({});
  const [webApp, setWebApp] = useState(null);

  const isSocialMedia = methods.watch('isSocialMedia');
  const androidRedirect = methods.watch('androidRedirect');
  const iosRedirect = methods.watch('iosRedirect');
  const isEditDisabled = true;


  const getTemplates = async () => {
    try {
      const response = await deeplink.getListTemplates();
      setListTemplates(response.map((t) => ({
        "value": t.id,
        "text": t.name,
      })))
      if (response.length > 0) {
        getTemplateInfo(response[0].id);
      }
    } catch (e) {
      dispatch(showError({message: "Error when get templates"}));
    }
  }

  const genQr = async (link) => {
    try {
      return await deeplink.generateQrCode(link)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getTemplates();
  }, []);

  const getTemplateInfo = async (templateId) => {
    try {
      const result = await deeplink.getTemplateById(templateId);

      const tempValue = [
        {key: 'androidRedirect', value: result.android_is_open_store ? PLATFORM.GGPLAY : PLATFORM.MWEB},
        {key: 'iosRedirect', value: result.ios_is_open_store ? PLATFORM.APPSTORE : PLATFORM.MWEB},
      ]

      tempValue.map((kv) => {
        methods.setValue(kv.key, kv.value);
      });

      setTemplate(result);
      getWebApp(result.app_web_id);
    } catch (e) {
      dispatch(showError({message: "Error when get template"}));
    }
  }

  const getWebApp = async (webAppId) => {
    try {
      if (webAppId && webAppId !== "") {
        const result = await AppSvc.getApp({appId: webAppId});
        setWebApp(result);
      } else {
        setWebApp(null);
      }
    } catch (err) {
    }
  };

  const handleTemplateChanged = (fieldValue) => {
    getTemplateInfo(fieldValue);
  }

  const onSubmit = async (data) => {
    if (!data.androidLinkAppNotInstallPath || data.androidLinkAppNotInstallPath === "undefined") {
      data.androidLinkAppNotInstallPath = ""
    }
    if (!data.iosLinkAppNotInstallPath || data.iosLinkAppNotInstallPath === "undefined") {
      data.iosLinkAppNotInstallPath = ""
    }
    if (!data.webPath || data.webPath === "undefined") {
      data.webPath = ""
    }

    const payload = {
      template_id: template.id,
      name: data.deepLinkName,
      utm_source: data.UTMSource,
      utm_medium: data.UTMMedium,
      utm_campaign: data.UTMCampaign,
      web_deep_link: webApp ? webApp.link : "",
      web_path: data.webPath,
      android_deep_link: template.android_deep_link,
      ios_deep_link: template.ios_deep_link,
      android_is_open_store: androidRedirect === PLATFORM.GGPLAY,
      ios_is_open_store: iosRedirect === PLATFORM.APPSTORE,
      android_link_app_not_install: webApp ? webApp.link : "",
      android_link_app_not_install_path: androidRedirect === PLATFORM.MWEB ? data.androidLinkAppNotInstallPath : "",
      ios_link_app_not_install: webApp ? webApp.link : "",
      ios_link_app_not_install_path: iosRedirect === PLATFORM.MWEB ? data.iosLinkAppNotInstallPath : "",
    }

    if (isSocialMedia) {
      payload.title = data.title;
      payload.image_url = data.imageURL;
      payload.description = data.description;
    }

    try {
      const result = await deeplink.createDeeplink([payload]).then((r) => {
        r.map((l) => {
          genQr(l.full_url_short).then((r) => {
            l.qrcode = r
          })
        })

        return r
      });

      setDeeplinkResult({
          ...payload,
          "links": result,
        }
      )

      dispatch(showInfo({message: "Create link successfully"}));
      window.location = `${PATHS.APP}${PATHS.DEEPLINK}`
    } catch (error) {
      console.log(error)
      dispatch(showError({message: "Create link unsuccessfully"}));
    }
  }

  const handleNavigate = () => {
    window.location.href = `${PATHS.APP}${PATHS.DEEPLINK}`
  }


  return (
    <div id="create-deeplink">
      <div className="ds-input-text mt-3">
        <a
          className="ds-text-color hover:underline"
          target="_blank" href={`${PATHS.APP}${PATHS.DEEPLINK}`}>
          Deeplink
        </a>
        <FontAwesomeIcon className="text-gray-500 ml-2.5 mr-2.5" icon={faChevronRight} size="xs"/>
        Create link
      </div>

      <div className="ds-text-page-title mt-3">Create link</div>

      <FormProvider {...methods} >
        <div className="gap-4 bg-white mt-5 rounded-lg shadown-md sm:p-3 border border-gray-100">
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="ml-4 mt-3">
              <div className="gap-4">
                <div>
                  <div className={"grid grid-cols-5 gap-4"}>
                    <div className="col-span-2">
                      <label className="ds-input-text">Template</label>
                      <TailwindComboboxWithoutForm
                        items={listTemplates}
                        onChange={handleTemplateChanged}
                      />
                    </div>
                  </div>


                  <div className={"grid grid-cols-5 gap-4 mt-6"}>
                    <div className="col-span-2">
                      <label className="ds-input-text">Link name</label>
                      <TextInput
                        name="deepLinkName"
                        className="ds-input ds-input-text w-full"
                        rules={
                          {
                            required: t('Link name is required'),
                          }
                        }
                        defaultValue={template.name}
                      />
                    </div>
                  </div>

                </div>
              </div>

              <hr className="h-px mt-8 bg-gray-200 border-0 mr-4"/>

              <h3 className="ds-text-form-title-2 mt-7">
                {t('Attribution')}
              </h3>

              <div className="grid grid-cols-5 gap-4 mt-5">
                <div className="col-span-2">
                  <span className="ds-input-text">UTM Source</span>
                  <TextInput
                    name="UTMSource"
                    className="ds-input ds-input-text w-full"
                    rules={
                      {
                        required: t('UTM Source is required'),
                      }
                    }
                    placeholder={"google"}
                  />
                </div>
              </div>

              <div className="grid grid-cols-5 gap-4 mt-5">
                <div className="col-span-2">
                  <span className="ds-input-text">UTM Medium</span>
                  <TextInput
                    name="UTMMedium"
                    className="ds-input ds-input-text w-full"
                    placeholder={"cpc"}
                  />
                </div>
              </div>

              <div className="grid grid-cols-5 gap-4 mt-5">
                <div className="col-span-2">
                  <span className="ds-input-text">UTM Campaign</span>
                  <TextInput
                    name="UTMCampaign"
                    className="ds-input ds-input-text w-full"
                    placeholder={"SALE_1111"}
                  />
                </div>
              </div>

              <hr className="h-px mt-8 bg-gray-200 border-0 mr-4"/>

              <div className={""}>
                <h3 className="ds-text-form-title-2 mt-7">
                  Social Media Preview
                </h3>
                <div className={"flex items-center"}>
                  <div className="ds-input-text mt-5">Create your social media preview</div>
                  <ToggleCheckbox name="isSocialMedia" className="mt-5 ms-4"/>
                </div>
                <div>
                  {isSocialMedia
                    &&
                    <>
                      <div className="grid grid-cols-5 gap-4 mt-4">
                        <div className="col-span-2">
                          <span className="ds-input-text">Title</span>
                          <TextInput
                            name="title"
                            className="ds-input ds-input-text w-full"
                            rules={
                              {
                                required: t('Title is required'),
                              }
                            }
                            placeholder={"DataSenses - Growth Analytics Provider"}
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-5 gap-4 mt-5">
                        <div className="col-span-2">
                          <span className="ds-input-text">Description</span>
                          <TextAreaInput
                            name="description"
                            id="description"
                            className="w-full ds-input h-32"
                            placeholder={"DataSenses is a Growth Analytics Provider that helps you to focus on your growth, not on mess data."}
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-5 gap-4 mt-5">
                        <div className="col-span-2">
                          <span className="ds-input-text">Image URL</span>
                          <TextInput
                            name="imageURL"
                            className="ds-input ds-input-text w-full"
                            rules={
                              {}
                            }
                            placeholder={"https://example.com/image.jpg"}
                          />
                        </div>
                      </div>

                      {/*<div className={"space-y-0.5"}>*/}
                      {/*  <label className="pl-1 ds-input-text">Preview</label>*/}
                      {/*</div>*/}
                    </>
                  }


                </div>

              </div>

              <hr className="h-px mt-7 bg-gray-200 border-0 mr-4"/>

              <div className={""}>
                <h3 className="ds-text-form-title-2 mt-7">
                  Redirection
                </h3>

                <div className={"space-y-6"}>
                  {
                    template.app_ios_id !== "" || template.app_android_id !== ""
                      ?
                      <>
                        <div className="mt-5 mr-4">
                          <span className="text-sm font-semibold text-gray-900">When app is not installed</span>
                          <table className="bg-white w-full mr-100 border-collapse border border-gray-200 rounded-lg">
                            <tbody className="text-gray-600 ds-input-text divide-y divide-gray-200">
                            <tr>
                              <td
                                className="px-6 py-4 w-1/6 whitespace-nowrap text-center border border-gray-200 font-medium">Android
                              </td>
                              <td className="px-7 py-6">
                                <div>
                                  <span className="ds-input-text">Redirect to</span>
                                  {
                                    androidRedirect === PLATFORM.MWEB ?
                                      <div>
                                        <div className="grid grid-cols-3 mb-2 gap-3">
                                          <div>
                                            <TailwindCombobox
                                              name="androidRedirect"
                                              items={androidRedirectOptions}
                                            />
                                          </div>
                                          <div>
                                            <TextInput
                                              name="androidMwebUrl"
                                              className="ds-disabled-input w-full hover:border-gray-300 focus:border-gray-300"
                                              disabled={isEditDisabled}
                                              placeholder={webApp ? webApp.link : ""}
                                            />
                                          </div>

                                          <div className="flex items-center justify-stretch">
                                            <div>/</div>
                                            <div className={"flex-1 ml-3"}>
                                              <TextInput
                                                name="androidLinkAppNotInstallPath"
                                                className="ds-input-text w-full"/>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      :
                                      <div className="grid grid-cols-3 mb-2 gap-1">
                                        <TailwindCombobox
                                          name="androidRedirect"
                                          items={androidRedirectOptions}
                                        />
                                      </div>
                                  }
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="px-6 py-4 w-1/6 whitespace-nowrap text-center border border-gray-200 font-medium">iOS
                              </td>
                              <td className="px-7 py-6">
                                <div>
                                  <span className="ds-input-text">Redirect to</span>
                                  {
                                    iosRedirect === PLATFORM.MWEB ?
                                      <div>
                                        <div className="grid grid-cols-3 mb-2 gap-3">
                                          <div>
                                            <TailwindCombobox
                                              name="iosRedirect"
                                              items={iOSRedirectOptions}
                                            />
                                          </div>
                                          <div>
                                            <TextInput
                                              name="iosdMwebUrl"
                                              className="ds-disabled-input w-full hover:border-gray-300 focus:border-gray-300"
                                              disabled={isEditDisabled}
                                              placeholder={webApp ? webApp.link : ""}
                                            />
                                          </div>
                                          <div className="flex items-center justify-stretch">
                                            <div>/</div>
                                            <div className={"flex-1 ml-3"}>
                                              <TextInput
                                                name="iosLinkAppNotInstallPath"
                                                className="ds-input-text w-full"/>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      :
                                      <div className="grid grid-cols-3 mb-2 gap-1">
                                        <TailwindCombobox
                                          name="iosRedirect"
                                          items={iOSRedirectOptions}
                                        />
                                      </div>
                                  }
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>

                        </div>
                        <div className="mr-4">
                          <span className="text-sm font-semibold text-gray-900">When app is installed</span>
                          <div className={""}>
                            <table className="bg-white w-full border-collapse border border-gray-200 rounded-lg">
                              <tbody className="text-gray-600 ds-input-text divide-y divide-gray-200">
                              <tr>
                                <td
                                  className="px-6 py-4 w-1/6 whitespace-nowrap text-center border border-gray-200 font-medium">Android
                                </td>
                                <td className="px-7 py-5">
                                  <div>
                                    <div className="grid grid-cols-3 mb-2 gap-1 mt-2">
                                      <div className="col-span-2">
                                        <TextInput
                                          name="androidDeeplink"
                                          className="ds-disabled-input w-full hover:border-gray-300 focus:border-gray-300"
                                          disabled={isEditDisabled}
                                          placeholder={template.android_deep_link !== "" ? template.android_deep_link : "Android deeplink"}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  className="px-6 py-4 w-1/6 whitespace-nowrap text-center border border-gray-200 font-medium">iOS
                                </td>
                                <td className="px-7 py-5">
                                  <div>
                                    <div className="grid grid-cols-3 mb-2 gap-1 mt-2">
                                      <div className="col-span-2">
                                        <TextInput
                                          name="iosDeeplink"
                                          className="ds-disabled-input w-full hover:border-gray-300 focus:border-gray-300"
                                          disabled={isEditDisabled}
                                          placeholder={template.ios_deep_link !== "" ? template.ios_deep_link : "iOS deeplink"}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              </tbody>
                            </table>

                            <div>
                            </div>
                          </div>
                        </div>
                      </>
                      :
                      <>
                      </>
                  }
                  <div>
                    <span
                      className="text-sm font-semibold text-gray-900">When link is clicked on desktop web page</span>
                    <div className={"mr-4"}>
                      <table className="bg-white w-full border-collapse border border-gray-200 rounded-lg">
                        <tbody className="text-gray-600 ds-input-text divide-y divide-gray-200">
                        <tr>
                          <td
                            className="px-6 py-4 w-1/6 whitespace-nowrap text-center border border-gray-200 font-medium">Desktop
                          </td>
                          <td className="px-7 py-6">
                            <div>
                              <span className="ds-input-text">Redirect to</span>
                              <div>
                                <div className="grid grid-cols-3 mb-2 gap-3">
                                  <div>
                                    <TailwindCombobox
                                      name="webRedirect"
                                      items={webRedirectOptions}
                                    />
                                  </div>
                                  <div>
                                    <TextInput
                                      name="webDeepLink"
                                      className="ds-disabled-input w-full hover:border-gray-300 focus:border-gray-300"
                                      disabled={isEditDisabled}
                                      placeholder={webApp ? webApp.link : ""}
                                    />
                                  </div>
                                  <div className="flex items-center justify-stretch">
                                    <div>/</div>
                                    <div className={"flex-1 ml-3"}>
                                      <TextInput
                                        name="webPath"
                                        className="ds-input-text w-full"/>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>

                    </div>
                  </div>
                </div>
              </div>

              <hr className="h-px mt-8 bg-gray-200 border-0 mr-4"/>

              <div className="flex items-center justify-end mr-4 mt-7 mb-4">
                <button
                  type="submit"
                  className="ds-button"
                >
                  <FontAwesomeIcon className="me-2" icon={faSquarePlus}/>
                  Create
                </button>
              </div>
            </div>
          </form>
        </div>
      </FormProvider>
    </div>
  )
}


