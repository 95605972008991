import { DsIconLogo } from "components/DsIcon";
import { useNavigate } from "react-router-dom";

export function Logo() {
  const navigate = useNavigate()

  return (
    <div onClick={() => navigate('/')}>
      <DsIconLogo fill={"black"} />
      <span className="-ml-4">ataSenses</span>
    </div>
  )
}
