import './index.scss';
import {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {DsIconShopify} from "components/DsIcon";
import {shopifyTabs, tabLink} from '../tabs';
import Connection from "./connection";
import ListDataSources from "./configurations";


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ShopifyIntegration() {
  const {t} = useTranslation();
  const [hashValue, setHashValue] = useState(window.location.hash || tabLink.CONNECTION);


  useEffect(() => {
    const handleHashChange = () => {
      setHashValue(window.location.hash);
    };

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);


  return (
    <div id="shopify" className="relative pb-5 sm:pb-0 ds-input-text">
      <div className="flex items-center w-full">
        <div className="mr-2">
          <div className={"h-10 w-10"}><DsIconShopify/></div>
        </div>

          <div className="ds-text-page-title">Shopify</div>
      </div>

      <div className="mt-4">
        <nav className="-mb-px flex space-x-8">
          {shopifyTabs.map((tab) => (
            <a
              id={tab.name}
              key={tab.name}
              href={tab.href}
              className={classNames(
                hashValue === tab.href
                  ? 'ds-tab-color'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                'whitespace-nowrap select-none cursor-pointer border-b-2 px-1 pb-2 text-sm font-medium'
              )}
              aria-current={tab.current ? 'page' : undefined}
            >
              {t(tab.name)}
            </a>
          ))}
        </nav>

        <div className="relative flex rounded mt-3 ds-block">
          <div className="flex-auto">
            <div className="tab-content tab-space">
              {hashValue === tabLink.CONFIGURATIONS && <ListDataSources/>}
              {hashValue === tabLink.CONNECTION && <Connection/>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
