import {useParams} from "react-router-dom";
import { StarIcon } from '@heroicons/react/20/solid'
import {isUndefinedOrEmpty} from "utils";
import {useEffect, useState} from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const products = [
  {
    id: "123",
    title: "DataSenses phân tích dữ liệu",
    description: "Tranh đá quý tân phú khánh",
    link: "https://datasenses.io/mock/product/123",
    image_link: "https://datasenses.io/static/media/cohort.11d4887fe436c0888331.png",
    condition: "new",
    availability: "in stock",
    price: 16000,
    brand: "OEM",
    rating: 5,
    reviewCount: 10,
    images: [{
      link: "https://datasenses.io/static/media/cohort.11d4887fe436c0888331.png",
      id: "123",
    }],
  },
  {
    id: "456",
    title: "DataSenses phân tích khách hàng",
    description: "DataSenses phân tích khách hàng",
    link: "https://datasenses.io/mock/product/456",
    image_link: "https://datasenses.io/static/media/roi.29032c6be196f422b48a.png",
    condition: "new",
    availability: "in stock",
    rating: 5,
    price: 15000,
    reviewCount: 15,
    brand: "OEM",
    images: [{
      link: "https://datasenses.io/static/media/roi.29032c6be196f422b48a.png",
      id: "456",
    }],
  },
]

export default function MockProduct() {
  const {productId} = useParams()
  const [productInfo, setProductInfo] = useState(null)

  useEffect(() => {
    const result = products.filter(p => p.id === productId)[0]
    setProductInfo(result)
  }, []);

  isUndefinedOrEmpty()
  return (
    <>
      {
        productInfo &&
          <div className="bg-white">
            <div className="pb-16 pt-6 sm:pb-24">
              <nav aria-label="Breadcrumb" className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <ol role="list" className="flex items-center space-x-4">
                  <li className="text-sm">
                    <a href={productInfo.link} aria-current="page" className="font-medium text-gray-500 hover:text-gray-600">
                      {productInfo.title}
                    </a>
                  </li>
                </ol>
              </nav>
              <div className="mx-auto mt-8 max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="lg:grid lg:auto-rows-min lg:grid-cols-12 lg:gap-x-8">
                  <div className="lg:col-span-5 lg:col-start-8">
                    <div className="flex justify-between">
                      <h1 className="text-xl font-medium text-gray-900">{productInfo.title}</h1>
                      <p className="text-xl font-medium text-gray-900">{productInfo && productInfo.price}</p>
                    </div>
                    {/* Reviews */}
                    <div className="mt-4">
                      <h2 className="sr-only">Reviews</h2>
                      <div className="flex items-center">
                        <p className="text-sm text-gray-700">
                          {productInfo.rating}
                          <span className="sr-only"> out of 5 stars</span>
                        </p>
                        <div className="ml-1 flex items-center">
                          {[0, 1, 2, 3, 4].map((rating) => (
                            <StarIcon
                              key={rating}
                              className={classNames(
                                productInfo.rating > rating ? 'text-yellow-400' : 'text-gray-200',
                                'h-5 w-5 flex-shrink-0'
                              )}
                              aria-hidden="true"
                            />
                          ))}
                        </div>
                        <div aria-hidden="true" className="ml-4 text-sm text-gray-300">
                          ·
                        </div>
                        <div className="ml-4 flex">
                          <a href="#" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
                            See all {productInfo.reviewCount} reviews
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Image gallery */}
                  <div className="mt-8 lg:col-span-7 lg:col-start-1 lg:row-span-3 lg:row-start-1 lg:mt-0">
                    <h2 className="sr-only">Images</h2>

                    <div className="grid grid-cols-1 lg:grid-cols-1 lg:grid-rows-3 lg:gap-8 w-140 h-150">
                      {productInfo.images.map((image) => (
                        <img
                          key={image.id}
                          src={image.link}
                          className={classNames(
                            image.primary ? 'lg:col-span-2 lg:row-span-2' : 'hidden lg:block',
                            'rounded-lg'
                          )}
                        />
                      ))}
                    </div>
                  </div>

                  <div className="mt-8 lg:col-span-5">
                    <form>
                      <div>
                        <h2 className="text-sm font-medium text-gray-900">Color</h2>
                      </div>

                      <div className="mt-8">
                        <div className="flex items-center justify-between">
                          <h2 className="text-sm font-medium text-gray-900">Size</h2>
                          <a href="#" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
                            See sizing chart
                          </a>
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="mt-8 flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Add to cart
                      </button>
                    </form>

                    <div className="mt-10">
                      <h2 className="text-sm font-medium text-gray-900">Description</h2>

                      <div
                        className="prose prose-sm mt-4 text-gray-500"
                        dangerouslySetInnerHTML={{__html: productInfo.description}}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      }
    </>
  )
}
