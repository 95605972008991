import { useEffect } from 'react';
import { CHART_CONFIG } from 'consts';
import { CONTAINS } from 'consts/chartConfig';

import TailwindComboboxWithoutForm from "components/TailwindCombobox/TailwindComboboxWithoutForm";

import types from './types';

import './index.scss';
/**
 * Component for rendering a property input.
 * @param {Object} props - The props object.
 * @param {Object} [props.value={ property: "", operator: "", value: "" }] - The value of the property input.
 * @param {Function} [props.onChange=() => {}] - The function to handle value changes.
 * @param {Function} [props.className=''] - Additional classes for styling.
 * @returns {JSX.Element} A React component representing the PropertyInput.
 */
export default function PropertyInput({
  value = {
    property: "",
    operator: CONTAINS,
    value: "",
  },
  eventPropertyOptions = [],
  onChange = () => { },
  className,
}) {
  const handleSelectPropertyChange = (fieldValue) => {
    onChange({
      ...value,
      property: fieldValue,
    });
  };

  const handleSelectOperatorChange = (fieldValue) => {
    onChange({
      ...value,
      operator: fieldValue,
    });
  };

  const handleValueChange = (event) => {
    onChange({
      ...value,
      value: event.target.value,
    });
  };

  const handleSelectFirstValueChange = (event) => {
    onChange({
      ...value,
      value: [event.target.value, value.value[1]],
    });
  };

  const handleSelectSecondValueChange = (event) => {
    onChange({
      ...value,
      value: [value.value[0], event.target.value],
    });
  };

  useEffect(() => {
    if (value.value) {
      return;
    }
    let newValue = "";

    if (value.operator === types.BETWEEN) {
      newValue = [];
    }

    onChange({
      ...value,
      value: newValue,
    });
  }, [value.operator]);

  const renderOperartorInput = () => {
    if (value.operator === types.BETWEEN) {
      return (
        <>
          <input
            value={value.value[0]}
            onChange={handleSelectFirstValueChange}
            className="input-style rounded border border-gray-300 focus:outline-none py-1.5 px-3 "
          />
          <input
            value={value.value[1] ?? ''}
            onChange={handleSelectSecondValueChange}
            className="input-style rounded border border-gray-300 focus:outline-none py-1.5 px-3 ms-1"
          />
        </>
      );
    }
    return (
      <input
        value={value.value}
        onChange={handleValueChange}
        className="ds-input ds-input-text "
      />
    );
  };

  return (
    <div className={`property-input flex justify-start items-center w-full gap-4 ${className} ds-violet-border`}>
      <div>
        <TailwindComboboxWithoutForm  value={value.property} items={eventPropertyOptions} onChange={handleSelectPropertyChange} />
      </div>
      <div>
        <TailwindComboboxWithoutForm value={value.operator} items={CHART_CONFIG.OPERATORS} onChange={handleSelectOperatorChange} />
      </div>
      <div>
        {renderOperartorInput()}
      </div>
    </div>
  );
}
