import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PATHS } from 'consts'
import { Link } from 'react-router-dom'


export default function AppEmpty() {
  return (
    <div className="text-center mt-20">
      <svg
        className="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-sm font-semibold text-gray-900">No Apps</h3>
      <p className="mt-1 text-sm text-gray-500">Create your app or finalize the first app integration</p>
      <div className="mt-6 pb-10">
        <Link
          to={PATHS.APP}
          style={{backgroundColor: "#4F46E5"}}
          className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <FontAwesomeIcon icon={faPlus} className="-ml-0.5 mr-1.5 h-4 w-4" aria-hidden="true" />
          Add New App
        </Link>
      </div>
    </div>
  )
}
