import React, {useCallback, useEffect, useState} from "react";
import {CHART_CONFIG} from "consts";
import ChartSerivce from "services/chart";
import {debounce} from "lodash";
import VisualisationEmpty from "views/visualisation/visualisation_empty";
import {parseTableChartResponse} from "views/visualisation/chartProcessor";
import {
  MaterialReactTable,
  MRT_ExpandAllButton,
  MRT_TablePagination,
  useMaterialReactTable
} from "material-react-table";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {dsVioletBackgroundColor, dsVioletColor} from "consts/color";
import {Box, Stack} from "@mui/material";
import {isUndefinedOrEmpty} from "utils";

export default function VisualisationTableChart({
                                                  appId,
                                                  globalFilter = [],
                                                  chartId,
                                                  dimensionValue,
                                                  metricValue,
                                                  limitValue,
                                                  showNonZero = true,
                                                  orderValue,

                                                }) {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [grouping, setGrouping] = useState([]);
  const [aggregation, setAggregation] = useState([]);

  const getData = useCallback(async () => {
    if ((dimensionValue && metricValue && globalFilter.length > 0)) {
      const request = {
        [CHART_CONFIG.FIELD_CHART_NAME]: chartId,
        [CHART_CONFIG.FIELD_CHART_TYPE]: CHART_CONFIG.CHART_TYPE_BARCHART,
        [CHART_CONFIG.FIELD_GLOBAL_FILTERS]: globalFilter,
      };

      const metrics = Array.isArray(metricValue) ? metricValue : [metricValue];
      request[CHART_CONFIG.FIELD_METRIC] = metrics.map(metric => ({[CHART_CONFIG.FIELD_NAME]: metric}));

      const dimensions = Array.isArray(dimensionValue) ? dimensionValue : [dimensionValue];
      request[CHART_CONFIG.FIELD_GROUP_BY] = dimensions.map(dimension => ({[CHART_CONFIG.FIELD_NAME]: dimension}));


      if (!isUndefinedOrEmpty(limitValue) && limitValue !== "All") {
        request[CHART_CONFIG.FIELD_LIMIT] = [{[CHART_CONFIG.FIELD_VALUE]: String(limitValue)}]
      }

      if (!isUndefinedOrEmpty(orderValue)) {
        request[CHART_CONFIG.FIELD_ORDER_BY] = [{[CHART_CONFIG.FIELD_NAME]: metrics[0] + orderValue}];
      }

      setIsLoading(true);
      try {
        const response = await ChartSerivce.getChart(appId, request);
        if (response) {
          const result = parseTableChartResponse({
            responsePayload: response.data,
            showNonZero,
            dimensionValue,
          });
          setData(result.rows || []);
          setColumns(result.columns || []);
          setGrouping(result.grouping);

          const aggResult = result.columns.reduce((acc, item) => {
            if (item.fieldType === 'metric' && item.aggregationFn) {
              acc[item.accessorKey] = item.aggregationFn;
            }
            return acc;
          }, {});
          setAggregation(aggResult)
        } else {
          setData([]);
        }
      } catch (error) {
        console.error("Error fetching chart data:", error);
        setData([]);
      } finally {
        setIsLoading(false);
      }
    }
  }, [appId, chartId, dimensionValue, globalFilter, limitValue, showNonZero, orderValue]);

  useEffect(() => {
    const debouncedGetData = debounce(getData, 500);
    debouncedGetData();
    return () => debouncedGetData.cancel();
  }, [getData]);

  const theme = createTheme({
    components: {
      MuiAlert: {
        styleOverrides: {
          root: {
            backgroundColor: dsVioletBackgroundColor,
          },
        },
      },
    },
  });

  return (
    <div className="w-full mt-4 ds-white-background ds-border-gray">
      {!isLoading && data.length > 0 ? (
        <ThemeProvider theme={theme}>
          <MaterialReactTable
            columns={columns}
            data={data}
            enableGrouping
            enableColumnPinning={true}
            initialState={{
              grouping: grouping.length > 1 ? grouping.slice(0, -1) : [],
              aggregation: aggregation,
            }}
            enableStickyHeader
            muiTableContainerProps={{
              sx: {
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              },
            }}
            muiTablePaperProps={{
              elevation: 0,
            }}
            muiPaginationProps={{
              variant: 'outlined',
            }}
            filterFromLeafRows={true}
            paginationDisplayMode={'pages'}
            enableCellActions={true}
            enableClickToCopy={true}
            enableColumnOrdering={true}
          />
        </ThemeProvider>
      ) : (
        <VisualisationEmpty isLoading={isLoading}/>
      )}
    </div>
  );
}
