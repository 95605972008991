import { useState, Children, useRef, useEffect } from 'react';
import $ from 'jquery';


import './index.scss';

/**
 * Tooltip component for displaying additional information on hover.
 * @param {Object} props - The props object.
 * @param {JSX.Element} props.children - The children elements, including Label and Content.
 * @param {string} [props.className=''] - Additional classes for styling.
 * @param {string} [props.minWidth=''] - Additional width.
 * @returns {JSX.Element} A React component representing the Tooltip.
 */
const Tooltip = ({
  children,
  minWidth,
  className,
}) => {
  let _label, _content;

  Children.forEach(children, child => {
    if (child.type === Label) {
      return _label = child
    }

    if (child.type === Content) {
      return _content = child
    }
  });

  const wrapper = useRef(null);

  const [active, setActive] = useState(false);

  const handleMouseEnter = () => setActive(true);

  const handleMouseLeave = () => setActive(false);

  useEffect(() => {
    if (active) {
      $(wrapper.current).show();
    } else {
      setTimeout(() => $(wrapper.current).hide(), 400);
    }
  }, [active]);

  return (
    <div className={`tooltip-box ${className}`}>
      <div
        className='cursor-pointer'
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {_label}
      </div>

      <div
        className={`tooltip-box-wrapper select-none pointer-events-none rounded-md text-sm text-black px-2 py-1 transition-opacity duration-300 ease-in-out ${active ? 'opacity-100' : 'opacity-0'}`}
        style={{ minWidth: minWidth ? `${minWidth}px` : '100%' }}
      >
        {_content}
      </div>
    </div>
  );
}

const Label = ({ children }) => <div>{children}</div>
const Content = ({ children }) => <div>{children}</div>

Tooltip.Label = Label;
Tooltip.Content = Content;

export default Tooltip;
