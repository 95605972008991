import React, { useState, useRef, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import { useTranslation } from 'react-i18next';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { showError, showInfo } from "components/FlashMessage/flashMessageSlice";
import TextInput from 'components/TextInput';
import {PATHS} from "consts";

import { isUndefinedOrEmpty } from 'utils';
import Visualisation from "views/dashboard_list/dashboard_detail/card_visualisation";
import DashboardSvc from "services/dashboard";
import CardSvc from "services/card";


export default function Dashboard() {
  const dispatch = useDispatch();
  const methods = useForm();
  const { t } = useTranslation();

  const columns = 16;
  const tileHeight = 60;
  const gap = 10;
  const defaultCardWidth = columns / 2;
  const defaultCardHeight = 7;

  const dashboardId = useParams();
  const [dashboard, setDashboard] = useState({});
  const [cards, setCards] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [positions, setPositions] = useState([]);
  const [tileWidth, setTileWidth] = useState();
  const [sizes, setSizes] = useState([]);
  const [tiles, setTiles] = useState([]);
  const gridRef = useRef(null);
  const insideBlockRef = useRef(null);

  const getDashboard = async () => {
    try {
      const result = await DashboardSvc.getDashboardDetail(dashboardId);
      setDashboard(result);
    } catch (error) {
      const { message } = error;
      dispatch(showError({ message }));
    }
  }

  const listCardsByDashboardId = async () => {
    try {
      const result = await CardSvc.listCardsByDashboardId(dashboardId);
      result.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

      const parsedData = result.map(item => ({
        ...item,
        payload: JSON.parse(item.payload),
        position: item.position ? JSON.parse(item.position) : {},
      }));
      setCards(parsedData);
    } catch (error) {
      const { message } = error;
      dispatch(showError({ message }));
    }
  }

  useEffect(() => {
    getDashboard();
    listCardsByDashboardId();
  }, [dashboardId]);

  const calculateInitialTiles = () => {
    const screenHeight = 400;
    const rowsNeeded = Math.ceil(screenHeight / tileHeight)*2;
    const totals = rowsNeeded * columns;

    setTiles(Array.from({ length: totals })); 
  };

  

  const calculateTileWidth = () => {
    if (gridRef.current) {
      const width = (gridRef.current.offsetWidth - ((columns-1)*gap))/columns;
      setTileWidth(width);
    } else {
      const width = 80;
      setTileWidth(width);
    }
    
    const initialSizes = cards.map((card, _) => ({
      x: isUndefinedOrEmpty(card.position) ? defaultCardWidth*tileWidth+(defaultCardWidth-1)*gap : card.position.x*tileWidth + (card.position.x-1)*gap,
      y: isUndefinedOrEmpty(card.position) ? defaultCardHeight*tileHeight+(defaultCardHeight-1)*gap : card.position.y*tileHeight + (card.position.y-1)*gap,
      cardId: card.id,
    }))
    setSizes(initialSizes);

    const initialPositions = cards.map((card, index) => ({
      top: isUndefinedOrEmpty(card.position) ? Math.floor(index / 2)*defaultCardHeight * (tileHeight + gap) : card.position.top*(tileHeight + gap),
      left: isUndefinedOrEmpty(card.position) && index % 2 === 0 ? 0 :
        isUndefinedOrEmpty(card.position) && index % 2 === 1 ? defaultCardWidth*(tileWidth + gap) : 
          card.position.left*(tileWidth + gap),
      cardId: card.id,
    }))
    setPositions(initialPositions);
  }

  const handleScroll = () => {
    if (window.innerHeight + window.scrollY >= document.body.scrollHeight - 100) {
      setTiles((prevTiles) => [
        ...prevTiles,
        ...Array.from({ length: columns })
      ]);
    }
  };

  useEffect(() => {
    calculateInitialTiles();
    calculateTileWidth();

    window.addEventListener('resize', calculateTileWidth);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('resize', calculateTileWidth);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [cards]);

  const handleMoveMouseDown = (index, e) => {
    e.preventDefault();
  
    const startX = e.pageX;
    const startY = e.pageY;
    const startTop = positions ? positions[index].top : 0;
    const startLeft = positions ? positions[index].left : 0;
  
    const onMouseMove = (event) => {
      const deltaX = event.pageX - startX;
      const deltaY = event.pageY - startY;

      setPositions(prevPositions => {
        const newPositions = [...prevPositions]
        newPositions[index] = {
          top: Math.max(0, startTop + deltaY),
          left: Math.max(0, startLeft + deltaX),
          cardId: newPositions[index].cardId,
        };

        return newPositions;
      });
    };
  
    const onMouseUp = () => {
      setPositions(prevPositions => {
        const newPositions = [...prevPositions]
        newPositions[index] = {
          top: Math.round(prevPositions[index].top / (tileHeight + gap)) * (tileHeight + gap),
          left: Math.round(prevPositions[index].left / (tileWidth + gap)) * (tileWidth + gap),
          cardId: newPositions[index].cardId,
        };
        return newPositions;  
      });

      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  const handleResizeMouseDown = (index, e, resizePosition) => {
    e.preventDefault();

    const startSize = sizes[index];
    const startPosition = { x: e.pageX, y: e.pageY}
    const startTop = positions ? positions[index].top : 0;
    const startLeft = positions ? positions[index].left : 0;

    const onMouseMove = (event) => {
      let newWidth = sizes[index].x;
      let newHeight = sizes[index].y;
      let newTop = positions[index]?.top || 0;
      let newLeft = positions[index]?.left || 0;

      if (resizePosition.includes('left')) {
        newWidth = Math.max(tileWidth, startSize.x - (event.pageX - startPosition.x))
        newLeft = Math.max(0, startLeft + (event.pageX - startPosition.x))
      }
      if (resizePosition.includes('right')) {
        newWidth = Math.max(tileWidth, startSize.x + (event.pageX - startPosition.x))
      }
      if (resizePosition.includes('top')) {
        newHeight = Math.max(tileHeight, startSize.y - (event.pageY - startPosition.y))
        newTop = Math.max(0, startTop + event.pageY - startPosition.y)
      }
      if (resizePosition.includes('bottom')) {
        newHeight = Math.max(tileHeight, startSize.y + (event.pageY - startPosition.y))
      }

      setSizes(prevSizes => {
        const newSizes = [...prevSizes];
        newSizes[index] = {
          x: newWidth,
          y: newHeight,
          cardId: newSizes[index].cardId,
        }
        return newSizes;
      });
      setPositions(prevPositions => {
        const newPositions = [...prevPositions];
        newPositions[index] = {
          top: newTop,
          left: newLeft,
          cardId: newPositions[index].cardId,
        }
        return newPositions
      })
    }

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);

      setSizes(prevSizes => {
        const newSizes = [...prevSizes];
        newSizes[index] = {
          x: Math.round(prevSizes[index].x / (tileWidth + gap)) * (tileWidth + gap) - gap,
          y: Math.round(prevSizes[index].y / (tileHeight + gap)) * (tileHeight + gap) - gap,
          cardId: newSizes[index].cardId,
        }
        return newSizes;
      });

      setPositions(prevPositions => {
        const newPositions = [...prevPositions]
        newPositions[index] = {
          top: Math.round(prevPositions[index].top / (tileHeight + gap)) * (tileHeight + gap),
          left: Math.round(prevPositions[index].left / (tileWidth + gap)) * (tileWidth + gap),
          cardId: newPositions[index].cardId,
        };
        return newPositions;
      });
    } 

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  const handleCancel = () => {
    setIsEdit(false);
    getDashboard();
    listCardsByDashboardId();
  }

  const onSubmit = async (data) => {
    try {
      if (data.dashboardName !== dashboard.name) {
        await DashboardSvc.updateDashboard({ dashboardId: dashboardId.dashboardId, name: data.dashboardName });
      }

      const payload = sizes.reduce((acc, obj) => {
        const {cardId, ...rest} = obj;
        rest.x = (rest.x + gap) / (tileWidth + gap)
        rest.y = (rest.y + gap) / (tileHeight + gap)
        acc[cardId] = rest;
        return acc
      }, {});

      positions.forEach(item => {
        const {cardId, ...rest} = item;
        if (payload[cardId]) {
          rest.top = rest.top / (tileHeight + gap)
          rest.left = rest.left / (tileWidth + gap)
          payload[cardId] = {...payload[cardId], ...rest};
        }
      });

      for (const [cardId, position] of Object.entries(payload)) {
        const processedPosition = JSON.stringify(position);

        await CardSvc.updateCard(
          cardId, 
          dashboardId.dashboardId, 
          {
            id: cardId,
            dashboardId: dashboardId.dashboardId,
            position: processedPosition,
          },
        )
      };

      dispatch(showInfo({ message: t("UPDATE_VALUE_SUCCESSFULLY", { Value: "Dashboard" }) }));
      setIsEdit(false);
      getDashboard();
      listCardsByDashboardId();
    } catch (error) {
      const { message } = error;
      dispatch(showError({ message }));
    }
    getDashboard();
    setIsEdit(false);
    listCardsByDashboardId();
  }


  return (
    <div id="dashboard">
      <div className="flex ds-input-text mt-3">
        <Link
          to={`${PATHS.APP}${PATHS.DASHBOARD}`}
          className="ds-input-text flex items-center text-sm hover:underline"
        >
          <FontAwesomeIcon icon={faAngleLeft}/>
          <p className={"ml-2"}>
            All dashboards
          </p>
        </Link>
      </div>

      {
        isEdit ?
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div className="flex justify-between items-center mt-3">
                <div className="flex">
                  <TextInput
                    name="dashboardName"
                    className="ds-input ds-input-text w-full"
                    defaultValue={dashboard.name}
                  />
                </div>
                <div className="flex items-center justify-end">
                  <button
                    type="button"
                    className="ds-button-cancel mr-2"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="ds-button"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </FormProvider>
          :
          <div className="flex justify-between items-center mt-3">
            <div className="ds-text-page-title flex">{dashboard.name}</div>
            <div className="flex items-center justify-end">
              <button
                type="button"
                className="ds-button"
                onClick={() => setIsEdit(true)}
              >
                Edit
              </button>
            </div>
          </div>
      }
      
      <div className="w-full mt-7">
        <div ref={gridRef} className="grid-container-wrapper" style={{position: 'relative'}}>
          {
            isEdit ?
              <div className="grid-container">
                {tiles.map((_, index) => (
                  <div key={index} className="grid-item">
                  </div>
                ))}
              </div>
            : <div></div>
          }

          <div className="">
            {cards && cards.map((card, index) => (
              <div
                ref={insideBlockRef}
                className={isEdit ? "ds-block-card-edit" : "ds-block-card"}
                style={
                  {
                    top: positions[index]?.top,
                    left: positions[index]?.left,
                    width: sizes[index]?.x,
                    height: sizes[index]?.y,
                  } 
                }
              >
                <div 
                  className=""
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    maxWidth: sizes[index]?.x*0.9,
                    maxHeight: sizes[index]?.y*0.9,
                    overflow: 'auto'
                  }}
                >
                  <Visualisation
                    appId={card.payload.appId}
                    card={card}
                    chartType={card.payload.chartType}
                    defaultChartType={card.payload.defaultChartType}
                    globalFilter={card.payload.globalFilter}
                    chartId={card.payload.chartId}
                    dimensionValue={card.payload.dimensionValue}
                    limitValue={card.payload.limitValue}
                    showDelete={true}
                    frequency={card.payload.frequency}
                    metricValue={card.payload.metricValue}
                    orderValue={card.payload.orderValue}
                    reset={listCardsByDashboardId}
                    onMoveMouseDown={(e) => handleMoveMouseDown(index, e)}
                    isEdit={isEdit}
                  />
                </div>
                
                {
                  isEdit ? 
                  <div>
                    <div className="resize-handle-bottom-right" onMouseDown={(e) => handleResizeMouseDown(index, e, "bottom-right")}></div>
                    <div className="resize-handle-bottom-left" onMouseDown={(e) => handleResizeMouseDown(index, e, "bottom-left")}></div>
                    <div className="resize-handle-top-right" onMouseDown={(e) => handleResizeMouseDown(index, e, "top-right")}></div>
                    <div className="resize-handle-top-left" onMouseDown={(e) => handleResizeMouseDown(index, e, "top-left")}></div>    
                    <div className="resize-handle-left" onMouseDown={(e) => handleResizeMouseDown(index, e, "left")}></div>
                    <div className="resize-handle-right" onMouseDown={(e) => handleResizeMouseDown(index, e, "right")}></div>
                    <div className="resize-handle-top" onMouseDown={(e) => handleResizeMouseDown(index, e, "top")}></div>
                    <div className="resize-handle-bottom" onMouseDown={(e) => handleResizeMouseDown(index, e, "bottom")}></div>
                  </div>
                  : <div></div>
                }
              </div>      
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
