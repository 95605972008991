export default function DsIconLpCohort() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 372.61">
      <g>
        <path fill="#ebebeb" d="M0 327.45H500V327.7H0z"></path>
        <path fill="#ebebeb" d="M396.83 343.54H449.9V343.79H396.83z"></path>
        <path fill="#ebebeb" d="M272.56 340.41H331.22V340.66H272.56z"></path>
        <path fill="#ebebeb" d="M380.2 338.96H399.39V339.21H380.2z"></path>
        <path fill="#ebebeb" d="M100.47 335.94H143.66V336.19H100.47z"></path>
        <path
          fill="#ebebeb"
          d="M79.46 335.94H94.89999999999999V336.19H79.46z"
        ></path>
        <path
          fill="#ebebeb"
          d="M131.47 340.16H185.67000000000002V340.41H131.47z"
        ></path>
        <path
          fill="#ebebeb"
          d="M237 282.85H43.91a5.71 5.71 0 01-5.7-5.71V5.71a5.71 5.71 0 015.7-5.66H237a5.71 5.71 0 015.71 5.71v271.38a5.71 5.71 0 01-5.71 5.71zM43.91.25a5.46 5.46 0 00-5.45 5.46v271.43a5.46 5.46 0 005.45 5.46H237a5.48 5.48 0 005.46-5.46V5.71A5.48 5.48 0 00237 .25zM453.31 282.85h-193.1a5.73 5.73 0 01-5.71-5.71V5.71a5.72 5.72 0 015.71-5.66h193.1A5.71 5.71 0 01459 5.71v271.43a5.7 5.7 0 01-5.69 5.71zM260.21.25a5.48 5.48 0 00-5.46 5.46v271.43a5.48 5.48 0 005.46 5.46h193.1a5.48 5.48 0 005.46-5.46V5.71a5.48 5.48 0 00-5.46-5.46z"
        ></path>
        <path
          fill="#f0f0f0"
          d="M371.65 21.28H415.47999999999996V149.21H371.65z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M362.23 21.28H412.65000000000003V149.21H362.23z"
        ></path>
        <path
          fill="#fff"
          d="M366.46 26.31H408.42999999999995V144.19H366.46z"
        ></path>
        <path fill="#f5f5f5" d="M376.63 39.33H398.25V131.16H376.63z"></path>
        <path
          fill="#f0f0f0"
          d="M308.64 48.84H352.46999999999997V176.77H308.64z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M299.23 48.84H349.65000000000003V176.77H299.23z"
        ></path>
        <path
          fill="#fff"
          d="M303.45 53.87H345.41999999999996V171.75H303.45z"
        ></path>
        <rect
          width="21.62"
          height="91.83"
          x="313.63"
          y="66.89"
          fill="#f5f5f5"
          rx="8.64"
        ></rect>
        <path fill="#e6e6e6" d="M268.82 230.48H277.33V327.45H268.82z"></path>
        <path fill="#f0f0f0" d="M266.14 230.48H272.05V327.45H266.14z"></path>
        <path fill="#f0f0f0" d="M264.31 230.47H365.51V240.04H264.31z"></path>
        <path fill="#e6e6e6" d="M369.32 240.04H377.83V327.44H369.32z"></path>
        <path fill="#f0f0f0" d="M366.63 240.04H372.54V327.44H366.63z"></path>
        <path
          fill="#e6e6e6"
          d="M423.15 240.04H431.65999999999997V327.44H423.15z"
        ></path>
        <path
          fill="#f0f0f0"
          d="M420.47 240.04H426.38000000000005V327.44H420.47z"
        ></path>
        <path fill="#e6e6e6" d="M322.66 240.04H331.17V327.44H322.66z"></path>
        <path
          fill="#f0f0f0"
          d="M319.97 240.04H325.88000000000005V327.44H319.97z"
        ></path>
        <path
          fill="#e6e6e6"
          d="M365.52 230.48H431.66999999999996V240.04999999999998H365.52z"
        ></path>
        <path
          fill="#e6e6e6"
          d="M361.25 230.27L404.68 230.27 410.05 171.27 366.62 171.27 361.25 230.27z"
        ></path>
        <path
          fill="#f0f0f0"
          d="M359 230.27L402.43 230.27 407.8 171.27 364.37 171.27 359 230.27z"
        ></path>
        <path
          fill="#fff"
          d="M396.49 223.74L400.67 177.8 370.31 177.8 366.13 223.74 396.49 223.74z"
        ></path>
        <path fill="#ebebeb" d="M134.07 30.71H205.87V327.44H134.07z"></path>
        <path fill="#fafafa" d="M140.94 36.71H199V321.44H140.94z"></path>
        <path
          fill="#ebebeb"
          d="M59.22 30.71H131.01999999999998V327.44H59.22z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M131.02 31.88H134.07000000000002V326.28H131.02z"
        ></path>
        <path fill="#fafafa" d="M66.09 36.71H124.15V321.44H66.09z"></path>
        <rect
          width="165.15"
          height="2.67"
          x="49.97"
          y="25.83"
          fill="#e0e0e0"
          rx="1.1"
        ></rect>
        <path fill="#e0e0e0" d="M140.94 36.72H199V42.39H140.94z"></path>
        <path fill="#e0e0e0" d="M140.93 36.71H198.99V46.27H140.93z"></path>
        <path fill="#e0e0e0" d="M66.09 36.71H124.15V57.6H66.09z"></path>
        <g opacity="0.8">
          <path
            fill="#f5f5f5"
            d="M190.74 19.6H201.82000000000002V323.79H190.74z"
          ></path>
          <path
            fill="#f0f0f0"
            d="M193.67 19.6H198.89999999999998V323.79H193.67z"
          ></path>
          <path fill="#f5f5f5" d="M196.69 19.6H207.77V323.79H196.69z"></path>
          <path fill="#f0f0f0" d="M199.61 19.6H204.84V323.79H199.61z"></path>
          <path fill="#f5f5f5" d="M202.23 19.6H213.31V323.79H202.23z"></path>
        </g>
        <g opacity="0.8">
          <path fill="#f5f5f5" d="M51.95 19.6H75.23V323.79H51.95z"></path>
          <path fill="#f0f0f0" d="M58.1 19.6H69.09V323.79H58.1z"></path>
          <path fill="#f5f5f5" d="M64.44 19.6H87.72V323.79H64.44z"></path>
          <path fill="#f0f0f0" d="M70.59 19.6H81.58V323.79H70.59z"></path>
          <path fill="#f5f5f5" d="M76.09 19.6H99.37V323.79H76.09z"></path>
        </g>
      </g>
      <g>
        <ellipse
          cx="250"
          cy="361.29"
          fill="#f5f5f5"
          rx="193.89"
          ry="11.32"
        ></ellipse>
      </g>
      <g>
        <path
          fill="#407BFF"
          d="M79.55 115.7H80.55V134.26H79.55z"
          transform="rotate(-4.51 80.03 124.983)"
        ></path>
        <path
          fill="#407BFF"
          d="M81.07 141.22H82.07V147.23H81.07z"
          transform="rotate(-4.52 81.611 144.198)"
        ></path>
        <path
          fill="#407BFF"
          d="M119.11 82.1L158.54 82.1 197.98 82.1 237.41 82.1 276.84 82.1 316.27 82.1 317.98 103.81 319.7 125.51 321.41 147.22 323.13 168.92 324.84 190.62 326.56 212.33 287.13 212.33 247.69 212.33 208.26 212.33 168.83 212.33 129.4 212.33 89.97 212.33 88.26 190.62 86.54 168.92 84.83 147.22 83.11 125.51 81.4 103.81 79.68 82.1 119.11 82.1z"
        ></path>
        <path
          fill="#fff"
          d="M122.02 79.1L161.45 79.1 200.88 79.1 240.31 79.1 279.74 79.1 319.17 79.1 320.89 100.81 322.6 122.51 324.32 144.22 326.03 165.92 327.75 187.62 329.46 209.33 290.03 209.33 250.6 209.33 211.17 209.33 171.74 209.33 132.31 209.33 92.87 209.33 91.16 187.62 89.44 165.92 87.73 144.22 86.02 122.51 84.3 100.81 82.59 79.1 122.02 79.1z"
        ></path>
        <path
          fill="#fff"
          d="M281.46 100.81L320.89 100.81 319.17 79.1 279.74 79.1 281.46 100.81z"
        ></path>
        <path
          fill="#fff"
          d="M242.03 100.81L281.46 100.81 279.74 79.1 240.31 79.1 242.03 100.81z"
        ></path>
        <path
          fill="#fff"
          d="M202.59 100.81L242.03 100.81 240.31 79.1 200.88 79.1 202.59 100.81z"
        ></path>
        <path
          fill="#fff"
          d="M163.16 100.81L202.59 100.81 200.88 79.1 161.45 79.1 163.16 100.81z"
        ></path>
        <path
          fill="#fff"
          d="M123.73 100.81L163.16 100.81 161.45 79.1 122.02 79.1 123.73 100.81z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M84.3 100.81L123.73 100.81 122.02 79.1 82.59 79.1 84.3 100.81z"
        ></path>
        <path
          fill="#407BFF"
          d="M283.17 122.51L322.6 122.51 320.89 100.81 281.46 100.81 283.17 122.51z"
        ></path>
        <path
          style={{isolation: "isolate"}}
          fill="#407BFF"
          d="M243.74 122.51L283.17 122.51 281.46 100.81 242.03 100.81 243.74 122.51z"
          opacity="0.3"
        ></path>
        <path
          style={{isolation: "isolate"}}
          fill="#407BFF"
          d="M204.31 122.51L243.74 122.51 242.03 100.81 202.59 100.81 204.31 122.51z"
          opacity="0.6"
        ></path>
        <path
          style={{isolation: "isolate"}}
          fill="#407BFF"
          d="M164.88 122.51L204.31 122.51 202.59 100.81 163.16 100.81 164.88 122.51z"
          opacity="0.6"
        ></path>
        <path
          fill="#407BFF"
          d="M125.45 122.51L164.88 122.51 163.16 100.81 123.73 100.81 125.45 122.51z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M86.02 122.51L125.45 122.51 123.73 100.81 84.3 100.81 86.02 122.51z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M324.59 144.47h-39.94v-.23l-1.74-22h39.93v.23zm-39.47-.5h38.93l-1.68-21.21h-38.93z"
        ></path>
        <path
          style={{isolation: "isolate"}}
          fill="#407BFF"
          d="M245.46 144.22L284.89 144.22 283.17 122.51 243.74 122.51 245.46 144.22z"
          opacity="0.1"
        ></path>
        <path
          style={{isolation: "isolate"}}
          fill="#407BFF"
          d="M206.02 144.22L245.46 144.22 243.74 122.51 204.31 122.51 206.02 144.22z"
          opacity="0.6"
        ></path>
        <path
          style={{isolation: "isolate"}}
          fill="#407BFF"
          d="M166.59 144.22L206.02 144.22 204.31 122.51 164.88 122.51 166.59 144.22z"
          opacity="0.6"
        ></path>
        <path
          style={{isolation: "isolate"}}
          fill="#407BFF"
          d="M127.16 144.22L166.59 144.22 164.88 122.51 125.45 122.51 127.16 144.22z"
          opacity="0.6"
        ></path>
        <path
          fill="#f5f5f5"
          d="M87.73 144.22L127.16 144.22 125.45 122.51 86.02 122.51 87.73 144.22z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M326.3 166.17h-39.93v-.23l-1.73-22h39.93v.23zm-39.47-.5h38.93l-1.67-21.2h-38.93z"
        ></path>
        <path
          fill="#fff"
          d="M247.17 165.92L286.6 165.92 284.89 144.22 245.46 144.22 247.17 165.92z"
        ></path>
        <path
          style={{isolation: "isolate"}}
          fill="#407BFF"
          d="M207.74 165.92L247.17 165.92 245.46 144.22 206.02 144.22 207.74 165.92z"
          opacity="0.1"
        ></path>
        <path
          style={{isolation: "isolate"}}
          fill="#407BFF"
          d="M168.31 165.92L207.74 165.92 206.02 144.22 166.59 144.22 168.31 165.92z"
          opacity="0.8"
        ></path>
        <path
          style={{isolation: "isolate"}}
          fill="#407BFF"
          d="M128.88 165.92L168.31 165.92 166.59 144.22 127.16 144.22 128.88 165.92z"
          opacity="0.8"
        ></path>
        <path
          fill="#f5f5f5"
          d="M89.44 165.92L128.88 165.92 127.16 144.22 87.73 144.22 89.44 165.92z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M328 187.87h-39.92v-.23l-1.74-22h39.93v.23zm-39.47-.5h38.93l-1.68-21.2h-38.91z"
        ></path>
        <path
          fill="#fff"
          d="M248.88 187.62L288.31 187.62 286.6 165.92 247.17 165.92 248.88 187.62z"
        ></path>
        <path
          fill="#fff"
          d="M209.45 187.62L248.88 187.62 247.17 165.92 207.74 165.92 209.45 187.62z"
        ></path>
        <path
          style={{isolation: "isolate"}}
          fill="#407BFF"
          d="M170.02 187.62L209.45 187.62 207.74 165.92 168.31 165.92 170.02 187.62z"
          opacity="0.1"
        ></path>
        <path
          fill="#407BFF"
          d="M130.59 187.62L170.02 187.62 168.31 165.92 128.88 165.92 130.59 187.62z"
        ></path>
        <path
          fill="#f5f5f5"
          d="M91.16 187.62L130.59 187.62 128.88 165.92 89.44 165.92 91.16 187.62z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M329.73 209.58H289.8v-.23l-1.74-22H328v.23zm-39.47-.5h38.93l-1.68-21.21h-38.92z"
        ></path>
        <path
          fill="#fff"
          d="M250.6 209.33L290.03 209.33 288.31 187.62 248.88 187.62 250.6 209.33z"
        ></path>
        <path
          fill="#fff"
          d="M211.17 209.33L250.6 209.33 248.88 187.62 209.45 187.62 211.17 209.33z"
        ></path>
        <path
          fill="#fff"
          d="M171.74 209.33L211.17 209.33 209.45 187.62 170.02 187.62 171.74 209.33z"
        ></path>
        <path
          style={{isolation: "isolate"}}
          fill="#407BFF"
          d="M132.31 209.33L171.74 209.33 170.02 187.62 130.59 187.62 132.31 209.33z"
          opacity="0.1"
        ></path>
        <path
          fill="#f5f5f5"
          d="M92.87 209.33L132.31 209.33 130.59 187.62 91.16 187.62 92.87 209.33z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M321.16 101.05h-39.93v-.23l-1.74-22h39.94v.23zm-39.47-.5h38.93l-1.68-21.2H280z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M281.73 101.05h-39.94v-.23l-1.74-22H280v.23zm-39.47-.5h38.93l-1.68-21.2h-38.93z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M242.3 101.05h-39.94v-.23l-1.74-22h39.93v.23zm-39.47-.5h38.93l-1.68-21.2h-38.93z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M202.87 101.05h-39.94v-.23l-1.73-22h39.93v.23zm-39.48-.5h38.93l-1.67-21.2h-38.93z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M163.43 101.05H123.5v-.23l-1.73-22h39.93v.23zm-39.47-.5h38.93l-1.67-21.2h-38.93z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M124 101.05H84.07v-.23l-1.73-22h39.93v.23zm-39.47-.5h38.93l-1.67-21.2H82.86z"
        ></path>
        <path
          style={{isolation: "isolate"}}
          fill="#407BFF"
          d="M283.17 122.51L322.6 122.51 320.89 100.81 281.46 100.81 283.17 122.51z"
          opacity="0.1"
        ></path>
        <path
          fill="#e0e0e0"
          d="M283.44 122.76h-39.93v-.23l-1.73-22h39.93v.23zm-39.47-.5h38.93l-1.67-21.21H242.3z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M244 122.76h-39.92v-.23l-1.74-22h39.94v.23zm-39.47-.5h38.93l-1.67-21.21h-38.92z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M204.58 122.76h-39.93v-.23l-1.74-22h39.94v.23zm-39.47-.5H204l-1.64-21.21h-38.93z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M165.15 122.76h-39.94v-.23l-1.74-22h39.93v.23zm-39.47-.5h38.93l-1.68-21.21H124z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M125.72 122.76H85.78v-.23l-1.74-22h40v.23zm-39.47-.5h38.93l-1.68-21.21H84.57zM324.59 144.47h-39.94v-.23l-1.74-22h39.93v.23zm-39.47-.5h38.93l-1.68-21.21h-38.93z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M285.16 144.47h-39.94v-.23l-1.74-22h39.92v.23zm-39.47-.5h38.93l-1.68-21.21H244z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M245.73 144.47h-39.94v-.23l-1.73-22H244v.23zm-39.47-.5h38.92l-1.67-21.21h-38.93z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M206.29 144.47h-39.93v-.23l-1.73-22h39.93v.23zm-39.47-.5h38.93l-1.67-21.21h-38.93z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M166.86 144.47h-39.93v-.23l-1.73-22h39.93v.23zm-39.47-.5h38.93l-1.67-21.21h-38.93z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M127.43 144.47H87.5v-.23l-1.73-22h39.93v.23zM88 144h38.93l-1.68-21.21h-39zM326.3 166.17h-39.93v-.23l-1.73-22h39.93v.23zm-39.47-.5h38.93l-1.67-21.2h-38.93z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M286.87 166.17h-39.93v-.23l-1.74-22h39.94v.23zm-39.47-.5h38.93l-1.68-21.2h-38.92z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M247.44 166.17h-39.93v-.23l-1.74-22h39.94v.23zm-39.47-.5h38.93l-1.68-21.2h-38.93z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M208 166.17h-39.92v-.23l-1.74-22h39.94v.23zm-39.47-.5h38.93l-1.68-21.2h-38.92z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M168.58 166.17h-39.94v-.23l-1.74-22h39.93v.23zm-39.47-.5H168l-1.68-21.2h-38.89z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M129.15 166.17H89.21v-.23l-1.74-22h39.93v.23zm-39.47-.5h38.93l-1.68-21.2H88zM328 187.87h-39.92v-.23l-1.74-22h39.93v.23zm-39.47-.5h38.93l-1.68-21.2h-38.91z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M288.59 187.87h-39.94v-.23l-1.73-22h39.93v.23zm-39.47-.5H288l-1.67-21.2h-38.89z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M249.15 187.87h-39.93v-.23l-1.73-22h39.91v.23zm-39.47-.5h38.93l-1.67-21.2H208z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M209.72 187.87h-39.93v-.23l-1.73-22H208v.23zm-39.47-.5h38.93l-1.67-21.2h-38.93z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M170.29 187.87h-39.93v-.23l-1.73-22h39.93v.23zm-39.47-.5h38.93l-1.67-21.2h-38.93z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M130.86 187.87H90.93v-.23l-1.74-22h39.94v.23zm-39.47-.5h38.93l-1.68-21.2H89.71zM329.73 209.58H289.8v-.23l-1.74-22H328v.23zm-39.47-.5h38.93l-1.68-21.21h-38.92z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M290.3 209.58h-39.93v-.23l-1.74-22h39.94v.23zm-39.47-.5h38.93l-1.68-21.21h-38.93z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M250.87 209.58h-39.93v-.23l-1.74-22h39.94v.23zm-39.47-.53h38.93l-1.68-21.21h-38.93z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M211.44 209.58H171.5v-.23l-1.74-22h39.93v.23zm-39.44-.53h38.9l-1.68-21.21h-38.93z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M172 209.58h-39.93v-.23l-1.74-22h39.93v.23zm-39.47-.5h38.92l-1.67-21.21h-38.92z"
        ></path>
        <path
          fill="#e0e0e0"
          d="M132.58 209.58H92.64v-.23l-1.73-22h39.93v.23zm-39.48-.53H132l-1.67-21.21h-38.9z"
        ></path>
      </g>
      <g>
        <path
          fill="#ce7a63"
          d="M388.85 99.93A145.34 145.34 0 01395 114.1a120.94 120.94 0 014.52 15l.42 2 .35 2 .17 1a12.91 12.91 0 01.13 1.76 11 11 0 01-.09 1.62l-.1.77-.15.71a20 20 0 01-1.83 4.77 28.17 28.17 0 01-2.4 3.73 44.57 44.57 0 01-5.49 6 50.42 50.42 0 01-6.2 5 3.25 3.25 0 01-4.49-4.56l.1-.15a91.93 91.93 0 007.38-11.75 14.79 14.79 0 001.66-5.09v-.37a1 1 0 000-.24c0-.08-.06-.06-.06-.09l-.21-.8-.43-1.63-.5-1.65c-.32-1.1-.71-2.21-1.06-3.32s-.77-2.22-1.2-3.33c-.82-2.22-1.7-4.45-2.61-6.66-1.8-4.42-3.79-8.93-5.7-13.2v-.11a6.5 6.5 0 0111.74-5.57z"
        ></path>
        <path
          fill="#ce7a63"
          d="M377.19 154.46l-2.61 4.23a2.11 2.11 0 00.72 2.9l.2.1 4.17 1.89a2.1 2.1 0 002.72-.93l1.13-2.12 2.92-4.81-3-4.64c-3.64-.03-6.25 3.38-6.25 3.38z"
        ></path>
        <path
          fill="#407BFF"
          d="M405.88 350.19a2.41 2.41 0 01-1.88-.14.72.72 0 01-.18-.84.83.83 0 01.47-.53c1-.49 3.38.47 3.48.52a.16.16 0 01.11.16.14.14 0 01-.1.16 8.59 8.59 0 01-1.9.67zm-1.24-1.19a.86.86 0 00-.21.07.49.49 0 00-.27.31c-.1.32 0 .42.06.45.41.35 1.93 0 3-.4a6 6 0 00-2.58-.43z"
        ></path>
        <path
          fill="#407BFF"
          d="M407.73 349.58h-.09c-.86-.25-2.7-1.55-2.72-2.41a.61.61 0 01.46-.59 1 1 0 01.8.07c1 .52 1.66 2.62 1.69 2.71a.2.2 0 01-.05.18zm-2.15-2.69h-.08c-.22.08-.22.19-.22.24 0 .51 1.23 1.53 2.12 1.95a4.24 4.24 0 00-1.38-2.14.61.61 0 00-.44-.05zM351.14 353.92c-1 0-1.91-.14-2.23-.58a.66.66 0 010-.78.87.87 0 01.57-.42c1.26-.35 3.92 1.17 4 1.23a.2.2 0 01.09.19.17.17 0 01-.14.14 13.56 13.56 0 01-2.29.22zm-1.2-1.48h-.35a.52.52 0 00-.35.26c-.13.22-.08.33 0 .39.36.49 2.28.51 3.69.31a7.89 7.89 0 00-3-1z"
        ></path>
        <path
          fill="#407BFF"
          d="M353.44 353.71h-.07c-.92-.41-2.74-2.06-2.6-2.91 0-.21.18-.46.67-.51a1.31 1.31 0 011 .31c1 .8 1.17 2.84 1.18 2.93a.16.16 0 01-.07.16.14.14 0 01-.11.02zm-1.85-3.09h-.11c-.33 0-.35.16-.35.2-.09.52 1.15 1.81 2.08 2.37a4.15 4.15 0 00-1-2.34 1 1 0 00-.62-.23z"
        ></path>
        <path
          fill="#ce7a63"
          d="M361.8 353.52L354.2 353.52 353.44 335.92 361.04 335.92 361.8 353.52z"
        ></path>
        <path
          fill="#ce7a63"
          d="M416.2 347.56L408.77 349.17 401.32 332.77 408.75 331.16 416.2 347.56z"
        ></path>
        <path
          fill="#263238"
          d="M408.06 348.42l8.35-1.81a.68.68 0 01.74.38l2.9 6.28a1.11 1.11 0 01-.56 1.48 1.13 1.13 0 01-.24.08c-2.93.58-4.38.71-8.05 1.51-2.26.49-7.36 1.73-10.48 2.41s-4.19-2.33-3-2.88c5.45-2.49 7.73-4.69 9.2-6.73a1.88 1.88 0 011.14-.72zM354.25 352.65h8.3a.66.66 0 01.65.52l1.5 6.75a1.11 1.11 0 01-.84 1.32 1.15 1.15 0 01-.26 0c-3 0-7.32-.22-11.08-.22-4.4 0-8.2.24-13.36.24-3.13 0-4-3.16-2.69-3.44 5.95-1.3 10.81-1.44 15.95-4.61a3.43 3.43 0 011.83-.56z"
        ></path>
        <path
          style={{isolation: "isolate"}}
          d="M353.44 335.93L353.83 345 361.44 345 361.05 335.93 353.44 335.93z"
          opacity="0.2"
        ></path>
        <path
          style={{isolation: "isolate"}}
          d="M408.75 331.16L401.32 332.77 405.17 341.22 412.6 339.62 408.75 331.16z"
          opacity="0.2"
        ></path>
        <path
          fill="#407BFF"
          d="M356.36 160.16s8.64 58.1 13.36 79.89c5.19 23.84 31.61 100.24 31.61 100.24l13.61-3s-19.45-71.53-22.41-95.13c-3.22-25.61-9.94-82-9.94-82z"
        ></path>
        <path
          fill="#263238"
          d="M398.07 335.72c-.06 0 2.54 4.92 2.54 4.92l15.31-3.32-1.29-4.6z"
        ></path>
        <path
          d="M361.81 180.86c10 2 8.92 39.16 7.72 58.29-2.88-13.52-7.09-39.55-10-58a3.39 3.39 0 012.28-.29z"
          style={{isolation: "isolate"}}
          opacity="0.3"
        ></path>
        <path
          fill="#407BFF"
          d="M341.94 160.16s-1.05 56.84-.22 80c.86 24.11 9.28 103.06 9.28 103.06h12.88s.47-77.54 1-101.22c.63-25.81 3.74-81.86 3.74-81.86z"
        ></path>
        <path
          fill="#263238"
          d="M348.52 338.11c-.06 0 .73 5.35.73 5.35h15.66l.42-4.77zM277.52 71.82c13.55-3.42 28.51 4.5 33.41 17.68s-2.13 26.64-15.68 30.06-28.51-4.49-33.41-17.68 2.16-26.64 15.68-30.06zm16.33 44c11.4-2.88 17.31-14.21 13.19-25.3s-16.71-17.76-28.12-14.88-17.32 14.21-13.2 25.3a24.35 24.35 0 0028.13 14.84z"
        ></path>
        <path
          d="M293.22 114.1c10.46-2.64 15.87-13 12.1-23.19a22.3 22.3 0 00-25.77-13.63c-10.45 2.64-15.88 13-12.1 23.19a22.32 22.32 0 0025.77 13.63z"
          style={{isolation: "isolate"}}
          fill="#407BFF"
          opacity="0.2"
        ></path>
        <path
          fill="#263238"
          d="M292.65 119.26a58.22 58.22 0 001.64 5.85c.64 1.93 1.3 3.85 2 5.75s1.49 3.8 2.28 5.68c1 1.84 1 3.93 4.07 5.23a3 3 0 002.83-.43 1.59 1.59 0 00.34-.39c1.42-2.46-.24-4.12-.94-6-.87-1.86-1.78-3.72-2.71-5.57s-1.94-3.68-3-5.5-2.14-3.63-3.37-5.4a2 2 0 00-2.15-.37c-.64.22-1.09.71-.99 1.15zM347.56 71.83c.16.59 0 1.15-.43 1.25s-.82-.3-1-.9 0-1.15.43-1.25.85.3 1 .9z"
        ></path>
        <path
          fill="#ba4d3c"
          d="M347.58 73a21.92 21.92 0 01-1.55 5.8 3.51 3.51 0 003-.19z"
        ></path>
        <path
          fill="#263238"
          d="M348.9 68a.29.29 0 00.18-.19.36.36 0 00-.2-.46 3.56 3.56 0 00-3.28.25.38.38 0 00-.09.51.37.37 0 00.51.08 2.82 2.82 0 012.59-.17.36.36 0 00.29-.02z"
        ></path>
        <path
          fill="#ce7a63"
          d="M367.34 74.7c.72 5.78 1.24 12 4.48 16.81 0 0 .1 5.65-10.19 6.58-11.32 1-7-5-7-5 4.14-1 4.28-6.49 2-10.63z"
        ></path>
        <path
          fill="#407BFF"
          d="M373.89 95.05c.64-2.54 1.33-5.45-.6-6.21-2.12-.83-13.41-1-17.15 1.1S353.86 97 353.86 97z"
        ></path>
        <path
          fill="#263238"
          d="M385 94.05c7 2 12.41 20.65 12.41 20.65l-16.33 8a160 160 0 01-6.08-14.86c-3.29-9.29 2.79-15.79 10-13.79z"
        ></path>
        <g fill="#fff" opacity="0.4">
          <path
            d="M375.16 100.3H382.02000000000004V101.3H375.16z"
            transform="rotate(-66.39 378.592 100.8)"
          ></path>
          <path d="M388.86 96.87l-1.12 2.57.91.4.92-2.1c-.23-.3-.47-.59-.71-.87z"></path>
          <path
            d="M379.45 111.27H386.31V112.27H379.45z"
            transform="rotate(-66.39 382.884 111.772)"
          ></path>
          <path d="M394.62 107.05c-.17-.41-.35-.84-.54-1.26l-2 4.67.92.4z"></path>
        </g>
        <path
          fill="#263238"
          d="M340.53 99.05c-1.82 7-3.65 23.65 1.41 61.08 17.29 1.77 33.13 2.84 40.65 0 .34-7.45-3.46-37.54 2.38-66.09a105.47 105.47 0 00-13.61-1.72 146.5 146.5 0 00-17.28 0 70.07 70.07 0 00-7.75 1.19 7.56 7.56 0 00-5.8 5.54z"
        ></path>
        <g fill="#fff" opacity="0.4">
          <path d="M348.1 94.05l.87.5.93-1.64-1.27.22z"></path>
          <path
            d="M338.56 105.28H345.42V106.28H338.56z"
            transform="rotate(-60.38 341.993 105.777)"
          ></path>
          <path
            d="M349.93 102.16H356.79V103.16H349.93z"
            transform="rotate(-60.37 353.364 102.656)"
          ></path>
          <path
            d="M359.82 98.08H366.67V99.08H359.82z"
            transform="rotate(-60.61 363.248 98.58)"
          ></path>
          <path
            d="M372.66 95.89H379.52000000000004V96.89H372.66z"
            transform="rotate(-60.38 376.09 96.384)"
          ></path>
          <path
            d="M341.7 116.66H348.56V117.66H341.7z"
            transform="rotate(-60.37 345.134 117.161)"
          ></path>
          <path
            d="M351.63 112.52H358.48V113.52H351.63z"
            transform="rotate(-60.61 355.061 113.02)"
          ></path>
          <path
            d="M362.95 109.4H369.8V110.4H362.95z"
            transform="rotate(-60.61 366.385 109.906)"
          ></path>
          <path
            d="M375.81 107.28H382.67V108.28H375.81z"
            transform="rotate(-60.38 379.24 107.776)"
          ></path>
          <path d="M338.9 128.87a.67.67 0 010 .2l.09-.15z"></path>
          <path
            d="M344.81 128.02H351.67V129.02H344.81z"
            transform="rotate(-60.38 348.239 128.518)"
          ></path>
          <path
            d="M356.17 124.88H363.03000000000003V125.88H356.17z"
            transform="rotate(-60.38 359.598 125.383)"
          ></path>
          <path
            d="M367.54 121.75H374.40000000000003V122.75H367.54z"
            transform="rotate(-60.38 370.967 122.254)"
          ></path>
          <path
            d="M380.22 121.88l.87.5.79-1.39c0-.73.07-1.47.11-2.2zM342.12 140.29l-.86-.49-1.39 2.44c.05.57.1 1.14.16 1.73z"></path>
          <path
            d="M347.94 139.4H354.8V140.4H347.94z"
            transform="rotate(-60.38 351.372 139.9)"
          ></path>
          <path
            d="M359.31 136.27H366.17V137.27H359.31z"
            transform="rotate(-60.38 362.74 136.77)"
          ></path>
          <path
            d="M370.67 133.12H377.53000000000003V134.12H370.67z"
            transform="rotate(-60.38 374.096 133.622)"
          ></path>
          <path d="M344.38 151.17l-2.94 5.18a8.42 8.42 0 00.14 1.11l.28.16 3.39-6z"></path>
          <path
            d="M351.08 150.8H357.94V151.8H351.08z"
            transform="rotate(-60.38 354.508 151.295)"
          ></path>
          <path
            d="M362.45 147.67H369.31V148.67H362.45z"
            transform="rotate(-60.38 365.876 148.166)"
          ></path>
          <path
            d="M373.81 144.5H380.67V145.5H373.81z"
            transform="rotate(-60.38 377.242 145)"
          ></path>
          <path
            d="M359.75 159.91l-.87-.5-1.2 2.12 1.11.06zM371.12 156.78l-.87-.49-3.19 5.61h1.14zM382.44 153.75v-.13l-.81-.46-3.39 6 .87.5z"></path>
        </g>
        <path
          d="M340.27 100.17c6.72-6.15 9.1 5.4 8.69 10.59-1.16 14.39-9.49 19.12-10 19.55-.84-16 .17-25.11 1.31-30.14z"
          style={{isolation: "isolate"}}
          opacity="0.3"
        ></path>
        <path
          fill="#ce7a63"
          d="M350.74 106.83c-1.72 2.26-3.5 4.53-5.27 6.77l-5.35 6.72-5.36 6.73c-1.76 2.24-3.55 4.45-5.26 6.67l-.39.5a4.93 4.93 0 01-3.22 1.87c-2 .27-4 .46-5.91.54s-3.9.08-5.83 0-3.85-.24-5.76-.49a51.21 51.21 0 01-5.75-1 3.27 3.27 0 01.25-6.4h.16l5.23-.87q2.63-.44 5.21-.93c1.72-.31 3.42-.69 5.1-1s3.31-.82 4.92-1.24l-3.61 2.3c3.21-5 6.55-9.75 10-14.44 1.71-2.35 3.47-4.66 5.24-7s3.57-4.57 5.44-6.81a6.5 6.5 0 1110.19 8.08z"
        ></path>
        <path
          fill="#ce7a63"
          d="M299.64 128.05l-4.71 1.59a2.1 2.1 0 00-1.32 2.66 2.6 2.6 0 00.1.25l2 4.12a2.1 2.1 0 002.69 1l2.22-.91 5.32-1.83.68-5.46c-2.79-2.42-6.98-1.42-6.98-1.42z"
        ></path>
        <path
          fill="#263238"
          d="M354.21 100.05c2.93 10.06-12.44 24-12.44 24L328 110.5s4.55-5.67 7.75-9.41c8.93-10.47 16.13-9.09 18.46-1.04z"
        ></path>
        <g fill="#fff" opacity="0.4">
          <path d="M345.13 94c-.39.12-.78.26-1.16.42l-.45 1.58 1 .26z"></path>
          <path d="M331.05 113.52L331.6 111.55 330.64 111.28 330.24 112.72 331.05 113.52z"></path>
          <path
            d="M337.04 108.39H343.90000000000003V109.39H337.04z"
            transform="rotate(-74.47 340.463 108.892)"
          ></path>
          <path
            d="M347.82 104.41H354.65999999999997V105.41H347.82z"
            transform="rotate(-74.29 351.248 104.91)"
          ></path>
          <path d="M347.67 116.05l-1-.26-1.25 4.49q.74-.82 1.53-1.77z"></path>
        </g>
        <path
          fill="#ce7a63"
          d="M367.07 65.53c1.4 7.42 2.6 11.68-.13 16.36-4.12 7-14 6-17.57-.85-3.23-6.16-5-17.25 1.42-22a10.28 10.28 0 0116.28 6.49z"
        ></path>
        <path
          fill="#263238"
          d="M352.39 72.27c-3.78-.28-3.48-6-1.32-8-5.46-.22-12.3-.44-10.63-6.39 1.23-4.33 12.67-4.44 7.34-7.44 5.11-3.17 8.14 2.11 5.63 3.28 8-3.11 17.2-5.34 13.92 2.33 5.5-.89 9.28 8.24 6.45 12.18s2.1 4.65 2.89 2.82c.39 5.33-5.06 5.17-5.84 4.17 3.28 5-3.33 11.27-10.05 7.89s-8.39-10.84-8.39-10.84z"
        ></path>
        <path
          fill="#263238"
          d="M365.16 56.55c4.25-2.42 5-5.46 3.29-8.34 7.04 2.42 2.6 8.84-3.29 8.34z"
        ></path>
        <path
          fill="#ce7a63"
          d="M355.54 71.05a7.13 7.13 0 01-.42 4.62c-.87 1.91-2.56 1.43-3.37-.29-.73-1.55-1.16-4.4.22-5.74s3.1-.44 3.57 1.41z"
        ></path>
      </g>
    </svg>
  )
}
