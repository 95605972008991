export const MERGE = {
  AND: 'AND',
  OR: 'OR',
};

export const ANY_OPERATOR = {
  GREATER_OR_EQUALS: '>=',
  EQUALS: '=',
};

export const MERGE_OPTIONS = [
  {
    name: 'All (AND)',
    fieldKey: MERGE.AND,
  },
  {
    name: 'Any (OR)',
    fieldKey: MERGE.OR,
  },
];

export const ANY_OPERATOR_OPTIONS = [
  {
    name: '≥ (greater or equals)',
    fieldKey: ANY_OPERATOR.GREATER_OR_EQUALS,
  },
  {
    name: '= (equals)',
    fieldKey: ANY_OPERATOR.EQUALS,
  },
];

export const BUILDER_FIELD = {
  FILTER_TYPE: "filterType",
  AGGREGATE: "aggregate",
  FIELD: "field",
  OPERATOR: "operator",
  VALUE: "value",
  FILTER: "filter",
  FILTERS: "filters",
  BLOCKS: "blocks",
  BLOCK_ID: "blockId",
  BLOCK_TYPE: "blockType",
  BLOCK_SUBS: "blockSubs",
  QUERY_PARAMS: "queryParams",
  SET_OPERATIONS: "setOperations",
  MERGE: "merge",
  EVENTS: "events",
  NAME: "name",
}

export const BLOCKS = {
  USER_FROM: "userFrom",
  USER_DID: "userDid",
  USER_NOT_DID: "userNotDid",
}

export const FILTER_TYPE = {
  WHERE: "WHERE",
  HAVING: "HAVING",
  GROUP_BY: "GROUPBY",
}

export const AGGREGATE_TYPE = {
  COUNT: "COUNT",
  AVG: "AVG",
  SUM: "SUM",
};

export const OPERATOR_TYPE = {
  GREATER_OR_EQUALS: ">=",
  LESS_THAN_OR_EQUALS: "<=",
  EQUALS: "=",
  LESS_THAN: "<",
  IN: "IN",
};

export const FIELD_NAME = {
  DATE_KEY: "date_key",
  EVENT_NAME: "event_name",
  HOUR: "hour",
  DAY_OF_WEEK: "day_of_week",
  CUSTOMER_ID: "customer_id",
  CUSTOMER_PHONE: "phone",
  CUSTOMER_EMAIL: "email",
  FREQUENCY: "frequency",
  EVENT_PROPERTIES: 'event_properties',
}

export const BLOCK_TYPE = {
  INTERSECT_DISTINCT: "INTERSECT DISTINCT",
  UNION_DISTINCT: "UNION DISTINCT",
}

export const SET_OPERATIONS = [
  "INTERSECT DISTINCT",
  "EXCEPT DISTINCT",
  "INTERSECT DISTINCT"
];

export const getQueryDateRelative = (day) => {
  return `DATE_SUB(CURRENT_DATE(), INTERVAL ${day} DAY)`
}
