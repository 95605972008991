import ToggleCheckbox from "components/ToggleCheckbox";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import CreateConversionValue from "views/skadnetwork/skan_studio/conversion_value/create";
import {isUndefinedOrEmpty} from "utils";
import {getCoarseValue} from "views/skadnetwork/skan_studio/const";
import {useFormContext} from "react-hook-form";
import {DsIconDelete, DsIconEdit} from "components/DsIcon";
import TailwindMenuDialog from "components/TailwindMenuDialog";
import EditConversionValue from "views/skadnetwork/skan_studio/conversion_value/edit";


export default function PostbackWindowOne(
  {
    eventList = []
  }
) {
  const {t} = useTranslation();
  const [onOpenCreateConversionValue, setOnOpenCreateConversionValue] = useState(false);
  const [onOpenEditConversionValue, setOnOpenEditConversionValue] = useState(false);
  const {register, watch, setValue} = useFormContext();
  const postbackWindowSettingEnabled = watch('postbackWindowOne.postbackWindowSettingsEnabled');
  const conversionValues = watch('postbackWindowOne.conversionValues') || [];
  const [conversionValueIndex, setConversionValueIndex] = useState(0);

  const addData = () => {
    setOnOpenCreateConversionValue(true);
  }

  const setFieldValue = (name, value) => {
    setValue(name, value);
  }

  const handleAddConversionValue = (value) => {
    setFieldValue('postbackWindowOne.conversionValues', [...conversionValues, value]);
  }

  const handleOnClickEdit = (index) => {
    setConversionValueIndex(index);
    setOnOpenEditConversionValue(true);
  }

  const handleEditConversionValue = (index, value) => {
    setFieldValue(`postbackWindowOne.conversionValues.${index}`, value)
  }

  const handleDeleteConversionValue = (index) => {
    const newConversionValues = conversionValues.filter((_, i) => i !== index);
    setFieldValue('postbackWindowOne.conversionValues', newConversionValues);
  }

  return (
    <div id="postback-window-one" className={"sm:p-3"}>
      <div className="font-bold me-2">{t('POSTBACK_WINDOW_SETTING_ENABLED')}</div>
      <ToggleCheckbox name="postbackWindowOne.postbackWindowSettingsEnabled"/>

      {postbackWindowSettingEnabled && <div>
        {!isUndefinedOrEmpty(conversionValues) && (
          <div id="segment-list">
            <div className="font-bold me-2">{t('CONVERSION_VALUES')}</div>
            <div className="ds-block mt-3">

              <div className="relative ds-input-text">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                  <tr>
                    <th scope="col" className="py-3 pl-4 pr-3 text-center text-sm font-bold text-gray-900">
                      {t('NAME')}
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-bold text-gray-900">
                      {t('FINE_VALUE')}
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-bold text-gray-900">
                      {t('COARSE_VALUE')}
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-bold text-gray-900">
                      {t('EVENTS')}
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-bold text-gray-900">
                      {t('LOCK')}
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  {conversionValues.map((conversionValue, index) => (
                    <tr key={index} className="bg-white border-b">
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 sm:pl-0 text-center">
                        {conversionValue.displayName}
                      </td>
                      <td
                        className="whitespace-nowrap px-3 py-4 text-center text-gray-500">{conversionValue.fineValue}</td>
                      <td
                        className="whitespace-nowrap px-3 py-4 text-center text-gray-500">{getCoarseValue(conversionValue.coarseValue)}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-center text-gray-500">
                        {conversionValue.eventMappings.map((event, index) => (
                          <span
                            className="inline-flex items-center rounded-md bg-gray-100 px-1.5 py-0.5 text-xs font-medium text-gray-600 ml-1">
                            {event.eventName}
                          </span>
                        ))}
                      </td>
                      <td
                        className="whitespace-nowrap px-3 py-4 text-center text-gray-500">{conversionValue.lockEnabled ? 'Enabled' : 'Disabled'}
                      </td>
                      <td className="relative whitespace-nowrap">
                        <TailwindMenuDialog
                          items={[
                            {
                              Name: "Delete",
                              Icon: <DsIconDelete/>,
                              Action: () => handleDeleteConversionValue(index),
                              ActionType: "button"
                            },
                            {
                              Name: "Edit",
                              Icon: <DsIconEdit/>,
                              Action: () => handleOnClickEdit(index),
                              ActionType: "button"
                            }
                          ]}
                        />
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}

        <div onClick={addData} className="ds-button-2 mt-2">
          + {t('CONVERSION_VALUE')}
        </div>

        <CreateConversionValue isOpen={onOpenCreateConversionValue}
                               closeModal={() => setOnOpenCreateConversionValue(false)}
                               eventList={eventList}
                               onChange={handleAddConversionValue}
                               postbackWindow={1}
        />
        <EditConversionValue isOpen={onOpenEditConversionValue}
                             closeModal={() => setOnOpenEditConversionValue(false)}
                             eventList={eventList}
                             onChange={handleEditConversionValue}
                             postbackWindow={1}
                             data={conversionValues[conversionValueIndex]}
                             index={conversionValueIndex}
        />
      </div>
      }
    </div>
  )
};
