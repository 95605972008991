import { useDispatch } from "react-redux";
import { useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEllipsisV, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import ToggleBox from "components/ToggleBox";


export default function ChartAction({ updateChart = () => { }, deleteChart = () => { } }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const methods = useForm();

  // const handleDelete = async () => {
  //   try {
  //     const requestData = {
  //       chartId: chartId,
  //     }
  //     const { message } = await Chart.deleteChart(requestData);
  //     dispatch(showInfo({ message }));
  //   } catch (error) {
  //     const { message } = error;
  //     dispatch(showError({ message }));
  //   }
  // }

  return (
    <ToggleBox autoClose position="right">
      <ToggleBox.Button>
        <FontAwesomeIcon icon={faEllipsisV} className='h-5 w-5 cursor-pointer' />
      </ToggleBox.Button>
      <ToggleBox.Content>
        <div
          onClick={updateChart}
          className="px-2 py-2 w-56 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none hover:bg-gray-200 cursor-pointer"
        >
          <button className="flex">
            <FontAwesomeIcon className="mr-3 h-4 w-4 text-gray-400 group-hover:text-gray-500" aria-hidden="true" icon={faEdit} />
            Update
          </button>
        </div>
        <div
          onClick={deleteChart}
          className="px-2 py-2 w-56 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none hover:bg-gray-200 cursor-pointer">
          <button className="flex">
            <FontAwesomeIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" icon={faTrashAlt} />
            Delete
          </button>
        </div>


      </ToggleBox.Content>
    </ToggleBox>
  )
}
