export const tabLink = {
  LINK: '#link',
  TEMPLATE: '#template',
}

export const tabs = [
  { name: 'Link', href: tabLink.LINK },
  { name: 'Template', href: tabLink.TEMPLATE },
];

export default tabs;