import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";

import Tooltip from "components/Tooltip";
import {DsIconGoogleAds, DsIconTooltip} from "components/DsIcon";

import AppService from 'services/app';
import {FormProvider, useForm} from "react-hook-form";
import {showInfo, showWarn} from "components/FlashMessage/flashMessageSlice";
import {useDispatch} from "react-redux";
import {InformationCircleIcon} from "@heroicons/react/20/solid";
import BackButton from "views/integration/meta_ads/back_button";
import PartnerConnectionList from "views/integration/connection_list";
import {PARTNERS} from "consts/partner";
import CheckboxDropdown from "components/CheckBoxGroup";


export default function Cost() {
  const {t} = useTranslation();
  const methods = useForm();
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState({});
  const [connections, setConnections] = useState([]);


  const onSubmit = async (data) => {
    try {
      AppService.integrateGoogleCost();
    } catch (error) {
      dispatch(showWarn({message: error.message}))
    }
  }

  const getConnection = async () => {
    const result = await AppService.getConnectionByPartner(PARTNERS.GOOGLE)
    setConnections(result)
  }
  const [searchParams] = useSearchParams();
  const status = searchParams.get('status');
  useEffect(() => {
    if (status === "success") {
      dispatch(showInfo({message: 'Connect successfully'}))
    }
    getConnection();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>

      <div id="cost" className={"sm:p-3"}>
        <div className="flex justify-start items-center w-full">
          <h3 className="ds-text-form-title mr-2">Connect to your Google Ads</h3>

          <Tooltip minWidth="250">
            <Tooltip.Label>
              <div><DsIconTooltip className="h-full w-full text-blue-400"
                                                                       aria-hidden="true"/>
              </div>
            </Tooltip.Label>
            <Tooltip.Content>
              <p>Campaign data will be synced daily. Connect again if something went wrong</p>
            </Tooltip.Content>
          </Tooltip>

        </div>
        <div className="ds-text-lighter">Enhance your integration to receive data</div>

        <FormProvider {...methods}>
          <form className="flex items-center mt-3" onSubmit={methods.handleSubmit(onSubmit)}>
            <button
              type="submit"
              className="ds-button-with-border mr-3 flex items-center border-2"
            >
              <div className={"mr-2"}>
                <div className={"h-10 w-10"}><DsIconGoogleAds/></div>
              </div>
              <FontAwesomeIcon icon={faAngleRight}/>
            </button>
          </form>
        </FormProvider>

        <div className={"mt-16"}>
          {connections.length > 0 &&
            <div >
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                <tr>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    {t('Email')}
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    {t('Created By')}
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    {t('Status')}
                  </th>
                  <th scope="col" className="w-1/4 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Ads Account
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    {t('Created At')}
                  </th>
                </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                {connections && connections.map((connection, index) => (
                  <tr key={index}>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{connection.sellerName}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{connection.createdBy}</td>
                    <td
                      className={`whitespace - nowrap px-3 py-4 text-sm  ${connection.status ? 'text-green-600' : 'text-rose-600'}`}>{connection.status ? 'Linked' : 'Unlinked'}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <CheckboxDropdown
                        onCheckboxChange={(name, checked) => {
                          // console.log(connection.connectionId, name, checked)
                        }}

                        connectionId={connection.connectionId}
                        partner={PARTNERS.GOOGLE}
                      />

                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{connection.createdAt}</td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>}
        </div>

        <hr className="h-px my-6 bg-gray-200 border-0"/>
        <div className="flex justify-between items-center px-4 w-full">
          <BackButton/>
        </div>
      </div>
    </>
  )
}
