import React, {useEffect, useState} from 'react';
import {titleCase} from "utils/util";

/**
 * FieldFilter component is used to manage field filters with search functionality.
 * @param {Object} props - The props object.
 * @param {Array} [props.value=[]] - The selected values for the field, defaults to an empty array.
 * @param {Function} [props.onChange=() => {}] - The function called when the selected values change.
 * @param {Array} [props.options=[]] - The available options for filtering.
 * @returns {JSX.Element} The JSX representation of the FieldFilter component.
 */
export default function FieldFilter({
                                      value = [],
                                      onChange = () => {
                                      },
                                      options = [],
                                    }) {
  const [defaultSelected, setDefaultSelected] = useState(value);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);

  const checkBoxClick = (value) => {
    const updatedArray = Array.isArray(defaultSelected)
      ? [...defaultSelected]
      : [defaultSelected];

    const indexToRemove = updatedArray.indexOf(value);

    if (indexToRemove === -1) {
      updatedArray.push(value);
    } else {
      updatedArray.splice(indexToRemove, 1);
    }

    setDefaultSelected(updatedArray);
  }

  useEffect(() => {
    onChange(defaultSelected);
  }, [defaultSelected, onChange]);

  useEffect(() => {
    setDefaultSelected(value);
  }, [value]);

  useEffect(() => {
    const filtered = options.filter(item =>
      item.value.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredOptions(filtered);
  }, [searchTerm, options]);

  return (
    <div>
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="w-full ds-search-box ds-input-text"
      />
      {filteredOptions.map((item, index) => (
        <div
          className="hover:bg-violet-50 cursor-pointer"
          onClick={() => checkBoxClick(item.value)}
          key={index}
        >
          <div className="items-center w-max p-2">
            <div className="flex">
              <input
                type="checkbox"
                id={item.fieldKey}
                value={item.value}
                checked={defaultSelected.includes(item.value)}
                onChange={() => checkBoxClick(item.value)}
                className={`accent-[#8b5cf6]`}
              />
              <div className="ds-input-text ml-2">
                {titleCase(item.value)}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
