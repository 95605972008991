import {useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquarePlus, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import EventFilter from "components/EventFilter";
import TailwindCombobox from "components/TailwindCombobox";
import {ANY_OPERATOR_OPTIONS, MERGE, MERGE_OPTIONS} from "views/segments/find_people/const";
import NumberInput from "components/NumberInput";

export default function UserDid({
                                  name,
                                  eventNameOptions = [],
                                  appId,
                                  dateDiff,
                                }) {
  const {t} = useTranslation();
  const {watch, getValues, setValue} = useFormContext();
  const events = watch(`${name}.events`) || [];

  return (
    <div>
      <div className={`ds-block space-y-4`}>
        <div className="ds-text-form-title mb-4">
          User do these events
        </div>

        {Array.isArray(events) && events.length > 1 && <div className={`flex gap-4 items-center`}>
          <TailwindCombobox
            name={`${name}.merge`}
            items={MERGE_OPTIONS}
            className={`!w-fit`}
          />
          <span
            className={`ds-input-text`}> {getValues(`${name}.merge`) === MERGE.AND ? t('OF_THESE_EVENTS') : t('COMBINATION_OF_THESE_EVENTS')}</span>
          {
            getValues(`${name}.merge`) === MERGE.OR &&
            <>
              <TailwindCombobox
                name={`${name}.mergeOperator`}
                items={ANY_OPERATOR_OPTIONS}
                className={`!w-fit`}
              />
              <NumberInput
                name={`${name}.mergeValue`}
                defaultValue={1}
              />
            </>
          }
        </div>}

        <div className="space-y-4 ds-block !bg-white">

          {events.map((event, index) => (
            <div key={index} className={`flex items-center space-x-4 ds-block justify-between`}>
              <EventFilter
                name={`${name}.events[${index}]`}
                eventNameOptions={eventNameOptions}
                appId={appId}
                dateDiff={dateDiff}
                index={index + 1}
              />

              <FontAwesomeIcon
                onClick={() => {
                  setValue(`${name}.events`, events.filter((_, i) => i !== index));
                }}
                className='right-2 top-6 cursor-pointer text-slate-500 hover:text-red-500'
                icon={faTrashCan}
              />
            </div>
          ))}

          <button
            type="button"
            onClick={() => {
              setValue(`${name}.events`, [...events, ""]);
            }}
            className="ds-button"
          >
            <FontAwesomeIcon icon={faSquarePlus} className="me-2"/>
            Event
          </button>

        </div>
      </div>
    </div>
  );
}
