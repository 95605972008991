// eslint-disable-next-line
export default {

  HOMEPAGE: '/',
  ANALYTIC_DASHBOARD: '/analytic-dashboard',
  MEASUREMENT: '/measurement',
  SHOP_METRIC: '/shop-metric',
  GROWTH: '/growth-analytics',
  PRICING: '/pricing',
  ANALYTICS: '/analytics',
  LP_FRAUD: '/fds',
  LANDING_PAGE_RECOMMENDATION: '/landing-page-recommendation',
  RECOMMENDATION: '/recommendation',
  ABOUT_US: '/about',
  CONTACT: '/contact',
  USER: '/user',
  LIST_DEEPLINK: '/list_deeplink',
  DEEPLINK_TEMPLATE: '/template',
  ADD: '/add',
  USERS: '/users',
  ROLES: '/roles',
  APP: '/app',
  ROI: '/roi',
  APP_EMPTY: '/app/app-empty',
  ACCOUNT_SETTINGS: '/app/account-settings',
  PROJECT: '/project',
  ADD_ROLES: '/roles/add',
  OVERVIEW: '/overview',
  FRAUD: '/fraud',
  INTEGRATION: '/integration',
  DATA_SOURCE: '/data-source',
  CONFIGURATIONS: '/configuration',
  LOGIN: '/login',
  REQUEST_DEMO: '/request-demo',
  CREATE_ACCOUNT: '/create-account',
  TABLE_ANALYTIC: '/table-analytic',
  NOT_FOUND: '/not_found',
  SEGMENTS: '/segments',
  FIND_PEOPLE: '/find-people',
  SKADNETWORK_OVERVIEW: '/skan',
  SKADNETWORK_STUDIO: '/skan-studio',
  SKADNETWORK: '/skan',
  SEGMENT_DETAIL: '/segments/segment-detail',
  EVENTS: '/events',
  EVENTS2: '/events',
  FUNNEL: '/funnel',
  COHORT: '/cohort',
  USER_MANAGEMENT: '/user-management',
  INVITE: '/invite',
  JOIN_INVITE: '/join-invite',
  EMAIL_VERIFY: '/email/verify',
  MARKETING_SUCCESS: '/marketing/success',
  PAYMENT_RETURN: '/payment_return',
  PARTNER_MARKETPLACE: '/partner-marketplace',
  MOCK: '/mock',
  DEEPLINK: '/deeplink',
  DEEPLINK_CREATE: '/create',
  DASHBOARD: '/dashboard',
  CHART: '/chart',
  ALL_REPORTS: '/all-reports',
  EVENTS_LIST: '/events-list',
  PRIVACY_ENGLISH: '/legal/privacy-policy-en',
  PRIVACY_VIETNAMESE: '/legal/privacy-policy-vn',
  MY_EXPENSE_DIARY_PRIVACY_ENGLISH: '/my-expense-diary/legal/privacy-policy-en',
  MY_EXPENSE_DIARY_VIETNAMESE: '/my-expense-diary/legal/privacy-policy-vn',
  TERM_OF_SERVICE: '/legal/term-of-service',
};
