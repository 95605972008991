import HttpMethod from "consts/httpMethod";
import {processErrorResponse, processRequest, processSuccessResponse} from "utils";
import {useParams} from "react-router-dom";

export default {
  async createDataSource(data) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/data-source/${data.partner}`,
      method: HttpMethod.POST
    };
    try {
      const res = await processRequest(ep, {data});
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return false;
    }
  },


  async createAdsDataSource(data) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/data-source/ads/${data.partner}`,
      method: HttpMethod.POST
    };
    try {
      const res = await processRequest(ep, {data});
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async createSocialMediaDataSource(data) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/data-source/social-media/${data.partner}`,
      method: HttpMethod.POST
    };
    try {
      const res = await processRequest(ep, {data});
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async listDataSources({partner}) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/data-source/${partner}/list`,
      method: HttpMethod.GET
    };
    try {
      const res = await processRequest(ep, {partner});
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async updateDataSource(data) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/data-source/${data.partner}/${data.id}/update`,
      method: HttpMethod.PUT
    };
    try {
      const res = await processRequest(ep, {data});
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async getDataSource({partner, id}) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/data-source/${partner}/${id}`,
      method: HttpMethod.GET
    };
    try {
      const res = await processRequest(ep, {partner, id});
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async deleteDataSource({partner, dataSourceId}) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/data-source/${partner}/${dataSourceId}/delete`,
      method: HttpMethod.DELETE
    };
    try {
      const res = await processRequest(ep, {partner, dataSourceId});
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return false;
    }
  }
}
