export default [{
  "fieldKey": "Etc/GMT+7",
  "name": "Etc/GMT+7"
}, {"fieldKey": "Etc/GMT+12", "name": "Etc/GMT+12"}, {
  "fieldKey": "Etc/GMT+11",
  "name": "Etc/GMT+11"
}, {"fieldKey": "Pacific/Pago_Pago", "name": "Pacific/Pago_Pago"}, {
  "fieldKey": "Pacific/Samoa",
  "name": "Pacific/Samoa"
}, {"fieldKey": "US/Samoa", "name": "US/Samoa"}, {
  "fieldKey": "Pacific/Niue",
  "name": "Pacific/Niue"
}, {"fieldKey": "Pacific/Midway", "name": "Pacific/Midway"}, {
  "fieldKey": "America/Adak",
  "name": "America/Adak"
}, {"fieldKey": "US/Aleutian", "name": "US/Aleutian"}, {
  "fieldKey": "Pacific/Honolulu",
  "name": "Pacific/Honolulu"
}, {"fieldKey": "US/Hawaii", "name": "US/Hawaii"}, {
  "fieldKey": "Pacific/Rarotonga",
  "name": "Pacific/Rarotonga"
}, {"fieldKey": "Pacific/Tahiti", "name": "Pacific/Tahiti"}, {
  "fieldKey": "Pacific/Johnston",
  "name": "Pacific/Johnston"
}, {"fieldKey": "Etc/GMT+10", "name": "Etc/GMT+10"}, {
  "fieldKey": "HST",
  "name": "HST"
}, {"fieldKey": "Pacific/Marquesas", "name": "Pacific/Marquesas"}, {
  "fieldKey": "America/Anchorage",
  "name": "America/Anchorage"
}, {"fieldKey": "America/Juneau", "name": "America/Juneau"}, {
  "fieldKey": "America/Metlakatla",
  "name": "America/Metlakatla"
}, {"fieldKey": "America/Nome", "name": "America/Nome"}, {
  "fieldKey": "America/Sitka",
  "name": "America/Sitka"
}, {"fieldKey": "America/Yakutat", "name": "America/Yakutat"}, {
  "fieldKey": "America/Atka",
  "name": "America/Atka"
}, {"fieldKey": "US/Alaska", "name": "US/Alaska"}, {
  "fieldKey": "Etc/GMT+9",
  "name": "Etc/GMT+9"
}, {"fieldKey": "Pacific/Gambier", "name": "Pacific/Gambier"}, {
  "fieldKey": "America/Tijuana",
  "name": "America/Tijuana"
}, {"fieldKey": "America/Santa_Isabel", "name": "America/Santa_Isabel"}, {
  "fieldKey": "America/Ensenada",
  "name": "America/Ensenada"
}, {"fieldKey": "Mexico/BajaNorte", "name": "Mexico/BajaNorte"}, {
  "fieldKey": "Etc/GMT+8",
  "name": "Etc/GMT+8"
}, {"fieldKey": "Pacific/Pitcairn", "name": "Pacific/Pitcairn"}, {
  "fieldKey": "America/Los_Angeles",
  "name": "America/Los_Angeles"
}, {"fieldKey": "America/Vancouver", "name": "America/Vancouver"}, {
  "fieldKey": "America/Dawson",
  "name": "America/Dawson"
}, {"fieldKey": "America/Whitehorse", "name": "America/Whitehorse"}, {
  "fieldKey": "Canada/Pacific",
  "name": "Canada/Pacific"
}, {"fieldKey": "Canada/Yukon", "name": "Canada/Yukon"}, {
  "fieldKey": "PST8PDT",
  "name": "PST8PDT"
}, {"fieldKey": "America/Phoenix", "name": "America/Phoenix"}, {
  "fieldKey": "America/Dawson_Creek",
  "name": "America/Dawson_Creek"
}, {"fieldKey": "America/Creston", "name": "America/Creston"}, {
  "fieldKey": "America/Fort_Nelson",
  "name": "America/Fort_Nelson"
}, {"fieldKey": "America/Shiprock", "name": "America/Shiprock"}, {
  "fieldKey": "America/Hermosillo",
  "name": "America/Hermosillo"
}, {"fieldKey": "US/Arizona", "name": "US/Arizona"}, {"fieldKey": "America/Chihuahua", "name": "America/Chihuahua"}, {
  "fieldKey": "America/Mazatlan",
  "name": "America/Mazatlan"
}, {"fieldKey": "Mexico/BajaSur", "name": "Mexico/BajaSur"}, {
  "fieldKey": "America/Denver",
  "name": "America/Denver"
}, {"fieldKey": "America/Edmonton", "name": "America/Edmonton"}, {
  "fieldKey": "America/Cambridge_Bay",
  "name": "America/Cambridge_Bay"
}, {"fieldKey": "America/Inuvik", "name": "America/Inuvik"}, {
  "fieldKey": "America/Yellowknife",
  "name": "America/Yellowknife"
}, {"fieldKey": "Canada/Mountain", "name": "Canada/Mountain"}, {
  "fieldKey": "America/Ojinaga",
  "name": "America/Ojinaga"
}, {"fieldKey": "America/Boise", "name": "America/Boise"}, {
  "fieldKey": "Navajo",
  "name": "Navajo"
}, {"fieldKey": "MST7MDT", "name": "MST7MDT"}, {"fieldKey": "MST", "name": "MST"}, {
  "fieldKey": "America/Guatemala",
  "name": "America/Guatemala"
}, {"fieldKey": "America/Belize", "name": "America/Belize"}, {
  "fieldKey": "America/Costa_Rica",
  "name": "America/Costa_Rica"
}, {"fieldKey": "Pacific/Galapagos", "name": "Pacific/Galapagos"}, {
  "fieldKey": "America/Tegucigalpa",
  "name": "America/Tegucigalpa"
}, {"fieldKey": "America/Managua", "name": "America/Managua"}, {
  "fieldKey": "America/El_Salvador",
  "name": "America/El_Salvador"
}, {"fieldKey": "Etc/GMT+6", "name": "Etc/GMT+6"}, {
  "fieldKey": "America/Chicago",
  "name": "America/Chicago"
}, {"fieldKey": "America/Winnipeg", "name": "America/Winnipeg"}, {
  "fieldKey": "America/Rainy_River",
  "name": "America/Rainy_River"
}, {"fieldKey": "America/Rankin_Inlet", "name": "America/Rankin_Inlet"}, {
  "fieldKey": "America/Resolute",
  "name": "America/Resolute"
}, {"fieldKey": "Canada/Central", "name": "Canada/Central"}, {
  "fieldKey": "America/Matamoros",
  "name": "America/Matamoros"
}, {"fieldKey": "America/Indiana/Knox", "name": "America/Indiana/Knox"}, {
  "fieldKey": "America/Knox_IN",
  "name": "America/Knox_IN"
}, {"fieldKey": "America/Indiana/Tell_City", "name": "America/Indiana/Tell_City"}, {
  "fieldKey": "America/Menominee",
  "name": "America/Menominee"
}, {
  "fieldKey": "America/North_Dakota/Beulah",
  "name": "America/North_Dakota/Beulah"
}, {
  "fieldKey": "America/North_Dakota/Center",
  "name": "America/North_Dakota/Center"
}, {
  "fieldKey": "America/North_Dakota/New_Salem",
  "name": "America/North_Dakota/New_Salem"
}, {"fieldKey": "US/Indiana-Starke", "name": "US/Indiana-Starke"}, {
  "fieldKey": "CST6CDT",
  "name": "CST6CDT"
}, {"fieldKey": "Pacific/Easter", "name": "Pacific/Easter"}, {
  "fieldKey": "Chile/EasterIsland",
  "name": "Chile/EasterIsland"
}, {"fieldKey": "America/Mexico_City", "name": "America/Mexico_City"}, {
  "fieldKey": "America/Bahia_Banderas",
  "name": "America/Bahia_Banderas"
}, {"fieldKey": "America/Merida", "name": "America/Merida"}, {
  "fieldKey": "America/Monterrey",
  "name": "America/Monterrey"
}, {"fieldKey": "Mexico/General", "name": "Mexico/General"}, {
  "fieldKey": "America/Regina",
  "name": "America/Regina"
}, {"fieldKey": "America/Swift_Current", "name": "America/Swift_Current"}, {
  "fieldKey": "Canada/Saskatchewan",
  "name": "Canada/Saskatchewan"
}, {"fieldKey": "America/Bogota", "name": "America/Bogota"}, {
  "fieldKey": "America/Rio_Branco",
  "name": "America/Rio_Branco"
}, {"fieldKey": "America/Eirunepe", "name": "America/Eirunepe"}, {
  "fieldKey": "America/Porto_Acre",
  "name": "America/Porto_Acre"
}, {"fieldKey": "Brazil/Acre", "name": "Brazil/Acre"}, {
  "fieldKey": "America/Coral_Harbour",
  "name": "America/Coral_Harbour"
}, {"fieldKey": "America/Guayaquil", "name": "America/Guayaquil"}, {
  "fieldKey": "America/Jamaica",
  "name": "America/Jamaica"
}, {"fieldKey": "Jamaica", "name": "Jamaica"}, {
  "fieldKey": "America/Cayman",
  "name": "America/Cayman"
}, {"fieldKey": "America/Panama", "name": "America/Panama"}, {
  "fieldKey": "America/Lima",
  "name": "America/Lima"
}, {"fieldKey": "Etc/GMT+5", "name": "Etc/GMT+5"}, {
  "fieldKey": "Australia/ACT",
  "name": "Australia/ACT"
}, {"fieldKey": "ACT", "name": "ACT"}, {"fieldKey": "EST", "name": "EST"}, {
  "fieldKey": "America/Cancun",
  "name": "America/Cancun"
}, {"fieldKey": "America/New_York", "name": "America/New_York"}, {
  "fieldKey": "America/Nassau",
  "name": "America/Nassau"
}, {"fieldKey": "America/Toronto", "name": "America/Toronto"}, {
  "fieldKey": "America/Iqaluit",
  "name": "America/Iqaluit"
}, {"fieldKey": "America/Montreal", "name": "America/Montreal"}, {
  "fieldKey": "America/Nipigon",
  "name": "America/Nipigon"
}, {"fieldKey": "America/Pangnirtung", "name": "America/Pangnirtung"}, {
  "fieldKey": "America/Thunder_Bay",
  "name": "America/Thunder_Bay"
}, {"fieldKey": "America/Atikokan", "name": "America/Atikokan"}, {
  "fieldKey": "Canada/Eastern",
  "name": "Canada/Eastern"
}, {"fieldKey": "America/Detroit", "name": "America/Detroit"}, {
  "fieldKey": "America/Indiana/Petersburg",
  "name": "America/Indiana/Petersburg"
}, {
  "fieldKey": "America/Indiana/Vincennes",
  "name": "America/Indiana/Vincennes"
}, {
  "fieldKey": "America/Indiana/Winamac",
  "name": "America/Indiana/Winamac"
}, {
  "fieldKey": "America/Kentucky/Monticello",
  "name": "America/Kentucky/Monticello"
}, {"fieldKey": "America/Louisville", "name": "America/Louisville"}, {
  "fieldKey": "America/Kentucky/Louisville",
  "name": "America/Kentucky/Louisville"
}, {"fieldKey": "US/Michigan", "name": "US/Michigan"}, {
  "fieldKey": "EST5EDT",
  "name": "EST5EDT"
}, {"fieldKey": "America/Port-au-Prince", "name": "America/Port-au-Prince"}, {
  "fieldKey": "America/Havana",
  "name": "America/Havana"
}, {"fieldKey": "Cuba", "name": "Cuba"}, {
  "fieldKey": "America/Indianapolis",
  "name": "America/Indianapolis"
}, {"fieldKey": "America/Indiana/Marengo", "name": "America/Indiana/Marengo"}, {
  "fieldKey": "America/Indiana/Vevay",
  "name": "America/Indiana/Vevay"
}, {"fieldKey": "America/Fort_Wayne", "name": "America/Fort_Wayne"}, {
  "fieldKey": "America/Indiana/Indianapolis",
  "name": "America/Indiana/Indianapolis"
}, {"fieldKey": "US/East-Indiana", "name": "US/East-Indiana"}, {
  "fieldKey": "America/Grand_Turk",
  "name": "America/Grand_Turk"
}, {"fieldKey": "America/Asuncion", "name": "America/Asuncion"}, {
  "fieldKey": "America/Halifax",
  "name": "America/Halifax"
}, {"fieldKey": "Atlantic/Bermuda", "name": "Atlantic/Bermuda"}, {
  "fieldKey": "America/Glace_Bay",
  "name": "America/Glace_Bay"
}, {"fieldKey": "America/Goose_Bay", "name": "America/Goose_Bay"}, {
  "fieldKey": "America/Moncton",
  "name": "America/Moncton"
}, {"fieldKey": "Canada/Atlantic", "name": "Canada/Atlantic"}, {
  "fieldKey": "America/Thule",
  "name": "America/Thule"
}, {"fieldKey": "America/Caracas", "name": "America/Caracas"}, {
  "fieldKey": "America/Cuiaba",
  "name": "America/Cuiaba"
}, {"fieldKey": "America/Campo_Grande", "name": "America/Campo_Grande"}, {
  "fieldKey": "America/La_Paz",
  "name": "America/La_Paz"
}, {"fieldKey": "America/Antigua", "name": "America/Antigua"}, {
  "fieldKey": "America/Anguilla",
  "name": "America/Anguilla"
}, {"fieldKey": "America/Aruba", "name": "America/Aruba"}, {
  "fieldKey": "America/Barbados",
  "name": "America/Barbados"
}, {"fieldKey": "America/St_Barthelemy", "name": "America/St_Barthelemy"}, {
  "fieldKey": "America/Kralendijk",
  "name": "America/Kralendijk"
}, {"fieldKey": "America/Manaus", "name": "America/Manaus"}, {
  "fieldKey": "America/Boa_Vista",
  "name": "America/Boa_Vista"
}, {"fieldKey": "America/Porto_Velho", "name": "America/Porto_Velho"}, {
  "fieldKey": "Brazil/West",
  "name": "Brazil/West"
}, {"fieldKey": "America/Blanc-Sablon", "name": "America/Blanc-Sablon"}, {
  "fieldKey": "America/Curacao",
  "name": "America/Curacao"
}, {"fieldKey": "America/Dominica", "name": "America/Dominica"}, {
  "fieldKey": "America/Santo_Domingo",
  "name": "America/Santo_Domingo"
}, {"fieldKey": "America/Grenada", "name": "America/Grenada"}, {
  "fieldKey": "America/Guadeloupe",
  "name": "America/Guadeloupe"
}, {"fieldKey": "America/Guyana", "name": "America/Guyana"}, {
  "fieldKey": "America/St_Kitts",
  "name": "America/St_Kitts"
}, {"fieldKey": "America/St_Lucia", "name": "America/St_Lucia"}, {
  "fieldKey": "America/Marigot",
  "name": "America/Marigot"
}, {"fieldKey": "America/Martinique", "name": "America/Martinique"}, {
  "fieldKey": "America/Montserrat",
  "name": "America/Montserrat"
}, {"fieldKey": "America/Puerto_Rico", "name": "America/Puerto_Rico"}, {
  "fieldKey": "America/Lower_Princes",
  "name": "America/Lower_Princes"
}, {"fieldKey": "America/Port_of_Spain", "name": "America/Port_of_Spain"}, {
  "fieldKey": "America/Virgin",
  "name": "America/Virgin"
}, {"fieldKey": "America/St_Vincent", "name": "America/St_Vincent"}, {
  "fieldKey": "America/Tortola",
  "name": "America/Tortola"
}, {"fieldKey": "America/St_Thomas", "name": "America/St_Thomas"}, {
  "fieldKey": "Etc/GMT+4",
  "name": "Etc/GMT+4"
}, {"fieldKey": "America/Santiago", "name": "America/Santiago"}, {
  "fieldKey": "Chile/Continental",
  "name": "Chile/Continental"
}, {"fieldKey": "America/St_Johns", "name": "America/St_Johns"}, {
  "fieldKey": "Canada/Newfoundland",
  "name": "Canada/Newfoundland"
}, {"fieldKey": "America/Araguaina", "name": "America/Araguaina"}, {
  "fieldKey": "America/Sao_Paulo",
  "name": "America/Sao_Paulo"
}, {"fieldKey": "Brazil/East", "name": "Brazil/East"}, {
  "fieldKey": "America/Cayenne",
  "name": "America/Cayenne"
}, {"fieldKey": "Antarctica/Rothera", "name": "Antarctica/Rothera"}, {
  "fieldKey": "Antarctica/Palmer",
  "name": "Antarctica/Palmer"
}, {"fieldKey": "America/Fortaleza", "name": "America/Fortaleza"}, {
  "fieldKey": "America/Belem",
  "name": "America/Belem"
}, {"fieldKey": "America/Maceio", "name": "America/Maceio"}, {
  "fieldKey": "America/Recife",
  "name": "America/Recife"
}, {"fieldKey": "America/Santarem", "name": "America/Santarem"}, {
  "fieldKey": "Atlantic/Stanley",
  "name": "Atlantic/Stanley"
}, {"fieldKey": "America/Paramaribo", "name": "America/Paramaribo"}, {
  "fieldKey": "Etc/GMT+3",
  "name": "Etc/GMT+3"
}, {"fieldKey": "America/Buenos_Aires", "name": "America/Buenos_Aires"}, {
  "fieldKey": "America/Argentina/La_Rioja",
  "name": "America/Argentina/La_Rioja"
}, {
  "fieldKey": "America/Argentina/Rio_Gallegos",
  "name": "America/Argentina/Rio_Gallegos"
}, {
  "fieldKey": "America/Argentina/Salta",
  "name": "America/Argentina/Salta"
}, {
  "fieldKey": "America/Argentina/San_Juan",
  "name": "America/Argentina/San_Juan"
}, {
  "fieldKey": "America/Argentina/San_Luis",
  "name": "America/Argentina/San_Luis"
}, {
  "fieldKey": "America/Argentina/Tucuman",
  "name": "America/Argentina/Tucuman"
}, {"fieldKey": "America/Argentina/Ushuaia", "name": "America/Argentina/Ushuaia"}, {
  "fieldKey": "America/Catamarca",
  "name": "America/Catamarca"
}, {
  "fieldKey": "America/Argentina/Catamarca",
  "name": "America/Argentina/Catamarca"
}, {
  "fieldKey": "America/Argentina/ComodRivadavia",
  "name": "America/Argentina/ComodRivadavia"
}, {"fieldKey": "America/Cordoba", "name": "America/Cordoba"}, {
  "fieldKey": "America/Argentina/Cordoba",
  "name": "America/Argentina/Cordoba"
}, {"fieldKey": "America/Jujuy", "name": "America/Jujuy"}, {
  "fieldKey": "America/Argentina/Jujuy",
  "name": "America/Argentina/Jujuy"
}, {"fieldKey": "America/Mendoza", "name": "America/Mendoza"}, {
  "fieldKey": "America/Argentina/Mendoza",
  "name": "America/Argentina/Mendoza"
}, {
  "fieldKey": "America/Argentina/Buenos_Aires",
  "name": "America/Argentina/Buenos_Aires"
}, {"fieldKey": "America/Rosario", "name": "America/Rosario"}, {
  "fieldKey": "America/Godthab",
  "name": "America/Godthab"
}, {"fieldKey": "America/Montevideo", "name": "America/Montevideo"}, {
  "fieldKey": "America/Punta_Arenas",
  "name": "America/Punta_Arenas"
}, {"fieldKey": "America/Miquelon", "name": "America/Miquelon"}, {
  "fieldKey": "America/Bahia",
  "name": "America/Bahia"
}, {"fieldKey": "Etc/GMT+2", "name": "Etc/GMT+2"}, {
  "fieldKey": "America/Noronha",
  "name": "America/Noronha"
}, {"fieldKey": "Brazil/DeNoronha", "name": "Brazil/DeNoronha"}, {
  "fieldKey": "Atlantic/South_Georgia",
  "name": "Atlantic/South_Georgia"
}, {"fieldKey": "Atlantic/Azores", "name": "Atlantic/Azores"}, {
  "fieldKey": "America/Scoresbysund",
  "name": "America/Scoresbysund"
}, {"fieldKey": "Atlantic/Cape_Verde", "name": "Atlantic/Cape_Verde"}, {
  "fieldKey": "Etc/GMT+1",
  "name": "Etc/GMT+1"
}, {"fieldKey": "Europe/London", "name": "Europe/London"}, {
  "fieldKey": "Etc/Greenwich",
  "name": "Etc/Greenwich"
}, {"fieldKey": "Europe/Belfast", "name": "Europe/Belfast"}, {"fieldKey": "GB", "name": "GB"}, {
  "fieldKey": "GB-Eire",
  "name": "GB-Eire"
}, {"fieldKey": "Greenwich", "name": "Greenwich"}, {
  "fieldKey": "Atlantic/Canary",
  "name": "Atlantic/Canary"
}, {"fieldKey": "Atlantic/Faeroe", "name": "Atlantic/Faeroe"}, {
  "fieldKey": "Europe/Guernsey",
  "name": "Europe/Guernsey"
}, {"fieldKey": "America/Danmarkshavn", "name": "America/Danmarkshavn"}, {
  "fieldKey": "Europe/Dublin",
  "name": "Europe/Dublin"
}, {"fieldKey": "Eire", "name": "Eire"}, {
  "fieldKey": "Europe/Isle_of_Man",
  "name": "Europe/Isle_of_Man"
}, {"fieldKey": "Europe/Jersey", "name": "Europe/Jersey"}, {
  "fieldKey": "Europe/Lisbon",
  "name": "Europe/Lisbon"
}, {"fieldKey": "Portugal", "name": "Portugal"}, {
  "fieldKey": "Atlantic/Madeira",
  "name": "Atlantic/Madeira"
}, {"fieldKey": "Atlantic/Faroe", "name": "Atlantic/Faroe"}, {"fieldKey": "WET", "name": "WET"}, {
  "fieldKey": "Etc/GMT",
  "name": "Etc/GMT"
}, {"fieldKey": "Etc/GMT+0", "name": "Etc/GMT+0"}, {
  "fieldKey": "Etc/GMT-0",
  "name": "Etc/GMT-0"
}, {"fieldKey": "Etc/GMT0", "name": "Etc/GMT0"}, {"fieldKey": "Etc/", "name": "Etc/"}, {
  "fieldKey": "Etc/UCT",
  "name": "Etc/UCT"
}, {"fieldKey": "Etc/Universal", "name": "Etc/Universal"}, {
  "fieldKey": "Etc/Zulu",
  "name": "Etc/Zulu"
}, {"fieldKey": "GMT", "name": "GMT"}, {"fieldKey": "GMT0", "name": "GMT0"}, {
  "fieldKey": "GMT+0",
  "name": "GMT+0"
}, {"fieldKey": "GMT-0", "name": "GMT-0"}, {"fieldKey": "UCT", "name": "UCT"}, {
  "fieldKey": "Universal",
  "name": "Universal"
}, {"fieldKey": "Zulu", "name": "Zulu"}, {
  "fieldKey": "Antarctica/Troll",
  "name": "Antarctica/Troll"
}, {"fieldKey": "Atlantic/Reykjavik", "name": "Atlantic/Reykjavik"}, {
  "fieldKey": "Iceland",
  "name": "Iceland"
}, {"fieldKey": "Africa/Ouagadougou", "name": "Africa/Ouagadougou"}, {
  "fieldKey": "Africa/Abidjan",
  "name": "Africa/Abidjan"
}, {"fieldKey": "Africa/Accra", "name": "Africa/Accra"}, {
  "fieldKey": "Africa/Banjul",
  "name": "Africa/Banjul"
}, {"fieldKey": "Africa/Conakry", "name": "Africa/Conakry"}, {
  "fieldKey": "Africa/Bissau",
  "name": "Africa/Bissau"
}, {"fieldKey": "Africa/Monrovia", "name": "Africa/Monrovia"}, {
  "fieldKey": "Africa/Bamako",
  "name": "Africa/Bamako"
}, {"fieldKey": "Africa/Nouakchott", "name": "Africa/Nouakchott"}, {
  "fieldKey": "Atlantic/St_Helena",
  "name": "Atlantic/St_Helena"
}, {"fieldKey": "Africa/Freetown", "name": "Africa/Freetown"}, {
  "fieldKey": "Africa/Dakar",
  "name": "Africa/Dakar"
}, {"fieldKey": "Africa/Lome", "name": "Africa/Lome"}, {
  "fieldKey": "Africa/Sao_Tome",
  "name": "Africa/Sao_Tome"
}, {"fieldKey": "Africa/Timbuktu", "name": "Africa/Timbuktu"}, {
  "fieldKey": "Africa/Casablanca",
  "name": "Africa/Casablanca"
}, {"fieldKey": "Africa/El_Aaiun", "name": "Africa/El_Aaiun"}, {
  "fieldKey": "Europe/Berlin",
  "name": "Europe/Berlin"
}, {"fieldKey": "Europe/Andorra", "name": "Europe/Andorra"}, {
  "fieldKey": "Europe/Vienna",
  "name": "Europe/Vienna"
}, {"fieldKey": "Europe/Zurich", "name": "Europe/Zurich"}, {
  "fieldKey": "Europe/Busingen",
  "name": "Europe/Busingen"
}, {"fieldKey": "Europe/Gibraltar", "name": "Europe/Gibraltar"}, {
  "fieldKey": "Europe/Rome",
  "name": "Europe/Rome"
}, {"fieldKey": "Europe/Vaduz", "name": "Europe/Vaduz"}, {
  "fieldKey": "Europe/Luxembourg",
  "name": "Europe/Luxembourg"
}, {"fieldKey": "Europe/Monaco", "name": "Europe/Monaco"}, {
  "fieldKey": "Europe/Malta",
  "name": "Europe/Malta"
}, {"fieldKey": "Europe/Amsterdam", "name": "Europe/Amsterdam"}, {
  "fieldKey": "Europe/Oslo",
  "name": "Europe/Oslo"
}, {"fieldKey": "Europe/Stockholm", "name": "Europe/Stockholm"}, {
  "fieldKey": "Arctic/Longyearbyen",
  "name": "Arctic/Longyearbyen"
}, {"fieldKey": "Atlantic/Jan_Mayen", "name": "Atlantic/Jan_Mayen"}, {
  "fieldKey": "Europe/San_Marino",
  "name": "Europe/San_Marino"
}, {"fieldKey": "Europe/Vatican", "name": "Europe/Vatican"}, {
  "fieldKey": "Europe/Budapest",
  "name": "Europe/Budapest"
}, {"fieldKey": "Europe/Tirane", "name": "Europe/Tirane"}, {
  "fieldKey": "Europe/Prague",
  "name": "Europe/Prague"
}, {"fieldKey": "Europe/Podgorica", "name": "Europe/Podgorica"}, {
  "fieldKey": "Europe/Belgrade",
  "name": "Europe/Belgrade"
}, {"fieldKey": "Europe/Ljubljana", "name": "Europe/Ljubljana"}, {
  "fieldKey": "Europe/Bratislava",
  "name": "Europe/Bratislava"
}, {"fieldKey": "Europe/Paris", "name": "Europe/Paris"}, {
  "fieldKey": "MET",
  "name": "MET"
}, {"fieldKey": "Europe/Brussels", "name": "Europe/Brussels"}, {
  "fieldKey": "Europe/Copenhagen",
  "name": "Europe/Copenhagen"
}, {"fieldKey": "Europe/Madrid", "name": "Europe/Madrid"}, {
  "fieldKey": "Africa/Ceuta",
  "name": "Africa/Ceuta"
}, {"fieldKey": "Europe/Warsaw", "name": "Europe/Warsaw"}, {
  "fieldKey": "Poland",
  "name": "Poland"
}, {"fieldKey": "Europe/Sarajevo", "name": "Europe/Sarajevo"}, {
  "fieldKey": "Europe/Zagreb",
  "name": "Europe/Zagreb"
}, {"fieldKey": "Europe/Skopje", "name": "Europe/Skopje"}, {
  "fieldKey": "Africa/Lagos",
  "name": "Africa/Lagos"
}, {"fieldKey": "Africa/Luanda", "name": "Africa/Luanda"}, {
  "fieldKey": "Africa/Porto-Novo",
  "name": "Africa/Porto-Novo"
}, {"fieldKey": "Africa/Kinshasa", "name": "Africa/Kinshasa"}, {
  "fieldKey": "Africa/Bangui",
  "name": "Africa/Bangui"
}, {"fieldKey": "Africa/Brazzaville", "name": "Africa/Brazzaville"}, {
  "fieldKey": "Africa/Douala",
  "name": "Africa/Douala"
}, {"fieldKey": "Africa/Algiers", "name": "Africa/Algiers"}, {
  "fieldKey": "Africa/Libreville",
  "name": "Africa/Libreville"
}, {"fieldKey": "Africa/Malabo", "name": "Africa/Malabo"}, {
  "fieldKey": "Africa/Niamey",
  "name": "Africa/Niamey"
}, {"fieldKey": "Africa/Ndjamena", "name": "Africa/Ndjamena"}, {
  "fieldKey": "Africa/Tunis",
  "name": "Africa/Tunis"
}, {"fieldKey": "Etc/GMT-1", "name": "Etc/GMT-1"}, {"fieldKey": "CET", "name": "CET"}, {
  "fieldKey": "Asia/Amman",
  "name": "Asia/Amman"
}, {"fieldKey": "Europe/Bucharest", "name": "Europe/Bucharest"}, {
  "fieldKey": "Asia/Nicosia",
  "name": "Asia/Nicosia"
}, {"fieldKey": "Asia/Famagusta", "name": "Asia/Famagusta"}, {
  "fieldKey": "Europe/Athens",
  "name": "Europe/Athens"
}, {"fieldKey": "Europe/Nicosia", "name": "Europe/Nicosia"}, {
  "fieldKey": "Asia/Beirut",
  "name": "Asia/Beirut"
}, {"fieldKey": "Africa/Cairo", "name": "Africa/Cairo"}, {
  "fieldKey": "Egypt",
  "name": "Egypt"
}, {"fieldKey": "Europe/Chisinau", "name": "Europe/Chisinau"}, {
  "fieldKey": "Europe/Tiraspol",
  "name": "Europe/Tiraspol"
}, {"fieldKey": "Asia/Damascus", "name": "Asia/Damascus"}, {
  "fieldKey": "Asia/Hebron",
  "name": "Asia/Hebron"
}, {"fieldKey": "Asia/Gaza", "name": "Asia/Gaza"}, {
  "fieldKey": "Africa/Johannesburg",
  "name": "Africa/Johannesburg"
}, {"fieldKey": "Africa/Bujumbura", "name": "Africa/Bujumbura"}, {
  "fieldKey": "Africa/Gaborone",
  "name": "Africa/Gaborone"
}, {"fieldKey": "Africa/Lubumbashi", "name": "Africa/Lubumbashi"}, {
  "fieldKey": "Africa/Maseru",
  "name": "Africa/Maseru"
}, {"fieldKey": "Africa/Blantyre", "name": "Africa/Blantyre"}, {
  "fieldKey": "Africa/Maputo",
  "name": "Africa/Maputo"
}, {"fieldKey": "Africa/Kigali", "name": "Africa/Kigali"}, {
  "fieldKey": "Africa/Mbabane",
  "name": "Africa/Mbabane"
}, {"fieldKey": "Africa/Lusaka", "name": "Africa/Lusaka"}, {
  "fieldKey": "Africa/Harare",
  "name": "Africa/Harare"
}, {"fieldKey": "Etc/GMT-2", "name": "Etc/GMT-2"}, {
  "fieldKey": "Europe/Kyiv",
  "name": "Europe/Kyiv"
}, {"fieldKey": "Europe/Mariehamn", "name": "Europe/Mariehamn"}, {
  "fieldKey": "Europe/Sofia",
  "name": "Europe/Sofia"
}, {"fieldKey": "Europe/Tallinn", "name": "Europe/Tallinn"}, {
  "fieldKey": "Europe/Helsinki",
  "name": "Europe/Helsinki"
}, {"fieldKey": "Europe/Vilnius", "name": "Europe/Vilnius"}, {
  "fieldKey": "Europe/Riga",
  "name": "Europe/Riga"
}, {"fieldKey": "Europe/Uzhgorod", "name": "Europe/Uzhgorod"}, {
  "fieldKey": "Europe/Zaporozhye",
  "name": "Europe/Zaporozhye"
}, {"fieldKey": "Asia/Jerusalem", "name": "Asia/Jerusalem"}, {
  "fieldKey": "Asia/Tel_Aviv",
  "name": "Asia/Tel_Aviv"
}, {"fieldKey": "Israel", "name": "Israel"}, {
  "fieldKey": "Europe/Kaliningrad",
  "name": "Europe/Kaliningrad"
}, {"fieldKey": "EET", "name": "EET"}, {
  "fieldKey": "Africa/Khartoum",
  "name": "Africa/Khartoum"
}, {"fieldKey": "Africa/Tripoli", "name": "Africa/Tripoli"}, {
  "fieldKey": "Libya",
  "name": "Libya"
}, {"fieldKey": "Africa/Windhoek", "name": "Africa/Windhoek"}, {
  "fieldKey": "Asia/Baghdad",
  "name": "Asia/Baghdad"
}, {"fieldKey": "Europe/Istanbul", "name": "Europe/Istanbul"}, {
  "fieldKey": "Asia/Istanbul",
  "name": "Asia/Istanbul"
}, {"fieldKey": "Turkey", "name": "Turkey"}, {
  "fieldKey": "Asia/Riyadh",
  "name": "Asia/Riyadh"
}, {"fieldKey": "Asia/Bahrain", "name": "Asia/Bahrain"}, {
  "fieldKey": "Asia/Kuwait",
  "name": "Asia/Kuwait"
}, {"fieldKey": "Asia/Qatar", "name": "Asia/Qatar"}, {
  "fieldKey": "Asia/Aden",
  "name": "Asia/Aden"
}, {"fieldKey": "Europe/Minsk", "name": "Europe/Minsk"}, {
  "fieldKey": "Europe/Moscow",
  "name": "Europe/Moscow"
}, {"fieldKey": "Europe/Kirov", "name": "Europe/Kirov"}, {
  "fieldKey": "W-SU",
  "name": "W-SU"
}, {"fieldKey": "Europe/Simferopol", "name": "Europe/Simferopol"}, {
  "fieldKey": "Africa/Nairobi",
  "name": "Africa/Nairobi"
}, {"fieldKey": "Antarctica/Syowa", "name": "Antarctica/Syowa"}, {
  "fieldKey": "Africa/Djibouti",
  "name": "Africa/Djibouti"
}, {"fieldKey": "Africa/Asmera", "name": "Africa/Asmera"}, {
  "fieldKey": "Africa/Asmara",
  "name": "Africa/Asmara"
}, {"fieldKey": "Africa/Addis_Ababa", "name": "Africa/Addis_Ababa"}, {
  "fieldKey": "Indian/Comoro",
  "name": "Indian/Comoro"
}, {"fieldKey": "Indian/Antananarivo", "name": "Indian/Antananarivo"}, {
  "fieldKey": "Africa/Mogadishu",
  "name": "Africa/Mogadishu"
}, {"fieldKey": "Africa/Juba", "name": "Africa/Juba"}, {
  "fieldKey": "Africa/Dar_es_Salaam",
  "name": "Africa/Dar_es_Salaam"
}, {"fieldKey": "Africa/Kampala", "name": "Africa/Kampala"}, {
  "fieldKey": "Indian/Mayotte",
  "name": "Indian/Mayotte"
}, {"fieldKey": "Etc/GMT-3", "name": "Etc/GMT-3"}, {
  "fieldKey": "Asia/Tehran",
  "name": "Asia/Tehran"
}, {"fieldKey": "Iran", "name": "Iran"}, {"fieldKey": "Asia/Dubai", "name": "Asia/Dubai"}, {
  "fieldKey": "Asia/Muscat",
  "name": "Asia/Muscat"
}, {"fieldKey": "Etc/GMT-4", "name": "Etc/GMT-4"}, {
  "fieldKey": "Europe/Astrakhan",
  "name": "Europe/Astrakhan"
}, {"fieldKey": "Europe/Ulyanovsk", "name": "Europe/Ulyanovsk"}, {
  "fieldKey": "Asia/Baku",
  "name": "Asia/Baku"
}, {"fieldKey": "Europe/Samara", "name": "Europe/Samara"}, {
  "fieldKey": "Indian/Mauritius",
  "name": "Indian/Mauritius"
}, {"fieldKey": "Indian/Reunion", "name": "Indian/Reunion"}, {
  "fieldKey": "Indian/Mahe",
  "name": "Indian/Mahe"
}, {"fieldKey": "Europe/Saratov", "name": "Europe/Saratov"}, {
  "fieldKey": "Asia/Tbilisi",
  "name": "Asia/Tbilisi"
}, {"fieldKey": "Europe/Volgograd", "name": "Europe/Volgograd"}, {
  "fieldKey": "Asia/Yerevan",
  "name": "Asia/Yerevan"
}, {"fieldKey": "Asia/Kabul", "name": "Asia/Kabul"}, {
  "fieldKey": "Asia/Tashkent",
  "name": "Asia/Tashkent"
}, {"fieldKey": "Antarctica/Mawson", "name": "Antarctica/Mawson"}, {
  "fieldKey": "Asia/Oral",
  "name": "Asia/Oral"
}, {"fieldKey": "Asia/Aqtau", "name": "Asia/Aqtau"}, {
  "fieldKey": "Asia/Aqtobe",
  "name": "Asia/Aqtobe"
}, {"fieldKey": "Asia/Atyrau", "name": "Asia/Atyrau"}, {
  "fieldKey": "Indian/Maldives",
  "name": "Indian/Maldives"
}, {"fieldKey": "Indian/Kerguelen", "name": "Indian/Kerguelen"}, {
  "fieldKey": "Asia/Dushanbe",
  "name": "Asia/Dushanbe"
}, {"fieldKey": "Asia/Ashgabat", "name": "Asia/Ashgabat"}, {
  "fieldKey": "Asia/Ashkhabad",
  "name": "Asia/Ashkhabad"
}, {"fieldKey": "Asia/Samarkand", "name": "Asia/Samarkand"}, {
  "fieldKey": "Etc/GMT-5",
  "name": "Etc/GMT-5"
}, {"fieldKey": "Asia/Yekaterinburg", "name": "Asia/Yekaterinburg"}, {
  "fieldKey": "Asia/Karachi",
  "name": "Asia/Karachi"
}, {"fieldKey": "Asia/Qyzylorda", "name": "Asia/Qyzylorda"}, {
  "fieldKey": "Asia/Calcutta",
  "name": "Asia/Calcutta"
}, {"fieldKey": "Asia/Kolkata", "name": "Asia/Kolkata"}, {
  "fieldKey": "Asia/Colombo",
  "name": "Asia/Colombo"
}, {"fieldKey": "Asia/Katmandu", "name": "Asia/Katmandu"}, {
  "fieldKey": "Asia/Kathmandu",
  "name": "Asia/Kathmandu"
}, {"fieldKey": "Asia/Almaty", "name": "Asia/Almaty"}, {
  "fieldKey": "Antarctica/Vostok",
  "name": "Antarctica/Vostok"
}, {"fieldKey": "Asia/Urumqi", "name": "Asia/Urumqi"}, {
  "fieldKey": "Indian/Chagos",
  "name": "Indian/Chagos"
}, {"fieldKey": "Asia/Bishkek", "name": "Asia/Bishkek"}, {
  "fieldKey": "Asia/Qostanay",
  "name": "Asia/Qostanay"
}, {"fieldKey": "Etc/GMT-6", "name": "Etc/GMT-6"}, {
  "fieldKey": "Asia/Dhaka",
  "name": "Asia/Dhaka"
}, {"fieldKey": "Asia/Dacca", "name": "Asia/Dacca"}, {
  "fieldKey": "Asia/Thimphu",
  "name": "Asia/Thimphu"
}, {"fieldKey": "Asia/Thimbu", "name": "Asia/Thimbu"}, {
  "fieldKey": "Asia/Omsk",
  "name": "Asia/Omsk"
}, {"fieldKey": "Asia/Rangoon", "name": "Asia/Rangoon"}, {
  "fieldKey": "Asia/Yangon",
  "name": "Asia/Yangon"
}, {"fieldKey": "Indian/Cocos", "name": "Indian/Cocos"}, {
  "fieldKey": "Asia/Bangkok",
  "name": "Asia/Bangkok"
}, {"fieldKey": "Antarctica/Davis", "name": "Antarctica/Davis"}, {
  "fieldKey": "Indian/Christmas",
  "name": "Indian/Christmas"
}, {"fieldKey": "Asia/Jakarta", "name": "Asia/Jakarta"}, {
  "fieldKey": "Asia/Pontianak",
  "name": "Asia/Pontianak"
}, {"fieldKey": "Asia/Phnom_Penh", "name": "Asia/Phnom_Penh"}, {
  "fieldKey": "Asia/Ho_Chi_Minh",
  "name": "Asia/Ho_Chi_Minh"
}, {"fieldKey": "Asia/Vientiane", "name": "Asia/Vientiane"}, {
  "fieldKey": "Asia/Saigon",
  "name": "Asia/Saigon"
}, {"fieldKey": "Etc/GMT-7", "name": "Etc/GMT-7"}, {
  "fieldKey": "Asia/Barnaul",
  "name": "Asia/Barnaul"
}, {"fieldKey": "Asia/Hovd", "name": "Asia/Hovd"}, {
  "fieldKey": "Asia/Krasnoyarsk",
  "name": "Asia/Krasnoyarsk"
}, {"fieldKey": "Asia/Novokuznetsk", "name": "Asia/Novokuznetsk"}, {
  "fieldKey": "Asia/Novosibirsk",
  "name": "Asia/Novosibirsk"
}, {"fieldKey": "Asia/Tomsk", "name": "Asia/Tomsk"}, {
  "fieldKey": "Asia/Shanghai",
  "name": "Asia/Shanghai"
}, {"fieldKey": "Asia/Chongqing", "name": "Asia/Chongqing"}, {
  "fieldKey": "Asia/Chungking",
  "name": "Asia/Chungking"
}, {"fieldKey": "Asia/Harbin", "name": "Asia/Harbin"}, {
  "fieldKey": "Asia/Kashgar",
  "name": "Asia/Kashgar"
}, {"fieldKey": "PRC", "name": "PRC"}, {
  "fieldKey": "Asia/Hong_Kong",
  "name": "Asia/Hong_Kong"
}, {"fieldKey": "Hongkong", "name": "Hongkong"}, {
  "fieldKey": "Asia/Macau",
  "name": "Asia/Macau"
}, {"fieldKey": "Asia/Macao", "name": "Asia/Macao"}, {
  "fieldKey": "Asia/Irkutsk",
  "name": "Asia/Irkutsk"
}, {"fieldKey": "Asia/Singapore", "name": "Asia/Singapore"}, {
  "fieldKey": "Singapore",
  "name": "Singapore"
}, {"fieldKey": "Antarctica/Casey", "name": "Antarctica/Casey"}, {
  "fieldKey": "Asia/Brunei",
  "name": "Asia/Brunei"
}, {"fieldKey": "Asia/Makassar", "name": "Asia/Makassar"}, {
  "fieldKey": "Asia/Ujung_Pandang",
  "name": "Asia/Ujung_Pandang"
}, {"fieldKey": "Asia/Kuala_Lumpur", "name": "Asia/Kuala_Lumpur"}, {
  "fieldKey": "Asia/Kuching",
  "name": "Asia/Kuching"
}, {"fieldKey": "Asia/Manila", "name": "Asia/Manila"}, {
  "fieldKey": "Etc/GMT-8",
  "name": "Etc/GMT-8"
}, {"fieldKey": "Australia/Perth", "name": "Australia/Perth"}, {
  "fieldKey": "Australia/West",
  "name": "Australia/West"
}, {"fieldKey": "Asia/Taipei", "name": "Asia/Taipei"}, {
  "fieldKey": "ROC",
  "name": "ROC"
}, {"fieldKey": "Asia/Ulaanbaatar", "name": "Asia/Ulaanbaatar"}, {
  "fieldKey": "Asia/Choibalsan",
  "name": "Asia/Choibalsan"
}, {"fieldKey": "Asia/Ulan_Bator", "name": "Asia/Ulan_Bator"}, {
  "fieldKey": "Australia/Eucla",
  "name": "Australia/Eucla"
}, {"fieldKey": "Asia/Chita", "name": "Asia/Chita"}, {
  "fieldKey": "Asia/Tokyo",
  "name": "Asia/Tokyo"
}, {"fieldKey": "Japan", "name": "Japan"}, {
  "fieldKey": "Asia/Jayapura",
  "name": "Asia/Jayapura"
}, {"fieldKey": "Pacific/Palau", "name": "Pacific/Palau"}, {
  "fieldKey": "Asia/Dili",
  "name": "Asia/Dili"
}, {"fieldKey": "Etc/GMT-9", "name": "Etc/GMT-9"}, {
  "fieldKey": "Asia/Pyongyang",
  "name": "Asia/Pyongyang"
}, {"fieldKey": "Asia/Seoul", "name": "Asia/Seoul"}, {"fieldKey": "ROK", "name": "ROK"}, {
  "fieldKey": "Asia/Yakutsk",
  "name": "Asia/Yakutsk"
}, {"fieldKey": "Asia/Khandyga", "name": "Asia/Khandyga"}, {
  "fieldKey": "Australia/Adelaide",
  "name": "Australia/Adelaide"
}, {"fieldKey": "Australia/Broken_Hill", "name": "Australia/Broken_Hill"}, {
  "fieldKey": "Australia/South",
  "name": "Australia/South"
}, {"fieldKey": "Australia/Yancowinna", "name": "Australia/Yancowinna"}, {
  "fieldKey": "Australia/Darwin",
  "name": "Australia/Darwin"
}, {"fieldKey": "Australia/North", "name": "Australia/North"}, {
  "fieldKey": "Australia/Brisbane",
  "name": "Australia/Brisbane"
}, {"fieldKey": "Australia/Lindeman", "name": "Australia/Lindeman"}, {
  "fieldKey": "Australia/Queensland",
  "name": "Australia/Queensland"
}, {"fieldKey": "Australia/Sydney", "name": "Australia/Sydney"}, {
  "fieldKey": "Australia/Melbourne",
  "name": "Australia/Melbourne"
}, {"fieldKey": "Australia/Canberra", "name": "Australia/Canberra"}, {
  "fieldKey": "Australia/NSW",
  "name": "Australia/NSW"
}, {"fieldKey": "Australia/Victoria", "name": "Australia/Victoria"}, {
  "fieldKey": "Pacific/Port_Moresby",
  "name": "Pacific/Port_Moresby"
}, {"fieldKey": "Antarctica/DumontDUrville", "name": "Antarctica/DumontDUrville"}, {
  "fieldKey": "Pacific/Truk",
  "name": "Pacific/Truk"
}, {"fieldKey": "Pacific/Guam", "name": "Pacific/Guam"}, {
  "fieldKey": "Pacific/Chuuk",
  "name": "Pacific/Chuuk"
}, {"fieldKey": "Pacific/Yap", "name": "Pacific/Yap"}, {
  "fieldKey": "Pacific/Saipan",
  "name": "Pacific/Saipan"
}, {"fieldKey": "Etc/GMT-10", "name": "Etc/GMT-10"}, {
  "fieldKey": "US/Pacific",
  "name": "US/Pacific"
}, {"fieldKey": "US/Pacific-New", "name": "US/Pacific-New"}, {
  "fieldKey": "Australia/Hobart",
  "name": "Australia/Hobart"
}, {"fieldKey": "Australia/Currie", "name": "Australia/Currie"}, {
  "fieldKey": "Australia/Tasmania",
  "name": "Australia/Tasmania"
}, {"fieldKey": "Asia/Vladivostok", "name": "Asia/Vladivostok"}, {
  "fieldKey": "Asia/Ust-Nera",
  "name": "Asia/Ust-Nera"
}, {"fieldKey": "Australia/Lord_Howe", "name": "Australia/Lord_Howe"}, {
  "fieldKey": "Australia/LHI",
  "name": "Australia/LHI"
}, {"fieldKey": "Pacific/Bougainville", "name": "Pacific/Bougainville"}, {
  "fieldKey": "Asia/Srednekolymsk",
  "name": "Asia/Srednekolymsk"
}, {"fieldKey": "Asia/Magadan", "name": "Asia/Magadan"}, {
  "fieldKey": "Pacific/Norfolk",
  "name": "Pacific/Norfolk"
}, {"fieldKey": "Asia/Sakhalin", "name": "Asia/Sakhalin"}, {
  "fieldKey": "Pacific/Guadalcanal",
  "name": "Pacific/Guadalcanal"
}, {"fieldKey": "Antarctica/Macquarie", "name": "Antarctica/Macquarie"}, {
  "fieldKey": "Pacific/Ponape",
  "name": "Pacific/Ponape"
}, {"fieldKey": "Pacific/Kosrae", "name": "Pacific/Kosrae"}, {
  "fieldKey": "Pacific/Pohnpei",
  "name": "Pacific/Pohnpei"
}, {"fieldKey": "Pacific/Noumea", "name": "Pacific/Noumea"}, {
  "fieldKey": "Pacific/Efate",
  "name": "Pacific/Efate"
}, {"fieldKey": "Etc/GMT-11", "name": "Etc/GMT-11"}, {
  "fieldKey": "Asia/Kamchatka",
  "name": "Asia/Kamchatka"
}, {"fieldKey": "Asia/Anadyr", "name": "Asia/Anadyr"}, {
  "fieldKey": "Pacific/Auckland",
  "name": "Pacific/Auckland"
}, {"fieldKey": "Antarctica/McMurdo", "name": "Antarctica/McMurdo"}, {
  "fieldKey": "Antarctica/South_Pole",
  "name": "Antarctica/South_Pole"
}, {"fieldKey": "NZ", "name": "NZ"}, {"fieldKey": "Etc/GMT-12", "name": "Etc/GMT-12"}, {
  "fieldKey": "Pacific/Tarawa",
  "name": "Pacific/Tarawa"
}, {"fieldKey": "Pacific/Majuro", "name": "Pacific/Majuro"}, {
  "fieldKey": "Pacific/Kwajalein",
  "name": "Pacific/Kwajalein"
}, {"fieldKey": "Kwajalein", "name": "Kwajalein"}, {
  "fieldKey": "Pacific/Nauru",
  "name": "Pacific/Nauru"
}, {"fieldKey": "Pacific/Funafuti", "name": "Pacific/Funafuti"}, {
  "fieldKey": "Pacific/Wake",
  "name": "Pacific/Wake"
}, {"fieldKey": "Pacific/Wallis", "name": "Pacific/Wallis"}, {
  "fieldKey": "Pacific/Fiji",
  "name": "Pacific/Fiji"
}, {"fieldKey": "Pacific/Chatham", "name": "Pacific/Chatham"}, {
  "fieldKey": "NZ-CHAT",
  "name": "NZ-CHAT"
}, {"fieldKey": "Etc/GMT-13", "name": "Etc/GMT-13"}, {
  "fieldKey": "Pacific/Enderbury",
  "name": "Pacific/Enderbury"
}, {"fieldKey": "Pacific/Fakaofo", "name": "Pacific/Fakaofo"}, {
  "fieldKey": "Pacific/Tongatapu",
  "name": "Pacific/Tongatapu"
}, {"fieldKey": "Pacific/Apia", "name": "Pacific/Apia"}, {
  "fieldKey": "Pacific/Kiritimati",
  "name": "Pacific/Kiritimati"
}, {"fieldKey": "Etc/GMT-14", "name": "Etc/GMT-14"}]
