import {showError, showInfo} from "components/FlashMessage/flashMessageSlice";
import { PATHS } from "consts";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";

import InvitationService from 'services/invitation';


export default function Invite() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();

  const sendToken = async () => {
    try {
      const { message } = await InvitationService.inviteUser({ token });
      navigate(`${PATHS.CREATE_ACCOUNT}?token=${token}`);
    } catch (error) {
      const { message } = error;

      dispatch(showError({ message }));
      navigate(PATHS.HOMEPAGE);
    }
  };

  useEffect(() => {
    sendToken();
  }, []);
}
