export const METRIC_DEFAULT = [
  {
    'dimension': 'event_name',
    'metric': 'events',
  },
  {
    'dimension': 'utm_campaign',
    'metric': 'sessions',
  },
  {
    'dimension': 'utm_source',
    'metric': 'users',
  },
];
