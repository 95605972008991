
export default function DsIconLogo({fill}) {
  return (
    <svg height="255.37257167305526" viewBox="0 -18 349.99999999999994 225.70808102416495"
         className="looka-1j8o68f w-12 h-12 inline align-middle" alt="logo">
      <g id="SvgjsG1031"
         transform="matrix(3.1404215847850234,0,0,3.1404215847850234,94.99999401012118,-104.86406055596345)"
         fill={`${fill}`}>
        <path
          d="M6 45.42 c0 1.8 0.84 3.78 3.36 5.52 c0.42 0.3 -0.78 0.54 -1.44 0.06 c-1.32 -0.96 -2.94 -3.36 -2.94 -5.94 c0 -3.84 2.7 -7.92 7.26 -11.1 c6.3 -4.44 13.14 -6.3 18.18 -6.3 c6.78 0 14.16 3.84 14.16 15.12 c0 8.82 -4.56 20.58 -12.6 28.86 c-6.78 7.08 -16.44 11.7 -22.8 11.7 s-9.18 -3.24 -9.18 -7.8 c0 -5.88 3.6 -13.86 13.2 -20.58 c0.84 -0.54 1.62 -1.08 2.46 -1.56 c2.1 -8.16 4.26 -15.66 4.68 -16.74 c0.66 -1.92 1.2 -0.36 0.78 1.2 c-1.32 4.44 -2.76 9.66 -4.08 14.76 c6.9 -3.66 12.42 -3.66 13.02 -3.6 c0.72 0.06 0.42 0.66 -1.32 0.78 c-1.56 0.12 -6.3 1.02 -12.3 4.92 c-1.62 6.3 -3.12 12.24 -3.96 16.26 c-0.42 1.98 -0.9 0.42 -0.78 -0.3 c0.6 -3.78 1.92 -9.42 3.36 -15 l-1.2 0.9 c-8.76 6.72 -12.96 14.58 -12.96 18.96 c0 3.12 2.58 5.82 8.4 5.82 c6.48 0 17.28 -5.52 21.66 -10.68 c7.56 -8.64 12.06 -18.54 12.06 -28.86 c0 -7.5 -4.44 -12.66 -12.84 -12.66 c-5.16 0 -11.88 2.52 -16.8 5.82 c-4.98 3.3 -7.38 7.44 -7.38 10.44 z"></path>
      </g>
    </svg>
  )
}
