import { useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { faPlus, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import SegmentService from "services/segment";
import TailwindCommandPalettes from "components/TailwindCommandPalettes";
import { showError, showInfo } from "components/FlashMessage/flashMessageSlice";


export function SaveToSegment({ queryScript, payload, rawPayload, result }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { appId } = useParams()
  const [segments, setSegments] = useState([])
  const [clickSaveButton, setClickSaveButton] = useState(false);
  const [name, setName] = useState('')

  const onClickSaveButton = (e) => {
    e.preventDefault();
    setClickSaveButton(true);
  }

  const createNewSegment = () => {
    if (name === '') {
      dispatch(showError({ message: "Name is required" }))
      return
    }

    try {
      const data = {
        name: name,
        queryScript: queryScript,
        payload: JSON.stringify(payload),
        result: JSON.stringify(result),
        rawPayload: JSON.stringify(rawPayload),
      }
      SegmentService.saveSegment({ appId, data });
      setClickSaveButton(false)
      dispatch(showInfo({ message: "Create segment successfully" }))
    } catch (error) {
      const { message } = error;
      dispatch(showError({ message }));
    }
  }

  const saveToSegment = (item) => {
    try {
      const segmentId = item.id;
      const data = {
        queryScript: queryScript,
        payload: JSON.stringify(payload),
        result: JSON.stringify(result),
        rawPayload: JSON.stringify(rawPayload),
      }
      SegmentService.UpdateSegment({ appId, segmentId, data });
      setClickSaveButton(false)
      dispatch(showInfo({ message: "Save segment successfully" }))
    } catch (error) {
      const { message } = error;
      dispatch(showError({ message }));
    }
  }

  const getSegmentList = async () => {
    try {
      const segments = await SegmentService.listSegments({ appId });
      setSegments(segments);
    } catch (error) {
      const { message } = error;
      dispatch(showError({ message }));
    }
  }

  useEffect(() => {
    getSegmentList();
  }, []);

  return (
    <div className="relative">
      <button onClick={onClickSaveButton}
        className="ds-button"
      >
        {t("SAVE_SEGMENT")}
      </button>
      <TailwindCommandPalettes
        placeholder={"Create or search segment"}
        open={clickSaveButton}
        setOpen={setClickSaveButton}
        query={name}
        setQuery={setName}
        pinnedActions={[
          {
            id: 1,
            name: "Create new segment",
            icon: faPlus,
            action: createNewSegment
          },
        ]}
        actions={
          segments.map((item) => ({
            ...item,
            icon: <FontAwesomeIcon icon={faSave} style={{ "color": "text-indigo-500" }} />,
            action: () => saveToSegment(item),
          }))
        }
      />
    </div>
  )
}
