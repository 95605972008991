import HttpMethod from "consts/httpMethod";
import { processErrorResponse, processRequest, processSuccessResponse, stringify } from "../utils";

export default {
  async createChart(data) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: '/chart/create',
      method: HttpMethod.POST
    };
    try {
      const res = await processRequest(ep, { data });
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async getChartDetail({ chartId }) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/chart/${chartId}`,
      method: HttpMethod.GET
    };
    try {
      const res = await processRequest(ep);
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  async listCharts({ appId, query={} }) {
    const queryParams = stringify(query);

    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/chart/list/${appId}${queryParams ? `?${queryParams}` : ''}`,
      method: HttpMethod.GET
    };

    try {
      const res = await processRequest(ep);
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },
  async updateChart({ chartId, name, definition }) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: '/chart/update',
      method: HttpMethod.PUT
    };
    try {
      const res = await processRequest(ep, { data: { chartId, name, definition } });
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  async deleteChart({ chartId }) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/chart/${chartId}`,
      method: HttpMethod.DELETE
    };
    try {
      const res = await processRequest(ep);
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },
}
