import { Fragment, useState } from 'react'
import {useDispatch, useSelector} from "react-redux";
import { Dialog, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { EMAIL_REGEX } from 'consts';
import {roleOptions} from "consts/chartConfig";

import TextInput from 'components/TextInput';
import { showInfo, showError } from "components/FlashMessage/flashMessageSlice";
import TailwindCombobox from "components/TailwindCombobox";
import LoadingSpinner from 'components/LoadingSpinner';

import UserService from 'services/user';

import './index.scss';


export default function InviteUser({ reset = () => { } }) {
  const { t } = useTranslation();

  const methods = useForm();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const currentUserEmail = useSelector((state) => state.user.profile.email);

  const onSubmit = async (data) => {

    try {
      if (currentUserEmail === data.email) {
        dispatch(showError({ message: "Invite own email is not allow" }));
      } else {
        setIsLoading(true);
        await UserService.inviteUser(data);
        dispatch(showInfo({ message: t("INVITE_USER_SUCCESSFULLY") }));

        reset();

        methods.reset();

        setIsOpen(false);
      }
    } catch (error) {
      const { message } = error;
      dispatch(showError({ message }));
    }

    setIsLoading(false);
  };

  return (
    <div id="invite-user" className="ds-input-text">
      <button
        onClick={() => setIsOpen(true)}
        type="button"
        className="ds-button"
      >
        + {t("INVITE_USERS")}
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    className="ds-text-form-title"
                  >
                    Invite User
                  </Dialog.Title>
                  <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                      <div className="flex flex-wrap mt-3">
                        <div className="w-full pr-2 ds-input-text">
                          <label>{t('Email')}</label>
                          <TextInput
                            name="email"
                            className="w-full ds-input mt-1"
                            placeholder={t('ENTER_VALUE', { Value: 'an email' })}
                            rules={
                              {
                                required: t('VALUE_IS_REQUIRED', { Value: t('EMAIL') }),
                                pattern: {
                                  value: EMAIL_REGEX,
                                  message: t('INVALID_EMAIL_ADDRESS')
                                },
                              }
                            }
                          />
                          <br />

                          <label>{t('Role')}</label>

                          <TailwindCombobox
                            name="role"
                            items={roleOptions}
                          />
                        </div>
                      </div>

                      <div className="mt-10 sm:mt-4 sm:flex sm:flex-row-reverse mt-3">
                        <button
                          type="button"
                          className="ds-button-cancel ml-2"
                          onClick={() => setIsOpen(false)}
                        >
                          {t('Discard')}
                        </button>

                        <button
                          type="submit"
                          disabled={isLoading}
                          className="inline-flex items-center w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto"
                        >
                          {isLoading ?
                            <>

                              <LoadingSpinner className="me-3" textColor="text-blue-500" />
                              {t('LOADING')}
                            </>
                            :
                            t('INVITE')
                          }
                        </button>
                      </div>
                    </form>
                  </FormProvider>

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>


    </div>
  );
}
