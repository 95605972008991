export const tabLink = {
  POSTBACK_WINDOW_ONE: '#postback-window-one',
  POSTBACK_WINDOW_TWO: '#postback-window-two',
  POSTBACK_WINDOW_THREE: '#postback-window-three',
}

export const tabs = [
  { name: 'Postback Window One', href: tabLink.POSTBACK_WINDOW_ONE },
  { name: 'Postback Window Two', href: tabLink.POSTBACK_WINDOW_TWO },
  { name: 'Postback Window Three', href: tabLink.POSTBACK_WINDOW_THREE },
];

export default tabs;
