import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { buildAppList } from "utils/app";
import { isUndefinedOrEmpty } from 'utils';
import {CHART_CONFIG, PATHS, PLATFORM, REPORT_TYPE} from "consts";
import $ from 'jquery';

import FieldFilterToogleBox from 'components/FieldFilterToogleBox';
import DateFilterToggleBox from 'components/DateFilterToggleBox';
import { showError } from "components/FlashMessage/flashMessageSlice";
import DsListBox from "components/DsListBox";
import ChartSerivce from 'services/chart';
import VisualisationStackedChart from "views/visualisation/visualisation_stackedchart";
import { buildGlobalFilterRequest } from "views/visualisation/chartProcessor";
import VisualisationKpi from "views/visualisation/visualisation_kpi";
import VisualisationLineChart from "views/visualisation/visualisation_linechart";


export default function SkadnetworkOverview() {
  const dispatch = useDispatch();
  const { appId } = useParams();
  const { t } = useTranslation();

  const appList = useSelector((state) => state.app.appList.filter(a => a.platform === PLATFORM.IOS));

  const appOptions = useMemo(() => {
    if (!isUndefinedOrEmpty(appList)) {
      if (!appList.find(a => a.appId === appId)) {
        window.location = `${PATHS.APP}/${appList[0].appId}${PATHS.SKADNETWORK_OVERVIEW}`;
      }
    } else {
      window.location = `${PATHS.APP}/empty${PATHS.SKADNETWORK}`
    }
    return buildAppList(appList, PATHS.SKADNETWORK_STUDIO);
  }, [appList]);

  const methods = useForm();

  const [fieldList, setFieldList] = useState([]);
  const [globalFiterRequest, setGlobalFiterRequest] = useState([]);

  const isShowDateFilter = useMemo(() => {
    return fieldList.some(item => item.fieldKey === CHART_CONFIG.CHART_DATE_FIELD)
  }, [fieldList]);

  const globalFiter = useMemo(() => {
    return fieldList.filter(element => element.attribute === 'dimension' && element.filterable === true && element.fieldKey !== CHART_CONFIG.CHART_DATE_FIELD);
  }, [fieldList]);

  const getChartFieldList = async () => {
    try {
      if (!isUndefinedOrEmpty(appId)) {
        const result = await ChartSerivce.getFieldList({ appId });
        setFieldList(result);
      }
    } catch (error) {
      const { message } = error;
      dispatch(showError({ message }));
    }
  };

  const onSubmit = async (data) => {
    try {
      const globalFiterRequest = buildGlobalFilterRequest(data);
      setGlobalFiterRequest(globalFiterRequest);
    } catch (error) {
      const { message } = error;
      dispatch(showError({ message }));
    }
  }

  useEffect(() => {
    getChartFieldList()
  }, [appId]);

  useEffect(() => {
    if (isUndefinedOrEmpty(appId) && appOptions.length > 0) {
      navigate(appOptions[0].href);
    }
  }, [appId, appOptions]);

  useEffect(() => {
    methods.handleSubmit(onSubmit)();
  }, [fieldList])

  useEffect(() => {
    methods.handleSubmit(onSubmit)();
  }, [methods.watch("global_filters.utm_campaign"), methods.watch("global_filters.date_key"), methods.watch("global_filters.utm_source")]);


  return (
    <div id="skadnetwork">
      <div className='flex justify-between'>
        <div className="ds-text-page-title">{t('SKAN')}</div>
        <div className="flex">
        </div>
      </div>

      <DsListBox listData={appOptions} />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="flex flex-row w-full mt-5 ds-border-gray ds-input">
            <div className="mr-2">
              {isShowDateFilter &&
                <DateFilterToggleBox name={`${CHART_CONFIG.FIELD_GLOBAL_FILTERS}.${CHART_CONFIG.CHART_DATE_FIELD}`}/>}
            </div>
            {
              globalFiter.map((filter, index) => (
                <div key={index} className="mr-2">
                  <FieldFilterToogleBox
                    appId={appId}
                    fieldKey={filter.fieldKey}
                    displayedName={t(filter.fieldKey)}
                    name={`${CHART_CONFIG.FIELD_GLOBAL_FILTERS}.${filter.fieldKey}`}
                  />
                </div>
              ))
            }
          </div>

          <div className="grid grid-cols-6 gap-2 w-full mt-5">
            <VisualisationKpi
              appId={appId}
              globalFilter={globalFiterRequest}
              chartId={CHART_CONFIG.CHART_NAME_KPI_CHART_ID}
              metricValue={CHART_CONFIG.CHART_KPI_SKAN_METRIC}
            />
          </div>

          <div className="ds-block w-full mt-5">
            <VisualisationStackedChart
              appId={appId}
              globalFilter={globalFiterRequest}
              chartId={"skan_install"}
              dimensionValue={"is_organic"}
              metricValue={"installs"}
            />
          </div>
          <div className="ds-block w-full mt-5">
            <VisualisationLineChart
              appId={appId}
              globalFilter={globalFiterRequest}
              chartId={"chart_impression"}
              dimensionValue={[]}
              metricValue={["ad_impressions", "ad_clicks"]}
            />
          </div>
        </form>
      </FormProvider>
    </div>
);
}
