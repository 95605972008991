/// eslint-disable-next-line
export const PARTNERS = {
  TIKTOK_SHOP: 'tiktok_shop',
  META_ADS: 'meta_ads',
  HARAVAN: 'haravan',
  LAZADA: 'lazada',
  SHOPEE: 'shopee',
  SHOPIFY: 'shopify',
  FACEBOOK: 'facebook',
  GOOGLE: 'google',
  APPLE_SEARCH: 'apple_search',
  TIKTOK: 'tiktok',
};

export const ADS_PARTNERS = [
  PARTNERS.FACEBOOK,
  PARTNERS.GOOGLE,
  PARTNERS.APPLE_SEARCH,
  PARTNERS.TIKTOK,
];

export const PARTNER_INTEGRATION_DOCS_URL = {
  [PARTNERS.FACEBOOK]: 'https://developer.datasenses.io/docs/partner-meta-ads',
  [PARTNERS.GOOGLE]: 'https://developer.datasenses.io/docs/partner-google-ads',
  [PARTNERS.TIKTOK]: 'https://developer.datasenses.io/docs/partner-tiktok-ads',
  [PARTNERS.APPLE_SEARCH]: 'https://developer.datasenses.io/docs/partner-apple-search-ads',
}
