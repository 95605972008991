import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";
import TailwindCombobox from "components/TailwindCombobox";
import PropertyFilter from "components/PropertyFilter";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import MetaDataService from "services/metadata";
import {showError} from "components/FlashMessage/flashMessageSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquarePlus, faXmark} from "@fortawesome/free-solid-svg-icons";
import {CHART_CONFIG} from "consts";
import DateFilterToggleBox from "components/DateFilterToggleBox";
import {DATE_KEY_TYPE_FIELD, DATE_KEY_TYPE_OPTIONS} from "consts/dateFilter";


export default function EventFilter({
                                      name,
                                      eventNameOptions = [],
                                      appId,
                                      index,
                                      dateDiff,
                                    }) {
  const {t} = useTranslation();
  const {watch, getValues, setValue} = useFormContext();
  const filters = watch(`${name}.filters`) || [];
  const eventName = watch(`${name}.name`);
  const [propertyOptions, setPropertyOptions] = useState([]);
  const dispatch = useDispatch();

  const getProperties = async () => {
    try {
      if (!eventName) {
        return;
      }
      const result = await MetaDataService.getFieldValues({appId, fieldKey: eventName});
      setPropertyOptions(result.map((value) => ({fieldKey: value, name: value})));
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
  }

  useEffect(() => {
    getProperties();
  }, [eventName]);

  return (
    <div key={index} className={`space-y-4`}>
      <div className="items-center space-y-4">

        <span className="ds-text-form-title">Event {index}</span>

        <div className={`flex items-center gap-4`}>
          <TailwindCombobox
            name={`${name}.name`}
            items={eventNameOptions}
            className={`!w-fit`}
          />
          <DateFilterToggleBox
            name={`${name}.${CHART_CONFIG.CHART_DATE_FIELD}`}
            type={getValues(`${name}.${DATE_KEY_TYPE_FIELD}`)}
            dateDiff={dateDiff}
            className={`!bg-white`}
          />
          <TailwindCombobox
            name={`${name}.${DATE_KEY_TYPE_FIELD}`}
            items={DATE_KEY_TYPE_OPTIONS}
          />
        </div>

        <div>
          <span className={`ds-text-form-title`}>With property filter</span>
        </div>

        {filters.map((filter, index) => (
          <div key={index} className={`flex items-center space-x-2`}>
            <FontAwesomeIcon
              onClick={() => {
                setValue(`${name}.filters`, filters.filter((_, i) => i !== index));
              }}
              className='right-2 top-6 cursor-pointer text-slate-500 hover:text-red-500'
              icon={faXmark}
              style={{cursor: 'pointer'}}
            />
            <PropertyFilter
              index={index}
              name={`${name}.filters[${index}]`}
              propertyOptions={propertyOptions}
              operatorOptions={CHART_CONFIG.OPERATORS_LIST}
            />
          </div>
        ))}


      </div>


      <button
        type={"button"}
        onClick={() => {
          setValue(`${name}.filters`, [...filters, ""]);
        }}
        className="ds-button-2"
      >
        <FontAwesomeIcon icon={faSquarePlus} className="me-2"/>
        Filter
      </button>

    </div>
  )
}
