import {Dialog, Transition} from "@headlessui/react";
import React, {Fragment} from "react";
import ConversionValueFormCreation from "views/skadnetwork/skan_studio/conversion_value/conversion_value_form";
import ConversionValueFormWithoutFineValueCreation
  from "views/skadnetwork/skan_studio/conversion_value/conversion_value_form_without_fine_value";

export default function CreateConversionValue({
                                                isOpen,
                                                closeModal,
                                                eventList = [],
                                                onChange = () => {
                                                },
                                                postbackWindow
                                              }) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25"/>
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className="relative max-w-4xl w-full transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  className="ds-text-form-title"
                >
                  Create Conversion Value
                </Dialog.Title>

                {postbackWindow === 1 ? <ConversionValueFormCreation
                    onChange={onChange}
                    closeModal={closeModal}
                    eventList={eventList}
                  />
                  :
                  <ConversionValueFormWithoutFineValueCreation
                    onChange={onChange}
                    closeModal={closeModal}
                    eventList={eventList}
                  />
                }

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

