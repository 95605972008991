import axios from "axios";
import {PATHS, PLATFORM} from "consts";
import {isUndefinedOrEmpty} from "utils";

export const buildAppList = (apps, path) => {
  return apps.map((app) => ({
    ...app,
    href: `${PATHS.APP}/${app.appId}${path}`,
  }))
}

export const buildIntegrationAppList = ({apps}) => {
  return apps.map((app) => ({
    ...app,
  }))
}

export const isAppActive = (appInfo) => {
  if (appInfo.status === 0) {
    return false
  }
  return true
}

export const isAppEligible = (data) => {
  if (isUndefinedOrEmpty(data.name)) {
    return false
  }
  if (data.name.length > 50) {
    return false
  }
  if (isUndefinedOrEmpty(data.platform) || isUndefinedOrEmpty(data.link)) {
    return false
  }
  if (![PLATFORM.ANDROID, PLATFORM.IOS, PLATFORM.WEB].includes(data.platform)) {
    return false
  }
  // if (data.platform === PLATFORM.ANDROID) {
  //   if (data.link.indexOf("https://play.google.com/store/apps/details?id=") === -1) {
  //     return false
  //   }
  // } else if (data.platform === PLATFORM.IOS) {
  //   if (data.link.indexOf("https://apps.apple.com") === -1) {
  //     return false
  //   }
  // } else if (data.platform === PLATFORM.WEB) {
  //   return true
  // }

  if (isUndefinedOrEmpty(data.timezone)) {
    return false
  }

  return true
}

export const getLogo = async (platform, link) => {
  if (isAppLinkEligible(platform, link)) {
    const response = await axios.get(link);
    let html = response.data;
    if (platform === PLATFORM.IOS) {
      const imgElement = html.querySelector('.we-artwork__image');
      return imgElement.getAttribute('src');
    } else if (platform === PLATFORM.ANDROID) {
      const imgElement = html.querySelector('.we-artwork__image');
      return imgElement.getAttribute('src');
    }
  }

  return ""
}

