import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, useController } from 'react-hook-form';
import moment from 'moment';
import { DATE_FORMAT, DISPLAY_DATE_CALENDAR_FORMAT } from 'consts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import DateFilter from 'components/DateFilter/WithoutForm';
import ToggleBox from 'components/ToggleBox';

import './index.scss';

export default function DateFilterToggleBox({
  name,
  defaultValue=[
    moment().subtract(3, 'days').format(DATE_FORMAT),
    moment().subtract(1, 'days').format(DATE_FORMAT)
  ],
}) {
  const { control } = useFormContext();
  const { field } = useController({name, control, defaultValue});

  const { t } = useTranslation();

  const wrapper = useRef(null);
  const dateWrapper = useRef(null);

  const onDateChange = (value) => {
    field.onChange(value);
  };

  const closeDateFilter = () => {
    if (wrapper.current) {
      wrapper.current.toggle();
    }
  };

  const onClose = () => {
    if (dateWrapper.current) {
      dateWrapper.current.reset();
    }
  };

  return (
    <ToggleBox ref={wrapper} onClose={onClose} className={""}>
      <ToggleBox.Button>
        <div className="ds-input ds-input-text ds-violet-border items-center space-x-2">
          <FontAwesomeIcon className="" icon={faCalendarDays} />
          <span>{ moment(field.value[0]).format(DISPLAY_DATE_CALENDAR_FORMAT) } </span>
          <span> { t('-') } </span>
          <span>{ moment(field.value[1]).format(DISPLAY_DATE_CALENDAR_FORMAT) }</span>
          <FontAwesomeIcon  icon={faChevronDown} />
        </div>
      </ToggleBox.Button>

      <ToggleBox.Content>
        <DateFilter ref={dateWrapper} onChange={onDateChange} onClose={closeDateFilter} value={field.value}/>
      </ToggleBox.Content>
    </ToggleBox>
  );
};
