import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePlus } from '@fortawesome/free-solid-svg-icons';
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';

import TextInput from 'components/TextInput';
import TailwindCombobox from "components/TailwindCombobox";
import { showInfo, showError } from "components/FlashMessage/flashMessageSlice";
import ToggleCheckbox from "components/ToggleCheckbox";

import { isUndefinedOrEmpty } from "utils";
import Dashboard from "services/dashboard";


export default function AddToDashboardModal({ cardType, cardSubType, payload, isOpen = false, closeModal = () => { }, reset = () => { } }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const methods = useForm();

  const [dashboardList, setDashboardList] = useState([]);
  const isNewDashboard = methods.watch("isNewDashboard");

  const listDashboards = async () => {
    try {
      const results = await Dashboard.listDashboards();
      results.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      const dashboards = [
        {
          fieldKey: "empty",
          name: "",
          isNoneOption: true
        },
        ...results.map((t) => ({
          fieldKey: t.dashboardId,
          name: t.name,
        })),
      ]

      setDashboardList(dashboards)

    } catch (e) {
    }
  }

  useEffect(() => {
    listDashboards();
  }, []);

  const onSubmit = async (data) => {
    try {
      const result = await Dashboard.addToDashboard({
        name: data.name,
        type: cardType,
        subType: cardSubType,
        payload: payload,
        existingDashboardIds: isUndefinedOrEmpty(data.existingDashboard) ? [] : [data.existingDashboard],
        newDashboardName: data.newDashboardName,
      });

      dispatch(showInfo({ message: "Add to dashboard successfully." }));
    } catch (error) {
      const { message } = error;
      dispatch(showError({ message }));
    }
  };


  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="ds-text-page-title"
                >
                  Add to dashboard
                </Dialog.Title>

                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="gap-4 bg-white mt-5 ds-border-gray shadown-md">
                      <div className="p-5">
                        <div className="flex flex-wrap">
                          <div className="w-full">
                            <label className="ds-input-text required">Card name</label>
                            <TextInput
                              name="name"
                              className="w-full"
                              placeholder={t('ENTER_VALUE', { Value: 'card name' })}
                              rules={
                                {
                                  required: t('VALUE_IS_REQUIRED', { Value: t('Card name') }),
                                }
                              }
                            />
                          </div>
                        </div>

                        <div className="mt-5">
                          <label className="ds-input-text">Add to existing dashboard</label>
                          <TailwindCombobox
                            name="existingDashboard"
                            items={dashboardList}
                          />
                        </div>

                        <div className="mt-6">
                          <div className={"flex items-center"}>
                            <div className="ds-input-text">Add to new dashboard</div>
                            <ToggleCheckbox name="isNewDashboard" className="ms-2"/>
                          </div>
                        </div>
                        <div>
                          {isNewDashboard &&
                            <>
                              <div className="mt-5">
                                <label className="ds-input-text required">New dashboard name</label>
                                <TextInput
                                  name="newDashboardName"
                                  className="w-full"
                                  placeholder={t('ENTER_VALUE', { Value: 'dashboard name' })}
                                  rules={
                                    {
                                      required: t('VALUE_IS_REQUIRED', { Value: t('Dashboard name') }),
                                    }
                                  }
                                />
                              </div>
                            </>
                          }
                        </div>

                        <div className="mt-10 mb-1 sm:flex sm:flex-row-reverse">
                          <button
                            type="submit"
                            className={`ds-button focus:outline-none`}
                          >
                            <FontAwesomeIcon icon={faSquarePlus} className="me-2"/>
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </FormProvider>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
