export default function DsIconCohort() {
  return (
    // <svg fill="#000000" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg">
    //   <path
    //     d="M1740 0c99.24 0 180 80.76 180 180v1560c0 99.24-80.76 180-180 180H180c-99.24 0-180-80.76-180-180V180C0 80.76 80.76 0 180 0h1560Zm60 600V180c0-33-27-60-60-60h-420v480h480Zm0 1065V720H720v1080h945c74.25 0 135-60.75 135-135ZM720 600h480V120H720v480Zm-120 0V120H180c-33 0-60 27-60 60v420h480Zm-480 600h480V720H120v480Zm480 600v-480H120v420c0 33 27 60 60 60h420Z"
    //     fillRule="evenodd"/>
    // </svg>
    <svg viewBox="0 0 24 24" fill="none" stroke="currentColor"
         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-table">
      <path
        d="M9 3H5a2 2 0 0 0-2 2v4m6-6h10a2 2 0 0 1 2 2v4M9 3v18m0 0h10a2 2 0 0 0 2-2V9M9 21H5a2 2 0 0 1-2-2V9m0 0h18"></path>
    </svg>
  )
}
