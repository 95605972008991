import { faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PATHS } from "consts";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

export default function PaymentReturn() {
  const { t } = useTranslation();
  const location = useLocation();
  const responseCode = new URLSearchParams(location.search).get('vnp_ResponseCode');
  const txnRef = new URLSearchParams(location.search).get('vnp_TxnRef');
  const status = new URLSearchParams(location.search).get('Status');

  const isLogin = useSelector((state) => !!state.user.refreshToken);

  return (
    <div className="bg-white h-screen">
      <div className="flex flex-col items-center justify-center h-screen p-6 md:mx-auto">
        <FontAwesomeIcon className={`text-${responseCode == '00' ? 'green' : 'red'}-600 w-16 h-16 mx-auto mt-6 mb-3`} icon={responseCode == '00' ? faCircleCheck : faCircleXmark} />
        <div className="text-center">
          <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">{ responseCode == '00' ? t('YOUR_PAYMENT_IS_SUCCESSFUL') : t('YOUR_PAYMENT_HAS_AN_ISSUE')}</h3>
          <p className="mt-1"><span className="font-semibold">Transaction ID:</span> {txnRef}</p>
          <p className="mt-1"><span className="font-semibold">Status:</span> {status}</p>
          <p className="mt-5"> {t('HAVE_A_GREAT_DAY')}!  </p>
          <div className="mt-5 text-center">
            <Link to={isLogin ? PATHS.APP : PATHS.HOMEPAGE} className="px-12 uppercase bg-indigo-600 hover:bg-indigo-500 text-white font-semibold py-3">
              {t('GO_HOMEPAGE')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
