import TextInput from "components/TextInput";
import {PATHS, PLATFORM} from "consts";
import TailwindCombobox from "components/TailwindCombobox";
import {FormProvider, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import deeplink from "services/deeplink";
import AppSvc from "services/app";
import {showError, showInfo} from "components/FlashMessage/flashMessageSlice";
import {useParams} from "react-router-dom";
import { faChevronRight, faSquarePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {androidRedirectOptions, iOSRedirectOptions, webRedirectOptions} from "../types";


export default function CreateDeeplinkTemplate() {
  const { t } = useTranslation();
  const methods = useForm();
  const dispatch = useDispatch();

  const appList = useSelector((state) => state.app.appList);
  const [openAndroidMWeb, setOpenAndroidMWeb] = useState(false)
  const [openIOSMWeb, setOpenIOSMWeb] = useState(false)
  const [androidApp, setAndroidApp] = useState(null)
  const [iosApp, setIosApp] = useState(null)
  const [webApp, setWebApp] = useState(null)

  const isEditDisabled = true

  const emptyApp = {
    "fieldKey": "empty",
    "name": "",
    "isNoneOption": true
  }

  const iOSApps = [
    emptyApp,
    ...appList.filter(a => a.platform === PLATFORM.IOS).map((a) => ({
      "fieldKey": a.appId,
      "name": a.name,
    })),
  ]

  const androidApps = [
    emptyApp,
    ...appList.filter(a => a.platform === PLATFORM.ANDROID).map((a) => ({
      "fieldKey": a.appId,
      "name": a.name,
    })),
  ]

  const webApps = [
    emptyApp,
    ...appList.filter(a => a.platform === PLATFORM.WEB).map((a) => ({
      "fieldKey": a.appId,
      "name": a.name,
    })),
  ]

  const androidRedirect = methods.watch("androidRedirect")
  const iosRedirect = methods.watch("iosRedirect")
  const androidDeeplink = methods.watch("androidDeeplink")
  const iosDeeplink = methods.watch("iosDeeplink")
  const webDeepLink = methods.watch("webDeepLink")
  const androidMwebUrl = methods.watch("androidMwebUrl")
  const iosMwebUrl = methods.watch("iosMwebUrl")
  const androidAppId = methods.watch("androidApp")
  const iosAppId = methods.watch("iosApp")
  const templateName = methods.watch("templateName")
  const webAppId = methods.watch("webApp")

  // console.log(webAppId)

  useEffect(() => {
    if (androidRedirect === PLATFORM.MWEB) {
      setOpenAndroidMWeb(true)
    } else {
      setOpenAndroidMWeb(false)
    }
  }, [androidRedirect]);

  useEffect(() => {
    if (iosRedirect === PLATFORM.MWEB) {
      setOpenIOSMWeb(true)
    } else {
      setOpenIOSMWeb(false)
    }
  }, [iosRedirect]);

  const getApp = async () => {
    try {
      if (androidAppId !== "empty" && androidAppId != null) {
        const result = await AppSvc.getApp({ appId: androidAppId});
        setAndroidApp(result);
      } else {
        setAndroidApp(null)
      }

      if (iosAppId !== "empty" && iosAppId != null) {
        const result = await AppSvc.getApp({ appId: iosAppId});
        setIosApp(result);
      } else {
        setIosApp(null)
      }

      if (webAppId !== "empty" && webAppId != null) {
        const result = await AppSvc.getApp({ appId: webAppId});
        setWebApp(result);
      } else {
        setWebApp(null)
      }
    } catch (err) {
    }
  };

  useEffect(() => {
    getApp()
  }, [androidAppId, iosAppId, webAppId]);

  const onSubmit = async () => {
    try {
      const requestPayload = {
        android_deep_link: androidDeeplink,
        android_is_open_store: androidRedirect === PLATFORM.GGPLAY,
        android_link_app_not_install: androidRedirect === PLATFORM.GGPLAY ? "" : webApp.link,
        app_android_id: androidAppId === "empty" ? "" : androidAppId,
        app_ios_id: iosAppId === "empty" ? "" : iosAppId,
        ios_deep_link: iosDeeplink,
        ios_is_open_store: iosRedirect === PLATFORM.APPSTORE,
        ios_link_app_not_install: iosRedirect === PLATFORM.APPSTORE ? "": webApp.link,
        name: templateName,
        app_web_id: webAppId === "empty" ? "" : webAppId,
      }
      const result = await deeplink.createDeeplinkTemplate(requestPayload);

      window.location = `${PATHS.APP}${PATHS.DEEPLINK}`
      dispatch(showInfo({ message: "Create template successfully" }));
    } catch (e) {
      dispatch(showError({ message: "Data invalid" }));
    }
  }


  return (
    <div id="create-template">
      <div className="ds-input-text mt-3">
        <a
          className="ds-text-color hover:underline"
          target="_blank" href={`${PATHS.APP}${PATHS.DEEPLINK}`}>
          Deeplink
        </a>
        <FontAwesomeIcon className="text-gray-500 ml-2.5 mr-2.5" icon={faChevronRight} size="xs"/>
        Create Template
      </div>

      <div className="ds-text-page-title mt-3">Create template</div>

      <FormProvider {...methods}>
        <div className="gap-4 bg-white mt-5 rounded-lg shadown-md sm:p-3 border border-gray-100">
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="ml-4 mt-3">
              <div className="gap-4">
                <div className="grid grid-cols-5 gap-4">
                  <div className="col-span-2">
                    <TextInput
                      label="Template name"
                      name="templateName"
                      className="ds-input ds-input-text w-full"
                      rules={
                        {
                          required: t('Template name is required'),
                        }
                      }
                    />
                  </div>
                </div>
              </div>

              <hr className="h-px mt-8 bg-gray-200 border-0 mr-4"/>

              <div className="mt-7">
                <h3 className="ds-text-form-title-2">
                  {t('Platform')}
                </h3>
                <p className="text-sm text-gray-500 mt-1.5">
                  {t('Select the apps for this template (iOS, Android, Web)')}
                </p>
              </div>

              <div className="grid grid-cols-5 gap-4 mt-5">
                <div className="col-span-2">
                  <span className="ds-input-text">Android</span>
                  <TailwindCombobox
                    className="w-1/2"
                    name="androidApp"
                    items={androidApps}
                  />
                </div>
              </div>
              <div className="grid grid-cols-5 gap-4 mt-6">
                <div className="col-span-2">
                  <span className="ds-input-text">iOS</span>
                  <TailwindCombobox
                    name="iosApp"
                    items={iOSApps}
                  />
                </div>
              </div>
              <div className="grid grid-cols-5 gap-4 mt-6">
                <div className="col-span-2">
                  <span className="ds-input-text">Web</span>
                  <TailwindCombobox
                    name="webApp"
                    items={webApps}
                  />
                </div>
              </div>

              <hr className="h-px mt-8 bg-gray-200 border-0 mr-4"/>

              {/*-------------------------------------Redirection app isn't installed-----------------------------*/}
              <div>
                <h3 className="ds-text-form-title-2 mt-7">
                  {t('Redirection')}
                </h3>
              </div>

              {
                androidApp || iosApp ?
                  <div>
                    <legend className="block text-sm font-semibold leading-6 text-gray-900 mt-6">
                      When app isn’t installed
                    </legend>
                    <table className="bg-white w-2/3 border-collapse border border-gray-200 rounded-lg">
                      <tbody className="text-gray-600 ds-input-text divide-y divide-gray-200">
                      <tr>
                        <td className="px-6 py-4 w-1/6 whitespace-nowrap text-center border border-gray-200 font-medium">Android</td>
                        <td className="px-7 py-6">
                          <div>
                            <span className="ds-input-text">Redirect to</span>
                            {
                              androidRedirect === PLATFORM.MWEB ?
                                <div>
                                  <div className="grid grid-cols-2 mb-2 gap-3">
                                    <div>
                                      <TailwindCombobox
                                        name="androidRedirect"
                                        items={androidRedirectOptions}
                                      />
                                    </div>
                                    <div>
                                      <TextInput
                                        name="androidMwebUrl"
                                        className="ds-disabled-input w-full hover:border-gray-300 focus:border-gray-300"
                                        disabled={isEditDisabled}
                                        placeholder={webApp ? webApp.link : ""}
                                      />
                                    </div>
                                  </div>
                                  {
                                    webApp ? <div></div>
                                      :
                                      <div className="flex items-center mt-5">
                                        <div className="ds-text-input font-bold mr-1">
                                          Note:
                                        </div>
                                        <div className="ds-text-input">
                                          Please select a Web App in Platform section.
                                        </div>
                                      </div>
                                  }
                                </div>
                                :
                                <div className="grid grid-cols-2 mb-2 gap-1">
                                  <TailwindCombobox
                                    name="androidRedirect"
                                    items={androidRedirectOptions}
                                  />
                                </div>
                            }
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 w-1/6 whitespace-nowrap text-center border border-gray-200 font-medium">iOS</td>
                        <td className="px-7 py-6">
                          <div>
                            <span className="ds-input-text">Redirect to</span>
                            {
                              iosRedirect === PLATFORM.MWEB ?
                                <div>
                                  <div className="grid grid-cols-2 mb-2 gap-3">
                                    <div>
                                      <TailwindCombobox
                                        name="iosRedirect"
                                        items={iOSRedirectOptions}
                                      />
                                    </div>
                                    <div>
                                      <TextInput
                                        name="iosdMwebUrl"
                                        className="ds-disabled-input w-full hover:border-gray-300 focus:border-gray-300"
                                        disabled={isEditDisabled}
                                        placeholder={webApp ? webApp.link : ""}
                                      />
                                    </div>
                                  </div>
                                  {
                                    webApp ? <div></div>
                                      :
                                      <div className="flex items-center mt-5">
                                        <div className="ds-text-input font-bold mr-1">
                                          Note:
                                        </div>
                                        <div className="ds-text-input">
                                          Please select a Web App in Platform section.
                                        </div>
                                      </div>
                                  }
                                </div>
                                :
                                <div className="grid grid-cols-2 mb-2 gap-1">
                                  <TailwindCombobox
                                    name="iosRedirect"
                                    items={iOSRedirectOptions}
                                  />
                                </div>
                            }
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>

                    {/*-------------------------------------Redirection app is installed-----------------------------*/}

                    <legend className="block text-sm font-semibold leading-6 mt-6">
                      When app is installed
                    </legend>
                    <table className="bg-white w-2/3 border-collapse border border-gray-200 rounded-lg">
                      <tbody className="text-gray-600 ds-input-text divide-y divide-gray-200">
                      <tr>
                        <td className="px-6 py-4 w-1/6 whitespace-nowrap text-center border border-gray-200 font-medium">Android</td>
                        <td className="px-7 py-5">
                          <div>
                            <div className="mb-2 gap-1 mt-2">
                              {
                                androidApp ?
                                  <TextInput
                                    name="androidDeeplink"
                                    className="ds-input w-full"
                                    placeholder={"Android deeplink"}
                                    rules={
                                      {
                                        required: t('Android deeplink is required.'),
                                      }
                                    }
                                  /> :
                                  <TextInput
                                    name="androidDeeplink"
                                    className="ds-input w-full"
                                    placeholder={"Android deeplink"}
                                  />
                              }

                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 w-1/6 whitespace-nowrap text-center border border-gray-200 font-medium">iOS</td>
                        <td className="px-7 py-5">
                          <div>
                            <div className="mb-2 gap-1 mt-2">
                              {
                                iosApp ?
                                  <TextInput
                                    name="iosDeeplink"
                                    className="ds-input w-full"
                                    placeholder={"iOS deeplink"}
                                    rules={
                                      {
                                        required: t('iOS deeplink is required.'),
                                      }
                                    }
                                  /> :
                                  <TextInput
                                    name="iosDeeplink"
                                    className="ds-input w-full"
                                    placeholder={"iOS deeplink"}
                                  />
                              }
                            </div>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  : <div></div>
              }

              {/*-------------------------------------Redirection Web-----------------------------*/}

              <legend className="block text-sm font-semibold leading-6 text-gray-900 mt-6">
                When link is clicked on desktop web page
              </legend>
              <table className="bg-white w-2/3 border-collapse border border-gray-200 rounded-lg">
                <tbody className="text-gray-600 ds-input-text divide-y divide-gray-200">
                <tr>
                  <td className="px-6 py-4 w-1/6 whitespace-nowrap text-center border border-gray-200 font-medium">Desktop</td>
                  <td className="px-7 py-6">
                    <div>
                      <span className="ds-input-text">Redirect to</span>
                      <div>
                        <div className="grid grid-cols-2 mb-2 gap-3">
                          <div>
                            <TailwindCombobox
                              name="webRedirect"
                              items={webRedirectOptions}
                            />
                          </div>
                          <div>
                            <TextInput
                              name="webDeepLink"
                              className="ds-disabled-input w-full hover:border-gray-300 focus:border-gray-300"
                              disabled={isEditDisabled}
                              placeholder={webApp ? webApp.link : ""}
                            />
                          </div>
                        </div>
                        {
                          webApp ? <div></div>
                            :
                            <div className="flex items-center mt-5">
                              <div className="ds-text-input font-bold mr-1">
                                Note:
                              </div>
                              <div className="ds-text-input">
                                Please select a Web App in Platform section.
                              </div>
                            </div>
                        }
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>

              <hr className="h-px mt-8 bg-gray-200 border-0 mr-4"/>

              <div className="flex items-center justify-end mr-4 mt-7 mb-4">
                <button
                  type="submit"
                  className="ds-button"
                >
                  <FontAwesomeIcon className="me-2" icon={faSquarePlus}/>
                  Create
                </button>
              </div>
            </div>
          </form>
        </div>
      </FormProvider>
    </div>
  )
}


