import {useEffect, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useController, useFormContext} from 'react-hook-form';
import moment from 'moment';
import {DATE_FORMAT, DISPLAY_DATE_CALENDAR_FORMAT} from 'consts';

import DateFilterWithoutForm from 'components/DateFilter/WithoutForm';
import ToggleBox from 'components/ToggleBox';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarDays, faChevronDown} from "@fortawesome/free-solid-svg-icons";
import {DATE_KEY_TYPE} from "consts/dateFilter";
import {convertDateKeyToDaysAgo} from "utils/util";

/**
 * DateFilterToggleBox is a component used to manage date filters with a toggle box UI.
 * @param {Object} props - The props object.
 * @param {string} props.name - The name of the date filter.
 * @param {Array} [props.defaultValue] - The default value for the date filter, defaults to an array containing the start and end dates.
 * @returns {JSX.Element} The JSX representation of the DateFilterToggleBox component.
 */
export default function DateFilterToggleBox({
                                              name,
                                              type = DATE_KEY_TYPE.DATE_EXACT,
                                              dateDiff = 0,
                                              defaultValue = [
                                                moment().subtract(3, 'days').format(DATE_FORMAT),
                                                moment().subtract(1, 'days').format(DATE_FORMAT)
                                              ],
                                              className = "",
                                            }) {
  const {control} = useFormContext();
  const {field} = useController({name, control, defaultValue});

  const {t} = useTranslation();

  const wrapper = useRef(null);
  const dateWrapper = useRef(null);

  const onDateChange = (value) => {
    field.onChange(value);
  };

  const closeDateFilter = () => {
    if (wrapper.current) {
      wrapper.current.toggle();
    }
  };

  const onClose = () => {
    if (dateWrapper.current) {
      dateWrapper.current.reset();
    }
  };

  useEffect(() => {
    if (type === DATE_KEY_TYPE.DATE_RELATIVE) {
      field.onChange([
        moment(field.value[0], DATE_FORMAT).add(dateDiff, 'days').format(DATE_FORMAT),
        moment(field.value[1], DATE_FORMAT).add(dateDiff, 'days').format(DATE_FORMAT)
      ]);
    }
  }, [dateDiff]);

  const relativeDate = useMemo(() => {
    return field.value.map((date) => {
      const days = convertDateKeyToDaysAgo(date);
      if (days === 0) {
        return `Today`;
      } else if (days === 1) {
        return `Yesterday`;
      } else {
        return `${days} days ago`;
      }
    });
  }, [field.value]);

  return (
    <ToggleBox ref={wrapper} onClose={onClose}>
      <ToggleBox.Button>
        <button
          type="button"
          className={`ds-input ds-input-text ds-gray-background space-x-2 ${className}`}
        >
          <FontAwesomeIcon className="" icon={faCalendarDays}/>
          <span>
            {(type === DATE_KEY_TYPE.DATE_EXACT) ?
              moment(field.value[0]).format(DISPLAY_DATE_CALENDAR_FORMAT) :
              relativeDate[0]}
          </span>
          <span> {t('-')} </span>
          <span>
            {(type === DATE_KEY_TYPE.DATE_EXACT) ?
              moment(field.value[1]).format(DISPLAY_DATE_CALENDAR_FORMAT) :
              relativeDate[1]}
          </span>
          <FontAwesomeIcon icon={faChevronDown}/>
        </button>
      </ToggleBox.Button>
      <ToggleBox.Content>
        <DateFilterWithoutForm ref={dateWrapper} onChange={onDateChange} onClose={closeDateFilter} value={field.value}/>
      </ToggleBox.Content>
    </ToggleBox>
  );
};
