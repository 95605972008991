import React from 'react';
import {useFormContext} from 'react-hook-form';

/**
 * Checkbox component.
 * @param {Object} props - Component props.
 * @param {string} props.name - The name of the checkbox.
 * @param {string} props.label - The label text for the checkbox.
 * @param {...any} rest - Additional props to be spread on the input element.
 * @returns {JSX.Element} Checkbox component.
 */
export default function ToggleCheckbox({ name, label = "", disabled = false, className, ...rest }) {
  const { register } = useFormContext(); // Access the form context

  return (
    <label className={`  inline-flex items-center ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'} ${className}`}>
      <input
        type="checkbox"
        {...register(name)} // Register the input with React Hook Form
        className="sr-only peer"
        disabled={disabled}
        {...rest}
      />
      <div className={`relative ${disabled ? 'bg-gray-300' : 'bg-gray-400'} w-9 h-5 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#c4b5fd] rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-[#8b5cf6]`}></div>
      <span className="ml-2">{label}</span>
    </label>
  );
};

