import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import './index.scss';

/**
 * RangeInput component represents a range input field in a form.
 * @param {Object} props - The props object.
 * @param {string} props.name - The name of the range input field.
 * @param {string} [props.label=""] - The label text for the range input field.
 * @param {number} [props.min=0] - The minimum value of the range input.
 * @param {number} [props.max=100] - The maximum value of the range input.
 * @param {number} [props.step=5] - The step value for the range input.
 * @param {string} [props.className] - Additional CSS classes for styling purposes.
 * @param {Object} rest - Any other additional props to be passed to the input element.
 * @returns {JSX.Element} The RangeInput component.
 */
export default function RangeInput({ name, label="", min=0, max=100, step=5, defaultValue, className, ...rest }) {
  const { register, setValue, watch } = useFormContext();

  const value = watch(name);

  useEffect(() => {
    if (value > max) {
      setValue(name, max);
    }

    if (value < min) {
      setValue(name, min);
    }
  }, [max, min]);

  useEffect(() => {
    setValue(name, defaultValue);
  }, []);

  return (
    <div className={`range-slider flex justify-start items-center ${className}`}>
      {label && <div className='mb-1'><label className="font-semibold text-sm">{label}</label></div>}
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        className="transparent h-[1px] w-full ds-range-input"
        {...register(name)}
        {...rest}
      />
    </div>
  );
}
