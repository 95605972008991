import DsListBox from "components/DsListBox";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useMemo, useState} from "react";
import {buildAppList} from "utils/app";
import {PATHS} from "consts";
import UserFrom from "views/segments/find_people/UserFrom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faComment, faUserGroup} from "@fortawesome/free-solid-svg-icons";
import {FormProvider, useForm} from "react-hook-form";
import LoadingSpinner from "components/LoadingSpinner";
import {t} from "i18next";
import UserDid from "views/segments/find_people/UserDid";
import MetaDataService from "services/metadata";
import {showError} from "components/FlashMessage/flashMessageSlice";
import {useParams} from "react-router-dom";
import UserNotDid from "views/segments/find_people/UserNotDid";
import {processData} from "views/segments/find_people/processor";
import {BUILDER_FIELD} from "views/segments/find_people/const";
import SegmentService from "services/segment";
import {addCommas, isUndefinedOrEmpty} from "utils";
import {SaveToSegment} from "views/segments/save_to_segment";
import {dateStringToDateDiff} from "utils/util";


export default function FindPeople() {

  const methods = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [eventNameOptions, setEventNameOptions] = useState([]);
  const {appId, segmentId} = useParams();
  const [rawPayload, setRawPayload] = useState({});
  const [result, setResult] = useState([]);
  const [dateDiff, setDateDiff] = useState(0);
  const [requestPayload, setRequestPayload] = useState({});
  const dispatch = useDispatch();

  const appList = useSelector((state) => state.app.appList);

  const appOptions = useMemo(() => {
    return buildAppList(appList, PATHS.FIND_PEOPLE);
  }, [appList]);

  const getEventNameOptions = async () => {
    try {
      const result = await MetaDataService.getEventNameList({appId});
      setEventNameOptions(result.map((value) => ({
        fieldKey: value,
        name: value
      })));
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
  }

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const payload = processData(data)
      if (payload[BUILDER_FIELD.BLOCKS].length === 0) {
        dispatch(showError({message: 'Data is empty'}));
        setIsLoading(false);
        return;
      }
      const response = await SegmentService.getSegmentData({appId, payload});
      setRequestPayload(requestPayload);
      setResult(!isUndefinedOrEmpty(response.result) ? response.result : {});
      setRawPayload(data);
    } catch (error) {
      const {message} = error;
      console.error(error);
      dispatch(showError({message}));
    }
    setIsLoading(false);
  };

  const loadSegmentPayload = async () => {
    if (segmentId) {
      try {
        const response = await SegmentService.getSegment({appId, segmentId});
        await methods.reset(JSON.parse(response.rawPayload));
        setDateDiff(dateStringToDateDiff(response.updatedAt));
      } catch (error) {
        const {message} = error;
        console.error(message);
      }
    }
  }

  useEffect(() => {
    loadSegmentPayload();
  }, [segmentId]);

  useEffect(() => {
    getEventNameOptions();
  }, [appId]);

  return (
    <div className={`space-y-4`}>
      <div className={`mb-4`}>
        <div className="ds-text-page-title">
          <FontAwesomeIcon icon={faUserGroup} className={`icon mr-2`}/>
          Find People
        </div>

        <DsListBox listData={appOptions}/>
      </div>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>

          <div className={`space-y-4`}>
            <UserFrom
              name="userFrom"
              dateDiff={dateDiff}
            />

            <UserDid
              name="userDid"
              eventNameOptions={eventNameOptions}
              appId={appId}
              dateDiff={dateDiff}
            />

            <UserNotDid
              name="userNotDid"
              eventNameOptions={eventNameOptions}
              appId={appId}
              dateDiff={dateDiff}
            />

            <div className="flex items-center">
              <button
                disabled={isLoading}
                type="submit"
                className="ds-button"
              >
                {isLoading ?
                  <>
                    <LoadingSpinner className="mb-1.5 me-3"/>
                    {t('LOADING')}
                  </>
                  :
                  t('VIEW_DETAILS')
                }
              </button>
            </div>
          </div>

        </form>
      </FormProvider>

      {!isUndefinedOrEmpty(result) &&
        <div className="">
          <div className='flex justify-start items-center'>
            <SaveToSegment
              queryScript={result.queryScript}
              payload={requestPayload}
              result={result}
              rawPayload={rawPayload}
            />
          </div>

          <div className='header flex justify-start items-center mt-5'>
            <FontAwesomeIcon className='icon px-2 py-3' icon={faComment}/>
            <span className='ds-text-form-title'>{t('Segment Details')}</span>
          </div>

          <div className='body px-3 py-5'>
            <div className='flex justify-center items-center'>
              <div className='text-center ds-input-text'>
                <div>{t('TOTAL_USERS')}</div>
                <div className='font-semibold text-2xl mt-1'>{addCommas(result.totalUsers)}</div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}
