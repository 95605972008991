import { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';

export default function VisualisationLineChart({ label, data = [], frequency }) {
  const series = useMemo(() => {
    return [
      {
        name: label,
        data,
      },
    ];
  }, [data])

  const options = {
    chart: {
      type: "line",
      stacked: false
    },
    dataLabels: {
      enabled: false
    },
    colors: ["#FF1654", "#247BA0"],
    yaxis: {
      title: {
        text: 'Users (in %)',
      },
      labels: {
        formatter: function (value) {
          return value + '%';
        },
      },
    },
    xaxis: {
      labels: {
        formatter: function (value) {
          return frequency[0].toUpperCase() + (value - 1);
        },
      },
    },
    tooltip: {
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        const seriesName = w.globals.seriesNames[seriesIndex];
        const percentageValue = series[seriesIndex][dataPointIndex];

        return `
          <div className="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
            User who did return event on Day ${dataPointIndex}
          </div>
          <div className="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
            <span className="apexcharts-tooltip-marker" style="background-color: rgb(255, 22, 84); display: block;"></span>
            <div className="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px; display: block;">
              <div className="apexcharts-tooltip-y-group">
                <span className="apexcharts-tooltip-text-y-label">${seriesName}: </span>
                <span className="apexcharts-tooltip-text-y-value">${percentageValue}%</span>
              </div>
              <div className="apexcharts-tooltip-goals-group">
                <span className="apexcharts-tooltip-text-goals-label"></span>
                <span className="apexcharts-tooltip-text-goals-value"></span>
              </div>
              <div className="apexcharts-tooltip-z-group">
                <span className="apexcharts-tooltip-text-z-label"></span>
                <span className="apexcharts-tooltip-text-z-value"></span>
              </div>
            </div>
          </div>
        `;
      },
    }
  };

  return (
    <ReactApexChart options={options} series={series} height={350} />
  );
}
