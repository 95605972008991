import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import userService from 'services/user';
import datasenses from "datasenses-web-sdk";

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    accessToken: '',
    refreshToken: '',
    profile: {
      name: "",
      email: "",
      phone: "",
      address: "",
      companyId: "",
      position: ""
    }
  },
  reducers: {
    setLoginSuccess: (state, action) => {
      const { accessToken, refreshToken, expired, projectId } = action.payload;

      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.projectId = projectId;
    },
    setRefreshTokenSucees: (state, action) => {
      const { accessToken, refreshToken } = action.payload;

      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
    },
    setProjectId: (state, action) => {
      state.projectId = action.payload.projectId;
    },
    setProfile: (state, action) => {
      state.profile.name = action.payload.name;
      state.profile.email = action.payload.email;
      state.profile.phone = action.payload.phone;
      state.profile.address = action.payload.address;
      state.profile.companyId = action.payload.companyId;
      state.profile.position = action.payload.position;

      //Todo: Should add event login under setLoginSuccess path
      datasenses.event.push('Identify', {
        "Customer ID": action.payload.id,
        "Email": action.payload.email,
        "Phone": action.payload.phone
      })

    },
    logout: (state) => {
      state.accessToken = '';
      state.refreshToken = '';
      state.expired = 0;
    },
  },
});

export const { setLoginSuccess, setRefreshTokenSucees, setProfile, logout , setProjectId} = userSlice.actions;

export const refreshToken = createAsyncThunk(
  'user/refreshToken',
  async (_, thunkAPI) => {
    const refreshToken = thunkAPI.getState().user.refreshToken;

    const result = await userService.refreshToken(refreshToken);

    thunkAPI.dispatch(setRefreshTokenSucees(result));
  }
);

export const getProfile = createAsyncThunk(
  'user/getProfile',
  async (_, thunkAPI) => {
    const data = await userService.getProfile();

    thunkAPI.dispatch(setProfile(data));
  },
);

export default userSlice.reducer;
