import {PLATFORM} from "consts/index";
import {DsIconAndroid, DsIconApple, DsIconWebPlatform} from "components/DsIcon";

export const SDKS = [
  {
    name: PLATFORM.IOS,
    title: "iOS sdk",
    logo: DsIconApple,
    href: "https://developer.datasenses.io/docs/sdk/ios",
  },
  {
    name: PLATFORM.ANDROID,
    title: "Android sdk",
    logo: DsIconAndroid,
    href: "https://developer.datasenses.io/docs/sdk/android",
  },
  {
    name: PLATFORM.WEB,
    title: "Web sdk",
    logo: DsIconWebPlatform,
    href: "https://developer.datasenses.io/docs/sdk/web",
  },
];

export const exceptFieldKeys = [
  "event_name",
  "utm_campaign"
]
