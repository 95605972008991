import {PARTNERS} from "consts/partner";
import {
  DsIconApple,
  DsIconColoredMetaAds,
  DsIconHaravan,
  DsIconHome,
  DsIconLazada,
  DsIconShopee,
  DsIconShopify,
  DsIconTiktok
} from "components/DsIcon";
import React from "react";

export default function LogoComponent({
                                        partner
}) {
  return (
    partner === PARTNERS.TIKTOK_SHOP || partner === PARTNERS.TIKTOK ?
      <div className={"h-10 w-10"}><DsIconTiktok/></div> :
      partner === PARTNERS.LAZADA ? <div className={"h-10 w-10"}><DsIconLazada/></div> :
        partner === PARTNERS.HARAVAN ? <div className={"h-10 w-10"}><DsIconHaravan/></div> :
          partner === PARTNERS.SHOPEE ? <div className={"h-10 w-10"}><DsIconShopee/></div> :
            partner === PARTNERS.SHOPIFY ? <div className={"h-10 w-10"}><DsIconShopify/></div> :
              partner === PARTNERS.FACEBOOK ? <div className={"h-10 w-10"}><DsIconColoredMetaAds/></div> :
                partner === PARTNERS.APPLE_SEARCH ? <div className={"h-10 w-10"}><DsIconApple/></div> :
                  <div className={"h-10 w-10"}><DsIconHome/></div>
  );
}
