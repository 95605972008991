export default function DsIconROI() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 68" id="return-on-investment"
         fill={'none'}
          stroke={'currentColor'}
          strokeWidth={'2'}
    >
      <path fill={'none'}
            d="m33.2 5.7 3.9 3.9c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4l-2.1-2.1C49.2 7.3 59.8 17.2 61.6 30c.1.5.6.9 1.1.8.5-.1.9-.6.8-1.1-1.9-13.5-12.8-24-26.1-25.5l2.5-1.3c.5-.3.7-.9.4-1.4-.2-.5-.8-.6-1.3-.4l-5.5 3c-.2.1-.4.3-.5.7-.1.3 0 .6.2.9zm33.6 32.1-3.1-4.4c-.1-.2-.4-.4-.7-.4-.3 0-.6.1-.8.3l-3.9 3.9c-.4.4-.4 1 0 1.4.4.4 1 .4 1.4 0l2.1-2.1c-1.2 12.9-11.1 23.5-24 25.3-.5.1-.9.6-.9 1.1.1.6.6.9 1.1.9 13.8-1.9 24.4-13.2 25.7-26.9l1.5 2c.3.4.9.5 1.4.2.4-.2.5-.8.2-1.3zM34.7 62.4 30.3 58c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l2.5 2.5C18.8 60.8 8.3 51 6.4 38.3c-.1-.5-.6-.9-1.1-.8-.5.1-.9.6-.8 1.1 2 13.3 12.8 23.6 25.8 25.3l-2.5 1.3c-.5.2-.7.8-.4 1.3.2.5.8.7 1.3.4l5.8-2.9c.4-.2.5-.5.5-.9-.1-.2-.1-.5-.3-.7zM5 35c.2 0 .5-.1.7-.3l4.1-4.1c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0l-2.3 2.3c1.1-12.6 10.5-23 23.1-25.1.5-.1.9-.6.8-1.2 0-.5-.5-.9-1-.8C15.5 6.7 5.5 17.6 4.2 31l-1.4-2.1c-.3-.5-.9-.6-1.4-.3-.4.3-.5 1-.2 1.4l3 4.6c.2.3.5.4.8.4zm11.7-1c0 9.6 7.8 17.4 17.3 17.4S51.3 43.6 51.3 34c0-9.6-7.8-17.4-17.3-17.4S16.7 24.4 16.7 34zm32.6 0c0 8.5-6.9 15.4-15.3 15.4S18.7 42.5 18.7 34 25.6 18.6 34 18.6 49.3 25.5 49.3 34z"></path>
      <path fill={'none'}
            d="M33.4 22.9v1.3c-1.7.1-3.1.6-4.2 1.6s-1.6 2.2-1.6 3.8.5 2.7 1.4 3.5c.9.8 2.4 1.4 4.4 1.9v5.2c-1.6-.3-3.1-1-4.6-2.3l-1.9 2.3c1.9 1.7 4.1 2.7 6.5 2.9v2h1.7v-1.9c1.8-.1 3.3-.6 4.4-1.6 1.1-1 1.6-2.2 1.6-3.8s-.5-2.7-1.4-3.6c-1-.8-2.5-1.5-4.5-2h-.1v-5c1.4.2 2.6.7 3.8 1.6l1.7-2.5c-1.7-1.2-3.6-1.9-5.5-2v-1.4h-1.7zm0 8.8c-1-.3-1.7-.7-2-1-.4-.3-.5-.8-.5-1.4s.2-1.1.7-1.5c.5-.4 1.1-.6 1.9-.7v4.6zm3.9 4.8c.4.4.6.9.6 1.5s-.2 1.1-.7 1.5c-.5.4-1.2.7-2 .7v-4.8c1 .4 1.7.7 2.1 1.1z"></path>
    </svg>
  );
}
