import HttpMethod from "consts/httpMethod";
import {processErrorResponse, processRequest, processSuccessResponse} from "utils";
import {useParams} from "react-router-dom";

export default {
  async saveIntegration({
                          appId, partner,
                          status, linkId, facebookAppId, tiktokAppId, installReferrerDecryptionKey,
                          ccpaEnabled, deepLinkEnabled, nonEuUsers,
                          installClickThroughLookbackDay, installClickThroughLookbackHour,
                          installViewThroughAttributionEnabled, installViewThroughLookbackDay, installViewThroughLookbackHour,
                          reinstallAttributionEnabled, reEngagementAttributionEnabled,
                          ignoreActiveUsersRetargetingEnabled, inactivityWindowDay,
                          inAppEventPostbacks
  }) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/app/${appId}/partner/${partner}/save-integration`,
      method: HttpMethod.POST
    };
    try {
      const res = await processRequest(ep, {data: {
          appId, partner,
          status, linkId, facebookAppId, tiktokAppId, installReferrerDecryptionKey,
          ccpaEnabled, deepLinkEnabled, nonEuUsers,
          installClickThroughLookbackDay, installClickThroughLookbackHour,
          installViewThroughAttributionEnabled, installViewThroughLookbackDay, installViewThroughLookbackHour,
          reinstallAttributionEnabled, reEngagementAttributionEnabled,
          ignoreActiveUsersRetargetingEnabled, inactivityWindowDay,
          inAppEventPostbacks
      }});
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  /**
   * Represents integration details for a Google app.
   * @typedef {Object} AppIntegrationGoogle
   * @property {string} id - The unique identifier of the integration.
   * @property {string} appId - The ID of the associated app.
   * @property {string} linkId - The link identifier.
   * @property {boolean} deepLinkEnabled - Indicates whether deep linking is enabled.
   * @property {boolean} nonEuUsers - Indicates whether non-EU users are allowed.
   * @property {number} installClickThroughLookbackDay - The number of days to look back for install click-through.
   * @property {number} installClickThroughLookbackHour - The number of hours to look back for install click-through.
   * @property {boolean} installViewThroughAttributionEnabled - Indicates whether install view-through attribution is enabled.
   * @property {number} installViewThroughLookbackDay - The number of days to look back for install view-through.
   * @property {number} installViewThroughLookbackHour - The number of hours to look back for install view-through.
   * @property {boolean} reinstallAttributionEnabled - Indicates whether reinstall attribution is enabled.
   * @property {boolean} reEngagementAttributionEnabled - Indicates whether re-engagement attribution is enabled.
   * @property {boolean} ignoreActiveUsersRetargetingEnabled - Indicates whether retargeting of active users is ignored.
   * @property {number} inactivityWindowDay - The number of days for the inactivity window.
   * @property {Array<Object>} inAppEventPostbacks - An array of objects representing in-app event postbacks.
   * @property {string} inAppEventPostbacks.datasensesEvent - The event on the Datasenses platform.
   * @property {string} inAppEventPostbacks.partnerEvent - The corresponding event on the partner's platform.
   * @property {string} inAppEventPostbacks.usersOrigin - Origin of users.
   * @property {boolean} inAppEventPostbacks.valueIncluded - Indicates whether value is included.
   * @property {boolean} inAppEventPostbacks.revenueIncluded - Indicates whether revenue is included.
   * @property {string} status - The status of the integration partner.
   * @property {string} createdBy - The email of the user who created the integration.
   * @property {string} updatedBy - The email of the user who last updated the integration.
   * @property {string} createdAt - The timestamp when the integration was created.
   * @property {string} updatedAt - The timestamp when the integration was last updated.
   */

  /**
   * Represents integration details for a Google app.
   * @typedef {Object} Project
   * @property {AppIntegrationGoogle} appIntegrationGoogle - Integration details for Google app.
   */

  /**
   * Retrieves the integration details for a specific app and partner.
   * @async
   * @param {Object} options - The options object.
   * @param {string} options.appId - The ID of the app.
   * @param {string} options.partner - The partner ID.
   * @returns {Promise<Project|boolean>} Returns a promise that resolves to an object representing the integration details if successful,
   * otherwise returns false.
   * @throws {Error} Throws an error if the request fails.
   * @example
   * const integrationDetails = await getAppIntegration({ appId: '382aa7a9-8ecb-46fc-b994-7e6521e2cd9a', partner: 'partnerId' });
   * // integrationDetails will contain the integration details object if successful, otherwise it will be false.
   */
  async getAppIntegration({appId, partner}) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/app/${appId}/partner/${partner}`,
      method: HttpMethod.GET
    };
    try {
      const res = await processRequest(ep);
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async checkFacebookDatasetIdPermission({appId}) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/app/${appId}/facebook-dataset-id-permission`,
      method: HttpMethod.GET
    };
    try {
      const res = await processRequest(ep);
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return false;
    }
  }
}
