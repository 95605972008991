export const CONTAINS = 'contains';
const NOT_CONTAINS = 'not_contains';
const EQUALS= '=';
const BETWEEN = 'between';
const GREATER_THAN= '>';
const LESS_THAN = '<';
const NOT_EQUALS= '!=';
const EXISTS = 'exists';
const NOT_EXISTS= 'not_exist';

export default {
  CONTAINS,
  NOT_CONTAINS,
  EQUALS,
  BETWEEN,
  GREATER_THAN,
  LESS_THAN,
  NOT_EQUALS,
  EXISTS,
  NOT_EXISTS,
  OPERATORS: [
    {
      text: '∋ (contains)',
      value: CONTAINS,
    },
    {
      text: '∌ (does not contain)',
        value: NOT_CONTAINS,
    },
    {
      text: '= (equals)',
        value: EQUALS,
    },
    {
      text: '≠ (not equals)',
        value: NOT_EQUALS,
    },
    {
      text: '∃ (exists)',
        value: EXISTS,
    },
    {
      text: '∄ (does not exist)',
        value: NOT_EXISTS,
    },
  ],
}

