import { useState, useMemo, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faA, faClock, faPlus, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import ToggleBox from 'components/ToggleBox';

import './index.scss'

/**
 * AddField component for adding new fields.
 * @param {Object} props - The props object.
 * @param {Array<{ value: any, text: string, dataType: string }>} [props.defaultOptions=[]] - The default options for adding fields.
 * @param {Function} [props.onAdd=() => {}] - The function to handle adding new fields.
 * @description The `dataType` property indicates the type of data the field represents, which can be either "text" or "time".
 * @returns {JSX.Element} A React component representing the AddField component.
 */
export default function AddField({
  defaultOptions = [],
  onAdd = () => { },
}) {
  const wrapper = useRef(null);

  const [search, setSearch] = useState("");

  const options = useMemo(() => {
    return defaultOptions.filter((item) => item.name.includes(search));
  }, [defaultOptions, search]);

  const addField = (value) => {
    onAdd(value);

    if (wrapper.current) {
      wrapper.current.toggle();
    }
  };

  const getIcon = (data) => {
    switch (data) {
      case 'Date':
        return faClock;
      default:
        return faA;
    }
  };

  return (
    <ToggleBox ref={wrapper} position='center'>
      <ToggleBox.Button>
        <FontAwesomeIcon className='cursor-pointer' icon={faPlus} />
      </ToggleBox.Button>

      <ToggleBox.Content>
        <div className='add-field px-3 py-2'>
          <div className="relative">
            <input
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              className='rounded py-1 px-3 border border-gray-300 pr-7'
            />

            <FontAwesomeIcon className='icon cursor-pointer' icon={faMagnifyingGlass} />
          </div>

          <div className='max-h-60 overflow-y-scroll mt-2'>
            {options.map((item, index) => (
              <div
                onClick={() => addField(item)}
                key={index}
                className='flex flex-row items-center py-1 px-2 hover:bg-blue-100 cursor-pointer'
              >
                <FontAwesomeIcon className='h-3 w-3 me-1.5' icon={getIcon(item.fieldType)} />
                <div className='select-none mb-0.5'>{item.name}</div>
              </div>
            ))}
          </div>
        </div>
      </ToggleBox.Content>
    </ToggleBox>
  )
};
