import { useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { faPlus, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { showError, showInfo } from "components/FlashMessage/flashMessageSlice";
import TailwindCommandPalettes from "components/TailwindCommandPalettes";

import DashboardService from "services/dashboard";


export function SaveToDashboard({ dashboardId, overviewPayload, reportType }) {
  const dispatch = useDispatch();
  const { appId } = useParams()
  const [dashboards, setDashboards] = useState([])
  const [clickSaveButton, setClickSaveButton] = useState(false);
  const [query, setQuery] = useState('')

  const onClickSaveButton = (e) => {
    e.preventDefault();

    if (dashboardId) {
      saveToCurrentDashboard();
    } else {
      setClickSaveButton(true);
    }
  }

  const createNewDashboard = async () => {
    if (query === '') {
      dispatch(showError({ message: "Name is required" }))
      return
    }

    try {
      const data = {
        name: query,
        appId: appId,
        payload: JSON.stringify(overviewPayload),
        reportType: reportType,
      }

      await DashboardService.createDashboard(data);

      setClickSaveButton(false)
      dispatch(showInfo({ message: "Create dashboard successfully" }))
    } catch (error) {
      const { message } = error;
      dispatch(showError({ message }));
    }
  }

  const saveToDashboard = async (item) => {
    try {
      const dashboardId = item.dashboardId;
      const payload = JSON.stringify(overviewPayload)
      await DashboardService.updateDashboard({ dashboardId, appId, payload });
      setClickSaveButton(false)
      dispatch(showInfo({ message: "Save dashboard successfully" }))
    } catch (error) {
      const { message } = error;
      dispatch(showError({ message }));
    }
  }

  const saveToCurrentDashboard = async () => {
    try {
      const payload = JSON.stringify(overviewPayload)
      await DashboardService.updateDashboard({ dashboardId, appId, payload });
      setClickSaveButton(false)
      dispatch(showInfo({ message: "Save dashboard successfully" }))
    } catch (error) {
      const { message } = error;
      dispatch(showError({ message }));
    }
  }

  const getDashboardList = async () => {
    try {
      const dashboards = await DashboardService.listDashboards();

      setDashboards(dashboards);
    } catch (error) {
      const { message } = error;
      dispatch(showError({ message }));
    }
  }

  useEffect(() => {
    getDashboardList()
  }, [appId]);

  return (
    <div className="relative">
      <button onClick={onClickSaveButton} className="ds-button">Save</button>
      <TailwindCommandPalettes
        placeholder={"Create or search dashboard"}
        open={clickSaveButton}
        setOpen={setClickSaveButton}
        query={query}
        setQuery={setQuery}
        pinnedActions={[
          {
            id: 1,
            name: 'Create a new dashboard',
            icon: faPlus,
            action: createNewDashboard,
          },
        ]}
        actions={
          dashboards.map((item) => ({
            ...item,
            icon: <FontAwesomeIcon icon={faSave} style={{ "color": "text-indigo-500" }} />,
            action: () => saveToDashboard(item),
          }))
        }
      />
    </div>
  )
}
