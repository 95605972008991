import TextInput from "components/TextInput";
import {PATHS, PLATFORM} from "consts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faApple} from "@fortawesome/free-brands-svg-icons";
import {DsIconAndroid, DsIconWebPlatform} from "components/DsIcon";
import TailwindCombobox from "components/TailwindCombobox";
import TIMEZONE_LIST from "views/homepage/timezone";
import LoadingSpinner from "components/LoadingSpinner";
import {FormProvider, useForm} from "react-hook-form";
import React, {useState} from "react";
import {isAppEligible} from "utils/app";
import AppService from "services/app";
import {showError, showInfo} from "components/FlashMessage/flashMessageSlice";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {faSquarePlus} from "@fortawesome/free-solid-svg-icons";

export default function AppFormCreation() {
  const {t} = useTranslation();
  const methods = useForm();
  const platform = methods.watch('platform');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    try {
      console.log(data)
      if (isAppEligible(data)) {
        setIsLoading(true);
        await AppService.createApp(data);
        dispatch(showInfo({message: t("CREATE_VALUE_SUCCESSFULLY", {Value: "App"})}));
        methods.reset();
        window.location = PATHS.APP
      } else {
        console.log(data);
        dispatch(showError({message: "Data form invalid"}));
      }
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
    setIsLoading(false);
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="gap-4 bg-white mt-5 ds-border-gray shadown-md">
          <div className="p-4">
            <div className="grid gap-4">
              <div>
                <div className="font-semibold text-lg leading-none">
                  <h3 className="ds-text-form-des">{t('ADD_AN_APP')}</h3>
                </div>
                <div
                  className="ds-text-lighter">{t('ADD_YOUR_APP_TO_START_RECORDING_INSTALLS_AND_EVENTS_IN_THE_DASHBOARD')}</div>
              </div>
              <div>
                <TextInput
                  label={t('APP_NAME')}
                  name="name"
                  className="w-full ds-input-text"
                  rules={
                    {required: t('VALUE_IS_REQUIRED', {Value: t('NAME')})}
                  }
                />
              </div>
              <div>
                <div className="ds-input-text">{t('SELECT_PLATFORM')}</div>
                <div className="grid grid-cols-2 gap-4 mt-1">
                  <div
                    className={`w-full h-20 p-4 cursor-pointer select-none ${platform === PLATFORM.ANDROID ? 'ds-border-blue' : 'ds-border-gray'}`}
                    onClick={() => methods.setValue('platform', PLATFORM.ANDROID)}>
                    <div className="flex justify-center items-center w-full">
                      <DsIconAndroid height={30} width={30}/>
                    </div>
                    <div
                      className="ds-input-text flex justify-center items-center w-full mt-1">Android,
                      AndroidTV
                    </div>
                  </div>

                  <div
                    className={`w-full h-20 p-4 cursor-pointer select-none ${platform === PLATFORM.IOS ? 'ds-border-blue' : 'ds-border-gray'}`}
                    onClick={() => methods.setValue('platform', PLATFORM.IOS)}>
                    <div className="flex justify-center items-center w-full">
                      <FontAwesomeIcon className='w-6 h-6 ds-icon-gray' icon={faApple}/>
                    </div>
                    <div className="ds-input-text flex justify-center items-center w-full mt-1">iOs</div>
                  </div>

                  <div
                    className={`w-full h-20 p-4 cursor-pointer select-none ${platform === PLATFORM.WEB ? 'ds-border-blue' : 'ds-border-gray'}`}
                    onClick={() => methods.setValue('platform', PLATFORM.WEB)}>
                    <div className="flex justify-center items-center w-full">
                      <div className="h-6 w-6">
                        <DsIconWebPlatform/>
                      </div>
                    </div>
                    <div className="ds-input-text flex justify-center items-center w-full mt-1">Web</div>
                  </div>
                </div>
                <TextInput name="platform" type="hidden" defaultValue={PLATFORM.ANDROID}/>
              </div>
              <div>
                <TextInput
                  label={platform === PLATFORM.ANDROID ? 'URL / Package name' : platform === PLATFORM.IOS ? 'URL / Bundle id' : 'URL'}
                  name="link"
                  className="w-full ds-input-text"
                  placeholder={
                    platform === PLATFORM.ANDROID ? "https://play.google.com/store/apps/details?id=vn.ds.androidapp // vn.ds.androidapp"
                      : platform === PLATFORM.IOS ? "https://apps.apple.com/ca/app/datasenses/id6479727711 // com.datasenses.io.iosapp"
                        : "https://datasenses.io"
                  }
                  rules={
                    {
                      required: t('VALUE_IS_REQUIRED', {Value: t('URL')}),
                      // pattern: {
                      //   value: URL_REGEX,
                      //   message: t('INVALID_URL')
                      // },
                    }
                  }
                />
              </div>
              <div>
                <TailwindCombobox
                  name="timezone"
                  title="Timezone"
                  rules={
                    {required: t('Timezone is required')}
                  }
                  items={TIMEZONE_LIST}
                />
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="submit"
                  className={`${isLoading && "cursor-not-allowed"} ds-button focus:outline-none`}
                >
                  {isLoading ?
                    <>
                      <LoadingSpinner className="mt-0.5 me-2"/>
                      {t('LOADING')}
                    </>
                    :
                    <>
                      <FontAwesomeIcon icon={faSquarePlus} className="me-2"/>
                      {t('Create App')}
                    </>
                  }
                </button>
              </div>
            </div>
          </div>
        </div>

      </form>
    </FormProvider>
  )
}
