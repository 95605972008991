import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PATHS } from "consts";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import ToggleCheckbox from "components/ToggleCheckbox";
import Tooltip from "components/Tooltip";

import TeamMemberInput from "../../components/TeamMemberInput";

import './index.scss';

export default function Permissions() {
  const { projectId } = useParams();
  const methods = useForm();
  const { t } = useTranslation();

  const active = methods.watch('active');

  const configureIntegration = methods.watch('configureIntegration');

  const configureInAppEventPostbacksDisabled = useMemo(() => !configureIntegration, [configureIntegration]);

  useEffect(() => {
    if (!configureIntegration) {
      methods.setValue('configureInAppEventPostbacks', false);
    }
  }, [configureIntegration]);

  return (
    <div id="permissions-tab">
      <FormProvider {...methods}>
        <div className="px-4">
          <div className="text-lg">Meta Ads permissions</div>

          <div className="text-xs">Select the permissions to grant this partner</div>

          <hr className="h-px my-6 bg-gray-200 border-0" />

          <div className="px-2">
            <div className="flex justify-start items-center w-full mt-2">
              <div>Ad network permissions</div>

              <ToggleCheckbox name="active" className="ms-5" />
            </div>
            <div className="mt-1 text-sm">Allow ad network to view their traffic for your app in their dashboard.</div>

            {
              active && (
                <>
                  <div className="mt-5">Allow access to Meta ads team members</div>
                  <div className="text-xs">Note: Admin users have permissions by default when this feature is enabled.</div>

                  <TeamMemberInput name="emails" />

                  <hr className="h-px my-6 bg-gray-200 border-0" />

                  <div>Allow Meta ads team members to:</div>

                  <div className="table w-1/2 mt-5">
                    <div className="table-row">
                      <div className="table-cell text-sm p-2">Configure integration</div>
                      <div className="table-cell p-2">
                        <ToggleCheckbox name="configureIntegration" />
                      </div>
                    </div>

                    <div className="table-row">
                      <div className="table-cell text-sm p-2 ps-5">Configure in-app event postbacks</div>
                      <div className="table-cell p-2">
                        <ToggleCheckbox name="configureInAppEventPostbacks" disabled={configureInAppEventPostbacksDisabled} />
                      </div>
                    </div>

                    <div className="table-row">
                      <div className="table-cell text-sm p-2">Access retention report</div>
                      <div className="table-cell p-2">
                        <ToggleCheckbox name="accessRetentionReport" />
                      </div>
                    </div>

                    <div className="table-row">
                      <div className="table-cell text-sm p-2">Access aggregate loyal users data</div>
                      <div className="table-cell p-2">
                        <ToggleCheckbox name="accessAggregateLoyalUsersData" />
                      </div>
                    </div>

                    <div className="table-row">
                      <div className="table-cell p-2">
                        <div className="flex justify-start items-center w-full mt-2">
                          <div className="text-sm me-2">Access aggregate conversions data</div>

                          <Tooltip minWidth="380">
                            <Tooltip.Label>
                              <FontAwesomeIcon className="text-gray-500 text-xs" icon={faCircleInfo} />
                            </Tooltip.Label>
                            <Tooltip.Content>
                              <p>When enabled, Google Ads (Adwords) can get conversion KPIs like installs, re-attributions, and re-engagements in reports. </p>
                            </Tooltip.Content>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="table-cell p-2">
                        <ToggleCheckbox name="accessAggregateConversionsData" />
                      </div>
                    </div>

                    <div className="table-row">
                      <div className="table-cell p-2">
                        <div className="flex justify-start items-center w-full mt-2">
                          <div className="text-sm me-2">Access aggregate in-app events data</div>

                          <Tooltip minWidth="380">
                            <Tooltip.Label>
                              <FontAwesomeIcon className="text-gray-500 text-xs" icon={faCircleInfo} />
                            </Tooltip.Label>
                            <Tooltip.Content>
                              <p>When enabled, Google Ads (Adwords) can see all in-app events recorded by AppsFlyer, not only the events mapped in the postback configuration.</p>
                            </Tooltip.Content>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="table-cell p-2">
                        <ToggleCheckbox name="accessAggregateInAppEventsData" />
                      </div>
                    </div>

                    <div className="table-row">
                      <div className="table-cell text-sm p-2">View validation rules</div>

                      <div className="table-cell p-2">
                        <ToggleCheckbox name="viewValidationRules" />
                      </div>
                    </div>

                    <div className="table-row">
                      <div className="table-cell text-sm p-2">Access aggregate revenue data</div>

                      <div className="table-cell p-2">
                        <ToggleCheckbox name="accessAggregateRevenueData" />
                      </div>
                    </div>

                    <div className="table-row">
                      <div className="table-cell p-2">
                        <div className="flex justify-start items-center w-full mt-2">
                          <div className="text-sm me-2">Use ad spend ingestion</div>

                          <Tooltip minWidth="380">
                            <Tooltip.Label>
                              <FontAwesomeIcon className="text-gray-500 text-xs" icon={faCircleInfo} />
                            </Tooltip.Label>
                            <Tooltip.Content>
                              <p>Allow the partner to upload spend data for this app using Ad Spend Ingestion</p>
                            </Tooltip.Content>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="table-cell p-2">
                        <ToggleCheckbox name="useAdSpendIngestion" />
                      </div>
                    </div>

                    <div className="table-row">
                      <div className="table-cell p-2">
                        <div className="flex justify-start items-center w-full mt-2">
                          <div className="text-sm me-2">Access Protect360 dashboard & raw data</div>

                          <Tooltip minWidth="380">
                            <Tooltip.Label>
                              <FontAwesomeIcon className="text-gray-500 text-xs" icon={faCircleInfo} />
                            </Tooltip.Label>
                            <Tooltip.Content>
                              <p>Raw data is available if any of the following are part of your subscription plan: Protect360, Validation Rules, raw data</p>
                            </Tooltip.Content>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="table-cell p-2">
                        <ToggleCheckbox name="accessProtect360DashboardAndRawData" />
                      </div>
                    </div>

                    <div className="table-row">
                      <div className="table-cell p-2">
                        <div className="flex justify-start items-center w-full mt-2">
                          <div className="text-sm me-2">Access ad revenue raw data (UA signals)</div>

                          <Tooltip minWidth="380">
                            <Tooltip.Label>
                              <FontAwesomeIcon className="text-gray-500 text-xs" icon={faCircleInfo} />
                            </Tooltip.Label>
                            <Tooltip.Content>
                              <p>This feature is exclusively available for ROI360 customers with the Advanced package.</p>
                            </Tooltip.Content>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="table-cell p-2">
                        <ToggleCheckbox name="accessAdRevenueRawData" />
                      </div>
                    </div>
                  </div>
                </>
              )
            }

          </div>
        </div>

        <footer>
          <hr className="h-px my-3 bg-gray-200 border-0" />

          <div className="flex justify-between items-center px-4 w-full">
            <Link
              to={`${PATHS.PROJECT}/${projectId}${PATHS.PARTNER_MARKETPLACE}`}
              className="text-blue-600 flex items-center text-sm hover:text-blue-500 hover:underline"
            >
              <FontAwesomeIcon icon={faAngleLeft} />
              <span className="ms-1 mb-0.5">{t('BACK_TO_LIST')}</span>
            </Link>

            <div className="flex justify-end items-center">
              <button className="text-blue-600 py-1.5 px-4 rounded me-2 hover:bg-blue-100">
                {t('DISCARD_CHANGES')}
              </button>

              <button className="bg-blue-600 text-white py-1.5 px-4 rounded">
                Save Permissions
              </button>
            </div>
          </div>
        </footer>
      </FormProvider>
    </div>
  );
}
