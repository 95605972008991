export default function DsIconEventsList() {
  return (
    // <svg fill="#000000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    //   <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    //   <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    //   <g id="SVGRepo_iconCarrier">
    //     <path
    //       d="M3,23a1,1,0,0,1-1-1V2A1,1,0,0,1,4,2V3H8A1,1,0,0,1,8,5H4v6h8a1,1,0,0,1,0,2H4v6H6a1,1,0,0,1,0,2H4v1A1,1,0,0,1,3,23ZM21,3H13V2a1,1,0,0,0-2,0V4h0V6a1,1,0,0,0,2,0V5h8a1,1,0,0,0,0-2Zm0,8H17V10a1,1,0,0,0-2,0v2h0v2a1,1,0,0,0,2,0V13h4a1,1,0,0,0,0-2Zm0,8H11V18a1,1,0,0,0-2,0v2H9v2a1,1,0,0,0,2,0V21H21a1,1,0,0,0,0-2Z"></path>
    //   </g>
    // </svg>
  <svg viewBox="0 0 24 24" fill="none" stroke="currentColor"
       strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-align-justify">
    <line x1="21" y1="10" x2="3" y2="10"></line>
    <line x1="21" y1="6" x2="3" y2="6"></line>
    <line x1="21" y1="14" x2="3" y2="14"></line>
    <line x1="21" y1="18" x2="3" y2="18"></line>
  </svg>
  );
}
