import { PATHS } from "consts";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function MarketingSuccess() {
  const { t } = useTranslation();

  return (
    <div className="bg-white h-screen">
      <div className="flex flex-col items-center justify-center h-screen p-6 md:mx-auto">
        <svg viewBox="0 0 24 24" className="text-green-600 w-16 h-16 mx-auto my-6">
          <path fill="currentColor"
            d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z">
          </path>
        </svg>
        <div className="text-center">
          <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">{t('DONE')}!</h3>
          <p className="text-gray-600 my-2">{t('YOUR_MARKETING_ACCOUNT_HAVE_BEEN_LINKED_SUCCESSFULLY')}.</p>
          <p> {t('HAVE_A_GREAT_DAY')}!  </p>
          <div className="py-10 text-center">
            <Link to={PATHS.HOMEPAGE} className="px-12 uppercase bg-indigo-600 hover:bg-indigo-500 text-white font-semibold py-3">
              {t('GO_HOMEPAGE')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
