import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { showError } from 'components/FlashMessage/flashMessageSlice';

import MetaDataService from 'services/metadata';

import EventInput from '../../components/EventInput/input';


export default function Input({
  value = [],
  onChange = () => { },
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { appId } = useParams();

  const [paramEventNameOptions, setParamEventNameOptions] = useState([]);

  const getParamEventNameOptions = async () => {
    try {
      const result = await MetaDataService.getEventNameList({ appId });

      setParamEventNameOptions(result.map((value) => ({ text: value, value })));
    } catch (error) {
      const { message } = error;
      dispatch(showError({ message }));
    }
  };

  const addData = () => {
    const data = [...value];

    data.push([{
      paramEventName: "",
      paramFilter: [],
    }]);

    onChange(data);
  };

  const handleEventChange = (newValue, stepIndex, eventIndex) => {
    const data = [...value];

    data[stepIndex][eventIndex] = newValue;

    onChange(data);
  };

  const deleteEvent = (stepIndex, eventIndex) => {
    const data = [...value];

    if (data[stepIndex].length === 1) {
      data.splice(stepIndex, 1);
    } else {
      data[stepIndex].splice(eventIndex, 1);
    }

    onChange(data);
  };

  const addEventType = (stepIndex) => {
    const data = [...value];

    data[stepIndex].push({
      name: "",
      paramFilter: [],
    });

    onChange(data);
  };

  useEffect(() => {
    getParamEventNameOptions();
  }, [appId]);

  return (
    <>
      {value.map((step, stepIndex) => (
        <div key={stepIndex} className='ds-block mb-4'>
          <div className='flex justify-start items-center border-b'>
            <div className='justify-start items-center px-3 py-1.5 border-e'>
              <FontAwesomeIcon icon={faEllipsisV} />
              <FontAwesomeIcon className='ms-0.5' icon={faEllipsisV} />
            </div>
            <span className='ms-3 font-bold uppercase'>{t('STEP')} {stepIndex + 1}</span>
          </div>
          <div className='py-3 ps-12 pe-2'>
            {step.map((item, eventIndex) => (
              <div key={eventIndex} className='relative'>
                <EventInput
                  key={eventIndex}
                  value={item}
                  onChange={(value) => handleEventChange(value, stepIndex, eventIndex)}
                  onDelete={() => deleteEvent(stepIndex, eventIndex)}
                  options={paramEventNameOptions}
                  showDelete={step.length > 1 || value.length > 1}
                  className="mb-10"
                />

                {eventIndex > 0 && <span className='absolute uppercase top-1 -left-8 text-gray-500 text-sm'>{t('OR')}</span>}
              </div>
            ))}
            <div
              onClick={() => addEventType(stepIndex)}
              className="ds-button-2"
            >
              Or Event
            </div>
          </div>
        </div>
      ))}
      <div
        onClick={addData}
        className="ds-button-2"
      >
        + {t('FUNNEL_STEP')}
      </div>
    </>
  );
}
