export default function DsIconLazada() {
  return (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
  viewBox="0 0 500 500" style={{enableBackground: 'new 0 0 500 500'}}>
    <g id="watermark_1_">
    <g>
    <g id="watermark" style={{opacity:0.61}}>
    <g>
    <g>
    <g>
    <g>
    <polygon style={{fill: '#FFFFFF'}} points="213.765,461.172 208.204,474.017 205.268,474.017 199.726,461.172 202.938,461.172
  206.846,470.347 210.81,461.172 							"/>
  </g>
  <g>
    <path style={{fill: '#FFFFFF'}} d="M223.507,469.741h-7.469c0.275,1.23,1.321,1.982,2.826,1.982c1.046,0,1.798-0.312,2.477-0.954
								l1.523,1.651c-0.917,1.046-2.294,1.597-4.074,1.597c-3.413,0-5.634-2.147-5.634-5.083c0-2.954,2.257-5.083,5.267-5.083
								c2.899,0,5.138,1.945,5.138,5.12C223.562,469.191,223.526,469.503,223.507,469.741 M216.002,468.071h4.863
								c-0.202-1.248-1.138-2.055-2.422-2.055C217.139,466.016,216.204,466.805,216.002,468.071"/>
  </g>
  <g>
    <path style={{fill: '#FFFFFF'}} d="M224.735,468.934c0-2.973,2.294-5.083,5.505-5.083c2.074,0,3.707,0.899,4.423,2.514
								l-2.22,1.193c-0.532-0.936-1.321-1.358-2.221-1.358c-1.45,0-2.587,1.009-2.587,2.734c0,1.725,1.138,2.734,2.587,2.734
								c0.899,0,1.688-0.404,2.221-1.358l2.22,1.211c-0.716,1.578-2.349,2.495-4.423,2.495
								C227.029,474.017,224.735,471.907,224.735,468.934"/>
  </g>
  <g>
    <path style={{fill: '#FFFFFF'}} d="M242.828,473.393c-0.587,0.422-1.45,0.624-2.331,0.624c-2.33,0-3.688-1.193-3.688-3.542
								v-4.055h-1.523v-2.202h1.523v-2.404h2.863v2.404h2.459v2.202h-2.459v4.019c0,0.844,0.458,1.303,1.229,1.303
								c0.422,0,0.844-0.128,1.156-0.367L242.828,473.393z"/>
  </g>
</g>
  <g>
    <path style={{fill: '#FFFFFF'}} d="M262.474,463.997v2.643c-0.238-0.018-0.422-0.037-0.642-0.037
							c-1.578,0-2.624,0.862-2.624,2.753v4.661h-2.863v-9.873h2.734v1.303C259.776,464.493,260.951,463.997,262.474,463.997"/>
  </g>
  <g>
    <path style={{fill: '#FFFFFF'}} d="M263.299,472.53l1.009-2.239c1.083,0.789,2.697,1.339,4.239,1.339
							c1.762,0,2.477-0.587,2.477-1.376c0-2.404-7.469-0.752-7.469-5.524c0-2.184,1.762-4,5.413-4c1.615,0,3.267,0.385,4.459,1.138
							l-0.918,2.257c-1.193-0.679-2.422-1.009-3.56-1.009c-1.761,0-2.44,0.66-2.44,1.468c0,2.367,7.468,0.734,7.468,5.45
							c0,2.147-1.78,3.982-5.45,3.982C266.492,474.017,264.437,473.411,263.299,472.53"/>
  </g>
  <g>
    <path style={{fill: '#FFFFFF'}} d="M285.428,469.741h-7.469c0.275,1.23,1.321,1.982,2.826,1.982c1.046,0,1.798-0.312,2.477-0.954
							l1.523,1.651c-0.918,1.046-2.294,1.597-4.074,1.597c-3.413,0-5.634-2.147-5.634-5.083c0-2.954,2.257-5.083,5.267-5.083
							c2.9,0,5.138,1.945,5.138,5.12C285.484,469.191,285.447,469.503,285.428,469.741 M277.923,468.071h4.863
							c-0.202-1.248-1.138-2.055-2.422-2.055C279.061,466.016,278.125,466.805,277.923,468.071"/>
  </g>
  <g>
    <path style={{fill: '#FFFFFF'}} d="M297.007,469.741h-7.469c0.275,1.23,1.321,1.982,2.826,1.982c1.046,0,1.798-0.312,2.477-0.954
							l1.523,1.651c-0.918,1.046-2.294,1.597-4.074,1.597c-3.413,0-5.634-2.147-5.634-5.083c0-2.954,2.257-5.083,5.267-5.083
							c2.899,0,5.138,1.945,5.138,5.12C297.062,469.191,297.025,469.503,297.007,469.741 M289.501,468.071h4.863
							c-0.202-1.248-1.138-2.055-2.422-2.055C290.639,466.016,289.703,466.805,289.501,468.071"/>
  </g>
  <g>
    <polygon style={{fill: '#FFFFFF'}} points="303.208,470.145 301.831,471.503 301.831,474.017 298.969,474.017 298.969,460.401
							301.831,460.401 301.831,468.108 306.016,464.144 309.429,464.144 305.318,468.328 309.796,474.017 306.327,474.017 						"/>
  </g>
  <g>
    <path style={{fill: '#FFFFFF'}} d="M256.601,475.359L256.601,475.359l-2.86-2.86c-0.435,0.508-0.953,0.943-1.534,1.284
							c-0.092,0.069-0.186,0.136-0.283,0.199l3.027,3.027c0.456,0.456,1.195,0.456,1.65,0
							C257.057,476.554,257.057,475.815,256.601,475.359"/>
  </g>
  <g>
    <path style={{fill: '#FFFFFF'}} d="M249.984,463.244c0.221-0.173,0.495-0.281,0.797-0.281c0.042,0,0.083,0.002,0.124,0.006
							c-0.214-0.156-0.478-0.249-0.764-0.249c-0.395,0-0.744,0.18-0.981,0.458c-0.051,0-0.1,0.006-0.151,0.008
							c0.039-0.077,0.064-0.162,0.064-0.255c0-0.234-0.143-0.434-0.346-0.519c0.087,0.126,0.138,0.279,0.138,0.445
							c0,0.122-0.031,0.237-0.081,0.341c-2.811,0.201-5.03,2.539-5.03,5.4c0,2.993,2.426,5.42,5.42,5.42
							c2.993,0,5.42-2.426,5.42-5.42C254.595,465.879,252.593,463.635,249.984,463.244 M247.983,464.656
							c0.435,0,0.844,0.112,1.199,0.309c0.374-0.245,0.82-0.387,1.299-0.387c0.711,0,1.348,0.312,1.784,0.806
							c-0.435-0.379-1.004-0.61-1.627-0.61c-0.449,0-0.87,0.12-1.234,0.329c-0.079,0.046-0.156,0.096-0.229,0.15
							c-0.065-0.048-0.133-0.093-0.204-0.134c-0.369-0.219-0.8-0.344-1.26-0.344c-0.3,0-0.588,0.054-0.854,0.152
							C247.196,464.754,247.578,464.656,247.983,464.656 M251.772,469.098c0.115,0.305,0.181,0.634,0.181,0.979
							c0,1.534-1.244,2.777-2.778,2.777c-1.534,0-2.777-1.243-2.777-2.777c0-0.345,0.066-0.674,0.181-0.979
							c-0.62-0.382-1.035-1.064-1.035-1.846c0-1.197,0.971-2.168,2.168-2.168c0.565,0,1.078,0.218,1.463,0.572
							c0.386-0.354,0.899-0.572,1.464-0.572c1.197,0,2.168,0.971,2.168,2.168C252.807,468.034,252.392,468.716,251.772,469.098"/>
  </g>
  <g>
    <path style={{fill: '#FFFFFF'}} d="M251.666,467.061c0,0.497-0.404,0.901-0.901,0.901c-0.497,0-0.901-0.404-0.901-0.901
							s0.404-0.901,0.901-0.901c0.101,0,0.197,0.017,0.287,0.047c-0.03,0.062-0.045,0.132-0.045,0.204
							c0,0.273,0.221,0.494,0.494,0.494c0.05,0,0.099-0.008,0.146-0.022C251.66,466.941,251.666,467,251.666,467.061"/>
  </g>
  <g>
    <path style={{fill: '#FFFFFF'}} d="M248.255,467.061c0,0.376-0.305,0.681-0.681,0.681c-0.376,0-0.681-0.305-0.681-0.681
							s0.305-0.681,0.681-0.681c0.076,0,0.149,0.013,0.217,0.035c-0.023,0.047-0.034,0.1-0.034,0.155
							c0,0.206,0.167,0.374,0.374,0.374c0.038,0,0.075-0.006,0.11-0.017C248.25,466.97,248.255,467.015,248.255,467.061"/>
  </g>
  <g>
    <g>
      <g>
        <rect x="242.908" y="467.83" style={{fill: '#FFFFFF'}} width="0.616" height="0.616"/>
      </g>
      <g>
        <rect x="254.827" y="467.83" style={{fill: '#FFFFFF'}} width="0.616" height="0.616"/>
      </g>
      <g>
        <path style={{fill: '#FFFFFF'}} d="M255.205,468.082h-0.14c0-3.248-2.642-5.89-5.89-5.89c-3.248,0-5.89,2.642-5.89,5.89h-0.14
									c0-1.611,0.627-3.125,1.766-4.264c1.139-1.139,2.653-1.766,4.264-1.766c1.611,0,3.125,0.627,4.264,1.766
									C254.578,464.957,255.205,466.471,255.205,468.082"/>
      </g>
    </g>
    <g>
      <rect x="248.868" y="461.814" style={{fill: '#FFFFFF'}} width="0.616" height="0.616"/>
    </g>
    <g>
      <rect x="244.95" y="462.052" style={{fill: '#FFFFFF'}} width="8.45" height="0.14"/>
    </g>
    <g>
      <path style={{fill: '#FFFFFF'}} d="M245.174,462.15c0,0.108-0.088,0.196-0.196,0.196c-0.108,0-0.196-0.088-0.196-0.196
								c0-0.108,0.088-0.196,0.196-0.196C245.086,461.954,245.174,462.042,245.174,462.15"/>
    </g>
    <g>
      <path style={{fill: '#FFFFFF'}} d="M253.596,462.15c0,0.108-0.088,0.196-0.196,0.196c-0.108,0-0.196-0.088-0.196-0.196
								c0-0.108,0.088-0.196,0.196-0.196C253.509,461.954,253.596,462.042,253.596,462.15"/>
    </g>
  </g>
</g>
  <g>
    <path style={{fill: '#FFFFFF'}} d="M314.356,473.409c0,1.559-2.364,1.559-2.364,0C311.993,471.851,314.356,471.851,314.356,473.409
						z"/>
  </g>
  <g>
    <g>
      <g>
        <path style={{fill: '#FFFFFF'}} d="M161.739,466.549l1.772,6.088l1.855-6.088h2.215l-2.823,8.14h-2.33l-0.837-2.396l-0.722-2.708
								l-0.722,2.708l-0.837,2.396h-2.33l-2.839-8.14h2.231l1.855,6.088l1.756-6.088H161.739z"/>
      </g>
      <g>
        <path style={{fill: '#FFFFFF'}} d="M176.713,466.549l1.772,6.088l1.855-6.088h2.215l-2.823,8.14h-2.33l-0.837-2.396l-0.722-2.708
								l-0.722,2.708l-0.837,2.396h-2.33l-2.839-8.14h2.231l1.855,6.088l1.756-6.088H176.713z"/>
      </g>
      <g>
        <path style={{fill: '#FFFFFF'}} d="M191.687,466.549l1.772,6.088l1.855-6.088h2.215l-2.823,8.14h-2.33l-0.837-2.396l-0.722-2.708
								l-0.722,2.708l-0.837,2.396h-2.33l-2.839-8.14h2.231l1.855,6.088l1.756-6.088H191.687z"/>
      </g>
    </g>
    <g>
      <path style={{fill: '#FFFFFF'}} d="M200.908,473.52c0,1.559-2.364,1.559-2.364,0C198.545,471.962,200.908,471.962,200.908,473.52z
							"/>
    </g>
    <g>
      <path style={{fill: '#FFFFFF'}} d="M322.71,473.409c-0.935,0.919-1.919,1.28-3.117,1.28c-2.346,0-4.3-1.411-4.3-4.282
							s1.953-4.284,4.3-4.284c1.149,0,2.035,0.329,2.921,1.199l-1.264,1.329c-0.476-0.428-1.066-0.64-1.623-0.64
							c-1.346,0-2.33,0.985-2.33,2.396c0,1.542,1.05,2.362,2.297,2.362c0.64,0,1.28-0.18,1.772-0.672L322.71,473.409z"/>
    </g>
    <g>
      <path style={{fill: '#FFFFFF'}} d="M331.805,470.472c0,2.33-1.591,4.217-4.217,4.217c-2.626,0-4.201-1.887-4.201-4.217
							c0-2.314,1.609-4.217,4.185-4.217C330.149,466.255,331.805,468.158,331.805,470.472z M325.389,470.472
							c0,1.231,0.739,2.38,2.199,2.38c1.46,0,2.199-1.149,2.199-2.38c0-1.215-0.854-2.396-2.199-2.396
							C326.144,468.077,325.389,469.258,325.389,470.472z"/>
    </g>
    <g>
      <path style={{fill: '#FFFFFF'}} d="M338.572,474.689v-4.332c0-1.068-0.559-2.035-1.658-2.035c-1.082,0-1.722,0.967-1.722,2.035
							v4.332h-2.003v-8.107h1.855l0.148,0.985c0.426-0.82,1.362-1.116,2.133-1.116c0.969,0,1.937,0.394,2.396,1.51
							c0.723-1.149,1.658-1.478,2.708-1.478c2.297,0,3.43,1.412,3.43,3.84v4.365h-2.003v-4.365c0-1.066-0.442-1.97-1.526-1.97
							c-1.082,0-1.756,0.935-1.756,2.003v4.332H338.572z"/>
    </g>
  </g>
</g>
</g>
  <g style={{opacity:0.61}}>
    <g>
      <g>
        <path style={{fill: '#FFFFFF'}} d="M345.988,431.299c-0.006-1.224-0.959-2.121-2.189-2.14c-0.924-0.014-0.832,0.12-0.834-0.867
						c-0.003-1.097-0.007-2.194,0.003-3.292c0.003-0.379-0.112-0.691-0.362-0.976c-2.342-2.668-4.678-5.342-7.019-8.011
						c-0.12-0.137-0.275-0.244-0.414-0.366c-6.214,0-12.427,0-18.641,0c-0.794,0.293-1.14,0.868-1.138,1.709
						c0.01,3.748,0.004,7.496,0.004,11.243c0,0.556-0.003,0.589-0.55,0.551c-1.497-0.102-2.516,0.986-2.497,2.492
						c0.044,3.476,0.013,6.953,0.014,10.429c0.001,1.58,0.867,2.443,2.45,2.449c0.582,0.002,0.582,0.002,0.582,0.6
						c0,2.219-0.001,4.438,0.001,6.657c0.001,1.192,0.58,1.771,1.768,1.771c8.013,0.001,16.027,0.001,24.04,0
						c1.168,0,1.755-0.583,1.757-1.745c0.002-2.256,0-4.512,0.001-6.768c0-0.512,0.002-0.511,0.52-0.515
						c0.271-0.002,0.541,0.008,0.809-0.058c0.996-0.246,1.69-1.044,1.694-2.069C346.003,438.696,346.005,434.997,345.988,431.299z
						 M317.537,417.162c5.337,0,10.674,0,16.01,0c0.548,0,0.554,0.007,0.554,0.561c0.001,2.243-0.001,4.486,0.001,6.73
						c0.001,0.811,0.249,1.059,1.06,1.059c1.91,0.001,3.821,0,5.731,0.001c0.458,0,0.479,0.021,0.481,0.483
						c0.003,0.887,0.002,1.775,0,2.662c-0.001,0.477-0.024,0.498-0.504,0.499c-3.895,0.001-7.79,0-11.684,0
						c-3.882,0-7.765,0-11.647,0c-0.55,0-0.552-0.001-0.552-0.561c-0.001-3.624-0.001-7.247,0-10.871
						C316.987,417.166,316.991,417.162,317.537,417.162z M340.822,451.579c-3.883,0.001-7.766,0-11.65,0c-3.871,0-7.742,0-11.613,0
						c-0.572,0-0.574-0.002-0.574-0.579c-0.001-1.997-0.001-3.994,0.001-5.991c0-0.464,0.023-0.486,0.478-0.487
						c7.803-0.001,15.607-0.001,23.41,0c0.478,0,0.499,0.02,0.499,0.503c0.002,1.997,0.001,3.994,0,5.991
						C341.375,451.574,341.37,451.579,340.822,451.579z"/>
      </g>
      <g>
        <g>
          <rect x="327.418" y="417.053" style={{fill: '#FFFFFF'}} width="1.757" height="1.054"/>
        </g>
        <g>
          <rect x="325.662" y="418.107" style={{fill: '#FFFFFF'}} width="1.757" height="1.054"/>
        </g>
        <g>
          <rect x="327.418" y="419.161" style={{fill: '#FFFFFF'}} width="1.757" height="1.054"/>
        </g>
        <g>
          <rect x="325.662" y="420.215" style={{fill: '#FFFFFF'}} width="1.757" height="1.054"/>
        </g>
        <g>
          <rect x="327.418" y="421.269" style={{fill: '#FFFFFF'}} width="1.757" height="1.054"/>
        </g>
        <g>
          <rect x="325.662" y="422.323" style={{fill: '#FFFFFF'}} width="1.757" height="1.054"/>
        </g>
        <g>
          <rect x="327.418" y="423.378" style={{fill: '#FFFFFF'}} width="1.757" height="1.054"/>
        </g>
        <g>
          <rect x="325.662" y="424.432" style={{fill: '#FFFFFF'}} width="1.757" height="1.054"/>
        </g>
        <g>
          <rect x="327.418" y="425.486" style={{fill: '#FFFFFF'}} width="1.757" height="1.054"/>
        </g>
        <g>
          <rect x="325.662" y="426.54" style={{fill: '#FFFFFF'}} width="1.757" height="1.054"/>
        </g>
        <g>
          <rect x="327.418" y="427.594" style={{fill: '#FFFFFF'}} width="1.757" height="1.054"/>
        </g>
        <g>
          <rect x="325.662" y="428.648" style={{fill: '#FFFFFF'}} width="1.757" height="1.054"/>
        </g>
      </g>
    </g>
    <g>
      <path style={{fill: '#FFFFFF'}} d="M187.637,431.299c-0.006-1.224-0.959-2.121-2.189-2.14c-0.924-0.014-0.832,0.12-0.834-0.867
					c-0.003-1.097-0.007-2.194,0.003-3.292c0.003-0.379-0.112-0.691-0.362-0.976c-2.342-2.668-4.678-5.342-7.019-8.011
					c-0.12-0.137-0.275-0.244-0.414-0.366c-6.214,0-12.427,0-18.641,0c-0.794,0.293-1.14,0.868-1.138,1.709
					c0.01,3.748,0.004,7.496,0.004,11.243c0,0.556-0.003,0.589-0.55,0.551c-1.497-0.102-2.516,0.986-2.497,2.492
					c0.044,3.476,0.013,6.953,0.014,10.429c0.001,1.58,0.867,2.443,2.45,2.449c0.582,0.002,0.582,0.002,0.582,0.6
					c0,2.219-0.001,4.438,0.001,6.657c0.001,1.192,0.58,1.771,1.768,1.771c8.013,0.001,16.027,0.001,24.04,0
					c1.168,0,1.755-0.583,1.757-1.745c0.002-2.256,0-4.512,0.001-6.768c0-0.512,0.002-0.511,0.52-0.515
					c0.271-0.002,0.541,0.008,0.809-0.058c0.996-0.246,1.69-1.044,1.694-2.069C187.652,438.696,187.655,434.997,187.637,431.299z
					 M159.187,417.162c5.337,0,10.674,0,16.01,0c0.548,0,0.554,0.007,0.554,0.561c0.001,2.243-0.001,4.486,0.001,6.73
					c0.001,0.811,0.249,1.059,1.06,1.059c1.91,0.001,3.821,0,5.731,0.001c0.458,0,0.479,0.021,0.481,0.483
					c0.003,0.887,0.002,1.775,0,2.662c-0.001,0.477-0.024,0.498-0.504,0.499c-3.895,0.001-7.79,0-11.684,0
					c-3.882,0-7.765,0-11.647,0c-0.55,0-0.552-0.001-0.552-0.561c-0.001-3.624-0.001-7.247,0-10.871
					C158.636,417.166,158.64,417.162,159.187,417.162z M182.472,451.579c-3.883,0.001-7.766,0-11.65,0c-3.871,0-7.742,0-11.613,0
					c-0.572,0-0.574-0.002-0.574-0.579c-0.001-1.997-0.001-3.994,0.001-5.991c0-0.464,0.023-0.486,0.478-0.487
					c7.803-0.001,15.607-0.001,23.41,0c0.478,0,0.499,0.02,0.499,0.503c0.002,1.997,0.001,3.994,0,5.991
					C183.024,451.574,183.019,451.579,182.472,451.579z"/>
    </g>
    <g>
      <path style={{fill: '#FFFFFF'}} d="M159.779,440.669v-7.897h2.779c0.375,0,0.726,0.061,1.053,0.182
					c0.327,0.121,0.612,0.292,0.855,0.513c0.242,0.221,0.434,0.483,0.573,0.789c0.14,0.305,0.21,0.642,0.21,1.009
					c0,0.375-0.07,0.715-0.21,1.02c-0.14,0.305-0.331,0.568-0.573,0.789c-0.243,0.221-0.528,0.392-0.855,0.513
					c-0.327,0.121-0.678,0.182-1.053,0.182h-1.29v2.901H159.779z M162.58,436.356c0.39,0,0.684-0.111,0.882-0.331
					c0.199-0.22,0.298-0.474,0.298-0.761c0-0.14-0.024-0.276-0.072-0.408c-0.048-0.132-0.121-0.248-0.221-0.347
					c-0.099-0.099-0.223-0.178-0.37-0.237c-0.147-0.059-0.32-0.089-0.518-0.089h-1.312v2.173H162.58z"/>
      <path style={{fill: '#FFFFFF'}} d="M166.308,432.771h1.731l3.177,5.294h0.088l-0.088-1.522v-3.772h1.478v7.897h-1.566l-3.353-5.592
					h-0.088l0.088,1.522v4.07h-1.467V432.771z"/>
      <path style={{fill: '#FFFFFF'}} d="M177.922,436.334h3.872c0.022,0.089,0.04,0.195,0.055,0.32c0.014,0.125,0.022,0.25,0.022,0.375
					c0,0.507-0.077,0.985-0.231,1.434c-0.154,0.449-0.397,0.849-0.728,1.202c-0.353,0.375-0.776,0.666-1.268,0.871
					c-0.493,0.206-1.055,0.309-1.688,0.309c-0.574,0-1.114-0.103-1.622-0.309c-0.507-0.206-0.948-0.492-1.323-0.86
					c-0.375-0.367-0.673-0.803-0.894-1.307c-0.221-0.504-0.331-1.053-0.331-1.649c0-0.595,0.11-1.145,0.331-1.649
					c0.221-0.504,0.518-0.939,0.894-1.307c0.375-0.367,0.816-0.654,1.323-0.86c0.507-0.206,1.048-0.309,1.622-0.309
					c0.64,0,1.211,0.11,1.715,0.331c0.504,0.221,0.935,0.526,1.296,0.915l-1.025,1.004c-0.258-0.272-0.544-0.48-0.861-0.623
					c-0.316-0.143-0.695-0.215-1.136-0.215c-0.361,0-0.703,0.065-1.026,0.193c-0.324,0.129-0.607,0.311-0.85,0.546
					c-0.242,0.235-0.436,0.52-0.579,0.855c-0.143,0.335-0.215,0.708-0.215,1.119c0,0.412,0.072,0.785,0.215,1.12
					c0.143,0.335,0.338,0.62,0.584,0.855c0.246,0.235,0.531,0.418,0.855,0.546c0.323,0.129,0.669,0.193,1.037,0.193
					c0.419,0,0.773-0.061,1.064-0.182c0.29-0.121,0.538-0.278,0.744-0.469c0.148-0.132,0.275-0.3,0.381-0.502
					c0.106-0.202,0.189-0.428,0.248-0.678h-2.482V436.334z"/>
    </g>
    <g>
      <path style={{fill: '#FFFFFF'}} d="M224.019,431.299c-0.006-1.224-0.959-2.121-2.189-2.14c-0.924-0.014-0.832,0.12-0.834-0.867
					c-0.003-1.097-0.007-2.194,0.003-3.292c0.003-0.379-0.112-0.691-0.362-0.976c-2.342-2.668-4.678-5.342-7.019-8.011
					c-0.12-0.137-0.275-0.244-0.414-0.366c-6.214,0-12.427,0-18.641,0c-0.794,0.293-1.14,0.868-1.138,1.709
					c0.01,3.748,0.004,7.496,0.004,11.243c0,0.556-0.003,0.589-0.55,0.551c-1.497-0.102-2.516,0.986-2.497,2.492
					c0.044,3.476,0.013,6.953,0.014,10.429c0.001,1.58,0.867,2.443,2.45,2.449c0.582,0.002,0.582,0.002,0.582,0.6
					c0,2.219-0.001,4.438,0.001,6.657c0.001,1.192,0.58,1.771,1.768,1.771c8.013,0.001,16.027,0.001,24.04,0
					c1.168,0,1.755-0.583,1.757-1.745c0.002-2.256,0-4.512,0.001-6.768c0-0.512,0.002-0.511,0.52-0.515
					c0.271-0.002,0.541,0.008,0.809-0.058c0.997-0.246,1.69-1.044,1.694-2.069C224.034,438.696,224.036,434.997,224.019,431.299z
					 M195.568,417.162c5.337,0,10.674,0,16.01,0c0.548,0,0.554,0.007,0.554,0.561c0.001,2.243-0.001,4.486,0.001,6.73
					c0.001,0.811,0.249,1.059,1.06,1.059c1.91,0.001,3.821,0,5.731,0.001c0.458,0,0.479,0.021,0.481,0.483
					c0.003,0.887,0.002,1.775,0,2.662c-0.001,0.477-0.024,0.498-0.504,0.499c-3.895,0.001-7.79,0-11.684,0
					c-3.882,0-7.765,0-11.647,0c-0.55,0-0.552-0.001-0.552-0.561c-0.001-3.624-0.001-7.247,0-10.871
					C195.018,417.166,195.022,417.162,195.568,417.162z M218.854,451.579c-3.883,0.001-7.766,0-11.65,0c-3.871,0-7.742,0-11.613,0
					c-0.572,0-0.574-0.002-0.574-0.579c-0.001-1.997-0.001-3.994,0.001-5.991c0-0.464,0.023-0.486,0.478-0.487
					c7.803-0.001,15.607-0.001,23.41,0c0.478,0,0.499,0.02,0.499,0.503c0.002,1.997,0.001,3.994,0,5.991
					C219.406,451.574,219.401,451.579,218.854,451.579z"/>
    </g>
    <g>
      <path style={{fill: '#FFFFFF'}} d="M205.111,432.771h1.699l2.967,7.897h-1.644l-0.651-1.886h-3.044l-0.651,1.886h-1.643
					L205.111,432.771z M206.997,437.415l-0.706-1.974l-0.287-0.96h-0.088l-0.287,0.96l-0.706,1.974H206.997z"/>
      <path style={{fill: '#FFFFFF'}} d="M211.331,434.536c-0.125,0-0.245-0.024-0.359-0.072c-0.114-0.048-0.213-0.114-0.298-0.199
					c-0.084-0.084-0.151-0.182-0.198-0.292c-0.048-0.11-0.072-0.232-0.072-0.364s0.024-0.254,0.072-0.364
					c0.048-0.11,0.114-0.208,0.198-0.292c0.084-0.084,0.184-0.151,0.298-0.198c0.114-0.048,0.233-0.072,0.359-0.072
					c0.257,0,0.478,0.09,0.662,0.27c0.184,0.181,0.276,0.399,0.276,0.656s-0.092,0.476-0.276,0.656
					C211.809,434.446,211.589,434.536,211.331,434.536z M210.615,440.669v-5.404h1.445v5.404H210.615z"/>
    </g>
    <g>
      <path style={{fill: '#FFFFFF'}} d="M261.044,431.299c-0.006-1.224-0.959-2.121-2.189-2.14c-0.924-0.014-0.832,0.12-0.834-0.867
					c-0.003-1.097-0.007-2.194,0.003-3.292c0.003-0.379-0.112-0.691-0.362-0.976c-2.342-2.668-4.678-5.342-7.019-8.011
					c-0.12-0.137-0.275-0.244-0.414-0.366c-6.214,0-12.427,0-18.641,0c-0.794,0.293-1.14,0.868-1.138,1.709
					c0.01,3.748,0.004,7.496,0.004,11.243c0,0.556-0.003,0.589-0.55,0.551c-1.497-0.102-2.516,0.986-2.497,2.492
					c0.044,3.476,0.013,6.953,0.014,10.429c0.001,1.58,0.867,2.443,2.45,2.449c0.582,0.002,0.582,0.002,0.582,0.6
					c0,2.219-0.001,4.438,0.001,6.657c0.001,1.192,0.58,1.771,1.768,1.771c8.013,0.001,16.027,0.001,24.04,0
					c1.168,0,1.755-0.583,1.757-1.745c0.002-2.256,0-4.512,0.001-6.768c0-0.512,0.002-0.511,0.52-0.515
					c0.271-0.002,0.541,0.008,0.809-0.058c0.996-0.246,1.69-1.044,1.694-2.069C261.059,438.696,261.061,434.997,261.044,431.299z
					 M232.593,417.162c5.337,0,10.674,0,16.01,0c0.548,0,0.554,0.007,0.554,0.561c0.001,2.243-0.001,4.486,0.001,6.73
					c0.001,0.811,0.249,1.059,1.06,1.059c1.91,0.001,3.821,0,5.731,0.001c0.458,0,0.479,0.021,0.481,0.483
					c0.003,0.887,0.002,1.775,0,2.662c-0.001,0.477-0.024,0.498-0.504,0.499c-3.895,0.001-7.79,0-11.684,0
					c-3.882,0-7.765,0-11.647,0c-0.55,0-0.552-0.001-0.552-0.561c-0.001-3.624-0.001-7.247,0-10.871
					C232.042,417.166,232.047,417.162,232.593,417.162z M255.878,451.579c-3.883,0.001-7.766,0-11.65,0c-3.871,0-7.742,0-11.613,0
					c-0.572,0-0.574-0.002-0.574-0.579c-0.001-1.997-0.001-3.994,0.001-5.991c0-0.464,0.023-0.486,0.478-0.487
					c7.803-0.001,15.607-0.001,23.41,0c0.478,0,0.499,0.02,0.499,0.503c0.002,1.997,0.001,3.994,0,5.991
					C256.431,451.574,256.426,451.579,255.878,451.579z"/>
    </g>
    <g>
      <path style={{fill: '#FFFFFF'}} d="M236.361,440.845c-0.338,0-0.662-0.048-0.971-0.143c-0.309-0.095-0.592-0.239-0.849-0.43
					c-0.257-0.191-0.481-0.425-0.673-0.7c-0.191-0.276-0.342-0.597-0.452-0.965l1.401-0.551c0.103,0.397,0.283,0.726,0.54,0.987
					c0.257,0.261,0.596,0.392,1.015,0.392c0.154,0,0.303-0.02,0.447-0.061c0.143-0.04,0.272-0.099,0.386-0.176
					s0.204-0.175,0.27-0.292c0.066-0.118,0.099-0.254,0.099-0.408c0-0.147-0.026-0.279-0.077-0.397
					c-0.051-0.117-0.138-0.227-0.259-0.331c-0.121-0.103-0.28-0.202-0.475-0.298c-0.195-0.095-0.435-0.195-0.722-0.298l-0.485-0.176
					c-0.213-0.073-0.429-0.173-0.645-0.298c-0.217-0.125-0.413-0.276-0.59-0.452c-0.177-0.176-0.322-0.384-0.436-0.623
					c-0.114-0.239-0.171-0.509-0.171-0.81c0-0.309,0.06-0.597,0.182-0.866c0.121-0.268,0.294-0.503,0.518-0.706
					c0.224-0.202,0.492-0.36,0.805-0.474s0.66-0.171,1.042-0.171c0.397,0,0.74,0.053,1.031,0.16
					c0.291,0.107,0.537,0.243,0.739,0.408c0.202,0.165,0.366,0.348,0.491,0.546c0.125,0.199,0.217,0.386,0.276,0.563l-1.312,0.551
					c-0.074-0.221-0.208-0.419-0.402-0.596c-0.195-0.176-0.462-0.265-0.8-0.265c-0.324,0-0.592,0.075-0.805,0.226
					c-0.213,0.151-0.32,0.348-0.32,0.59c0,0.235,0.103,0.436,0.309,0.601c0.206,0.165,0.533,0.325,0.982,0.48l0.497,0.165
					c0.316,0.111,0.605,0.237,0.866,0.381c0.261,0.143,0.485,0.314,0.673,0.513c0.187,0.199,0.331,0.425,0.43,0.678
					c0.1,0.254,0.149,0.546,0.149,0.877c0,0.412-0.083,0.767-0.248,1.064c-0.165,0.298-0.377,0.542-0.634,0.734
					c-0.258,0.191-0.548,0.335-0.872,0.43C236.986,440.797,236.67,440.845,236.361,440.845z"/>
      <path style={{fill: '#FFFFFF'}} d="M239.516,432.771h1.61l1.633,4.754l0.287,1.015h0.088l0.309-1.015l1.699-4.754h1.61l-2.879,7.897
					h-1.566L239.516,432.771z"/>
      <path style={{fill: '#FFFFFF'}} d="M251.097,436.334h3.871c0.022,0.089,0.04,0.195,0.055,0.32c0.015,0.125,0.023,0.25,0.023,0.375
					c0,0.507-0.078,0.985-0.232,1.434c-0.154,0.449-0.397,0.849-0.728,1.202c-0.353,0.375-0.775,0.666-1.268,0.871
					c-0.493,0.206-1.055,0.309-1.688,0.309c-0.574,0-1.114-0.103-1.621-0.309c-0.507-0.206-0.949-0.492-1.324-0.86
					c-0.375-0.367-0.673-0.803-0.894-1.307c-0.22-0.504-0.331-1.053-0.331-1.649c0-0.595,0.11-1.145,0.331-1.649
					c0.221-0.504,0.518-0.939,0.894-1.307c0.375-0.367,0.816-0.654,1.324-0.86c0.507-0.206,1.047-0.309,1.621-0.309
					c0.64,0,1.211,0.11,1.715,0.331c0.504,0.221,0.936,0.526,1.296,0.915l-1.026,1.004c-0.257-0.272-0.544-0.48-0.861-0.623
					c-0.316-0.143-0.694-0.215-1.136-0.215c-0.36,0-0.702,0.065-1.026,0.193c-0.323,0.129-0.607,0.311-0.849,0.546
					c-0.243,0.235-0.436,0.52-0.579,0.855c-0.143,0.335-0.215,0.708-0.215,1.119c0,0.412,0.072,0.785,0.215,1.12
					c0.143,0.335,0.338,0.62,0.585,0.855c0.246,0.235,0.531,0.418,0.854,0.546c0.324,0.129,0.669,0.193,1.037,0.193
					c0.419,0,0.774-0.061,1.064-0.182c0.29-0.121,0.539-0.278,0.745-0.469c0.147-0.132,0.274-0.3,0.38-0.502
					c0.107-0.202,0.189-0.428,0.248-0.678h-2.482V436.334z"/>
    </g>
    <g>
      <path style={{fill: '#FFFFFF'}} d="M297.3,431.299c-0.006-1.224-0.959-2.121-2.189-2.14c-0.924-0.014-0.832,0.12-0.834-0.867
					c-0.003-1.097-0.007-2.194,0.003-3.292c0.003-0.379-0.112-0.691-0.362-0.976c-2.342-2.668-4.678-5.342-7.019-8.011
					c-0.12-0.137-0.275-0.244-0.414-0.366c-6.214,0-12.427,0-18.641,0c-0.794,0.293-1.14,0.868-1.138,1.709
					c0.01,3.748,0.004,7.496,0.004,11.243c0,0.556-0.003,0.589-0.55,0.551c-1.497-0.102-2.516,0.986-2.497,2.492
					c0.044,3.476,0.013,6.953,0.014,10.429c0.001,1.58,0.867,2.443,2.45,2.449c0.582,0.002,0.582,0.002,0.582,0.6
					c0,2.219-0.001,4.438,0.001,6.657c0.001,1.192,0.58,1.771,1.768,1.771c8.013,0.001,16.027,0.001,24.04,0
					c1.168,0,1.755-0.583,1.757-1.745c0.002-2.256,0-4.512,0.001-6.768c0-0.512,0.002-0.511,0.52-0.515
					c0.271-0.002,0.541,0.008,0.809-0.058c0.996-0.246,1.69-1.044,1.694-2.069C297.315,438.696,297.318,434.997,297.3,431.299z
					 M268.85,417.162c5.337,0,10.674,0,16.01,0c0.548,0,0.554,0.007,0.554,0.561c0.001,2.243-0.001,4.486,0.001,6.73
					c0.001,0.811,0.249,1.059,1.06,1.059c1.91,0.001,3.821,0,5.731,0.001c0.458,0,0.479,0.021,0.481,0.483
					c0.003,0.887,0.002,1.775,0,2.662c-0.001,0.477-0.024,0.498-0.504,0.499c-3.895,0.001-7.79,0-11.684,0
					c-3.882,0-7.765,0-11.647,0c-0.55,0-0.552-0.001-0.552-0.561c-0.001-3.624-0.001-7.247,0-10.871
					C268.299,417.166,268.303,417.162,268.85,417.162z M292.135,451.579c-3.883,0.001-7.766,0-11.65,0c-3.871,0-7.742,0-11.613,0
					c-0.572,0-0.574-0.002-0.574-0.579c-0.001-1.997-0.001-3.994,0.001-5.991c0-0.464,0.023-0.486,0.478-0.487
					c7.803-0.001,15.607-0.001,23.41,0c0.478,0,0.499,0.02,0.499,0.503c0.001,1.997,0.001,3.994,0,5.991
					C292.687,451.574,292.682,451.579,292.135,451.579z"/>
    </g>
    <g>
      <path style={{fill: '#FFFFFF'}} d="M272.966,434.183v1.831h3.165v1.412h-3.165v1.831h3.518v1.412h-5.007v-7.897h5.007v1.412H272.966
					z"/>
      <path style={{fill: '#FFFFFF'}} d="M277.852,440.669v-7.897h2.779c0.375,0,0.726,0.061,1.053,0.182
					c0.327,0.121,0.612,0.292,0.855,0.513c0.243,0.221,0.434,0.483,0.574,0.789c0.14,0.305,0.209,0.642,0.209,1.009
					c0,0.375-0.07,0.715-0.209,1.02c-0.14,0.305-0.331,0.568-0.574,0.789c-0.243,0.221-0.528,0.392-0.855,0.513
					c-0.327,0.121-0.678,0.182-1.053,0.182h-1.29v2.901H277.852z M280.653,436.356c0.389,0,0.684-0.111,0.882-0.331
					c0.199-0.22,0.298-0.474,0.298-0.761c0-0.14-0.024-0.276-0.072-0.408c-0.048-0.132-0.121-0.248-0.221-0.347
					c-0.099-0.099-0.222-0.178-0.37-0.237c-0.147-0.059-0.32-0.089-0.518-0.089h-1.312v2.173H280.653z"/>
      <path style={{fill: '#FFFFFF'}} d="M286.797,440.845c-0.338,0-0.662-0.048-0.971-0.143c-0.309-0.095-0.592-0.239-0.849-0.43
					c-0.257-0.191-0.482-0.425-0.673-0.7c-0.191-0.276-0.342-0.597-0.452-0.965l1.401-0.551c0.103,0.397,0.283,0.726,0.541,0.987
					c0.257,0.261,0.595,0.392,1.015,0.392c0.154,0,0.303-0.02,0.447-0.061c0.143-0.04,0.272-0.099,0.386-0.176
					c0.114-0.077,0.204-0.175,0.27-0.292c0.066-0.118,0.1-0.254,0.1-0.408c0-0.147-0.026-0.279-0.077-0.397
					c-0.051-0.117-0.138-0.227-0.259-0.331c-0.121-0.103-0.279-0.202-0.474-0.298c-0.195-0.095-0.436-0.195-0.723-0.298
					l-0.485-0.176c-0.213-0.073-0.428-0.173-0.645-0.298c-0.217-0.125-0.414-0.276-0.59-0.452c-0.176-0.176-0.322-0.384-0.436-0.623
					c-0.114-0.239-0.171-0.509-0.171-0.81c0-0.309,0.061-0.597,0.182-0.866c0.121-0.268,0.294-0.503,0.518-0.706
					c0.224-0.202,0.493-0.36,0.805-0.474c0.312-0.114,0.66-0.171,1.042-0.171c0.397,0,0.741,0.053,1.031,0.16
					c0.29,0.107,0.537,0.243,0.739,0.408c0.202,0.165,0.365,0.348,0.491,0.546c0.125,0.199,0.217,0.386,0.276,0.563l-1.312,0.551
					c-0.074-0.221-0.208-0.419-0.403-0.596c-0.195-0.176-0.462-0.265-0.799-0.265c-0.324,0-0.592,0.075-0.805,0.226
					c-0.213,0.151-0.32,0.348-0.32,0.59c0,0.235,0.103,0.436,0.309,0.601c0.206,0.165,0.533,0.325,0.982,0.48l0.496,0.165
					c0.316,0.111,0.605,0.237,0.866,0.381c0.261,0.143,0.485,0.314,0.673,0.513c0.187,0.199,0.331,0.425,0.43,0.678
					c0.099,0.254,0.149,0.546,0.149,0.877c0,0.412-0.083,0.767-0.248,1.064c-0.165,0.298-0.377,0.542-0.634,0.734
					c-0.258,0.191-0.548,0.335-0.872,0.43C287.422,440.797,287.105,440.845,286.797,440.845z"/>
    </g>
    <g>
      <path style={{fill: '#FFFFFF'}} d="M321.394,439.168l4.004-4.985h-3.827v-1.412h5.559v1.5l-3.96,4.985h4.004v1.412h-5.779V439.168z"
      />
      <path style={{fill: '#FFFFFF'}} d="M328.409,432.771h1.489v7.897h-1.489V432.771z"/>
      <path style={{fill: '#FFFFFF'}} d="M331.486,440.669v-7.897h2.779c0.375,0,0.726,0.061,1.053,0.182
					c0.327,0.121,0.612,0.292,0.855,0.513s0.434,0.483,0.574,0.789c0.14,0.305,0.209,0.642,0.209,1.009
					c0,0.375-0.07,0.715-0.209,1.02c-0.14,0.305-0.331,0.568-0.574,0.789c-0.243,0.221-0.528,0.392-0.855,0.513
					c-0.327,0.121-0.678,0.182-1.053,0.182h-1.29v2.901H331.486z M334.288,436.356c0.389,0,0.684-0.111,0.882-0.331
					c0.199-0.22,0.298-0.474,0.298-0.761c0-0.14-0.024-0.276-0.072-0.408c-0.048-0.132-0.121-0.248-0.221-0.347
					c-0.099-0.099-0.222-0.178-0.37-0.237c-0.147-0.059-0.32-0.089-0.518-0.089h-1.312v2.173H334.288z"/>
    </g>
  </g>
  <g style={{opacity:0.61}}>
    <path style={{fill: '#FFFFFF'}} d="M300.133,433.27h9.067v2.242h-9.067V433.27z M300.133,437.136h9.067v2.243h-9.067V437.136z"/>
  </g>
</g>
  <g style={{opacity:0.61}}>
    <path style={{fill: '#FFFFFF'}} d="M156.756,478.878h1.607c1.263,0,2.114,0.812,2.114,1.974c0,1.161-0.851,1.973-2.114,1.973h-1.607
			V478.878z M158.34,482.464c1.049,0,1.725-0.665,1.725-1.612c0-0.947-0.677-1.613-1.725-1.613h-1.167v3.225H158.34z"/>
    <path style={{fill: '#FFFFFF'}} d="M165.139,480.851c0-1.15,0.879-2.007,2.075-2.007c1.184,0,2.069,0.851,2.069,2.007
			c0,1.156-0.885,2.007-2.069,2.007C166.019,482.858,165.139,482.002,165.139,480.851z M168.866,480.851
			c0-0.942-0.705-1.635-1.652-1.635c-0.953,0-1.663,0.693-1.663,1.635c0,0.941,0.71,1.635,1.663,1.635
			C168.162,482.486,168.866,481.793,168.866,480.851z"/>
    <path style={{fill: '#FFFFFF'}} d="M179.596,478.878l-1.331,3.947h-0.44l-1.156-3.366l-1.161,3.366h-0.434l-1.331-3.947h0.429
			l1.139,3.394l1.178-3.394h0.389l1.161,3.411l1.156-3.411H179.596z"/>
    <path style={{fill: '#FFFFFF'}} d="M187.731,478.878v3.947h-0.344l-2.537-3.202v3.202h-0.417v-3.947h0.344l2.543,3.203v-3.203H187.731
			z"/>
    <path style={{fill: '#FFFFFF'}} d="M193.093,478.878h0.417v3.586h2.21v0.361h-2.627V478.878z"/>
    <path style={{fill: '#FFFFFF'}} d="M200.061,480.851c0-1.15,0.879-2.007,2.075-2.007c1.184,0,2.069,0.851,2.069,2.007
			c0,1.156-0.885,2.007-2.069,2.007C200.941,482.858,200.061,482.002,200.061,480.851z M203.788,480.851
			c0-0.942-0.705-1.635-1.652-1.635c-0.953,0-1.663,0.693-1.663,1.635c0,0.941,0.71,1.635,1.663,1.635
			C203.083,482.486,203.788,481.793,203.788,480.851z"/>
    <path style={{fill: '#FFFFFF'}} d="M211.636,481.77h-2.199l-0.474,1.054h-0.434l1.804-3.947h0.412l1.804,3.947h-0.44L211.636,481.77z
			 M211.484,481.432l-0.947-2.12l-0.947,2.12H211.484z"/>
    <path style={{fill: '#FFFFFF'}} d="M217.28,478.878h1.607c1.263,0,2.114,0.812,2.114,1.974c0,1.161-0.851,1.973-2.114,1.973h-1.607
			V478.878z M218.864,482.464c1.049,0,1.725-0.665,1.725-1.612c0-0.947-0.677-1.613-1.725-1.613h-1.167v3.225H218.864z"/>
    <path style={{fill: '#FFFFFF'}} d="M234.041,481.77h-2.199l-0.474,1.054h-0.434l1.804-3.947h0.412l1.804,3.947h-0.44L234.041,481.77z
			 M233.889,481.432l-0.947-2.12l-0.947,2.12H233.889z"/>
    <path style={{fill: '#FFFFFF'}} d="M239.685,478.878h0.417v3.586h2.21v0.361h-2.627V478.878z"/>
    <path style={{fill: '#FFFFFF'}} d="M247.082,478.878h0.417v3.586h2.21v0.361h-2.627V478.878z"/>
    <path style={{fill: '#FFFFFF'}} d="M260.449,479.239v1.551h2.041v0.361h-2.041v1.675h-0.417v-3.947h2.706v0.361H260.449z"/>
    <path style={{fill: '#FFFFFF'}} d="M267.678,478.878h0.417v3.947h-0.417V478.878z"/>
    <path style={{fill: '#FFFFFF'}} d="M273.456,478.878h0.417v3.586h2.21v0.361h-2.627V478.878z"/>
    <path style={{fill: '#FFFFFF'}} d="M283.644,482.464v0.361h-2.791v-3.947h2.706v0.361h-2.289v1.404h2.041v0.355h-2.041v1.466H283.644z
			"/>
    <path style={{fill: '#FFFFFF'}} d="M294.672,479.239v1.551h2.041v0.361h-2.041v1.675h-0.417v-3.947h2.706v0.361H294.672z"/>
    <path style={{fill: '#FFFFFF'}} d="M301.5,480.851c0-1.15,0.879-2.007,2.075-2.007c1.184,0,2.069,0.851,2.069,2.007
			c0,1.156-0.885,2.007-2.069,2.007C302.379,482.858,301.5,482.002,301.5,480.851z M305.226,480.851
			c0-0.942-0.705-1.635-1.652-1.635c-0.953,0-1.663,0.693-1.663,1.635c0,0.941,0.71,1.635,1.663,1.635
			C304.522,482.486,305.226,481.793,305.226,480.851z"/>
    <path style={{fill: '#FFFFFF'}} d="M313.351,482.825l-0.902-1.269c-0.102,0.011-0.203,0.017-0.316,0.017h-1.06v1.252h-0.417v-3.947
			h1.477c1.004,0,1.613,0.507,1.613,1.353c0,0.62-0.327,1.054-0.902,1.24l0.964,1.353H313.351z M313.328,480.231
			c0-0.632-0.417-0.992-1.207-0.992h-1.048v1.979h1.048C312.911,481.218,313.328,480.851,313.328,480.231z"/>
    <path style={{fill: '#FFFFFF'}} d="M322.907,478.878v3.947h-0.4v-3.158l-1.551,2.656h-0.197l-1.551-2.639v3.14h-0.4v-3.947h0.344
			l1.714,2.926l1.697-2.926H322.907z"/>
    <path style={{fill: '#FFFFFF'}} d="M330.744,481.77h-2.199l-0.473,1.054h-0.434l1.804-3.947h0.411l1.804,3.947h-0.44L330.744,481.77z
			 M330.592,481.432l-0.947-2.12l-0.947,2.12H330.592z"/>
    <path style={{fill: '#FFFFFF'}} d="M336.957,479.239h-1.387v-0.361h3.192v0.361h-1.387v3.586h-0.417V479.239z"/>
    <path style={{fill: '#FFFFFF'}} d="M343.063,482.346l0.164-0.321c0.282,0.276,0.784,0.479,1.302,0.479c0.739,0,1.06-0.31,1.06-0.699
			c0-1.083-2.43-0.417-2.43-1.877c0-0.581,0.451-1.083,1.455-1.083c0.446,0,0.908,0.13,1.224,0.35l-0.141,0.333
			c-0.338-0.22-0.733-0.327-1.083-0.327c-0.722,0-1.043,0.321-1.043,0.716c0,1.083,2.43,0.429,2.43,1.866
			c0,0.581-0.462,1.077-1.472,1.077C343.937,482.858,343.356,482.65,343.063,482.346z"/>
  </g>
</g>
  <g>
  </g>
  <g>
    <g>
      <g id="g942" transform="matrix(1.3719271,0,0,1.3719271,169.4857,-38.493576)">
        <g id="g71" transform="matrix(3.3097462,0,0,3.3097462,-688.02908,65.088537)">

          <linearGradient id="path69_00000099622257553990214210000008263016700241757588_" gradientUnits="userSpaceOnUse" x1="140.8855" y1="97.7027" x2="220.9184" y2="98.3226" gradientTransform="matrix(5.5697 0 0 -5.5697 -608.3595 588.3176)">
            <stop  offset="9.999999e-08" style={{stopColor: '#FFB900'}}/>
            <stop  offset="0.3376" style={{stopColor: '#F38000'}}/>
            <stop  offset="0.5673" style={{stopColor: '#F83C72'}}/>
            <stop  offset="0.78" style={{stopColor: '#FC1CBE'}}/>
            <stop  offset="0.93" style={{stopColor: '#FE08ED'}}/>
            <stop  offset="1" style={{stopColor: '#FF00FF'}}/>
          </linearGradient>
          <path id="path69" style={{fill: 'url(#path69_00000099622257553990214210000008263016700241757588_)'}} d="M225.633,84.099
					c-0.704,0.005-1.396-0.175-2.009-0.522c-5.242-3.033-44.066-27.376-45.532-28.119c-1.116-0.521-1.878-1.587-2.009-2.812V18.501
					c-0.029-1.274,0.601-2.474,1.667-3.173l0.281-0.161c3.756-2.33,16.329-10.002,18.317-11.107
					c0.456-0.271,0.976-0.416,1.507-0.422c0.497,0.006,0.986,0.13,1.426,0.361c0,0,17.594,11.469,20.286,12.493
					c1.888,0.868,3.948,1.3,6.025,1.265c2.355,0.049,4.681-0.519,6.748-1.647c2.631-1.386,19.442-12.051,19.623-12.051
					c0.424-0.256,0.911-0.388,1.406-0.382c0.531,0.003,1.051,0.148,1.506,0.422c2.29,1.265,17.876,10.806,18.538,11.227
					c1.096,0.661,1.761,1.853,1.748,3.133v34.145c-0.123,1.228-0.887,2.298-2.008,2.812c-1.466,0.803-40.17,25.146-45.513,28.119
					C227.033,83.898,226.34,84.092,225.633,84.099"/>
        </g>
        <g id="g79" transform="matrix(3.3097462,0,0,3.3097462,-688.02908,65.088537)">

          <linearGradient id="path77_00000023281040928281621430000006127223861845921671_" gradientUnits="userSpaceOnUse" x1="145.8389" y1="91.9334" x2="227.4359" y2="137.1129" gradientTransform="matrix(5.5697 0 0 -5.5697 -608.3595 588.3176)">
            <stop  offset="0" style={{stopColor: '#EE0A3F'}}/>
            <stop  offset="1" style={{stopColor: '#EE0A3F', stopOpacity: 0}}/>
          </linearGradient>
          <path id="path77" style={{fill: 'url(#path77_00000023281040928281621430000006127223861845921671_)'}} d="M225.432,84.099h0.201
					c0.704,0.005,1.396-0.175,2.008-0.522c5.242-3.033,44.046-27.376,45.513-28.119c1.121-0.514,1.885-1.585,2.008-2.812V18.501
					c0.007-0.578-0.124-1.149-0.382-1.667l-49.349,27.094V84.099"/>
        </g>
        <g id="g970" transform="matrix(-3.2744849,0,0,3.2744849,-467.18522,65.934126)">

          <linearGradient id="path968_00000081625354636100831810000013800948749179321772_" gradientUnits="userSpaceOnUse" x1="-67.1887" y1="95.5176" x2="-27.3341" y2="127.6227" gradientTransform="matrix(-5.5104 0 0 -5.5104 -519.5161 586.6527)">
            <stop  offset="0" style={{stopColor: '#ED6600'}}/>
            <stop  offset="1" style={{stopColor: '#F98200'}}/>
          </linearGradient>
          <path id="path968" style={{fill: 'url(#path968_00000081625354636100831810000013800948749179321772_)'}} d="M-160.242,84.26h0.201
					c0.704,0.005,1.396-0.175,2.008-0.522c5.242-3.033,44.046-27.376,45.512-28.119c1.121-0.514,1.885-1.584,2.008-2.812V18.662
					c0.007-0.578-0.124-1.149-0.382-1.667l-49.349,27.095V84.26"/>
        </g>
      </g>
      <g>
        <g id="g35_1_" transform="matrix(12.509277,0,0,12.509277,438.06846,8.29825)">
          <path id="path33_1_" style={{fill: '#FFFFFF'}} d="M-23.79,14.171h1.633v7.859h2.816v1.551h-4.442L-23.79,14.171"/>
        </g>
        <g id="g41_1_" transform="matrix(12.509277,0,0,12.509277,438.06846,8.29825)">
          <path id="path39_1_" style={{fill: '#FFFFFF'}} d="M-15.558,16.529c0.865-0.011,1.686,0.384,2.218,1.066v-0.926h1.524v6.927h-1.524
					v-0.926c-0.532,0.683-1.353,1.077-2.218,1.066c-1.9,0-3.342-1.623-3.342-3.605C-18.9,18.148-17.458,16.529-15.558,16.529
					 M-15.281,17.916c-1.234,0-2.051,0.971-2.051,2.214c0,1.244,0.83,2.218,2.051,2.218c1.22,0,2.05-0.97,2.05-2.218
					C-13.231,18.883-14.048,17.916-15.281,17.916"/>
        </g>
        <g id="g47_1_" transform="matrix(12.509277,0,0,12.509277,438.06846,8.29825)">
          <path id="path45_1_" style={{fill: '#FFFFFF'}} d="M-10.631,22.392l3.632-4.34h-3.578v-1.384h5.546v1.22l-3.619,4.323h3.701v1.384
					h-5.683V22.392"/>
        </g>
      </g>
    </g>
  </g>
</svg>


)
}
