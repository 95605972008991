import { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { DATE_FORMAT } from 'consts';

import DateFilter from 'components/DateFilter/WithoutForm';
import ToggleBox from 'components/ToggleBox';

/**
 * DateTime component for selecting date and time ranges.
 * @param {Object} props - The props object.
 * @param {Array<string>} [props.value] - The selected date and time range represented as an array of two strings in DATE_FORMAT.
 * @param {Function} [props.onChange=() => {}] - The function to handle changes in the selected date and time range.
 * @param {string} [props.className=''] - Additional classes for styling.
 * @returns {JSX.Element} A React component representing the DateTime component.
 */
export default function DateTime({
  value = [
    moment().subtract(2, 'days').format(DATE_FORMAT),
    moment().add(2, 'days').format(DATE_FORMAT)
  ],
  onChange = () => { },
  className,
}) {
  const wrapper = useRef(null);
  const dateWrapper = useRef(null);

  const onDateChange = (value) => {
    onChange(value);
  };

  const closeDateFilter = () => {
    if (wrapper.current) {
      wrapper.current.toggle();
    }
  };

  const onClose = () => {
    if (dateWrapper.current) {
      dateWrapper.current.reset();
    }
  };

  return (
    <ToggleBox ref={wrapper} onClose={onClose}>
      <ToggleBox.Button>
        <div className={`rounded-full flex justify-between items-center border bg-blue-100 px-2 py-0.5 cursor-pointer me-3 ${className}`}>
          <div className='flex flex-row items-center'>
            <FontAwesomeIcon className='h-3 w-3 me-1.5' icon={faClock} />
            <div className='select-none mb-0.5'>{moment(value[0]).format('MMM DD')} - {moment(value[1]).format('MMM DD')}</div>
          </div>
        </div>
      </ToggleBox.Button>

      <ToggleBox.Content>
        <DateFilter ref={dateWrapper} onChange={onDateChange} onClose={closeDateFilter} value={value} />
      </ToggleBox.Content>
    </ToggleBox>
  );
};
