export const COLORS = [
  '#3b82f6',   // Blue
  '#ef4444',   // Red
  '#10b981',   // Emerald
  '#6366f1',   // Indigo
  '#22c55e',   // Green
  '#f59e0b',   // Amber
  // '#ec4899',   // Pink
  '#06b6d4',   // Cyan
  '#d946ef',   // Fuchsia
  '#14b8a6',   // Teal
  '#a855f7',   // Purple
  '#84cc16',   // Lime
  // '#8b5cf6',   // Violet
  // '#f97316',   // Orange
  // '#ecc94b',   // Yellow
  // '#6b7280',   // Gray
];

export const DS_CHART_COLORS = [
  '#8B5CF6',
  '#C4B5FD',
  '#FB923C',
  '#FACC15',
  '#FB533C',
  '#FDB5D3',
  '#2454FF',
  '#08F0FF',
  '#00C0EB',
  '#BA63EF'
];