import { useDispatch, useSelector } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getProfile, setLoginSuccess } from "store/userSlice";
import { PATHS, EMAIL_REGEX } from "consts";
import { useEffect } from "react";
import TextInput from "components/TextInput";
import { showError } from "components/FlashMessage/flashMessageSlice";
import UserService from "services/user";
import { Header } from "../landing_page/home/Header";
import { Faqs } from "../landing_page/home/Faqs";
import { Footer } from "../landing_page/home/Footer";
import LPTextBlock from "views/landing_page/LPTextBlock";

export default function Login() {
  const { t } = useTranslation();
  const methods = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");

  const isLogin = useSelector((state) => !!state.user.refreshToken);

  useEffect(() => {
    if (isLogin) {
      navigate(PATHS.APP);
    }
  }, [isLogin]);

  const onSubmit = async (data) => {
    try {
      if (token) {
        data.token = token;
      }

      const result = await UserService.login(data);
      dispatch(setLoginSuccess(result));
      dispatch(getProfile());
    } catch (error) {
      dispatch(showError({ message: "Invalid username or password" }));
    }
  };

  return (
    <>
      <Header />
      {/*mx-auto max-w-7xl*/}
      <div
        style={{
          background: "url(https://static.datasenses.io/datasense-bg.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
      <div className={"px-4"}>
        <main className={"pt-56 lg:pt-56 mx-auto max-w-7xl pb-32"}>
          <div className={"grid grid-rows-1 gap-y-36"}>
            <div className={""}>
              <div className={"flex grid grid-cols-1 lg:grid-cols-2 gap-8 items-center"}>
                <LPTextBlock
                  title={"Sign in to your account"}
                  description={
                    "Securely manage your data, track insights, and stay connected."
                  }
                  type={"bg_light"}
                />
                <div className="w-full glass-effect justify-center">
                  <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                      {t("SIGN_IN_TO_YOUR_ACCOUNT")}
                    </h1>
                    <FormProvider {...methods}>
                      <form
                        onSubmit={methods.handleSubmit(onSubmit)}
                        className="space-y-4 md:space-y-6"
                      >
                        <div>
                          <TextInput
                            label={t("YOUR_EMAIL")}
                            name="email"
                            className="w-full"
                            rules={{
                              required: t("VALUE_IS_REQUIRED", {
                                Value: t("YOUR_EMAIL"),
                              }),
                              pattern: {
                                value: EMAIL_REGEX,
                                message: t("INVALID_EMAIL_ADDRESS"),
                              },
                            }}
                          />
                        </div>
                        <div>
                          <TextInput
                            label={t("PASSWORD")}
                            name="password"
                            type="password"
                            className="w-full"
                            rules={{
                              required: t("VALUE_IS_REQUIRED", {
                                Value: t("PASSWORD"),
                              }),
                            }}
                          />
                        </div>
                        <div className="flex items-center justify-between">
                          <div></div>
                          <Link className="text-sm font-medium text-primary-600 hover:underline">
                            {t("FORGOT_PASSWORD")}?
                          </Link>
                        </div>
                        <button className="ds-button-3">{t("SIGN_IN")}</button>
                        <p className="text-sm font-light text-gray-500">
                          {t("DONT_HAVE_AN_ACCOUNT_YET")}?{" "}
                          <Link
                            to={PATHS.REQUEST_DEMO}
                            className="font-medium text-primary-600 hover:underline"
                          >
                            {t("Request a demo")}
                          </Link>
                        </p>
                      </form>
                    </FormProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <div>
          <Faqs />
        </div>
      </div>
      <Footer />
      </div>
    </>
  );
}
