import datasenses from 'datasenses-web-sdk';
import appService from 'services/app';

const channels = [
  {
    channel: "direct",
    link: "http://localhost:3000/mock",
  },
  {
    channel: "google",
    link: "http://localhost:3000/mock?utm_source=google&utm_medium=gg_uatdevelopment&utm_campaign=gg_testing",
  },
  {
    channel: "facebook",
    link: "http://localhost:3000/mock?utm_source=facebook&utm_medium=fb_uatdevelopment&utm_campaign=fb_testing",
  },
  {
    channel: "affiliate",
    link: "http://localhost:3000/mock?utm_source=affiliate&utm_medium=aff_uatdevelopment&utm_campaign=aff_testing",
  },
  {
    channel: "seo",
    link: "http://localhost:3000/mock",
  },
  {
    channel: "facebook Iphone",
    link: "http://localhost:3000/mock?utm_source=facebook&utm_medium=fb_uatdevelopment&utm_campaign=fb_testing&utm_item_id=1",
  },
  {
    channel: "google Xe Sh",
    link: "http://localhost:3000/mock?utm_source=google&utm_medium=gg_uatdevelopment&utm_campaign=gg_testing&utm_item_id=2",
  },
]
const products = [
  {
    item_id: "1",
    name: "Iphone promax 15",
    price: 24000000,
    qty: 1,
    category: "Điện thoại",
  },
  {
    item_id: "2",
    name: "Xe máy sh",
    price: 119000000,
    qty: 1,
    category: "Xe máy"
  },
  {
    item_id: "3",
    name: "Samsung galaxy 3",
    price: 19000000,
    qty: 1,
    category: "Điện thoại"
  },
  {
    item_id: "4",
    name: "Sách tony buổi sáng",
    price: 100000,
    qty: 2,
    category: "Sách"
  },
]

export default function Mock() {
  const viewProduct = (product) => {
    datasenses.event.push('product_viewed', product)
  }
  const addToCart = (product) => {
    datasenses.event.push('add_to_cart', product)
  }

  const placeOrder = (product) => {
    datasenses.event.push('charged', {
      "items": [product],
      "charged_id": Math.floor(Math.random() * 10000) + 1,
      "amount": product.price
    })
  }

  const placeOrderWithManyItems = () => {
    datasenses.event.push('charged', {
      "items": [
        {
          item_id: "4",
          name: "Sách tony buổi sáng",
          price: 100000,
          qty: 2,
          category: "Sách"
        },
        {
          item_id: "1",
          name: "Iphone promax 15",
          price: 24000000,
          qty: 1,
          category: "Điện thoại",
        },
      ],
      "charged_id": Math.floor(Math.random() * 10000) + 1,
    })
  }

  const utmVisited = (channel, link) => {
    window.location.href = link
  }

  return (
    <>
      <div className="bg-white flex flex-row rounded shadow-md w-full mt-5 py-2 px-3">
        <button
          className="rounded mr-5 bg-red-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={() => {
            appService.integrateHaravan()
          }}
        >
          Mock Oauth Haravan
        </button>

        {channels.map((c) => (
          <button
            type="button"
            onClick={() => utmVisited(c.channel, c.link)}
            className="rounded mr-5 bg-pink-400 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Traffic {c.channel}
          </button>
        ))}
      </div>
      <div className="bg-white flex flex-row rounded shadow-md w-full mt-5 py-2 px-3">
        {products.map((p) => (
          <button
            type="button"
            onClick={() => viewProduct(p)}
            className="rounded mr-5 bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            View {p.name}
          </button>
        ))}
      </div>

      <div className="bg-white flex flex-row rounded shadow-md w-full mt-5 py-2 px-3">
        {products.map((p) => (
          <button
            type="button"
            onClick={() => addToCart(p)}
            className="rounded mr-5 bg-green-400 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Add to cart {p.name}
          </button>
        ))}
      </div>
      <div className="bg-white flex flex-row rounded shadow-md w-full mt-5 py-2 px-3">
        {products.map((p) => (
          <button
            type="button"
            onClick={() => placeOrder(p)}
            className="rounded mr-5 bg-rose-300 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Place order {p.name}
          </button>
        ))}
      </div>
      <div className="bg-white flex flex-row rounded shadow-md w-full mt-5 py-2 px-3">
          <button
            type="button"
            onClick={() => placeOrderWithManyItems()}
            className="rounded mr-5 bg-rose-300 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Place order Tony buổi sáng & iPhone
          </button>
      </div>
    </>
  )
}
