import { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import ToggleBox from 'components/ToggleBox';


import './index.scss';

/**
 * MultiSelectOption component for managing multi-select options.
 * @param {Object} props - The props object.
 * @param {Array} [props.value=[]] - The selected values.
 * @param {Function} [props.onChange=()=>{}] - The onChange event handler.
 * @param {Array<{ value: any, text: string }>} [props.options=[]] - An array of options for the multi-select.
 * @param {string} [props.className=''] - Additional classes for styling.
 * @returns {JSX.Element} A React component representing the multi-select option.
 */
export default function MultiSelectOptionWithoutForm({
  value = [],
  onChange = () => { },
  options = [],
  className,
}) {
  const wrapper = useRef();

  const addData = (item) => {
    onChange([...value, item]);
    wrapper.current.toggle();
  };

  const deleteData = (e, item) => {
    e.stopPropagation();

    const newValue = [...value];

    const indexToDelete = value.indexOf(item);

    newValue.splice(indexToDelete, 1);

    onChange(newValue);
  };

  const getText = (targetValue) => {
    const data = options.find(obj => obj.value === targetValue);

    if (data) {
      return data.text;
    } else {
      return null;
    }
  };

  const checkData = () => {
    if (value.length === options.length) {
      setTimeout(() => wrapper.current.close());
    }
  }

  return (
    <ToggleBox className="w-full ds-violet-border" ref={wrapper}>
      <ToggleBox.Button>
        <div onClick={checkData} className={`multi-select-option ds-input flex flex-wrap ${className} ds-violet-border `}>
          {Array.isArray(value) && value.map((item, index) => (
            <div
              className='rounded-md text-sm px-2 border border-gray-300 w-min flex flex-row items-center select-none me-1 value'
              key={index}
            >
              <span className='whitespace-nowrap'>{getText(item)}</span>

              <FontAwesomeIcon onClick={(e) => deleteData(e, item)} className='icon cursor-pointer hover:text-red-400' icon={faXmark}/>
            </div>
          ))}
        </div>
      </ToggleBox.Button>

      <ToggleBox.Content>
        <div className="py-2 px-1 max-h-60 overflow-auto">
          {Array.isArray(options) && options.map(item => (
            !value.includes(item.value) &&
            <div className='w-full px-3 py-0.5 hover:bg-gray-200 cursor-pointer' key={item.value} onClick={() => addData(item.value)}>{item.text}</div>
          ))}
        </div>
      </ToggleBox.Content>
    </ToggleBox>
  );
}
