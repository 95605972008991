import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, Outlet, useLocation, useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {PATHS, PLATFORM, STATUS} from "consts";
import {getApps} from 'store/appSlice';
import {isUndefinedOrEmpty} from 'utils';
import AppHeader from 'components/AppHeader';
import SidePanel from 'components/SidePanel';
import {DsIconAndroid, DsIconDashboard, DsIconSetting, DsIconWebPlatform} from "components/DsIcon";

import './index.scss';
import CreateApp from "./create_app";
import AppFormCreation from "views/homepage/create_app/app_form";
import {faApple} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquarePlus} from "@fortawesome/free-solid-svg-icons";


const PATHS_NO_APP_ID = [
  PATHS.APP,
  PATHS.APP_EMPTY,
  PATHS.ACCOUNT_SETTINGS,
  `${PATHS.APP}${PATHS.PARTNER_MARKETPLACE}`,
  `${PATHS.APP}${PATHS.INTEGRATION}/google`,
  `${PATHS.APP}${PATHS.INTEGRATION}/meta`,
  `${PATHS.APP}${PATHS.INTEGRATION}/tiktok`,
  `${PATHS.APP}${PATHS.INTEGRATION}/apple_search`,
  `${PATHS.APP}${PATHS.INTEGRATION}/haravan`,
  `${PATHS.APP}${PATHS.INTEGRATION}/tiktok-shop`,
  `${PATHS.APP}${PATHS.INTEGRATION}/shopify`,
  `${PATHS.APP}${PATHS.INTEGRATION}/lazada`,
];


export default function Homepage() {
  const isLogin = useSelector((state) => !!state.user.refreshToken);
  const location = useLocation();
  const apps = useSelector((state) => state.app.appList);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {appId} = useParams();

  const [isCreateAppOpen, setIsCreateAppOpen] = useState(false);


  const navigateOverview = (appId) => {
    navigate(`${PATHS.APP}/${appId}${PATHS.OVERVIEW}`);
  }

  const navigateSetting = (appId) => {
    navigate(`${PATHS.APP}/${appId}`);
  }

  const listApps = () => dispatch(getApps());

  useEffect(() => {
    if (!isLogin) {
      navigate(PATHS.LOGIN);
    } else {
      listApps();
    }
  }, [isLogin]);

  useEffect(() => {
    const conditions = [
      apps.length === 0,
      isUndefinedOrEmpty(appId),
      !PATHS_NO_APP_ID.includes(location.pathname),
    ];

    // if (conditions.every(item => item === true)) {
    //   navigate(PATHS.APP_EMPTY);
    // }
  }, [appId, apps, location]);

  const handleOpenOption = (e) => {
    e.stopPropagation();
  }
  return (
    <div id="homepage">
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-56 lg:flex-col">
        <SidePanel/>
      </div>
      <div className="lg:pl-56">
        <AppHeader/>
        <main className="main">
          <div className="px-4 sm:px-6 lg:px-8">
            {
              location.pathname === PATHS.APP ?
                <div>
                  <div className="flex justify-between ds-block-title">
                    <div className="ds-text-page-title">
                      {t('MY_APP')}
                    </div>
                    <div className={`items-center`}>
                      <button
                        onClick={() => setIsCreateAppOpen(true)}
                        className="ds-button"
                      >
                        <FontAwesomeIcon icon={faSquarePlus} className="me-2"/>
                        {t('CREATE_APP')}
                      </button>
                  </div>
                  </div>

                  {apps.length > 0 ?
                    <div>
                      <div>
                        <ul role="list" className="grid grid-cols-1 ds-spacing-4 sm:grid-cols-2 lg:grid-cols-3 mt-2">
                          {apps.map((app) => (
                            <li key={app.id} className="col-span-1 divide-y divide-gray-200 ds-block-with-hover">
                              <Link to={`${PATHS.APP}/${app.appId}`}>
                                <div className="flex w-full items-center justify-between p-4">
                                  <div className="flex-1 truncate">
                                    <div className="flex items-center space-x-4">
                                      <div className="h-5 w-5">
                                        {
                                          (() => {
                                            if (app.platform === PLATFORM.ANDROID) {
                                              return <DsIconAndroid/>
                                            } else if (app.platform === PLATFORM.IOS) {
                                              return <FontAwesomeIcon className='w-full h-full ds-icon-gray' icon={faApple}/>
                                            } else {
                                              return <>
                                                <DsIconWebPlatform/>
                                              </>
                                            }
                                          })()
                                        }
                                      </div>
                                      <h3 className="truncate text-sm font-medium text-gray-800">{app.name}</h3>
                                    </div>
                                    <div className="mt-1 flex items-center space-x-3">
                                      <div className="h-5 w-5"/>
                                      {app.status === STATUS.APP_STATUS_ACTIVE ? <span
                                                className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                        Active
                                      </span> : <span
                                                className="inline-flex flex-shrink-0 items-center rounded-full bg-red-50 px-1.5 py-0.5 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                                        Inactive
                                      </span>
                                      }
                                    </div>
                                  </div>
                                  {
                                    app.platform !== PLATFORM.WEB && app.logo &&
                                    <img className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300" src={app.logo}
                                         alt=""/>
                                  }
                                </div>
                              </Link>

                              <div>
                                <div className="-mt-px cursor-pointer flex divide-x divide-gray-200">
                                  <div className="flex w-0 flex-1">
                                    <a
                                      onClick={() => navigateOverview(app.appId)}
                                      className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 ds-input-text"
                                    >
                                      <div className={`w-4 h-4`}>
                                        <DsIconDashboard height={20} width={20} aria-hidden="true"/>
                                      </div>
                                      {t('OVERVIEW')}
                                    </a>
                                  </div>
                                  <div className="-ml-px flex w-0 flex-1">
                                    <a
                                      onClick={() => navigateSetting(app.appId)}
                                      className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 ds-input-text"
                                    >
                                      <div className={`w-4 h-4`}>
                                        <DsIconSetting height={25} width={25} aria-hidden="true"/>
                                      </div>
                                      {t('Settings')}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    :
                    <div className={"grid lg:grid-cols-2"}>
                      <AppFormCreation/>
                    </div>
                  }
                </div>
                :
                <Outlet/>
            }
          </div>
        </main>
      </div>

      <CreateApp
        isOpen={isCreateAppOpen}
        reset={listApps}
        closeModal={() => setIsCreateAppOpen(false)}
      />
    </div>
  );
};
