import {
  timeType,
} from "../../types.js";

export const processGetIntegrationData = (data) => {
  if (data.appIntegrationFacebook.status === 'INTEGRATION_PARTNER_ACTIVE') {
    data.appIntegrationFacebook.active = true
  } else {
    data.appIntegrationFacebook.active = false
  }

  if (data.appIntegrationFacebook.installClickThroughLookbackDay > 0) {
    data.appIntegrationFacebook.clickThrough = data.appIntegrationFacebook.installClickThroughLookbackDay
    data.appIntegrationFacebook.clickThroughType = timeType.Days
  } else {
    data.appIntegrationFacebook.clickThrough = data.appIntegrationFacebook.installClickThroughLookbackHour
    data.appIntegrationFacebook.clickThroughType = timeType.Hours
  }

  if (!!data.appIntegrationFacebook.inAppEventPostbacks) {
    if (data.appIntegrationFacebook.inAppEventPostbacks.length > 0) {
      data.appIntegrationFacebook.inAppEventPostbacksEnabled = true
    } else {
      data.appIntegrationFacebook.inAppEventPostbacksEnabled = false
    }

    for (let key = 0; key < data.appIntegrationFacebook.inAppEventPostbacks.length; key ++) {
      if (data.appIntegrationFacebook.inAppEventPostbacks[key].valueIncluded &&
        data.appIntegrationFacebook.inAppEventPostbacks[key].revenueIncluded) {
        data.appIntegrationFacebook.inAppEventPostbacks[key].including = 'value_and_revenue';
      } else if (data.appIntegrationFacebook.inAppEventPostbacks[key].valueIncluded &&
        !data.appIntegrationFacebook.inAppEventPostbacks[key].revenueIncluded) {
        data.appIntegrationFacebook.inAppEventPostbacks[key].including = 'value_and_no_revenue';
      } else if (!data.appIntegrationFacebook.inAppEventPostbacks[key].valueIncluded &&
        !data.appIntegrationFacebook.inAppEventPostbacks[key].revenueIncluded) {
        data.appIntegrationFacebook.inAppEventPostbacks[key].including = 'no_value_and_no_revenue';
      }
    }
  }

  return data;
}
