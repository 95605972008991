import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from 'react-redux';
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight, faSquarePlus, faTrashCan,} from "@fortawesome/free-solid-svg-icons";

import Tooltip from "components/Tooltip";
import ToggleCheckbox from "components/ToggleCheckbox";
import TextInput from "components/TextInput";
import RangeInput from "components/RangeSlider";
import TailwindCombobox from "components/TailwindCombobox";

import './index.scss';

import IntegrationService from "services/integration";
import {processGetIntegrationData} from "./processor";
import {processSaveIntegrationData} from "../../processor";

import {includingOptions, timeType, userFromOptions,} from "../../types.js";
import {showError, showInfo} from "components/FlashMessage/flashMessageSlice";
import MetadataService from "../../../../services/metadata";
import DsListBoxNoNavigate from "components/DsListBoxNoNavigate";
import {isUndefinedOrEmpty} from "utils";
import BackButton from "views/integration/meta_ads/back_button";
import {DsIconTooltip} from "components/DsIcon";
import LoadingSpinner from "components/LoadingSpinner";

export default function Integration() {
  const appList = useSelector((state) => state.app.appList);
  const [appId, setAppId] = useState(appList.length > 0 ? appList[0].app : null);
  const methods = useForm();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [initValue, setInitValue] = useState(null);
  const [datasensesEvents, setDatasensesEvents] = useState(null);
  const [partnerEvents, setPartnerEvents] = useState(null);

  const active = methods.watch('active');

  const clickThroughType = methods.watch('clickThroughType');
  const clickThrough = methods.watch('clickThrough');

  const inAppEventPostbacksEnabled = methods.watch('inAppEventPostbacksEnabled');

  const {fields, append, remove} = useFieldArray({
    control: methods.control,
    name: 'inAppEventPostbacks',
  });

  useEffect(() => {
    methods.setValue('clickThroughType', timeType.Days);
  }, []);


  const getEventsList = async () => {
    try {
      const events = await MetadataService.getFieldValues({appId, fieldKey: 'event_name'});
      const eventNames = [];

      events.map((item) => {
        eventNames.push({fieldKey: item, name: item})
      });

      setDatasensesEvents(eventNames)
      setPartnerEvents(eventNames)
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
  }

  const getAppIntegrationGoogle = async () => {
    try {
      const result = await IntegrationService.getAppIntegration({appId, partner: 'google'});

      const processedResult = processGetIntegrationData(result)
      if (processedResult.status !== 'INTEGRATION_PARTNER_INACTIVE' && !isUndefinedOrEmpty(appId)) {
        const tempValue = [
          {key: 'active', value: processedResult.appIntegrationGoogle.active},
          {key: 'linkId', value: processedResult.appIntegrationGoogle.linkId},
          {key: 'clickThroughType', value: processedResult.appIntegrationGoogle.clickThroughType},
          {key: 'clickThrough', value: processedResult.appIntegrationGoogle.clickThrough},
          {key: 'inAppEventPostbacksEnabled', value: processedResult.appIntegrationGoogle.inAppEventPostbacksEnabled},
          {key: 'inAppEventPostbacks', value: processedResult.appIntegrationGoogle.inAppEventPostbacks},
        ]

        tempValue.map((kv) => {
          methods.setValue(kv.key, kv.value);
        });

        setInitValue(tempValue);
      }
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
  }

  useEffect(() => {
    if (appId) {
      getAppIntegrationGoogle();
      getEventsList();
      discardChanges();
    }
  }, [appId]);

  const discardChanges = () => {
    if (initValue === null) {
      return;
    }
    initValue.map((kv) => {
      methods.setValue(kv.key, kv.value);
    });
  }

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      data.appId = appId;
      const payload = processSaveIntegrationData(data);
      await IntegrationService.saveIntegration({appId, partner: 'google', ...payload});
      dispatch(showInfo({message: t("SAVE_VALUE_SUCCESSFULLY", {Value: "Integration with Google"})}));

      const tempValue = [
        {key: 'active', value: data.active},
        {key: 'linkId', value: data.linkId},
        {key: 'clickThroughType', value: data.clickThroughType},
        {key: 'clickThrough', value: data.clickThrough},
        {key: 'inAppEventPostbacksEnabled', value: data.inAppEventPostbacksEnabled},
        {key: 'inAppEventPostbacks', value: data.inAppEventPostbacks},
      ]
      setInitValue(tempValue)
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
    setIsLoading(false);
  }

  return (
    <div id="integration-tab" className={"sm:p-3"}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="ds-input-text">
            <div className="ds-text-form-title">Apple Search ads integration</div>

            <div className="ds-text-lighter">Set up your integration details for this partner</div>
            <div className={"w-1/3 mt-3"}>
              <DsListBoxNoNavigate listData={appList} onSelectionChange={(a) => {
                setAppId(a.appId)
              }}/>
            </div>
            <div className="flex justify-start items-center w-full mt-3">
              <div className="me-1">Activate partner</div>


              <Tooltip minWidth="380">
                <Tooltip.Label>
                  <div><DsIconTooltip className="h-full w-full text-blue-400"
                                      aria-hidden="true"/>
                  </div>
                </Tooltip.Label>
                <Tooltip.Content>
                  <p>Activate partner to enable attribution and send postbacks.</p>
                </Tooltip.Content>
              </Tooltip>
              <ToggleCheckbox name="active" className="ms-5"/>
            </div>
            <hr className="h-px mt-3 bg-gray-200 border-0"/>

            <div className={active ? 'visible' : 'invisible h-0'}>

              <div className="">
                <div className="ds-text-form-title mt-3">General settings</div>
                <div className="flex justify-start items-center w-full mt-1">


                  <div className="mt-1">Email</div>
                  <Tooltip minWidth="400">
                    <Tooltip.Label>
                      <div><DsIconTooltip className="h-full w-full text-blue-400"
                                          aria-hidden="true"/>
                      </div>
                    </Tooltip.Label>
                    <Tooltip.Content>
                      <p>Email that linked to your Google ads account. Prefer a group email</p>
                    </Tooltip.Content>
                  </Tooltip>
                </div>


                <TextInput
                  placeholder="Email linked Google ads account. Prefer a group email"
                  name="linkId"
                  type="text"
                  className="mt-1 w-4/12"
                />

                {/*<div className="flex justify-start items-center w-full mt-1">*/}
                {/*  <div className="text-xs me-2">Link ID</div>*/}

                {/*  <Tooltip minWidth="400">*/}
                {/*    <Tooltip.Label>*/}
                {/*      <FontAwesomeIcon className="text-gray-500 text-xs" icon={faCircleInfo}/>*/}
                {/*    </Tooltip.Label>*/}
                {/*    <Tooltip.Content>*/}
                {/*      <p>The Link ID parameter created in your Google AdWords account is unique per app and mandatory for*/}
                {/*        the attribution to begin.</p>*/}
                {/*    </Tooltip.Content>*/}
                {/*  </Tooltip>*/}
                {/*</div>*/}
                {/*<TextInput name="linkID" className="mt-1 w-4/12"/>*/}

                {/*<div className="table">*/}
                {/*<div className="table-row">*/}
                {/*  <div className="table-cell pt-11">*/}
                {/*    <div className="flex justify-start items-center w-full">*/}
                {/*      <div className="text-xs me-2">Deferred deep linking with Google feeds</div>*/}

                {/*      <Tooltip minWidth="300">*/}
                {/*        <Tooltip.Label>*/}
                {/*          <FontAwesomeIcon className="text-gray-500 text-xs" icon={faCircleInfo}/>*/}
                {/*        </Tooltip.Label>*/}
                {/*        <Tooltip.Content>*/}
                {/*          <p>To enable deferred deep linking with Google, create and apply Google feeds for App*/}
                {/*            campaigns.</p>*/}
                {/*        </Tooltip.Content>*/}
                {/*      </Tooltip>*/}
                {/*    </div>*/}
                {/*  </div>*/}

                {/*  <div className="table-cell relative">*/}
                {/*    <ToggleCheckbox name="deferredDeepLinking " className="absolute bottom-0 ps-10"/>*/}
                {/*  </div>*/}
                {/*</div>*/}

                {/*<div className="table-row">*/}
                {/*  <div className="table-cell pt-11">*/}
                {/*    <div className="flex justify-start items-center w-full">*/}
                {/*      <div className="text-xs me-2">Non EU Users</div>*/}

                {/*      <Tooltip minWidth="450">*/}
                {/*        <Tooltip.Label>*/}
                {/*          <FontAwesomeIcon className="text-gray-500 text-xs" icon={faCircleInfo}/>*/}
                {/*        </Tooltip.Label>*/}
                {/*        <Tooltip.Content>*/}
                {/*          <p>When enabled, your app is informing this partner that EU privacy laws (such as DMA and*/}
                {/*            GDPR) do not apply to any of your users. This indication will be included on every event,*/}
                {/*            postback or audience sent to this partner regardless of where the user is located. This*/}
                {/*            toggle should only be used if the app has no activity, users or customers in Europe. Please*/}
                {/*            check with your legal and privacy teams to ensure this toggle is used in compliance with*/}
                {/*            your policies</p>*/}
                {/*        </Tooltip.Content>*/}
                {/*      </Tooltip>*/}
                {/*    </div>*/}
                {/*  </div>*/}

                {/*  <div className="table-cell relative">*/}
                {/*    <ToggleCheckbox name="nonEU" className="absolute bottom-0 ps-10"/>*/}
                {/*  </div>*/}
                {/*</div>*/}
                {/*</div>*/}

                <hr className="h-px my-6 bg-gray-200 border-0"/>

                {/*<div>Install attribution</div>*/}

                <div className="flex justify-start items-center w-full mt-1">
                  <div className="me-2">Install click-through lookback window</div>

                  <Tooltip minWidth="380">
                    <Tooltip.Label>
                      <div><DsIconTooltip className="h-full w-full text-blue-400"
                                          aria-hidden="true"/>
                      </div>
                    </Tooltip.Label>
                    <Tooltip.Content>
                      <p>Set the maximum time after a click-to-app that an install can be attributed to the click.</p>
                    </Tooltip.Content>
                  </Tooltip>
                </div>

                <div className="flex items-center mt-2">
                  <TextInput id="clickThroughTypeHoursRadio" name="clickThroughType" type="radio"
                             value={timeType.Hours} className="w-3 h-3 mr-2"/>
                  <label htmlFor="clickThroughTypeHoursRadio" className="mr-7 mb-1.5">
                    {t('HOURS')}
                  </label>

                  <TextInput id="clickThroughTypeDaysRadio" name="clickThroughType" type="radio" value={timeType.Days}
                             className="w-3 h-3 mr-2"/>
                  <label htmlFor="clickThroughTypeDaysRadio" className="mb-1.5">
                    {t('DAYS')}
                  </label>
                </div>

                <div className="flex justify-start items-center w-full mt-2">
                  <RangeInput
                    name="clickThrough"
                    className="w-4/12"
                    defaultValue="23"
                    max={clickThroughType === timeType.Days ? 30 : 23}
                    min="1"
                    step="1"
                  />

                  <FontAwesomeIcon className="text-blue-700 ms-3" icon={faAngleRight}/>

                  <div className="text-xs ms-1">{clickThrough} {clickThroughType}</div>
                </div>

                {/*<div className="flex justify-start items-center w-full mt-10">*/}
                {/*  <div className="text-sm me-2">Pre-registration campaign lookback window</div>*/}

                {/*  <Tooltip minWidth="380">*/}
                {/*    <Tooltip.Label>*/}
                {/*      <FontAwesomeIcon className="text-gray-500 text-xs" icon={faCircleInfo}/>*/}
                {/*    </Tooltip.Label>*/}
                {/*    <Tooltip.Content>*/}
                {/*      <p>A lookback window for Google pre-registration campaigns.</p>*/}
                {/*    </Tooltip.Content>*/}
                {/*  </Tooltip>*/}
                {/*</div>*/}

                {/*<div className="flex justify-start items-center w-full mt-2">*/}
                {/*  <RangeInput*/}
                {/*    name="preRegistration"*/}
                {/*    className="w-4/12"*/}
                {/*    defaultValue="90"*/}
                {/*    max="90"*/}
                {/*    min="1"*/}
                {/*    step="1"*/}
                {/*  />*/}

                {/*  <FontAwesomeIcon className="text-blue-700 ms-3" icon={faAngleRight}/>*/}

                {/*  <div className="text-xs lower ms-1">{preRegistration} {t('DAYS')}</div>*/}
                {/*</div>*/}

                {/*<div className="flex justify-start items-center w-full mt-10">*/}
                {/*  <div className="text-xs me-2">Install view-through attribution</div>*/}

                {/*  <Tooltip minWidth="380">*/}
                {/*    <Tooltip.Label>*/}
                {/*      <FontAwesomeIcon className="text-gray-500 text-xs" icon={faCircleInfo}/>*/}
                {/*    </Tooltip.Label>*/}
                {/*    <Tooltip.Content>*/}
                {/*      <p>Turn on to start attributing installs by users who view your ad, but don't click it</p>*/}
                {/*    </Tooltip.Content>*/}
                {/*  </Tooltip>*/}

                {/*  <ToggleCheckbox name="installViewThroughAttribution" className="ms-10"/>*/}
                {/*</div>*/}

                {/*{*/}
                {/*  installViewThroughAttribution &&*/}
                {/*  <>*/}
                {/*    <div className="flex justify-start items-center w-full mt-3">*/}
                {/*      <div className="text-xs me-2">Install view-through lookback window</div>*/}

                {/*      <Tooltip minWidth="380">*/}
                {/*        <Tooltip.Label>*/}
                {/*          <FontAwesomeIcon className="text-gray-500 text-xs" icon={faCircleInfo}/>*/}
                {/*        </Tooltip.Label>*/}
                {/*        <Tooltip.Content>*/}
                {/*          <p>Set the maximum time after a view that an install can be attributed to the view.</p>*/}
                {/*        </Tooltip.Content>*/}
                {/*      </Tooltip>*/}
                {/*    </div>*/}

                {/*    <div className="flex justify-start items-center mt-5">*/}
                {/*      <TextInput id="installViewThroughHoursRadio" name="installViewThroughType" type="radio"*/}
                {/*                 value={timeType.Hours}/>*/}
                {/*      <label htmlFor="installViewThroughHoursRadio" className="ms-1">{t('HOURS')}</label>*/}

                {/*      <TextInput id="installViewThroughDaysRadio" name="installViewThroughType" type="radio"*/}
                {/*                 value={timeType.Days} className="ms-3"/>*/}
                {/*      <label htmlFor="installViewThroughDaysRadio" className="ms-1">{t('DAYS')}</label>*/}
                {/*    </div>*/}

                {/*    <div className="flex justify-start items-center w-full mt-2">*/}
                {/*      <RangeInput*/}
                {/*        name="installViewThrough"*/}
                {/*        className="w-4/12"*/}
                {/*        defaultValue="1"*/}
                {/*        max={installViewThroughType === timeType.Days ? 7 : 23}*/}
                {/*        min="1"*/}
                {/*        step="1"*/}
                {/*      />*/}

                {/*      <FontAwesomeIcon className="text-blue-700 ms-3" icon={faAngleRight}/>*/}

                {/*      <div className="text-xs ms-1">{installViewThrough} {installViewThroughType}</div>*/}
                {/*    </div>*/}
                {/*  </>*/}
                {/*}*/}

                {/*<hr className="h-px my-6 bg-gray-200 border-0"/>*/}

                {/*<div className="flex justify-start items-center w-full mt-5">*/}
                {/*  <div className="me-2">Reinstall attribution</div>*/}

                {/*  <Tooltip minWidth="380">*/}
                {/*    <Tooltip.Label>*/}
                {/*      <FontAwesomeIcon className="text-gray-500 text-xs" icon={faCircleInfo}/>*/}
                {/*    </Tooltip.Label>*/}
                {/*    <Tooltip.Content>*/}
                {/*      <p>Turn on to start attributing users who reinstall the app during their re-attribution window.</p>*/}
                {/*    </Tooltip.Content>*/}
                {/*  </Tooltip>*/}

                {/*  <ToggleCheckbox name="reinstallAttribution" className="ms-10"/>*/}
                {/*</div>*/}

                {/*{*/}
                {/*  reinstallAttribution &&*/}
                {/*  <div*/}
                {/*    className="flex justify-start items-center py-2 px-4 mb-4 text-yellow-800 rounded-lg bg-yellow-100 mt-3"*/}
                {/*    role="alert">*/}
                {/*    <FontAwesomeIcon className="text-lg text-yellow-500" icon={faTriangleExclamation}/>*/}
                {/*    <span className="text-sm ms-2">Reinstall attribution lookback windows and view-through status are taken from the install attribution settings</span>*/}
                {/*  </div>*/}
                {/*}*/}

                {/*<hr className="h-px my-6 bg-gray-200 border-0"/>*/}

                {/*<div className="flex justify-start items-center w-full mt-5">*/}
                {/*  <div className="me-2">Re-engagement attribution</div>*/}

                {/*  <Tooltip minWidth="380">*/}
                {/*    <Tooltip.Label>*/}
                {/*      <FontAwesomeIcon className="text-gray-500 text-xs" icon={faCircleInfo}/>*/}
                {/*    </Tooltip.Label>*/}
                {/*    <Tooltip.Content>*/}
                {/*      <p>Turn on to start attributing re-engagements of your existing users</p>*/}
                {/*    </Tooltip.Content>*/}
                {/*  </Tooltip>*/}

                {/*  <ToggleCheckbox name="reengagementAttribution" className="ms-10"/>*/}
                {/*</div>*/}

                {/*<hr className="h-px my-6 bg-gray-200 border-0"/>*/}

                {/*<div className="flex justify-start items-center w-full mt-5">*/}
                {/*  <div className="me-2">Ignore active users for retargeting</div>*/}

                {/*  <Tooltip minWidth="380">*/}
                {/*    <Tooltip.Label>*/}
                {/*      <FontAwesomeIcon className="text-gray-500 text-xs" icon={faCircleInfo}/>*/}
                {/*    </Tooltip.Label>*/}
                {/*    <Tooltip.Content>*/}
                {/*      <p>When enabled, retargeting conversions of users who were active during this window are*/}
                {/*        ignored.</p>*/}
                {/*    </Tooltip.Content>*/}
                {/*  </Tooltip>*/}

                {/*  <ToggleCheckbox name="ignoreActiveUsersForRetargeting" className="ms-10"/>*/}
                {/*</div>*/}

                {/*{*/}
                {/*  ignoreActiveUsersForRetargeting &&*/}
                {/*  <>*/}
                {/*    <div className="flex justify-start items-center w-full mt-2">*/}
                {/*      <div className="text-xs me-2">Inactivity window</div>*/}

                {/*      <Tooltip minWidth="380">*/}
                {/*        <Tooltip.Label>*/}
                {/*          <FontAwesomeIcon className="text-gray-500 text-xs" icon={faCircleInfo}/>*/}
                {/*        </Tooltip.Label>*/}
                {/*        <Tooltip.Content>*/}
                {/*          <p>Example: When the inactivity window is set to 7 days, re-engagements and re-attributions of*/}
                {/*            users who launched the app during the last 7 days, are not recorded in AppsFlyer. Retargeting*/}
                {/*            conversions of users that were dormant in the last 7 days are recorded.</p>*/}
                {/*        </Tooltip.Content>*/}
                {/*      </Tooltip>*/}
                {/*    </div>*/}

                {/*    <div className="flex justify-start items-center w-full mt-2">*/}
                {/*      <RangeInput*/}
                {/*        name="inactivityWindow"*/}
                {/*        className="w-4/12"*/}
                {/*        defaultValue="7"*/}
                {/*        max="30"*/}
                {/*        min="1"*/}
                {/*        step="1"*/}
                {/*      />*/}

                {/*      <FontAwesomeIcon className="text-blue-700 ms-3" icon={faAngleRight}/>*/}

                {/*      <div className="text-xs ms-1">{inactivityWindow} {t("DAYS")}</div>*/}
                {/*    </div>*/}
                {/*  </>*/}
                {/*}*/}

                <hr className="h-px my-6 bg-gray-200 border-0"/>

                <div className="flex justify-start items-center w-full mt-5">
                  <div className="me-2">In-app event postbacks</div>

                  <ToggleCheckbox name="inAppEventPostbacksEnabled" className="ms-10"/>
                </div>

                <div className={inAppEventPostbacksEnabled ? 'visible' : 'invisible h-0'}>
                  <div className="text-xs mt-2">Set up the events and data included in postbacks sent to this partner
                  </div>

                  <div className="grid grid-cols-5 gap-4 mt-5">
                    <div>DataSenses event</div>
                    <div>mapped to partner event</div>
                    <div>for users from</div>
                    <div>including</div>
                    <div></div>
                  </div>
                  {!isUndefinedOrEmpty(fields)
                    ?
                    fields.map((item, index) => (
                      <div key={item.id} className="grid grid-cols-5 gap-4 mt-2">
                        <div>
                          <TailwindCombobox
                            name={`inAppEventPostbacks.${index}.datasensesEvent`}
                            items={datasensesEvents}
                          />
                        </div>
                        <div>
                          <TailwindCombobox
                            name={`inAppEventPostbacks.${index}.partnerEvent`}
                            items={partnerEvents}
                          />
                        </div>
                        <div>
                          <TailwindCombobox
                            name={`inAppEventPostbacks.${index}.usersOrigin`}
                            items={userFromOptions}
                          />
                        </div>
                        <div>
                          <TailwindCombobox
                            name={`inAppEventPostbacks.${index}.including`}
                            items={includingOptions}
                          />
                        </div>
                        <div>
                          <div className="table-cell py-1">
                            <FontAwesomeIcon className="text-red-500 cursor-pointer mt-2" onClick={() => remove(index)}
                                             icon={faTrashCan}/>
                          </div>
                        </div>
                      </div>
                    ))
                    :
                    <></>
                  }
                  <div
                    onClick={append}
                    className="bg-transparent cursor-pointer hover:bg-blue-500 ds-button font-semibold hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent w-fit rounded mt-4"
                  >
                    <FontAwesomeIcon icon={faSquarePlus} className="me-2"/>
                    Add Events
                  </div>
                </div>
              </div>
              <hr className="h-px mt-4 mb-3 bg-gray-200 border-0"/>
            </div>
          </div>

          <footer>
            <div className="flex justify-between items-center px-4 w-full">
              <BackButton/>

              <div className="flex justify-end items-center mb-1">

                <button
                  type="button"
                  className="ds-button-cancel mr-2"
                  onClick={discardChanges}
                >
                  {t('DISCARD_CHANGES')}
                </button>

                <button
                  type="submit"
                  className={`${isLoading && "cursor-not-allowed"} ds-button inline-flex focus:outline-none`}
                >
                  {isLoading ?
                    <>
                      <LoadingSpinner className="mt-0.5 me-2"/>
                      {t('LOADING')}
                    </>
                    :
                    t('SAVE_INTEGRATION')
                  }
                </button>
              </div>
            </div>
          </footer>
        </form>
      </FormProvider>
    </div>
  );
}
