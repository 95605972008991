import { useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';

import moment from 'moment';
import { HOUR_FORMAT } from 'consts';

import ToggleBox from 'components/ToggleBox';


import './index.scss';

const hours = Array.from({ length: 24 }, (_, index) => index);
const minutes = Array.from({ length: 12 }, (_, index) => index * 5);

/**
 * Time select input component.
 * @param {Object} props - The props object.
 * @param {string} [props.value=''] - The value of the input field.
 * @param {function} [props.onChange=()=>{}] - The function called when the value changes.
 * @param {Array} [props.range=[]] - The range of selectable time values.
 * @param {string} [props.className=''] - Additional classes for styling.
 * @returns {JSX.Element} A React component representing the TimeSelectInput.
 */
export default function TimeSelectInput({
  value = '',
  onChange = () => { },
  onlyHour = false,
  range = [],
  className,
}) {
  const wrapper = useRef();

  const [step, setStep] = useState(1);
  const [hour, setHour] = useState('');

  const currentHour = useMemo(() => {
    if (value && /^\d{1,2}:\d{2}$/.test(value)) {
      const [hour] = value.split(':');
      return hour;
    }
    return null;
  }, [value]);

  const chooseHour = (item) => {
    if (Array.isArray(range) && range.length === 2 && moment(range[0], HOUR_FORMAT).isValid()) {
      const from = moment(range[0], HOUR_FORMAT);
      const valueTime = moment(`${item}:59`, HOUR_FORMAT);

      if (valueTime.isBefore(from)) {
        return;
      }
    }

    setHour(item);

    if (onlyHour) {
      onChange(item);
      wrapper.current.toggle();
    } else {
      setTimeout(() => setStep(2));
    }
  };

  const isDisabled = (item) => {
    if (Array.isArray(range) && range.length === 2) {
      if (moment(range[0], HOUR_FORMAT).isValid()) {
        const from = moment(range[0], HOUR_FORMAT);
        const valueTime = moment(`${item}`, HOUR_FORMAT);

        if (valueTime.isBefore(from)) {
          return true;
        }
      }

      if (moment(range[1], HOUR_FORMAT).isValid()) {
        const to = moment(range[1], HOUR_FORMAT);
        const valueTime = moment(`${item}`, HOUR_FORMAT);

        if (valueTime.isAfter(to)) {
          return true;
        }
      }
    }
    return false;
  };

  const chooseMinute = (item) => {
    if (Array.isArray(range) && range.length === 2 && moment(range[0], HOUR_FORMAT).isValid()) {
      const from = moment(range[0], HOUR_FORMAT);
      const valueTime = moment(`${item}:00`, HOUR_FORMAT);

      if (valueTime.isBefore(from)) {
        return;
      }
    }

    onChange(item);
    wrapper.current.toggle();
  };

  const reset = () => {
    setStep(1);
    setHour('')
  };

  return (
    <ToggleBox className="w-full" ref={wrapper} onClose={reset}>
      <ToggleBox.Button>
        <div className="time-select-input relative">
          <input
            value={onlyHour ? `${value}:00` : value}
            readOnly
            className={`rounded py-1 px-3 border border-gray-300 focus:outline-none pr-7 w-full ${className}`}
          />
          <FontAwesomeIcon className="icon" icon={faCalendarDays} />
        </div>
      </ToggleBox.Button>

      <ToggleBox.Content>
        <div className="py-1 px-2">
          {
            step === 1 &&
            <div className="grid grid-cols-4 gap-1">
              {hours.map(hour => (
                <div
                  key={hour}
                  className={`text-center cursor-pointer rounded py-0.5 ${currentHour == hour ? 'bg-blue-300' : 'hover:bg-blue-100'} ${isDisabled(`${hour}:59`) && 'text-gray-500'}`}
                  onClick={() => chooseHour(hour)}
                >
                  {hour}:00
                </div>
              ))}
            </div>
          }

          {
            step === 2 &&
            <div className="grid grid-cols-4">
              {minutes.map(minute => (
                <div
                  key={minute}
                  className={`text-center cursor-pointer rounded py-0.5 ${value == `${hour}:${String(minute).padStart(2, '0')}` ? 'bg-blue-300' : 'hover:bg-blue-100'}  ${isDisabled(`${hour}:${String(minute).padStart(2, '0')}`) && 'text-gray-500'}`}
                  onClick={() => chooseMinute(`${hour}:${String(minute).padStart(2, '0')}`)}
                >
                  {hour}:{String(minute).padStart(2, '0')}
                </div>
              ))}
            </div>
          }
        </div>
      </ToggleBox.Content>
    </ToggleBox>
  );
}
