import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {showError, showInfo} from "../../../components/FlashMessage/flashMessageSlice";
import CompanyService from "../../../services/company";
import {FormProvider, useForm} from "react-hook-form";
import TextInput from "../../../components/TextInput";


export default function CompanyProfile() {

  const dispatch = useDispatch();
  const companyMethods = useForm();

  const getCompanyInfo = async () => {
    try {
      const response = await CompanyService.getCompanyInfo();
      companyMethods.setValue('name', response.name);
      companyMethods.setValue('address', response.address);
      companyMethods.setValue('phone', response.phone);
      companyMethods.setValue('domainEmail', response.domainEmail);
      companyMethods.setValue('website', response.website);
      companyMethods.setValue('createdBy', response.createdBy);
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
  }

  const onSave = async (data) => {
    try {
      await CompanyService.updateCompanyInfo(data);
      await getCompanyInfo();
      dispatch(showInfo({message: "Company Profile Updated!"}));
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
  }

  useEffect(() => {
    getCompanyInfo();
  }, []);

  return (
    <div className="ds-block">
      <FormProvider {...companyMethods}>
        <form className="" onSubmit={companyMethods.handleSubmit(onSave)}>
          <div className="p-2">
            <h1 className="ds-text-form-title">Company details</h1>
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <div className="mt-2">
                  <TextInput
                    label="Name"
                    type="text"
                    name="name"
                    className="ds-input ds-input-text w-full"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <div className="mt-2">
                  <TextInput
                    label="Address"
                    name="address"
                    type="text"
                    className="ds-input ds-input-text w-full"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <div className="mt-2">
                  <TextInput
                    label="Contact Person"
                    name="createdBy"
                    type="email"
                    disabled={true}
                    className="ds-input ds-input-text w-full"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <div className="mt-2">
                  <TextInput
                    label="Phone"
                    name="phone"
                    className="ds-input ds-input-text w-full"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <div className="mt-2">
                  <TextInput
                    label="Domain Email"
                    name="domainEmail"
                    disabled={true}
                    className="ds-input ds-input-text w-full"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <div className="mt-2">
                  <TextInput
                    label="Website"
                    name="website"
                    type="url"
                    className="ds-input ds-input-text w-full"
                  />
                </div>
              </div>

            </div>
          </div>

          <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
            <button
              type="submit"
              className="ds-button"
            >
              Save
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}
