import SegmentFilterType from "components/SegmentFilterInput/types";

const OPERATOR_TYPE = {
  GREATER_OR_EQUALS: ">=",
  LESS_THAN_OR_EQUALS: "<=",
  EQUALS: "=",
  LESS_THAN: "<",
  IN: "IN",
};

const FIELD_NAME = {
  DATE_KEY: "date_key",
  EVENT_TYPE: "event_type",
  EVENT_NAME: "event_name",
  HOUR: "hour",
  DAY: "day",
  DAY_OF_WEEK: "day_of_week",
  FIRST_NAME: "first_name",
  LAST_NAME: "last_name",
}

const proceedparamFilter = (paramEventName, paramFilter) => {
  const filters = [];

  filters.push({
    field: FIELD_NAME.EVENT_NAME,
    operator: OPERATOR_TYPE.EQUALS,
    value: paramEventName,
  });

  for (const filter of paramFilter) {
    if (filter.type === SegmentFilterType.EVENT_PROPERTY) {
      filters.push({
        field: `$.${filter.value.property}`,
        operator: filter.value.operator,
        value: filter.value.value
      });
    }

    if (filter.type === SegmentFilterType.FIRST_NAME) {
      filters.push({
        field: FIELD_NAME.FIRST_NAME,
        operator: OPERATOR_TYPE.IN,
        value: filter.value
      });
    }

    if (filter.type === SegmentFilterType.LAST_NAME) {
      filters.push({
        field: FIELD_NAME.LAST_NAME,
        operator: OPERATOR_TYPE.IN,
        value: filter.value
      });
    }

    if (filter.type === SegmentFilterType.TIME_OF_THE_DAY) {
      filters.push({ field: FIELD_NAME.HOUR, operator: OPERATOR_TYPE.GREATER_OR_EQUALS, value: filter.value[0] })
      filters.push({ field: FIELD_NAME.HOUR, operator: OPERATOR_TYPE.LESS_THAN_OR_EQUALS, value: filter.value[1] })
    }

    if (filter.type === SegmentFilterType.DAY_OF_THE_MONTH) {
      //TODO: fix for demo
      if (filter.value.length > 0) {
        filters.push({
          field: FIELD_NAME.DAY,
          operator: OPERATOR_TYPE.EQUALS,
          value: filter.value[0]
        });
      }
    }
  };

  return filters;
};

export const proceedParam = (data) => {
  return {
    first_event: proceedparamFilter(data.firstEvent.paramEventName, data.firstEvent.paramFilter),
    return_event: proceedparamFilter(data.returnEvent.paramEventName, data.returnEvent.paramFilter),
  }
};
