import {PARTNERS} from "consts/partner";

export const timeType = {
  Hours: 'hour(s)',
  Days: 'day(s)',
}

export const userFromOptions = [
  { fieldKey: 'USERS_ORIGIN_PARTNER_ONLY', name: 'This partner only' },
  { fieldKey: 'USERS_ORIGIN_ALL', name: 'All media sources, including organic' },
]

export const includingOptions = [
  { fieldKey: 'value_and_revenue', name: 'Values & revenue' },
  { fieldKey: 'no_value_and_no_revenue', name: 'No values & no revenue' },
  { fieldKey: 'value_and_no_revenue', name: 'Values & no revenue' },
  { fieldKey: 'no_value_and_revenue', name: 'No values & revenue'},
]

export const integrationStatus = {
  true: "INTEGRATION_PARTNER_ACTIVE",
  false: "INTEGRATION_PARTNER_INACTIVE",
};

export const destinationOptions = [
  { fieldKey: 'DATA_SOURCE_DESTINATION_GOOGLE_SHEETS', name: 'Google Sheets' },
  // { fieldKey: 'DATA_SOURCE_DESTINATION_GOOGLE_CLOUD_STORAGE', name: 'Google Cloud Storage' },
  // { fieldKey: 'DATA_SOURCE_DESTINATION_EXTERNAL_BIGQUERY', name: 'Your company\'s BigQuery' },
  { fieldKey: 'DATA_SOURCE_DESTINATION_DS_BI_TOOL', name: 'DataSenses BI Tool' },
]

export const destinationSocialMediaOptions = [
  // { fieldKey: 'DATA_SOURCE_DESTINATION_GOOGLE_SHEETS', name: 'Google Sheets' },
  // { fieldKey: 'DATA_SOURCE_DESTINATION_GOOGLE_CLOUD_STORAGE', name: 'Google Cloud Storage' },
  // { fieldKey: 'DATA_SOURCE_DESTINATION_EXTERNAL_BIGQUERY', name: 'Your company\'s BigQuery' },
  { fieldKey: 'DATA_SOURCE_DESTINATION_DS_BI_TOOL', name: 'DataSenses BI Tool' },
]

export const sourceSocialMediaOptions = [
  // { fieldKey: 'DATA_SOURCE_DESTINATION_GOOGLE_SHEETS', name: 'Google Sheets' },
  // { fieldKey: 'DATA_SOURCE_DESTINATION_GOOGLE_CLOUD_STORAGE', name: 'Google Cloud Storage' },
  // { fieldKey: 'DATA_SOURCE_DESTINATION_EXTERNAL_BIGQUERY', name: 'Your company\'s BigQuery' },
  // { fieldKey: 'all', name: 'All' },
  { fieldKey: 'facebook-ads', name: 'Meta Ads' },
  { fieldKey: 'facebook-product', name: 'Meta Ads Product' },
  { fieldKey: 'facebook-page', name: 'Facebook Page Insights' },
  { fieldKey: 'facebook-post', name: 'Facebook Post Insights' },
  { fieldKey: 'instagram-page', name: 'Instagram Account Insights' },
  { fieldKey: 'instagram-post', name: 'Instagram Media Insights' },
]

export const sourceGoogleAdsOptions = [
  // { fieldKey: 'DATA_SOURCE_DESTINATION_GOOGLE_SHEETS', name: 'Google Sheets' },
  // { fieldKey: 'DATA_SOURCE_DESTINATION_GOOGLE_CLOUD_STORAGE', name: 'Google Cloud Storage' },
  // { fieldKey: 'DATA_SOURCE_DESTINATION_EXTERNAL_BIGQUERY', name: 'Your company\'s BigQuery' },
  // { fieldKey: 'all', name: 'All' },
  { fieldKey: 'google-ads', name: 'Google Ads' },
  // { fieldKey: 'google-product', name: 'Google Ads Level Product' },
]

export const sourceAppleSearchAdsOptions = [
  {fieldKey: 'apple-search-ads', name: 'Apple Search Ads'},
]

export const sourceTiktokAdsOptions = [
  { fieldKey: 'tiktok-ads', name: 'Tiktok Ads' },
]

export const sourceOptions = [
  { partner: PARTNERS.FACEBOOK, options: sourceSocialMediaOptions },
  { partner: PARTNERS.GOOGLE, options: sourceGoogleAdsOptions },
  { partner: PARTNERS.APPLE_SEARCH, options: sourceAppleSearchAdsOptions },
  { partner: PARTNERS.TIKTOK, options: sourceTiktokAdsOptions },
]

export const dataSourceDestination = {
  "DATA_SOURCE_DESTINATION_GOOGLE_SHEETS": 'Google Sheets',
  "DATA_SOURCE_DESTINATION_GOOGLE_CLOUD_STORAGE": 'Google Cloud Storage',
  "DATA_SOURCE_DESTINATION_EXTERNAL_BIGQUERY": 'Your company\'s BigQuery',
  "DATA_SOURCE_DESTINATION_DS_BI_TOOL": 'DataSenses BI Tool',
}

export const dataSourceStatus = {
  "DATA_SOURCE_ACTIVE": 'Active',
  "DATA_SOURCE_INACTIVE": 'Inactive',
};

export const dataSourceIntervalTime = {
  '1-DATA_SOURCE_TIME_TYPE_DAY': 'daily',
  '2-DATA_SOURCE_TIME_TYPE_DAY': 'every 2 days',
  '3-DATA_SOURCE_TIME_TYPE_DAY': 'every 3 days',
  '7-DATA_SOURCE_TIME_TYPE_DAY': 'weekly',
  '14-DATA_SOURCE_TIME_TYPE_DAY': 'every 2 weeks',
  '21-DATA_SOURCE_TIME_TYPE_DAY': 'every 3 weeks',
  '1-DATA_SOURCE_TIME_TYPE_MONTH': 'every 1 month',
  '1-DATA_SOURCE_TIME_TYPE_HOUR': 'hourly',
  '2-DATA_SOURCE_TIME_TYPE_HOUR': 'every 2 hours',
  '3-DATA_SOURCE_TIME_TYPE_HOUR': 'every 3 hours',
  '6-DATA_SOURCE_TIME_TYPE_HOUR': 'every 6 hours',
  '12-DATA_SOURCE_TIME_TYPE_HOUR': 'every 12 hours',
}

export const intervalTimeOptions = [
  { fieldKey: '1-DATA_SOURCE_TIME_TYPE_DAY', name: 'daily'},
  { fieldKey: '7-DATA_SOURCE_TIME_TYPE_DAY', name: 'weekly'},
  { fieldKey: '3-DATA_SOURCE_TIME_TYPE_HOUR', name: 'every 3 hours'},
]

export const intervalTimeDateOnlyOptions = [
  { fieldKey: '1-DATA_SOURCE_TIME_TYPE_DAY', name: 'daily'},
  { fieldKey: '7-DATA_SOURCE_TIME_TYPE_DAY', name: 'weekly'},
]

export const facebookPlatformsOptions = [
  { fieldKey: 'facebook', name: 'Meta Post'},
  { fieldKey: 'facebook_ads', name: 'Meta Ads'},
  { fieldKey: 'ig', name: 'Instagram'},
]

export const facebookPlatformsOptionMap = {
  'META-ADS': 'Meta Ads',
  'META-POST': 'Meta Post',
  'INSTAGRAM': 'Instagram',
}

export const fileFormatOptions = [
  { fieldKey: 'DATA_SOURCE_FILE_FORMAT_UNSPECIFIED', name: 'None'},
  { fieldKey: 'DATA_SOURCE_FILE_FORMAT_CSV', name: 'CSV'},
  { fieldKey: 'DATA_SOURCE_FILE_FORMAT_JSON', name: 'JSON'},
  { fieldKey: 'DATA_SOURCE_FILE_FORMAT_PARQUET', name: 'Parquet'},
  { fieldKey: 'DATA_SOURCE_FILE_FORMAT_HDF5', name: 'HDF5'},
  { fieldKey: 'DATA_SOURCE_FILE_FORMAT_XML', name: 'XML'},
]

export const fieldOptions = {
  "haravan": [
    { fieldKey: 'all', name: 'All'},
    { fieldKey: 'date_key', name: 'date_key' },
    { fieldKey: 'seller_name', name: 'seller_name' },
    { fieldKey: 'created_at', name: 'created_at'},
    { fieldKey: 'order_created_at', name: 'order_created_at'},
    { fieldKey: 'order_updated_at', name: 'order_updated_at'},
    { fieldKey: 'order_id', name: 'order_id'},
    { fieldKey: 'order_number', name: 'order_number'},
    { fieldKey: 'order_status', name: 'order_status'},
    { fieldKey: 'fulfillment_status', name: 'fulfillment_status'},
    { fieldKey: 'source', name: 'source'},
    { fieldKey: 'source_name', name: 'source_name'},
    { fieldKey: 'utm_source', name: 'utm_source'},
    { fieldKey: 'utm_medium', name: 'utm_medium'},
    { fieldKey: 'utm_campaign', name: 'utm_campaign'},
    { fieldKey: 'utm_term', name: 'utm_term'},
    { fieldKey: 'utm_content', name: 'utm_content'},
    { fieldKey: 'attributed_haravan_social_psid', name: 'attributed_haravan_social_psid'},
    { fieldKey: 'attributed_haravan_social_post_id', name: 'attributed_haravan_social_post_id'},
    { fieldKey: 'attributed_haravan_social_page_id', name: 'attributed_haravan_social_page_id'},
    { fieldKey: 'attributed_haravan_social_channel', name: 'attributed_haravan_social_channel'},
    { fieldKey: 'attributed_haravan_sales_channel_branch_id', name: 'attributed_haravan_sales_channel_branch_id'},
    { fieldKey: 'gateway_code', name: 'gateway_code'},
    { fieldKey: 'gateway', name: 'gateway'},
    { fieldKey: 'total_weight', name: 'total_weight'},
    { fieldKey: 'product_id', name: 'product_id'},
    { fieldKey: 'product_name', name: 'product_name'},
    { fieldKey: 'sku', name: 'sku'},
    { fieldKey: 'variant_id', name: 'variant_id'},
    { fieldKey: 'variant_name', name: 'variant_name'},
    { fieldKey: 'vendor', name: 'vendor'},
    { fieldKey: 'product_type', name: 'product_type'},
    { fieldKey: 'bar_code', name: 'bar_code'},
    { fieldKey: 'product_image', name: 'product_image'},
    { fieldKey: 'currency', name: 'currency'},
    { fieldKey: 'price', name: 'price'},
    { fieldKey: 'original_price', name: 'original_price'},
    { fieldKey: 'quantity', name: 'quantity'},
    { fieldKey: 'amount', name: 'amount'},
    { fieldKey: 'shipping_fee', name: 'shipping_fee'},
    { fieldKey: 'discount_amount', name: 'discount_amount'},
    { fieldKey: 'tax', name: 'tax'},
    { fieldKey: 'discount_codes', name: 'discount_codes'},
    { fieldKey: 'taxes_included', name: 'taxes_included'},
    { fieldKey: 'financial_status', name: 'financial_status'},
    { fieldKey: 'processing_method', name: 'processing_method'},
    { fieldKey: 'shipping_type', name: 'shipping_type'},
    { fieldKey: 'cancelled_at', name: 'cancelled_at'},
    { fieldKey: 'cancelled_status', name: 'cancelled_status'},
    { fieldKey: 'cancel_reason', name: 'cancel_reason'},
    { fieldKey: 'confirmed_at', name: 'confirmed_at'},
    { fieldKey: 'confirmed_status', name: 'confirmed_status'},
    { fieldKey: 'prev_order_date', name: 'prev_order_date'},
    { fieldKey: 'prev_order_id', name: 'prev_order_id'},
    { fieldKey: 'prev_order_number', name: 'prev_order_number'},
    { fieldKey: 'ref_order_date', name: 'ref_order_date'},
    { fieldKey: 'ref_order_id', name: 'ref_order_id'},
    { fieldKey: 'ref_order_number', name: 'ref_order_number'},
    { fieldKey: 'note', name: 'note'},
    { fieldKey: 'tags', name: 'tags'},
    { fieldKey: 'location_id', name: 'location_id'},
    { fieldKey: 'location_name', name: 'location_name'},
    { fieldKey: 'assigned_location_at', name: 'assigned_location_at'},
    { fieldKey: 'assigned_location_id', name: 'assigned_location_id'},
    { fieldKey: 'assigned_location_name', name: 'assigned_location_name'},
    { fieldKey: 'user_id', name: 'user_id'},
    { fieldKey: 'shipping_country', name: 'shipping_country'},
    { fieldKey: 'shipping_province', name: 'shipping_province'},
    { fieldKey: 'shipping_city', name: 'shipping_city'},
    { fieldKey: 'shipping_district', name: 'shipping_district'},
    { fieldKey: 'shipping_ward', name: 'shipping_ward'},
    { fieldKey: 'shipping_address', name: 'shipping_address'},
    { fieldKey: 'shipping_company', name: 'shipping_company'},
    { fieldKey: 'shipping_first_name', name: 'shipping_first_name'},
    { fieldKey: 'shipping_last_name', name: 'shipping_last_name'},
    { fieldKey: 'shipping_phone', name: 'shipping_phone'},
    { fieldKey: 'billing_country', name: 'billing_country'},
    { fieldKey: 'billing_province', name: 'billing_province'},
    { fieldKey: 'billing_city', name: 'billing_city'},
    { fieldKey: 'billing_district', name: 'billing_district'},
    { fieldKey: 'billing_ward', name: 'billing_ward'},
    { fieldKey: 'billing_address', name: 'billing_address'},
    { fieldKey: 'billing_company', name: 'billing_company'},
    { fieldKey: 'billing_first_name', name: 'billing_first_name'},
    { fieldKey: 'billing_last_name', name: 'billing_last_name'},
    { fieldKey: 'billing_phone', name: 'billing_phone'},
    { fieldKey: 'email', name: 'email'},
    { fieldKey: 'contact_email', name: 'contact_email'},
    { fieldKey: 'device_id', name: 'device_id'},
    { fieldKey: 'buyer_accepts_marketing', name: 'buyer_accepts_marketing'},
    { fieldKey: 'confirm_user', name: 'confirm_user'},
    { fieldKey: 'exported_confirm_at', name: 'exported_confirm_at'},
    { fieldKey: 'closed_at', name: 'closed_at'},
    { fieldKey: 'closed_status', name: 'closed_status'},
    { fieldKey: 'token', name: 'token'},
    { fieldKey: 'cart_token', name: 'cart_token'},
    { fieldKey: 'checkout_token', name: 'checkout_token'},
    { fieldKey: 'discount_applications', name: 'discount_applications'},
    { fieldKey: 'redeem_model', name: 'redeem_model'},
    { fieldKey: 'payment_url', name: 'payment_url'},
    { fieldKey: 'tax_lines', name: 'tax_lines'},
    { fieldKey: 'browser_ip', name: 'browser_ip'},
    { fieldKey: 'referring_site', name: 'referring_site'},
    { fieldKey: 'landing_site', name: 'landing_site'},
    { fieldKey: 'landing_site_ref', name: 'landing_site_ref'},
    { fieldKey: 'risk_level', name: 'risk_level'},
  ],
  "tiktok_shop": [
    { fieldKey: 'all', name: 'All'},
    { fieldKey: 'date_key', name: 'date_key' },
    { fieldKey: 'seller_name', name: 'seller_name' },
    { fieldKey: 'created_at', name: 'created_at'},
    { fieldKey: 'create_time', name: 'create_time'},
    { fieldKey: 'update_time', name: 'update_time'},
    { fieldKey: 'paid_time', name: 'paid_time'},
    { fieldKey: 'order_id', name: 'order_id'},
    { fieldKey: 'order_status', name: 'order_status'},
    { fieldKey: 'payment_method', name: 'payment_method'},
    { fieldKey: 'payment_method_name', name: 'payment_method_name'},
    { fieldKey: 'payment_method_type', name: 'payment_method_type'},
    { fieldKey: 'is_cod', name: 'is_cod'},
    { fieldKey: 'cod_risk_type', name: 'cod_risk_type'},
    { fieldKey: 'product_id', name: 'product_id'},
    { fieldKey: 'product_name', name: 'product_name'},
    { fieldKey: 'quantity', name: 'quantity'},
    { fieldKey: 'seller_sku', name: 'seller_sku'},
    { fieldKey: 'sku_id', name: 'sku_id'},
    { fieldKey: 'sku_image', name: 'sku_image'},
    { fieldKey: 'sku_name', name: 'sku_name'},
    { fieldKey: 'sku_type', name: 'sku_type'},
    { fieldKey: 'currency', name: 'currency'},
    { fieldKey: 'original_price', name: 'original_price'},
    { fieldKey: 'sale_price', name: 'sale_price'},
    { fieldKey: 'platform_discount', name: 'platform_discount'},
    { fieldKey: 'seller_discount', name: 'seller_discount'},
    { fieldKey: 'original_shipping_fee', name: 'original_shipping_fee'},
    { fieldKey: 'shipping_fee', name: 'shipping_fee'},
    { fieldKey: 'shipping_fee_platform_discount', name: 'shipping_fee_platform_discount'},
    { fieldKey: 'shipping_fee_seller_discount', name: 'shipping_fee_seller_discount'},
    { fieldKey: 'taxes', name: 'taxes'},
    { fieldKey: 'amount', name: 'amount'},
    { fieldKey: 'buyer_uid', name: 'buyer_uid'},
    { fieldKey: 'country', name: 'country'},
    { fieldKey: 'province', name: 'province'},
    { fieldKey: 'city', name: 'city'},
    { fieldKey: 'district', name: 'district'},
    { fieldKey: 'ward', name: 'ward'},
    { fieldKey: 'name', name: 'name'},
    { fieldKey: 'phone', name: 'phone'},
    { fieldKey: 'receiver_address_updated', name: 'receiver_address_updated'},
    { fieldKey: 'buyer_message', name: 'buyer_message'},
    { fieldKey: 'delivery_option_type', name: 'delivery_option_type'},
    { fieldKey: 'shipping_provider_id', name: 'shipping_provider_id'},
    { fieldKey: 'shipping_provider', name: 'shipping_provider'},
    { fieldKey: 'delivery_option_id', name: 'delivery_option_id'},
    { fieldKey: 'delivery_option', name: 'delivery_option'},
    { fieldKey: 'delivery_option_description', name: 'delivery_option_description'},
    { fieldKey: 'cancel_order_sla', name: 'cancel_order_sla'},
    { fieldKey: 'cancel_reason', name: 'cancel_reason'},
    { fieldKey: 'cancel_user', name: 'cancel_user'},
    { fieldKey: 'tracking_number', name: 'tracking_number'},
    { fieldKey: 'warehouse_id', name: 'warehouse_id'},
    { fieldKey: 'fulfillment_type', name: 'fulfillment_type'},
    { fieldKey: 'is_sample_order', name: 'is_sample_order'},
    { fieldKey: 'display_status', name: 'display_status'},
    { fieldKey: 'ext_status', name: 'ext_status'},
    { fieldKey: 'rts_sla', name: 'rts_sla'},
    { fieldKey: 'rts_time', name: 'rts_time'},
    { fieldKey: 'tts_sla', name: 'tts_sla'},
  ],
  "shopify": [
    { fieldKey: 'all', name: 'All'},
    { fieldKey: 'date_key', name: 'date_key' },
    { fieldKey: 'created_at', name: 'created_at'},
    { fieldKey: 'create_time', name: 'create_time'},
    { fieldKey: 'update_time', name: 'update_time'},
    { fieldKey: 'paid_time', name: 'paid_time'},
    { fieldKey: 'order_id', name: 'order_id'},
    { fieldKey: 'order_status', name: 'order_status'},
    { fieldKey: 'payment_method', name: 'payment_method'},
    { fieldKey: 'payment_method_name', name: 'payment_method_name'},
    { fieldKey: 'payment_method_type', name: 'payment_method_type'},
    { fieldKey: 'is_cod', name: 'is_cod'},
    { fieldKey: 'cod_risk_type', name: 'cod_risk_type'},
    { fieldKey: 'product_id', name: 'product_id'},
    { fieldKey: 'product_name', name: 'product_name'},
    { fieldKey: 'quantity', name: 'quantity'},
    { fieldKey: 'seller_sku', name: 'seller_sku'},
    { fieldKey: 'sku_id', name: 'sku_id'},
    { fieldKey: 'sku_image', name: 'sku_image'},
    { fieldKey: 'sku_name', name: 'sku_name'},
    { fieldKey: 'sku_type', name: 'sku_type'},
    { fieldKey: 'currency', name: 'currency'},
    { fieldKey: 'original_price', name: 'original_price'},
    { fieldKey: 'sale_price', name: 'sale_price'},
    { fieldKey: 'platform_discount', name: 'platform_discount'},
    { fieldKey: 'seller_discount', name: 'seller_discount'},
    { fieldKey: 'original_shipping_fee', name: 'original_shipping_fee'},
    { fieldKey: 'shipping_fee', name: 'shipping_fee'},
    { fieldKey: 'shipping_fee_platform_discount', name: 'shipping_fee_platform_discount'},
    { fieldKey: 'shipping_fee_seller_discount', name: 'shipping_fee_seller_discount'},
    { fieldKey: 'taxes', name: 'taxes'},
    { fieldKey: 'amount', name: 'amount'},
    { fieldKey: 'buyer_uid', name: 'buyer_uid'},
    { fieldKey: 'country', name: 'country'},
    { fieldKey: 'province', name: 'province'},
    { fieldKey: 'city', name: 'city'},
    { fieldKey: 'district', name: 'district'},
    { fieldKey: 'ward', name: 'ward'},
    { fieldKey: 'name', name: 'name'},
    { fieldKey: 'phone', name: 'phone'},
    { fieldKey: 'receiver_address_updated', name: 'receiver_address_updated'},
    { fieldKey: 'buyer_message', name: 'buyer_message'},
    { fieldKey: 'delivery_option_type', name: 'delivery_option_type'},
    { fieldKey: 'shipping_provider_id', name: 'shipping_provider_id'},
    { fieldKey: 'shipping_provider', name: 'shipping_provider'},
    { fieldKey: 'delivery_option_id', name: 'delivery_option_id'},
    { fieldKey: 'delivery_option', name: 'delivery_option'},
    { fieldKey: 'delivery_option_description', name: 'delivery_option_description'},
    { fieldKey: 'cancel_order_sla', name: 'cancel_order_sla'},
    { fieldKey: 'cancel_reason', name: 'cancel_reason'},
    { fieldKey: 'cancel_user', name: 'cancel_user'},
    { fieldKey: 'tracking_number', name: 'tracking_number'},
    { fieldKey: 'warehouse_id', name: 'warehouse_id'},
    { fieldKey: 'fulfillment_type', name: 'fulfillment_type'},
    { fieldKey: 'is_sample_order', name: 'is_sample_order'},
    { fieldKey: 'display_status', name: 'display_status'},
    { fieldKey: 'ext_status', name: 'ext_status'},
    { fieldKey: 'rts_sla', name: 'rts_sla'},
    { fieldKey: 'rts_time', name: 'rts_time'},
    { fieldKey: 'tts_sla', name: 'tts_sla'},
  ],
  "shopee": [
    { fieldKey: 'all', name: 'All'},
    { fieldKey: 'date_key', name: 'date_key' },
    { fieldKey: 'created_at', name: 'created_at'},
    { fieldKey: 'create_time', name: 'create_time'},
    { fieldKey: 'update_time', name: 'update_time'},
    { fieldKey: 'paid_time', name: 'paid_time'},
    { fieldKey: 'order_id', name: 'order_id'},
    { fieldKey: 'order_status', name: 'order_status'},
    { fieldKey: 'payment_method', name: 'payment_method'},
    { fieldKey: 'payment_method_name', name: 'payment_method_name'},
    { fieldKey: 'payment_method_type', name: 'payment_method_type'},
    { fieldKey: 'is_cod', name: 'is_cod'},
    { fieldKey: 'cod_risk_type', name: 'cod_risk_type'},
    { fieldKey: 'product_id', name: 'product_id'},
    { fieldKey: 'product_name', name: 'product_name'},
    { fieldKey: 'quantity', name: 'quantity'},
    { fieldKey: 'seller_sku', name: 'seller_sku'},
    { fieldKey: 'sku_id', name: 'sku_id'},
    { fieldKey: 'sku_image', name: 'sku_image'},
    { fieldKey: 'sku_name', name: 'sku_name'},
    { fieldKey: 'sku_type', name: 'sku_type'},
    { fieldKey: 'currency', name: 'currency'},
    { fieldKey: 'original_price', name: 'original_price'},
    { fieldKey: 'sale_price', name: 'sale_price'},
    { fieldKey: 'platform_discount', name: 'platform_discount'},
    { fieldKey: 'seller_discount', name: 'seller_discount'},
    { fieldKey: 'original_shipping_fee', name: 'original_shipping_fee'},
    { fieldKey: 'shipping_fee', name: 'shipping_fee'},
    { fieldKey: 'shipping_fee_platform_discount', name: 'shipping_fee_platform_discount'},
    { fieldKey: 'shipping_fee_seller_discount', name: 'shipping_fee_seller_discount'},
    { fieldKey: 'taxes', name: 'taxes'},
    { fieldKey: 'amount', name: 'amount'},
    { fieldKey: 'buyer_uid', name: 'buyer_uid'},
    { fieldKey: 'country', name: 'country'},
    { fieldKey: 'province', name: 'province'},
    { fieldKey: 'city', name: 'city'},
    { fieldKey: 'district', name: 'district'},
    { fieldKey: 'ward', name: 'ward'},
    { fieldKey: 'name', name: 'name'},
    { fieldKey: 'phone', name: 'phone'},
    { fieldKey: 'receiver_address_updated', name: 'receiver_address_updated'},
    { fieldKey: 'buyer_message', name: 'buyer_message'},
    { fieldKey: 'delivery_option_type', name: 'delivery_option_type'},
    { fieldKey: 'shipping_provider_id', name: 'shipping_provider_id'},
    { fieldKey: 'shipping_provider', name: 'shipping_provider'},
    { fieldKey: 'delivery_option_id', name: 'delivery_option_id'},
    { fieldKey: 'delivery_option', name: 'delivery_option'},
    { fieldKey: 'delivery_option_description', name: 'delivery_option_description'},
    { fieldKey: 'cancel_order_sla', name: 'cancel_order_sla'},
    { fieldKey: 'cancel_reason', name: 'cancel_reason'},
    { fieldKey: 'cancel_user', name: 'cancel_user'},
    { fieldKey: 'tracking_number', name: 'tracking_number'},
    { fieldKey: 'warehouse_id', name: 'warehouse_id'},
    { fieldKey: 'fulfillment_type', name: 'fulfillment_type'},
    { fieldKey: 'is_sample_order', name: 'is_sample_order'},
    { fieldKey: 'display_status', name: 'display_status'},
    { fieldKey: 'ext_status', name: 'ext_status'},
    { fieldKey: 'rts_sla', name: 'rts_sla'},
    { fieldKey: 'rts_time', name: 'rts_time'},
    { fieldKey: 'tts_sla', name: 'tts_sla'},
  ],
  "facebook": [
    { fieldKey: 'all', name: 'All'},
    { fieldKey: 'date_key', name: 'date_key' },
    { fieldKey: 'created_at', name: 'created_at'},
    { fieldKey: 'create_time', name: 'create_time'},
    { fieldKey: 'update_time', name: 'update_time'},
    { fieldKey: 'paid_time', name: 'paid_time'},
    { fieldKey: 'order_id', name: 'order_id'},
    { fieldKey: 'order_status', name: 'order_status'},
    { fieldKey: 'payment_method', name: 'payment_method'},
    { fieldKey: 'payment_method_name', name: 'payment_method_name'},
    { fieldKey: 'payment_method_type', name: 'payment_method_type'},
    { fieldKey: 'is_cod', name: 'is_cod'},
    { fieldKey: 'cod_risk_type', name: 'cod_risk_type'},
    { fieldKey: 'product_id', name: 'product_id'},
    { fieldKey: 'product_name', name: 'product_name'},
    { fieldKey: 'quantity', name: 'quantity'},
    { fieldKey: 'seller_sku', name: 'seller_sku'},
    { fieldKey: 'sku_id', name: 'sku_id'},
    { fieldKey: 'sku_image', name: 'sku_image'},
    { fieldKey: 'sku_name', name: 'sku_name'},
    { fieldKey: 'sku_type', name: 'sku_type'},
    { fieldKey: 'currency', name: 'currency'},
    { fieldKey: 'original_price', name: 'original_price'},
    { fieldKey: 'sale_price', name: 'sale_price'},
    { fieldKey: 'platform_discount', name: 'platform_discount'},
    { fieldKey: 'seller_discount', name: 'seller_discount'},
    { fieldKey: 'original_shipping_fee', name: 'original_shipping_fee'},
    { fieldKey: 'shipping_fee', name: 'shipping_fee'},
    { fieldKey: 'shipping_fee_platform_discount', name: 'shipping_fee_platform_discount'},
    { fieldKey: 'shipping_fee_seller_discount', name: 'shipping_fee_seller_discount'},
    { fieldKey: 'taxes', name: 'taxes'},
    { fieldKey: 'amount', name: 'amount'},
    { fieldKey: 'buyer_uid', name: 'buyer_uid'},
    { fieldKey: 'country', name: 'country'},
    { fieldKey: 'province', name: 'province'},
    { fieldKey: 'city', name: 'city'},
    { fieldKey: 'district', name: 'district'},
    { fieldKey: 'ward', name: 'ward'},
    { fieldKey: 'name', name: 'name'},
    { fieldKey: 'phone', name: 'phone'},
    { fieldKey: 'receiver_address_updated', name: 'receiver_address_updated'},
    { fieldKey: 'buyer_message', name: 'buyer_message'},
    { fieldKey: 'delivery_option_type', name: 'delivery_option_type'},
    { fieldKey: 'shipping_provider_id', name: 'shipping_provider_id'},
    { fieldKey: 'shipping_provider', name: 'shipping_provider'},
    { fieldKey: 'delivery_option_id', name: 'delivery_option_id'},
    { fieldKey: 'delivery_option', name: 'delivery_option'},
    { fieldKey: 'delivery_option_description', name: 'delivery_option_description'},
    { fieldKey: 'cancel_order_sla', name: 'cancel_order_sla'},
    { fieldKey: 'cancel_reason', name: 'cancel_reason'},
    { fieldKey: 'cancel_user', name: 'cancel_user'},
    { fieldKey: 'tracking_number', name: 'tracking_number'},
    { fieldKey: 'warehouse_id', name: 'warehouse_id'},
    { fieldKey: 'fulfillment_type', name: 'fulfillment_type'},
    { fieldKey: 'is_sample_order', name: 'is_sample_order'},
    { fieldKey: 'display_status', name: 'display_status'},
    { fieldKey: 'ext_status', name: 'ext_status'},
    { fieldKey: 'rts_sla', name: 'rts_sla'},
    { fieldKey: 'rts_time', name: 'rts_time'},
    { fieldKey: 'tts_sla', name: 'tts_sla'},
  ],
  "lazada": [
    { fieldKey: 'all', name: 'All'},
    { fieldKey: 'date_key', name: 'date_key'},
    { fieldKey: 'created_at', name: 'created_at'},
    { fieldKey: 'order_created_at', name: 'order_created_at'},
    { fieldKey: 'order_updated_at', name: 'order_updated_at'},
    { fieldKey: 'seller_name', name: 'seller_name'},
    { fieldKey: 'order_id', name: 'order_id'},
    { fieldKey: 'order_number', name: 'order_number'},
    { fieldKey: 'order_type', name: 'order_type'},
    { fieldKey: 'order_status', name: 'order_status'},
    { fieldKey: 'payment_method', name: 'payment_method'},
    { fieldKey: 'shop_id', name: 'shop_id'},
    { fieldKey: 'product_id', name: 'product_id'},
    { fieldKey: 'order_item_id', name: 'order_item_id'},
    { fieldKey: 'sku', name: 'sku'},
    { fieldKey: 'shop_sku', name: 'shop_sku'},
    { fieldKey: 'sku_id', name: 'sku_id'},
    { fieldKey: 'product_name', name: 'product_name'},
    { fieldKey: 'variation', name: 'variation'},
    { fieldKey: 'product_main_image', name: 'product_main_image'},
    { fieldKey: 'product_detail_url', name: 'product_detail_url'},
    { fieldKey: 'quantity', name: 'quantity'},
    { fieldKey: 'currency', name: 'currency'},
    { fieldKey: 'item_price', name: 'item_price'},
    { fieldKey: 'amount', name: 'amount'},
    { fieldKey: 'voucher_amount', name: 'voucher_amount'},
    { fieldKey: 'voucher_platform', name: 'voucher_platform'},
    { fieldKey: 'voucher_seller', name: 'voucher_seller'},
    { fieldKey: 'voucher_platform_lpi', name: 'voucher_platform_lpi'},
    { fieldKey: 'voucher_seller_lpi', name: 'voucher_seller_lpi'},
    { fieldKey: 'shipping_fee_original', name: 'shipping_fee_original'},
    { fieldKey: 'shipping_amount', name: 'shipping_amount'},
    { fieldKey: 'shipping_fee_discount_platform', name: 'shipping_fee_discount_platform'},
    { fieldKey: 'shipping_fee_discount_seller', name: 'shipping_fee_discount_seller'},
    { fieldKey: 'shipping_service_cost', name: 'shipping_service_cost'},
    { fieldKey: 'tax_amount', name: 'tax_amount'},
    { fieldKey: 'supply_price_currency', name: 'supply_price_currency'},
    { fieldKey: 'supply_price', name: 'supply_price'},
    { fieldKey: 'voucher_code', name: 'voucher_code'},
    { fieldKey: 'voucher_code_platform', name: 'voucher_code_platform'},
    { fieldKey: 'voucher_code_seller', name: 'voucher_code_seller'},
    { fieldKey: 'cancel_return_initiator', name: 'cancel_return_initiator'},
    { fieldKey: 'reason', name: 'reason'},
    { fieldKey: 'reason_detail', name: 'reason_detail'},
    { fieldKey: 'return_status', name: 'return_status'},
    { fieldKey: 'order_flag', name: 'order_flag'},
    { fieldKey: 'delivery_option_sof', name: 'delivery_option_sof'},
    { fieldKey: 'shipping_type', name: 'shipping_type'},
    { fieldKey: 'shipping_provider_type', name: 'shipping_provider_type'},
    { fieldKey: 'shipping_pickup_provider', name: 'shipping_pickup_provider'},
    { fieldKey: 'shipping_deliver_provider', name: 'shipping_deliver_provider'},
    { fieldKey: 'promised_shipping_time', name: 'promised_shipping_time'},
    { fieldKey: 'fulfillment_sla', name: 'fulfillment_sla'},
    { fieldKey: 'digital_delivery_info', name: 'digital_delivery_info'},
    { fieldKey: 'buyer_id', name: 'buyer_id'},
    { fieldKey: 'shipping_country', name: 'shipping_country'},
    { fieldKey: 'shipping_city', name: 'shipping_city'},
    { fieldKey: 'shipping_address', name: 'shipping_address'},
    { fieldKey: 'shipping_first_name', name: 'shipping_first_name'},
    { fieldKey: 'shipping_last_name', name: 'shipping_last_name'},
    { fieldKey: 'shipping_phone', name: 'shipping_phone'},
    { fieldKey: 'billing_country', name: 'billing_country'},
    { fieldKey: 'billing_city', name: 'billing_city'},
    { fieldKey: 'billing_address', name: 'billing_address'},
    { fieldKey: 'billing_first_name', name: 'billing_first_name'},
    { fieldKey: 'billing_last_name', name: 'billing_last_name'},
    { fieldKey: 'billing_phone', name: 'billing_phone'},
    { fieldKey: 'buyer_note', name: 'buyer_note'},
    { fieldKey: 'package_id', name: 'package_id'},
    { fieldKey: 'warehouse_code', name: 'warehouse_code'},
    { fieldKey: 'is_fbl', name: 'is_fbl'},
    { fieldKey: 'pick_up_store_name', name: 'pick_up_store_name'},
    { fieldKey: 'pick_up_store_address', name: 'pick_up_store_address'},
    { fieldKey: 'pick_up_store_code', name: 'pick_up_store_code'},
    { fieldKey: 'pick_up_store_open_hour', name: 'pick_up_store_open_hour'},
    { fieldKey: 'biz_group', name: 'biz_group'},
    { fieldKey: 'gift_wrapping', name: 'gift_wrapping'},
    { fieldKey: 'gift_message', name: 'gift_message'},
    { fieldKey: 'gift_option', name: 'gift_option'},
    { fieldKey: 'payment_time', name: 'payment_time'},
    { fieldKey: 'sla_time_stamp', name: 'sla_time_stamp'},
    { fieldKey: 'invoice_number', name: 'invoice_number'},
    { fieldKey: 'stage_pay_status', name: 'stage_pay_status'},
    { fieldKey: 'is_reroute', name: 'is_reroute'},
    { fieldKey: 'tracking_code_pre', name: 'tracking_code_pre'},
    { fieldKey: 'mp3_order', name: 'mp3_order'},
    { fieldKey: 'personalization', name: 'personalization'},
    { fieldKey: 'wallet_credits', name: 'wallet_credits'},
    { fieldKey: 'is_digital', name: 'is_digital'},
    { fieldKey: 'tracking_code', name: 'tracking_code'},
    { fieldKey: 'semi_managed', name: 'semi_managed'},
    { fieldKey: 'priority_fulfillment_tag', name: 'priority_fulfillment_tag'},
    { fieldKey: 'item_extra_attributes', name: 'item_extra_attributes'},
    { fieldKey: 'extra_attributes', name: 'extra_attributes'},
    { fieldKey: 'national_registration_number', name: 'national_registration_number'},
    { fieldKey: 'is_tax_invoice_requested', name: 'is_tax_invoice_requested'},
    { fieldKey: 'remarks', name: 'remarks'},
    { fieldKey: 'tax_code', name: 'tax_code'},
    { fieldKey: 'branch_number', name: 'branch_number'},
  ],
}
