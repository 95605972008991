import {useState, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm, FormProvider, useFieldArray} from 'react-hook-form';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams, useLocation} from "react-router-dom";
import {CHART_CONFIG, PATHS, PLATFORM, REPORT_TYPE} from "consts";

import DateFilterToggleBox from 'components/DateFilterToggleBox';
import {showError} from "components/FlashMessage/flashMessageSlice";
import {buildGlobalFilterRequest} from "./processor"


import FieldFilterToogleBox from "components/FieldFilterToogleBox";
import Overview from "views/recommendation/overview";


export default function Recommendation() {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const navigate = useNavigate();


  const methods = useForm();

  const [fieldList, setFieldList] = useState([
    {fieldKey: "date_key"},
    {
      fieldKey: "utm_campaign",
      attribute: "dimension",
      filterable: true,
    },
  ]);
  const [globalFiterRequest, setGlobalFiterRequest] = useState([]);
  const [previousGlobalFilters, setPreviousGlobalFilters] = useState([]);

  const isShowDateFilter = useMemo(() => {
    return fieldList.some(item => item.fieldKey === CHART_CONFIG.CHART_DATE_FIELD)
  }, [fieldList]);

  const globalFiter = useMemo(() => {
    return fieldList.filter(element => element.attribute === 'dimension' && element.filterable === true && element.fieldKey !== CHART_CONFIG.CHART_DATE_FIELD);
  }, [fieldList]);


  const onSubmit = async (data) => {
    try {
      const [globalFiterRequest, prevGlobalFilterRequest] = buildGlobalFilterRequest(data);
      setGlobalFiterRequest(globalFiterRequest);
      setPreviousGlobalFilters(prevGlobalFilterRequest);
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
  }


  // useEffect(() => {
  //   methods.handleSubmit(onSubmit)();
  // }, [])

  useEffect(() => {
    methods.handleSubmit(onSubmit)();
  }, [methods.watch("global_filters.utm_campaign"), methods.watch("global_filters.date_key")]);


  return (
    <div id="skadnetwork">
      <div className='flex justify-between'>
        <div className="ds-text-page-title">{t('RECOMMENDATION')}</div>
        <div className="flex">
        </div>
      </div>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="flex flex-row w-full mt-5 ds-border-gray ds-input">
            <div className="mr-2">
              {isShowDateFilter &&
                <DateFilterToggleBox name={`${CHART_CONFIG.FIELD_GLOBAL_FILTERS}.${CHART_CONFIG.CHART_DATE_FIELD}`}/>}
            </div>
            {
              globalFiter.map((filter, index) => (
                <div key={index} className="mr-2">
                  <FieldFilterToogleBox
                    fieldKey={filter.fieldKey}
                    displayedName={t(filter.fieldKey)}
                    name={`${CHART_CONFIG.FIELD_GLOBAL_FILTERS}.${filter.fieldKey}`}
                  />
                </div>
              ))
            }
          </div>

          {/*<div className="grid grid-cols-6 gap-2 w-full mt-5">*/}
          {/*  <Overview*/}
          {/*    globalFilter={globalFiterRequest}*/}
          {/*    prevGlobalFilter={previousGlobalFilters}*/}
          {/*  />*/}
          {/*</div>          */}
          <div className="w-full mt-5">
            <Overview
              globalFilter={globalFiterRequest}
              prevGlobalFilter={previousGlobalFilters}
            />
          </div>


        </form>
      </FormProvider>
    </div>
  );
}
