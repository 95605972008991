import TimeSelectInput from 'components/TimeSelectInput/WithoutForm';

/**
 * Component for rendering a time input.
 * @param {Object} props - The props object.
 * @param {string[]} [props.value=['', '']] - The value of the time input.
 * @param {Function} [props.onChange=() => {}] - The function to handle value changes.
 * @param {string} [props.className=''] - Additional classes for styling.
 * @returns {JSX.Element} A React component representing the TimeInput.
 */
export default function TimeInput({
  value = [0, 23],
  onChange = () => { },
  className,
}) {
  /**
   * Handles the selection of the start time.
   * @param {string} newValue - The new value for the start time.
   */
  const chooseFrom = (newValue) => {
    onChange([newValue, value[1]]);
  };

  /**
   * Handles the selection of the end time.
   * @param {string} newValue - The new value for the end time.
   */
  const chooseTo = (newValue) => {
    onChange([value[0], newValue]);
  };

  return (
    <div className={`flex justify-between items-center w-full ${className}`}>
      <TimeSelectInput value={value[0]}  onChange={chooseFrom} onlyHour range={['', value[1]]} />
      <div className='pe-1'></div>
      <TimeSelectInput value={value[1]} onChange={chooseTo} onlyHour range={[value[0], '']} />
    </div>
  );
};
