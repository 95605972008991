import { useController, useFormContext } from 'react-hook-form';

import Input from './input';


export default function FunnelStep({
  name,
  defaultValue = [
    [
      {
        paramEventName: "",
        paramFilter: [],
      },
    ],
  ],
}) {
  const { control } = useFormContext();
  const { field } = useController({ name, control, defaultValue });

  const onChange = (value) => {
    field.onChange(value);
  };

  return (
    <Input onChange={onChange} value={field.value} />
  );
}
