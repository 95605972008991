import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { buildAppList } from "utils/app";
import { DISPLAY_DATE_CALENDAR_FORMAT, PATHS, REPORT_TYPE } from "consts";
import { getDiffWeek, isArray } from "utils";
import $ from 'jquery';

import Tooltip from 'components/Tooltip';
import LoadingSpinner from "components/LoadingSpinner";
import { showError } from "components/FlashMessage/flashMessageSlice";
import DsListBox from "components/DsListBox";
import TailwindCombobox from "components/TailwindCombobox";


import DateFilterToggleBox from "../components/DateFilterToggleBox";
import EventInput from "../components/EventInput";

import VisualizationLinechart from './VisualizationLinechart';
import CohortTable from './CohortTable';

import './index.scss';

import AnalysisService from 'services/analysis';
import MetaDataService from 'services/metadata';
import SegmentService from "services/segment";
import DashboardService from "services/dashboard";

import { proceedParam } from "./processor";
import moment from "moment";
import { SaveToDashboard } from "views/dashboard_list/save_to_dashboard";
import {exceptFieldKeys} from "consts/app";


const PLOT_VALUE = {
  Cohorts: 'cohorts',
  Frequency: 'frequency',
};

const DATE_FORMAT = 'DD-MM-YYYY';
const MONTH_FORMAT = 'MM-YYYY';
const DISPLAY_MONTH_FORMAT = 'MMM, YYYY';

const FREQUENCY_VALUE = {
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
}

const FREQUENCY_OPTIONS = [
  {
    name: 'Weekly',
    fieldKey: FREQUENCY_VALUE.WEEKLY,
  },
  {
    name: 'Monthly',
    fieldKey: FREQUENCY_VALUE.MONTHLY,
  },
];


export default function Cohort() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { appId, dashboardId } = useParams();
  const appList = useSelector((state) => state.app.appList);

  const [paramEventNameOptions, setParamEventNameOptions] = useState([]);
  const [segmentsOptions, setSegmentsOptions] = useState([]);
  const [header, setHeader] = useState([]);
  const [initialRender, setInitialRender] = useState(true);
  const [isSticky, setIsSticky] = useState(false);

  const getParamEventNameOptions = async () => {
    try {
      const result = await MetaDataService.getEventNameList({ appId });

      setParamEventNameOptions(result.map((value) => ({ text: value, value })));
      // setParamEventNameOptions(result.map((value) => {
      //   if (!exceptFieldKeys.includes(value)) {
      //     return { text: value, value };
      //   } else {
      //     return null;
      //   }
      // }).filter((value) => value !== null)
      // );
    } catch (error) {
      const { message } = error;
      dispatch(showError({ message }));
    }
  };

  const methods = useForm({ defaultValues: { plot: PLOT_VALUE.Cohorts } });
  const watchfrequency = methods.watch("frequency");

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  //const methods1 = useForm({defaultValues: {}});
  const date = methods.watch("date")

  const getSegmentList = async () => {
    try {
      const segments = await SegmentService.listSegments({ appId });

      setSegmentsOptions([
        {
          name: 'All user',
          fieldKey: 'All user',
        },
        ...segments.map((item) => ({ name: item.name, fieldKey: item.id }))
      ]);
    } catch (error) {
      const { message } = error;

      dispatch(showError({ message }));
    }
  }

  const getDashboardDetail = async () => {
    const result = await DashboardService.getDashboardDetail({ dashboardId });

    const formData = JSON.parse(result.payload);

    methods.setValue("date", formData["date"]);
    methods.setValue("firstEvent", formData["firstEvent"]);
    methods.setValue("returnEvent", formData["returnEvent"]);
    methods.setValue("frequency", formData["frequency"]);
    // setType(formData["type"]);
  }

  const onSubmit = async (data) => {
    setIsLoading(true);
    setData([]);

    const payload = proceedParam(data);

    const type = watchfrequency ?? FREQUENCY_VALUE.WEEKLY;

    try {
      let fromDate = "";
      let toDate = "";
      if (date !== undefined) {
        fromDate = date[0];
        toDate = date[1];
      }
      const result = await AnalysisService.getCohort({ ...payload, type, app_id: appId,from_date:fromDate,to_date:toDate });
      const data = {};

      let cohorts = new Set([]);

      for (const record of result) {
        cohorts.add(record.date);
      }

      cohorts = Array.from(cohorts);

      let maximumLength = 1;

      let name = 'Day';

      if (type === FREQUENCY_VALUE.MONTHLY) {
        name = 'Month'
      } else if (type === FREQUENCY_VALUE.WEEKLY) {
        name = 'Week'
      }

      if (cohorts.length > 1) {
        if (type === FREQUENCY_VALUE.MONTHLY) {
          const startMonth = moment(cohorts[0], MONTH_FORMAT);
          const endMonth = moment(cohorts[cohorts.length - 1], MONTH_FORMAT);

          maximumLength = endMonth.diff(startMonth, 'months');
        } else if (type === FREQUENCY_VALUE.WEEKLY) {
          const startWeek = cohorts[0];
          const endWeek = cohorts[cohorts.length - 1];

          maximumLength = getDiffWeek(startWeek, endWeek);
        } else {
          const startDate = moment(cohorts[0], DATE_FORMAT);
          const endDate = moment(cohorts[cohorts.length - 1], DATE_FORMAT);

          maximumLength = endDate.diff(startDate, 'days');
        }

        setHeader(Array.from({ length: maximumLength + 1 }, (_, index) => `${name} ${index}`));
      } else {
        setHeader(Array.from({ length: cohorts.length + 1 }, (_, index) => `${name} ${index}`));
      }

      for (const cohort of cohorts) {
        let name;

        if (type === FREQUENCY_VALUE.MONTHLY) {
          const data = cohort.split('-');

          name = moment(data, MONTH_FORMAT).format(DISPLAY_MONTH_FORMAT);
        } else if (type === FREQUENCY_VALUE.WEEKLY) {
          const data = cohort.split('-');

          name = `Week ${data[0]}, ${data[2]}`;
        } else {
          name = moment(cohort, DATE_FORMAT).format(DISPLAY_DATE_CALENDAR_FORMAT);
        }

        data[cohort] = {
          name: `Cohort of ${name}`,
          total: 0,
          data: [
            100.0,
            ...Array.from({ length: maximumLength }, () => 0)
          ],
        }

        maximumLength -= 1;
      }

      for (const record of result) {
        data[record.date].total = record.total_users;
        data[record.date].data[record.number] = record.percentage;
      }

      setData(Object.values(data));
    } catch (error) {
      const { message } = error;

      dispatch(showError({ message }));
    }

    setIsLoading(false);
  };

  const appOptions = useMemo(() => {
    return buildAppList(appList, PATHS.COHORT);
  }, [appList]);

  useEffect(() => {
    if (isArray(data) && data.length > 0) {
      methods.handleSubmit(onSubmit)();
    }
  }, [watchfrequency]);

  useEffect(() => {
    getParamEventNameOptions();
    getSegmentList();
  }, [appId]);

  useEffect(() => {
    if (dashboardId) {
      getDashboardDetail();
    }
  }, [dashboardId]);

  useEffect(() => {
    if (!initialRender && !dashboardId) {
      methods.setValue("firstEvent", {
        paramEventName: paramEventNameOptions[0].value,
        paramFilter: []
      });
      methods.setValue("returnEvent", {
        paramEventName: paramEventNameOptions[0].value,
        paramFilter: []
      });
      methods.setValue("frequency", FREQUENCY_VALUE.DAILY);
      methods.setValue("segment", "All user");
    } else {
      setInitialRender(false);
    }
  }, [location]);

  useEffect(() => {
    function checkSticky() {
      if ($(window).scrollTop() > 30) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    }

    window.addEventListener('scroll', checkSticky);

    () => window.removeEventListener('scroll', checkSticky);
  }, []);

  return (
    <div id="cohort">
      {isSticky && <div className='bg-stick'></div>}

      <div className={`${isSticky && 'sticky'}`}>
        <div className="flex items-center justify-between">
          <div className="flex ds-text-page-title">
            {t('COHORTS')}
            <Tooltip minWidth="250">
              <Tooltip.Label>
                <FontAwesomeIcon className="text-gray-500 ml-2" icon={faCircleQuestion} />
              </Tooltip.Label>
              <Tooltip.Content>
                <p>{t('COHORT_DESCRIPTION')}</p>
              </Tooltip.Content>
            </Tooltip>
          </div>

          <SaveToDashboard
            dashboardId={dashboardId}
            overviewPayload={methods.watch()}
            reportType={REPORT_TYPE.REPORT_COHORT}
          />
        </div>

        <div className="mt-4">
          <DsListBox listData={appOptions} />
        </div>
      </div>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div>
            <div className="flex justify-start items-center mb-3 mt-4">
              <TailwindCombobox name="segment" items={segmentsOptions} />
              <div className="mx-0.5"></div>
              <DateFilterToggleBox name="date" />
            </div>

            <div className='ds-block'>
              <div className="ds-text-form-title">{t('COHORT_EVENTS')}</div>

              <div className="ds-input-text mt-5">{t('FIRST_EVENT')}</div>
              <div className='border border-gray-300 rounded bg-white py-2 px-3 mt-1  '>
                <EventInput
                  name="firstEvent"
                  options={paramEventNameOptions}
                />
              </div>

              <div className="ds-input-text mt-3">{t('RETURN_EVENT')}</div>
              <div className='border border-gray-300 rounded bg-white py-2 px-3 mt-1'>
                <EventInput
                  name="returnEvent"
                  options={paramEventNameOptions}
                />
              </div>
            </div>

          </div>
          <div className='gap-2 mt-4'>
            <button
              type="submit"
              className="ds-button"
            >
              {isLoading ?
                <>
                  <LoadingSpinner className="me-2 mb-1" />
                  {t('LOADING')}
                </>
                :
                t('VIEW_COHORT')
              }
            </button>
          </div>

          <div className={isArray(data) && data.length > 0 ? 'visible' : 'invisible h-0'}>
            <div className='header flex justify-between items-center mt-3'>
              <div className="flex justify-start items-center">
                <FontAwesomeIcon className='icon px-2 py-3' icon={faChartLine} />
                <span className='ds-text-form-title ms-1'>{t('SEGMENT')}</span>
              </div>

              <div className="flex justify-end items-center py-1 me-2">
                <span className='ds-text-form-title me-2'>{t('FREQUENCY')}</span>

                <TailwindCombobox name={"frequency"} items={FREQUENCY_OPTIONS} />

              </div>
            </div>

            {
              isArray(data) && data.length > 0 &&
              <>
                <VisualizationLinechart
                  label={data.length > 0 ? data[0].name : ''}
                  data={data.length > 0 ? data[0].data : []}
                  frequency={watchfrequency}
                />
                <CohortTable header={header} data={data} />
              </>
            }



          </div>
        </form>
      </FormProvider>
    </div>
  );
}
