import { useDispatch } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next';
import LoadingSpinner from 'components/LoadingSpinner';
import { showError, showInfo } from "components/FlashMessage/flashMessageSlice";
import CompanyService from "../../../../services/company";


export default function DeleteUserModal({ email, isOpen = false, closeModal = () => { }, reset = () => { } }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const deleteUser = async () => {
    try {
      setIsLoading(true);

      await CompanyService.removeUser(email);

      dispatch(showInfo({ message: t("DELETE_VALUE_SUCCESSFULLY", { Value: "User" }) }));

      closeModal();
    } catch (error) {
      const { message } = error;
      dispatch(showError({ message }));
    }

    setIsLoading(false);
    reset();
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Delete User
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Do you want to delete user <strong>{email}</strong> from company?
                  </p>
                </div>

                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:ml-3"
                    onClick={closeModal}
                  >
                    {t('CANCEL')}
                  </button>

                  <button
                    type="button"
                    className="inline-flex items-center w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:w-auto"
                    onClick={deleteUser}
                  >
                    {isLoading ?
                      <>
                        <LoadingSpinner className="me-3" />
                        {t('LOADING')}
                      </>
                      :
                      t('DELETE')
                    }
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
