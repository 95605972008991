import {
  BLOCK_TYPE,
  BLOCKS,
  BUILDER_FIELD,
  FIELD_NAME,
  FILTER_TYPE,
  getQueryDateRelative,
  MERGE,
  OPERATOR_TYPE,
  SET_OPERATIONS
} from "views/segments/find_people/const";
import {isUndefinedOrEmpty} from "utils";
import {DATE_KEY_TYPE, DATE_KEY_TYPE_FIELD} from "consts/dateFilter";
import {convertDateKeyToDaysAgo} from "utils/util";

const processDateKey = (data) => {
  const dateFilters = [];

  const dateKey = data[FIELD_NAME.DATE_KEY];
  const dateKeyType = data[DATE_KEY_TYPE_FIELD];

  if (!isUndefinedOrEmpty(dateKeyType) && dateKeyType === DATE_KEY_TYPE.DATE_RELATIVE) {
    if (!isUndefinedOrEmpty(dateKey)) {
      const dateKeyRelative = dateKey.map((date) => {
        return convertDateKeyToDaysAgo(date);
      });
      dateFilters.push(
        {
          [BUILDER_FIELD.FILTER_TYPE]: FILTER_TYPE.WHERE,
          [BUILDER_FIELD.FIELD]: FIELD_NAME.DATE_KEY,
          [BUILDER_FIELD.OPERATOR]: OPERATOR_TYPE.GREATER_OR_EQUALS,
          [BUILDER_FIELD.VALUE]: getQueryDateRelative(dateKeyRelative[0]),
        },
        {
          [BUILDER_FIELD.FILTER_TYPE]: FILTER_TYPE.WHERE,
          [BUILDER_FIELD.FIELD]: FIELD_NAME.DATE_KEY,
          [BUILDER_FIELD.OPERATOR]: OPERATOR_TYPE.LESS_THAN_OR_EQUALS,
          [BUILDER_FIELD.VALUE]: getQueryDateRelative(dateKeyRelative[1]),
        }
      );
    }
  } else if (!isUndefinedOrEmpty(dateKey)) {
    dateFilters.push(
      {
        [BUILDER_FIELD.FILTER_TYPE]: FILTER_TYPE.WHERE,
        [BUILDER_FIELD.FIELD]: FIELD_NAME.DATE_KEY,
        [BUILDER_FIELD.OPERATOR]: OPERATOR_TYPE.GREATER_OR_EQUALS,
        [BUILDER_FIELD.VALUE]: dateKey[0],
      },
      {
        [BUILDER_FIELD.FILTER_TYPE]: FILTER_TYPE.WHERE,
        [BUILDER_FIELD.FIELD]: FIELD_NAME.DATE_KEY,
        [BUILDER_FIELD.OPERATOR]: OPERATOR_TYPE.LESS_THAN_OR_EQUALS,
        [BUILDER_FIELD.VALUE]: dateKey[1],
      }
    );
  }

  return dateFilters;
};

const processEvent = (data) => {
  const name = data[BUILDER_FIELD.NAME];
  const filters = data[BUILDER_FIELD.FILTERS];
  const finalFilters = processDateKey(data);

  if (!isUndefinedOrEmpty(name)) {
    finalFilters.push({
      [BUILDER_FIELD.FILTER_TYPE]: FILTER_TYPE.WHERE,
      [BUILDER_FIELD.FIELD]: FIELD_NAME.EVENT_NAME,
      [BUILDER_FIELD.OPERATOR]: OPERATOR_TYPE.EQUALS,
      [BUILDER_FIELD.VALUE]: name,
    });
  }

  const subFilters = [];

  if (!isUndefinedOrEmpty(filters)) {
    filters.forEach((filter) => {
      if (!isUndefinedOrEmpty(filter)) {
        subFilters.push({
          [BUILDER_FIELD.FILTER_TYPE]: FILTER_TYPE.WHERE,
          [BUILDER_FIELD.FIELD]: filter[BUILDER_FIELD.NAME],
          [BUILDER_FIELD.OPERATOR]: filter[BUILDER_FIELD.OPERATOR],
          [BUILDER_FIELD.VALUE]: filter[BUILDER_FIELD.VALUE],
        });
      }
    });
  }

  if (!isUndefinedOrEmpty(subFilters)) {
    finalFilters.push({
      [BUILDER_FIELD.FILTER_TYPE]: FILTER_TYPE.WHERE,
      [BUILDER_FIELD.FIELD]: FIELD_NAME.EVENT_PROPERTIES,
      [BUILDER_FIELD.FILTERS]: subFilters,
    });
  }

  return finalFilters;
};

const processUserFrom = (data) => {
  const finalFilters = processDateKey(data);
  const filters = data[BUILDER_FIELD.FILTER];

  if (!isUndefinedOrEmpty(filters)) {
    Object.keys(filters).forEach((key) => {
      const filter = filters[key];
      if (!isUndefinedOrEmpty(filter)) {
        finalFilters.push({
          [BUILDER_FIELD.FILTER_TYPE]: FILTER_TYPE.WHERE,
          [BUILDER_FIELD.FIELD]: key,
          [BUILDER_FIELD.OPERATOR]: OPERATOR_TYPE.IN,
          [BUILDER_FIELD.VALUE]: filter,
        });
      }
    });
  }

  return [
    {
      [BUILDER_FIELD.QUERY_PARAMS]: [
        FIELD_NAME.CUSTOMER_ID,
        FIELD_NAME.CUSTOMER_PHONE,
        FIELD_NAME.CUSTOMER_EMAIL,
      ],
      [BUILDER_FIELD.FILTERS]: finalFilters,
    },
  ];
};

const processUserDid = (data) => {
  const events = data[BUILDER_FIELD.EVENTS];
  const result = [];

  if (!isUndefinedOrEmpty(events)) {
    events.forEach((event) => {
      if (!isUndefinedOrEmpty(event)) {
        result.push({
          [BUILDER_FIELD.QUERY_PARAMS]: [
            FIELD_NAME.CUSTOMER_ID,
            FIELD_NAME.CUSTOMER_PHONE,
            FIELD_NAME.CUSTOMER_EMAIL,
          ],
          [BUILDER_FIELD.FILTERS]: processEvent(event),
        });
      }
    });
  }

  return result;
};

export const processData = (data) => {
  const payload = [];

  if (!isUndefinedOrEmpty(data[BLOCKS.USER_FROM]))
  {
    const userFrom = processUserFrom(data[BLOCKS.USER_FROM]);

    if (!isUndefinedOrEmpty(userFrom)) {
      payload.push({
        [BUILDER_FIELD.BLOCK_ID]: 1,
        [BUILDER_FIELD.BLOCK_TYPE]: BLOCK_TYPE.INTERSECT_DISTINCT,
        [BUILDER_FIELD.BLOCK_SUBS]: userFrom,
      });
    }
  }

  if (!isUndefinedOrEmpty(data[BLOCKS.USER_DID]))
  {
    const userDid = processUserDid(data[BLOCKS.USER_DID]);
    const merge = data[BLOCKS.USER_DID][BUILDER_FIELD.MERGE];

    if (!isUndefinedOrEmpty(userDid)) {
      payload.push({
        [BUILDER_FIELD.BLOCK_ID]: 2,
        [BUILDER_FIELD.BLOCK_TYPE]:
          merge === MERGE.AND
            ? BLOCK_TYPE.INTERSECT_DISTINCT
            : BLOCK_TYPE.UNION_DISTINCT,
        [BUILDER_FIELD.BLOCK_SUBS]: userDid,
      });
    }
  }

  if (!isUndefinedOrEmpty(data[BLOCKS.USER_NOT_DID]))
  {
    const userNotDid = processUserDid(data[BLOCKS.USER_NOT_DID]);

    if (!isUndefinedOrEmpty(userNotDid)) {
      payload.push({
        [BUILDER_FIELD.BLOCK_ID]: 3,
        [BUILDER_FIELD.BLOCK_TYPE]: BLOCK_TYPE.INTERSECT_DISTINCT,
        [BUILDER_FIELD.BLOCK_SUBS]: userNotDid,
      });
    }
  }

  return {
    [BUILDER_FIELD.SET_OPERATIONS]: SET_OPERATIONS,
    [BUILDER_FIELD.BLOCKS]: payload,
  };
};
