import DsIconCopy from "components/DsIcon/DsIconCopy";

export default function CopyButton({id, message}) {

  const myFunction = () => {
    navigator.clipboard.writeText(message).then(()=> {
        var tooltip = document.getElementById(id);
        tooltip.classList.add('active');
        setTimeout(() => {
            tooltip.classList.remove('active');
        }, 1500);
    })
  }

  return (
      <div className="tooltipContainer" onClick={() => myFunction(id, message)}>
        <div className={"w-5 h-5"}><DsIconCopy /></div>
        <div id={id} className="copyTooltip">Copied</div>
      </div>
  )
}
