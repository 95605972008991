import {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import ReactApexChart from 'react-apexcharts';
import {debounce} from 'lodash';
import {CHART_CONFIG} from "consts";

import ChartSerivce from 'services/chart';

import {parseStackedChartResponse} from '../chartProcessor';
import VisualisationEmpty from "../visualisation_empty";
import {formatNumber, isUndefinedOrEmpty} from "utils";
import {DS_CHART_COLORS} from "../consts";
import {titleCase} from "utils/util";

const getChartData = (key, categories, series) => {
  return {
    key: key,
    series: series,
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 2,
          borderRadiusApplication: "end",
          borderRadiusWhenStacked: "last",
        },
      },
      colors: DS_CHART_COLORS,
      xaxis: {
        type: "category",
        categories: categories,
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return formatNumber(Math.round(value * 10) / 10);
          },
        },
      },
      legend: {
        position: "right",
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
      // tooltip: {
      //   x: {
      //     format: 'dd/MM/yy'
      //   },
      //   shared: true,
      //   intersect: false,
      //   inverseOrder: true,
      //   hideEmptySeries: true,
      // },
      // colors: ['#000000', '#666666']
    },
  };
};

export default function VisualisationStackedChart({
                                                    appId,
                                                    globalFilter,
                                                    chartId,
                                                    dimensionValue,
                                                    metricValue,
                                                    showNonZero,
                                                    title = false,
                                                    frequencyValue
                                                  }) {
  const {t} = useTranslation();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const charts = useMemo(() => {
    const charts = [];
    data.map((v) => {
      let categories = [];
      let series = [];

      Object.keys(v).map((f) => {
        if (f === "date_key") {
          categories = v[f];  // Assign categories to date_key values
        } else {
          v[f].map((e) => {
            series.push(e);  // Add other values to the series
          });
        }
      });
      charts.push(getChartData(Object.keys(v).find(key => key !== "date_key"), categories, series));  // Build chart data after iteration
    });

    // console.log("series", JSON.stringify(series), JSON.stringify(categories))

    return charts
  }, [data]);

  const getData = async () => {
    if (dimensionValue && metricValue && globalFilter.length > 0) {

      const request = {
        [CHART_CONFIG.FIELD_CHART_NAME]: chartId,
        [CHART_CONFIG.FIELD_CHART_TYPE]: CHART_CONFIG.CHART_TYPE_STACKEDCHART,
        [CHART_CONFIG.FIELD_GLOBAL_FILTERS]: globalFilter,
        [CHART_CONFIG.FIELD_METRIC]: [],
        [CHART_CONFIG.FIELD_GROUP_BY]: [],
        // [CHART_CONFIG.FIELD_GROUP_BY]: [{[CHART_CONFIG.FIELD_NAME]: CHART_CONFIG.CHART_DATE_FIELD}, {[CHART_CONFIG.FIELD_NAME]: dimensionValue}],
        [CHART_CONFIG.FIELD_ORDER_BY]: [{[CHART_CONFIG.FIELD_NAME]: CHART_CONFIG.CHART_DATE_FIELD}],
      };

      if (!isUndefinedOrEmpty(frequencyValue)) {
        request[CHART_CONFIG.FIELD_GROUP_BY].push(
          {
            [CHART_CONFIG.FIELD_NAME]: frequencyValue,
            [CHART_CONFIG.FIELD_VALUE]: CHART_CONFIG.CHART_DATE_FIELD,
          }
        )
      } else {
        request[CHART_CONFIG.FIELD_GROUP_BY].push(
          {[CHART_CONFIG.FIELD_NAME]: CHART_CONFIG.CHART_DATE_FIELD}
        )
      }

      if (Array.isArray(metricValue)) {
        metricValue.map((v) => {
          request[CHART_CONFIG.FIELD_METRIC].push({[CHART_CONFIG.FIELD_NAME]: v})
        })
      } else {
        request[CHART_CONFIG.FIELD_METRIC].push({[CHART_CONFIG.FIELD_NAME]: metricValue})
      }

      const dimensions = Array.isArray(dimensionValue) ? dimensionValue : [dimensionValue];
      dimensions.map((v) => {
        if (v !== CHART_CONFIG.CHART_DATE_FIELD) {
          request[CHART_CONFIG.FIELD_GROUP_BY].push({[CHART_CONFIG.FIELD_NAME]: v})
        }
      })
      setIsLoading(true);
      if (request[CHART_CONFIG.FIELD_GROUP_BY].length <= 1) {
        setIsLoading(false);
        return
      }
      const result = await ChartSerivce.getChart(appId, request);
      if (result) {
        setData(parseStackedChartResponse({
          responsePayload: result.data,
          showNonZero: showNonZero
        }));
      } else {
        setData([])
      }
      setIsLoading(false);
    }
  };

  const debouncedGetData = debounce(getData, 500);

  useEffect(() => {
    debouncedGetData();
  }, [globalFilter, dimensionValue, metricValue, showNonZero]);


  return (
    <div className="w-full">
      <div className="">
        {
          charts.length > 0 ? charts.map((v, index) => (

            <div className={"space-y-2"} key={index}>
              {title ? <div className="ds-text-page-sub-title">{titleCase(v.key)}</div> : ''}
              <div className={"ds-white-background"}>
                <ReactApexChart key={index} options={v.options} series={v.series} type="bar" height={350}/>
              </div>
            </div>
          )) : <VisualisationEmpty isLoading={isLoading}/>
        }
      </div>
    </div>
  )
}
