import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from 'react-redux';
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight, faSquarePlus, faTrashCan,} from "@fortawesome/free-solid-svg-icons";

import Tooltip from "components/Tooltip";
import ToggleCheckbox from "components/ToggleCheckbox";
import TextInput from "components/TextInput";
import RangeInput from "components/RangeSlider";
import TailwindCombobox from "components/TailwindCombobox";

import './index.scss';

import IntegrationService from "services/integration";
import {processGetIntegrationData} from "./processor";
import {processSaveIntegrationData} from "../../processor";

import {showError, showInfo, showWarn} from "components/FlashMessage/flashMessageSlice";
import MetadataService from "services/metadata";
import {isUndefinedOrEmpty} from "utils";
import DsListBoxNoNavigate from "components/DsListBoxNoNavigate";
import {includingOptions, timeType, userFromOptions,} from "../../types.js";
import BackButton from "views/integration/meta_ads/back_button";
import {DsIconTooltip} from "components/DsIcon";
import LoadingSpinner from "components/LoadingSpinner";
import {PARTNER_INTEGRATION_DOCS_URL, PARTNERS} from "consts/partner";
import AlertInfo from "components/AlertMessage/AlertInfo";

export default function Integration() {
  const methods = useForm();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [initValue, setInitValue] = useState(null);
  const [datasensesEvents, setDatasensesEvents] = useState(null);
  const [partnerEvents, setPartnerEvents] = useState(null);
  const appList = useSelector((state) => state.app.appList);
  const [appId, setAppId] = useState(appList.length > 0 ? appList[0].app : null);

  const active = methods.watch('active');

  const clickThroughType = methods.watch('clickThroughType');
  const clickThrough = methods.watch('clickThrough');

  const inAppEventPostbacksEnabled = methods.watch('inAppEventPostbacksEnabled');

  const {fields, append, remove} = useFieldArray({
    control: methods.control,
    name: 'inAppEventPostbacks',
  });

  useEffect(() => {
    methods.setValue('clickThroughType', timeType.Days);
  }, []);

  const getEventsList = async () => {
    try {
      const events = await MetadataService.getFieldValues({appId, fieldKey: 'event_name'});
      const eventNames = [];

      events.map((item) => {
        eventNames.push({fieldKey: item, name: item})
      });

      setDatasensesEvents(eventNames)
      setPartnerEvents(eventNames)
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
  }

  const getAppIntegrationFacebook = async () => {
    try {
      const result = await IntegrationService.getAppIntegration({appId, partner: 'facebook'});

      if (result.appIntegrationFacebook.appId !== 'INTEGRATION_PARTNER_INACTIVE' && !isUndefinedOrEmpty(appId)) {
        const processedResult = processGetIntegrationData(result)
        if (processedResult.status !== 'INTEGRATION_PARTNER_INACTIVE' && !isUndefinedOrEmpty(appId)) {

          const tempValue = [
            {key: 'active', value: processedResult.appIntegrationFacebook.active},
            {key: 'facebookAppId', value: processedResult.appIntegrationFacebook.facebookAppId},
            {
              key: 'installReferrerDecryptionKey',
              value: processedResult.appIntegrationFacebook.installReferrerDecryptionKey
            },
            {key: 'clickThroughType', value: processedResult.appIntegrationFacebook.clickThroughType},
            {key: 'clickThrough', value: processedResult.appIntegrationFacebook.clickThrough},
            {
              key: 'inAppEventPostbacksEnabled',
              value: processedResult.appIntegrationFacebook.inAppEventPostbacksEnabled
            },
            {key: 'inAppEventPostbacks', value: processedResult.appIntegrationFacebook.inAppEventPostbacks},
          ]

          tempValue.map((kv) => {
            methods.setValue(kv.key, kv.value);
          });
          setInitValue(tempValue);

        }


      }
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
  }

  useEffect(() => {
    if (appId) {
      getAppIntegrationFacebook();
      getEventsList();
      discardChanges();
    }
  }, [appId]);

  const discardChanges = () => {
    if (initValue === null) {
      return;
    }
    initValue.map((kv) => {
      methods.setValue(kv.key, kv.value);
    });
  }

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      data.appId = appId;
      const payload = processSaveIntegrationData(data);
      await IntegrationService.saveIntegration({appId, partner: PARTNERS.FACEBOOK, ...payload});
      dispatch(showInfo({message: t("SAVE_VALUE_SUCCESSFULLY", {Value: "Integration with Facebook"})}));

      const message = await IntegrationService.checkFacebookDatasetIdPermission({appId})
      if (!isUndefinedOrEmpty(message.message)) {
        dispatch(showWarn(message));
      }

      const tempValue = [
        {key: 'active', value: data.active},
        {key: 'facebookAppId', value: data.facebookAppId},
        {key: 'installReferrerDecryptionKey', value: data.installReferrerDecryptionKey},
        {key: 'clickThroughType', value: data.clickThroughType},
        {key: 'clickThrough', value: data.clickThrough},
        {key: 'inAppEventPostbacksEnabled', value: data.inAppEventPostbacksEnabled},
        {key: 'inAppEventPostbacks', value: data.inAppEventPostbacks},
      ]
      setInitValue(tempValue);
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
    setIsLoading(false);
  }

  return (
    <div id="intergration-tab" className={"sm:p-3"}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="ds-input-text">
            <div className="ds-text-form-title">Meta ads integration</div>

            <div className="ds-text-lighter">
              Set up your integration details for this partner.
              <a href={PARTNER_INTEGRATION_DOCS_URL[PARTNERS.FACEBOOK]} target="_blank" rel="noopener noreferrer" className={`ml-1 ds-text-hyperlink`}>
                Learn more
              </a>
            </div>
            <div className={"w-1/3 mt-3"}>
              <DsListBoxNoNavigate listData={appList} onSelectionChange={(a) => {
                setAppId(a.appId)
              }}/>
            </div>
            <div className="flex justify-start items-center w-full mt-3">
              <div className="me-1">Activate partner</div>

              <Tooltip minWidth="380">
                <Tooltip.Label>
                  <div><DsIconTooltip className="h-full w-full text-blue-400"
                                      aria-hidden="true"/>
                  </div>
                </Tooltip.Label>
                <Tooltip.Content>
                  <p>Activate partner to enable attribution and send postbacks.</p>
                </Tooltip.Content>
              </Tooltip>

              <ToggleCheckbox name="active" className="ms-5"/>
            </div>
            <hr className="h-px mt-3 bg-gray-200 border-0"/>

            <div className={active ? 'visible' : 'invisible h-0'}>
              <div className="ds-text-form-title mt-3">General settings</div>

              <div className="flex justify-start items-center w-full mt-1">
                <div className="mr-1">Facebook Dataset Id</div>
              </div>
              <AlertInfo
                className={"my-2"}
                message={
                  'If you don\'t have a Facebook Dataset ID, you can create one by following the instructions in the Facebook docs "Create a dataset through an existing mobile app".'
                }
                link={"https://www.facebook.com/business/help/5818684664831465?id=490360542427371"}
              />
              <TextInput
                name="facebookAppId"
                className="mt-1 w-4/12"
              />

              <div className="flex justify-start items-center w-full mt-5">
                <div className="me-2">Install Referrer Decryption Key</div>

                <Tooltip minWidth="360">
                  <Tooltip.Label>
                    <div><DsIconTooltip className="h-full w-full text-blue-400"
                                        aria-hidden="true"/>
                    </div>
                  </Tooltip.Label>
                  <Tooltip.Content>
                    <p>DataSenses uses this key to decrypt user-level campaign data provided by Meta ads via the
                      Google
                      Install Referrer</p>
                  </Tooltip.Content>
                </Tooltip>
              </div>
              <TextInput name="installReferrerDecryptionKey" className="mt-1 w-4/12"/>

              {/*<div className="flex justify-start items-center w-full mt-5">*/}
              {/*  <div className="text-xs me-1">Limit Meta ads use of users' personal information (CCPA)</div>*/}

              {/*  <Tooltip minWidth="360">*/}
              {/*    <Tooltip.Label>*/}
              {/*      <div className="ds-tooltip"><DsIconTooltip/></div>*/}
              {/*    </Tooltip.Label>*/}
              {/*    <Tooltip.Content>*/}
              {/*      <p>Switch on this toggle to indicate that Meta ads should limit its use of California personal*/}
              {/*        information for a given event. If your app fully complies with CCPA regulations, you can leave*/}
              {/*        this toggle off.</p>*/}
              {/*    </Tooltip.Content>*/}
              {/*  </Tooltip>*/}

              {/*  <ToggleCheckbox name="limitMetaAds" className="ms-5"/>*/}
              {/*</div>*/}

              <hr className="h-px my-6 bg-gray-200 border-0"/>

              {/*<div>Install attribution</div>*/}

              <div className="flex justify-start items-center w-full mt-1">
                <div className="me-2">Install click-through lookback window</div>

                <Tooltip minWidth="380">
                  <Tooltip.Label>
                    <div><DsIconTooltip className="h-full w-full text-blue-400"
                                        aria-hidden="true"/>
                    </div>
                  </Tooltip.Label>
                  <Tooltip.Content>
                    <p>Set the maximum time after a click-to-app that an install can be attributed to the click.</p>
                  </Tooltip.Content>
                </Tooltip>
              </div>

              <div className="flex items-center mt-2">
                <TextInput id="clickThroughTypeHoursRadio" name="clickThroughType" type="radio"
                           value={timeType.Hours} className="w-3 h-3 mr-2"/>
                <label htmlFor="clickThroughTypeHoursRadio" className="mr-7 mb-1.5">{t('HOURS')}</label>

                <TextInput id="clickThroughTypeDaysRadio" name="clickThroughType" type="radio" value={timeType.Days}
                           className="w-3 h-3 mr-2"/>
                <label htmlFor="clickThroughTypeDaysRadio" className="mb-1.5">{t('DAYS')}</label>
              </div>

              <div className="flex justify-start items-center w-full mt-2">
                <RangeInput
                  name="clickThrough"
                  className="w-4/12"
                  defaultValue="23"
                  max={clickThroughType === timeType.Days ? 30 : 23}
                  min="1"
                  step="1"
                />

                <FontAwesomeIcon className="text-blue-700 ms-3" icon={faAngleRight}/>

                <div className="text-xs ms-1">{clickThrough} {clickThroughType}</div>
              </div>

              {/*<div className="flex justify-start items-center w-full mt-10">*/}
              {/*  <div className="text-xs me-2">Install view-through attribution</div>*/}

              {/*  <Tooltip minWidth="380">*/}
              {/*    <Tooltip.Label>*/}
              {/*      <div className="ds-tooltip"><DsIconTooltip/></div>*/}
              {/*    </Tooltip.Label>*/}
              {/*    <Tooltip.Content>*/}
              {/*      <p>Turn on to start attributing installs by users who view your ad, but don't click it</p>*/}
              {/*    </Tooltip.Content>*/}
              {/*  </Tooltip>*/}

              {/*  <ToggleCheckbox name="installViewThroughAttributionEnabled" className="ms-10"/>*/}
              {/*</div>*/}

              {/*<div className={installViewThroughAttributionEnabled ? 'visible' : 'invisible h-0'}>*/}
              {/*  <>*/}
              {/*    <div className="flex justify-start items-center w-full mt-3">*/}
              {/*      <div className="text-xs me-2">Install view-through lookback window</div>*/}

              {/*      <Tooltip minWidth="380">*/}
              {/*        <Tooltip.Label>*/}
              {/*          <div className="ds-tooltip"><DsIconTooltip/></div>*/}
              {/*        </Tooltip.Label>*/}
              {/*        <Tooltip.Content>*/}
              {/*          <p>Set the maximum time after a view that an install can be attributed to the view.</p>*/}
              {/*        </Tooltip.Content>*/}
              {/*      </Tooltip>*/}
              {/*    </div>*/}

              {/*    <div className="flex justify-start items-center w-full mt-2">*/}
              {/*      <RangeInput*/}
              {/*        name="installViewThroughLookbackHour"*/}
              {/*        className="w-4/12"*/}
              {/*        defaultValue="24"*/}
              {/*        max={24}*/}
              {/*        min="1"*/}
              {/*        step="1"*/}
              {/*      />*/}

              {/*      <FontAwesomeIcon className="text-blue-700 ms-3" icon={faAngleRight}/>*/}

              {/*      <div className="text-xs ms-1">{installViewThroughLookbackHour} <span*/}
              {/*        className="lowercase">{t('HOURS')}</span>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </>*/}
              {/*</div>*/}


              {/*<hr className="h-px my-6 bg-gray-200 border-0"/>*/}

              {/*<div className="flex justify-start items-center w-full mt-5">*/}
              {/*  <div className="me-2">Reinstall attribution</div>*/}

              {/*  <Tooltip minWidth="380">*/}
              {/*    <Tooltip.Label>*/}
              {/*      <div className="ds-tooltip"><DsIconTooltip/></div>*/}
              {/*    </Tooltip.Label>*/}
              {/*    <Tooltip.Content>*/}
              {/*      <p>Turn on to start attributing users who reinstall the app during their re-attribution*/}
              {/*        window.</p>*/}
              {/*    </Tooltip.Content>*/}
              {/*  </Tooltip>*/}

              {/*  <ToggleCheckbox name="reinstallAttributionEnabled" className="ms-10"/>*/}
              {/*</div>*/}

              {/*<div className={reinstallAttributionEnabled ? 'visible' : 'invisible h-0'}>*/}
              {/*  <div*/}
              {/*    className="flex justify-start items-center py-2 px-4 mb-4 text-yellow-800 rounded-lg bg-yellow-100 mt-3"*/}
              {/*    role="alert">*/}
              {/*    <FontAwesomeIcon className="text-lg text-yellow-500" icon={faTriangleExclamation}/>*/}
              {/*    <span className="text-sm ms-2">Reinstall attribution lookback windows and view-through status are taken from the install attribution settings</span>*/}
              {/*  </div>*/}
              {/*</div>*/}

              {/*<hr className="h-px my-6 bg-gray-200 border-0"/>*/}

              {/*<div className="flex justify-start items-center w-full mt-5">*/}
              {/*  <div className="me-2">Re-engagement attribution</div>*/}

              {/*  <Tooltip minWidth="380">*/}
              {/*    <Tooltip.Label>*/}
              {/*      <div className="ds-tooltip"><DsIconTooltip/></div>*/}
              {/*    </Tooltip.Label>*/}
              {/*    <Tooltip.Content>*/}
              {/*      <p>Turn on to start attributing re-engagements of your existing users</p>*/}
              {/*    </Tooltip.Content>*/}
              {/*  </Tooltip>*/}

              {/*  <ToggleCheckbox name="reengagementAttribution" className="ms-10"/>*/}
              {/*</div>*/}

              {/*<hr className="h-px my-6 bg-gray-200 border-0"/>*/}

              {/*<div className="flex justify-start items-center w-full mt-5">*/}
              {/*  <div className="me-2">Ignore active users for retargeting</div>*/}

              {/*  <Tooltip minWidth="380">*/}
              {/*    <Tooltip.Label>*/}
              {/*      <div className="ds-tooltip"><DsIconTooltip/></div>*/}
              {/*    </Tooltip.Label>*/}
              {/*    <Tooltip.Content>*/}
              {/*      <p>When enabled, retargeting conversions of users who were active during this window are*/}
              {/*        ignored.</p>*/}
              {/*    </Tooltip.Content>*/}
              {/*  </Tooltip>*/}

              {/*  <ToggleCheckbox name="ignoreActiveUsersRetargetingEnabled" className="ms-10"/>*/}
              {/*</div>*/}

              {/*<div className={ignoreActiveUsersRetargetingEnabled ? 'visible' : 'invisible h-0'}>*/}
              {/*  <>*/}
              {/*    <div className="flex justify-start items-center w-full mt-2">*/}
              {/*      <div className="me-2">Inactivity window</div>*/}

              {/*      <Tooltip minWidth="380">*/}
              {/*        <Tooltip.Label>*/}
              {/*          <div className="ds-tooltip"><DsIconTooltip/></div>*/}
              {/*        </Tooltip.Label>*/}
              {/*        <Tooltip.Content>*/}
              {/*          <p>Example: When the inactivity window is set to 7 days, re-engagements and re-attributions of*/}
              {/*            users who launched the app during the last 7 days, are not recorded in AppsFlyer.*/}
              {/*            Retargeting*/}
              {/*            conversions of users that were dormant in the last 7 days are recorded.</p>*/}
              {/*        </Tooltip.Content>*/}
              {/*      </Tooltip>*/}
              {/*    </div>*/}

              {/*    <div className="flex justify-start items-center w-full mt-2">*/}
              {/*      <RangeInput*/}
              {/*        name="inactivityWindowDay"*/}
              {/*        className="w-4/12"*/}
              {/*        defaultValue="7"*/}
              {/*        max="30"*/}
              {/*        min="1"*/}
              {/*        step="1"*/}
              {/*      />*/}

              {/*      <FontAwesomeIcon className="text-blue-700 ms-3" icon={faAngleRight}/>*/}

              {/*      <div className="text-xs ms-1">{inactivityWindowDay} {t("DAYS")}</div>*/}
              {/*    </div>*/}
              {/*  </>*/}
              {/*</div>*/}
              <hr className="h-px my-6 bg-gray-200 border-0"/>

              <div className="flex justify-start items-center w-full mt-5">
                <div className="me-2">In-app event postbacks</div>

                <Tooltip minWidth="380">
                  <Tooltip.Label>
                    <div><DsIconTooltip className="h-full w-full text-blue-400"
                                        aria-hidden="true"/>
                    </div>
                  </Tooltip.Label>
                  <Tooltip.Content>
                    <p>To use Meta ads optimization capabilities such as app event optimization, value-based
                      optimization, and dynamic ads, it's crucial to map SDK events to the corresponding Meta ads
                      event
                      ID. Custom events are not available for optimization.</p>
                  </Tooltip.Content>
                </Tooltip>

                <ToggleCheckbox name="inAppEventPostbacksEnabled" className="ms-10"/>
              </div>

              <div className={(inAppEventPostbacksEnabled && active) ? 'visible' : 'invisible h-0'}>
                <>
                  <div className="text-xs mt-2">Set up the events and data included in postbacks sent to this partner
                  </div>

                  <div className="grid grid-cols-5 gap-4 mt-5">
                    <div>DataSenses event</div>
                    <div>mapped to partner event</div>
                    <div>for users from</div>
                    <div>including</div>
                    <div></div>
                  </div>

                  {!isUndefinedOrEmpty(fields)
                    ?
                    fields.map((item, index) => (
                      <div key={item.id} className="grid grid-cols-5 gap-4 mt-2">
                        <div>
                          <TailwindCombobox
                            name={`inAppEventPostbacks.${index}.datasensesEvent`}
                            items={datasensesEvents}
                          />
                        </div>
                        <div>
                          <TailwindCombobox
                            name={`inAppEventPostbacks.${index}.partnerEvent`}
                            items={partnerEvents}/>
                        </div>
                        <div>
                          <TailwindCombobox name={`inAppEventPostbacks.${index}.usersOrigin`}
                                            items={userFromOptions}/>
                        </div>
                        <div>
                          <TailwindCombobox name={`inAppEventPostbacks.${index}.including`}
                                            items={includingOptions}/>
                        </div>
                        <div>
                          <FontAwesomeIcon className="text-red-500 cursor-pointer mt-2" onClick={() => remove(index)}
                                           icon={faTrashCan}/>
                        </div>
                      </div>
                    ))
                    :
                    <></>
                  }
                  <div
                    onClick={append}
                    className=" cursor-pointer ds-button w-fit mt-4"
                  >
                    <FontAwesomeIcon icon={faSquarePlus} className="me-2"/>
                    Add Events
                  </div>
                </>
              </div>
              <hr className="h-px mt-4 mb-3 bg-gray-200 border-0"/>
            </div>
          </div>

          <footer>
            <div className="flex justify-between items-center px-4 w-full">
              <BackButton/>

              <div className="flex justify-end items-center mb-1">
                <button
                  type="button"
                  className="ds-button-cancel mr-2"
                  onClick={discardChanges}
                >
                  {t('DISCARD_CHANGES')}
                </button>

                <button
                  type="submit"
                  className={`${isLoading && "cursor-not-allowed"} ds-button inline-flex focus:outline-none`}
                >
                  {isLoading ?
                    <>
                      <LoadingSpinner className="mt-0.5 me-2"/>
                      {t('LOADING')}
                    </>
                    :
                    t('SAVE_INTEGRATION')
                  }
                </button>
              </div>
            </div>
          </footer>
        </form>
      </FormProvider>
    </div>
  );
}
