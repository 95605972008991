import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistCombineReducers,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import flashMessageReducer from 'components/FlashMessage/flashMessageSlice';

import appReducer from './appSlice';
import userReducer from './userSlice';


const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['app', 'user'],
};

const persistedReducer = persistCombineReducers(
  persistConfig,
  {
    app: appReducer,
    user: userReducer,
    flashMessage: flashMessageReducer,
  }
);


export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
  }),
});

export const persistor = persistStore(store);
