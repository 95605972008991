import { Container } from "./Container";
import { Logo } from "components/Logo";
import { NavLink } from "./NavLink";
import { Button } from "./Button";
import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { useSelector } from 'react-redux';
import FlyoutSolution from "./FlyoutSolution";
import { DsIconMobileNavigation } from 'components/DsIcon';

export const navigation = [
  { name: "Sign In", href: "/login" },
  // { name: "Measurement", href: "/measurement" },
  { name: "Pricing", href: "/pricing" },
  { name: "Document", href: "https://developer.datasenses.io" },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function MobileNavLink({ href, children }) {
  return (
    <div>
      <a href={href}>
        {children}
      </a>
    </div>
  )
}

function MobileNavIcon({ open }) {
  return <DsIconMobileNavigation open={open}/>
}

function MobileNavigation() {
  return (
    <Popover>
      <Popover.Button
        className="relative z-10 flex h-8 w-8 items-center justify-center ui-not-focus-visible:outline-none"
        aria-label="Toggle Navigation"
      >
        {({ open }) => <MobileNavIcon open={open} />}
      </Popover.Button>
      <Transition.Root>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            as="div"
            className="absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5"
          >

            <nav className="flex flex-1 flex-col" aria-label="Sidebar">
              <ul role="list" className="-mx-2 space-y-1">
                {navigation.map((item) => (
                  <li key={item.name}>
                    <a
                      href={item.href}
                      className={classNames(
                        item.current ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                        'group flex gap-x-3 rounded-md p-2 pl-3 text-sm leading-6 font-semibold'
                      )}
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          </Popover.Panel>
        </Transition.Child>
      </Transition.Root>
    </Popover>
  )
}

export function Header({classNames}) {
  const isLogin = useSelector((state) => !!state.user.refreshToken);

  return (
    <header className={`py-6 fixed top-0 h-24 z-50 w-full glass-effect ${classNames}`}>
      <Container>
        <nav className="relative z-50 flex justify-between">
          <div className="flex items-center md:gap-x-12">
            <a href="#" aria-label="Home">
              <Logo className="h-10 w-auto"/>
            </a>
            <div className="hidden md:flex md:gap-x-6">
              <FlyoutSolution navigationName="Products" />
              <NavLink key={"pricing"} href="/pricing">Pricing</NavLink>
              <NavLink key={"document"} href="https://developer.datasenses.io">Document</NavLink>
            </div>
          </div>
          <div className="flex items-center gap-x-5 md:gap-x-8">
            {
              isLogin ?
                <Button to="/app" color="blue">
                  <span>
                    Go to console
                  </span>
                </Button>
                :
                <>
                  <div className="hidden md:block">
                    <NavLink href="/login">Sign in</NavLink>
                  </div>

                  <Button to="/request-demo" color="blue">
                    <span>
                      Request a demo
                    </span>
                  </Button>
                </>
            }
            <div className="-mr-1 md:hidden">
              <MobileNavigation />
            </div>
          </div>
        </nav>
      </Container>
    </header>
  )
}
