import { useController, useFormContext } from 'react-hook-form';

import Input from './input';


export default function EventInput({
  name,
  defaultValue = {
    paramEventName: "",
    paramFilter: [],
  },
  options = [],
  showDelete = false,
  className,
}) {
  const { control } = useFormContext();
  const { field } = useController({ name, control, defaultValue });

  const onChange = (value) => {
    field.onChange(value);
  };

  return (
    <Input
      onChange={onChange}
      value={field.value}
      options={options}
      showDelete={showDelete}
      className={`${className} ds-violet-border`}
    />
  );
}
