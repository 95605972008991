import { Header } from "views/landing_page/home/Header";
import { Footer } from "views/landing_page/home/Footer";
import { DsIconApple, DsIconGoogleAds, DsIconTiktok } from "components/DsIcon";
import DsIconFacebook from "components/DsIcon/DsIconFacebook";
import DsIconHaravan from "components/DsIcon/DsIconHaravan";
import DsIconShopify from "components/DsIcon/DsIconShopify";
import DsIconRocket from "components/DsIcon/DsIconRocket";
import DsIconTime from "components/DsIcon/DsIconTime";
import TailwindBackground from "components/TailwindBackground";
import ShopMetricPricing from "views/landing_page/shopmetric/pricing";
import { Faqs } from "views/landing_page/home/Faqs";
import DsIconPieChart from "components/DsIcon/DsIconPieChart";
import DsIconChart from "components/DsIcon/DsIconChart";
import DsIconConnect from "components/DsIcon/DsIconConnect";
import DsIconClock from "components/DsIcon/DsIconClock";
import LPTextBlock from "../LPTextBlock";
export default function GrowthAnalytics() {
  return (
    <>
      <div
        style={{
          background: "url(https://static.datasenses.io/datasense-bg.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div>
          <Header />
          <div className="px-4">
            <main className="pb-16 pt-56 lg:pt-56">
              <div className="mx-auto max-w-7xl ">
                <div>
                  <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-8 items-center">
                    <div>
                      <div>
                        <LPTextBlock
                          title={"Your Trusted Growth Analytics Provider"}
                          description={
                            "Get complete visibility into your marketing with ROI tracking, AI-driven optimization, and advanced analytics—all while safeguarding against ad fraud."
                          }
                          type={"bg_light"}
                        />
                      </div>
                    </div>
                    <div className="flex justify-end h-auto w-full">
                      <img
                        className="w-full h-auto lg:ml-14"
                        src={"https://static.datasenses.io/roi.png"}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="pt-32">
                <div className="mx-auto glass-effect">
                  <div className={"p-8 lg:p-16 "}>
                    <div className="grid grid-row-1 gap-8">
                      <div className="grid lg:grid-cols-2 sm:grid-cols-1">
                        <div
                          className={
                            "flex lg:justify-start sm:justify-start items-center"
                          }
                        >
                          <div>
                            <LPTextBlock
                              title={"Return On Investment Measurement"}
                              description={
                                "Measure impact of every single dollar you spend across all channels. Monitor at Product level, not only campaign level Monitor at KOL level, not only channel level"
                              }
                              type={"bg_light"}
                            />
                          </div>
                        </div>
                        <div>
                          <div className={"flex justify-center"}>
                            <img
                              className="w-full h-auto"
                              src={"https://static.datasenses.io/roi.png"}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>

                      <div className="grid lg:grid-cols-2 sm:grid-cols-1">
                        <div
                          className={
                            "flex lg:justify-start sm:justify-start items-center"
                          }
                        >
                          <div>
                            <LPTextBlock
                              title={"Online To Offline Solution"}
                              description={
                                "This type of solution typically involves generating a deep link that users can click, which then directs them to specific content or actions within an app or website."
                              }
                              type={"bg_light"}
                            />
                          </div>
                        </div>
                        <div>
                          <div className={"flex justify-center"}>
                            <img
                              className="w-3/4 h-auto"
                              src={
                                "https://static.datasenses.io/datasenses_qr.png"
                              }
                              alt=""
                            />
                          </div>
                        </div>
                      </div>

                      <div className="grid lg:grid-cols-2 sm:grid-cols-1">
                        <div
                          className={
                            "flex lg:justify-start sm:justify-start items-center"
                          }
                        >
                          <div>
                            <LPTextBlock
                              title={"Understand Customer"}
                              description={
                                "Look at your business from customers' point of views. Who are the most valuable customers? Support Funnel analysis Support Cohort analysis Support Customer Segmentation analysis"
                              }
                              type={"bg_light"}
                            />
                          </div>
                        </div>
                        <div>
                          <div className={"flex justify-center"}>
                            <img
                              className="w-3/4 h-auto"
                              src={"https://static.datasenses.io/ds_funnel.png"}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>

                      <div className="grid lg:grid-cols-2 sm:grid-cols-1">
                        <div
                          className={
                            "flex lg:justify-start sm:justify-start items-center"
                          }
                        >
                          <div>
                            <LPTextBlock
                              title={"Recommendation"}
                              description={
                                "Leverage Data & AI to control your budget and optimize your campaigns."
                              }
                              type={"bg_light"}
                            />
                          </div>
                        </div>
                        <div>
                          <div className={"flex justify-center"}>
                            <img
                              className="w-3/4 h-auto"
                              src={"	https://static.datasenses.io/customer.png"}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>

                      <div className="grid lg:grid-cols-2 sm:grid-cols-1">
                        <div
                          className={
                            "flex lg:justify-start sm:justify-start items-center"
                          }
                        >
                          <div>
                            <LPTextBlock
                              title={"Fraud Detection System"}
                              description={
                                "Detect & prevent ad fraud of the app install journey, abusers who are unusual earning the interest from your business"
                              }
                              type={"bg_light"}
                            />
                          </div>
                        </div>
                        <div>
                          <div className={"flex justify-center"}>
                            <img
                              className="w-3/4 h-auto"
                              src={"	https://static.datasenses.io/fraud.png"}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </main>
            <Faqs />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
