import React, {Fragment} from "react";
import {Dialog, Transition} from "@headlessui/react";
import DataSourceFormUpdate from "./data_source_form_update";
import LogoComponent from "views/integration/data_source_configurations/logo";


export default function UpdateDataSource({isOpen, closeModal, reset, id, partner, connections}) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25"/>
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className="relative max-w-2xl w-full transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex items-center w-full">
                  <LogoComponent partner={partner}/>
                  <Dialog.Title
                    className="ds-text-form-title ml-2"
                  >
                    Update Configuration
                  </Dialog.Title>
                </div>

                <DataSourceFormUpdate
                  id={id}
                  closeModal={closeModal}
                  reset={reset}
                  partner={partner}
                  connections={connections}
                />

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
