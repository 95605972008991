import {Header} from "../../landing_page/home/Header";


export default function PrivacyVietnamese() {
  return (
    <>
      <Header/>
      <div className="bg-white lg:px-8 pb-16 pt-56 lg:pt-56">
        <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">CHÍNH SÁCH BẢO MẬT THÔNG TIN
            CÁ NHÂN
          </h1>
          <p className="mt-10 leading-8">
            Crypto Portable là một app dùng để xem giá của thị trường Crypto. Chúng tôi ôn trọng quyền riêng tư của
            khách hàng và cam kết bảo mật thông tin cá nhân của khách hàng khi khách hàng tin vào chúng tôi cung cấp
            thông tin cá nhân của khách hàng khi sử dụng app. Đây là nguyên tắc khi tiếp cận quyền riêng tư, thông tin
            cá nhân tại CryptoPortable
          </p>
          <p className="mt-10 leading-8">
            Chính Sách Bảo Mật Thông Tin Cá Nhân này bao gồm các nội dung:
          </p>
          <ul className="list-decimal ml-5 space-y-4">
            <li className="pl-5">
              Sự Chấp Thuận
            </li>
            <li className="pl-5">
              Mục Đích Thu Thập
            </li>
            <li className="pl-5">
              Phạm Vi Thu Thập
            </li>
            <li className="pl-5">
              Thời Gian Lưu Trữ
            </li>
            <li className="pl-5">
              Không Chia Sẻ Thông Tin Cá Nhân Khách Hàng
            </li>
            <li className="pl-5">An Toàn Dữ Liệu</li>
            <li className="pl-5">
              Quyền Của Khách Hàng Đối Với Thông Tin Cá Nhân
            </li>
            <li className="pl-5">
              Thông Tin Liên Hệ
            </li>
          </ul>

          <div className="mt-10 max-w-2xl space-y-4 leading-8">
              <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">1. Sự Chấp Thuận
              </h2>
              <p>
                Bằng việc trao cho chúng tôi thông tin cá nhân của bạn, sử dụng CryptoPortable bạn đồng ý thông tin cá nhân của bạn sẽ được thu thập, sử dụng như được nêu trong Chính Sách này. Nếu bạn không đồng ý với Chính Sách này, bạn dừng cung cấp cho chúng tôi bất cứ thông tin cá nhân nào và/hoặc sử dụng các quyền như được nêu tại Mục 7 dưới đây.
                Chúng tôi bảo lưu quyền sửa đổi, bổ sung nhằm hoàn thiện đối với Chính Sách này vào bất kỳ thời điểm nào. Chúng tôi khuyến khích bạn thường xuyên xem lại Chính Sách Bảo Mật Thông Tin Cá Nhân này để có được những cập nhật mới nhất đảm bảo bạn biết và thực hiện quyền quản lý thông tin cá nhân của bạn.
              </p>
            </div>
            <div className="mt-10 max-w-2xl space-y-4 leading-8">
              <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">2. Mục Đích Thu Thập
              </h2>
              <p>
                Chúng tôi thu thập thông tin hành vi trong app chỉ cần thiết nhằm phục vụ cho các mục đích:
                * Để xem những tính năng nào được sử dụng qua đó cải thiện ứng dụng
              </p>
            </div>
            <div className="mt-10 max-w-2xl space-y-4 leading-8">
              <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">3. Phạm Vi Thu Thập
              </h2>
              <p>Chúng tôi thu thập thông tin cá nhân của bạn khi:
                • Bạn tương tác với chúng tôi. Chúng tôi sử dụng cookies và công nghệ theo dấu khác để thu thập một số thông tin khi bạn tương tác trên CryptoPortable
              </p>
            </div>
            <div className="mt-10 max-w-2xl space-y-4 leading-8">
              <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">4. Thời Gian Lưu Trữ
              </h2>
              <p>Thông tin cá nhân của khách hàng sẽ được lưu trữ cho đến khi khách hàng có yêu cầu hủy bỏ hoặc khách hàng tự đăng nhập và thực hiện hủy bỏ (Tham khảo mục 7). Trong mọi trường hợp thông tin cá nhân của khách hàng sẽ được bảo mật trên máy chủ của CryptoPortable
              </p>
            </div>
            <div className="mt-10 max-w-2xl space-y-4 leading-8">
              <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">5. Không Chia Sẻ Thông Tin Cá Nhân Khách Hàng
              </h2>
              <p>
                Chúng tôi sẽ không cung cấp thông tin cá nhân của bạn cho bất kỳ bên thứ ba nào, trừ một số hoạt động cần thiết dưới đây:
                • Yêu cầu pháp lý: Chúng tôi có thể tiết lộ các thông tin cá nhân nếu điều đó do luật pháp yêu cầu và việc tiết lộ như vậy là cần thiết một cách hợp lý để tuân thủ các quy trình pháp lý.
              </p>
            </div>
            <div className="mt-10 max-w-2xl space-y-4 leading-8">
              <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">6. An Toàn Dữ Liệu
              </h2>
              <p>
                Chúng tôi luôn nỗ lực để giữ an toàn thông tin cá nhân của khách hàng, chúng tôi đã và đang thực hiện nhiều biện pháp an toàn, bao gồm:
                • Bảo đảm an toàn trong môi trường vận hành: chúng tôi lưu trữ không tin cá nhân khách hàng trong môi trường vận hành an toàn và chỉ có nhân viên, đại diện và nhà cung cấp dịch vụ có thể truy cập trên cơ sở cần phải biết. Chúng tôi tuân theo các tiêu chuẩn ngành, pháp luật trong việc bảo mật thông tin cá nhân khách hàng.
                • Trong trường hợp máy chủ lưu trữ thông tin bị hacker tấn công dẫn đến mất mát dữ liệu cá nhân khách hàng, chúng tôi sẽ có trách nhiệm thông báo vụ việc cho cơ quan chức năng để điều tra xử lý kịp thời và thông báo cho khách hàng được biết.
                • Các thông tin thanh toán: được bảo mật theo tiêu chuẩn ngành.
              </p>
            </div>
            <div className="mt-10 max-w-2xl space-y-4 leading-8">
              <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
                7. Quyền Của Khách Hàng Đối Với Thông Tin Cá Nhân
              </h2>
              <p>
                • Khách hàng có quyền cung cấp thông tin cá nhân cho chúng tôi và có thể thay đổi quyết định đó vào bất cứ lúc nào.
                1) Yêu cầu Truy cập đến hoặc Sửa Dữ Liệu Cá Nhân
                • Nếu Khách hàng đã có hành vi trên app chúng tôi mà chúng tôi đang lưu giữ hoặc kiểm soát thông qua việc sử dụng app.
                • Chúng tôi bảo lưu quyền từ chối sửa dữ liệu cá nhân của bạn theo các quy định của pháp luật về bảo vệ bí mật thông tin cá nhân, trường hợp các điều luật đó yêu cầu và/hoặc cho phép một tổ chức từ chối sửa dữ liệu cá nhân trong các trường hợp như thế.
              </p>
            </div>
            <div className="mt-10 max-w-2xl space-y-4 leading-8">
              <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
                8. Thông Tin Liên Hệ
              </h2>
              <p>
                Nếu bạn có câu hỏi hoặc bất kỳ thắc mắc nào về Chính Sách này hoặc thực tế việc thu thập, quản ly thông tin cá nhân của chúng tôi, xin vui lòng liên hệ với chúng tôi bằng cách:
                • Gửi thư điện tử đến địa chỉ email: ducps2@gmail.com
              </p>
            </div>
        </div>
      </div>
    </>
)
}
