import { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import { PATHS, EMAIL_REGEX } from "consts";

import TextInput from 'components/TextInput';
import { showError, showInfo } from 'components/FlashMessage/flashMessageSlice';

import UserService from 'services/user';

import { Header } from "../landing_page/home/Header";
import { Footer } from "../landing_page/home/Footer";
import { Faqs } from "../landing_page/home/Faqs";
import {DsIconSlogan} from "../../components/DsIcon";
import {Hero} from "views/landing_page/home/Hero";
import RequestForm from './RequestForm';

export default function RequestDemo() {
  const { t } = useTranslation();
  const methods = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isLogin = useSelector((state) => !!state.user.refreshToken);

  useEffect(() => {
    if (isLogin) {
      navigate(PATHS.APP);
    }
  }, [isLogin]);

  const onSubmit = async (data) => {
    try {
      const { message } = await UserService.requestDemo(data);
      dispatch(showInfo({ message: "We will contact you soon." }));
      navigate(PATHS.HOMEPAGE);
    } catch (error) {
      const { message } = error;
      console.log(error)
      dispatch(showError({ message }));
    }
  };

  return (
    <>
      <Header />
      <div
        style={{
          background: "url(https://static.datasenses.io/datasense-bg.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
      <div className={"px-2"}>
        <main className={"pt-56 lg:pt-56 mx-auto max-w-7xl pb-32"}>
            <div className={"lp-background-2 py-10"}>
              <RequestForm/>
            </div>
        
        </main>
        <div>
            <Faqs/>
          </div>
        <Footer/>
      </div>
      </div>
    </>
  )
}
