export const tabLink = {
  // OVERVIEW: '#overview',
  MY_PROFILE: '#my-profile',
  COMPANY_PROFILE: '#company-profile',
  USERS: '#users',
}

export const tabs = [
  // { name: 'Overview', href: tabLink.OVERVIEW },
  { name: 'My Profile', href: tabLink.MY_PROFILE },
  { name: 'Company Profile', href: tabLink.COMPANY_PROFILE },
  { name: 'Users', href: tabLink.USERS },
];

export default tabs;
