import { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { debounce } from "lodash";
import { buildAppList } from "utils/app";
import { PATHS } from "consts";

import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import TextInput from 'components/TextInput/WithoutForm';
import ChartService from 'services/chart_record';
import DsListBox from "components/DsListBox";
import { showError } from "components/FlashMessage/flashMessageSlice";

import AddChart from './add_chart';
import ChartAction from "./chart_action";
import EditChartModal from "./edit_chart";
import DeleteChartModal from "./delete_chart";

import './index.scss';

export default function ChartList() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { appId } = useParams();

  const appList = useSelector((state) => state.app.appList);

  const appOptions = useMemo(() => {
    return buildAppList(appList, PATHS.CHART);
  }, [appList]);

  const [chartList, setChartList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const [chartId, setChartId] = useState('');
  const [chartName, setChartName] = useState('');
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);

  const handleUpdate = (id, name) => {
    setChartId(id);
    setChartName(name);
    setIsUpdateOpen(true);
  };

  const handleDelete = (id, name) => {
    setChartId(id);
    setChartName(name);
    setIsDeleteOpen(true);
  }

  const getChartList = async () => {
    const query = { name: searchTerm };

    try {
      const charts = await ChartService.listCharts({ appId, query });
      setChartList(charts)
    } catch (error) {
      const { message } = error;
      dispatch(showError({ message }));
    }
  };

  useEffect(() => {
    getChartList();
  }, []);

  const debouncedSearch = debounce(() => {
    getChartList();
  }, 300);

  useEffect(() => {
    debouncedSearch();

    return () => {
      debouncedSearch.cancel();
    };
  }, [searchTerm]);

  return (
    <div id="chart-list">
      <div className="font-bold text-xl me-2">{t('CHART')}</div>

      <DsListBox listData={appOptions} />

      <div className="bg-white rounded shadow-md w-full mt-5 py-2">
        <div className="flex justify-between items-center px-4">
          <div className="flex flex-row">
            <TextInput
              icon={faMagnifyingGlass}
              placeholder={t('SEARCH_BY_NAME')}
              onChange={(e) => setSearchTerm(e.target.value)}
            />

            {/*<button className='filter-icon ml-3 flex items-center justify-center w-9 h-9 rounded'>*/}
            {/*  <AdjustmentsHorizontalIcon className='h-6 w-6 text-blue-600'/>*/}
            {/*</button>*/}
          </div>
          <AddChart reset={getChartList} />
        </div>

        <div className='general-info py-3 mt-2'>
          <div className="flex justify-between px-4">
            {/*<span>{t('SHOWING_VALUE_OF_TOTAL', { Value: 6, Total:6, Name: t('USERS') })}</span>*/}

            <div className="flex flex-row items-center text-blue-700">
              {/*<DocumentIcon className='h-5 w-5'/>*/}
              {/*<span className='cursor-pointer'>{ t('DOWNLOAD_AS_CSV') }</span>*/}
            </div>
          </div>
        </div>
        <div className="relative">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3"><input type="checkbox" disabled /></th>
                <th scope="col" className="px-6 py-3">{t('NAME')}</th>
                <th scope="col" className="px-6 py-3">{t('DEFINITION')}</th>
                <th scope="col" className="px-6 py-3">{t('CREATED_BY')}</th>
                <th scope="col" className="px-6 py-3"></th>
              </tr>
            </thead>
            <tbody>
              {chartList && chartList.map((chart, index) => (
                <tr key={index} className="bg-white border-b">
                  <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                    <input type="checkbox" />
                  </th>
                  <td className="px-6 py-4">{chart.name}</td>
                  <td className="px-6 py-4">{chart.definition}</td>
                  <td className="px-6 py-4">{chart.createdBy}</td>
                  <td className="px-6 py-4">
                    <ChartAction
                      chartId={chart.chartId}
                      updateChart={() => handleUpdate(chart.chartId, chart.name)}
                      deleteChart={() => handleDelete(chart.chartId, chart.name)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* <div className="flex justify-between mt-3 px-2">
              <div></div>
              <div></div>
              <div className='flex flex-row items-center'>
                <span className='me-2 text-sm'>{t('SHOW')}</span>
                <SelectInput name="pageSize" options={entries} />
              </div>
            </div> */}
      </div>

      <DeleteChartModal
        id={chartId}
        name={chartName}
        isOpen={isDeleteOpen}
        closeModal={() => setIsDeleteOpen(false)}
        reset={getChartList}
      />

      <EditChartModal
        id={chartId}
        isOpen={isUpdateOpen}
        closeModal={() => setIsUpdateOpen(false)}
        reset={getChartList}
      />
    </div>
  );
};
