import HttpMethod from "consts/httpMethod";
import {processErrorResponse, processRequest, processSuccessResponse} from "utils";
import QRCode from 'qrcode'
import * as errors from "errors";


export default {
  async createDeeplink(data) {
    const ep = {
      baseURL: process.env.REACT_APP_DEEPLINK_API,
      url: '/url/bulk',
      method: HttpMethod.POST
    };
    try {
      const res = await processRequest(ep, { data});
      return processSuccessResponse(res);
    } catch (e) {
      return false;
    }
  },

  async updateDeeplink({templateId, shortURL, data}) {
    const ep = {
      baseURL: process.env.REACT_APP_DEEPLINK_API,
      url: `/url/items/${templateId}/${shortURL}`,
      method: HttpMethod.PUT
    };
    try {
      const res = await processRequest(ep, { data});
      return processSuccessResponse(res);
    } catch (e) {
      return false;
    }
  },

  async getDeeplinkById(templateId, linkId) {
    const ep = {
      baseURL: process.env.REACT_APP_DEEPLINK_API,
      url: `/url/items/${templateId}/${linkId}`,
      method: HttpMethod.GET
    };
    try {
      const res = await processRequest(ep);
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  async deleteLink({templateId, linkId}) {
    const ep = {
      baseURL: process.env.REACT_APP_DEEPLINK_API,
      url: `/url/items/${templateId}/${linkId}`,
      method: HttpMethod.DELETE
    };
    try {
      const res = await processRequest(ep, {});
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  async getDeepLinkList(templateId) {
    const ep = {
      baseURL: process.env.REACT_APP_DEEPLINK_API,
      url: `/url/${templateId}?filter[created_at.sort]=desc&limit=20`,
      method: HttpMethod.GET
    };
    try {
      const res = await processRequest(ep);
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  async generateQrCode(link) {
    try {
      return QRCode.toDataURL(link)
    } catch (e) {
      console.log(e)
      return [];
    }
  },

  async createDeeplinkTemplate(data) {
    const ep = {
      baseURL: process.env.REACT_APP_DEEPLINK_API,
      url: '/template/create',
      method: HttpMethod.POST
    };
    try {
      const res = await processRequest(ep, {data});
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
    }
  },

  async getListTemplates()  {
    const ep = {
      baseURL: process.env.REACT_APP_DEEPLINK_API,
      url: '/template/list',
      method: HttpMethod.GET
    };
    try {
      const res = await processRequest(ep, {});
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
    }
  },

  async getTemplateById(id) {
    const ep = {
      baseURL: process.env.REACT_APP_DEEPLINK_API,
      url: `/template/${id}`,
      method: HttpMethod.GET
    };
    try {
      const res = await processRequest(ep, {});
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
    }
  },

  async updateTemplate(data) {
    const ep = {
      baseURL: process.env.REACT_APP_DEEPLINK_API,
      url: `/template/update`,
      method: HttpMethod.PUT
    };
    try {
      const res = await processRequest(ep, {data});
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async deleteTemplateById(id) {
    const ep = {
      baseURL: process.env.REACT_APP_DEEPLINK_API,
      url: `/template/${id}`,
      method: HttpMethod.DELETE
    };
    try {
      const res = await processRequest(ep, {});
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  }
}
