import './index.scss';
import React, {useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import DateFilterToggleBox from "../../../analytics/components/DateFilterToggleBox";
import {json} from "react-router-dom";


const jsonResponse = [
  {
    order_id: "201218V2Y6E59M",
    username: "phuongnguyen64",
    created_at: "2024-05-28",
    status: "CANCELLED",
    amount: "400000",
    item_id: "2600144043",
    quantity: "1",
    payment_method: "Bank Transfer",
    shipping_carrier: "Giao Hàng Nhanh",
    currency: "VND"
  },
  {
    order_id: "209345D3A7B23N",
    username: "vinhle",
    created_at: "2024-05-27",
    status: "PROCESSING",
    amount: "250000",
    item_id: "3498567210",
    quantity: "2",
    payment_method: "Credit Card",
    shipping_carrier: "Giao Hàng Tiết Kiệm",
    currency: "VND"
  },
  {
    order_id: "304867R5S1G92T",
    username: "tuannguyen",
    created_at: "2024-05-26",
    status: "COMPLETED",
    amount: "800000",
    item_id: "1265437890",
    quantity: "3",
    payment_method: "Cash on Delivery",
    shipping_carrier: "Viettel Post",
    currency: "VND"
  },
  {
    order_id: "401593G2H5J83P",
    username: "jane_doe",
    created_at: "2024-05-25",
    status: "PROCESSING",
    amount: "1200000",
    item_id: "8754123690",
    quantity: "1",
    payment_method: "Bank Transfer",
    shipping_carrier: "Giao Hàng Nhanh",
    currency: "VND"
  },
  {
    order_id: "509284F3R6T74Q",
    username: "duchoa",
    created_at: "2024-05-24",
    status: "COMPLETED",
    amount: "350000",
    item_id: "3256789541",
    quantity: "2",
    payment_method: "Cash on Delivery",
    shipping_carrier: "Giao Hàng Nhanh",
    currency: "VND"
  },
  {
    order_id: "608541L2M8N47V",
    username: "anhtuan",
    created_at: "2024-05-23",
    status: "PENDING",
    amount: "600000",
    item_id: "9632587410",
    quantity: "1",
    payment_method: "Credit Card",
    shipping_carrier: "Giao Hàng Tiết Kiệm",
    currency: "VND"
  },
  {
    order_id: "701234I1J9K86U",
    username: "david_anderson",
    created_at: "2024-05-22",
    status: "COMPLETED",
    amount: "450000",
    item_id: "7412589630",
    quantity: "3",
    payment_method: "Bank Transfer",
    shipping_carrier: "Giao Hàng Tiết Kiệm",
    currency: "VND"
  },
  {
    order_id: "809678K4L3T25V",
    username: "sarah_adams",
    created_at: "2024-05-21",
    status: "PROCESSING",
    amount: "900000",
    item_id: "8523697410",
    quantity: "1",
    payment_method: "Cash on Delivery",
    shipping_carrier: "Viettel Post",
    currency: "VND"
  },
  {
    order_id: "904321A5B7C63E",
    username: "michael_brown",
    created_at: "2024-05-20",
    status: "COMPLETED",
    amount: "200000",
    item_id: "9512364870",
    quantity: "2",
    payment_method: "Credit Card",
    shipping_carrier: "Giao Hàng Nhanh",
    currency: "VND"
  },
  {
    order_id: "100987X2Y4Z6W81",
    username: "lisa_jackson",
    created_at: "2024-05-19",
    status: "CANCELLED",
    amount: "750000",
    item_id: "3698521470",
    quantity: "1",
    payment_method: "Bank Transfer",
    shipping_carrier: "Giao Hàng Nhanh",
    currency: "VND"
  },
  {
    order_id: "100987X2Y4Z6W81",
    username: "lisa_jackson",
    created_at: "2024-05-18",
    status: "CANCELLED",
    amount: "750000",
    item_id: "3698521470",
    quantity: "1",
    payment_method: "Bank Transfer",
    shipping_carrier: "Giao Hàng Nhanh",
    currency: "VND"
  },
  {
    order_id: "100987X2Y4Z6W81",
    username: "lisa_jackson",
    created_at: "2024-05-16",
    status: "CANCELLED",
    amount: "750000",
    item_id: "3698521470",
    quantity: "1",
    payment_method: "Bank Transfer",
    shipping_carrier: "Giao Hàng Nhanh",
    currency: "VND"
  },
  {
    order_id: "100987X2Y4Z6W81",
    username: "lisa_jackson",
    created_at: "2024-05-17",
    status: "CANCELLED",
    amount: "750000",
    item_id: "3698521470",
    quantity: "1",
    payment_method: "Bank Transfer",
    shipping_carrier: "Giao Hàng Nhanh",
    currency: "VND"
  },
  {
    order_id: "100987X2Y4Z6W81",
    username: "lisa_jackson",
    created_at: "2024-05-31",
    status: "CANCELLED",
    amount: "750000",
    item_id: "3698521470",
    quantity: "1",
    payment_method: "Bank Transfer",
    shipping_carrier: "Giao Hàng Nhanh",
    currency: "VND"
  },
  {
    order_id: "100987X2Y4Z6W82",
    username: "tuannguyen",
    created_at: "2024-06-01",
    status: "CANCELLED",
    amount: "750000",
    item_id: "3698521470",
    quantity: "1",
    payment_method: "Bank Transfer",
    shipping_carrier: "Giao Hàng Nhanh",
    currency: "VND"
  },
  {
    order_id: "100987X2Y4Z6W83",
    username: "trinhbankieu",
    created_at: "2024-06-02",
    status: "CANCELLED",
    amount: "750000",
    item_id: "3698521470",
    quantity: "1",
    payment_method: "Bank Transfer",
    shipping_carrier: "Giao Hàng Nhanh",
    currency: "VND"
  },
  {
    order_id: "100987X2Y4Z6W84",
    username: "hoangho",
    created_at: "2024-06-04",
    status: "CANCELLED",
    amount: "750000",
    item_id: "3698521470",
    quantity: "1",
    payment_method: "Bank Transfer",
    shipping_carrier: "Giao Hàng Nhanh",
    currency: "VND"
  },
  {
    order_id: "100987X2Y4Z6W81",
    username: "lisa_jackson",
    created_at: "2024-06-05",
    status: "CANCELLED",
    amount: "750000",
    item_id: "3698521470",
    quantity: "1",
    payment_method: "Bank Transfer",
    shipping_carrier: "Giao Hàng Nhanh",
    currency: "VND"
  },
  {
    order_id: "100987X2Y4Z6W81",
    username: "vannguyen",
    created_at: "2024-06-06",
    status: "CANCELLED",
    amount: "750000",
    item_id: "3698521470",
    quantity: "1",
    payment_method: "Bank Transfer",
    shipping_carrier: "Giao Hàng Nhanh",
    currency: "VND"
  },
  {
    order_id: "100987X2Y4Z6W81",
    username: "vannguyen2",
    created_at: "2024-06-07",
    status: "CANCELLED",
    amount: "750000",
    item_id: "3698521470",
    quantity: "1",
    payment_method: "Bank Transfer",
    shipping_carrier: "Giao Hàng Nhanh",
    currency: "VND"
  },
];

export default function DataShopee() {

  const methods = useForm();
  const [orders, setOrders] = useState([]);
  const dateInput = methods.watch("dateInput")

  useEffect(() => {
    let fromDate = "";
    let toDate = "";
    if (dateInput !== undefined) {
      fromDate = dateInput[0];
      toDate = dateInput[1];
    } else {
      fromDate = "2024-05-23";
      toDate = "2024-05-23";
    }
    const filteredOrders = jsonResponse.filter(order => order.created_at >= fromDate && order.created_at <= toDate);
    setOrders(filteredOrders);
  }, [JSON.stringify(dateInput)]);

  return (
    <FormProvider {...methods}>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto mt-10">
            <h1 className="text-base font-semibold leading-6 text-gray-900">Your Shopee Shop ID: 113782</h1>
          </div>
        </div>
        <div className="flex justify-start items-center mb-3 mt-4">
          <DateFilterToggleBox name="dateInput"/>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                    Order ID
                  </th>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                    Username
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Status
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Amount
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Item ID
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Quantity
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Created At
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Payment Method
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Currency
                  </th>
                </tr>
                </thead>
                <tbody className="bg-white">

                {orders.map((order) => (
                  <tr>
                    <td
                      className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">{order.order_id}</td>
                    <td
                      className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">{order.username}</td>
                    <td
                      className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">{order.status}</td>
                    <td
                      className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">{order.amount}</td>
                    <td
                      className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">{order.item_id}</td>
                    <td
                      className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">{order.quantity}</td>
                    <td
                      className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">{order.created_at}</td>
                    <td
                      className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">{order.payment_method}</td>
                    <td
                      className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">{order.currency}</td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
    </FormProvider>
  );
}
