import {Dialog, Transition} from '@headlessui/react'
import {Fragment} from 'react'
import {useTranslation} from 'react-i18next';


export default function DeleteModal({
                                      name,
                                      objectType,
                                      isOpen = false,
                                      onDelete = () => {
                                      },
                                      closeModal = () => {
                                      },
                                    }) {
  const {t} = useTranslation();

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25"/>
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Delete {objectType}
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Do you want to delete <strong>{name}</strong> {objectType}?
                  </p>
                </div>

                <div className="mt-5 mb-1 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className={`ds-button-delete focus:outline-none`}
                    onClick={onDelete}
                  >
                    Delete
                  </button>

                  <button
                    type="button"
                    className="ds-button-cancel mr-2"
                    onClick={closeModal}
                  >
                    {t('CANCEL')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
