import { useTranslation } from 'react-i18next';
import { faA } from '@fortawesome/free-solid-svg-icons';
import { FILTER_TYPES } from 'consts';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ChartService from 'services/chart';

import DateTime from './DateTime';
import AddField from './AddField';
import ValueSelect from './ValueSelect';
import ValueInput from './ValueInput';

import './index.scss'

/**
 * MultiFilterBar component for managing multiple filters.
 * @param {Object} props - The props object.
 * @param {Object} props.value - The value object representing the current filter state.
 * @param {Function} [props.onChange=()=>{}] - The function to handle changes in filter state.
 * @returns {JSX.Element} A React component representing the multi-filter bar.
 */
export default function MultiFilterBar({
  value,
  onChange = () => { },
}) {
  const { t } = useTranslation();

  const { appId } = useParams();

  const [dimensionFields, setDimensionFields] = useState([]);
  const [metricFields, setMetricFields] = useState([]);

  const addFilterField = (item) => {
    if (value.filter.some(i => i.fieldKey === item.fieldKey)) {
      return;
    }

    const newValue = { ...value };

    newValue.filter.push({
      name: item.name,
      fieldKey: item.fieldKey,
      fieldType: item.fieldType,
      value: [],
      type: FILTER_TYPES.INCLUDE,
    });

    setTimeout(() => onChange(newValue));
  };

  const addShowField = (item) => {
    if (value.show.some(i => i.fieldKey === item.fieldKey)) {
      return;
    }

    const newValue = { ...value };

    newValue.show.push({
      name: item.name,
      fieldKey: item.fieldKey,
      fieldType: item.fieldType,
      value: "",
      type: FILTER_TYPES.GREATER_THAN,
    });

    setTimeout(() => onChange(newValue));
  };

  const setValueFilterField = (index, newFieldValue, newFieldType) => {
    const newValue = { ...value };
    newValue.filter[index].value = newFieldValue;
    newValue.filter[index].type = newFieldType;
    onChange(newValue);
  };

  const setValueShowField = (index, newFieldValue, newFieldType) => {
    const newValue = { ...value };
    newValue.show[index].value = newFieldValue;
    newValue.show[index].type = newFieldType;
    onChange(newValue);
  };

  const deleteFilterField = (index) => {
    const newValue = { ...value };

    delete newValue.filter[index];

    onChange(newValue);
  };

  const deleteShowField = (index) => {
    const newValue = { ...value };

    delete newValue.show[index];

    onChange(newValue);
  }

  const setDateTimeValue = (dates) => {
    const newValue = { ...value };
    newValue.filter[0].value = dates;
    onChange(newValue);
  };

  const getField = async () => {
    try {
      const result = await ChartService.getFieldList({ appId });

      setMetricFields(result.filter(item => item.attribute === 'metric'));

      setDimensionFields(result.filter(item => item.attribute === 'dimension'));
    } catch (error) {

    }
  };

  useEffect(() => {
    getField();
  }, []);

  return (
    <div className='multi-filter-bar bg-white h-100 shadow-md rounded-lg'>
      <div className="child flex flex-row items-center px-2 pt-1.5 pb-0.5 border-b">
        <label className="uppercase text-gray-600 text-sm me-3">{t('FILTER')}</label>

        <div className='flex flex-wrap items-center'>
          <DateTime className="mb-1" value={value.filter.dates} onChange={setDateTimeValue} />

          {value.filter.slice(1).map((item, index) => (
            <ValueSelect
              className="mb-1"
              icon={faA}
              key={index}
              name={item.name}
              fieldKey={item.fieldKey}
              value={item.value}
              type={item.type}
              onChange={(value, type) => setValueFilterField(index + 1, value, type)}
              onDelete={() => deleteFilterField(index + 1)}
            />
          ))}

          <AddField defaultOptions={dimensionFields} onAdd={addFilterField} />
        </div>
      </div>

      <div className="child flex flex-row items-center px-2 pb-0.5">
        <label className="uppercase text-gray-600 text-sm me-3">{t('SHOW')}</label>
        <div className='flex flex-wrap items-center'>
          {value.show.map((item, index) => (
            <ValueInput
              className="mb-1"
              icon={faA}
              key={index}
              name={item.name}
              fieldKey={item.fieldKey}
              value={item.value}
              type={item.type}
              onChange={(value, type) => setValueShowField(index, value, type)}
              onDelete={() => deleteShowField(index)}
            />
          ))}

          <AddField defaultOptions={metricFields} onAdd={addShowField} />
        </div>
      </div>
    </div>
  );
}
