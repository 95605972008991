import { Header } from "views/landing_page/home/Header";
import { CallToAction } from "views/landing_page/home/CallToAction";
import { Faqs } from "views/landing_page/home/Faqs";
import { Footer } from "views/landing_page/home/Footer";
import LPTextBlock from "views/landing_page/LPTextBlock";
import LPImageBlock from "views/landing_page/LPImageBlock";
import {DsIconLpCohort, DsIconLpTable} from "components/DsIcon";
import { CheckIcon } from "@heroicons/react/16/solid";

export default function LandingPageAnalytics() {
  return (
    <>
      <Header />
      <main className="pb-16 pt-56 lg:pt-56">
        <div className={"grid grid-rows-1 gap-y-20 lg:gap-y-26 py-10"}>
          <div className={"lp-background-2"}>
            <div className={"grid grid-cols-1 lg:grid-cols-2 mx-auto max-w-7xl lg:px-8 gap-2"}>
              <LPTextBlock
                title={"Attribution Measurement"}
                description={"Help you to answer which channels, campaigns, platforms contribute the most to acquire an install or a purchase"}
                description_items={[
                  <span className={"flex flex-wrap justify-start items-center"}><CheckIcon
                    className={"w-5 h-5 mr-2 text-blue-600"}/> <span className={"font-bold"}>Platform:&nbsp; </span> Support Android, iOS and web</span>,
                  <span className={"flex flex-wrap justify-start items-center"}><CheckIcon
                    className={"w-5 h-5 mr-2 text-blue-600"}/> <span className={"font-bold"}>Channel:&nbsp; </span> Calculate the attribution across channels from organic to paid ads</span>,
                  <span className={"flex flex-wrap justify-start items-center"}><CheckIcon
                    className={"w-5 h-5 mr-2 text-blue-600"}/> <span
                    className={"font-bold"}>Cross platform:&nbsp; </span> Measure web to web, web to app</span>,
                ]}
                type={"bg_light"}
              />
              <img
                className="w-full h-auto lg:ml-20"
                src={"https://static.datasenses.io/attribution.png"}
                alt=""
              />
            </div>
          </div>
          <div className={"lp-background-2"}>
            <div className={"grid grid-cols-1 lg:grid-cols-2 mx-auto max-w-7xl lg:px-8 gap-2"}>
              <LPTextBlock
                title={"ROI Calculation"}
                description={"Map your marketing insights with your campaigns"}
                description_items={[
                  <span className={"flex flex-wrap justify-start items-center"}><CheckIcon
                    className={"w-5 h-5 mr-2 text-blue-600"}/> <span className={"font-bold"}>Cost:&nbsp;</span> Get cost data of all partners you are using on demand</span>,
                  <span className={"flex flex-wrap justify-start items-center"}><CheckIcon
                    className={"w-5 h-5 mr-2 text-blue-600"}/> <span className={"font-bold"}>Combine:&nbsp; </span> cost and campaign together</span>,
                ]}
                type={"bg_light"}
              />
              <img
                className="w-full h-auto"
                src={"https://static.datasenses.io/roi.png"}
                alt=""
                sizes="(min-width: 2048px) 67.8125rem, (min-width: 1280px) 100vw, 45rem"
              />
            </div>
          </div>
          <div className={"lp-background-2"}>
            <div className={"grid grid-cols-1 lg:grid-cols-2 mx-auto max-w-7xl lg:px-8 gap-2"}>
              <LPImageBlock
                imageContent={<DsIconLpTable/>}
              />
              <LPTextBlock
                title={"SKAdNetwork"}
                description={"Support to track iOS install campaigns"}
                description_items={[
                  <span className={"flex flex-wrap justify-start items-center"}><CheckIcon
                    className={"w-5 h-5 mr-2 text-blue-600"}/> Effortless integration</span>,
                  <span className={"flex flex-wrap justify-start items-center"}><CheckIcon
                    className={"w-5 h-5 mr-2 text-blue-600"}/> Efficient iOS Growth</span>,
                ]}
                type={"bg_light"}
              />
            </div>
          </div>
        </div>
        {/*<pricing/>*/}
      </main>
      <CallToAction/>
      <Faqs/>
      <Footer/>
    </>
  )
}
