import { DsIconAnalysis, DsIconLoading } from "components/DsIcon";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function VisualisationEmpty({ isLoading, type }) {
  return (
    isLoading ? <div
      className={classNames(type === "KPI" ? "h-24" : "h-72", "flex justify-center items-center relative block w-full mt-2 border-t")}
    >
      <div className="ds-input-text">
        <div role="status">
          <div className="mx-auto h-14 w-14"><DsIconLoading /></div>
        </div>
      </div>
    </div>
      : <div
        className={classNames(type === "KPI" ? "h-24" : "h-72", `flex justify-center items-center w-full mt-2 ds-border-gray`)}
      >
        <div className="ds-input-text">
          <div className="mx-auto h-14 w-14"><DsIconAnalysis /></div>
          <span className="">Data Empty</span>
        </div>
      </div>
  )
}
