import {useEffect, useMemo, useState} from 'react';
import ReactApexChart from 'react-apexcharts';
import {useTranslation} from 'react-i18next';
import {debounce} from 'lodash';

import {CHART_CONFIG} from "consts";
import ChartSerivce from 'services/chart';
import {parseBarChartResponse} from '../chartProcessor';
import VisualisationEmpty from "../visualisation_empty";
import {formatNumber, isUndefinedOrEmpty} from "utils";
import {DS_CHART_COLORS} from "../consts";
import {titleCase} from "utils/util";

const getChartData = (key, categories, series) => {
  return {
    key: key,
    series: [series],
    options: {
      tooltip: {
        y: {
          formatter: function (value) {
            return formatNumber(value); // Customize the number format for the tooltip
          },
        },

      },
      chart: {
        zoom: {
          enabled: false,
        },
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "60%",
          borderRadius: 2,
          borderRadiusApplication: "end",
        },
      },
      colors: DS_CHART_COLORS,
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: categories,
        labels: {
          formatter: function (value) {
            return formatNumber(Math.round(value * 10) / 10);
          },
          style: {

            // fontSize: "18px",
          },
        },
        axisBorder: {
          show: false,
        },
      },
      grid: {
        // row: {
        //   colors: ["#e5e5e5", "transparent"],
        //   opacity: 0.5,
        // },
        // column: {
        //   colors: ["#f8f8f8", "transparent"],
        // },
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      // colors: ['#000000']
    },
  };
};
export default function VisualisationBarChart({
                                                appId,
                                                globalFilter = [],
                                                chartId,
                                                dimensionValue,
                                                metricValue,
                                                showNonZero,
                                                limitValue,
                                                orderValue,
                                                title = false
                                              }) {
  const {t} = useTranslation();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const charts = useMemo(() => {
    const charts = [];
    data.map((c) => {
      Object.entries(c).forEach(([key, value]) => {
        charts.push(getChartData(key, value.name,
          {
            name: 'Total',
            data: value.value
          }
        ))
      })
    });

    return charts;
  }, [data]);

  const getData = async () => {
    if (dimensionValue && metricValue && globalFilter.length > 0) {
      const request = {
        [CHART_CONFIG.FIELD_CHART_NAME]: chartId,
        [CHART_CONFIG.FIELD_CHART_TYPE]: CHART_CONFIG.CHART_TYPE_BARCHART,
        [CHART_CONFIG.FIELD_GLOBAL_FILTERS]: globalFilter,
      };

      const metrics = Array.isArray(metricValue) ? metricValue : [metricValue];
      request[CHART_CONFIG.FIELD_METRIC] = metrics.map(metric => ({[CHART_CONFIG.FIELD_NAME]: metric}));

      const dimensions = Array.isArray(dimensionValue) ? dimensionValue : [dimensionValue];
      request[CHART_CONFIG.FIELD_GROUP_BY] = dimensions.map(dimension => ({[CHART_CONFIG.FIELD_NAME]: dimension}));


      if (!isUndefinedOrEmpty(limitValue) && limitValue !== "All") {
        request[CHART_CONFIG.FIELD_LIMIT] = [{[CHART_CONFIG.FIELD_VALUE]: String(limitValue)}]
      }

      if (!isUndefinedOrEmpty(orderValue)) {
        request[CHART_CONFIG.FIELD_ORDER_BY] = [{[CHART_CONFIG.FIELD_NAME]: metrics[0] + orderValue}];
      }

      setIsLoading(true)
      const result = await ChartSerivce.getChart(appId, request);
      if (result) {
        setData(parseBarChartResponse({
          responsePayload: result.data,
          showNonZero: showNonZero
        }));
      } else {
        setData([]);
      }
      setIsLoading(false)
    }
  };

  const debouncedGetData = debounce(getData, 500);

  useEffect(() => {
    debouncedGetData();
  }, [globalFilter, dimensionValue, metricValue, showNonZero]);

  return (
    <div className="w-full">
      <div className="space-y-4 pt-4">
        {
          charts.length > 0 ? charts.map((v, index) => (
            <div className={""} key={index}>
              {title ? <div className="ds-text-page-sub-title">{titleCase(v.key)}</div> : ''}
              <div className={"ds-white-background"}>
                <ReactApexChart key={index} options={v.options} series={v.series} type="bar" height={350}/>
              </div>
            </div>
          )) : <VisualisationEmpty isLoading={isLoading}/>
        }
      </div>
    </div>
  )
}
