export const tabLink = {
  INFO: '#info',
  CONVERSION: '#conversion',
  SDK: '#sdk',
}

export const tabs = [
  { name: 'App Information', href: tabLink.INFO },
  { name: 'Attribution Setting', href: tabLink.CONVERSION },
  { name: 'SDK Integration', href: tabLink.SDK },
];

export default tabs;
