import {isUndefinedOrEmpty} from "utils";

function omitEmptyFields(inputDict) {
  return Object.fromEntries(
    Object.entries(inputDict).filter(([_, value]) => value !== undefined && value !== null && value !== "")
  );
}

export const processConversionValueData = (data) => {

  if (!isUndefinedOrEmpty(data.eventMappings)) {
    for (let index = 0; index < data.eventMappings.length; index++) {
      if (isUndefinedOrEmpty(data.eventMappings[index].eventName)
        ||
        (
          isUndefinedOrEmpty(data.eventMappings[index].minEventValue) &&
          isUndefinedOrEmpty(data.eventMappings[index].maxEventValue) &&
          isUndefinedOrEmpty(data.eventMappings[index].minEventCount) &&
          isUndefinedOrEmpty(data.eventMappings[index].maxEventCount)
        )
      ) {
        throw new Error("Event name and at least one of four event condition is required")
      } else {
        data.eventMappings[index].minEventValue = parseInt(data.eventMappings[index].minEventValue)
        data.eventMappings[index].maxEventValue = parseInt(data.eventMappings[index].maxEventValue)
      }

      if (data.eventMappings[index].minEventValue > data.eventMappings[index].maxEventValue) {
        throw new Error("Min value should be less than or equal to max value")
      }
      if (parseInt(data.eventMappings[index].minEventCount) > parseInt(data.eventMappings[index].maxEventCount)) {
        throw new Error("Min count should be less than or equal to max count")
      }

      data.eventMappings[index] = omitEmptyFields(data.eventMappings[index]);
    }
  } else {
    throw new Error("At least one event is required")
  }

  return {
    displayName: data.displayName,
    lockEnabled: data.lockEnabled,
    fineValue: data.fineValue,
    coarseValue: data.coarseValue,
    eventMappings: data.eventMappings,
  }
}
