import { useDispatch } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';

import { showError, showInfo } from "components/FlashMessage/flashMessageSlice";
import TextInput from 'components/TextInput';

import DashboardService from 'services/dashboard';


export default function EditDashboardModal({ id, isOpen = false, closeModal = () => { }, reset = () => { } }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const methods = useForm();

  const onSubmit = async (data) => {
    try {
      await DashboardService.updateDashboard({ dashboardId: id, name: data.name });

      dispatch(showInfo({ message: t("UPDATE_VALUE_SUCCESSFULLY", { Value: "Dashboard" }) }));

      closeModal();

      reset();
    } catch (error) {
      const { message } = error;
      dispatch(showError({ message }));
    }

    reset();
  }

  const getDashboardDetail = async () => {
    if (id && isOpen) {
      const result = await DashboardService.getDashboardDetail({ dashboardId: id });
      methods.setValue('name', result.name);
    }
  }

  useEffect(() => {
    getDashboardDetail();
  }, [isOpen]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="ds-text-page-title"
                >
                  Update dashboard
                </Dialog.Title>

                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="gap-4 bg-white mt-5 ds-border-gray shadown-md">
                      <div className="p-5">
                        <div className="flex flex-wrap">
                          <div className="w-full">
                            <label className="ds-input-text required">{t('Dashboard name')}</label>
                            <TextInput
                              name="name"
                              className="w-full"
                              placeholder={t('ENTER_VALUE', { Value: 'dashboard name' })}
                              rules={
                                {
                                  required: t('VALUE_IS_REQUIRED', { Value: t('Dashboard name') }),
                                }
                              }
                            />
                          </div>
                        </div>

                        <div className="mt-10 mb-1 sm:flex sm:flex-row-reverse">
                          <button
                            type="submit"
                            className={`ds-button focus:outline-none`}
                          >
                            Update
                          </button>

                          <button
                            type="button"
                            className="ds-button-cancel mr-2"
                            onClick={closeModal}
                          >
                            {t('CANCEL')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </FormProvider>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
