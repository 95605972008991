import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {PATHS} from "consts";
import {
  DsIconApple,
  DsIconColoredGoogleAds,
  DsIconColoredMetaAds,
  DsIconHaravan,
  DsIconInstagram,
  DsIconShopify,
  DsIconTiktok
} from "components/DsIcon";
import './index.scss';
import React, {useState} from "react";
import DsIconLazada from "components/DsIcon/DsIconLazada";
import DsIconShopee from "components/DsIcon/DsIconShopee";
import DsIconFacebook from "../../components/DsIcon/DsIconFacebook";


const markets = [
  {
    logo: "https://static.datasenses.io/googleads.png",
    name: 'Google Ads (Adwords)',
    link: `${PATHS.APP}${PATHS.INTEGRATION}/google`,
    business: 'Ads network',
  },
  {
    logo: "https://static.datasenses.io/meta2.png",
    name: 'Meta',
    link: `${PATHS.APP}${PATHS.INTEGRATION}/meta`,
    business: 'Ads network & Social Media',
  },
  {
    logo: "https://static.datasenses.io/tiktok.png",
    name: 'Tiktok Business',
    link: `${PATHS.APP}${PATHS.INTEGRATION}/tiktok`,
    business: 'Ads network',
  },
  {
    logo: "https://static.datasenses.io/apple.png",
    name: 'Apple Search Ads',
    link: `${PATHS.APP}${PATHS.INTEGRATION}/apple_search`,
    business: 'Ads network',
  },
  {
    name: 'Haravan',
    link: `${PATHS.APP}${PATHS.INTEGRATION}/haravan`,
    business: 'Seller',
  },
  {
    name: 'Tiktok Shop',
    link: `${PATHS.APP}${PATHS.INTEGRATION}/tiktok-shop`,
    business: 'Seller',
  },
  {
    name: 'Shopify',
    link: `${PATHS.APP}${PATHS.INTEGRATION}/shopify`,
    business: 'Seller',
  },
  {
    name: 'Lazada',
    link: `${PATHS.APP}${PATHS.INTEGRATION}/lazada`,
    business: 'Seller',
  },
  {
    name: 'Shopee',
    link: `${PATHS.APP}${PATHS.INTEGRATION}/shopee`,
    business: 'Seller',
  }
]


export default function PartnerMarketplace() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [appInfo, setAppInfo] = useState({})

  const getIcon = (name) => {
    if (name === 'Google Ads (Adwords)') return <div className={"h-10 w-10"}><DsIconColoredGoogleAds/></div>
    if (name === 'Meta') return <div className={"h-10 items-center "}><DsIconColoredMetaAds/></div>
    if (name === 'Tiktok Business') return <div className={"h-10 w-10"}><DsIconTiktok/></div>
    if (name === 'Apple Search Ads') return <div className={"h-10 w-10"}><DsIconApple/></div>
    if (name === 'Haravan') return <div className={"h-10 w-10"}><DsIconHaravan/></div>
    if (name === 'Tiktok Shop') return <div className={"h-10 w-10"}><DsIconTiktok/></div>
    if (name === 'Shopify') return <div className={"h-10 w-10"}><DsIconShopify/></div>
    if (name === 'Lazada') return <div className={"h-10 w-10"}><DsIconLazada/></div>
    if (name === 'Shopee') return <div className={"h-10 w-10"}><DsIconShopee/></div>
  }

  return (
    <div id="partner_marketplace" className="relative">
      <div className="ds-text-page-title ds-block-title">{t('PARTNER_MARKETPLACE')}</div>

      <div className="grid grid-cols-3 ds-spacing-4 mt-5">
        {
          markets.map((item, index) => (
            <div
              key={index}
              onClick={() => navigate(item.link)}
              // className="marketplace select-none cursor-pointer rounded-lg shadow border overflow-hidden"
              className="ds-block-with-hover ds-input-text cursor-pointer"
            >
              <div className="py-2 px-4 flex m-4">

                {getIcon(item.name)}
                <div className={"ml-2"}>
                  <div className={"ds-text items-starts name font-bold "}>{item.name}</div>
                  {
                    item.name !== 'Meta'
                      ?
                      <div className="ds-text-lighter">{item.business}</div>
                      :
                      <div className="flex items-center">
                        <div className="ds-text-lighter">{item.business}</div>
                        <div className={" ml-0.5 h-5 w-5"}>
                          <DsIconFacebook/>
                        </div>
                        <div className={"h-5 w-5"}>
                          <DsIconInstagram/>
                        </div>
                      </div>

                  }
                </div>
              </div>

            </div>
          ))
        }
      </div>


    </div>
  )
}
