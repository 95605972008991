export const tabLink = {
  INTEGRATION: '#integration',
  ATTRIBUTION_LINK: '#atribution_link',
  // COST: '#insights',
  AD_REVENUE: '#ad_revenue',
  PERMISSIONS: '#permissions',
  CONNECTION: '#connections',
  // MOCK_DATA: '#data',
  // MOCK_DATA_IG: '#data_ig',
  // MOCK_DATA_FACEBOOK_ADS: '#data_facebook_ads',
  // MOCK_DATA_FACEBOOK_POST: '#data_facebook_post',

  CONFIGURATIONS: "#configurations",
}

export const tabs = [
  {name: 'INTEGRATION', href: tabLink.INTEGRATION},
  {name: 'Connection', href: tabLink.CONNECTION},
  // { name: 'Data', href: tabLink.MOCK_DATA },
  {name: 'Configurations', href: tabLink.CONFIGURATIONS},
];

export const facebookTabs = [
  {name: 'INTEGRATION', href: tabLink.INTEGRATION},
  {name: 'Connection', href: tabLink.CONNECTION},
  // { name: 'Data Facebook Ads', href: tabLink.MOCK_DATA_FACEBOOK_ADS },
  // { name: 'Data Facebook Post', href: tabLink.MOCK_DATA_FACEBOOK_POST },
  // { name: 'Data Instagram', href: tabLink.MOCK_DATA_IG },
  {name: 'Configurations', href: tabLink.CONFIGURATIONS},
];

export const googleTabs = [
  {name: 'INTEGRATION', href: tabLink.INTEGRATION},
  {name: 'Connection', href: tabLink.CONNECTION},
  {name: 'Configurations', href: tabLink.CONFIGURATIONS},
];

export const tiktokAdsTabs = [
  {name: 'INTEGRATION', href: tabLink.INTEGRATION},
  {name: 'Connection', href: tabLink.CONNECTION},
  {name: 'Configurations', href: tabLink.CONFIGURATIONS},
];

export const tiktokShopTabs = [
  {name: 'Configurations', href: tabLink.CONFIGURATIONS},
  {name: 'Connection', href: tabLink.CONNECTION},
];

export const appleTabs = [
  // {name: 'INTEGRATION', href: tabLink.INTEGRATION},
  {name: 'Connection', href: tabLink.CONNECTION},
  {name: 'Configurations', href: tabLink.CONFIGURATIONS},
];

export const lazadaTabs = [
  {name: 'Configurations', href: tabLink.CONFIGURATIONS},
  {name: 'Connection', href: tabLink.CONNECTION},
];

export const haravanTabs = [
  {name: 'Configurations', href: tabLink.CONFIGURATIONS},
  {name: 'Connection', href: tabLink.CONNECTION},
];

export const shopifyTabs = [
  {name: 'Configurations', href: tabLink.CONFIGURATIONS},
  {name: 'Connection', href: tabLink.CONNECTION},
];

export const shopeeTabs = [
  {name: 'Configurations', href: tabLink.CONFIGURATIONS},
  {name: 'Connection', href: tabLink.CONNECTION},
  {name: 'Data', href: tabLink.MOCK_DATA},
]

export default tabs;
