import { useEffect, useMemo, useRef, useState } from 'react';
import Chart from 'apexcharts';


export default function FunnelVisualisationBarChart({ isPercent = false, name = "", data = [] }) {
  const chartRef = useRef(null);


  useEffect(() => {
    if (!chartRef.current) return;


    for (let i = 0; i < data.length; i++) {
      data[i].data = isPercent ? data[i].data.map((item) => item.percentage.toFixed(2)) : data[i].data.map((item) => item.totalUsers);
    }


    const chartOptions = {
      series: data,
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '20%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: data[0].data.map((_, index) => `${name} ${index + 1}`),
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return isPercent ? value + '%' : value;
          }
        }
      }
    };

    const chart = new Chart(chartRef.current, chartOptions);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [isPercent, data]);

  return <div ref={chartRef}></div>;
}
