import { useRef, useState, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import { extractData } from 'utils';

import { sortTypes } from './types';

import './index.scss';


/**
 * Represents an attribute.
 * @typedef {Object} Attribute
 * @property {string} name - The name of the attribute.
 * @property {string} type - The type of the attribute.
 */

/**
 * @typedef {Object} Split
 * @property {Attribute[]} attributes - The attributes of the split.
 * @property {SplitData[]} data - The data within the split.
 * @property {Array<string>|null} key - The key of the split.
 */

/**
 * @typedef {Object} SplitData
 * @property {string} [attributeName] - The user attribute value.
 * @property {Split} SPLIT - The user attribute value.
 */

/**
 * Represents a dataset.
 * @typedef {Object} Dataset
 * @property {Array<Attribute>} attributes - An array of attribute objects.
 * @property {Array<SplitData>} data - An array of data objects.
 * @property {Array<string>|null} key - Key property.
 */

/**
 * MultiLevelTable component renders a table based on the provided data and label.
 * @param {Object} props - The props object containing configuration parameters.
 * @param {string} [props.label="Users, Session"] - The label for the table.
 * @param {Dataset} [props.data] - The data object containing attributes and nested data.
 * @param {Array} [props.percentLevelShow=[]] - The array containing levels to show as percentages.
 * @param {string} [props.className] - The CSS class name for styling purposes.
 */
export default function MultiLevelTable({
  label = "Users, Session",
  data = {
    "attributes": [
      {
        "name": "users",
        "type": "string"
      },
      {
        "name": "session",
        "type": "string"
      },
      {
        "name": "SPLIT",
        "type": "DATASET"
      }
    ],
    "data": [
      {
        "SPLIT": {
          "attributes": [
            {
              "name": "users",
              "type": "string"
            },
            {
              "name": "session",
              "type": "string"
            },
            {
              "name": "event_type",
              "type": "string"
            },
            {
              "name": "SPLIT",
              "type": "DATASET"
            }
          ],
          "data": [
            {
              "SPLIT": {
                "attributes": [
                  {
                    "name": "users",
                    "type": "string"
                  },
                  {
                    "name": "session",
                    "type": "string"
                  },
                  {
                    "name": "date_key",
                    "type": "string"
                  }
                ],
                "data": [
                  {
                    "date_key": "2023-11-03",
                    "session": "2",
                    "users": "1"
                  },
                  {
                    "date_key": "2023-11-04",
                    "session": "2",
                    "users": "2"
                  },
                  {
                    "date_key": "2023-11-05",
                    "session": "2",
                    "users": "1"
                  },
                  {
                    "date_key": "2023-11-06",
                    "session": "2",
                    "users": "1"
                  },
                  {
                    "date_key": "2023-11-09",
                    "session": "2",
                    "users": "1"
                  },
                  {
                    "date_key": "2023-11-10",
                    "session": "9",
                    "users": "2"
                  }
                ],
                "key": [
                  "date_key"
                ]
              },
              "event_type": "utm_visited",
              "session": "19",
              "users": "8"
            },
            {
              "SPLIT": {
                "attributes": [
                  {
                    "name": "users",
                    "type": "string"
                  },
                  {
                    "name": "session",
                    "type": "string"
                  },
                  {
                    "name": "date_key",
                    "type": "string"
                  }
                ],
                "data": [
                  {
                    "date_key": "2023-11-03",
                    "session": "23",
                    "users": "16"
                  },
                  {
                    "date_key": "2023-11-06",
                    "session": "22",
                    "users": "16"
                  },
                  {
                    "date_key": "2023-11-05",
                    "session": "31",
                    "users": "23"
                  },
                  {
                    "date_key": "2023-11-09",
                    "session": "21",
                    "users": "15"
                  },
                  {
                    "date_key": "2023-11-02",
                    "session": "17",
                    "users": "14"
                  },
                  {
                    "date_key": "2023-11-07",
                    "session": "34",
                    "users": "28"
                  },
                  {
                    "date_key": "2023-11-04",
                    "session": "127",
                    "users": "104"
                  },
                  {
                    "date_key": "2023-11-08",
                    "session": "124",
                    "users": "108"
                  },
                  {
                    "date_key": "2023-11-10",
                    "session": "129",
                    "users": "108"
                  }
                ],
                "key": [
                  "date_key"
                ]
              },
              "event_type": "view_pdp",
              "session": "528",
              "users": "432"
            },
            {
              "SPLIT": {
                "attributes": [
                  {
                    "name": "users",
                    "type": "string"
                  },
                  {
                    "name": "session",
                    "type": "string"
                  },
                  {
                    "name": "date_key",
                    "type": "string"
                  }
                ],
                "data": [
                  {
                    "date_key": "2023-11-04",
                    "session": "1",
                    "users": "1"
                  },
                  {
                    "date_key": "2023-11-10",
                    "session": "1",
                    "users": "1"
                  }
                ],
                "key": [
                  "date_key"
                ]
              },
              "event_type": "app_installed",
              "session": "2",
              "users": "2"
            },
            {
              "SPLIT": {
                "attributes": [
                  {
                    "name": "users",
                    "type": "string"
                  },
                  {
                    "name": "session",
                    "type": "string"
                  },
                  {
                    "name": "date_key",
                    "type": "string"
                  }
                ],
                "data": [
                  {
                    "date_key": "2023-11-04",
                    "session": "1",
                    "users": "1"
                  },
                  {
                    "date_key": "2023-11-10",
                    "session": "1",
                    "users": "1"
                  }
                ],
                "key": [
                  "date_key"
                ]
              },
              "event_type": "app_launched",
              "session": "2",
              "users": "2"
            },
            {
              "SPLIT": {
                "attributes": [
                  {
                    "name": "users",
                    "type": "string"
                  },
                  {
                    "name": "session",
                    "type": "string"
                  },
                  {
                    "name": "date_key",
                    "type": "string"
                  }
                ],
                "data": [
                  {
                    "date_key": "2023-11-02",
                    "session": "4",
                    "users": "4"
                  },
                  {
                    "date_key": "2023-11-04",
                    "session": "3",
                    "users": "3"
                  },
                  {
                    "date_key": "2023-11-05",
                    "session": "3",
                    "users": "3"
                  },
                  {
                    "date_key": "2023-11-07",
                    "session": "1",
                    "users": "1"
                  },
                  {
                    "date_key": "2023-11-08",
                    "session": "2",
                    "users": "2"
                  },
                  {
                    "date_key": "2023-11-10",
                    "session": "8",
                    "users": "7"
                  }
                ],
                "key": [
                  "date_key"
                ]
              },
              "event_type": "charged",
              "session": "21",
              "users": "20"
            },
            {
              "SPLIT": {
                "attributes": [
                  {
                    "name": "users",
                    "type": "string"
                  },
                  {
                    "name": "session",
                    "type": "string"
                  },
                  {
                    "name": "date_key",
                    "type": "string"
                  }
                ],
                "data": [
                  {
                    "date_key": "2023-11-04",
                    "session": "73",
                    "users": "71"
                  },
                  {
                    "date_key": "2023-11-02",
                    "session": "138",
                    "users": "138"
                  },
                  {
                    "date_key": "2023-11-06",
                    "session": "177",
                    "users": "175"
                  },
                  {
                    "date_key": "2023-11-08",
                    "session": "77",
                    "users": "74"
                  },
                  {
                    "date_key": "2023-11-03",
                    "session": "156",
                    "users": "153"
                  },
                  {
                    "date_key": "2023-11-07",
                    "session": "167",
                    "users": "167"
                  },
                  {
                    "date_key": "2023-11-09",
                    "session": "142",
                    "users": "134"
                  },
                  {
                    "date_key": "2023-11-10",
                    "session": "84",
                    "users": "75"
                  },
                  {
                    "date_key": "2023-11-05",
                    "session": "153",
                    "users": "151"
                  }
                ],
                "key": [
                  "date_key"
                ]
              },
              "event_type": "user_loggedin",
              "session": "1167",
              "users": "1138"
            }
          ],
          "key": [
            "event_type"
          ]
        },
        "session": "1739.000000",
        "users": "1602.000000"
      }
    ],
    "key": null
  },
  sort = {
    name: '',
    type: sortTypes.DESC,
  },
  onSortChange = () => { },
  percentLevelShow = [],
  className,
}) {
  const nameWrapper = useRef(null);
  const wrapper = useRef(null);

  const [columnWidth, setColumnWidth] = useState(0);

  const header = useMemo(() => {
    try {
      return data.attributes.filter(attribute => attribute.name !== "SPLIT");
    } catch (error) {
      return [];
    }
  }, [data]);

  const allData = useMemo(() => {
    return extractData(data.data, header.map(item => item.name));
  }, [data, header]);

  const clickFilter = (name) => {
    if (sort.name === name) {
      const newValue = {
        name,
        type: sort.type === sortTypes.ASC ? sortTypes.DESC : sortTypes.ASC,
      };

      onSortChange(newValue);
    } else {
      onSortChange({ name, type: sortTypes.DESC });
    }
  };

  const watchScreenSize = () => {
    const width = $(wrapper.current).width();
    const nameWidth = $(nameWrapper.current).width();

    setColumnWidth((width - nameWidth) / (Math.min(header.length, 5)));
  };

  useEffect(() => {
    $(window).on('resize', watchScreenSize);
    watchScreenSize();

    return () => {
      $(window).off('resize', watchScreenSize);
    };
  }, []);

  return (
    <div ref={wrapper} className={`multi-level-table rounded-lg relative inline-block align-top w-full overflow-hidden bg-white shadow-md px-4 py-2 ${className}`}>
      <div className="table-wrapper w-full overflow-x-scroll">
        <div className='table border-collapse'>
          <div className="table-row border-b">
            <div ref={nameWrapper} className='table-cell font-bold select-none ellipsis'>
              {label}
            </div>
            {
              header.map((item, index) => (
                <div style={{ 'width': `${columnWidth}px` }} className='table-cell p-1 font-bold' key={index}>
                  <div className='flex justify-start items-center'>
                    <span className='select-none'>{item.name}</span>

                    <div
                      onClick={() => clickFilter(item.name)}
                      className='relative sort-wrapper cursor-pointer ms-3 h-100'
                    >
                      <FontAwesomeIcon
                        icon={faSortUp}
                        className={`absolute up  ${sort.name === item.name && sort.type === sortTypes.ASC ? 'text-blue-600' : ''}`}
                      />
                      <FontAwesomeIcon
                        icon={faSortDown}
                        className={`absolute down ${sort.name === item.name && sort.type === sortTypes.DESC ? 'text-blue-600' : ''}`}
                      />
                    </div>
                  </div>

                </div>
              ))
            }
          </div>

          {
            allData.map((item, index) => (
              <div key={index} className="table-row border-b">
                <div className='table-cell' style={{ 'paddingLeft': `${item.level * 15}px` }}>{item.name}</div>
                {
                  header.map((headerItem, index) => (
                    <div
                      key={index}
                      className='table-cell p-1 relative'
                    >
                      <span className='z-10 relative'>{item[headerItem.name]}</span>
                      <div
                        className='progress absolute h-full left-0 top-0'
                        style={{
                          width: percentLevelShow.includes(item.level) ? `${item[headerItem.name] / item.parent[headerItem.name] * 100}%` : '0'
                        }}
                      >
                      </div>
                    </div>
                  ))
                }
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};
