
import { useEffect } from "react";
import TextInput from 'components/TextInput';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import UserService from 'services/user';
import { showError, showInfo } from 'components/FlashMessage/flashMessageSlice';

export default function MyProfile() {
  const { t } = useTranslation();
  const profileMethods = useForm();
  const passwordMethods = useForm();
  const dispatch = useDispatch();

  const getProfile = async () => {
    try {
      const result = await UserService.getProfile();
      profileMethods.setValue('name', result.name);
      profileMethods.setValue('phone', result.phone);
      profileMethods.setValue('email', result.email);
      profileMethods.setValue('position', result.position);
    }
    catch (error) {
    }
  }

  useEffect(() => {
    getProfile();
  }, []);

  const onSave = async (data) => {
    await UserService.changeProfile(data);
    getProfile();
    dispatch(showInfo({ message: "Change Profile Successfully!" }));
  }

  const onSubmit = async (data) => {
    try {
      if (data.newPassword !== data.confirmPassword) {
        dispatch(showError({ message: "New Password do not match!" }));
        return;
      }

      await UserService.changePassword(data);
      dispatch(showInfo({ message: "Change Password Successfully!" }));

      passwordMethods.reset();

    } catch (error) {
      console.log(error);
      const { message } = error;
      dispatch(showError({ message }));
    }
  };

  return (
    <div className="">
      <div className="grid grid-cols-1 gap-y-4">
        <div className="ds-block">
            <FormProvider {...profileMethods}>
              <form className="" onSubmit={profileMethods.handleSubmit(onSave)}>
                <div className="p-2">
                  <h1 className="ds-text-form-title">User details</h1>
                  <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                      <div className="mt-2">
                        <TextInput
                          label="Name"
                          type="text"
                          name="name"
                          className="ds-input ds-input-text w-full"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <div className="mt-2">
                        <TextInput
                          label="Phone number"
                          name="phone"
                          type="text"
                          className="ds-input ds-input-text w-full"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <div className="mt-2">
                        <TextInput
                          label="Email address"
                          name="email"
                          type="email"
                          disabled={true}
                          className="ds-input ds-input-text w-full"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <div className="mt-2">
                        <TextInput
                          label="Role"
                          name="position"
                          disabled={true}
                          className="ds-input ds-input-text w-full"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                  <button
                    type="submit"
                    className="ds-button"
                  >
                    Save
                  </button>
                </div>
              </form>
            </FormProvider>
        </div>
        <div className="ds-block">
          <FormProvider {...passwordMethods}>
            <form onSubmit={passwordMethods.handleSubmit(onSubmit)}>
              <div className="p-2">
                <h1 className="ds-text-form-title">Change password</h1>

                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <div className="mt-2">
                      <TextInput
                        label="Current password"
                        name="oldPassword"
                        type="password"
                        className="ds-input ds-input-text w-full"
                        rules={
                          {
                            required: t('VALUE_IS_REQUIRED', { Value: t('Current password') }),
                          }
                        }
                      />
                      <br />
                      <TextInput
                        label="New password"
                        name="newPassword"
                        type="password"
                        className="ds-input ds-input-text w-full"
                        rules={
                          {
                            required: t('VALUE_IS_REQUIRED', { Value: t('New password') }),
                          }
                        }
                      />
                      <br />
                      <TextInput
                        label="Confirm password"
                        name="confirmPassword"
                        type="password"
                        className="ds-input ds-input-text w-full"
                        rules={
                          {
                            required: t('VALUE_IS_REQUIRED', { Value: t('Confirm password') }),
                          }
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                <button
                  type="submit"
                  className="ds-button"
                >
                  Save
                </button>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  )
}
