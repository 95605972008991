import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import Tooltip from "components/Tooltip";

import Integration from "./integration";
import Cost from "./connection";

import {appleTabs, tabLink} from '../tabs';

import './index.scss';
import ListDataSources from "views/integration/data_source_configurations";
import {DsIconTooltip} from "components/DsIcon";
import {PARTNERS} from "consts/partner";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function AppleSearchAdsIntegration() {
  const {t} = useTranslation();
  const [hashValue, setHashValue] = useState(window.location.hash || '#integration');
  // const {appId} = useParams()

  useEffect(() => {
    const handleHashChange = () => {
      setHashValue(window.location.hash);
    };

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  return (
    <div id="apple-search-ads" className="relative pb-5 sm:pb-0 ds-input-text">
      <div className="flex items-center w-full">
        <div className="ds-image mr-2">
          <img src={"https://static.datasenses.io/apple.png"}/>
        </div>

        <div className="ds-text-page-title mr-2">Apple Search Ads</div>

        <Tooltip minWidth="380">
          <Tooltip.Label>
            <div><DsIconTooltip className="h-full w-full text-blue-400"
                                aria-hidden="true"/>
            </div>
          </Tooltip.Label>
          <Tooltip.Content>
            <p>Integrate data with Apple Search Ads</p>
          </Tooltip.Content>
        </Tooltip>
      </div>

      <div className="mt-4">
        <nav className="-mb-px flex space-x-8">
          {appleTabs.map((tab) => (
            <a
              id={tab.name}
              key={tab.name}
              href={tab.href}
              className={classNames(
                hashValue === tab.href
                  ? 'ds-tab-color'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                'whitespace-nowrap select-none cursor-pointer border-b-2 px-1 pb-2 text-sm font-medium'
              )}
              aria-current={tab.current ? 'page' : undefined}
            >
              {t(tab.name)}
            </a>
          ))}
        </nav>
        <div className="relative flex rounded mt-3 ds-block">
          <div className="flex-auto">
            <div className="tab-content tab-space">
              {hashValue === tabLink.INTEGRATION && <Integration/>}
              {hashValue === tabLink.CONNECTION && <Cost/>}
              {hashValue === tabLink.CONFIGURATIONS && <ListDataSources partner={PARTNERS.APPLE_SEARCH}/>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
