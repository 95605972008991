import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {PARTNERS} from "consts/partner";

import Tooltip from "components/Tooltip";
import {DsIconColoredMetaAds, DsIconTooltip} from "components/DsIcon";

import AppService from 'services/app';
import {FormProvider, useForm} from "react-hook-form";
import {showInfo, showWarn} from "components/FlashMessage/flashMessageSlice";
import {useDispatch, useSelector} from "react-redux";
import BackButton from "views/integration/meta_ads/back_button";
import CheckboxDropdown from "components/CheckBoxGroup";
import LoadingSpinner from "components/LoadingSpinner";
import {isUndefinedOrEmpty} from "utils";

export default function Connection() {
  const methods = useForm();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [connections, setConnections] = useState([]);
  const [listAdsAccount, setListAdsAccount] = useState({});
  const [searchParams] = useSearchParams();
  const status = searchParams.get('status');
  const [isWaiting, setIsWaiting] = useState(null);
  const email = useSelector((state) => state.user.profile.email);



  useEffect(() => {
    setIsWaiting(false);
    const fetchData = async () => {
      try {
        const result = await AppService.getConnectionByPartner(PARTNERS.FACEBOOK);
        setConnections(result);
      } catch (error) {
        console.error("Error fetching connections:", error);
      }
    };

    fetchData();



    if (status === "success") {
      dispatch(showInfo({message: 'Connect successfully'}));
    }

    setIsWaiting(true);

    window.scrollTo(0, 0);
  }, []);

  const onSubmit = async (data) => {
    try {


      AppService.integrateFacebookCost(data.adsCustomerId);

    } catch (error) {
      dispatch(showWarn({message: error.message}));
    }
  };


  const onSubmit2 = async (data) => {
    try {


      AppService.integrateFacebookCost2(data.adsCustomerId);

    } catch (error) {
      dispatch(showWarn({message: error.message}));
    }
  };


  return (
    <>
      <div id="cost" className={"sm:p-3"}>
        <div className="flex justify-start items-center w-full">
          <h3 className="ds-text-form-title mt-3 mr-1">Connect to your Facebook Ads</h3>
          <Tooltip minWidth="250">
            <Tooltip.Label>
              <div><DsIconTooltip className="h-full w-full text-blue-400"
                                  aria-hidden="true"/>
              </div>
            </Tooltip.Label>
            <Tooltip.Content>
              <p>Campaign data will be synced daily. Connect again if something went wrong</p>
            </Tooltip.Content>
          </Tooltip>
        </div>
        <div className="ds-text-lighter">Enhance your integration to receive data</div>
        <FormProvider {...methods}>
          <form className="flex items-center mt-3" onSubmit={methods.handleSubmit(onSubmit)}>
            <button
              type="submit"
              className="ds-button-with-border mr-3 flex items-center border-2"
            >
              <div className={"mr-2"}>
                <div className={"h-10 w-10"}><DsIconColoredMetaAds/></div>
              </div>
              <FontAwesomeIcon icon={faAngleRight}/>
            </button>
          </form>
        </FormProvider>
        { email === "integration@datasenses.io" &&
          <>
            <FormProvider {...methods}>
              <form className="flex items-center mt-3" onSubmit={methods.handleSubmit(onSubmit2)}>
                <button
                  type="submit"
                  className="ds-button-with-border pr-6 flex items-center border-2 w-1/6"
                >
                  <div className={"mr-2 flex "}>
                      <div className={"h-10 w-10"}><DsIconColoredMetaAds/></div>
                    <div className={"h-10 w-10"}> Ads Management</div>

                  </div>
                  <FontAwesomeIcon icon={faAngleRight}/>
                </button>
              </form>
            </FormProvider>
        </>
        }



        <div className={"mt-16"}>
          {isWaiting ?
            <div>
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                <tr>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    {t('Email')}
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    {t('Status')}
                  </th>
                  <th scope="col" className="w-1/4 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Ads Account
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Connected Page
                  </th>
                </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                {connections && connections.map((connection, index) => (
                  <tr key={index}>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{connection.sellerName}</td>
                    <td
                      className={`whitespace-nowrap px-3 py-4 text-sm ${connection.status ? 'text-green-600' : 'text-rose-600'}`}>
                      {connection.status ? 'Linked' : 'Unlinked'}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <CheckboxDropdown
                        onCheckboxChange={(name, checked) => {
                          // console.log(connection.connectionId, name, checked)
                        }}

                        connectionId={connection.connectionId}
                        partner={PARTNERS.FACEBOOK}
                      />

                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {connection.subConnection && connection.subConnection.map((subConnection, index) => (
                        <>
                          <div>
                            <div key={index} className="flex items-center">
                              <div className="w-2 h-2 bg-green-500 rounded-full mr-2"></div>
                              <div>{subConnection.pageName}</div>
                            </div>
                            {subConnection.instagramAccount.username !== "" &&
                              <div>
                                <div className={"flex items-center"}>
                                  <div className="ml-5 w-1 h-1 bg-indigo-500 rounded-full mr-2"></div>
                                  Connected Instagram Account:
                                </div>

                                <div className={"flex items-center"}>
                                  <div className="ml-10 w-1 h-1 bg-blue-500 rounded-full mr-2"></div>
                                  <div className={"flex items-center"}>
                                    <img className={"w-5 h-5 rounded-lg mr-1 text-sm"}
                                         src={subConnection.instagramAccount.profilePictureUrl}
                                         alt="placeholder"
                                      // loading={"lazy"}
                                    />
                                    {subConnection.instagramAccount.username}
                                    <div className={"ml-1 text-xs font-extralight"}>
                                      Follows: {subConnection.instagramAccount.followsCount}{", "}
                                      Followers: {subConnection.instagramAccount.followersCount}
                                    </div>
                                  </div>
                                </div>


                              </div>
                            }
                          </div>

                        </>
                      ))
                      }
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>

            :
            <div className={"flex justify-center text-red-600"}>
              <LoadingSpinner textColor={"text-blue-600"} size={"h-10 w-10"}/>
            </div>
          }
        </div>
        <hr className="h-px my-6 bg-gray-200 border-0"/>
        <div className="flex justify-between items-center px-4 w-full mt-5 mb-1">
          <BackButton/>
        </div>
      </div>
    </>
  )
}
