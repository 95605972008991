import TextInput from "components/TextInput";
import {PATHS, PLATFORM} from "consts";
import TailwindCombobox from "components/TailwindCombobox";
import {FormProvider, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { useNavigate } from "react-router-dom";

import deeplink from "services/deeplink";
import AppSvc from "services/app";
import {showError, showInfo} from "components/FlashMessage/flashMessageSlice";
import {useParams} from "react-router-dom";
import {isUndefinedOrEmpty} from "utils";
import { faChevronRight, faSquarePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TailwindComboboxWithoutForm from "components/TailwindCombobox/TailwindComboboxWithoutForm";
import ToggleCheckbox from "components/ToggleCheckbox";
import { list } from "postcss";
import {androidRedirectOptions, iOSRedirectOptions, webRedirectOptions} from "../types";


export default function UpdateDeeplinkTemplate() {
  const { t } = useTranslation();
  const methods = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const appList = useSelector((state) => state.app.appList);
  const [initValue, setInitValue] = useState(null);
  const [openAndroidMWeb, setOpenAndroidMWeb] = useState(false)
  const [openIOSMWeb, setOpenIOSMWeb] = useState(false)
  const [listTemplates, setListTemplates] = useState([])
  const [androidApp, setAndroidApp] = useState(null)
  const [iosApp, setIosApp] = useState(null)
  const [webApp, setWebApp] = useState(null)
  const [templateId, setTemplateId] = useState(null)

  const androidRedirect = methods.watch("androidRedirect")
  const iosRedirect = methods.watch("iosRedirect")
  const androidDeeplink = methods.watch("androidDeeplink")
  const iosDeeplink = methods.watch("iosDeeplink")
  const androidMwebUrl = methods.watch("androidMwebUrl")
  const iosMwebUrl = methods.watch("iosMwebUrl")
  const androidAppId = methods.watch("androidApp")
  const iosAppId = methods.watch("iosApp")
  const templateName = methods.watch("templateName")
  const webAppId = methods.watch("webApp")
  const webDeepLink = methods.watch("webDeepLink")
  const isAllLinksUpdated = methods.watch("isAllLinksUpdated")

  const isEditDisabled = true

  const emptyApp = {
    "fieldKey": "empty",
    "name": "",
    "isNoneOption": true
  }

  const iOSApps = [
    emptyApp,
    ...appList.filter(a => a.platform === PLATFORM.IOS).map((a) => ({
      "fieldKey": a.appId,
      "name": a.name,
    })),
  ]

  const androidApps = [
    emptyApp,
    ...appList.filter(a => a.platform === PLATFORM.ANDROID).map((a) => ({
      "fieldKey": a.appId,
      "name": a.name,
    })),
  ]

  const webApps = [
    emptyApp,
    ...appList.filter(a => a.platform === PLATFORM.WEB).map((a) => ({
      "fieldKey": a.appId,
      "name": a.name,
    })),
  ]

  useEffect(() => {
    if (androidRedirect === PLATFORM.MWEB) {
      setOpenAndroidMWeb(true)
    } else {
      setOpenAndroidMWeb(false)
    }
  }, [androidRedirect]);

  useEffect(() => {
    if (iosRedirect === PLATFORM.MWEB) {
      setOpenIOSMWeb(true)
    } else {
      setOpenIOSMWeb(false)
    }
  }, [iosRedirect]);

  const deleteTemplate = async (templateId) => {
    try {
      console.log(templateId);
      const result = await deeplink.deleteTemplateById(templateId);
      if (result) {
        dispatch(showInfo({ message: "Delete template successfully" }));
        getTemplates();
      }
    } catch (err) {
      dispatch(showError({ message: "Delete template failed" }));
    }
  };

  const getApp = async () => {
    try {
      if (androidAppId && androidAppId !== "empty") {
        const result = await AppSvc.getApp({ appId: androidAppId });
        setAndroidApp(result);
      } else {
        setAndroidApp(null);
      }

      if (iosAppId && iosAppId !== "empty") {
        const result = await AppSvc.getApp({ appId: iosAppId });
        setIosApp(result);
      } else {
        setIosApp(null);
      }

      if (webAppId && webAppId !== "empty") {
        const result = await AppSvc.getApp({ appId: webAppId });
        setWebApp(result);
      } else {
        setWebApp(null);
      }
    } catch (err) {
    }
  };

  useEffect(() => {
    getApp();
  }, [androidAppId, iosAppId, webAppId]);

  const getTemplates = async () => {
    try {
      const result = await deeplink.getListTemplates();
      const templates = result.map((t) => ({
        value: t.id,
        text: t.name,
      }));
      setListTemplates(templates);
      if (templates.length > 0) {
        setTemplateId(templates[0].value);
        getTemplateInfo(templates[0].value);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getTemplateInfo = async (templateId) => {
    try {
      const result = await deeplink.getTemplateById(templateId);

      const tempValue = [
        {key: 'androidApp', value: result.app_android_id},
        {key: 'iosApp', value: result.app_ios_id},
        {key: 'webApp', value: result.app_web_id},
        {key: 'androidDeeplink', value: result.android_deep_link},
        {key: 'iosDeeplink', value: result.ios_deep_link},
        {key: 'androidRedirect', value: result.android_is_open_store ? PLATFORM.GGPLAY : PLATFORM.MWEB},
        {key: 'iosRedirect', value: result.ios_is_open_store ? PLATFORM.APPSTORE : PLATFORM.MWEB},
        {key: 'templateName', value: result.name},
      ]

      tempValue.map((kv) => {
        methods.setValue(kv.key, kv.value);
      });

      setInitValue(tempValue);
    } catch (e) {
    }
  };

  const handleTemplateChanged = (id) => {
    setTemplateId(id);
    getTemplateInfo(id);
    // console.log(id);
  };

  useEffect(() => {
    if (listTemplates.length > 0) {
      getTemplateInfo(listTemplates[0].value);
      discardChanges();
    }
  }, [listTemplates]);

  useEffect( () => {
    getTemplates()
  }, []);

  const discardChanges = () => {
    if (initValue === null) {
      return;
    }
    initValue.map((kv) => {
      methods.setValue(kv.key, kv.value);
    });
  }

  const onSubmit = async () => {
    const requestPayload = {
      id: templateId,
      name: templateName,
      android_deep_link: androidDeeplink,
      android_is_open_store: androidRedirect === PLATFORM.GGPLAY,
      android_link_app_not_install: androidRedirect === PLATFORM.GGPLAY ? "" : webApp.link,
      app_android_id: androidAppId === "empty" ? "" : androidAppId,
      app_ios_id: iosAppId === "empty" ? "" : iosAppId,
      ios_deep_link: iosDeeplink,
      ios_is_open_store: iosRedirect === PLATFORM.APPSTORE,
      ios_link_app_not_install: iosRedirect === PLATFORM.APPSTORE ? "": webApp.link,
      app_web_id: webAppId === "empty" ? "" : webAppId,
      is_all_links_updated: isAllLinksUpdated,
    }

    try {
      console.log(requestPayload);
      const result = await deeplink.updateTemplate(requestPayload);

      dispatch(showInfo({ message: "Update template successfully" }));
      window.location = `${PATHS.APP}${PATHS.DEEPLINK}`
    } catch (e) {
      dispatch(showError({ message: "Data invalid" }));
    }
  }

  return (
    <div id="update-template">
        <FormProvider {...methods}>
          <div className="gap-4 bg-white mt-7 rounded-lg shadown-md sm:p-3 border border-gray-100">
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div className="ml-4 mt-3">
                <div>
                  <div className="flex justify-between">
                    <div className="w-1/3">
                      <div>
                        <span className="text-sm font-semibold text-gray-900">Template</span>
                        <TailwindComboboxWithoutForm
                          items={listTemplates}
                          onChange={handleTemplateChanged}
                          className="w-full"
                        />
                      </div>
                    </div>

                    {/* <div>{templateId}</div> */}

                    <div className="flex items-center justify-end mr-4">
                      <button
                        type="button"
                        className="ds-button-cancel mr-2"
                        onClick={() => deleteTemplate(templateId)}
                      >
                        Delete
                      </button>
                      <button
                        type="submit"
                        className="ds-button"
                        onClick={() => {navigate(`${PATHS.APP}${PATHS.DEEPLINK_TEMPLATE}`)}}
                      >
                        <FontAwesomeIcon className="me-2" icon={faSquarePlus}/>
                        Create
                      </button>
                    </div>
                  </div>

                    <hr className="h-px mt-8 bg-gray-200 border-0 mr-4"/>

                    <div className="mt-7">
                      <h3 className="ds-text-form-title-2">
                        {t('Platform')}
                      </h3>
                    </div>

                    <div className="grid grid-cols-5 gap-4 mt-5">
                      <div className="col-span-2">
                        <span className="ds-input-text">Android</span>
                        <TailwindCombobox
                          className="w-1/2"
                          name="androidApp"
                          items={androidApps}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-5 gap-4 mt-6">
                      <div className="col-span-2">
                        <span className="ds-input-text">iOS</span>
                        <TailwindCombobox
                          name="iosApp"
                          items={iOSApps}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-5 gap-4 mt-6">
                      <div className="col-span-2">
                        <span className="ds-input-text">Web</span>
                        <TailwindCombobox
                          name="webApp"
                          items={webApps}
                        />
                      </div>
                    </div>

                    <hr className="h-px mt-8 bg-gray-200 border-0 mr-4"/>

                  {/*-------------------------------------Redirection app isn't installed-----------------------------*/}
                    <div>
                        <h3 className="ds-text-form-title-2 mt-7">
                          {t('Redirection')}
                        </h3>
                    </div>

                    {
                    androidApp || iosApp ?
                    <div>
                    <legend className="block text-sm font-semibold leading-6 text-gray-900 mt-6">
                      When app isn’t installed
                    </legend>
                    <table className="bg-white w-2/3 border-collapse border border-gray-200 rounded-lg">
                      <tbody className="text-gray-600 ds-input-text divide-y divide-gray-200">
                        <tr>
                          <td className="px-6 py-4 w-1/6 whitespace-nowrap text-center border border-gray-200 font-medium">Android</td>
                          <td className="px-7 py-6">
                            <div>
                              <span className="ds-input-text">Redirect to</span>
                              {
                                androidRedirect === PLATFORM.MWEB ?
                                <div>
                                  <div className="grid grid-cols-2 mb-2 gap-3">
                                    <div>
                                      <TailwindCombobox
                                        name="androidRedirect"
                                        items={androidRedirectOptions}
                                      />
                                    </div>
                                    <div>
                                      <TextInput
                                        name="androidMwebUrl"
                                        className="ds-disabled-input w-full hover:border-gray-300 focus:border-gray-300"
                                        disabled={isEditDisabled}
                                        placeholder={webApp ? webApp.link : ""}
                                      />
                                    </div>
                                  </div>
                                  {
                                    webApp ? <div></div>
                                    :
                                    <div className="flex items-center mt-5">
                                    <div className="ds-text-input font-bold mr-1">
                                      Note:
                                    </div>
                                    <div className="ds-text-input">
                                      Please select a Web App in Platform section.
                                    </div>
                                  </div>
                                  }
                                </div>
                                :
                                <div className="grid grid-cols-2 mb-2 gap-1">
                                  <TailwindCombobox
                                    name="androidRedirect"
                                    items={androidRedirectOptions}
                                  />
                                </div>
                              }
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="px-6 py-4 w-1/6 whitespace-nowrap text-center border border-gray-200 font-medium">iOS</td>
                          <td className="px-7 py-6">
                            <div>
                              <span className="ds-input-text">Redirect to</span>
                              {
                                iosRedirect === PLATFORM.MWEB ?
                                <div>
                                  <div className="grid grid-cols-2 mb-2 gap-3">
                                    <div>
                                      <TailwindCombobox
                                        name="iosRedirect"
                                        items={iOSRedirectOptions}
                                      />
                                    </div>
                                    <div>
                                      <TextInput
                                        name="iosMwebUrl"
                                        className="ds-disabled-input w-full hover:border-gray-300 focus:border-gray-300"
                                        disabled={isEditDisabled}
                                        placeholder={webApp ? webApp.link : ""}
                                      />
                                    </div>
                                  </div>
                                  {
                                    webApp ? <div></div>
                                    :
                                    <div className="flex items-center mt-5">
                                    <div className="ds-text-input font-bold mr-1">
                                      Note:
                                    </div>
                                    <div className="ds-text-input">
                                      Please select a Web App in Platform section.
                                    </div>
                                  </div>
                                  }
                                </div>
                                :
                                <div className="grid grid-cols-2 mb-2 gap-1">
                                  <TailwindCombobox
                                    name="iosRedirect"
                                    items={iOSRedirectOptions}
                                  />
                                </div>
                              }
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    {/*-------------------------------------Redirection app is installed-----------------------------*/}

                    <legend className="block text-sm font-semibold leading-6 mt-6">
                      When app is installed
                    </legend>
                    <table className="bg-white w-2/3 border-collapse border border-gray-200 rounded-lg">
                      <tbody className="text-gray-600 ds-input-text divide-y divide-gray-200">
                        <tr>
                          <td className="px-6 py-4 w-1/6 whitespace-nowrap text-center border border-gray-200 font-medium">Android</td>
                          <td className="px-7 py-5">
                            <div>
                              <div className="mb-2 gap-1 mt-2">
                                {
                                  androidApp ?
                                  <TextInput
                                    name="androidDeeplink"
                                    className="ds-input w-full"
                                    placeholder={"Android deeplink"}
                                    rules={
                                      {
                                        required: t('Android deeplink is required.'),
                                      }
                                    }
                                  /> :
                                  <TextInput
                                    name="androidDeeplink"
                                    className="ds-input w-full"
                                    placeholder={"Android deeplink"}
                                  />
                                }

                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="px-6 py-4 w-1/6 whitespace-nowrap text-center border border-gray-200 font-medium">iOS</td>
                          <td className="px-7 py-5">
                            <div>
                            <div className="mb-2 gap-1 mt-2">
                              {
                                iosApp ?
                                <TextInput
                                  name="iosDeeplink"
                                  className="ds-input w-full"
                                  placeholder={"iOS deeplink"}
                                  rules={
                                    {
                                      required: t('iOS deeplink is required.'),
                                    }
                                  }
                                /> :
                                <TextInput
                                  name="iosDeeplink"
                                  className="ds-input w-full"
                                  placeholder={"iOS deeplink"}
                                />
                              }
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    </div>
                    : <div></div>
                            }

                    {/*-------------------------------------Redirection Web-----------------------------*/}

                    <legend className="block text-sm font-semibold leading-6 text-gray-900 mt-6">
                      When link is clicked on desktop web page
                    </legend>
                    <table className="bg-white w-2/3 border-collapse border border-gray-200 rounded-lg">
                      <tbody className="text-gray-600 ds-input-text divide-y divide-gray-200">
                        <tr>
                          <td className="px-6 py-4 w-1/6 whitespace-nowrap text-center border border-gray-200 font-medium">Desktop</td>
                          <td className="px-7 py-6">
                            <div>
                              <span className="ds-input-text">Redirect to</span>
                              <div>
                                <div className="grid grid-cols-2 mb-2 gap-3">
                                  <div>
                                    <TailwindCombobox
                                      name="webRedirect"
                                      items={webRedirectOptions}
                                    />
                                </div>
                                <div>
                                  <TextInput
                                    name="webDeepLink"
                                    className="ds-disabled-input w-full hover:border-gray-300 focus:border-gray-300"
                                    disabled={isEditDisabled}
                                    placeholder={webApp ? webApp.link : ""}
                                  />
                                </div>
                                </div>
                                {
                                  webApp ? <div></div>
                                  :
                                  <div className="flex items-center mt-5">
                                    <div className="ds-text-input font-bold mr-1">
                                      Note:
                                    </div>
                                    <div className="ds-text-input">
                                      Please select a Web App in Platform section.
                                    </div>
                                  </div>
                                }
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <hr className="h-px mt-8 bg-gray-200 border-0 mr-4"/>

                    <div className="mt-7">
                      <h3 className="ds-text-form-title-2">
                        {t('Additional settings')}
                      </h3>
                    </div>

                    <div className={"flex items-center mt-6"}>
                      <div className="ds-input-text">Sync updated data to all links of this template</div>
                      <ToggleCheckbox name="isAllLinksUpdated" className="ms-4"/>
                    </div>

                    <hr className="h-px mt-6 bg-gray-200 border-0 mr-4"/>

                    <div className="flex items-center justify-end mr-4 mt-7 mb-4">
                      <button
                          type="button"
                          className="ds-button-cancel mr-2"
                          onClick={discardChanges}
                        >
                          Discard changes
                      </button>

                      <button
                        type="submit"
                        className="ds-button"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                  </div>
                </form>
          </div>
      </FormProvider>
    </div>
  )
}
