import './index.scss';
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useTranslation} from 'react-i18next';
import {debounce} from "lodash";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass, faSquarePlus} from '@fortawesome/free-solid-svg-icons';
import {showError, showInfo} from "components/FlashMessage/flashMessageSlice";
import TailwindMenuDialog from "components/TailwindMenuDialog";
import {DsIconDelete, DsIconEdit} from "components/DsIcon";
import TextInput from 'components/TextInput/WithoutForm';
import DeleteModal from "components/DeleteModal";

import {PATHS, TIMESTAMP_PROTOBUF_FORMAT} from "consts";
import EditDashboardModal from "./edit_dashboard";
import CreateDashboardModal from "./create_dashboard";
import DashboardService from 'services/dashboard';


export default function DashboardList() {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const [dashboardList, setDashboardList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [dashboardId, setDashboardId] = useState('');
  const [dashboardName, setDashboardName] = useState('');
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);

  const handleCreate = () => {
    setIsCreateOpen(true);
  }

  const handleUpdate = (dashboard) => {
    setDashboardId(dashboard.dashboardId);
    setDashboardName(dashboard.name);
    setIsUpdateOpen(true);
  };

  const handleDelete = (dashboard) => {
    setDashboardId(dashboard.dashboardId);
    setDashboardName(dashboard.name);
    setIsDeleteOpen(true);
  }

  const deleteDashboard = async () => {
    try {
      await DashboardService.deleteDashboard({ dashboardId: dashboardId });

      dispatch(showInfo({ message: t("DELETE_VALUE_SUCCESSFULLY", { Value: "dashboard" }) }));

      setIsDeleteOpen(false);
    } catch (error) {
      const { message } = error;
      dispatch(showError({ message }));
    }
    getDashboardList();
  }

  const getDashboardList = async () => {
    const query = {name: searchTerm};

    try {
      const dashboards = await DashboardService.listDashboards({query});
      dashboards.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      setDashboardList(dashboards);
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      getDashboardList();
    }
  }

  const getPath = (dashboard) => {
    return `${PATHS.APP}/dashboard/${dashboard.dashboardId}`;
  }

  useEffect(() => {
    getDashboardList();
  }, []);

  const debouncedSearch = debounce(() => {
    getDashboardList();
  }, 300);

  useEffect(() => {
    debouncedSearch();

    return () => {
      debouncedSearch.cancel();
    };
  }, [searchTerm]);

  return (
    <div id="dashboard-list">
      <div className="ds-text-page-title">{t('DASHBOARDS')}</div>

      <div className="ds-block mt-5 ">
        <div className={""}>
          <div className="flex justify-between items-center">
            {/* <div className="items-center w-1/5"> */}
              {/* <TextInput
                name="search"
                icon={faMagnifyingGlass}
                placeholder={t('Search dashboard')}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={handleKeyDown}
                click={getDashboardList}
                className={"ds-input w-full"}
              /> */}
            {/* </div> */}
            {/* <div className="flex items-center justify-end"> */}
              <button
                type="button"
                onClick={handleCreate}
                className="ds-button"
              >
                <FontAwesomeIcon className="me-2" icon={faSquarePlus}/>
                Create
              </button>
            {/* </div> */}
          </div>

          <table className="min-w-full divide-y divide-gray-300 mt-3">
            <thead>
            <tr>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-bold text-gray-900">
                {t('NAME')}
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-bold text-gray-900">
                {t('CREATED_BY')}
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-bold text-gray-900">
                {t('CREATED_AT')}
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-bold text-gray-900">
              </th>
            </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
            {dashboardList && dashboardList.map((dashboard, index) => (
              <tr key={index}>
                <td
                  className="px-3 py-4 ds-text-color">
                  <Link
                    className="text-sm no-underline ds-text-color"
                    to={getPath(dashboard)}
                  >
                    {dashboard.name}
                  </Link>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{dashboard.createdBy}</td>
                <td
                  className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{moment(dashboard.createdAt).format(TIMESTAMP_PROTOBUF_FORMAT)}
                </td>
                <td className="relative whitespace-nowrap">
                  <TailwindMenuDialog
                    items={[
                      {
                        Name: "Update",
                        Icon: <DsIconEdit/>,
                        Action: () => handleUpdate(dashboard),
                        ActionType: "button"
                      },
                      {
                        Name: "Delete",
                        Icon: <DsIconDelete/>,
                        Action: () => handleDelete(dashboard),
                        ActionType: "button"
                      },
                    ]}
                  />
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>

      <CreateDashboardModal
        isOpen={isCreateOpen}
        closeModal={() => setIsCreateOpen(false)}
        reset={getDashboardList}
      />

      <DeleteModal
        name={dashboardName}
        objectType="dashboard"
        isOpen={isDeleteOpen}
        onDelete={deleteDashboard}
        closeModal={() => setIsDeleteOpen(false)}
      />

      <EditDashboardModal
        id={dashboardId}
        isOpen={isUpdateOpen}
        closeModal={() => setIsUpdateOpen(false)}
        reset={getDashboardList}
      />
    </div>
  );
};


