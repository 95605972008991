import { Header } from "views/landing_page/home/Header";
import { CallToAction } from "views/landing_page/home/CallToAction";
import { Faqs } from "views/landing_page/home/Faqs";
import { Footer } from "views/landing_page/home/Footer";
import LPTextBlock from "views/landing_page/LPTextBlock";
import LPImageBlock from "views/landing_page/LPImageBlock";
import {DsIconLpCohort, DsIconLpTable} from "components/DsIcon";
import { CheckIcon } from "@heroicons/react/16/solid";
import attributionImg from "../home/images/attribution.png";
import roiImg from "../home/images/roi.png";

export default function LandingPageFraud() {
  return (
    <>
      <Header />
      <main className="pb-16 pt-56 lg:pt-56">
        <div className={"grid grid-rows-1 gap-y-20 lg:gap-y-26 py-10"}>
          <div className={"lp-background-2"}>
            <div className={"grid grid-cols-1 lg:grid-cols-2 mx-auto max-w-7xl lg:px-8 gap-2"}>
              <LPTextBlock
                title={"Protect Your Budget with AI-Powered"}
                description={"AI-Powered fraud decisioning. Fraudulent activities such as click fraud or impression fraud can waste advertising budget by showing ads to fake or non-human traffic. A fraud detection system helps identify and mitigate such fraudulent activities, saving advertisers money."}
                description_items={[
                ]}
                type={"bg_light"}
              />
              <div>
                <img
                  className="w-full h-auto"
                  src={attributionImg}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className={"lp-background-2"}>
            <div className={"grid grid-cols-1 lg:grid-cols-2 mx-auto max-w-7xl lg:px-8 gap-2"}>
              <LPTextBlock
                title={"Serve Real Customers"}
                description={"Fraud detection systems ensure that ads are served to real customers who are genuinely interested in the products or services being advertised. This accuracy improves targeting effectiveness, leading to better engagement and conversion rates."}
                description_items={[
                  <span className={"flex flex-wrap justify-start items-center"}><CheckIcon
                    className={"w-5 h-5 mr-2 text-blue-600"}/> <span className={"font-bold"}>Accurate targeting</span></span>,
                  <span className={"flex flex-wrap justify-start items-center"}><CheckIcon
                    className={"w-5 h-5 mr-2 text-blue-600"}/> <span className={"font-bold"}>Enhanced customer experience</span></span>,
                  <span className={"flex flex-wrap justify-start items-center"}><CheckIcon
                    className={"w-5 h-5 mr-2 text-blue-600"}/> <span className={"font-bold"}>Brand safety</span></span>,
                ]}
                type={"bg_light"}
              />
              <div>
                <img
                  className="w-full h-auto"
                  src={roiImg}
                  alt=""
                  sizes="(min-width: 2048px) 67.8125rem, (min-width: 1280px) 100vw, 45rem"
                />
              </div>
            </div>
          </div>
          <div className={"lp-background-2"}>
            <div className={"grid grid-cols-1 lg:grid-cols-2 mx-auto max-w-7xl lg:px-8 gap-2"}>
              <div>
                <LPImageBlock
                  imageContent={<DsIconLpTable/>}
                />
              </div>
              <LPTextBlock
                title={"Grow Fearlessly"}
                description={"Advertisers can scale their advertising efforts more confidently with the support of fraud detection systems. By ensuring that their ads are served to real users and genuine prospects, advertisers can expand their reach across new markets and audience segments without worrying about the impact of fraud on campaign performance."}
                description_items={[
                ]}
                type={"bg_light"}
              />
            </div>
          </div>
        </div>
      </main>
      <CallToAction/>
      <Faqs/>
      <Footer/>
    </>
  )
}
