import { Button } from "./Button";
import { Container } from "./Container";
import { DsIconSlogan } from "../../../components/DsIcon";
// import ChannelsGif from "./images/channels.gif"

export function Hero({hasDemoButton = true}) {
  return (
    <Container className={"text-center"}>
      <h1 className="mx-auto max-w-4xl font-display text-5xl lg:text-7xl font-medium tracking-tight">
        Harnessing Data & AI{" "}
        <span className="relative whitespace-nowrap text-violet-500">
          {/* <DsIconSlogan /> */}
          <span className="relative">for Growth</span>
        </span>
      </h1>
      <p className="mx-auto mt-10 max-w-2xl lp-text-desc-bg-light">
        Transform insights into sustainable growth. Improve ROI, LTV and
        increase app installs by understanding deeply your customers & your
        campaigns.
      </p>

      {hasDemoButton &&
        <div className="mt-12 flex justify-center gap-x-6">
          <a
            className="group inline-flex items-center justify-center rounded-lg py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-violet-500 text-white hover:text-slate-100 hover:bg-violet-400 active:bg-violet-800 active:text-violet-100 focus-visible:outline-violet-500"
            color="blue"
            variant="solid"
            href="/request-demo"
          >
            <span>Request a demo</span>
          </a>
        </div>
      }

    </Container>
  );
}
