import HttpMethod from 'consts/httpMethod';
import {processRequest, processSuccessResponse, processErrorResponse, isUndefinedOrEmpty} from 'utils';


export default {
  async getOverviewInsight(data) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: '/recommendation/overview',
      method: HttpMethod.POST
    };

    try {

      const res = await processRequest(ep, {data: data});

      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },


}
