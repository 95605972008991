import { useDispatch } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import LoadingSpinner from 'components/LoadingSpinner';
import { showError, showInfo } from "components/FlashMessage/flashMessageSlice";
import TextInput from 'components/TextInput';

import ChartRecordService from 'services/chart_record';


export default function EditChartModal({ id, isOpen = false, closeModal = () => { }, reset = () => { } }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm();

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);

      await ChartRecordService.updateChart({ chartId: id, ...data });

      dispatch(showInfo({ message: t("UPDATE_VALUE_SUCCESSFULLY", { Value: "Chart" }) }));

      closeModal();

      reset();
    } catch (error) {
      const { message } = error;
      dispatch(showError({ message }));
    }

    setIsLoading(false);
    reset();
  }

  const getChartDetail = async () => {
    if (id && isOpen) {
      const result = await ChartRecordService.getChartDetail({ chartId: id });
      methods.setValue('name', result.name);
      methods.setValue('definition', result.definition);
    }
  }

  useEffect(() => {
    getChartDetail();
  }, [isOpen]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Update Chart
                </Dialog.Title>

                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="mt-2">
                      <div className="w-full">
                        <label className="font-semibold text-sm required">{t('CHART_NAME')}</label>
                        <TextInput
                          name="name"
                          className="w-full mt-1"
                          placeholder={t('ENTER_VALUE', { Value: 'chart name' })}
                        />
                      </div>

                      <div className="w-full mt-5">
                        <label className="font-semibold text-sm required">{t('DEFINITION')}</label>
                        <TextInput
                          name="definition"
                          className="w-full mt-1"
                          placeholder={t('ENTER_VALUE', { Value: 'definition' })}
                        />
                      </div>
                    </div>

                    <div className="mt-10 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:ml-3"
                        onClick={closeModal}
                      >
                        {t('CANCEL')}
                      </button>

                      <button
                        type="submit"
                        className="inline-flex items-center w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto"
                      >
                        {isLoading ?
                          <>
                            <LoadingSpinner className="me-3" />
                            {t('LOADING')}
                          </>
                          :
                          t('UPDATE')
                        }
                      </button>
                    </div>
                  </form>
                </FormProvider>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
