import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import Tooltip from "components/Tooltip";
import {DsIconTiktok, DsIconTooltip} from "components/DsIcon";

import Integration from "./integration";

import {tabLink, tiktokAdsTabs} from '../tabs';

import './index.scss';
import Connection from "./connection";
import {InformationCircleIcon} from "@heroicons/react/20/solid";
import ListDataSources from "views/integration/data_source_configurations";
import {PARTNERS} from "consts/partner";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function TikTokAdsIntegration() {
  const {t} = useTranslation();
  const [hashValue, setHashValue] = useState(window.location.hash || tabLink.INTEGRATION);
  useEffect(() => {
    const handleHashChange = () => {
      setHashValue(window.location.hash);
    };

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  return (
    <div id="google-ads" className="relative pb-5 sm:pb-0 ds-input-text">
      <div className="flex items-center w-full">
        <div className={"h-10 w-10"}><DsIconTiktok/></div>
        <div className="ds-text-page-title me-1">Tiktok Ads</div>

        <Tooltip minWidth="380">
          <Tooltip.Label>
            <div><DsIconTooltip className="h-full w-full text-blue-400"
                                aria-hidden="true"/>
            </div>
          </Tooltip.Label>
          <Tooltip.Content>
            <p>Set up active integration with ad networks, agencies, and other 3rd party platforms.</p>
          </Tooltip.Content>
        </Tooltip>

      </div>
      <div className="mt-4">
        <nav className="-mb-px flex space-x-8">
          {tiktokAdsTabs.map((tab) => (
            <a
              id={tab.name}
              key={tab.name}
              href={tab.href}
              className={classNames(
                hashValue === tab.href
                  ? 'ds-tab-color'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                'whitespace-nowrap select-none cursor-pointer border-b-2 px-1 pb-2 text-sm font-medium'
              )}
              aria-current={tab.current ? 'page' : undefined}
            >
              {t(tab.name)}
            </a>
          ))}
        </nav>

        <div className="relative flex rounded mt-3 ds-block">
          <div className="flex-auto">
            <div className="tab-content tab-space">
              {hashValue === tabLink.INTEGRATION && <Integration/>}
              {/*{hashValue === tabLink.ATTRIBUTION_LINK && <AttributionLink/>}*/}
              {hashValue === tabLink.CONNECTION && <Connection/>}
              {/*{hashValue === tabLink.PERMISSIONS && <Permissions/>}*/}
              {hashValue === tabLink.CONFIGURATIONS && <ListDataSources partner={PARTNERS.TIKTOK}/>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
