import {isUndefinedOrEmpty} from "utils";


export const processSkanConversionValueData = (data) => {
  const processed_data = {};
  for (const key in data) {
    if (data[key].postbackWindowSettingsEnabled) {
      if (isUndefinedOrEmpty(data[key].conversionValues)) {
        throw new Error(`Conversion values for ${key} is required`)
      }
      processed_data[key] = data[key];
    }
  }

  if (!('postbackWindowOne' in processed_data)) {
    throw new Error("Postback window one is required")
  }

  return processed_data;
}
