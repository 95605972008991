import errors from './errors';

import en from './en.json';

const translations = {
  en: {
    translation: {
      ...en,
      ...errors.en,
    },
  },
};

export default translations;
