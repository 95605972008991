import {useTranslation} from "react-i18next";
import ToggleCheckbox from "components/ToggleCheckbox";
import {isUndefinedOrEmpty} from "utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquarePlus, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {showError, showInfo} from "components/FlashMessage/flashMessageSlice";
import {useDispatch} from "react-redux";
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import TailwindCombobox from "components/TailwindCombobox";
import TextInput from "components/TextInput";
import NumberInput from "components/NumberInput";
import {processConversionValueData} from "views/skadnetwork/skan_studio/conversion_value/processor";
import {CoarseValueOptions, FineValueOptions} from "views/skadnetwork/skan_studio/const";

export default function ConversionValueFormCreation(
  {
    onChange = payload => {
    },
    closeModal,
    eventList = [],
  } = {}
) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const methods = useForm();
  const {fields, append, remove} = useFieldArray({
    name: 'eventMappings',
    control: methods.control,
  });
  const watchData = methods.watch();

  const onApply = async () => {
    try {
      const payload = processConversionValueData(watchData)
      onChange(payload)
      dispatch(showInfo({message: "Add conversion value success"}))
      closeModal();
    } catch (error) {
      const {message} = error
      dispatch(showError({message}))
    }
  }

  return (
    <FormProvider {...methods}>
      <form>
        <div className="gap-4 bg-white mt-5 ds-border-gray shadown-md">
          <div className="p-4">
            <div className="grid gap-4">
              <div>
                <div className="font-semibold text-lg leading-none">
                  <h3 className="ds-text-form-des">{t('ADD_A_CONVERSION_VALUE')}</h3>
                </div>
                <div
                  className="ds-text-lighter">A conversion value can be used to measure the relative importance of
                  in-app events. You can create definitions for fine (0 to 63) and coarse (high, medium, and low) values
                  by specifying the events to measure across each postback window.
                </div>
              </div>
              <div>
                <TextInput
                  label={t('CONVERSION_VALUE_NAME')}
                  className="w-full ds-input-text"
                  name="displayName"
                />
                <div className="mt-2">
                  <h3 className="ds-text-form-des">Values</h3>
                </div>
                <div className="ds-text-lighter">Select the fine and/or coarse value you are configuring.
                </div>
                <div className="grid grid-cols-2 gap-4 mt-2">
                  <h3 className="ds-text-form-des">Fine Value</h3>
                  <TailwindCombobox
                    items={FineValueOptions}
                    name={"fineValue"}
                  />
                  <h3 className="ds-text-form-des">Coarse Value</h3>
                  <TailwindCombobox
                    items={CoarseValueOptions}
                    name={"coarseValue"}
                  />
                </div>
                <div className="mt-2">
                  <h3 className="ds-text-form-des">Lock</h3>
                  <div className="ds-text-lighter"> Optionally lock this conversion value when its conditions are met.
                    Once a conversion value is locked, no other values can be set afterwards within the same window.
                  </div>
                  <ToggleCheckbox
                    className="mt-2"
                    name={"lockEnabled"}
                  />
                </div>
                <div className="mt-2">
                  <h3 className="ds-text-form-des">Definition</h3>
                  <div className="ds-text-lighter">Define the criteria that must be met for this conversion value. A
                    criteria is met for a user when they log all configured events within their value and count ranges.
                    Events with currency are evaluated using the property's currency setting. Only use events marked as
                    key events to improve ad campaign measurement.
                  </div>
                  <div className="ml-2 mt-2 grid grid-cols-7 gap-3">
                    <div className="ds-text-form-des col-span-2">Event Name</div>
                    <div className="ds-text-form-des">Min Value</div>
                    <div className="ds-text-form-des">Max Value</div>
                    <div className="ds-text-form-des">Min Count</div>
                    <div className="ds-text-form-des">Max Count</div>
                  </div>
                  {!isUndefinedOrEmpty(fields) && fields.map((eventMapping, index) => (
                    <div key={eventMapping.id} className="mt-2 grid grid-cols-7 gap-3">
                      <div className="col-span-2">
                        <TailwindCombobox
                          items={eventList}
                          name={`eventMappings[${index}].eventName`}
                        />
                      </div>
                      <NumberInput
                        name={`eventMappings[${index}].minEventValue`}
                      />
                      <NumberInput
                        name={`eventMappings[${index}].maxEventValue`}
                      />
                      <NumberInput
                        name={`eventMappings[${index}].minEventCount`}
                      />
                      <NumberInput
                        name={`eventMappings[${index}].maxEventCount`}
                      />
                      <div className="table-cell py-1">
                        <FontAwesomeIcon className="text-red-500 cursor-pointer mt-2"
                                         onClick={() => remove(index)}
                                         icon={faTrashCan}/>
                      </div>
                    </div>
                  ))
                  }
                  <div
                    onClick={() => append({
                      eventName: ''
                    })}
                    className="bg-transparent cursor-pointer hover:bg-blue-500 ds-button font-semibold hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent w-fit rounded mt-4"
                  >
                    <FontAwesomeIcon icon={faSquarePlus} className="me-2"/>
                    Add Events
                  </div>
                </div>
                <div className="flex justify-end items-center mb-1">
                  <button type={"button"}
                          onClick={() => onApply()}
                          className="ds-button">
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
