import { Fragment } from 'react'
import { Combobox, Dialog, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";


export default function TailwindCommandPalettes({
  open,
  setOpen,
  query,
  setQuery,
  pinnedActions = [],
  actions = [],
  placeholder = '',
}) {


  const filteredItems =
    query === ''
      ? actions
      : actions.filter((item) => {
        return item.name.toLowerCase().includes(query.toLowerCase())
      })

  return (
    <Transition.Root show={open} as={Fragment} afterLeave={() => setQuery('')} appear>
      <Dialog as="div" className="relative z-10 " onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="bg-white rounded-xl w-96 mx-auto w-100 max-w-xl mt-14 absolute right-5 ">
              <Combobox>
                <div className="relative">
                  <FontAwesomeIcon icon={faMagnifyingGlass} className="pointer-events-none absolute left-4 top-3.5 h-4 w-4 text-gray-400" />
                  <Combobox.Input
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-2 text-gray-900 placeholder:text-gray-400 sm:text-sm focus:outline-none"
                    placeholder={placeholder}
                    onChange={(event) => setQuery(event.target.value)}
                  />
                </div>

                <Combobox.Options static className="max-h-96 transform-gpu scroll-py-3 overflow-y-auto truncate line-clamp-2">
                  {pinnedActions.length > 0 && pinnedActions.map((item, key) => (
                    <Combobox.Option
                      onClick={item.action}
                      className="flex cursor-pointer cursor-default select-none rounded-xl p-3"
                      key={key}
                    >
                      <div className="flex h-5 flex-none items-center justify-center">
                        <FontAwesomeIcon icon={item.icon} />
                        <p className="ml-4 text-sm font-medium text-gray-900">
                          {item.name}
                        </p>
                      </div>
                    </Combobox.Option>
                  ))}

                  {filteredItems.length > 0 && filteredItems.map((item, key) => (
                    <Combobox.Option
                      key={key}
                      value={item}
                      onClick={item.action}
                      className={"flex cursor-pointer select-none rounded-xl p-3 hover:bg-slate-100"}
                    >
                      <div className="flex h-5 flex-none items-center justify-center">
                        {item.icon}
                        <p className="ml-4 text-sm text-gray-700">
                          {item.name}
                        </p>
                      </div>
                    </Combobox.Option>
                  ))}
                </Combobox.Options>

                {query !== '' && filteredItems.length === 0 && (
                  <div className="px-6 py-14 text-center text-sm sm:px-14">
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      type="outline"
                      name="exclamation-circle"
                      className="mx-auto h-5 w-5 text-gray-400"
                    />
                    <p className="mt-4 font-semibold text-gray-900">No results found</p>
                  </div>
                )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
