import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';

import './index.scss';
import { useEffect, useMemo, useRef, useState } from 'react';


export default function CohortTable({
  header = ['Week 1', 'Week 2', 'Week 3'],
  data = [
    {
      name: "All Users",
      total: 8935,
      data: [1, 2, 3]
    }
  ],
  isPercentValue = true,
}) {
  const { t } = useTranslation();

  const secondColumnWrapper = useRef(null);
  const [secondColumnLeft, setSecondColumnLeft] = useState(null);

  const [maxValue, setMaxValue] = useState(0);
  const [minValue, setMinValue] = useState(0);
  const [averageValue, setAverageValue] = useState(0);

  const widthClass = useMemo(() => {
    try {
      return data[0].data.length > 6 ? 'w-full' : 'w-fit';
    } catch (error) {
      return 'w-full';
    }
  }, [data]);

  useEffect(() => {
    const allData = data.map(entry => entry.data);

    const flattenedData = allData.flat();

    const filteredData = flattenedData.filter(value => value != 100 && value != 0);

    const maxValue = Math.max(...filteredData);
    const minValue = Math.min(...filteredData);
    const sum = filteredData.reduce((acc, curr) => acc + curr, 0);
    const averageValue = sum / filteredData.length;

    setMaxValue(maxValue);
    setMinValue(minValue);
    setAverageValue(averageValue);
  }, [data]);

  useEffect(() => {
    if (data.length > 0 && header.length > 0) {
      if (secondColumnWrapper) {
        const value = $(secondColumnWrapper.current).offset().left - $(secondColumnWrapper.current).parent().offset().left;
        setSecondColumnLeft(value);
      }
    } else {
      setSecondColumnLeft(0);
    }
  }, [data, header]);

  const getLabel = (value) => {
    if (value === 100) {
      return 'full';
    }

    if (value === 0) {
      return 'zero';
    }

    const diffToMax = Math.abs(maxValue - value);
    const diffToMin = Math.abs(minValue - value);
    const diffToAvg = Math.abs(averageValue - value);

    if (diffToMax < diffToMin && diffToMax < diffToAvg) {
      return 'max';
    } else if (diffToMin < diffToMax && diffToMin < diffToAvg) {
      return 'min';
    }
    return 'middle';
  };

  return (
    <div id='cohort'>
      <div className="cohort-header flex justify-between items-center border border-gray-300 px-5 py-4">
        <div></div>
        <div className='font-bold text-gray-600'>{t('USERS_WHO_CAME_BACK_AND_DID_RETURN_EVENT_ON')}</div>
        <div>
          <div className="flex justify-end items-center text-blue-700 cursor-pointer">
            <FontAwesomeIcon icon={faFileCsv} />
            <div className='font-semibold ms-2'>{t('EXPORT_AS_CSV')}</div>
          </div>
        </div>
      </div>
      <div className={`table-wrapper ${widthClass} overflow-x-scroll`}>
        <div className='table border border-t-0 text-sm bg-white'>
          <div className="table-row font-semibold">
            <div className='table-cell'>
              <div>{t('USERS_WHO_DID')}</div>
              <div>{t('FIRST_EVENT')}</div>
            </div>
            <div
              ref={secondColumnWrapper}
              style={{ left: `${secondColumnLeft}px` }}
              className='table-cell'
            >
              {t('USER_COUNT')}
            </div>
            {header.map((item, index) => (
              <div key={index} className='table-cell'>{item}</div>
            ))}
          </div>
          {data.map((row, i) => (
            <div key={i} className="table-row">
              <div className='table-cell'>
                {row.name}
              </div>
              <div
                style={{ left: `${secondColumnLeft}px` }}
                className='table-cell font-semibold'
              >
                {row.total.toLocaleString()}
              </div>
              {row.data.map((item, y) => (
                <div key={y} className={`table-cell ${getLabel(item)}`}>{item}{isPercentValue && '%'}</div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
