import HttpMethod from 'consts/httpMethod';
import { processRequest, processSuccessResponse, processErrorResponse } from 'utils';


export default {

  async getSegmentData({ appId, payload }) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `app/${appId}/segment`,
      method: HttpMethod.POST
    };

    const data = {
      payload
    };

    try {
      const res = await processRequest(ep, { data });
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return {};
    }
  },

  async saveSegment({ appId, data }) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `app/${appId}/segment/save`,
      method: HttpMethod.POST
    };

    const res = await processRequest(ep, { data });

    try {
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  async getSegment({ appId, segmentId }) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `app/${appId}/segment/${segmentId}`,
      method: HttpMethod.GET
    };

    try {
      const res = await processRequest(ep);
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return {};
    }
  },

  async UpdateSegment({ appId, segmentId, data}) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `app/${appId}/segment/${segmentId}/update`,
      method: HttpMethod.POST
    };

    try {
      const res = await processRequest(ep, { data });
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  async deleteSegment({ appId, segmentId }) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `app/${appId}/segment/${segmentId}/delete`,
      method: HttpMethod.POST
    };

    try {
      const res = await processRequest(ep);
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  async listSegments({ appId }) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `app/${appId}/segment/list`,
      method: HttpMethod.GET
    };

    try {
      const res = await processRequest(ep);
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  }
}
