import {useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";
import React from "react";
import EventFilter from "components/EventFilter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquarePlus, faTrashCan} from "@fortawesome/free-solid-svg-icons";


export default function UserNotDid({
                                     name,
                                     eventNameOptions = [],
                                     appId,
                                     dateDiff,
                                   }) {
  const {t} = useTranslation();
  const {watch, getValues, setValue} = useFormContext();
  const events = watch(`${name}.events`) || [];

  return (
    <div>
      <div className={`ds-block space-y-4`}>
        <div className="ds-text-form-title mb-4">
          User not do these events
        </div>

        <div className="space-y-4 ds-block !bg-white">
          {events.map((event, index) => (
            <div key={index} className={`flex items-center space-x-4 ds-block justify-between`}>
              <EventFilter
                name={`${name}.events[${index}]`}
                eventNameOptions={eventNameOptions}
                appId={appId}
                dateDiff={dateDiff}
                index={index + 1}
              />

              <FontAwesomeIcon
                onClick={() => {
                  setValue(`${name}.events`, events.filter((_, i) => i !== index));
                }}
                className='right-2 top-6 cursor-pointer text-slate-500 hover:text-red-500 justify-self-end'
                icon={faTrashCan}
              />
            </div>
          ))}

          <button
            type="button"
            onClick={() => {
              setValue(`${name}.events`, [...events, ""]);
            }}
            className="ds-button"
          >
            <FontAwesomeIcon icon={faSquarePlus} className="me-2"/>
            Event
          </button>
        </div>
      </div>
    </div>
  );
}
