import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { DsIconThreeDot } from "components/DsIcon";
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function TailwindMenuDialog({ items }) {
  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button>
            <div className="h-5 w-5"><DsIconThreeDot /></div>
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-50 mt-2 w-30 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="items-center">
              {items.map((item, index) => (
                <Menu.Item key={index}>
                  {({ active }) => (
                    item.ActionType === "button" ?
                      <div
                        onClick={() => item.Action(item)}
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'group flex items-center px-4 py-3 ds-input-text relative hover:bg-gray-100 cursor-pointer'
                        )}
                      >
                        <div className="w-5 h-5">{item.Icon}</div>
                        <span className="px-4">{item.Name}</span>
                      </div>
                      : item.ActionType === "link" ?
                        <Link to={item.Link} className={classNames(
                          active ? 'bg-gray-100' : '',
                          'group flex items-center px-4 py-3 ds-input-text relative cursor-pointer hover:bg-gray-100'
                        )}>
                          <div className="w-5 h-5">{item.Icon}</div>
                          <span className="px-4">{item.Name}</span>
                        </Link>
                        : ""
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  )
}
