import { Container } from './Container'
import { Logo } from "components/Logo";
import { NavLink } from './NavLink'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressBook, faBuilding, faEnvelope, faLocation, faPhone} from "@fortawesome/free-solid-svg-icons";
import {faMailchimp} from "@fortawesome/free-brands-svg-icons";
// import {navigation} from "./Header";

const navigation = {
  Products: [
    // { name: 'Measurement', href: '/measurement' },
    // { name: 'Analytics', href: '/analytics' },
    // { name: 'Recommendation', href: '/measurement' },
    // { name: 'Protect', href: '/fds' },
    { name: 'ShopMetric', href: '/shop-metric' },
    { name: 'Growth Analytics Provider', href: '/growth-analytics' },
  ],
  Resources: [
    { name: 'Document', href: 'https://developer.datasenses.io' },
    { name: 'Privacy', href: '/legal/privacy-policy-en' },
    { name: 'Terms of service', href: '/legal/term-of-service' },
  ],
  // Company: [
  //   { name: 'Contact', href: '/contact' },
  //   { name: 'About us', href: '/about' },
  // ],
  'Quick links': [
    { name: 'Request a demo', href: '/request_demo' },
  ]
}



export function Footer() {
  return (
    <footer className="mt-16 lg:mt-32 glass-effect p-12 lg:p-20" aria-labelledby="footer-heading">
      <div className="mx-auto max-w-7xl">
        <div className="grid gap-8 grid-cols-1 xl:grid xl:grid-cols-4 xl:gap-8">
          {Object.keys(navigation).map((key) => (
            <div key={key}>
              <h3 className="lp-text-desc-bg-light text-base font-bold">{key}</h3>
              <ul role="list" className="mt-3 space-y-4">
                {navigation[key].map((item) => (
                  <li key={item.name}>
                    <a href={item.href} className="lp-text-desc-bg-light text-sm">
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            ))}
        </div>
        <div className="mt-16 pt-8 border-t border-gray-900/10  sm:mt-20 lg:mt-24">
          <p className="text-xs leading-5 text-gray-500">&copy; 2024 DataSenses. All rights reserved.</p>
        </div>
      </div>
    </footer>
  )
}
