import { Menu, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarChart,
  faChartColumn,
  faChevronDown,
  faEllipsisVertical,
  faLineChart,
  faTable,
  faTrash,
  faShapes
} from "@fortawesome/free-solid-svg-icons";
import { Fragment, useMemo } from "react";
import { CHART_CONFIG } from "consts";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const defaultChartList = [
  { Icon: faLineChart, Name: "Line", Type: CHART_CONFIG.CHART_TYPE_LINECHART },
  { Icon: faBarChart, Name: "Bar", Type: CHART_CONFIG.CHART_TYPE_BARCHART },
  { Icon: faChartColumn, Name: "Stack", Type: CHART_CONFIG.CHART_TYPE_STACKEDCHART },
  {Icon: faTable, Name: "Table", Type: CHART_CONFIG.CHART_TYPE_TABLE},
  {Icon: faShapes, Name: "KPI", Type: CHART_CONFIG.CHART_TYPE_KPI},
]

export const customChartList = [
  { Icon: faLineChart, Name: "Line", Type: CHART_CONFIG.CHART_TYPE_LINECHART },
  { Icon: faBarChart, Name: "Bar", Type: CHART_CONFIG.CHART_TYPE_BARCHART },
  { Icon: faChartColumn, Name: "Stack", Type: CHART_CONFIG.CHART_TYPE_STACKEDCHART },
  { Icon: faTrash, Name: "Delete", Type: CHART_CONFIG.DELETE_CHART },
]

export default function ChartItems({ onClick, onDelete, icon, showDelete = false }) {
  const chartList = useMemo(() => {
    return showDelete ? customChartList : defaultChartList;
  }, [showDelete]);

  const handleChoose = (item) => {
    if (item.Type === CHART_CONFIG.DELETE_CHART) {
      onDelete();
    } else {
      onClick(item);
    }
  }

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          {icon === "THREE_DOT" ?
            <Menu.Button>
              <FontAwesomeIcon className="-mr-1 h-5 w-5 text-gray-400" icon={faEllipsisVertical} />
            </Menu.Button>
            :
            <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
              Options
              <FontAwesomeIcon icon={faChevronDown} className="-mr-1 h-4 w-4 text-gray-400" aria-hidden="true" />
            </Menu.Button>
          }
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-50 mt-2 w-30 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div>
              {chartList.map((item, index) => (
                <Menu.Item key={index}>
                  {({ active }) => (
                    <a
                      onClick={() => handleChoose(item)}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'group flex items-center px-4 py-3 text-sm bg-white cursor-pointer hover:bg-gray-100'
                      )}
                    >
                      <FontAwesomeIcon icon={item.Icon} />
                      <span className="px-4">{item.Name}</span>
                    </a>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  )
}
