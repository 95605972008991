
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import LPTextBlock from "../LPTextBlock";
export function Faqs() {
  return (
    <div className="max-w-7xl mx-auto glass-effect text-left lg:p-16 p-8">
      <div >
        <LPTextBlock
          title={"Frequently Asked Questions"}
          type={"bg_light"}
        />
      </div>

      <div className={"grid grid-rows-1 gap-4 text-base lg:text-lg"}>
        <div className="flex-1">
          <Disclosure>
            <DisclosureButton className="text-left">
              <p className="text-slate-700 font-semibold mb-2 hover:text-violet-500 px-2 ">
                How can DataSenses enhance your business?
              </p>
            </DisclosureButton>
            <DisclosurePanel className="text-gray-500">
              <p className="text-slate-700 px-2">
                DataSenses empowers you to gain deeper insights into your
                marketing campaigns and customer behaviors, enabling more informed
                decision-making.
              </p>
            </DisclosurePanel>
          </Disclosure>
        </div>
        <div className="flex-1">
          <Disclosure>
            <DisclosureButton className="text-left">
              <p className="text-slate-700 font-semibold mb-2 hover:text-violet-500 px-2">
                How does DataSenses optimize advertising efforts using data?
              </p>
            </DisclosureButton>
            <DisclosurePanel className="text-gray-500">
              <p className="text-slate-700 px-2">
                DataSenses integrates with AdNetworks to sync crucial data,
                facilitating ad optimization. We also analyze customer journeys to
                provide a comprehensive understanding of your audience.
              </p>
            </DisclosurePanel>
          </Disclosure>
        </div>
        <div className="flex-1">
          <Disclosure>
            <DisclosureButton className="text-left">
              <p className="text-slate-700 font-semibold mb-2 hover:text-violet-500 px-2">
                How is Return On Investment (ROI) calculated with DataSenses?
              </p>
            </DisclosureButton>
            <DisclosurePanel className="text-gray-500">
              <p className="text-slate-700 px-2">
                We monitor and attribute conversions from installations or
                purchases to specific campaigns, whether direct or advertised.
                This data is then used to map insights and calculate the ROI of
                your marketing efforts.
              </p>
            </DisclosurePanel>
          </Disclosure>
        </div>
      </div>

      {/*<div className="flex-1 px-12 pb-4">*/}
      {/*  <Disclosure>*/}
      {/*    <DisclosureButton className="text-left">*/}
      {/*      <p className="text-slate-700 font-semibold mb-2 text-lg hover:text-violet-500 ">*/}
      {/*        How can DataSenses enhance your business?*/}
      {/*      </p>*/}
      {/*    </DisclosureButton>*/}
      {/*    <DisclosurePanel className="text-gray-500">*/}
      {/*      <p className="text-slate-700 text-lg">*/}
      {/*        DataSenses empowers you to gain deeper insights into your*/}
      {/*        marketing campaigns and customer behaviors, enabling more informed*/}
      {/*        decision-making.*/}
      {/*      </p>*/}
      {/*    </DisclosurePanel>*/}
      {/*  </Disclosure>*/}
      {/*</div>*/}

      {/*<div className="flex-1 px-12 pb-4">*/}
      {/*  <Disclosure>*/}
      {/*    <DisclosureButton className="text-left">*/}
      {/*      <p className="text-slate-700 font-semibold mb-2 text-lg hover:text-violet-500">*/}
      {/*        How does DataSenses optimize advertising efforts using data?*/}
      {/*      </p>*/}
      {/*    </DisclosureButton>*/}
      {/*    <DisclosurePanel className="text-gray-500">*/}
      {/*      <p className="text-slate-700 text-lg">*/}
      {/*        DataSenses integrates with AdNetworks to sync crucial data,*/}
      {/*        facilitating ad optimization. We also analyze customer journeys to*/}
      {/*        provide a comprehensive understanding of your audience.*/}
      {/*      </p>*/}
      {/*    </DisclosurePanel>*/}
      {/*  </Disclosure>*/}
      {/*</div>*/}

      {/*<div className="flex-1 px-12">*/}
      {/*  <Disclosure>*/}
      {/*    <DisclosureButton className="text-left">*/}
      {/*      <p className="text-slate-700 font-semibold mb-2 text-lg hover:text-violet-500">*/}
      {/*        How is Return On Investment (ROI) calculated with DataSenses?*/}
      {/*      </p>*/}
      {/*    </DisclosureButton>*/}
      {/*    <DisclosurePanel className="text-gray-500">*/}
      {/*      <p className="text-slate-700 text-lg">*/}
      {/*        We monitor and attribute conversions from installations or*/}
      {/*        purchases to specific campaigns, whether direct or advertised.*/}
      {/*        This data is then used to map insights and calculate the ROI of*/}
      {/*        your marketing efforts.*/}
      {/*      </p>*/}
      {/*    </DisclosurePanel>*/}
      {/*  </Disclosure>*/}
      {/*</div>*/}
    </div>
  );
}

export default Faqs;
