import { Header } from "views/landing_page/home/Header";
import { CallToAction } from "views/landing_page/home/CallToAction";
import { Faqs } from "views/landing_page/home/Faqs";
import { Footer } from "views/landing_page/home/Footer";
import LPTextBlock from "views/landing_page/LPTextBlock";
import LPImageBlock from "views/landing_page/LPImageBlock";
import {DsIconLpCohort, DsIconLpTable} from "components/DsIcon";
import { CheckIcon } from "@heroicons/react/16/solid";
import attributionImg from "../home/images/attribution.png";
import roiImg from "../home/images/roi.png";

export default function LandingPageRecommendation() {
  return (
    <>
      <Header />
      <main className="pb-16 pt-56 lg:pt-56">
        <div className={"grid grid-rows-1 gap-y-20 lg:gap-y-26 py-10"}>
          <div className={"lp-background-2"}>
            <div className={"grid grid-cols-1 lg:grid-cols-2 mx-auto max-w-7xl lg:px-8 gap-2"}>
              <LPTextBlock
                title={"Higher Conversion Rates"}
                description={"By presenting users with products or services that align with their interests and needs, recommendation systems can significantly increase conversion rates. Advertisers benefit from improved campaign performance as users are more likely to take action when presented with relevant recommendations."}
                description_items={[
                  <span className={"flex flex-wrap justify-start items-center"}><CheckIcon
                    className={"w-5 h-5 mr-2 text-blue-600"}/> <span>Campaign performance suggestion&nbsp; </span></span>,
                ]}
                type={"bg_light"}
              />
              <img
                className="w-auto h-72 ml-20"
                src={attributionImg}
                alt=""
              />
            </div>
          </div>
          <div className={"lp-background-2"}>
            <div className={"grid grid-cols-1 lg:grid-cols-2 mx-auto max-w-7xl lg:px-8 gap-2"}>
              <LPTextBlock
                title={"Personalized Targeting"}
                description={"Recommendation systems analyze user data to deliver personalized recommendations based on individual preferences, behaviors, and demographics. This enables advertisers to target their ads more effectively, increasing the relevance of their campaigns and improving engagement with their target audience."}
                description_items={[
                  <span className={"flex flex-wrap justify-start items-center"}><CheckIcon
                    className={"w-5 h-5 mr-2 text-blue-600"}/> <span className={"font-bold"}>Label:&nbsp; </span> your customer characteristic</span>,
                  <span className={"flex flex-wrap justify-start items-center"}><CheckIcon
                    className={"w-5 h-5 mr-2 text-blue-600"}/> <span className={"font-bold"}>Predict:&nbsp; </span> loyal, churn customers</span>,
                ]}
                type={"bg_light"}
              />
              <img
                className="w-full h-auto"
                src={roiImg}
                alt=""
                sizes="(min-width: 2048px) 67.8125rem, (min-width: 1280px) 100vw, 45rem"
              />
            </div>
          </div>
        </div>
      </main>
      <CallToAction/>
      <Faqs/>
      <Footer/>
    </>
  )
}
