import {useMemo} from 'react';
import {Link, useLocation, useParams} from "react-router-dom";
import {PATHS} from 'consts';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {isUndefinedOrEmpty} from 'utils';
import {Logo} from 'components/Logo';
import {
  DsIconBarChart,
  DsIconCohort,
  DsIconDashboard,
  DsIconDeeplink,
  DsIconFunnel,
  DsIconHome,
  DsIconMarketPlace,
  DsIconSearch,
  DsIconSetting
} from "components/DsIcon";

import ItemGroup from './ItemGroup';

import './index.scss';
import DsIconEventsList from "components/DsIcon/DsIconEventsList";
import DsIconFraud from "components/DsIcon/DsIconFraud";
import DsIconConnection from "components/DsIcon/DsIconConnection";
import DsIconPieChart from "components/DsIcon/DsIconPieChart";
import DsIconSkan from "components/DsIcon/DsIconSkan";
import DsIconServer from "components/DsIcon/DsIconServer";
import DsIconEvents from "components/DsIcon/DsIconEvents";
import DsIconROI from "components/DsIcon/DsIconROI";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function SidePanel() {
  const location = useLocation();
  const appList = useSelector((state) => state.app.appList);
  const {appId} = useParams();
  const {t} = useTranslation();

  const defaultAppId = useMemo(() => {
    if (isUndefinedOrEmpty(appId)) {
      if (appList.length > 0) {
        return appList[0].appId;
      }
      return undefined;
    }
    return appId
  }, [appId, appList])

  const analytics = [
    {name: t('HOME'), href: PATHS.APP, icon: <DsIconHome/>},
    {name: t('OVERVIEW'), href: `${PATHS.APP}/${defaultAppId}${PATHS.OVERVIEW}`, icon: <DsIconDashboard/>},
    {name: t('ROI'), href: `${PATHS.APP}/${defaultAppId}${PATHS.ROI}`, icon: <DsIconROI/>},
    {name: 'Dashboard', href: `${PATHS.APP}${PATHS.DASHBOARD}`, icon: <DsIconServer/>},
    {name: t('PARTNER_MARKETPLACE'), href: `${PATHS.APP}${PATHS.PARTNER_MARKETPLACE}`, icon: <DsIconMarketPlace/>},
    {name: t('Deeplink'), href: `${PATHS.APP}${PATHS.DEEPLINK}`, icon: <DsIconDeeplink/>},

    {
      name: t('ANALYSIS'),
      href: '#',
      icon: <DsIconConnection/>,
      group: [
        {
          name: t('EVENTS'),
          href: `${PATHS.APP}/${defaultAppId}${PATHS.EVENTS}`,
          icon: <DsIconEvents/>,
        },
        {
          name: t('FUNNELS'),
          href: `${PATHS.APP}/${defaultAppId}${PATHS.FUNNEL}`,
          icon: <DsIconFunnel/>,
        },
        {
          name: t('COHORTS'),
          href: `${PATHS.APP}/${defaultAppId}${PATHS.COHORT}`,
          icon: <DsIconCohort/>,
        },
      ]
    },
    {
      name: t('SEGMENTS'),
      href: '#',
      icon: <DsIconPieChart/>,
      group: [
        {
          name: t('FIND_PEOPLE'),
          href: `${PATHS.APP}/${defaultAppId}${PATHS.FIND_PEOPLE}`,
          icon: <DsIconSearch/>,
        },
        {
          name: t('SEGMENTS'),
          href: `${PATHS.APP}/${defaultAppId}${PATHS.SEGMENTS}`,
          icon: <DsIconPieChart/>,
        },
      ]
    },
    // {name: 'Recommendation', href: `${PATHS.APP}${PATHS.RECOMMENDATION}`, icon: <DsIconLike/>},
    {
      name: t('SKAdNetwork'),
      href: '#',
      icon: <DsIconSkan/>,
      group: [
        {
          name: t('Overview'),
          href: `${PATHS.APP}/${defaultAppId}${PATHS.SKADNETWORK_OVERVIEW}`,
          icon: <DsIconDashboard/>,
        },
        {
          name: t('SKAN Studio'),
          href: `${PATHS.APP}/${defaultAppId}${PATHS.SKADNETWORK_STUDIO}`,
          icon: <DsIconSkan/>,
        },
      ]
    },
    {
      name: t('Events List'),
      href: `${PATHS.APP}/${defaultAppId}${PATHS.EVENTS_LIST}`,
      icon: <DsIconEventsList/>,
    },
    {name: t('FRAUD'), href: `${PATHS.APP}/${defaultAppId}${PATHS.FRAUD}`, icon: <DsIconFraud/>},
    // { name: 'Mock', href: `${PATHS.MOCK}`, icon: <DsIconDeeplink /> },
  ]

  const settings = [
    {name: 'Account Settings', href: PATHS.ACCOUNT_SETTINGS, icon: <DsIconSetting/>},
  ];

  return (
    <div id="side-panel-wrapper">
      <div id="side-pannel" className="lg:fixed lg:inset-y-0 lg:z-30 lg:flex lg:w-56 lg:flex-col md:w-32">
        <div className="flex grow flex-col gap-y-5 overflow-y-auto px-6 pb-4 pt-4">
          <div className="flex h-16 shrink-0 items-center">
            <Link to={PATHS.APP} className="-m-1.5 p-1.5">
              <Logo/>
            </Link>
          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7 ds-sidebar-text">
              <li>
                <ul role="list" className="-mx-2 mt-2 space-y-1">
                  {analytics.map((item) => (
                      !!item.group ?
                        <li key={item.name}>
                          <ItemGroup group={item.group} icon={item.icon} name={item.name}/>
                        </li>
                        :
                        <li key={item.name}>
                          <Link
                            to={item.href}
                            className={classNames(
                              location.pathname === item.href
                                ? 'active'
                                : '',
                              'group flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6'
                            )}
                          >
                            <div className="h-5 w-5 flex center justify-center">
                              {item.icon}
                            </div>
                            <span>{item.name}</span>
                          </Link>
                        </li>
                    )
                  )}
                </ul>
              </li>
              <li>
                <ul role="list" className="-mx-2 mt-2 space-y-1">
                  {settings.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className={classNames(
                          location.pathname === item.href
                            ? 'active'
                            : '',
                          'group flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6'
                        )}
                      >
                        <div className="h-4 w-4 flex center justify-center">{item.icon}</div>
                        <span className="truncate">{item.name}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>

            </ul>
          </nav>
        </div>
      </div>
    </div>
  )
}
