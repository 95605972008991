import React, { useState } from 'react';

const TooltipButton = (
  {
    icon: Icon,
    tooltipText,
    onClick = () => {},
    className = '',
  }
) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className={`relative inline-block ${className}`}>
      <button
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        onClick={onClick}
        className="h-6 w-6 ds-button-icon"
      >
        {Icon && <Icon className="w-6 h-6"/>}
      </button>

      {showTooltip && (
        <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 px-3 py-1 bg-gray-600 text-white text-xs rounded-lg">
          {tooltipText}
        </div>
      )}
    </div>
  );
};

export default TooltipButton;
