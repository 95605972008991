import React, {useEffect, useMemo, useState} from 'react'
import {buildAppList} from "utils/app";
import {DATASENSES_DOCS_URL, PATHS} from "consts";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {SDKS} from "consts/app";
import {useParams} from "react-router-dom";
import {beautifyDate} from "utils";
import {JsonToggle} from "views/events_list/JsonToggle";
import {showError} from "components/FlashMessage/flashMessageSlice";
import AppService from "services/app";
import DsListBox from "components/DsListBox";


const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ')
}

export default function EventsList() {
  const {t} = useTranslation();
  const {appId} = useParams();
  const [events, setEvents] = useState([]);
  const dispatch = useDispatch();

  const appList = useSelector((state) => state.app.appList);

  const appOptions = useMemo(() => {
    return buildAppList(appList, PATHS.EVENTS_LIST);
  }, [appList]);

  const getEvents = async () => {
    try {
      const response = await AppService.getEventLogs({appId});
      const respEvents = response.map((event) => {
        return JSON.parse(event.metrics);
      });
      respEvents.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setEvents(respEvents);
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
  }

  useEffect(() => {
    getEvents();
  }, [appId]);

  return (
    <>
      <div className="flex items-center">
        <div className="font-bold text-xl me-2 ml-1">{t('Events List')}</div>
      </div>

      <DsListBox listData={appOptions}/>

      <div className="ds-block mt-4">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">Events</h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of recent events that app sent to the server using SDK.
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <a
              href={SDKS.find(sdk => sdk.name === appList.find(app => app.appId === appId).platform) ? SDKS.find(sdk => sdk.name === appList.find(app => app.appId === appId).platform).href : DATASENSES_DOCS_URL}
              target="_blank"  // Opens link in new tab
              rel="noopener noreferrer" // Security best practice for target=_blank
              className="ds-button"
            >
              SDK Docs
            </a>
          </div>

        </div>
      </div>

      <div className="ds-block mt-4">
        <ul role="list" className="space-y-6 mt-4 mb-4">
          {events.map((event, activityItemIdx) => (
            <li key={activityItemIdx} className="relative flex gap-x-4">
              <div
                className={classNames(
                  activityItemIdx === events.length - 1 ? 'h-6' : '-bottom-6',
                  'absolute left-0 top-0 flex w-6 justify-center',
                )}
              >
                <div className="w-px bg-gray-200"/>
              </div>
              <>
                <div className="relative flex h-6 w-6 flex-none items-center justify-center">
                  <div className="h-2 w-2 rounded-full bg-gray-100 ring-1 ring-gray-300"/>
                </div>
                <div style={{overflowX: 'auto', overflowY: 'hidden'}} className="flex-auto">
                  <p className="text-xs leading-5 text-gray-500 ds-input-text">
                    <span className="font-medium text-gray-900">{event.event_name}</span> has been sent to Datasenses
                    with
                    user data <JsonToggle jsonData={event.user_data}/> and event properties <JsonToggle
                    jsonData={event.event_properties}/>
                  </p>
                </div>
                <time className="flex-none text-xs leading-5 text-gray-500">
                  {beautifyDate(event.created_at).timeAgo}
                </time>
              </>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}
