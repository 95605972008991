import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {DsIconDelete, DsIconEdit} from "components/DsIcon";
import TailwindMenuDialog from "components/TailwindMenuDialog";
import {showError} from "components/FlashMessage/flashMessageSlice";

import CompanyService from "services/company";
import InviteUser from "./invite_user";
import DeleteUserModal from "./delete_user";
import UpdateUserModal from "./edit_user";


export default function CompanyUsers() {
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();
  const [userEmail, setUserEmail] = useState('');
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const currentUserEmail = useSelector((state) => state.user.profile.email);

  const handleUpdate = (email) => {
    if (email === currentUserEmail) {
      dispatch(showError({message: "You can't update yourself"}));
      return;
    }
    setUserEmail(email);
    setIsUpdateOpen(true);
  }

  const handleDelete = (email) => {
    if (email === currentUserEmail) {
      dispatch(showError({message: "You can't delete yourself"}));
      return;
    }
    setUserEmail(email);
    setIsDeleteOpen(true);
  }

  const listCompanyUsers = async () => {
    try {
      const response = await CompanyService.listCompanyUsers();
      setUsers(response);
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
  }

  useEffect(() => {
    listCompanyUsers();
  }, []);

  return (
    <>
      <div className="ds-block ds-input-text">
        <div className="p-2">
          <div className="flex items-center justify-between">
            <div className="ds-text-form-title">
              Users
            </div>
            <div className="">
              <InviteUser/>
            </div>
          </div>
          <div className="">
            <div className="min-w-full align-middle">
              <table className="min-w-full divide-y">
                <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left sm:pl-3">
                    Name
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left">
                    Role
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left">
                    Email
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                  </th>
                </tr>
                </thead>
                <tbody>
                {users.map((user, index) => (
                  <tr key={index} className="even:bg-gray-50">
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 sm:pl-3">
                      {user.name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4">{user.role}</td>
                    <td className="whitespace-nowrap px-3 py-4">{user.email}</td>
                    <td
                      className="relative whitespace-nowrap py-4 pl-3 text-right flex justify-end">
                      <TailwindMenuDialog
                        items={[
                          {
                            Name: "Update",
                            Icon: <DsIconEdit/>,
                            ActionType: "button",
                            Action: () => handleUpdate(user.email)
                          },
                          {
                            Name: "Delete",
                            Icon: <DsIconDelete/>,
                            ActionType: "button",
                            Action: () => handleDelete(user.email)
                          },
                        ]}
                      />
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <DeleteUserModal
          isOpen={isDeleteOpen}
          closeModal={() => setIsDeleteOpen(false)}
          email={userEmail}
          reset={listCompanyUsers}
        />
        <UpdateUserModal
          isOpen={isUpdateOpen}
          closeModal={() => setIsUpdateOpen(false)}
          email={userEmail}
          reset={listCompanyUsers}
        />
      </div>
    </>
  )
}
