import React, {Fragment, useEffect, useState} from "react"; // Import useState
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {Dialog, Transition} from '@headlessui/react'
import {CheckIcon, XMarkIcon} from '@heroicons/react/24/outline'

import EmailService from 'services/email';
import {PATHS} from "../../consts";

export default function EmailVerify() {
  const location = useLocation();
  const {t} = useTranslation();
  const [verificationResult, setVerificationResult] = useState(false);
  const [message, setMessage] = useState("Verify Email Successfully!");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const sendToken = async () => {
    const token = new URLSearchParams(location.search).get('token');

    try {
      await EmailService.verify({token});
      setVerificationResult(true); // Set verification result to success

    } catch (error) {
      setVerificationResult(false); // Set verification result to error
      setMessage(error.message); // Update message state here
    }
    setOpen(true)
  };

  const navigateToLogin = () => {
    navigate(PATHS.LOGIN);
  }
  useEffect(() => {
    sendToken();
  }, []);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  {verificationResult ? (
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                      <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true"/>
                    </div>
                  ) : (
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                      <XMarkIcon className="h-6 w-6 text-red-600" aria-hidden="true"/>
                    </div>
                  )}
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      {verificationResult ? "Verify Email Successfully!" : "Verify Email Failed!"}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {message}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={navigateToLogin}
                  >
                    Go back to login
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
