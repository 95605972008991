import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { PATHS } from "consts";


export default function AttributionLink() {
  const { projectId } = useParams();
  const { t } = useTranslation();

  return (
    <div id="attribution-link-tab" className="block">
      <div className="px-4">
        <div className="text-lg">Meta Ads (Adwords) Attribution link</div>
        <div className="text-xs mt-2">Create an attribution link to use in a campaign with this partner.</div>

        <hr className="h-px my-4 bg-gray-200 border-0" />

        <div className="text-xs">This partner does not use external attribution links.</div>
      </div>

      <footer>
        <hr className="h-px my-6 bg-gray-200 border-0" />

        <div className="flex justify-between items-center px-4 w-full">
          <Link
            to={`${PATHS.PROJECT}/${projectId}${PATHS.PARTNER_MARKETPLACE}`}
            className="text-blue-600 flex items-center text-sm hover:text-blue-500 hover:underline"
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            <span className="ms-1 mb-0.5">{t('BACK_TO_LIST')}</span>
          </Link>

          <div className="flex justify-end items-center">
            <button className="bg-gray-200 text-gray-400 py-1.5 px-4 rounded cursor-not-allowed">
              Save Attribution Link
            </button>
          </div>
        </div>

      </footer>
    </div>
  )
}
