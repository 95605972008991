import {useState} from 'react'
import {Dialog} from '@headlessui/react'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars, faPenToSquare, faXmark} from '@fortawesome/free-solid-svg-icons';

import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import ToggleBox from 'components/ToggleBox';
import {DsIconLogout} from 'components/DsIcon';

import './index.scss';
import {PATHS} from "../../consts";
import {tabLink} from "../../views/account_settings/tabs";
import {persistor} from "store";

const navigation = [
  {name: 'Product', href: '#'},
  {name: 'Features', href: '#'},
  {name: 'Marketplace', href: '#'},
  {name: 'Company', href: '#'},
]

export default function AppHeader() {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const email = useSelector((state) => state.user.profile.email);

  const handleLogout = () => {
    persistor.purge().then(() => {
      console.log('Persisted state has been cleared');
    })
    window.location = `${PATHS.LOGIN}`;
  };

  const navigateMyProfile = () => {
    window.location = `${PATHS.ACCOUNT_SETTINGS}${tabLink.MY_PROFILE}`
  }

  return (
    <div id="app-header" className="w-full z-40">
      <nav className="w-full h-full flex items-center justify-end p-3" aria-label="Global">
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <FontAwesomeIcon icon={faBars} className="h-5 w-5" aria-hidden="true"/>
          </button>
        </div>
        <div className="flex">
          <ToggleBox position="right">
            <ToggleBox.Button>
              <div className="hidden lg:flex lg:flex-1 lg:justify-end items-center">
                <div
                  className="ds-background-color-violet w-8 h-8 rounded-full inline-flex items-center justify-center cursor-pointer border-slate-400 hover:border-2 hover:w-8.5 hover:h-8.5">
                  <span className='font-semibold text-white uppercase select-none'>{email[0]}</span>
                </div>
              </div>
            </ToggleBox.Button>

            <ToggleBox.Content>
              <div>
                <div className='py-4 px-3 hover:bg-slate-100 cursor-pointer'>
                  <div className='flex items-center'>
                    <div
                      className="ds-background-color-violet w-8 h-8 rounded-full bg-blue-600 inline-flex items-center justify-center">
                      <span className='uppercase text-white select-none'>{email[0]}</span>
                    </div>
                    <span onClick={navigateMyProfile} className='ellipsis w-36 mx-2 select-none'>{email}</span>
                    <FontAwesomeIcon icon={faPenToSquare} className='text-blue-500 hidden'/>
                  </div>
                </div>
                <hr className="bg-gray-200 border-0 h-px"/>
                <div className='py-3 px-5 hover:bg-slate-100 cursor-pointer' onClick={handleLogout}>
                  <div className='flex items-center justify-start'>
                    <div className="w-5 h-5">
                      <DsIconLogout/>
                    </div>
                    <div className='ml-3'>{t('LOGOUT')}</div>
                  </div>
                </div>
              </div>
            </ToggleBox.Content>
          </ToggleBox>
        </div>

      </nav>

      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10"/>
        <Dialog.Panel
          className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt=""
              />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <FontAwesomeIcon icon={faXmark} className="h-5 w-5" aria-hidden="true"/>
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="py-6">
                <div
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  onClick={handleLogout}
                >
                  {t('LOGOUT')}
                </div>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </div>
  )
}
