import { Fragment } from "react";
import { CheckIcon, MinusIcon } from "@heroicons/react/20/solid";
import LPTextBlock from "views/landing_page/LPTextBlock";
const tiers = [
  {
    name: "Basic",
    id: "tier-basic",
    href: "/request-demo",
    priceMonthly: "",
    mostPopular: false,
  },
  {
    name: "Standard",
    id: "tier-basic",
    href: "/request-demo",
    priceMonthly: "",
    mostPopular: false,
  },
  {
    name: "Growth",
    id: "tier-essential",
    href: "/request-demo",
    priceMonthly: "",
    mostPopular: true,
  },
  {
    name: "Enterprise",
    id: "tier-premium",
    href: "/request-demo",
    priceMonthly: "",
    mostPopular: false,
  },
];

const sections = [
  {
    name: "Features",
    features: [
      {
        name: "Pricing",
        tiers: {
          Basic: "Contact us (Discount 15% with annual paid)",
          Standard: "Contact us (Discount 15% with annual paid)",
          Growth: "Contact us (Discount 15% with annual paid)",
          Enterprise: "Contact us (Discount 15% with annual paid)",
        },
      },
      {
        name: "Connector",
        tiers: {
          Basic: "10",
          Standard: "10",
          Growth: "All (On demand)",
          Enterprise: "All (On demand)",
        },
      },
      {
        name: "Pipeline",
        tiers: {
          Basic: "25",
          Standard: "55",
          Growth: "125",
          Enterprise: "Unlimited",
        },
      },
      {
        name: "Data transformation",
        tiers: {
          Basic: "5 requests /month",
          Standard: "20 requests /month",
          Growth: "40 requests /month",
          Enterprise: "Unlimited",
        },
      },
      {
        name: "Sync frequency",
        tiers: {
          Basic: "Every 3 hours",
          Standard: "Every 3 hours",
          Growth: "Every hour",
          Enterprise: "On demand",
        },
      },
      {
        name: "DataSenses BI Tool",
        tiers: {
          Basic: false,
          Standard: false,
          Growth: true,
          Enterprise: true,
        },
      },
      {
        name: "Row limit",
        tiers: {
          Basic: "1M rows / month",
          Standard: "2M rows / month",
          Growth: "5M rows / month",
          Enterprise: "Unlimited",
        },
      },
      {
        name: "Growth consulting",
        tiers: {
          Basic: false,
          Standard: false,
          Growth: false,
          Enterprise: true,
        },
      },
      {
        name: "Growth Analytics Platform",
        tiers: {
          Basic: false,
          Standard: false,
          Growth: false,
          Enterprise: true,
        },
      },
    ],
  },
  {
    name: "Support",
    features: [
      {
        name: "1:1 onboarding tour",
        tiers: {
          Basic: false,
          Standard: false,
          Growth: true,
          Enterprise: true,
        },
      },
      {
        name: "Priority phone support",
        tiers: {
          Basic: false,
          Standard: false,
          Growth: false,
          Enterprise: true,
        },
      },
      {
        name: "Quarterly product workshops",
        tiers: {
          Basic: false,
          Standard: false,
          Growth: false,
          Enterprise: true,
        },
      },
      {
        name: "24/7 online support",
        tiers: {
          Basic: false,
          Standard: false,
          Growth: false,
          Enterprise: true,
        },
      },
    ],
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ShopMetricPricing() {
  return (
    <div className="">
      <div className="text-center">
        <LPTextBlock
          title={"Plans and pricing"}
          description={
            "DataSenses is a Growth Analytics Provider. Choose a plan that fits your needs to get started transforming insights into growth."
          }
          type={"bg_light"}
        />
      </div>

      {/* xs to lg */}
      <div className="mx-auto mt-12 max-w-md space-y-8 sm:mt-16 lg:hidden">
        {tiers.map((tier) => (
          <section
            key={tier.id}
            className={classNames(
              tier.mostPopular
                ? "rounded-xl  glass-effect-2 bg-violet-50/50"
                : "",
              "p-8"
            )}
          >
            <h3
              id={tier.id}
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              {tier.name}
            </h3>
            <p className="mt-2 flex items-baseline gap-x-1 text-gray-900">
              <span className="text-4xl font-bold">{tier.priceMonthly}</span>
            </p>
            <a
              href={tier.href}
              aria-describedby={tier.id}
              className={classNames(
                tier.mostPopular
                  ? "bg-indigo-600 text-white hover:bg-indigo-500"
                  : "text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300",
                "mt-8 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              )}
            >
              Buy plan
            </a>
            <ul
              role="list"
              className="mt-10 space-y-4 text-sm leading-6 text-gray-900"
            >
              {sections.map((section) => (
                <li key={section.name}>
                  <ul role="list" className="space-y-4">
                    {section.features.map((feature) =>
                      feature.tiers[tier.name] ? (
                        <li key={feature.name} className="flex gap-x-3">
                          <CheckIcon
                            className="h-6 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            {feature.name}{" "}
                            {typeof feature.tiers[tier.name] === "string" ? (
                              <span className="text-sm leading-6 text-gray-500">
                                ({feature.tiers[tier.name]})
                              </span>
                            ) : null}
                          </span>
                        </li>
                      ) : null
                    )}
                  </ul>
                </li>
              ))}
            </ul>
          </section>
        ))}
      </div>

      {/* lg+ */}
      <div className="isolate mt-20 hidden lg:block">
        <div className="relative">
          {tiers.some((tier) => tier.mostPopular) ? (
            <div className="absolute inset-x-5 inset-y-0 -z-10 flex">
              <div
                className="flex w-1/5"
                aria-hidden="true"
                style={{
                  marginLeft: `${
                    (tiers.findIndex((tier) => tier.mostPopular) + 1) * 20
                  }%`,
                }}
              >
                <div className="w-full rounded-xl  glass-effect-2 bg-violet-50/50" />
              </div>
            </div>
          ) : null}
          <table className="w-full table-fixed border-separate border-spacing-x-8 text-left">
            <caption className="sr-only">Pricing plan comparison</caption>
            <colgroup>
              <col className="w-1/5" />
              <col className="w-1/5" />
              <col className="w-1/5" />
              <col className="w-1/5" />
              <col className="w-1/5" />
            </colgroup>
            <thead>
              <tr>
                <td />
                {tiers.map((tier) => (
                  <th
                    key={tier.id}
                    scope="col"
                    className="px-6 pt-6 xl:px-8 xl:pt-8"
                  >
                    <div className="font-semibold leading-7 text-gray-900 text-center">
                      {tier.name}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">
                  <span className="sr-only">Price</span>
                </th>
                {tiers.map((tier) => (
                  <td key={tier.id} className="px-6 pt-2 xl:px-8">
                    <div className="flex items-baseline gap-x-1 text-gray-900">
                      <span className="text-4xl font-bold">
                        {tier.priceMonthly}
                      </span>
                    </div>
                    <a
                      href={tier.href}
                      className={classNames(
                        tier.mostPopular
                          ? "bg-indigo-600 text-white hover:bg-indigo-500"
                          : "text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300",
                        "mt-8 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      )}
                    >
                      Contact Us
                    </a>
                  </td>
                ))}
              </tr>
              {sections.map((section, sectionIdx) => (
                <Fragment key={section.name}>
                  <tr>
                    <th
                      scope="colgroup"
                      colSpan={4}
                      className={classNames(
                        sectionIdx === 0 ? "pt-8" : "pt-16",
                        "pb-4 text-sm font-semibold leading-6 text-gray-900"
                      )}
                    >
                      {section.name}
                      <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/10" />
                    </th>
                  </tr>
                  {section.features.map((feature) => (
                    <tr key={feature.name}>
                      <th
                        scope="row"
                        className="py-4 text-sm font-normal leading-6 text-gray-900"
                      >
                        {feature.name}
                        <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
                      </th>
                      {tiers.map((tier) => (
                        <td key={tier.id} className="px-6 py-4 xl:px-8">
                          {typeof feature.tiers[tier.name] === "string" ? (
                            <div className="item-justifytext-center text-sm leading-6 text-gray-500">
                              {feature.tiers[tier.name]}
                            </div>
                          ) : (
                            <>
                              {feature.tiers[tier.name] === true ? (
                                <CheckIcon
                                  className="mx-auto h-5 w-5 text-indigo-600"
                                  aria-hidden="true"
                                />
                              ) : (
                                <MinusIcon
                                  className="mx-auto h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              )}

                              <span className="sr-only">
                                {feature.tiers[tier.name] === true
                                  ? "Included"
                                  : "Not included"}{" "}
                                in {tier.name}
                              </span>
                            </>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
