import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DATE_FORMAT } from 'consts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import './index.scss';

/**
 * Calendar component to display a monthly calendar with selectable dates.
 * @param {Object} props - The props object.
 * @param {string} [props.date=moment().toISOString()] - The date to display in the calendar.
 * @param {Array} [props.range=[]] - The range of dates.
 * @param {string} [props.value=moment().format(DATE_FORMAT)] - The currently selected date.
 * @param {boolean} [props.showPrev=false] - Boolean to indicate whether to show the previous month button.
 * @param {boolean} [props.showNext=false] - Boolean to indicate whether to show the next month button.
 * @param {Function} [props.onMonthChange=() => {}] - Function to handle month change.
 * @param {Function} [props.onDateClick=() => {}] - Function to handle date click.
 * @param {string} [props.className=''] - Additional CSS class names for customization.
 * @returns {JSX.Element} The JSX representation of the Calendar component.
 */
export default function Calendar({
  date=moment().toISOString(),
  range=[],
  value=moment().format(DATE_FORMAT),
  showPrev=false,
  showNext=false,
  onMonthChange=() => {},
  onDateClick=() => {},
  className='',
}) {
  const { t } = useTranslation();

  const from = useMemo(
    () => moment(range?.length ? range[0] : date).startOf('day')
  , [range, date]);

  const to = useMemo(
    () => moment(range?.length ? range[1] : date).startOf('day')
  , [range, date]);

  const today = moment().startOf('day');

  const prev = () => {
    onMonthChange(moment(value).clone().subtract(1, 'months').startOf('months').format(DATE_FORMAT));
  };

  const next = () => {
    onMonthChange(moment(value).clone().add(1, 'months').startOf('months').format(DATE_FORMAT));
  };

  const dates = useMemo(() => {
    const d = moment(value).clone().startOf('month');
    d.subtract(d.day(), 'days');

    if (d.date() === 1) {
      d.subtract(7, 'days');
    }

    d.add(1, 'days');

    return Array(6 * 7).fill(0).map(() => {
      let dd = '';

      if (d.month() === moment(value).month()) {
        dd = d.date();
      }

      const cell = {
        date: dd,
        key: d.format(DATE_FORMAT),
        highlight: (!!dd && (d.diff(from, 'days') >= 0) && (to.diff(d, 'days') >= 0)),
        start: (!!dd && (d.diff(from, 'days') >= 0) && (to.diff(d, 'days') >= 0) && ((d.diff(from, 'days') === 0) || (d.day() === 1))),
        end: (!!dd && (d.diff(from, 'days') >= 0) && (to.diff(d, 'days') >= 0) && ((to.diff(d, 'days') === 0) || (d.day() === 0))),
        active: (!!dd && ((d.diff(from, 'days') === 0) || (d.diff(to, 'days') === 0))),
        available: d.isSameOrBefore(today),
      };

      d.add(1, 'day');

      return cell;
    });
  }, [from, to, value]);

  const getClassName = (cell) => {
    let name = '';

    if (cell.highlight) {
      name += ' highlight';
    }

    if (cell.start) {
      name += ' start';
    }

    if (cell.end) {
      name += ' end';
    }

    if (cell.active) {
      name += ' active';
    }

    if (!cell.available) {
      name += ' disabled';
    }

    return name;
  };

  return (
    <div className={`calendar bg-white h-fit ds-input-text ${className}`}>
      <div className="flex items-center justify-between">
        { showPrev ? <FontAwesomeIcon icon={faChevronLeft} className='w-4 h-4 cursor-pointer' onClick={prev} /> : <div/> }

        <span className='text-center select-none'>{ moment(value).format('MMMM YYYY') }</span>

        { showNext ? <FontAwesomeIcon icon={faChevronRight} className='w-4 h-4 cursor-pointer' onClick={next} /> : <div/> }
      </div>

      <div className="grid">
        <table className="calendar ds-input-text">
          <thead>
            <tr className="ds-input-text">
              <th>
                <span className="ds-input-text">{ t('MO') }</span>
              </th>
              <th>
                <span className="ds-input-text">{t('TU')}</span>
              </th>
              <th><span className="ds-input-text">{t('WE')}</span></th>
              <th><span className="ds-input-text">{t('TH')}</span></th>
              <th><span className="ds-input-text">{t('FR')}</span></th>
              <th><span className="ds-input-text">{t('SA')}</span></th>
              <th><span className="ds-input-text">{t('SU')}</span></th>
            </tr>
          </thead>
          <tbody>
            {
              Array.from({ length: 6 }).map((_, index) => (
                <tr key={index} >
                  {
                    dates.slice(index*7, index*7 + 7).map((cell) => (
                      <td
                        key={cell.key}
                        onClick={() => onDateClick(cell.key)}
                        className={getClassName(cell)}
                      >
                        { cell.date }
                      </td>
                    ))
                  }
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}
