import { Container } from './Container'
import { DsIconLogo } from "components/DsIcon";

export function CallToAction() {
  return (
    <div className="mx-auto max-w-7xl text-center mb-10">
      <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">
        Focus on your growth, <span className={"relative whitespace-nowrap text-blue-600"}>not on mess data</span>
        <br/>
      </h2>
      <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
        We are happy to serve & support your business growth
      </p>
      <div className="mt-10 flex items-center justify-center gap-x-6">
        <a href="/request-demo" className="group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-blue-600 text-white hover:text-slate-100 hover:bg-blue-500 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600"
           color="blue" variant="solid">
          Request a demo
        </a>
      </div>
    </div>
  )
}
