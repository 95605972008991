import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import {Provider} from 'react-redux';
import './index.scss';
import App from './App';
import {store, persistor} from 'store';
import {PersistGate} from 'redux-persist/integration/react';
import reportWebVitals from './reportWebVitals';
import {initInterceptors} from 'utils/interceptor';
import FlashMessage from "components/FlashMessage";
import './i18n';

initInterceptors(store);

import datasenses from "datasenses-web-sdk";

datasenses.init("AZkmmGM7iIMk9TO2Zsn6sJbz5ml8jJt1BL-yWpp-Si6LiY4_1ZgwJhZ7ZGDE5V5gchjVWRIzkNf-ZtGAqAc");

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <FlashMessage/>

        <App/>
      </PersistGate>
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
