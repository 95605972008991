import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from "react-hook-form";
import TextInput from "components/TextInput";
import TailwindCombobox from "components/TailwindCombobox";
import React, {useEffect, useState} from "react";
import deeplink from "services/deeplink";
import {showError, showInfo} from "components/FlashMessage/flashMessageSlice";
import {useDispatch} from "react-redux";
import {isUndefinedOrEmpty} from "utils";
import {Link, useParams} from "react-router-dom";
import {PATHS, PLATFORM} from "consts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import TextAreaInput from "components/TextAreaInput";
import ToggleCheckbox from "components/ToggleCheckbox";
import {androidRedirectOptions, iOSRedirectOptions, webRedirectOptions} from "../types";
import AppSvc from "services/app";


export default function UpdateDeeplink() {
  const { t } = useTranslation();
  const methods = useForm();
  const dispatch = useDispatch();
  const [templates, setTemplates] = useState([]);
  const [link, setLink] = useState({});
  const [initValue, setInitValue] = useState(null);

  const {templateId, linkId} = useParams()
  const [isEditing, setIsEditing] = useState(false)
  const [defaultLinkInfo, setDefaultLinkInfo] = useState({})
  const [template, setTemplate] = useState({});
  const [webAppId, setWebAppId] = useState("");
  const [webApp, setWebApp] = useState({});

  const isSocialMedia = methods.watch('isSocialMedia');
  const androidRedirect = methods.watch('androidRedirect');
  const iosRedirect = methods.watch('iosRedirect');
  const isEditDisabled = true;


  const getTemplateInfo = async (templateId) => {
    try {
      const result = await deeplink.getTemplateById(templateId);
      setTemplate(result);
      setWebAppId(result.web_app_id);
      // const tempValue = [
      //   {key: 'androidRedirect', value: defaultLinkInfo.android_is_open_store ? PLATFORM.GGPLAY : PLATFORM.MWEB},
      //   {key: 'iosRedirect', value: defaultLinkInfo.ios_is_open_store  ? PLATFORM.APPSTORE : PLATFORM.MWEB},
      // ]
      // console.log(defaultLinkInfo.android_is_open_store)
      // console.log(defaultLinkInfo.ios_is_open_store)
      //
      // tempValue.map((kv) => {
      //   methods.setValue(kv.key, kv.value);
      // });
    } catch (e) {
      dispatch(showError({message: "Error when get template"}));
    }
  }

  const getTemplates = async () => {
    try {
      const result = await deeplink.getListTemplates();
      setTemplates(result.map((t) => ({
        "fieldKey": t.id,
        "name": t.name,
      })))
    } catch (e) {

    }
  }

  useEffect(() => {
    getTemplates();
  }, []);

  const genQr = async (link) => {
    try {
      return await deeplink.generateQrCode(link)
    } catch (e) {
      console.log(e)
    }
  }

  const getLinkDetail = async (templateId, linkId) => {
    try {
      const result = await deeplink.getDeeplinkById(templateId, linkId).then((e) => {
        genQr(e.full_url_short).then((r) => {
          e.qrcode = r
        })
        return e
      });

      setDefaultLinkInfo(result);
      setIsEditing(true);
      const tempValue2 = [
        {key: 'androidRedirect', value: result.android_is_open_store ? PLATFORM.GGPLAY : PLATFORM.MWEB},
        {key: 'iosRedirect', value: result.ios_is_open_store  ? PLATFORM.APPSTORE : PLATFORM.MWEB},
      ]

      tempValue2.map((kv) => {
        methods.setValue(kv.key, kv.value);
      });

      const webApp = await AppSvc.getApp({ appId: template.app_web_id });

      const tempValue = [
        {key: 'deeplinkName', value: result.name},
        {key: 'UTMSource', value: result.utm_source},
        {key: 'UTMMedium', value: result.utm_medium},
        {key: 'UTMCampaign', value: result.utm_campaign},
        {key: 'isSocialMedia', value: result.title === ""},
        {key: 'title', value: result.title},
        {key: 'description', value: result.description},
        {key: 'imageURL', value: result.image_url},
        {key: 'androidLinkAppNotInstallPath', value: result.android_link_app_not_install_path},
        {key: 'iosLinkAppNotInstallPath', value: result.ios_link_app_not_install_path},
        {key: 'webPath', value: result.web_path},
        // {key: 'androidRedirect', value: result.android_is_open_store ? PLATFORM.GGPLAY : PLATFORM.MWEB},
        // {key: 'iosRedirect', value: result.ios_is_open_store ? PLATFORM.APPSTORE : PLATFORM.MWEB},
      ]
      setInitValue(tempValue);
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (!isUndefinedOrEmpty(templateId) && !isUndefinedOrEmpty(linkId)) {
      getTemplateInfo(templateId);
      getLinkDetail(templateId, linkId);
      getWebApp();
    }
  }, []);

  const getWebApp = async () => {
    try {
      const result = await AppSvc.getApp({ appId: template.app_web_id });
      setWebApp(result);
    } catch (err) {
    }
  };

  useEffect(() => {
    getWebApp();
  }, [webAppId]);

  const discardChanges = () => {
    if (initValue === null) {
      return;
    }
    initValue.map((kv) => {
      methods.setValue(kv.key, kv.value);
    });
  }

  const onSubmit = async (data) => {
    if (!data.androidLinkAppNotInstallPath || data.androidLinkAppNotInstallPath === "undefined") {
      data.androidLinkAppNotInstallPath = ""
    }
    if (!data.iosLinkAppNotInstallPath || data.iosLinkAppNotInstallPath === "undefined") {
      data.iosLinkAppNotInstallPath = ""
    }
    if (!data.webPath || data.webPath === "undefined") {
      data.webPath = ""
    }

    console.log(data.iosRedirect)

    const payload = {
      name: data.deepLinkName,
      utm_source: data.UTMSource,
      utm_campaign: data.UTMCampaign,
      utm_medium: data.UTMMedium,
      template_id: template.id,
      web_deep_link: webApp ? webApp.link : "",
      web_path: data.webPath,
      android_is_open_store: data.androidRedirect === PLATFORM.GGPLAY,
      ios_is_open_store: data.iosRedirect === PLATFORM.APPSTORE,

      ios_link_app_not_install: webApp ? webApp.link : "",
      ios_link_app_not_install_path: data.iosLinkAppNotInstallPath,
      android_link_app_not_install: webApp ? webApp.link : "",
      android_link_app_not_install_path: data.androidLinkAppNotInstallPath,

      android_deep_link: template.android_deep_link,
      ios_deep_link: template.ios_deep_link,
    }



    // if (isSocialMedia) {
    payload.title = data.title;
    payload.image_url = data.imageURL;
    payload.description = data.description;

    // console.log(payload)
    // } else {
    //   payload.title = "";
    //   payload.image_url = "";
    //   payload.description = "";
    // }

    try {
      const result = await deeplink.updateDeeplink({
        templateId: templateId,
        shortURL: linkId,
        data: payload,
      })

      setLink({
          ...payload,
          "links": result,
        }
      )

      dispatch(showInfo({ message: "Update link successfully" }));
      getLinkDetail(templateId, linkId)
      // getLinksByTemplateId(templateId)
    } catch (error) {
      console.log(error)
      dispatch(showError({ message: "Update link unsuccessfully" }));
    }  }


  return (
    <div id="update-deeplink">

      <div className="ds-input-text mt-3">
        <a
          className="ds-text-color hover:underline"
          target="_blank" href={`${PATHS.APP}${PATHS.DEEPLINK}`}>
          Deeplink
        </a>
        <FontAwesomeIcon className="text-gray-500 ml-2.5 mr-2.5" icon={faChevronRight} size="xs"/>
        Update link
      </div>

      <div className="ds-text-page-title mt-3">Update link</div>

      <FormProvider {...methods}>
        <div className="gap-4 bg-white mt-5 rounded-lg shadown-md sm:p-3 border border-gray-100">
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="ml-4 mt-3">
              <div className={"gap-4"}>
                <div>
                  <div className="grid grid-cols-5 gap-4">
                    <div className="col-span-2">
                      <label className="pl-1 ds-input-text">Template</label>
                      <TextInput
                        name="templateName"
                        className="ds-disabled-input w-full"
                        disabled={isEditing && !isUndefinedOrEmpty(defaultLinkInfo)}
                        placeHolder={templates.find((t) => t.fieldKey === templateId)?.name}
                      />

                      <div className="mt-5">
                        <label className="ds-input-text">Deeplink name</label>
                        <TextInput
                          name="deepLinkName"
                          className="ds-input w-full"
                          // defaultValue={defaultLinkInfo.name}
                          // disabled={isEditing && !isUndefinedOrEmpty(defaultLinkInfo)}
                          defaultValue={isEditing && !isUndefinedOrEmpty(defaultLinkInfo) ? defaultLinkInfo.name : ""}
                          rules={
                            {
                              required: t('Name is required'),
                            }
                          }
                        />
                      </div>


                    </div>
                    <div></div>
                    <div>
                      <div className="">
                        {defaultLinkInfo.qrcode &&
                          <img className="" src={defaultLinkInfo.qrcode} alt="Base64 Image"/>
                        }
                      </div>
                    </div>
                  </div>
                  <div className={"mt-3 grid grid-cols-5 gap-4"}>
                    <div className="col-span-2">
                      <label className="ds-input-text">Short URL</label>
                      <div
                        className="ds-input-box ds-disabled-input w-full"
                      >
                        {
                          isEditing && !isUndefinedOrEmpty(defaultLinkInfo) && <div
                            className={"mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"}
                          >
                            <Link to={defaultLinkInfo.full_url_short} className={"hover:bg-blue-200"}>
                              {defaultLinkInfo.full_url_short}
                            </Link>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr className="h-px mt-8 bg-gray-200 border-0 mr-4"/>

              <h3 className="ds-text-form-title-2 mt-7">
                {t('Attribution')}
              </h3>

              <div className={"grid grid-cols-5 gap-4 mt-5"}>
                <div className={"col-span-2"}>
                  <label className="ds-input-text">UTM Source</label>
                  <TextInput
                    name="UTMSource"
                    className="ds-input ds-input-text w-full"
                    // disabled={isEditing && !isUndefinedOrEmpty(defaultLinkInfo)}
                    defaultValue={isEditing && !isUndefinedOrEmpty(defaultLinkInfo) ? defaultLinkInfo.utm_source : "google"}
                  />
                </div>
              </div>

              <div className={"grid grid-cols-5 gap-4 mt-5"}>
                <div className={"col-span-2"}>
                  <label className="ds-input-text">UTM Medium</label>
                  <TextInput
                    name="UTMMedium"
                    className="ds-input ds-input-text w-full"
                    // disabled={isEditing && !isUndefinedOrEmpty(defaultLinkInfo)}
                    defaultValue={isEditing && !isUndefinedOrEmpty(defaultLinkInfo) ? defaultLinkInfo.utm_medium : "cpc"}
                  />
                </div>
              </div>

              <div className={"grid grid-cols-5 gap-4 mt-5"}>
                <div className={"col-span-2"}>
                  <label className="ds-input-text">UTM Campaign</label>
                  <TextInput
                    name="UTMCampaign"
                    className="ds-input ds-input-text w-full"
                    rules={
                      {
                        required: t('Campaign is required'),
                      }
                    }
                    // disabled={isEditing && !isUndefinedOrEmpty(defaultLinkInfo)}
                    defaultValue={isEditing && !isUndefinedOrEmpty(defaultLinkInfo) ? defaultLinkInfo.utm_campaign : "sale 11.11"}
                  />
                </div>
              </div>

              <hr className="h-px mt-8 bg-gray-200 border-0 mr-4"/>

              <div className={""}>
                <h3 className="ds-text-form-title-2 mt-7">
                  Social Media Preview
                </h3>
                {/*<div className={"flex items-center"}>*/}
                {/*  <div className="ds-input-text mt-5">Create your social media preview</div>*/}
                {/*  <ToggleCheckbox name="isSocialMedia" disabled={true} className="mt-5 ms-4"/>*/}
                {/*</div>*/}

                <div>
                  {/*{isSocialMedia || !isUndefinedOrEmpty(defaultLinkInfo.title)*/}
                  {/*  &&*/}
                  <>
                    <div className="grid grid-cols-5 gap-4 mt-4">
                      <div className="col-span-2">
                        <span className="ds-input-text">Title</span>
                        <TextInput
                          name="title"
                          className="ds-input ds-input-text w-full"
                          rules={
                            {
                              required: t('Title is required'),
                            }
                          }
                          placeholder={"DataSenses - Growth Analytics Provider"}
                          defaultValue={isEditing && !isUndefinedOrEmpty(defaultLinkInfo) ? defaultLinkInfo.title : ""}

                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-5 gap-4 mt-5">
                      <div className="col-span-2">
                        <span className="ds-input-text">Description</span>
                        <TextAreaInput
                          name="description"
                          id="description"
                          className="w-full ds-input h-32"
                          placeholder={"DataSenses is a Growth Analytics Provider that helps you to focus on your growth, not on mess data."}
                          defaultValue={isEditing && !isUndefinedOrEmpty(defaultLinkInfo) ? defaultLinkInfo.description : ""}
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-5 gap-4 mt-5">
                      <div className="col-span-2">
                        <span className="ds-input-text">Image URL</span>
                        <TextInput
                          name="imageURL"
                          className="ds-input ds-input-text w-full"
                          rules={
                            {}
                          }
                          placeholder={"https://example.com/image.jpg"}
                          defaultValue={isEditing && !isUndefinedOrEmpty(defaultLinkInfo) ? defaultLinkInfo.image_url : ""}

                        />
                      </div>
                    </div>


                    {/* <div className={"space-y-0.5 "}> */}
                    {/* <label className="pl-1">Preview</label> */}
                    {/* <div */}
                    {/* className="w-full ds-box-no-padding h-60 grid grid-rows-5 divide-y" /* Set the height to match the textarea */}
                    {/* > */}
                    {/* <div className="divide-y row-span-3 flex items-center justify-center overflow-hidden"> */}
                    {/* <img */}
                    {/* src={isEditing && !isUndefinedOrEmpty(defaultLinkInfo) ? defaultLinkInfo.image_url : ""} */}
                    {/* alt="Preview" */}
                    {/* className="object-cover w-full h-full" */}
                    {/* /> */}
                    {/* </div> */}
                    {/* <div className={"row-span-2 bg-gray-100"}> */}
                    {/* <p className="text-gray-500 text-xs pt-2 pl-2"> */}
                    {/* {(() => { */}
                    {/* const url = isEditing && !isUndefinedOrEmpty(defaultLinkInfo) ? defaultLinkInfo.full_url_short : ""; */}
                    {/* try { */}
                    {/* const conURL = new URL(url); */}
                    {/* return conURL.hostname; */}
                    {/* } catch (e) { */}
                    {/* console.log(e) */}
                    {/* return ""; */}
                    {/* } */}
                    {/* })()} */}
                    {/* </p> */}
                    {/* <h1 */}
                    {/* className={"text-gray-900 text-lg pl-2 font-medium"}>{isEditing && !isUndefinedOrEmpty(defaultLinkInfo) ? defaultLinkInfo.title : ""}</h1> */}
                    {/* <div className={"line-clamp-1 w-[400px]"}> */}
                    {/* <p */}
                    {/* className={"text-ellipsis overflow-hidden  text-gray-500 text-sm pl-2 "}>{isEditing && !isUndefinedOrEmpty(defaultLinkInfo) ? defaultLinkInfo.description : ""}</p> */}
                    {/* </div> */}

                    {/* </div> */}
                    {/* </div> */}
                    {/* </div> */}
                  </>


                </div>

                <hr className="h-px mt-8 bg-gray-200 border-0 mr-4"/>

                <div className={""}>
                  <h3 className="ds-text-form-title-2 mt-7">
                    Redirection
                  </h3>

                  <div className={"space-y-6"}>
                    {
                      template.app_ios_id !== "" || template.app_android_id !== ""
                        ?
                        <>
                          <div className="mt-5 mr-4">
                            <span className="text-sm font-semibold text-gray-900">When app is not installed</span>
                            <table className="bg-white w-full mr-100 border-collapse border border-gray-200 rounded-lg">
                              <tbody className="text-gray-600 ds-input-text divide-y divide-gray-200">
                              <tr>
                                <td className="px-6 py-4 w-1/6 whitespace-nowrap text-center border border-gray-200 font-medium">Android</td>
                                <td className="px-7 py-6">
                                  <div>
                                    <span className="ds-input-text">Redirect to</span>
                                    {
                                      androidRedirect === PLATFORM.MWEB ?
                                        <div>
                                          <div className="grid grid-cols-3 mb-2 gap-3">
                                            <div>
                                              <TailwindCombobox
                                                name="androidRedirect"
                                                items={androidRedirectOptions}
                                              />
                                            </div>
                                            <div>
                                              <TextInput
                                                name="androidMwebUrl"
                                                className="ds-disabled-input w-full hover:border-gray-300 focus:border-gray-300"
                                                disabled={isEditDisabled}
                                                placeholder={webApp ? webApp.link : ""}
                                              />
                                            </div>

                                            <div className="flex items-center justify-stretch">
                                              <div>/</div>
                                              <div className={"flex-1 ml-3"}>
                                                <TextInput
                                                  name="androidLinkAppNotInstallPath"
                                                  className="ds-input-text w-full"
                                                  defaultValue={isUndefinedOrEmpty(defaultLinkInfo.android_link_app_not_install_path) ? "" : defaultLinkInfo.android_link_app_not_install_path}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        :
                                        <div className="grid grid-cols-3 mb-2 gap-1">
                                          <TailwindCombobox
                                            name="androidRedirect"
                                            items={androidRedirectOptions}
                                          />
                                        </div>
                                    }
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className="px-6 py-4 w-1/6 whitespace-nowrap text-center border border-gray-200 font-medium">iOS</td>
                                <td className="px-7 py-6">
                                  <div>
                                    <span className="ds-input-text">Redirect to</span>
                                    {
                                      iosRedirect === PLATFORM.MWEB ?
                                        <div>
                                          <div className="grid grid-cols-3 mb-2 gap-3">
                                            <div>
                                              <TailwindCombobox
                                                name="iosRedirect"
                                                items={iOSRedirectOptions}
                                              />
                                            </div>
                                            <div>
                                              <TextInput
                                                name="iosdMwebUrl"
                                                className="ds-disabled-input w-full hover:border-gray-300 focus:border-gray-300"
                                                disabled={isEditDisabled}
                                                placeholder={webApp ? webApp.link : ""}
                                              />
                                            </div>
                                            <div className="flex items-center justify-stretch">
                                              <div>/</div>
                                              <div className={"flex-1 ml-3"}>
                                                <TextInput
                                                  name="iosLinkAppNotInstallPath"
                                                  className="ds-input-text w-full"
                                                  defaultValue={isUndefinedOrEmpty(defaultLinkInfo.ios_link_app_not_install_path) ? "" : defaultLinkInfo.ios_link_app_not_install_path}

                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        :
                                        <div className="grid grid-cols-3 mb-2 gap-1">
                                          <TailwindCombobox
                                            name="iosRedirect"
                                            items={iOSRedirectOptions}
                                          />
                                        </div>
                                    }
                                  </div>
                                </td>
                              </tr>
                              </tbody>
                            </table>

                          </div>
                          <div className="mr-4">
                            <span className="text-sm font-semibold text-gray-900">When app is installed</span>
                            <div className={""}>
                              <table className="bg-white w-full border-collapse border border-gray-200 rounded-lg">
                                <tbody className="text-gray-600 ds-input-text divide-y divide-gray-200">
                                <tr>
                                  <td className="px-6 py-4 w-1/6 whitespace-nowrap text-center border border-gray-200 font-medium">Android</td>
                                  <td className="px-7 py-5">
                                    <div>
                                      <div className="grid grid-cols-3 mb-2 gap-1 mt-2">
                                        <div className="col-span-2">
                                          <TextInput
                                            name="androidDeeplink"
                                            className="ds-disabled-input w-full hover:border-gray-300 focus:border-gray-300"
                                            disabled={isEditDisabled}
                                            placeholder={template.android_deep_link !== "" ? template.android_deep_link : "Android deeplink"}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="px-6 py-4 w-1/6 whitespace-nowrap text-center border border-gray-200 font-medium">iOS</td>
                                  <td className="px-7 py-5">
                                    <div>
                                      <div className="grid grid-cols-3 mb-2 gap-1 mt-2">
                                        <div className="col-span-2">
                                          <TextInput
                                            name="iosDeeplink"
                                            className="ds-disabled-input w-full hover:border-gray-300 focus:border-gray-300"
                                            disabled={isEditDisabled}
                                            placeholder={template.ios_deep_link !== "" ? template.ios_deep_link : "iOS deeplink"}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                </tbody>
                              </table>

                              <div>
                              </div>
                            </div>
                          </div>
                        </>
                        :
                        <>
                        </>
                    }
                    <div>
                      <span className="text-sm font-semibold text-gray-900">When link is clicked on desktop web page</span>
                      <div className={"mr-4"}>
                        <table className="bg-white w-full border-collapse border border-gray-200 rounded-lg">
                          <tbody className="text-gray-600 ds-input-text divide-y divide-gray-200">
                          <tr>
                            <td className="px-6 py-4 w-1/6 whitespace-nowrap text-center border border-gray-200 font-medium">Desktop</td>
                            <td className="px-7 py-6">
                              <div>
                                <span className="ds-input-text">Redirect to</span>
                                <div>
                                  <div className="grid grid-cols-3 mb-2 gap-3">
                                    <div>
                                      <TailwindCombobox
                                        name="webRedirect"
                                        items={webRedirectOptions}
                                      />
                                    </div>
                                    <div>
                                      <TextInput
                                        name="webDeepLink"
                                        className="ds-disabled-input w-full hover:border-gray-300 focus:border-gray-300"
                                        disabled={isEditDisabled}
                                        placeholder={webApp ? webApp.link : ""}
                                      />
                                    </div>
                                    <div className="flex items-center justify-stretch">
                                      <div>/</div>
                                      <div className={"flex-1 ml-3"}>
                                        <TextInput
                                          name="webPath"
                                          className="ds-input-text w-full"
                                          defaultValue={isUndefinedOrEmpty(defaultLinkInfo.web_path) ? "" : defaultLinkInfo.web_path}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr className="h-px mt-8 bg-gray-200 border-0 mr-4"/>

              <div className="flex items-center justify-end mr-4 mt-7 mb-4">
                <button
                  type="button"
                  className="ds-button-cancel mr-2"
                  onClick={discardChanges}
                >
                  Discard changes
                </button>

                <button
                  type="submit"
                  className="ds-button"
                >
                  Update
                </button>
              </div>
            </div>
          </form>
        </div>
      </FormProvider>

    </div>
  )
}


