import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClose,
  faEquals,
  faFilter,
  faGreaterThan,
  faGreaterThanEqual,
  faLessThan,
  faLessThanEqual,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';
import ToggleBox from 'components/ToggleBox';
import { FILTER_TYPES } from 'consts';

import './index.scss';

const typeActions = [
  {
    icon: faGreaterThan,
    label: 'GREATER_THAN',
    value: FILTER_TYPES.GREATER_THAN,
  },
  {
    icon: faGreaterThanEqual,
    label: 'GREATER_THAN_OR_EQUAL_TO',
    value: FILTER_TYPES.GREATER_THAN_OR_EQUAL_TO,
  },
  {
    icon: faLessThan,
    label: 'LESS_THAN',
    value: FILTER_TYPES.LESS_THAN,
  },
  {
    icon: faLessThanEqual,
    label: 'LESS_THAN_OR_EQUAL_TO',
    value: FILTER_TYPES.LESS_THAN_OR_EQUAL_TO,
  },
  {
    icon: faEquals,
    label: 'IS_EQUAL_TO',
    value: FILTER_TYPES.IS_EQUAL_TO,
  },
];


/**
 * A component for inputting values with various types of conditions.
 *
 * @param {Object} props - The component props.
 * @param {string} props.icon - The icon to display.
 * @param {string} props.name - The name of the input field.
 * @param {string} props.value - The currently inputted value.
 * @param {string} props.type - The default type of condition.
 * @param {Function} [props.onChange=() => {}] - The function called when the input value or condition type changes.
 * @param {Function} [props.onDelete=() => {}] - The function called when the delete button is clicked.
 * @param {string} [props.className] - Additional CSS class names for styling.
 * @returns {JSX.Element} - The rendered component.
 */
export default function ValueInput({
  icon,
  name,
  value,
  type,
  onChange = () => { },
  onDelete = () => { },
  className,
}) {
  const { t } = useTranslation();
  const [defaultType, setDefaultType] = useState(type);
  const [valueInput, setValueInput] = useState("");

  const wrapper = useRef(null);

  const typeIcon = useMemo(() => {
    return typeActions.find(item => item.value === type).icon;
  }, [type]);

  const defaultTypeLabel = useMemo(() => {
    return typeActions.find(item => item.value === defaultType).label;
  }, [defaultType]);

  const chooseType = (value) => {
    setDefaultType(value);
  };

  const onClose = () => {
    if (value.length === 0) {
      onDelete();
    }
  };

  const onTypeClose = () => {
  };

  const apply = () => {
    onChange(valueInput, defaultType);
    wrapper.current.toggle();
  };

  const cancel = () => {
    if (value.length === 0) {
      onDelete();
    } else {
      wrapper.current.toggle();
      setDefaultType(type);
    }
  };

  useEffect(() => {
    setValueInput(value);
  }, [value]);

  useEffect(() => {
    setDefaultType(type);
  }, [type]);

  return (
    <ToggleBox className="value-input-wrapper" firstOpen={true} ref={wrapper} position='center' onClose={onClose}>
      <ToggleBox.Button>
        <div className={`rounded-full flex justify-between items-center border bg-blue-100 px-2 py-0.5 cursor-pointer me-3 ${className}`}>
          <div className='flex flex-row items-center'>
            <FontAwesomeIcon className='h-3 w-3 me-1.5' icon={icon} />
            <div className='select-none mb-0.5'>{name}</div>
            <FontAwesomeIcon className='h-3 w-3 px-1' icon={typeIcon} />
            <div className='select-none mb-0.5'>{value}</div>
          </div>

          <FontAwesomeIcon onClick={onDelete} className='h-4 w-4 ms-3 hover:text-red-500' icon={faClose} />
        </div>
      </ToggleBox.Button>

      <ToggleBox.Content>
        <div className='value-input relative py-2'>
          <div className='flex justify-between items-center px-3'>
            <div>
              <div className='mb-1'>{t('CONDITION')}</div>
              <ToggleBox autoClose position='start' onClose={onTypeClose}>
                <ToggleBox.Button>
                  <div className='flex flex-row items-center px-3 pe-1 rounded border border-gray-300 w-max cursor-pointer select-none me-2'>
                    <span className='me-2'>{t(defaultTypeLabel)}</span>
                    <FontAwesomeIcon className='h-3 w-3 mb-1' icon={faSortDown} />
                  </div>
                </ToggleBox.Button>

                <ToggleBox.Content>
                  <div>
                    {typeActions.map((item, index) => (
                      <div key={index} onClick={() => chooseType(item.value)} className='flex flex-row items-center cursor-pointer select-none hover:bg-blue-100 px-3 py-0.5'>
                        <FontAwesomeIcon className='h-4 w-4 me-2' icon={item.icon} />
                        {t(item.label)}
                      </div>
                    ))}
                  </div>
                </ToggleBox.Content>
              </ToggleBox>
            </div>

            <div className='ms-3'>
              <div className='mb-1'>{t('VALUE')}</div>
              <input
                value={valueInput}
                onChange={(event) => setValueInput(event.target.value)}
                className='rounded px-3 border border-gray-300 focus:outline-none'
                placeholder={t('VALUE')}
              />
            </div>
          </div>

          <div className='flex flex-row-reverse absolute right-4 bottom-2'>
            <button
              type="button"
              onClick={apply}
              disabled={valueInput === value && defaultType === type}
              className="text-white bg-blue-600 disabled:bg-gray-300 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-1.5 focus:outline-none"
            >
              {t('APPLY')}
            </button>

            <button
              type="button"
              onClick={cancel}
              className="text-blue-500 me-2 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-1.5 focus:outline-none"
            >
              {t('CANCEL')}
            </button>
          </div>
        </div>
      </ToggleBox.Content>
    </ToggleBox>
  );
}
