import {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from 'react-hook-form';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleQuestion} from '@fortawesome/free-regular-svg-icons';
import {PATHS, REPORT_TYPE, CARD_TYPE} from "consts";
import {isArray, isUndefinedOrEmpty} from "utils";
import {buildAppList} from "utils/app";
import $ from 'jquery';

import Tooltip from 'components/Tooltip';
import LoadingSpinner from "components/LoadingSpinner";
import {showError} from "components/FlashMessage/flashMessageSlice";
import DsListBox from "components/DsListBox";
import TailwindComboboxWithoutForm from "components/TailwindCombobox/TailwindComboboxWithoutForm";

import AnalysisService from 'services/analysis';
import SegmentService from "services/segment";
import DashboardService from "services/dashboard";
import MetaDataService from 'services/metadata';

import DateFilterToggleBox from "../components/DateFilterToggleBox";
import FunnelStep from "./FunnelStep";
import FunnelVisualisationBarChart from "./VisualizationBarchart";
import AddToDashboard from "views/dashboard_list/add_to_dashboard";

import './index.scss';

import {proceedParam} from "./processor";
import {SaveToDashboard} from "views/dashboard_list/save_to_dashboard";
import VisualizationFunnel from "views/analytics/components/VisualizationFunnel";
import TailwindCombobox from "components/TailwindCombobox";

const chartTypes = [
  {
    text: 'Bar Chart (Count)',
    value: 'bar_count',
  },
  {
    text: 'Bar Chart (Percentage)',
    value: 'bar_percentage',
  },
  {
    text: 'Funnel Chart (Count)',
    value: 'funnel_count',
  },
  {
    text: 'Funnel Chart (Percentage)',
    value: 'funnel_percentage',
  },
];


export default function Funnel() {
  const dispatch = useDispatch();
  const location = useLocation();
  const {t} = useTranslation();
  const {appId, dashboardId} = useParams();
  const appList = useSelector((state) => state.app.appList);

  const methods = useForm({defaultValues: {}});

  const [isLoading, setIsLoading] = useState(false);
  const [initialRender, setInitialRender] = useState(true);
  const [isSticky, setIsSticky] = useState(false);
  const [chartType, setChartType] = useState('funnel_percentage');

  const [data, setData] = useState([]);
  const [segmentsOptions, setSegmentsOptions] = useState([]);
  const [steps, setSteps] = useState([]);
  const date = methods.watch("date")

  const payload = {
    chartType: isUndefinedOrEmpty(chartType) ? "" : chartType,
    isPercent: isUndefinedOrEmpty(chartType) ? "" : chartType.endsWith('percentage'),
    name: "Step",
    data: !isUndefinedOrEmpty(chartType) && chartType.startsWith('bar') ? [{name: "All Users", data}]
      : !isUndefinedOrEmpty(chartType) && !chartType.startsWith('bar') ? data : [],
    steps: steps,
  }

  const getSegmentList = async () => {
    try {
      const segments = await SegmentService.listSegments({appId});

      setSegmentsOptions([
        {
          name: 'All user',
          fieldKey: 'All user',
        },
        ...segments.map((item) => ({name: item.name, fieldKey: item.id}))
      ]);
    } catch (error) {
      const {message} = error;

      dispatch(showError({message}));
    }
  }

  const getDashboardDetail = async () => {
    const result = await DashboardService.getDashboardDetail({dashboardId});

    const formData = JSON.parse(result.payload);

    methods.setValue("date", formData["date"]);
    methods.setValue("funnelSteps", formData["funnelSteps"]);
    // setType(formData["type"]);
  }

  const onSubmit = async (data) => {
    try {

      setIsLoading(true);
      let fromDate = "";
      let toDate = "";
      if (date !== undefined) {
        fromDate = date[0];
        toDate = date[1];
      }
      setSteps(data.funnelSteps.map((item) => item.map((step) => step.paramEventName)));
      const payload = proceedParam(data);
      const result = await AnalysisService.getFunnel({...payload, app_id: appId,from_date:fromDate,to_date:toDate});

      setData(result);
    } catch (error) {
      const {message} = error;

      dispatch(showError({message}));
    }

    setIsLoading(false);
  };

  const appOptions = useMemo(() => {
    return buildAppList(appList, PATHS.FUNNEL);
  }, [appList]);

  const reset = async () => {
    const result = await MetaDataService.getEventNameList({appId});

    methods.setValue("funnelSteps", [
      [
        {
          paramEventName: result[0],
          paramFilter: [],
        }
      ]
    ]);

    methods.setValue("segment", "All user");
  }

  useEffect(() => {
    getSegmentList();
  }, [appId]);

  useEffect(() => {
    if (dashboardId) {
      getDashboardDetail();
    }
  }, [dashboardId]);

  useEffect(() => {
    if (!initialRender && !dashboardId) {
      reset();
    } else {
      setInitialRender(false);
    }
  }, [location]);

  useEffect(() => {
    function checkSticky() {
      if ($(window).scrollTop() > 30) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    }

    window.addEventListener('scroll', checkSticky);

    () => window.removeEventListener('scroll', checkSticky);
  }, []);

  return (
    <div id="funnel">
      {isSticky && <div className='bg-stick'></div>}

      <div className={`${isSticky && 'sticky'}`}>
        <div className="flex items-center justify-between">
          <div className="flex ds-text-page-title">
            {t('FUNNELS')}
            <Tooltip minWidth="250">
              <Tooltip.Label>
                <FontAwesomeIcon className="text-gray-500 ml-2" icon={faCircleQuestion}/>
              </Tooltip.Label>
              <Tooltip.Content>
                <p>{t('FUNNEL_DESCRIPTION')}</p>
              </Tooltip.Content>
            </Tooltip>
          </div>

          <SaveToDashboard
            dashboardId={dashboardId}
            overviewPayload={methods.watch()}
            reportType={REPORT_TYPE.REPORT_FUNNEL}
          />
        </div>

        <DsListBox listData={appOptions}/>
      </div>

      <div className="grid grid-cols-1 gap-2 mt-4">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div>
              <div className="flex justify-start items-center mt-2 mb-3 gap-1">
                <TailwindCombobox name="segment" items={segmentsOptions}/>
                <DateFilterToggleBox name="date"/>
              </div>
            </div>
            <div>
              <FunnelStep name="funnelSteps"/>
            </div>
            <div className="flex mt-5">
              <button
                type="submit"
                disabled={isLoading}
                className="ds-button me-2"
              >
                {isLoading ?
                  <>
                    <LoadingSpinner className="me-2 mb-1"/>
                    {t('LOADING')}
                  </>
                  :
                  t('VIEW_FUNNEL')
                }
              </button>
            </div>
          </form>
        </FormProvider>
      </div>


      {
        isArray(data) && data.length > 0 &&
        <div className={`ds-block mt-4`}>
          <div className='flex items-center justify-end px-4 mt-3'>
            <div className="grow items-center flex">
              <span className='font-semibold ds-text-page-title'>{t('Funnel Analysis')}</span>
            </div>
            <AddToDashboard
              className="mt-4"
              cardType={CARD_TYPE.CART_TYPE_FUNNEL}
              cardSubType=""
              payload={payload}
            />
          </div>

          <div className='px-3 py-5'>
            <div className="grid grid-cols-4 justify-start">
              <TailwindComboboxWithoutForm value={chartType} items={chartTypes} onChange={(value) => setChartType(value)}/>
            </div>
            {!isUndefinedOrEmpty(chartType) && (chartType.startsWith('bar') ?
              <FunnelVisualisationBarChart isPercent={chartType.endsWith('percentage')} name="Step" data={[
                {name: "All Users", data},
              ]}/>              :
              <VisualizationFunnel isPercent={chartType.endsWith('percentage')} name="Step" data={data} steps={steps}/>)
            }
          </div>
        </div>
      }


      {/*{*/}
      {/*  isArray(data) && data.length > 0 &&*/}
      {/*  <div className={`ds-block mt-4`}>*/}
      {/*    <div className='flex justify-start items-center px-2 mt-3'>*/}
      {/*      <span className='font-semibold ds-text-page-title'>{t('Funnel Analysis')}</span>*/}
      {/*    </div>*/}

      {/*    <div className='px-3 py-5'>*/}
      {/*      <div className="grid grid-cols-4 justify-start">*/}
      {/*        <TailwindComboboxWithoutForm value={chartType} items={chartTypes} onChange={(value) => setChartType(value)}/>*/}
      {/*      </div>*/}
      {/*      {!isUndefinedOrEmpty(chartType) && (chartType.startsWith('bar') ?*/}
      {/*        <FunnelVisualisationBarChart isPercent={chartType.endsWith('percentage')} name="Step" data={[*/}
      {/*          {name: "segment1", data},*/}
      {/*          {name: "segment2", data},*/}
      {/*          {name: "segment1", data},*/}
      {/*          {name: "segment2", data},*/}

      {/*        ]}/>*/}
      {/*        :*/}
      {/*        <VisualizationFunnel isPercent={chartType.endsWith('percentage')} name="Step" data={data} steps={steps}/>)*/}
      {/*      }*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*}*/}


    </div>
  );
};
