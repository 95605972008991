import React from "react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {isUndefinedOrEmpty} from "utils";

export default function TiktokShopPartnerConnectionList({connections, action, classNames}) {
  const {t} = useTranslation();
  const methods = useForm();

  return (
    <>
      <div className={`${classNames}`}>
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
          <tr>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              {t('Seller Name')}
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              {t('Shop Ids')}
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              {t('Created By')}
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              {t('Status')}
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              {t('Created At')}
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            </th>
          </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
          {connections && connections.map((connection, index) => (
            <tr key={index}>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{connection.sellerName}</td>
              {!isUndefinedOrEmpty(connection.shopIds) ?
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {
                  connection.shopIds.map((event, _) => (
                    <span
                      className="inline-flex items-center rounded-md bg-gray-100 px-1.5 py-0.5 text-xs font-medium text-gray-600 ml-1">
                            {event}
                          </span>
                  ))
                }
                </td>
                :
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">-</td>
              }
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{connection.createdBy}</td>
              <td
                className={`whitespace - nowrap px-3 py-4 text-sm  ${connection.status ? 'text-green-600' : 'text-rose-600'}`}>{connection.status ? 'Linked' : 'Unlinked'}</td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{connection.createdAt}</td>
              <td
                className={`whitespace - nowrap px-3 py-4 text-sm`}>
                <button disabled={`${(!!connection.status)}`} className={`ds-button-disable`}
                        onClick={() => action()}>Reconnect
                </button>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
