import { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react'
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

/**
 * Renders a group of navigation items with a collapsible feature.
 * @param {Object} props - The props object.
 * @param {Object} props.icon - The icon to display for the group.
 * @param {string} props.name - The name of the group.
 * @param {Array} props.group - The array of items within the group.
 * @returns {JSX.Element} A React component representing the ItemGroup.
 */
export default function ItemGroup({
  icon,
  name,
  group = []
}) {
  const location = useLocation();
  const [isShowing, setIsShowing] = useState(false);

  useEffect(() => {
    const result = group.some((item) => location.pathname.includes(item.href));
    setIsShowing(result);
  }, [location]);

  return (
    <>
      <div onClick={() => setIsShowing(!isShowing)} className="item-group group cursor-pointer flex justify-between items-center rounded-md p-2 text-sm leading-6 font-semibold">
        <div className="flex gap-x-3  group items-center rounded-md text-sm font-semibold leading-6">
          <div className="h-4 w-4 mb-1 flex center justify-center">
            {icon}
          </div>
          <span>{name}</span>
          <span>{isShowing}</span>
        </div>

        <FontAwesomeIcon className={`transition-transform transform ${isShowing ? 'rotate-180' : ''}`} icon={faAngleDown} />
      </div>
      <Transition
        show={isShowing}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 h-0"
        enterTo="opacity-100 h-full"
        leave="transition ease-in duration-75"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className='ps-3 pt-1'>
          {group.map((item, index) => (
            <Link
              key={index}
              to={item.href}
              className={classNames(
                location.pathname === item.href
                  ? 'active'
                  : '',
                'group flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6'
              )}
            >
              <div className="h-4 w-4 flex center justify-center">
                {item.icon}
              </div>
              <span>{item.name}</span>
            </Link>
            ))}
        </div>
      </Transition>
    </>
  )
}
