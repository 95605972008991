import {PLATFORM} from "consts";


export const iOSRedirectOptions = [
  {
    name: 'App Store',
    fieldKey: PLATFORM.APPSTORE,
  },
  {
    name: 'Open mobile web',
    fieldKey: PLATFORM.MWEB,
  },
]

export const androidRedirectOptions = [
  {
    name: 'Google Play',
    fieldKey: PLATFORM.GGPLAY,
  },
  {
    name: 'Open mobile web',
    fieldKey: PLATFORM.MWEB,
  },
]

export const webRedirectOptions = [
  {
    name: "Open web",
    fieldKey: PLATFORM.WEB,
  },
]