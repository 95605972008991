import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {isArray, isUndefinedOrEmpty} from "utils";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark} from '@fortawesome/free-solid-svg-icons';
import {CONTAINS} from "consts/chartConfig";

import FilterInput from 'components/SegmentFilterInput/input';
import FILTER_TYPES from 'components/SegmentFilterInput/types';
import {showError} from "components/FlashMessage/flashMessageSlice";
import TailwindComboboxWithoutForm from "components/TailwindCombobox/TailwindComboboxWithoutForm";

import MetaDataService from 'services/metadata';


const typeOptions = [
  {
    text: 'Event property',
    value: FILTER_TYPES.EVENT_PROPERTY,
  },
  {
    text: 'Time of the day',
    value: FILTER_TYPES.TIME_OF_THE_DAY,
  },
  {
    text: 'Day of the month',
    value: FILTER_TYPES.DAY_OF_THE_MONTH,
  },
];


export default function ({
                           value = {
                             paramEventName: '',
                             paramFilter: [],
                           },
                           onChange = () => {
                           },
                           onDelete = () => {
                           },
                           options = [],
                           showDelete = false,
                           className,
                         }) {
  const {t} = useTranslation();

  const dispatch = useDispatch();
  const {appId} = useParams();

  const [eventPropertyOptions, setEventPropertyOptions] = useState([]);

  const getEventPropertyOptions = async (fieldKey) => {
    try {
      if (!fieldKey) {
        return;
      }
      const result = await MetaDataService.getFieldValues({appId, fieldKey});

      setEventPropertyOptions(result.map((value) => ({text: value, value})));
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
  };

  const handleSelectEventNameChange = (fieldValue) => {
    onChange({
      ...value,
      paramEventName: fieldValue,
    });
  };

  const addFilter = () => {
    const newValue = {...value};

    newValue.paramFilter.push({
      type: FILTER_TYPES.EVENT_PROPERTY,
      value: {
        property: "",
        operator: CONTAINS,
        value: "",
      },
    });

    onChange(newValue);
  };

  const deleteFilter = (index) => {
    const newValue = {...value};

    newValue.paramFilter.splice(index, 1);

    onChange(newValue);
  };

  const handleFilterChange = (data, index) => {
    const newValue = {...value};

    newValue.paramFilter[index] = data;

    onChange(newValue);
  };

  useEffect(() => {
    if (isArray(options) && options.length > 0) {
      onChange({
        paramEventName: options[0].value,
        paramFilter: value.paramFilter,
      });
    }
  }, [options]);

  useEffect(() => {
    getEventPropertyOptions(value.paramEventName);
  }, [value.paramEventName, appId]);

  return (
    <div className={className}>
      <div className='flex justify-between items-center'>
        {/*<select*/}
        {/*  className="select bg-white rounded border border-gray-300 py-1.5 px-3"*/}
        {/*  value={value.paramEventName}*/}
        {/*  onChange={handleSelectEventNameChange}*/}
        {/*>*/}
        {/*  {Array.isArray(options) && options.map(item => (*/}
        {/*    <option value={item} key={item}>{item}</option>*/}
        {/*  ))}*/}
        {/*</select>*/}

        <TailwindComboboxWithoutForm
          items={options}
          value={value.paramEventName}
          onChange={handleSelectEventNameChange}
          className={"ds-violet-border"}
        />


        {showDelete && <FontAwesomeIcon onClick={onDelete} className='text-red-500 cursor-pointer' icon={faXmark}/>}
      </div>

      <div className='mt-2'>
        <span onClick={addFilter} className='ds-text-color cursor-pointer select-none text-sm ds-text-color'>
          + {t("FILTER_BY")}
        </span>
      </div>

      {!isUndefinedOrEmpty(value.paramFilter) && value.paramFilter.map((item, index) => (
        <FilterInput
          key={index}
          value={item}
          onChange={(value) => handleFilterChange(value, index)}
          onDelete={() => deleteFilter(index)}
          eventPropertyOptions={eventPropertyOptions}
          typeOptions={typeOptions}
          className="mt-2 ds-violet-border"
        />
      ))}
    </div>
  );
};
