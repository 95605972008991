import {FormProvider, useForm} from "react-hook-form";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useMemo, useState} from "react";
import {PATHS, PLATFORM} from "consts";
import {buildAppList} from "utils/app";
import DsListBox from "components/DsListBox";
import {tabLink} from "views/skadnetwork/skan_studio/tabs";
import PostbackWindowOne from "views/skadnetwork/skan_studio/postback_window_one";
import {showError, showInfo} from "components/FlashMessage/flashMessageSlice";
import SKANConversionService from "services/skan_studio";
import PostbackWindowTwo from "views/skadnetwork/skan_studio/postback_window_two";
import tabs from "./tabs";
import PostbackWindowThree from "views/skadnetwork/skan_studio/postback_window_three";
import DeleteSKAN from "views/skadnetwork/skan_studio/delete_skan";
import {isUndefinedOrEmpty} from "utils";
import {processSkanConversionValueData} from "views/skadnetwork/processor";
import DiscardChange from "views/skadnetwork/skan_studio/discard_change";
import {ArrowTrendingUpIcon, ChevronDownIcon, ChevronUpIcon, WrenchIcon} from "@heroicons/react/24/solid";
import DsListBoxUsingWindowLocation from "components/DsListBoxUsingWindowLocation";
import MetadataService from "services/metadata";
import {defaultEventsList} from "views/skadnetwork/skan_studio/const";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function SkadnetworkStudio() {
  const {appId} = useParams();
  const {t} = useTranslation();
  const methods = useForm();
  const [hashValue, setHashValue] = useState(window.location.hash || tabLink.POSTBACK_WINDOW_ONE);
  const dispatch = useDispatch();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isDiscardOpen, setIsDiscardOpen] = useState(false);
  const [overviewOpen, setOverviewOpen] = useState(false);
  const [isSKANCreated, setIsSKANCreated] = useState(false);
  const [eventList, setEventList] = useState([]);

  const getSKANConversion = async () => {
    try {
      const response = await SKANConversionService.getSKANConversion(appId);
      if (!isUndefinedOrEmpty(response)) {
        setIsSKANCreated(true);
      }
      methods.reset(response)
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
  }

  const getEventsList = async () => {
    try {
      const events = await MetadataService.getEventNameList({appId});
      const eventNames = [];

      events.map((item) => {
        eventNames.push({fieldKey: item, name: item})
      });

      defaultEventsList.map((item) => {
        if (!eventNames.find(({fieldKey}) => fieldKey === item.fieldKey)) {
          eventNames.push(item);
        }
      });

      setEventList(eventNames);
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
  }

  useEffect(() => {
    const handleHashChange = () => {
      setHashValue(window.location.hash);
    };

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  const createSKANConversionService = async (data) => {
    await SKANConversionService.createSKANConversion(appId, data);
    dispatch(showInfo({message: "Save SKAN success"}));
  }

  const updateSKANConversionService = async (data) => {
    await SKANConversionService.updateSKANConversion(appId, data);
    dispatch(showInfo({message: "Update SKAN success"}));
  }

  const onSubmit = async (data) => {
    try {
      const payload = processSkanConversionValueData(data);
      if (isSKANCreated) {
        await updateSKANConversionService(payload);
      } else {
        await createSKANConversionService(payload);
      }
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
  }

  useEffect(() => {
    if (appId) {
      getSKANConversion();
      getEventsList();
    }
  }, [appId]);

  const appList = useSelector(state => state.app.appList.filter(a => a.platform === PLATFORM.IOS));

  const appOptions = useMemo(() => {
    if (!isUndefinedOrEmpty(appList)) {
        if (!appList.find(a => a.appId === appId)) {
          window.location = `${PATHS.APP}/${appList[0].appId}${PATHS.SKADNETWORK_STUDIO}`;
        }
      } else {
        window.location = `${PATHS.APP}/empty${PATHS.SKADNETWORK}`
      }
    return buildAppList(appList, PATHS.SKADNETWORK_STUDIO);
  }, [appList]);

  return (
    <div id="skadnetwork-studio">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>

          <div className='flex justify-between'>
            <div className="ds-text-page-title">{t('SKAN Conversion Studio')}</div>
            <div className="flex">
            </div>
          </div>

          {!isUndefinedOrEmpty(appOptions) && <DsListBoxUsingWindowLocation listData={appOptions}/>}

          <div className="ds-block mt-3 flex justify-between hover:bg-gray-100 p-3 cursor-pointer"
                onClick={() => setOverviewOpen(!overviewOpen)}>
            <div>
              <div className="font-bold me-2">
                Overview
              </div>
              <span className="text-sm font-normal">
                To measure post-install user interactions and understand your return on ad spend, set up a SKAdNetwork conversion value schema.
                </span>
            </div>
            <div className="flex items-center">
              {overviewOpen ? <ChevronUpIcon className="h-4 w-4 text-gray-500"/>
              : <ChevronDownIcon className="h-4 w-4 text-gray-500"/>
              }
            </div>
          </div>

          {overviewOpen && <div className="ds-block mt-3">
            <div className="ds-input-text flex justify-between">
              <WrenchIcon className="h-4 w-4 text-gray-500 me-2"/>
              <span>SKAdNetwork is Apple's privacy-preserving install attribution system that helps advertisers measure the success of ad campaigns while maintaining user privacy. A conversion value schema is a way you can identify and assign value to the in-app events that matter most.
              </span>
            </div>
            <div className="ds-input-text flex justify-start mt-2">
              <ArrowTrendingUpIcon className="h-4 w-4 text-gray-500 me-2"/>
              <span> To improve the measurement of your ad campaigns, define values using only events marked as key events.
              </span>
            </div>
            </div>}

          <div className="ds-block mt-3">
            <div className="font-bold me-2">
              Set schema scope
            </div>
            <span className="text-sm font-normal">
              Apple introduced three postback windows with SKAdNetwork v4.0 that can be configured independently. Choose the time windows for which you'd like to configure schemas. Postback window 1 (0 to 2 days) is required. If a postback window does not have a definition, the preceding window's definition will be used.
            </span>
            <div className="mt-2">
              <span className="text-sm font-bold">Postback Window 1 (0 to 2 days)</span>
            </div>
            <div className="mt-2">
              <span className="text-sm font-bold">Postback Window 2 (3 to 7 days)</span>
            </div>
            <div className="mt-2">
              <span className="text-sm font-bold">Postback Window 3 (8 to 30 days)</span>
            </div>
          </div>

          <div className='mt-5'>
            <nav className="-mb-px flex space-x-8">
              {tabs.map((tab) => (
                <a
                  id={tab.name}
                  key={tab.name}
                  href={tab.href}
                  className={classNames(
                    hashValue === tab.href
                      ? "ds-tab-color"
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'whitespace-nowrap select-none cursor-pointer border-b-2 px-1 pb-2 text-sm font-medium'
                  )}
                  aria-current={tab.current ? 'page' : undefined}
                >
                  {t(tab.name)}
                </a>
              ))}
            </nav>
          </div>

          <div className="py-5 flex-auto">
            <div className="tab-content tab-space">
              {hashValue === tabLink.POSTBACK_WINDOW_ONE && <PostbackWindowOne
                eventList={eventList}
              />}
              {hashValue === tabLink.POSTBACK_WINDOW_TWO && <PostbackWindowTwo
                eventList={eventList}
              />}
              {hashValue === tabLink.POSTBACK_WINDOW_THREE && <PostbackWindowThree
                eventList={eventList}
              />}
            </div>
          </div>

          <div className="flex justify-end mt-5">
            <button className="ds-button-2 mr-2" type="button"
                    onClick={() => setIsDiscardOpen(true)}>Discard Change
            </button>
            <button className="ds-button ds-button-primary mr-2" type="submit">Save SKAN</button>
            <button className={"ds-button"} type={"button"}
                    onClick={() => setIsDeleteOpen(true)}
            >
              Delete SKAN
            </button>
          </div>
        </form>
      </FormProvider>

      <DeleteSKAN
        appId={appId}
        isOpen={isDeleteOpen}
        closeModal={() => setIsDeleteOpen(false)}
        reset={getSKANConversion}
      />
      <DiscardChange
        appId={appId}
        isOpen={isDiscardOpen}
        closeModal={() => setIsDiscardOpen(false)}
      />
    </div>
  );
}
