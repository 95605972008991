import {useEffect, useState} from "react";
import RecommendationService from "services/recommendation";
import {showError} from "components/FlashMessage/flashMessageSlice";
import LoadingSpinner from "components/LoadingSpinner";
import {ArrowDownIcon, ArrowUpIcon} from '@heroicons/react/20/solid'
import {useTranslation} from "react-i18next";
import React from "react";
import VisualisationEmpty from "views/visualisation/visualisation_empty";
import {DsIconAnalysis, DsIconLoading} from "components/DsIcon";

export default function Overview({
                                   globalFilter = [],
                                   prevGlobalFilter = [],

                                 }) {
  const [data, setData] = useState([]);
  const [prevData, setPrevData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const {t} = useTranslation()
  const getData = async () => {
    try {
      setIsLoading(true)
      if (globalFilter.length > 0) {

        const request = {
          filters: globalFilter,
          query_params: ["utm_campaign",
            "SUM(ad_app_installs) as ad_app_installs",
            "SUM(ad_click) as ad_click", "SUM(ad_cost) as ad_cost",
            "SUM(ad_impression) as ad_impression"],
        };
        const result = await RecommendationService.getOverviewInsight(request);
        setData(result);
      }
      setIsLoading(false)
      if (prevGlobalFilter.length > 0) {
        const request = {
          filters: prevGlobalFilter,
          query_params: ["utm_campaign",
            "SUM(ad_app_installs) as ad_app_installs",
            "SUM(ad_click) as ad_click", "SUM(ad_cost) as ad_cost",
            "SUM(ad_impression) as ad_impression"],
        };
        const result = await RecommendationService.getOverviewInsight(request);
        setPrevData(result);
      }


    } catch (error) {
      showError({message: error.message});
    }

  };

  useEffect(() => {
    getData();
  }, [globalFilter, prevGlobalFilter]);

  const [sums, setSums] = useState({});
  const [prevSums, setPrevSums] = useState({});

  // Calculate sum of each field when component mounts
  useEffect(() => {
    const sumsData = {};
    data.forEach(obj => {
      Object.keys(obj).forEach(field => {
        if (field !== 'utm_campaign') {
          if (typeof sumsData[field] === 'undefined') {
            sumsData[field] = obj[field];
          } else {
            sumsData[field] += obj[field];
          }
          if (field === 'ad_cost') {
            sumsData[field] = roundUpSecondDigit(sumsData[field]);
          }
        }
      });
    });
    setSums(sumsData);
    prevData.forEach(obj => {
      Object.keys(obj).forEach(field => {
        if (field !== 'utm_campaign') {
          if (typeof prevSums[field] === 'undefined') {
            prevSums[field] = obj[field];
          } else {
            prevSums[field] += obj[field];
          }
          if (field === 'ad_cost') {
            prevSums[field] = roundUpSecondDigit(prevSums[field]);
          }
        }
      });
      setPrevSums(prevSums);
    });
  }, [data]);


  function roundUpSecondDigit(number) {
    return Math.ceil(number * 100) / 100;
  }


  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <>
      <div>
        {!isLoading
        ?
          <>
            <dl
              className="ds-block grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4 divide-gray-200 divide-x"
            >
              {Object.keys(sums).map(item => (
                  <>
                    <div className="px-4 py-5 sm:p-6">

                      <dt className="text-base font-normal text-gray-900">{t(item)}</dt>

                      <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                        {item !== 'ad_cost'
                          ?
                          <div className="flex items-baseline text-2xl font-semibold ds-text-color">
                            {sums[item]}
                          </div>
                          :
                          <>
                            <div className="flex items-baseline text-2xl font-semibold ds-text-color">
                              {sums[item].toLocaleString()}
                              <span className="ml-2 text-sm font-medium text-gray-500">VND</span>
                            </div>
                          </>


                        }


                        <div
                          className={classNames(
                            Math.sign(sums[item] - prevSums[item]) === 1 ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800',
                            'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0'
                          )}
                        >
                          {Math.sign(sums[item] - prevSums[item]) === 1 ? (
                            <ArrowUpIcon
                              className={classNames(
                                'text-green-500',
                                " h-5 w-5 flex-shrink-0 self-center text-green-500")}
                              aria-hidden="true"
                            />
                          ) : (
                            <ArrowDownIcon
                              className={classNames(
                                'text-gray-500',
                                " h-5 w-5 flex-shrink-0 self-center text-gray-500")}

                              aria-hidden="true"
                            />
                          )}


                        </div>


                      </dd>
                    </div>
                  </>

                ))

                }

            </dl>
          </>
          :
          <>
            {
              isLoading ? <div
                  className={"flex justify-center items-center relative w-full mt-2 ds-block"}
                >
                  <div className="ds-input-text">
                    <div role="status">
                      <div className="mx-auto h-14 w-14"><DsIconLoading/></div>
                    </div>
                  </div>
                </div>
                : <div
                  className={classNames(`ds-block flex justify-center items-center w-full mt-2 ds-border-gray`)}
                >
                  <div className="ds-input-text">
                    <div className="mx-auto h-14 w-14"><DsIconAnalysis/></div>
                    <span className="">Data Empty</span>
                  </div>
                </div>
            }
          </>
        }


      </div>

      <div className="ds-block mt-5">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="">
          <tr>
            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-bold text-gray-900 sm:pl-6">
              Campaign
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-bold text-gray-900">
              Spend
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-bold text-gray-900">
              Installs
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-bold text-gray-900">
              Clicks
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-bold text-gray-900">
              Impression
            </th>

          </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white text-left">
          {
            isLoading
              ?
              <tr>
                {
                  Array.from({length: 5}).map((_, i) => (
                    <td key={i} className="pl-8 whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <LoadingSpinner/>
                    </td>
                  ))
                }
              </tr>
              :
              <>
                {data.map((item, index) => (
                  <tr key={index}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-normal text-gray-900 sm:pl-6">
                      {item.utm_campaign}
                    </td>
                    <td
                      className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{roundUpSecondDigit(item.ad_cost).toLocaleString()}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.ad_app_installs}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.ad_click}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.ad_impression}</td>
                  </tr>
                ))}
              </>

          }


          </tbody>

        </table>


      </div>
      <div>


      </div>

    </>
  )
}
