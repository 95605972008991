import TextInput from "components/TextInput";
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import DataSourceService from "services/data_source";
import {showError, showInfo} from "components/FlashMessage/flashMessageSlice";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import Tooltip from "../../../../../components/Tooltip";
import {InformationCircleIcon} from "@heroicons/react/20/solid";
import {destinationOptions, fieldOptions, fileFormatOptions, intervalTimeOptions} from "../../../types";
import TailwindCombobox from "../../../../../components/TailwindCombobox";
import {processDataSourceData} from "../../../processor";
import {PATHS} from "../../../../../consts";
import {isUndefinedOrEmpty} from "../../../../../utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleExclamation, faSquarePlus, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import {DsIconTooltip} from "components/DsIcon";


export default function DataSourceFormUpdate({id, closeModal, reset, partner}) {
  const methods = useForm();
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [initValue, setInitValue] = useState(null);
  const [isGoogleSheets, setIsGoogleSheets] = useState(false);
  const [isGCloudStorage, setIsGCloudStorage] = useState(false);
  const [isExternalBigQuery, setIsExternalBigQuery] = useState(false);
  const {fields, append, remove} = useFieldArray({
    control: methods.control,
    name: 'fields',
  });
  const destination = methods.watch("destination");


  const getDataSource = async() => {
    const data = await DataSourceService.getDataSource({partner, id});

    const tempValue = [
      {key: 'name', value: data.name},
      {key: 'shopId', value: data.shopId},
      {key: 'intervalTime', value: `${data.intervalTime}-${data.intervalTimeType}`},
      {key: "destination", value: data.destination},
      {key: 'googleSheetsUrl', value: data.googleSheetsUrl},
      {key: 'sheetName', value: data.sheetName},
      {key: 'bucketName', value: data.bucketName},
      {key: 'objectKey', value: data.objectKey},
      {key: 'fileFormat', value: data.fileFormat},
      {key: 'gCloudProjectId', value: data.gCloudProjectId},
      {key: 'dataset', value: data.dataset},
      {key: 'tableName', value: data.tableName},
      {key: 'fields', value: data.fields},
    ]

    if (data.destination === 'DATA_SOURCE_DESTINATION_GOOGLE_SHEETS') {
      setIsGoogleSheets(true)
    } else if (data.destination === 'DATA_SOURCE_DESTINATION_GOOGLE_CLOUD_STORAGE') {
      setIsGCloudStorage(true)
    } else if (data.destination === 'DATA_SOURCE_DESTINATION_EXTERNAL_BIGQUERY') {
      setIsExternalBigQuery(true)
    }

    tempValue.map((kv) => {
      methods.setValue(kv.key, kv.value);
    });
    setInitValue(tempValue)
  }

  useEffect(() => {
    if (destination === 'DATA_SOURCE_DESTINATION_GOOGLE_SHEETS') {
      setIsGoogleSheets(true)
    } else {
      setIsGoogleSheets(false)
    }

    if (destination === 'DATA_SOURCE_DESTINATION_GOOGLE_CLOUD_STORAGE') {
      setIsGCloudStorage(true)
    } else {
      setIsGCloudStorage(false)
    }

    if (destination  === 'DATA_SOURCE_DESTINATION_EXTERNAL_BIGQUERY') {
      setIsExternalBigQuery(true)
    } else {
      setIsExternalBigQuery(false)
    }
  }, [destination]);

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      data.id = id;
      data.partner = partner;
      const payload = processDataSourceData(data)

      await DataSourceService.updateDataSource(payload);
      dispatch(showInfo({message: t("UPDATE_VALUE_SUCCESSFULLY", {Value: "data source"})}));
      closeModal();
      reset();
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
    setIsLoading(false);
  }

  const discardChanges = () => {
    if (initValue === null) {
      return;
    }
    initValue.map((kv) => {
      methods.setValue(kv.key, kv.value);
    });
  }

  useEffect(() => {
    getDataSource();
    discardChanges();
  }, [partner, id]);


  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="gap-4 bg-white mt-5 ds-border-gray shadown-md">
          <div className="grid grid-cols-4 gap-4 bg-white rounded-lg shadown-md">
            <div className="col-span-3">
              <div className="p-4">
                <div className="grid gap-4">
                  <div>
                    <TextInput
                      label="Name"
                      name="name"
                      className="ds-input-text w-full"
                      rules={
                        {
                          required: t('Name is required'),
                        }
                      }
                    />
                  </div>
                  <div>
                    <TextInput
                      name="shopId"
                      label="Shop Id"
                      className="ds-input w-full"
                      rules={
                        {
                          required: t('Shop Id is required'),
                        }
                      }
                    />
                  </div>

                  <div>
                    <div className="flex justify-start items-center w-full mt-1">
                      <div className="ds-input-text me-2">Interval time</div>
                      <Tooltip minWidth="380">
                        <Tooltip.Label>
                          <div><DsIconTooltip className="h-full w-full text-blue-400"
                                              aria-hidden="true"/>
                          </div>
                        </Tooltip.Label>
                        <Tooltip.Content>
                          <p>Set interval time to export data.</p>
                        </Tooltip.Content>
                      </Tooltip>
                    </div>
                    <TailwindCombobox
                      name="intervalTime"
                      className="w-full"
                      items={intervalTimeOptions}
                      rules={
                        {
                          required: t('Interval time is required'),
                        }
                      }
                    />
                  </div>

                  <div>
                    <span className="ds-input-text">Export data to</span>
                    <TailwindCombobox
                      name="destination"
                      className="w-full"
                      items={destinationOptions}
                      rules={
                        {
                          required: t('Export destination is required'),
                        }
                      }
                    />
                  </div>
                  {
                    isGoogleSheets &&
                    <div className="grid gap-4">
                      <div className="ds-text-lighter">
                        <FontAwesomeIcon className="text-red-500 cursor-pointer mt-1 mr-1"
                                         icon={faCircleExclamation}/>
                        Please give account "big-query@datasenses-404710.iam.gserviceaccount.com" Editor access to your
                        Google Sheets before hitting "Update".
                      </div>
                      <div>
                        <TextInput
                          label="Google Sheets URL"
                          name="googleSheetsUrl"
                          className="ds-input w-full"
                          rules={
                            {
                              required: t('Google Sheets URL is required'),
                            }
                          }
                        />
                      </div>
                      <div>
                        <TextInput
                          label="Sheet name"
                          name="sheetName"
                          className="ds-input w-full"
                          placeholder="Sheet1"
                          rules={
                            {
                              required: t('Sheet name is required'),
                            }
                          }
                        />
                      </div>
                    </div>
                  }
                  {
                    isGCloudStorage &&
                    <div className="grid gap-4">
                      <div>
                        <TextInput
                          label="Bucket name"
                          name="bucketName"
                          className="ds-input w-full"
                          rules={
                            {
                              required: t('Bucket name is required'),
                            }
                          }
                        />
                      </div>
                      <div>
                        <TextInput
                          label="Object key"
                          name="objectKey"
                          className="ds-input w-full"
                          rules={
                            {
                              required: t('Object key is required'),
                            }
                          }
                        />
                      </div>
                      <div>
                        <span className="ds-input-text">File format (optional)</span>
                        <TailwindCombobox
                          name="fileFormat"
                          items={fileFormatOptions}
                        />
                      </div>
                    </div>
                  }
                  {
                    isExternalBigQuery &&
                    <div className="grid gap-4">
                      <div>
                        <TextInput
                          label="Project Id"
                          name="gCloudProjectId"
                          className="ds-input w-full"
                          rules={
                            {
                              required: t('Project Id is required'),
                            }
                          }
                        />
                      </div>
                      <div>
                        <TextInput
                          label="Dataset"
                          name="dataset"
                          className="ds-input w-full"
                          rules={
                            {
                              required: t('Object key is required'),
                            }
                          }
                        />
                      </div>
                      <div>
                        <TextInput
                          label="Table name"
                          name="tableName"
                          className="ds-input w-full"
                          rules={
                            {
                              required: t('Table name is required'),
                            }
                          }
                        />
                      </div>
                    </div>
                  }
                  <div>
                    <div className="flex justify-start items-center w-full mt-1">
                      <div className="ds-input-text me-2">Fields</div>
                    </div>
                    {!isUndefinedOrEmpty(fields)
                      ?
                      fields.map((item, index) => (
                        <div key={item.id} className="grid grid-cols-2 gap-2 mt-0.5 mb-1">
                          <div>
                            <TailwindCombobox
                              name={`fields.${index}`}
                              items={fieldOptions[partner]}
                            />
                          </div>
                          <div>
                            <div className="table-cell py-1 mt-1">
                              <FontAwesomeIcon className="text-gray-400 cursor-pointer mt-1"
                                               onClick={() => remove(index)}
                                               icon={faTrashCan}/>
                            </div>
                          </div>
                        </div>
                      ))
                      :
                      <></>
                    }
                    <div
                      onClick={append}
                      className="bg-transparent cursor-pointer hover:bg-blue-500 ds-button-2 font-semibold hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent w-fit rounded mt-2"
                    >
                      <FontAwesomeIcon icon={faSquarePlus} className="me-2"/>
                      Add
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-end items-center mb-5 mt-2 mr-5">
            <button
              type="button"
              className="ds-button-cancel mr-3"
              onClick={discardChanges}
            >
              {t('DISCARD_CHANGES')}
            </button>

            <button
              type="submit"
              className="ds-button"
            >
              {isLoading ?
                <>
                  <LoadingSpinner className="me-3" textColor="text-blue-500" />
                  {t('LOADING')}
                </>
                :
                <>
                  Update
                </>
              }
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  )
}
