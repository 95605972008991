import { useFormContext } from "react-hook-form";
import ChartItems from "./chart_items";
import { useEffect } from "react";


export default function ChartList({
  name,
  defaultChartType,
  showDelete = false,
  onDelete = () => { },
}) {
  const { register, setValue, watch } = useFormContext();

  useEffect(() => {
    register(name);
  }, [name, register]);

  useEffect(() => {
    if (defaultChartType) {
      setValue(name, defaultChartType);
    }
  }, [defaultChartType]);

  const onClick = (item) => {
    setValue(name, item.Type)
  }

  return (
    <ChartItems onClick={onClick} showDelete={showDelete} onDelete={onDelete} icon="THREE_DOT" />
  )
}
