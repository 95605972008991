import { useController, useFormContext } from 'react-hook-form';

import Input from './input';

/**
 * Component for rendering a segment filter input.
 * @param {Object} props - The props object.
 * @param {string} props.name - The name of the segment filter input.
 * @param {Object} [props.defaultValue=[]] - The default value for the segment filter input.
 * @param {string} [props.className=''] - Additional classes for styling.
 * @returns {JSX.Element} A React component representing the SegmentFilterInput.
 */
export default function TeamMemberInput({
  name,
  defaultValue = [],
  className,
}) {
  const { control } = useFormContext();
  const { field } = useController({ name, control, defaultValue });

  const onChange = (value) => {
    field.onChange(value);
  };

  return (
    <Input onChange={onChange} value={field.value} className={className} />
  );
}
