import {PATHS} from "consts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import React from "react";

export default function BackButton() {
  return (
    <>
      <Link
        to={`${PATHS.APP}${PATHS.PARTNER_MARKETPLACE}`}
        className="ds-text-color-violet flex items-center text-sm hover:underline"
      >
        <FontAwesomeIcon icon={faAngleLeft}/>
        <p className={"ml-2"}>
          Back
        </p>
      </Link>
    </>
  )
}
