import { Fragment, useRef } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { useForm, FormProvider } from 'react-hook-form';
import { isUndefinedOrEmpty } from "utils";
import { useDispatch } from "react-redux";
import { showError } from "components/FlashMessage/flashMessageSlice";
import TailwindCombobox from "components/TailwindCombobox";

import { defaultChartList } from "../visualisation_header/chart_list/chart_items";

const listChart = defaultChartList.map((c) => (
  {
    fieldKey: c.Type,
    name: c.Name,
    icon: c.Icon,
  }
))

export default function VisualisationDialog({
  open,
  setOpen,
  dimensions,
  metrics,
  addNewChart = () => {},
}) {
  const { t } = useTranslation();
  const cancelButtonRef = useRef(null)
  const methods = useForm();
  const dispatch = useDispatch()

  const dimensionValue = methods.watch("dimension")
  const metricValue = methods.watch("metric")
  const typeValue = methods.watch("type")

  const onDiscard = (e) => {
    e.preventDefault();
    setOpen(false)
  }



  const addButton = () => {
    if (!isUndefinedOrEmpty(dimensionValue) && !isUndefinedOrEmpty(metricValue) && !isUndefinedOrEmpty(typeValue)) {
      addNewChart({
        dimension: dimensionValue,
        metric: metricValue,
        type: typeValue,
      })
      setOpen(false)
    } else {
      dispatch(showError({ message: "Field is missing" }))
    }
  }

  const icon = <Menu.Button>
    <FontAwesomeIcon className="-mr-1 h-5 w-5 text-gray-400" icon={faEllipsisVertical} />
  </Menu.Button>

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative w-full h-96 transform overflow-hidden ds-block text-left sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                <FormProvider {...methods}>
                  <form>
                    <div className="grid grid-rows-1 gap-8">
                      <div className="flex grid grid-cols-6 items-center">
                        <div className="col-start-1 col-end-1 text-sm font-medium">{t('Select chart')}</div>
                        <div className="col-start-2 col-end-4">
                          <TailwindCombobox
                            name={`type`}
                            items={listChart}
                          />
                        </div>
                      </div>
                      <div className="flex grid grid-cols-6 items-center">

                        <div className="col-start-1 col-end-1 text-sm font-medium">{t("SHOW_TOP")}</div>
                        <div className="col-start-2 col-end-4">
                          <TailwindCombobox
                            name={`dimension`}
                            items={dimensions}
                          />
                        </div>
                        <div className="col-start-4 col-end-4 text-sm font-medium text-center">{t('BY')}</div>
                        <div className="col-start-5 col-end-8">
                          <TailwindCombobox
                            name={`metric`}
                            items={metrics}
                          />
                        </div>


                      </div>
                    </div>
                    <div className="absolute bottom-2 right-2">
                      <button
                        onClick={onDiscard}
                        className="ds-button-cancel mr-2">
                        Discard change
                      </button>
                      <button
                        onClick={addButton}
                        type="button"
                        className="ds-button">
                        Add
                      </button>
                    </div>
                  </form>
                </FormProvider>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
