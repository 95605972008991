import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import {
  faClose,
  faFilter,
  faSortDown,
  faMagnifyingGlass,
  faFilterCircleXmark,
  faList,
  faBacon,
} from '@fortawesome/free-solid-svg-icons';
import ToggleBox from 'components/ToggleBox';
import { FILTER_TYPES } from 'consts';

import ChartService from 'services/chart';

import './index.scss';

const typeActions = [
  {
    icon: faFilter,
    label: 'INCLUDE',
    value: FILTER_TYPES.INCLUDE,
  },
  {
    icon: faFilterCircleXmark,
    label: 'EXCLUDE',
    value: FILTER_TYPES.EXCLUDE,
  },
  {
    icon: faList,
    label: 'CONTAIN',
    value: FILTER_TYPES.CONTAIN,
  },
  {
    icon: faBacon,
    label: 'REGEX',
    value: FILTER_TYPES.REGEX,
  },
];

/**
 * A component for selecting values with various types of filters.
 *
 * @param {Object} props - The component props.
 * @param {string} props.icon - The icon to display.
 * @param {string} props.name - The name of the select field.
 * @param {string} props.fieldKey - The field key.
 * @param {Array} props.value - The currently selected values.
 * @param {string} props.type - The default type of filter.
 * @param {Function} [props.onChange=() => {}] - The function called when the selected values change.
 * @param {Function} [props.onDelete=() => {}] - The function called when the delete button is clicked.
 * @param {string} [props.className] - Additional CSS class names for styling.
 * @returns {JSX.Element} - The rendered component.
 */
export default function ValueSelect({
  icon,
  name,
  fieldKey,
  value,
  type,
  onChange = () => { },
  onDelete = () => { },
  className,
}) {
  const { t } = useTranslation();
  const { appId } = useParams();
  const [defaultType, setDefaultType] = useState(type);
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState("");

  const wrapper = useRef(null);

  const typeIcon = useMemo(() => {
    return typeActions.find(item => item.value === defaultType).icon;
  }, [defaultType]);

  const [defaultOptions, setDefaultOptions] = useState([]);

  const options = useMemo(() => {
    return defaultOptions.filter((value) => value.includes(search));
  }, [defaultOptions, search]);

  const chooseType = (value) => {
    setDefaultType(value);
  };

  useEffect(() => {
    if ([FILTER_TYPES.CONTAIN, FILTER_TYPES.REGEX].includes(defaultType)) {
      setSelected([]);
    } else {
      setSelected(value);
    }
  }, [defaultType]);

  const itemLabel = useMemo(() => {
    if ([FILTER_TYPES.INCLUDE, FILTER_TYPES.EXCLUDE].includes(defaultType)) {
      return `${selected.length} items`;
    }
    return selected.length > 0 ? selected[0] : '';
  }, [defaultType, selected]);

  const checkBoxClick = (value) => {
    if ([FILTER_TYPES.INCLUDE, FILTER_TYPES.EXCLUDE].includes(defaultType)) {
      const indexToRemove = selected.indexOf(value);
      if (indexToRemove === -1) {
        setSelected([...selected, value]);
      } else {
        const updatedArray = selected.filter(item => item !== value);
        setSelected(updatedArray);
      }
    } else {
      setSelected([value]);
    }
  };

  const onClose = () => {
    if (value.length === 0) {
      onDelete();
    }
  };

  const onTypeClose = () => {
  };

  const apply = () => {
    onChange(selected, defaultType);
    wrapper.current.toggle();
  };

  const cancel = () => {
    if (value.length === 0) {
      onDelete();
    } else {
      wrapper.current.toggle();
      setSelected(value);
      setDefaultType(type);
    }
  };

  const getFieldValue = async () => {
    try {
      const result = await ChartService.getFieldValue({ appId, fieldKey });

      setDefaultOptions(result.map((item) => item.value));
    } catch (error) {

    }
  };

  useEffect(() => {
    getFieldValue();
  }, [appId, fieldKey]);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  useEffect(() => {
    setDefaultType(type);
  }, [type]);

  return (
    <ToggleBox firstOpen={true} ref={wrapper} position='center' onClose={onClose}>
      <ToggleBox.Button>
        <div className={`rounded-full flex justify-between items-center border bg-blue-100 px-2 py-0.5 cursor-pointer me-3 ${className}`}>
          <div className='flex flex-row items-center'>
            <FontAwesomeIcon className='h-3 w-3 me-1.5' icon={icon} />
            <div className='select-none mb-0.5'>{name}: {itemLabel}</div>
          </div>

          <FontAwesomeIcon onClick={onDelete} className='h-4 w-4 ms-3 hover:text-red-500' icon={faClose} />
        </div>
      </ToggleBox.Button>

      <ToggleBox.Content>
        <div className='value-select relative py-2'>
          <div className='flex flex-row px-3'>
            <ToggleBox autoClose position='start' onClose={onTypeClose}>
              <ToggleBox.Button>
                <div className='flex flex-row items-center border cursor-pointer p-1 me-2'>
                  <FontAwesomeIcon className='h-4 w-4 me-2' icon={typeIcon} />
                  <FontAwesomeIcon className='h-3 w-3 mb-1' icon={faSortDown} />
                </div>
              </ToggleBox.Button>

              <ToggleBox.Content>
                <div>
                  {typeActions.map((item, index) => (
                    <div key={index} onClick={() => chooseType(item.value)} className='flex flex-row items-center cursor-pointer select-none hover:bg-blue-100 px-3 py-0.5'>
                      <FontAwesomeIcon className='h-4 w-4 me-2' icon={item.icon} />
                      {t(item.label)}
                    </div>
                  ))}
                </div>
              </ToggleBox.Content>
            </ToggleBox>

            <div className='relative'>
              <input
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                className='rounded px-3 border border-gray-300 pl-8'
                placeholder={t('SEARCH')}
              />
              <FontAwesomeIcon className='h-4 w-4 search-icon' icon={faMagnifyingGlass} />
            </div>
          </div>

          <div className='max-h-28 overflow-y-scroll mt-3'>
            {Array.isArray(options) && options.map((value, key) => (
              <div
                key={key}
                className={`flex flex-row items-center px-3 hover:bg-blue-100 ${(defaultType === 'contain' || defaultType === 'regex') && selected.includes(value) && 'bg-blue-100'}`}
                onClick={() => checkBoxClick(value)}
              >
                {
                  (defaultType === FILTER_TYPES.INCLUDE || defaultType === FILTER_TYPES.EXCLUDE) &&
                  <input
                    type="checkbox"
                    id={value}
                    value={value}
                    checked={selected.includes(value)}
                    onChange={() => checkBoxClick(value)}
                  />
                }
                <label className="ms-4 pb-1 select-none">{value}</label>
              </div>
            ))}
          </div>

          <div className='flex flex-row-reverse absolute right-4 bottom-2'>
            <button
              type="button"
              onClick={apply}
              disabled={selected.length === 0}
              className="text-white bg-blue-600 disabled:bg-gray-300 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-1.5 focus:outline-none"
            >
              {t('APPLY')}
            </button>

            <button
              type="button"
              onClick={cancel}
              className="text-blue-500 me-2 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-1.5 focus:outline-none"
            >
              {t('CANCEL')}
            </button>
          </div>
        </div>
      </ToggleBox.Content>
    </ToggleBox>
  );
}
