import {useEffect, useState} from "react";
import {debounce} from "lodash";
import {CHART_CONFIG} from "consts";
import {formatNumber, isUndefinedOrEmpty} from "utils";

import ChartSerivce from "services/chart";

import {parseKPIChartResponse} from "../chartProcessor";
import VisualisationEmpty from "views/visualisation/visualisation_empty";
import {titleCase} from "utils/util";


export default function VisualisationKpi({
                                           appId,
                                           globalFilter = [],
                                           chartId,
                                           metricValue,
                                           header,
                                           className = ""
                                         }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const getData = async () => {
    if (globalFilter.length > 0) {
      const metrics = Array.isArray(metricValue) ? metricValue : [metricValue];
      setIsLoading(true)
      const request = {
        [CHART_CONFIG.FIELD_CHART_NAME]: chartId,
        [CHART_CONFIG.FIELD_CHART_TYPE]: CHART_CONFIG.CHART_TYPE_KPI,
        [CHART_CONFIG.FIELD_GLOBAL_FILTERS]: globalFilter,
        [CHART_CONFIG.FIELD_METRIC]: metrics.map((v) => ({
          [CHART_CONFIG.FIELD_NAME]: !isUndefinedOrEmpty(v.name) ? v.name : v
        })),
      };

      const result = await ChartSerivce.getChart(appId, request);
      if (result) {
        setData(parseKPIChartResponse(result.data));
        setIsLoading(false);
      } else {
        setData([]);
        setIsLoading(false);
      }
    }
  };

  const debouncedGetData = debounce(getData, 500);

  useEffect(() => {
    debouncedGetData();
  }, [globalFilter]);


  const getDisplayName = (fieldName) => {
    const metrics = Array.isArray(metricValue) ? metricValue : [metricValue];
    const fields = metrics.filter(item => (item.name === fieldName || item === fieldName))
    if (fields.length > 0) {
      return isUndefinedOrEmpty(fields[0]['displayedName']) ? titleCase(fields[0]) : fields[0]['displayedName']
    } else {
      return ''
    }
  }

  return (
    <>
      <div
        className={`relative items-center ds-block p-3  ${className && className.trim() ? className : "flex justify-between text-base"}`}>
        {
          !isLoading && data.length > 0 ? data.map((e, index) => (

            <div key={index} className={`px-1`}>
              <div>
                  <dt className="ds-input-text">{getDisplayName(e.name)}</dt>
                <dd className="ds-text-color-violet font-bold break-words">
                  {formatNumber(Math.round(e.value))}
                </dd>
              </div>
            </div>
          )) : <VisualisationEmpty  isLoading={isLoading} type={"KPI"}/>
        }
        {!isUndefinedOrEmpty(header) && header}
      </div>
    </>
  )
}
