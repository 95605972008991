import {useDispatch} from "react-redux";
import {useTranslation} from 'react-i18next';
import {useState} from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowsUpDownLeftRight} from '@fortawesome/free-solid-svg-icons';
import {CARD_SUBTYPE_DESCRIPTION, CARD_TYPE, CARD_TYPE_DESCRIPTION, CHART_CONFIG} from "consts";
import {DsIconDelete, DsIconEdit} from "components/DsIcon";
import TailwindMenuDialog from "components/TailwindMenuDialog";
import {showError, showInfo} from "components/FlashMessage/flashMessageSlice";
import DeleteModal from "components/DeleteModal";

import {isUndefinedOrEmpty} from "utils";
import VisualisationLineChart from "views/visualisation/visualisation_linechart";
import VisualisationBarChart from "views/visualisation/visualisation_barchart";
import FunnelVisualisationBarChart from "views/analytics/funnel/VisualizationBarchart/index"
import VisualisationTableChart from "views/visualisation/visualization_table";
import VisualizationFunnel from "views/analytics/components/VisualizationFunnel";
import VisualisationStackedChart from "views/visualisation/visualisation_stackedchart";
import VisualisationKpi from "views/visualisation/visualisation_kpi";
import CardSvc from "services/card";


export default function Visualisation({
                                        appId,
                                        card = {},
                                        chartType,
                                        globalFilter,
                                        chartId,
                                        dimensionValue,
                                        limitValue,
                                        showNonZero,
                                        metricValue,
                                        frequencyValue,
                                        orderValue,
                                        title = false,
                                        onMoveMouseDown = () => {},
                                        isEdit = false,
                                        reset = () => {
                                        },
                                      }) {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const descriptionSubType = isUndefinedOrEmpty(CARD_SUBTYPE_DESCRIPTION[card.subType]) ? "" : ` | ${CARD_SUBTYPE_DESCRIPTION[card.subType]}`
  const description = CARD_TYPE_DESCRIPTION[card.type] + descriptionSubType

  const handleUpdate = () => {
  }

  const handleDelete = () => {
    setIsDeleteOpen(true);
  }

  const deleteCard = async () => {
    try {
      await CardSvc.deleteCard({id: card ? card.id : "", dashboardId: card ? card.dashboardId : ""});

      dispatch(showInfo({message: t("DELETE_VALUE_SUCCESSFULLY", {Value: "card"})}));

      setIsDeleteOpen(false);
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
    reset();
  }


  return (
    <div>
      <div className="flex items-center justify-end ds-input-text">
        <div className="grow items-center flex">
          <div className="ds-text-form-title">{card ? card.name : ""}</div>
        </div>

        {
          isEdit ?
          <div className="move-handle" onMouseDown={onMoveMouseDown}>
            <FontAwesomeIcon className="mr-7" icon={faArrowsUpDownLeftRight}/>
          </div>
            : <div></div>
        }

        <div className="flex justify-end items-center">
          <TailwindMenuDialog
            items={[
              // {
              //   Name: "Update",
              //   Icon: <DsIconEdit/>,
              //   Action: () => handleUpdate(),
              //   ActionType: "button"
              // },
              {
                Name: "Delete",
                Icon: <DsIconDelete/>,
                Action: () => handleDelete(),
                ActionType: "button"
              },
            ]}
          />
        </div>
      </div>
      <div className="ds-input-text mb-2">{description}</div>

      <div className="w-auto h-auto">
        {chartType === CHART_CONFIG.CHART_TYPE_BARCHART ?
          <VisualisationBarChart
            appId={appId}
            globalFilter={globalFilter}
            chartId={chartId}
            dimensionValue={dimensionValue}
            metricValue={metricValue}
            showNonZero={showNonZero}
            limitValue={limitValue}
            orderValue={orderValue}
            title={title}
          />
          : chartType === CHART_CONFIG.CHART_TYPE_LINECHART ?
            <VisualisationLineChart
              appId={appId}
              globalFilter={globalFilter}
              chartId={chartId}
              dimensionValue={dimensionValue}
              metricValue={metricValue}
              showNonZero={showNonZero}
              frequencyValue={frequencyValue}
              title={title}
            />
            : chartType === CHART_CONFIG.CHART_TYPE_TABLE ?
              <VisualisationTableChart
                appId={appId}
                globalFilter={globalFilter}
                chartId={chartId}
                dimensionValue={dimensionValue}
                metricValue={metricValue}
                showNonZero={showNonZero}
                limitValue={limitValue}
              />
              : chartType === CHART_CONFIG.CHART_TYPE_STACKEDCHART ?
                <VisualisationStackedChart
                  appId={appId}
                  globalFilter={globalFilter}
                  chartId={chartId}
                  dimensionValue={dimensionValue}
                  metricValue={metricValue}
                  showNonZero={showNonZero}
                  title={title}
                  frequencyValue={frequencyValue}
                />
                : chartType === CHART_CONFIG.CHART_TYPE_KPI ?
                  <VisualisationKpi
                    appId={appId}
                    globalFilter={globalFilter}
                    chartId={chartId}
                    metricValue={metricValue}
                  />
                  : chartType.startsWith('bar') && card.type === CARD_TYPE.CART_TYPE_FUNNEL ?
                    <FunnelVisualisationBarChart
                      isPercent={card ? card.payload.isPercent : false}
                      name="Step"
                      data={card ? card.payload.data : []}
                    />
                    : chartType.startsWith('funnel') ?
                      <VisualizationFunnel
                        isPercent={card ? card.payload.isPercent : false}
                        name="Step"
                        data={card ? card.payload.data : []}
                        steps={card ? card.payload.steps : []}
                      />

                      : <></>
        }
      </div>

      <DeleteModal
        name={card ? card.name : ""}
        objectType="card"
        isOpen={isDeleteOpen}
        onDelete={deleteCard}
        closeModal={() => setIsDeleteOpen(false)}
      />
    </div>
  )
}
