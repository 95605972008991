import { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import { PATHS, EMAIL_REGEX } from "consts";

import TextInput from 'components/TextInput';
import { showError, showInfo } from 'components/FlashMessage/flashMessageSlice';

import UserService from 'services/user';

import { Header } from "../landing_page/home/Header";
import { Footer } from "../landing_page/home/Footer";
import { Faqs } from "../landing_page/home/Faqs";
import {DsIconSlogan} from "../../components/DsIcon";
import LPTextBlock from 'views/landing_page/LPTextBlock';

export default function RequestForm() {
  const { t } = useTranslation();
  const methods = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isLogin = useSelector((state) => !!state.user.refreshToken);

  useEffect(() => {
    if (isLogin) {
      navigate(PATHS.APP);
    }
  }, [isLogin]);

  const onSubmit = async (data) => {
    try {
      const { message } = await UserService.requestDemo(data);
      dispatch(showInfo({ message: "We will contact you soon." }));
      navigate(PATHS.HOMEPAGE);
    } catch (error) {
      const { message } = error;
      console.log(error)
      dispatch(showError({ message }));
    }
  };


return (
  <>
    <div className={"grid grid-rows-1 gap-y-36"}>
        <div className={"lp-background-2"}>
          <div className={"flex grid grid-cols-1 lg:grid-cols-2 gap-8"}>
          <div className="flex flex-col items-center justify-center">
                  <LPTextBlock
                    title={"Discover the Perfect Solution"}
                    description={
                      <>
                       Request a personalized demo to see how our platform can optimize your marketing and accelerate growth.
                      </>
                    }
                    type={"bg_light"}

                  />
                </div>
            <div className={"w-full bg-white glass-effect justify-center"}>
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                {/* <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                  {t('REQUEST_A_DEMO')}
                </h1> */}
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)} className="space-y-4 md:space-y-6">
                    <div>
                      <TextInput
                        label={t('FULL_NAME')}
                        name="name"
                        className="w-full"
                        rules={
                          {required: t('VALUE_IS_REQUIRED', {Value: t('FULL_NAME')})}
                        }
                      />
                    </div>
                    <div>
                      <TextInput
                        label={t('YOUR_EMAIL')}
                        name="email"
                        className="w-full"
                        // value={invitedUser && invitedUser.email ? invitedUser.email : undefined}
                        rules={
                          {
                            required: t('VALUE_IS_REQUIRED', {Value: t('YOUR_EMAIL')}),
                            pattern: {
                              value: EMAIL_REGEX,
                              message: t('INVALID_EMAIL_ADDRESS')
                            },
                          }
                        }
                      />
                    </div>
                    <div>
                      <TextInput
                        label={t('PHONE_NUMBER')}
                        name="phone"
                        className="w-full"
                        rules={
                          {
                            required: t('VALUE_IS_REQUIRED', {Value: t('PHONE_NUMBER')}),
                          }
                        }
                      />
                    </div>
                    <div>
                      <TextInput
                        label={t('COMPANY_NAME')}
                        name="company_name"
                        className="w-full mb-2"
                      />
                    </div>
                    <button
                      className="ds-button-3"
                    >
                      {t('REQUEST_A_DEMO')}
                    </button>
                    <p className="text-sm font-light text-gray-500">
                      {t('ALREADY_HAVE_AN_ACCOUNT')}?
                      <Link
                        to={PATHS.LOGIN}
                        className="font-medium text-primary-600 hover:underline ms-1"
                      >
                        {t('SIGN_IN')}
                      </Link>
                    </p>
                  </form>
                </FormProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
  </>
)
}
