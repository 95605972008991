import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {buildAppList} from "utils/app";
import {isUndefinedOrEmpty} from "utils";
import {CHART_CONFIG, PATHS, REPORT_TYPE} from "consts";
import $ from "jquery";

import FieldFilterToogleBox from "components/FieldFilterToogleBox";
import DateFilterToggleBox from "components/DateFilterToggleBox";
import {showError} from "components/FlashMessage/flashMessageSlice";
import DsListBox from "components/DsListBox";

import ChartSerivce from "services/chart";
import DashboardService from "services/dashboard";
import {SaveToDashboard} from "../dashboard_list/save_to_dashboard";

import "./index.scss";
import {buildGlobalFilterRequest} from "views/visualisation/chartProcessor";
import Visualisation from "views/overview/visualisation";
import VisualisationAddNew from "views/overview/visualisation_add_new";
import VisualisationKpi from "views/visualisation/visualisation_kpi";
import {showDataOptions} from "consts/chartConfig";


export default function Overview() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [reload, setReload] = useState(false);

  const [initialRender, setInitialRender] = useState(true);
  const [isSticky, setIsSticky] = useState(false);

  const {appId, dashboardId} = useParams();
  const {t} = useTranslation();

  const appList = useSelector((state) => state.app.appList);

  const appOptions = useMemo(() => {
    return buildAppList(appList, PATHS.OVERVIEW);
  }, [appList]);

  const methods = useForm();

  const {
    fields: chartCustoms,
    append: appendChartCustom,
    remove: removeChartCustom,
  } = useFieldArray({
    control: methods.control,
    name: CHART_CONFIG.CHART_CUSTOM,
  });

  const addNewChart = (item) => {
    appendChartCustom(item);
  };

  const [fieldList, setFieldList] = useState([]);
  const [globalFiterRequest, setGlobalFiterRequest] = useState([]);

  const chartDimensions = useMemo(() => {
    const value = fieldList.filter(
      (element) => element.attribute === "dimension" && element.isSelectedOption
    );
    return value
      ? value.map((item) => ({name: item.name, fieldKey: item.fieldKey}))
      : [];
  }, [fieldList]);


  const chartMetrics = useMemo(() => {
    const value = fieldList.filter(
      (element) => element.attribute === "metric" && element.isSelectedOption
    );
    return value
      ? value.map((item) => ({name: item.name, fieldKey: item.fieldKey}))
      : [];
  }, [fieldList]);

  const isShowDateFilter = useMemo(() => {
    return fieldList.some(
      (item) => item.fieldKey === CHART_CONFIG.CHART_DATE_FIELD
    );
  }, [fieldList]);

  const globalFiter = useMemo(() => {
    return fieldList.filter(
      (element) =>
        element.attribute === "dimension" &&
        element.filterable === true &&
        element.fieldKey !== CHART_CONFIG.CHART_DATE_FIELD
    );
  }, [fieldList]);


  const getChartFieldList = async () => {
    try {
      if (!isUndefinedOrEmpty(appId)) {
        const result = await ChartSerivce.getFieldList({appId});
        setFieldList(result);
      }
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
  };

  const onSubmit = async (data) => {
    try {
      const globalFiterRequest = buildGlobalFilterRequest(data);
      setGlobalFiterRequest(globalFiterRequest);
      setReload(false);
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
  };

  const onClickReload = () => {
    setReload(false);
    methods.handleSubmit(onSubmit)();
  };

  const getDashboardDetail = async () => {
    const result = await DashboardService.getDashboardDetail({dashboardId});
    const formData = JSON.parse(result.payload);

    const globalFiterRequest = buildGlobalFilterRequest(formData);
    setGlobalFiterRequest(globalFiterRequest);

    if (Object.keys(formData).length > 0) {
      for (const key of Object.keys(
        formData[CHART_CONFIG.FIELD_GLOBAL_FILTERS]
      )) {
        methods.setValue(
          `${CHART_CONFIG.FIELD_GLOBAL_FILTERS}.${key}`,
          formData[CHART_CONFIG.FIELD_GLOBAL_FILTERS][key]
        );
      }
    }

    methods.setValue(CHART_CONFIG.CHART_1, formData[CHART_CONFIG.CHART_1]);
    methods.setValue(CHART_CONFIG.CHART_2, formData[CHART_CONFIG.CHART_2]);
    methods.setValue(CHART_CONFIG.CHART_3, formData[CHART_CONFIG.CHART_3]);
    methods.setValue(
      CHART_CONFIG.CHART_CUSTOM,
      formData[CHART_CONFIG.CHART_CUSTOM]
    );

    methods.handleSubmit(onSubmit)();

    setReload(true);
  };

  const reset = () => {
    methods.setValue(CHART_CONFIG.CHART_1, {
      dimension: chartDimensions[0].fieldKey,
      metric: chartMetrics[0].fieldKey,
      type: CHART_CONFIG.CHART_TYPE_LINECHART,
    });

    methods.setValue(CHART_CONFIG.CHART_2, {
      dimension: chartDimensions[0].fieldKey,
      metric: chartMetrics[0].fieldKey,
      type: CHART_CONFIG.CHART_TYPE_BARCHART,
    });

    methods.setValue(CHART_CONFIG.CHART_3, {
      dimension: chartDimensions[0].fieldKey,
      metric: chartMetrics[0].fieldKey,
      type: CHART_CONFIG.CHART_TYPE_STACKEDCHART,
    });

    methods.setValue(CHART_CONFIG.CHART_CUSTOM, []);
  };

  useEffect(() => {
    const subscription = methods.watch(() => {
      setReload(true);
    });
    return () => subscription.unsubscribe();
  }, [methods.watch()]);

  useEffect(() => {
    getChartFieldList();
  }, [appId]);

  useEffect(() => {
    methods.handleSubmit(onSubmit)();
  }, [chartMetrics, chartDimensions]);

  useEffect(() => {
    if (isUndefinedOrEmpty(appId) && appOptions.length > 0) {
      navigate(appOptions[0].href);
    }
  }, [appId, appOptions]);

  useEffect(() => {
    if (dashboardId) {
      getDashboardDetail();
    }
  }, [dashboardId]);

  useEffect(() => {
    if (!initialRender && !dashboardId) {
      reset();
    } else {
      setInitialRender(false);
    }
  }, [location]);

  useEffect(() => {
    function checkSticky() {
      if ($(window).scrollTop() > 55) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    }

    window.addEventListener("scroll", checkSticky);

    () => window.removeEventListener("scroll", checkSticky);
  }, []);

  return (
    <div id="overview">
      {isSticky && <div className="bg-stick"></div>}

      <div className={`${isSticky && "sticky"}`}>
        <div className="flex justify-between ds-block-white">
          <div className="ds-text-page-title">{t("OVERVIEW")}</div>
          <div className="flex">
            {reload && (
              <div className="text-sm font-semibold px-5">
                <button onClick={onClickReload} className="ds-button">
                  Reload
                </button>
              </div>
            )}
            {/*<div className="text-sm font-semibold">*/}
            {/*  <SaveToDashboard*/}
            {/*    dashboardId={dashboardId}*/}
            {/*    overviewPayload={methods.watch()}*/}
            {/*    reportType={REPORT_TYPE.REPORT_OVERVIEW}*/}
            {/*  />*/}
            {/*</div>*/}
          </div>
        </div>

        <DsListBox listData={appOptions}/>
      </div>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="flex flex-row w-full mt-5 space-x-2">
            <div>
              {isShowDateFilter && (
                <DateFilterToggleBox
                  name={`${CHART_CONFIG.FIELD_GLOBAL_FILTERS}.${CHART_CONFIG.CHART_DATE_FIELD}`}
                />
              )}
            </div>
            {globalFiter.map((filter, index) => (
              <div key={index}>
                <FieldFilterToogleBox
                  appId={appId}
                  fieldKey={filter.fieldKey}
                  displayedName={t(filter.fieldKey)}
                  name={`${CHART_CONFIG.FIELD_GLOBAL_FILTERS}.${filter.fieldKey}`}
                />
              </div>
            ))}
          </div>

          {/*<div className="grid grid-cols-6 gap-4 w-full mt-5">*/}
          {/*  <VisualisationKpi*/}
          {/*    appId={appId}*/}
          {/*    globalFilter={globalFiterRequest}*/}
          {/*    chartId={CHART_CONFIG.CHART_NAME_KPI_CHART_ID}*/}
          {/*    metricValue={CHART_CONFIG.CHART_KPI_METRIC}*/}
          {/*  />*/}
          {/*</div>*/}

          <div className="grid grid-cols-6 gap-4 w-full mt-5">
            {
              CHART_CONFIG.CHART_KPI_METRIC.map((value, index) => (
                <Visualisation
                  appId={appId}
                  globalFilter={globalFiterRequest}
                  chartId={CHART_CONFIG.CHART_NAME_KPI_CHART_ID + "_" + index}
                  metrics={[value]}
                  chartIndex={index}
                  defaultChartType={CHART_CONFIG.CHART_TYPE_KPI}
                />
              ))
            }
          </div>






          <div className="ds-block w-full mt-5">
            {chartDimensions.length > 0 && chartMetrics.length > 0 && (
              <Visualisation
                appId={appId}
                defaultChartType={CHART_CONFIG.CHART_TYPE_LINECHART}
                globalFilter={globalFiterRequest}
                chartId={CHART_CONFIG.CHART_1}
                chartIndex={0}
                dimensions={chartDimensions}
                metrics={chartMetrics}
                dataOptions={showDataOptions}
              />
            )}
          </div>

          <div className="w-full mt-5">
            <div className="grid grid-cols-2 gap-4">
              <div className="ds-block">
                {chartDimensions.length > 0 && chartMetrics.length > 0 && (
                  <Visualisation
                    appId={appId}
                    defaultChartType={CHART_CONFIG.CHART_TYPE_BARCHART}
                    globalFilter={globalFiterRequest}
                    chartId={CHART_CONFIG.CHART_2}
                    chartIndex={1}
                    dimensions={chartDimensions}
                    metrics={chartMetrics}
                    dataOptions={showDataOptions}

                  />
                )}
              </div>

              <div className="ds-block">
                {chartDimensions.length > 0 && chartMetrics.length > 0 && (
                  <Visualisation
                    appId={appId}
                    defaultChartType={CHART_CONFIG.CHART_TYPE_STACKEDCHART}
                    globalFilter={globalFiterRequest}
                    chartId={CHART_CONFIG.CHART_3}
                    chartIndex={2}
                    dimensions={chartDimensions}
                    metrics={chartMetrics}
                    dataOptions={showDataOptions}

                  />
                )}
              </div>

              {chartCustoms.map((item, index) => (
                <div className="ds-block" key={item.id}>
                  <Visualisation
                    appId={appId}
                    globalFilter={globalFiterRequest}
                    chartId={`${CHART_CONFIG.CHART_CUSTOM}.${index}`}
                    chartIndex={index + 3}
                    dimensions={chartDimensions}
                    metrics={chartMetrics}
                    showDelete={true}
                    onDelete={() => removeChartCustom(index)}
                    dataOptions={showDataOptions}

                  />
                </div>
              ))}

              {/*<div className="ds-block">*/}
              {/*  {chartDimensions && chartMetrics && (*/}
              {/*    <VisualisationAddNew*/}
              {/*      dimensions={chartDimensions}*/}
              {/*      metrics={chartMetrics}*/}
              {/*      addNewChart={addNewChart}*/}
              {/*    />*/}
              {/*  )}*/}
              {/*</div>*/}
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
