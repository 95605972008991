import HttpMethod from 'consts/httpMethod';
import {processErrorResponse, processRequest, processSuccessResponse} from 'utils';

export default {
  async getSKANConversion(appId) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `app/${appId}/skan-conversion`,
      method: HttpMethod.GET
    };

    try {
      const res = await processRequest(ep);
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  async createSKANConversion(appId, data) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `app/${appId}/skan-conversion`,
      method: HttpMethod.POST
    };

    try {
      const res = await processRequest(ep, {data});
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  async updateSKANConversion(appId, data) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `app/${appId}/skan-conversion`,
      method: HttpMethod.PUT
    };

    try {
      const res = await processRequest(ep, {data});
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  async deleteSKANConversion(appId) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `app/${appId}/skan-conversion`,
      method: HttpMethod.DELETE
    };

    try {
      const res = await processRequest(ep);
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },
}
