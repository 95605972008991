import {useEffect, useRef} from 'react';
import Chart from 'apexcharts';
import {isUndefinedOrEmpty} from "utils";


export default function VisualizationFunnel({isPercent = false, name = "", data = [], steps= []}) {
  const chartRef = useRef(null);


  useEffect(() => {
    if (!chartRef.current) return;

    const seriesData = isPercent ? data.map((item) => item.percentage.toFixed(2)) : data.map((item) => item.totalUsers);

    const chartOptions = {
      series: [
        {
          name: name,
          data: seriesData,
        },
      ],
      chart: {
        type: 'bar',
        height: 350,
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          horizontal: true,
          barHeight: '80%',
          isFunnel: true,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opt) {
          return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + val
        },
        dropShadow: {
          enabled: true,
        },
      },
      xaxis: {
        categories: !isUndefinedOrEmpty(steps) ? steps.map(step => Array.isArray(step) ? step.join(' or ') : step) : data.map((value, index) => `${name} ${index + 1}`),
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return isPercent ? value + '%' : value;
          }
        }
      },
      legend: {
        show: false,
      },
    };

    const chart = new Chart(chartRef.current, chartOptions);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [isPercent, data]);

  return <div ref={chartRef}></div>;
}
