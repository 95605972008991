import { Header } from "views/landing_page/home/Header";

export default function TermOfService() {
  return (
    <>
      <Header/>
      <div className="bg-white lg:px-8 pb-16 pt-56 lg:pt-56">
        <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Terms of Service</h1>
          <p className="mt-10 leading-8">
            We encourage you to read them to use the website metrics.datasenses.com (“the service), operated by DATA
            SOLUTION AND ANALYSIS CO.,LTD, legally constituted in accordance with the Vietnam law.
            These Terms apply to all visitors, users, and others who access or use the Service. By accessing or using
            the Service you agree to be bound by these Terms.
            By making use of DataSenses's site, you’re accepting the Terms and Conditions described below.
            Please, consider that these Terms and Conditions are subject to changes and can be modified at any time.
            However, we will notify our email list of such changes at least 15 days in advance to our contacts’ email
            list.
          </p>
          <div className="mt-10 max-w-3xl space-y-4 leading-8">
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Service</h2>
            <p className="mt-10 leading-8">
              DATA SOLUTION AND ANALYSIS CO.,LTD offers a set of web and hardware applications to help you connect
              different data sources to data visualization and Business Intelligence tools.
              DATA SOLUTION AND ANALYSIS CO.,LTD offers connectors; connectors are web applications that bring data from
              different applications APIs or sources to visualize them on different platforms.
              DATA SOLUTION AND ANALYSIS CO.,LTD is constantly upgrading and launching new products; offers and features
              may change over time.
            </p>
          </div>
          <div className="mt-10 max-w-3xl space-y-4 leading-8">
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Payments</h2>
            <p className="mt-10 leading-8">
              Read http://localhost:3000/legal/privacy-payment for more information
            </p>
          </div>
          <div className="mt-10 max-w-3xl space-y-4 leading-8">
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Content policy</h2>
            <p className="mt-10 leading-8">
              DATA SOLUTION AND ANALYSIS CO.,LTD is not responsible for the content of the service’s users. You, as a
              user, expressly understand and agree that you are solely responsible for the Content and for all activity
              that occurs under your account, whether done so by You or any third person using Your account.
              You may not create content (or type queries) that is unlawful, offensive, threatening, defamatory, and, in
              general, objectionable.
              Examples of objectionable, thus prohibited, content include, but are not limited to, the following:
              Defamatory, derogatory, or discriminatory content or messages towards any community or group of people,
              regardless of age, gender, political affiliation, religion, sexual orientation, etc.
              Harassing content intended to bully an individual or group of people.
              Political content: do not create or promote content intended to influence political processes or
              campaigns.
              Spam: do not create unsolicited, random advertising, chain letters, or content meant to promote
              unsolicited, spammy services.
              Illegal activities promotion such as drugs, sex work, unregulated gambling
              Malware: do not create content that contains (or incites the installation) viruses, worms, malware, trojan
              horses, or any content intended to disrupt, damage, or jeopardize the functioning of any software,
              hardware, or telecommunications equipment, nor obtain unauthorized access to data or third-party personal
              information.
              Violence incitation: avoid any type of content that celebrates or encourages violence, suffering, or
              humiliation of others, or self-harm.
              Adult content: avoid content meant to arouse sexual excitement, sexual activity, or promotes sexual
              services of any kind.
              Misinformation: do not create content meant to misinform, distort facts, or spread fake news.
              Violating privacy or infringing on any proprietary rights.
            </p>
          </div>
          <div className="mt-10 max-w-3xl space-y-4 leading-8">
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">3rd Parties</h2>
            <p className="mt-10 leading-8">
              As a data connectors providers, DATA SOLUTION AND ANALYSIS CO.,LTD connects to other web applications data
              sources and bring them to data visualization and Business Intelligence tools. As such, DATA SOLUTION AND
              ANALYSIS CO.,LTD is not accountable or responsible for any leakage or bug caused do to an error from those
              3rd parties, in any of those ends. DATA SOLUTION AND ANALYSIS CO.,LTD shall have no liability for any
              failure or delay due to matters beyond its reasonable control. The foregoing shall not apply to the extent
              prohibited by applicable law.
              Connectors are meant to only access to the data only after users’ consent while signing up, but the
              company never stores such data without consent.
              DATA SOLUTION AND ANALYSIS CO.,LTD may collect Personal Identifiable Information (PII) for user management
              and contacts details purposes.
              DATA SOLUTION AND ANALYSIS CO.,LTD does not share such PII with 3rd parties, unless its release is
              appropriate to comply with the law, enforce our site policies, or protect ours or others’ rights, property
              or safety.
              For more information please read http://localhost:3000/legal/privacy-policy-data
            </p>
          </div>
          <div className="mt-10 max-w-3xl space-y-4 leading-8">
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Disclaimer of warranties</h2>
            <p className="mt-10 leading-8">
              Our service relies on a set of 3rd parties. As such, we cannot warrant our service will be complete, accurate, reliable, timely, secure, error-free, or uninterrupted. You understand that you’re using DATA SOLUTION AND ANALYSIS CO.,LT at your discretion and risk.
              DATA SOLUTION AND ANALYSIS CO.,LT hereby disclaims all warranties of any kind, whether express, implied, statutory, or otherwise, including, without limitation, the warranties of merchantability and fitness or particular purpose an non-infringement.Yet, DATA SOLUTION AND ANALYSIS CO.,LT will make its best effort to serve the product its promoting with the standard quality of reputable providers.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
