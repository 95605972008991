export default function EmptyBox({title, subTitle, logoUrl}) {
  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="text-center mt-20 mb-20">
        <img src={logoUrl} alt="" className="h-10 w-10 mx-auto flex-shrink-0 rounded-full"/>
        <h3 className="mt-2 text-sm font-semibold text-gray-900">{title}</h3>
        <p className="mt-1 text-sm text-gray-500">{subTitle}</p>
      </div>
    </div>
  )
}
