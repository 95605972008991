export const tabLink = {
  TREND_AND_PROPERTIES: '#trend-and-properties',
  SESSION: '#session',
  GEOGRAPHY: '#geography',
  TECHNOGRAPHIC: '#technographics',
}

export const tabs = [
  { name: 'Trend and Properties', href: tabLink.TREND_AND_PROPERTIES },
  { name: 'Session', href: tabLink.SESSION },
  { name: 'Geography', href: tabLink.GEOGRAPHY},
  // { name: 'Technographics', href: tabLink.TECHNOGRAPHIC},
];
