import {CHART_CONFIG} from "consts";
import {isArray, isUndefinedOrEmpty} from "utils";
import {capitalizeAll} from "utils/util";

/**
 * @typedef {Object} Attribute
 * @property {string} name - The name of the attribute.
 * @property {string} type - The type of the attribute.
 */

/**
 * @typedef {Object} SplitData
 * @property {string} session - The session attribute value.
 * @property {string} user - The user attribute value.
 * @property {Split} SPLIT - The user attribute value.
 */

/**
 * @typedef {Object} Split
 * @property {Attribute[]} attributes - The attributes of the split.
 * @property {SplitData[]} data - The data within the split.
 * @property {string|null} key - The key of the split.
 * @property {string} chart_type - The type of chart related to the split.
 * @property {string} chart_name - The name of the chart related to the split.
 * @property {null} metric - The metric related to the split.
 */

/**
 * @typedef {Object} ResponsePayload
 * @property {Attribute[]} attributes - The attributes of the payload.
 * @property {SplitData[]} data - The data within the payload.
 * @property {string|null} key - The key of the payload.
 * @property {string} chart_type - The type of chart related to the payload.
 * @property {string} chart_name - The name of the chart related to the payload.
 * @property {Attribute[]} metric - The metric related to the payload.
 */

export const buildGlobalFilterRequest = (data) => {
  const globalFilter = [];

  try {
    const fieldGlobalFilters = data[CHART_CONFIG.FIELD_GLOBAL_FILTERS];

    Object.entries(fieldGlobalFilters).forEach(([key, value]) => {
      if (key === CHART_CONFIG.CHART_DATE_FIELD && isArray(value) && value.length === 2) {
        globalFilter.push(
          {name: key, operator: ">=", value: value[0]},
          {name: key, operator: "<=", value: value[1]}
        );
      } else if (key === CHART_CONFIG.CHART_FILTER && isArray(value) && value.length > 0) {
        value.forEach((filter) => {
          globalFilter.push(filter);
        });
      } else if (isArray(value) && value.length > 0) {
        globalFilter.push({
          name: key,
          operator: "IN",
          value: value.join("|||"),
        });
      }
    });

    return globalFilter;
  } catch (error) {
    return [];
  }
};

export const buildDefaultCharts = (dimension, metric) => {
  return [
    {
      ChartId: CHART_CONFIG.CHART_NAME_KPI_CHART_ID,
      Type: CHART_CONFIG.CHART_TYPE_KPI,
      Dimension: [],
      Metric: [],
    },
    {
      ChartId: CHART_CONFIG.CHART_1,
      Type: CHART_CONFIG.CHART_TYPE_LINECHART,
      Dimension: dimension,
      Metric: metric,
    },
    {
      ChartId: CHART_CONFIG.CHART_2,
      Type: CHART_CONFIG.CHART_TYPE_BARCHART,
      Dimension: dimension,
      Metric: metric,
    },
    {
      ChartId: CHART_CONFIG.CHART_3,
      Type: CHART_CONFIG.CHART_TYPE_STACKEDCHART,
      Dimension: dimension,
      Metric: metric,
    }
  ]
}

/**
 * Represents a chart object.
 * @typedef {Object} Chart
 * @property {string} ChartId - The unique identifier of the chart.
 * @property {string} Type - The type of the chart.
 * @property {Array} Dimension - The dimensions associated with the chart.
 * @property {Array} Metric - The metrics associated with the chart.
 */

/**
 * Filter charts by a specific chart type.
 * @param {Array<Chart>} charts - The array of charts to filter.
 * @param {string} chartType - The chart type to filter by.
 * @returns {Array<Chart>} The filtered array of charts with the specified chart type.
 */
export const filterChartByType = (charts, chartType) => {
  return charts.filter(e => e.Type === chartType);
}

export const buildGroupRequest = (config) => {
  if ([CHART_CONFIG.CHART_TYPE_LINECHART, CHART_CONFIG.CHART_TYPE_STACKEDCHART].includes(config[CHART_CONFIG.FIELD_CHART_TYPE])) {
    config[CHART_CONFIG.FIELD_DIMENSION].unshift({
      [CHART_CONFIG.FIELD_NAME]: CHART_CONFIG.CHART_DATE_FIELD
    });
  }
  return config[CHART_CONFIG.FIELD_DIMENSION];
}

export const buildChartRequest = (data, globalFilters, chartMetricAndGroup) => {
  try {
    return Array.from(Object.entries(chartMetricAndGroup)).map(([k, v]) => (
      {
        [CHART_CONFIG.FIELD_CHART_NAME]: k,
        [CHART_CONFIG.FIELD_CHART_TYPE]: v[CHART_CONFIG.FIELD_CHART_TYPE],
        [CHART_CONFIG.FIELD_GLOBAL_FILTERS]: globalFilters,
        [CHART_CONFIG.FIELD_METRIC]: v[CHART_CONFIG.FIELD_METRIC],
        [CHART_CONFIG.FIELD_GROUP_BY]: v[CHART_CONFIG.FIELD_DIMENSION],
      }
    ))

  } catch (err) {
    return {
      payload: [],
      message: "Exception",
    }
  }
}

/**
 * Represents the parsed data for a KPI chart response.
 * @typedef {Object} KPIMetricData
 * @property {string} name - The name of the metric.
 * @property {any} value - The value associated with the metric.
 */

/**
 * Parses the response payload for a KPI chart.
 *
 * @param {ResponsePayload} responsePayload - The response payload containing KPI chart data.
 * @returns {KPIMetricData[]} An array of objects representing the parsed KPI chart data.
 */

const percentageMetrics = [
  'ccr', 'ctr', 'icr',
  'ecpi', 'ecpc', 'ecpm',
  'roi', 'roas'
]

export const parseKPIChartResponse = (responsePayload) => {
  try {
    /** @type {Array<String>} */
    const metrics = responsePayload[CHART_CONFIG.FIELD_METRIC].map(item => item.name);
    return metrics.map((metric) => ({
      name: metric,
      value: responsePayload.data[0][metric], // Todo: Look stupid
    }))
  } catch (exception) {
    return [];
  }
}


export const parseLineChartResponse = ({responsePayload, showNonZeroOnly}) => {
  const metrics = responsePayload[CHART_CONFIG.FIELD_METRIC].map(item => item.name);

  const result = {};

  const parse = (data) => {

    data.forEach(item => {
      const {SPLIT} = item || {};
      if (SPLIT && SPLIT.key && SPLIT.data) {
        const key = SPLIT.key[0];

        SPLIT.data.forEach((tmp) => {

          if (key === CHART_CONFIG.CHART_DATE_FIELD) {
            result[key] = result[key] || [];
            result[key].push(tmp[key]);
            if (!tmp.SPLIT) {
              metrics.forEach((metric) => {
                result[metric] = result[metric] || [];
                result[metric].push(tmp[metric]);
              })
            }
          } else {
            metrics.forEach((metric) => {
              result[metric] = result[metric] || {};
              result[metric][tmp[key]] = result[metric][tmp[key]] || [];
              if (percentageMetrics.includes(metric)) {
                result[metric][tmp[key]].push(Math.round(tmp[metric] * 10000) / 100);
              } else {
                result[metric][tmp[key]].push(Math.round(tmp[metric]));
              }

              const dateKeyLength = result[CHART_CONFIG.CHART_DATE_FIELD].length;

              if (result[metric][tmp[key]].length !== dateKeyLength) {
                if (tmp[metric]) {
                  result[metric][tmp[key]].splice(result[metric][tmp[key]].length - 1, 0, "0"); // Push 0 to right before the last element
                } else {
                  result[metric][tmp[key]].push("0"); // Push 0 as the last element
                }
              }
            })
          }

          if (tmp.SPLIT) {
            parse([tmp]);
          }
        });
      }
    });
  };

  parse(responsePayload.data);
  // addMissingDates(result, metrics);

  /** @type {ChartMetricData[]} */
  const finalResult = metrics.map((metric) => {
    const buf = [];

    if (typeof result[metric] == 'object' && !Array.isArray(result[metric])) {
      for (let [key, value] of Object.entries(result[metric])) {
        if (showNonZeroOnly === true) {
          if (value.some(v => !isUndefinedOrEmpty(v) && v !== 0 && v !== "0")) {
            buf.push({
              name: key,
              data: value,
            });
          }
        } else {
          buf.push({
            name: key,
            data: value,
          });
        }
      }
    } else if (Array.isArray(result[metric])) {
      buf.push({
        name: metric,
        data: result[metric],
      });
    }

    return {
      [CHART_CONFIG.CHART_DATE_FIELD]: result[CHART_CONFIG.CHART_DATE_FIELD],
      [metric]: buf
    };
  });

  return finalResult;
}

/**
 * Represents the result of mapping metrics to their corresponding data.
 * @typedef {Object} MetricData
 * @property {string[]} name - The names corresponding to the metrics.
 * @property {any[]} value - The values associated with the metrics.
 */

/**
 * Parses the response payload for a KPI chart.
 *
 * @param {ResponsePayload} responsePayload - The response payload containing KPI chart data.
 * @returns {MetricData[]} An array of objects representing the mapped metrics and their data.
 */
export const parseBarChartResponse = ({responsePayload, showNonZero}) => {
  /** @type {Array<String>} */
  const metrics = responsePayload[CHART_CONFIG.FIELD_METRIC].map(item => item.name);

  /**
   * Represents session data with dynamic keys.
   * @typedef {Object} SessionData
   * @property {Object.<string, string[]>} session - Object containing session data.
   *    The keys are dynamic and represent different session types.
   *    The values are arrays of strings representing session identifiers.
   */

  /** @type {SessionData} */
  const result = {};

  /**
   * Parses the response payload for a KPI chart.
   *
   * @param {SplitData[]} data - The response payload containing KPI chart data.
   * @returns {Object} An array of objects representing the parsed KPI chart data.
   */
  const parse = (data) => {
    data.forEach(item => {
      const {SPLIT} = item || {};

      if (SPLIT && SPLIT.key && SPLIT.data) {
        const key = SPLIT.key[0];

        SPLIT.data.forEach((tmp) => {
          metrics.forEach((metric) => {
            result[metric] = result[metric] || {};
            result[metric][tmp[key]] = result[metric][tmp[key]] || [];
            if (percentageMetrics.includes(metric)) {
              result[metric][tmp[key]].push(Math.round(tmp[metric] * 10000) / 100);
            } else {
              result[metric][tmp[key]].push(Math.round(tmp[metric]));
            }
            result[metric][tmp[key]].push(tmp[metric]);
          });
        });
      }
    })
    metrics.forEach((metric) => {
      const resultArray = Object.entries(result[metric]);
      resultArray.sort((a, b) => b[1][0] - a[1][0]);

      result[metric] = Object.fromEntries(resultArray);
    })
  }

  parse(responsePayload.data);

  return metrics.map((metric) => {
    const keys = [];
    const values = [];

    for (let [key, value] of Object.entries(result[metric])) {
      if (showNonZero) {
        if (value[0] === 0) {
          continue;
        }
      }
      keys.push(key);
      if (isNaN(value[0])) {
        value[0] = 0;
      }
      values.push(value[0]);
    }

    return {
      [metric]: {
        name: keys,
        value: values,
      }
    }
  });
}


/**
 * Represents the result of mapping metrics to their corresponding data with date fields.
 * @typedef {Object} ChartData
 * @property {string} [dateField] - The name of the date field in the chart configuration.
 * @property {Object.<string, {name: string, data: any[]}[]>} metricsData - Object containing metric data.
 */


/**
 * Parses the response payload for a KPI chart.
 *
 * @param {ResponsePayload} responsePayload - The response payload containing KPI chart data.
 * @param showNonZero
 * @returns {ChartData[]} An array of objects representing the mapped metrics and their data with date fields.
 */
export const parseStackedChartResponse = ({responsePayload, showNonZero}) => {
  /** @type {Array<String>} */
  const metrics = responsePayload[CHART_CONFIG.FIELD_METRIC].map(item => item.name);
  const result = {};

  /**
   * Parses the response payload for a KPI chart.
   *
   * @param {SplitData[]} data - The response payload containing KPI chart data.
   * @returns {Object} An array of objects representing the parsed KPI chart data.
   */
  const parse = (data) => {
    data.forEach(item => {
      const {SPLIT} = item || {};

      if (SPLIT && SPLIT.key && SPLIT.data) {
        const key = SPLIT.key[0];

        SPLIT.data.forEach((tmp) => {
          if (key === CHART_CONFIG.CHART_DATE_FIELD) {
            result[key] = result[key] || [];
            result[key].push(tmp[key]);
          } else {
            metrics.forEach((metric) => {
              result[metric] = result[metric] || {};
              result[metric][tmp[key]] = result[metric][tmp[key]] || [];
              if (percentageMetrics.includes(metric)) {
                result[metric][tmp[key]].push(Math.round(tmp[metric] * 10000) / 100);
              } else {
                result[metric][tmp[key]].push(Math.round(tmp[metric]));
              }

              const dateKeyLength = result[CHART_CONFIG.CHART_DATE_FIELD].length;
              if (result[metric][tmp[key]].length !== dateKeyLength) {
                if (tmp[metric]) {
                  result[metric][tmp[key]].splice(result[metric][tmp[key]].length - 1, 0, "0"); // Push 0 to right before the last element
                } else {
                  result[metric][tmp[key]].push("0"); // Push 0 as the last element
                }
              }
            });
          }

          if (tmp.SPLIT) {
            parse([tmp]);
          }
        })
      }
    });
  }

  parse(responsePayload.data);

  return metrics.map((metric) => {
    const buf = [];

    for (let [key, value] of Object.entries(result[metric])) {
      if (showNonZero) {
        if (value.some(v => !isUndefinedOrEmpty(v) && v !== 0 && v !== "0")) {
          buf.push({
            name: key,
            data: value,
          });
        }
      } else {
        buf.push({
          name: key,
          data: value,
        });
      }
    }

    return {
      [CHART_CONFIG.CHART_DATE_FIELD]: result[CHART_CONFIG.CHART_DATE_FIELD],
      [metric]: buf,
    };
  });
}

export const parseTableChartResponse = ({responsePayload, showNonZero, dimensionValue}) => {
  /** @type {Array<String>} */
  const metrics = responsePayload[CHART_CONFIG.FIELD_METRIC].map(item => item.name);
  dimensionValue = (Array.isArray(dimensionValue)) ? dimensionValue : [dimensionValue];

  const parseNestedResponse = (data, metrics, percentageMetrics) => {
    let result = [];

    const recursiveParse = (item, dimensions = {}) => {
      if (item.SPLIT && item.SPLIT.data) {
        const key = item.SPLIT.key[0];
        item.SPLIT.data.forEach(subItem => {
          recursiveParse(subItem, {...dimensions, [key]: subItem[key]});
        });
      } else {
        Object.keys(dimensions).forEach(key => {
          if (!dimensionValue.includes(key)) {
            dimensionValue.forEach(dimension => {
              if (key.includes(dimension)) {
                dimensions[dimension] = dimensions[key];
              }
            });
            delete dimensions[key];
          }
        });
        let dataPoint = {...dimensions};
        metrics.forEach(metric => {
          if (percentageMetrics.includes(metric)) {
            dataPoint[metric] = Math.round(parseFloat(item[metric]) * 10000) / 100;
          } else {
            dataPoint[metric] = Math.round(parseFloat(item[metric]));
          }
        });
        if (showNonZero) {
          if (metrics.some(metric => dataPoint[metric] !== 0)) {
            result.push(dataPoint);
          }
        }
      }
    };

    data.forEach(item => recursiveParse(item));

    return result;
  };

  const rows = parseNestedResponse(responsePayload.data, metrics, percentageMetrics);


  if (isUndefinedOrEmpty(metrics)) {
    return {}
  }

  const columns = dimensionValue.map((dimension, index) => ({
    accessorKey: dimension,
    header: capitalizeAll(dimension.replace('_', ' ')),
    enableGrouping: true,
    fieldType: 'dimension',
  }))

  columns.push(
    ...metrics.map((metric) => ({
      accessorKey: metric,
      header: capitalizeAll(metric.replace('_', ' ')),
      Cell: ({ cell }) => cell.getValue().toLocaleString(),
      fieldType: 'metric',
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => `Total: ${cell.getValue().toLocaleString()}`,
    }))
  )

  return {
    rows,
    columns,
    grouping: dimensionValue,
  }
}

export const addMissingDates = (result, metrics) => {
  let allDates = [];
  let missingDateIndexes = [];

  const existingDates = result[CHART_CONFIG.CHART_DATE_FIELD];
  const startDate = new Date(Math.min(...existingDates.map(date => new Date(date))));
  const endDate = new Date(Math.max(...existingDates.map(date => new Date(date))));

  let i = 0;
  for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
    if (!existingDates.includes(date.toISOString().slice(0, 10))) {
      missingDateIndexes.push(i)
    }
    i = i + 1

    allDates.push(date.toISOString().slice(0, 10));
  }

  result[CHART_CONFIG.CHART_DATE_FIELD] = allDates

  missingDateIndexes.forEach((i) => {
    metrics.forEach((metric) => {
      Object.values(result[metric]).forEach((value) => {
        if (!isUndefinedOrEmpty(value) && value.length > i) {
          value.splice(i, 0, 0)
        }
      })
    })
  })
}

const METRICS_CONFIG = {
  "platform": ["session", "user", "arpu", "revenue", "conversion_rate", "event_count", "install", "organic_install"],
  "ad": ["impression", "cost", "click", "conversion_value"],
  "ad_id": ["impression", "cost", "click", "conversion_value"],
  "adset": ["impression", "cost", "click", "conversion_value"],
  "adset_id": ["impression", "cost", "click", "conversion_value"],
  "utm_campaign": ["session", "cir", "user", "cost", "arpu", "revenue", "conversion_rate", "cpi", "roi", "event_count", "install", "organic_install"],
  "campaign_id": ["impression", "cost", "click", "conversion_value"],
  "utm_source": ["session", "cir", "user", "cost", "arpu", "revenue", "conversion_rate", "cpi", "roi", "event_count", "install", "organic_install"],
  "utm_item_id": ["session", "cir", "user", "cost", "arpu", "revenue", "conversion_rate", "roi", "event_count"]
}


export const getMetricsByDimensionKey = (dimensionKey, metrics) => {
  const buf = [];
  for (let [key, value] of Object.entries(METRICS_CONFIG)) {
    if (key === dimensionKey) {
      metrics.forEach((m) => {
        if (value.includes(m.fieldKey)) {
          buf.push(m)
        }
      })
    }
  }
  return buf;
}
