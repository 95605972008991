import {
  timeType,
} from "../../types.js";

export const processGetIntegrationData = (data) => {
  if (data.appIntegrationTiktok.status === 'INTEGRATION_PARTNER_ACTIVE') {
    data.appIntegrationTiktok.active = true
  } else {
    data.appIntegrationTiktok.active = false
  }

  if (!!data.appIntegrationTiktok.inAppEventPostbacks) {
    data.appIntegrationTiktok.inAppEventPostbacksEnabled = data.appIntegrationTiktok.inAppEventPostbacks.length > 0;

    for (let key = 0; key < data.appIntegrationTiktok.inAppEventPostbacks.length; key ++) {
      if (data.appIntegrationTiktok.inAppEventPostbacks[key].valueIncluded &&
        data.appIntegrationTiktok.inAppEventPostbacks[key].revenueIncluded) {
        data.appIntegrationTiktok.inAppEventPostbacks[key].including = 'value_and_revenue';
      } else if (data.appIntegrationTiktok.inAppEventPostbacks[key].valueIncluded &&
        !data.appIntegrationTiktok.inAppEventPostbacks[key].revenueIncluded) {
        data.appIntegrationTiktok.inAppEventPostbacks[key].including = 'value_and_no_revenue';
      } else if (!data.appIntegrationTiktok.inAppEventPostbacks[key].valueIncluded &&
        !data.appIntegrationTiktok.inAppEventPostbacks[key].revenueIncluded) {
        data.appIntegrationTiktok.inAppEventPostbacks[key].including = 'no_value_and_no_revenue';
      }
    }
  }

  return data;
}
