import HttpMethod from "consts/httpMethod";
import { processErrorResponse, processRequest, processSuccessResponse } from "utils";

export default {

  /**
   * Asynchronously fetches project details based on the provided appId.
   *
   * @param {Object} params - Parameters for the project retrieval.
   * @param {string} params.appId - The unique identifier of the app.
   * @returns {Promise<Array<String>>} A Promise that resolves to a Array string representing the fetched details.
   * @throws {Error} If an error occurs during the request, it handles the error and returns an empty array.
   */
  async getEventNameList({ appId }) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `app/${appId}/fields/event_name/values`,
      method: HttpMethod.GET
    };
    try {
      const res = await processRequest(ep);
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  async getFields2({ appId, fieldKey }) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `v2/app/${appId}/fields/${fieldKey}/values`,
      method: HttpMethod.GET
    };
    try {
      const res = await processRequest(ep);
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  /**
   * Asynchronously fetches project details based on the provided appId.
   *
   * @param {Object} params - Parameters for the project retrieval.
   * @param {string} params.appId - The unique identifier of the app.
   * @param {string} params.fieldKey - The fieldKey of the app.
   * @returns {Promise<Array<String>>} A Promise that resolves to a Array string representing the fetched details.
   * @throws {Error} If an error occurs during the request, it handles the error and returns an empty array.
   */
  async getFieldValues({ appId, fieldKey }) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `app/${appId}/fields/${fieldKey}/values`,
      method: HttpMethod.GET
    };
    try {
      const res = await processRequest(ep);
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },
}
