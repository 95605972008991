import {CHART_CONFIG} from "consts";
import {useFormContext} from "react-hook-form";

import VisualisationHeader from "../visualisation_header";
import VisualisationStackedChart from "views/visualisation/visualisation_stackedchart";
import VisualisationLineChart from "views/visualisation/visualisation_linechart";
import VisualisationBarChart from "views/visualisation/visualisation_barchart";
import {isUndefinedOrEmpty} from "utils";
import {useEffect} from "react";
import {METRIC_DEFAULT} from "views/overview/visualisation/consts";
import VisualisationTableChart from "views/visualisation/visualization_table";
import VisualisationKpi from "views/visualisation/visualisation_kpi";


export default function Visualisation({
                                        appId,
                                        defaultChartType,
                                        globalFilter,
                                        chartId,
                                        chartIndex,
                                        dimensions,
                                        metrics,
                                        showDelete = false,
                                        onDelete = () => {
                                        },

                                        limitOptions = [],
                                        orderOptions = [],
                                        dataOptions = [],
                                        frequencyOptions = [],
                                        multiMetrics = [],
                                        multiDimensions = [],
                                        title = false,
                                        cardType,
                                        cardSubType,
                                      }) {
  const {watch, setValue} = useFormContext();
  const dimensionValue = watch(`${chartId}.dimension`);
  const metricValue = watch(`${chartId}.metric`);
  const chartType = watch(`${chartId}.type`);
  const showNonZero = watch(`${chartId}.showNonZero`);
  const limitValue = watch(`${chartId}.limit`);
  const orderValue = watch(`${chartId}.order`);
  const frequencyValue = watch(`${chartId}.frequency`);

  const payload = {
    appId: appId,
    globalFilter: globalFilter,
    chartType: chartType,
    chartId: chartId,
    dimensionValue: dimensionValue,
    limitValue: limitValue,
    showNonZero: showNonZero,
    metricValue: metricValue,
    frequencyValue: frequencyValue,
    orderValue: orderValue,
    title: title,
  }

  useEffect(() => {
    if (!isUndefinedOrEmpty(dimensions) && !isUndefinedOrEmpty(dimensions[chartIndex])) {
      const metric = METRIC_DEFAULT.find(m => m.dimension === dimensions[chartIndex].fieldKey);
      if (!isUndefinedOrEmpty(metric) && isUndefinedOrEmpty(multiMetrics)) {
        setValue(`${chartId}.metric`, metric.metric);
      }
    }
  }, [dimensions, chartIndex]);

  useEffect(() => {
    if (defaultChartType === CHART_CONFIG.CHART_TYPE_KPI) {
      setValue(`${chartId}.type`, CHART_CONFIG.CHART_TYPE_KPI);
      setValue(`${chartId}.metric`, metrics);
    }
  }, [])

  useEffect(() => {
    if ((Array.isArray(dimensionValue) && dimensionValue.length > 1) && (Array.isArray(metricValue) && metricValue.length > 1)) {
      setValue(`${chartId}.type`, CHART_CONFIG.CHART_TYPE_TABLE);
      return
    }

    if (chartType === CHART_CONFIG.CHART_TYPE_KPI && (Array.isArray(dimensionValue) && dimensionValue.length > 0)) {
      setValue(`${chartId}.type`, CHART_CONFIG.CHART_TYPE_TABLE);
      return
    }

    if (Array.isArray(dimensionValue) && dimensionValue.length === 0) {
      console.log("dimensionValue", dimensionValue)
      setValue(`${chartId}.type`, CHART_CONFIG.CHART_TYPE_KPI);
    }
  }, [dimensionValue, metricValue, chartType])

  useEffect(() => {
    if (chartType === CHART_CONFIG.CHART_TYPE_KPI) {
      setValue(`${chartId}.dimension`, []);
    }
  }, [chartType])

  const header = <VisualisationHeader
    chartId={chartId}
    dimensions={dimensions}
    dimensionValue={dimensionValue}
    metrics={metrics}
    defaultChartType={defaultChartType}
    showDelete={showDelete}
    onDelete={onDelete}
    className={"mb-4"}
    dataOptions={dataOptions}
    limitOptions={limitOptions}
    frequencyOptions={frequencyOptions}
    orderOptions={orderOptions}
    multiMetrics={multiMetrics}
    multiDimensions={multiDimensions}
    chartType={chartType}
    payload={payload}
    cardType={cardType}
    cardSubType={cardSubType}
  />


  return (
    <>
      {
        defaultChartType !== CHART_CONFIG.CHART_TYPE_KPI && header
      }

      <div className="w-full">
        {chartType === CHART_CONFIG.CHART_TYPE_BARCHART ?
          <VisualisationBarChart
            appId={appId}
            globalFilter={globalFilter}
            chartId={chartId}
            dimensionValue={dimensionValue}
            metricValue={metricValue}
            showNonZero={showNonZero}
            limitValue={limitValue}
            orderValue={orderValue}
            title={title}
          />
          : chartType === CHART_CONFIG.CHART_TYPE_LINECHART ?
            <VisualisationLineChart
              appId={appId}
              globalFilter={globalFilter}
              chartId={chartId}
              dimensionValue={dimensionValue}
              metricValue={metricValue}
              showNonZero={showNonZero}
              frequencyValue={frequencyValue}
              title={title}
            />
            : chartType === CHART_CONFIG.CHART_TYPE_STACKEDCHART ?
              <VisualisationStackedChart
                appId={appId}
                globalFilter={globalFilter}
                chartId={chartId}
                dimensionValue={dimensionValue}
                metricValue={metricValue}
                showNonZero={showNonZero}
                frequencyValue={frequencyValue}
                title={title}
              />
              : chartType === CHART_CONFIG.CHART_TYPE_TABLE ?
                <VisualisationTableChart
                  appId={appId}
                  globalFilter={globalFilter}
                  chartId={chartId}
                  dimensionValue={dimensionValue}
                  metricValue={metricValue}
                  showNonZero={showNonZero}
                  limitValue={limitValue}
                />
                : chartType === CHART_CONFIG.CHART_TYPE_KPI ?
                  <div>
                    <VisualisationKpi
                      appId={appId}
                      globalFilter={globalFilter}
                      chartId={chartId}
                      metricValue={metricValue}
                      header = {defaultChartType === CHART_CONFIG.CHART_TYPE_KPI && header}
                      className = {defaultChartType !== CHART_CONFIG.CHART_TYPE_KPI
                        && `place-items-center  min-h-[200px] bg-white justify-center grid grid-cols-${!isUndefinedOrEmpty(metricValue) ? metricValue.length : 1} text-2xl`}
                    />
                  </div>
                  : <></>
        }
      </div>
    </>
  )
}
