import {useState} from "react";

import DsIconAddToDashboard from "components/DsIcon/DsIconAddToDashboard";
import TooltipButton from "components/TooltipButton";

import AddToDashboardModal from './add_to_dashboard_modal';


export default function AddToDashboard({ className = '', cardType, cardSubType, payload}) {
  const [isAddToDashboardOpen, setIsAddToDashboardOpen] = useState(false);
  const payloadStr = JSON.stringify(payload);

  const handleAddToDashboard = async () => {
    setIsAddToDashboardOpen(true);
  }


  return (
    <div>
      <TooltipButton
        icon={DsIconAddToDashboard}
        tooltipText="Add to dashboard"
        onClick={handleAddToDashboard}
        className={className}
      />

      <AddToDashboardModal
        cardType={cardType}
        cardSubType={cardSubType}
        payload={payloadStr}
        isOpen={isAddToDashboardOpen}
        closeModal={() => setIsAddToDashboardOpen(false)}
      />
    </div>
  );
}
