import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { Link, useParams } from 'react-router-dom';
import { PATHS } from 'consts';

import MultiFilterBar from 'components/MultiFilterBar';
import MultiLevelTable from 'components/MultiLevelTable';
import { sortTypes } from 'components/MultiLevelTable/types';

import './index.scss';

export default function TableAnalysis() {
  const { appId } = useParams();
  const { t } = useTranslation();
  const methods = useForm();

  const name = methods.watch('name');

  const [editing, setEditing] = useState(false);

  const [sort, setSort] = useState({
    name: 'users',
    type: sortTypes.DESC,
  });

  const onSubmit = (data) => {
  };

  useEffect(() => {
    methods.setValue('name', "Untitled Report")
  }, []);

  useEffect(() => {
    const subscription = methods.watch(methods.handleSubmit(onSubmit));
    return () => subscription.unsubscribe();
  }, [methods.handleSubmit, methods.watch]);

  return (
    <div id="table-analysis">
      <Link
        to={`${PATHS.APP}/${appId}${PATHS.ALL_REPORTS}`}
        className="font-semibold hover:underline"
      >
        {t('ALL_REPORTS')}
      </Link>

      <FormProvider {...methods}>
        <div className='flex items-center justify-between mt-2 mb-5'>
          <div className='flex items-center justify-start'>
            {
              editing ?
                <input
                  {...methods.register("name")}
                  onBlur={() => setEditing(false)}
                  className="peer h-full w-full border-b border-blue-500 bg-transparent font-sans font-bold text-xl text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                /> : <div className='font-bold text-xl'>{name}</div>
            }
            {
              !editing && <FontAwesomeIcon onClick={() => setEditing(true)} icon={faPencil} className='ms-2 cursor-pointer' />
            }
          </div>

          <button className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-1 px-3 rounded">
            {t('SAVE')}
          </button>
        </div>

        <MultiFilterBar name="filter" />
      </FormProvider>

      <MultiLevelTable
        className="mt-4"
        sort={sort}
        onSortChange={(value) => setSort(value)}
        percentLevelShow={[2]}
      />
    </div>
  )
}
