import React from "react";
import {PATHS} from "consts";
import DsIconFail from "components/DsIcon/DsIconFail";

export default function SkanEmpty() {
  const navigateHomePage = () => {
    window.location.href = PATHS.APP;
  }
  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <div>
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full">
          <DsIconFail className="h-6 w-6 text-red-600" aria-hidden="true"/>
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <p className="text-base font-semibold leading-6 text-gray-900">
            This feature is only working if you have an iOS app
          </p>
        </div>
      </div>
      <div className="flex justify-center mt-5 sm:mt-6">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={navigateHomePage}
        >
          Go back to home page
        </button>
      </div>
    </div>
  )
}
