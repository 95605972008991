import { faEdit, faMagnifyingGlass, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DsListBox from 'components/DsListBox';
import TextInput from 'components/TextInput/WithoutForm';
import { PATHS } from 'consts';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { buildAppList } from 'utils/app';

import './index.scss';
import { Link, useParams } from 'react-router-dom';

const SORT_TYPE = {
  ASC: 'ASC',
  DESC: 'DESC',
};

const NAME_HEADER = {
  NAME: 'name',
  CREATED_BY: 'createdBy',
  LAST_MODIFIED: 'lastModified',
  VISIBILITY: 'visibility',
}


export default function ReportList() {
  const { appId } = useParams();
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState('');

  const [filter, setFilter] = useState({
    sort: {
      name: 'lastModified',
      type: SORT_TYPE.DESC
    }
  });

  const clickFilter = (name) => {
    if (filter.sort.name === name) {
      const sort = {
        name,
        type: filter.sort.type === SORT_TYPE.ASC ? SORT_TYPE.DESC : SORT_TYPE.ASC,
      };

      setFilter({ ...filter, sort });
    } else {
      setFilter({ ...filter, sort: { name, type: SORT_TYPE.DESC } });
    }
  };

  const appList = useSelector((state) => state.app.appList);

  const appOptions = useMemo(() => {
    return buildAppList(appList, PATHS.ALL_REPORTS);
  }, [appList]);

  return (
    <div id="report-list">
      <div className="font-bold text-xl me-2">{t('ALL_REPORTS')}</div>

      <DsListBox listData={appOptions} />

      <div className="bg-white rounded-lg shadow-md w-full mt-5 py-2">
        <div className="flex justify-between items-center px-4">
          <div className="flex flex-row">
            <TextInput
              name="search"
              icon={faMagnifyingGlass}
              placeholder={t('SEARCH_BY_NAME')}
              onChange={(e) => setSearchTerm(e.target.value)}
            />

          </div>
          {/* <AddDashboard reset={getDashboardList} /> */}
        </div>

        <div className="relative mt-2">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500">
            <thead className="text-xs text-gray-700 bg-gray-50">
              <tr>
                {
                  Object.keys(NAME_HEADER).map((key) => (
                    <th scope="col" className="px-6 py-3">
                      <div className="flex justify-start items-center">
                        {t(key)}

                        <div
                          onClick={() => clickFilter(NAME_HEADER[key])}
                          className='relative sort-wrapper cursor-pointer ms-3 h-100'
                        >
                          <FontAwesomeIcon
                            icon={faSortUp}
                            className={`absolute up ${filter.sort.name === NAME_HEADER[key] && filter.sort.type === SORT_TYPE.ASC ? 'text-blue-600' : ''}`}
                          />
                          <FontAwesomeIcon
                            icon={faSortDown}
                            className={`absolute down ${filter.sort.name === NAME_HEADER[key] && filter.sort.type === SORT_TYPE.DESC ? 'text-blue-600' : ''}`}
                          />
                        </div>
                      </div>
                    </th>
                  ))
                }

                <th scope="col" className="px-6 py-3"></th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td className='px-6 py-3'>Untitled Report</td>
                <td className='px-6 py-3'>chau@shopee.vn</td>
                <td className='px-6 py-3'>12/05/2024</td>
                <td className='px-6 py-3'>true</td>
                <td className='px-6 py-3'>
                  <Link to={`${PATHS.APP}/${appId}${PATHS.ALL_REPORTS}/123`}>
                    <FontAwesomeIcon icon={faEdit} className='cursor-pointer' />
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
