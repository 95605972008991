import { useRef, useState } from 'react';

import ToggleBox from 'components/ToggleBox';


import './index.scss';

/**
 * Text combo box component.
 * @param {Object} props - The props object.
 * @param {string} [props.value=''] - The value of the input field.
 * @param {function} [props.onChange=()=>{}] - The function called when the value changes.
 * @param {string} [props.className=''] - Additional classes for styling.
 * @param {boolean} [props.multi=false] - Indicates if multiple values can be selected.
 * @param {Array} [props.options=[]] - The options for the combo box.
 * @returns {JSX.Element} A React component representing the TextComboBox.
 */
export default function TextComboBox({
  value = '',
  onChange = () => { },
  className,
  multi=false,
  options=[],
}) {
  const wrapper = useRef();
  const [search, setSearch] = useState('');

  const handleValueChange = (event) => {
    const strings = event.target.value.split(',');
    setSearch(strings[strings.length - 1]);
    onChange(event.target.value);
    wrapper.current.open();
  };

  const addData = (newItem) => {
    if (multi) {
      if (value == '') {
        onChange(`${newItem},`);
      } else {
        const resultString = value.replace(new RegExp(search + "$"), newItem);
        onChange(`${resultString},`);
      }
    } else {
      onChange(newItem);
    }
    setSearch('');
    wrapper.current.toggle();
  };

  return (
    <ToggleBox className="w-full" ref={wrapper}>
      <ToggleBox.Button>
        <div className="text-combobox-input relative">
          <input
            value={value}
            onChange={handleValueChange}
            className={`rounded bg-white py-1.5 px-3 border border-gray-300 focus:outline-none pr-7 w-full ${className}`}
          />
        </div>
      </ToggleBox.Button>

      <ToggleBox.Content>
        <div className="py-2 px-1 max-h-60 overflow-auto">
          {Array.isArray(options) && options.map(item => (
            item.toLowerCase().includes(search.toLowerCase()) && <div className='w-full px-3 py-1.5 hover:bg-gray-200 cursor-pointer' key={item} onClick={() => addData(item)}>{item}</div>
          ))}
        </div>
      </ToggleBox.Content>
    </ToggleBox>
  );
};
